import React from "react";

const Download = () => {
  const className = "fill-primaryBlue";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="35"
      height="35"
      viewBox="0 0 35 35"
      className={className}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_17989"
            data-name="Rectangle 17989"
            width="12.994"
            height="16.075"
          />
        </clipPath>
      </defs>
      <g
        id="Group_42558"
        data-name="Group 42558"
        transform="translate(-1707 -891)"
      >
        <circle
          id="Ellipse_1353"
          data-name="Ellipse 1353"
          cx="17.5"
          cy="17.5"
          r="17.5"
          transform="translate(1707 891)"
          opacity="0.2"
        />
        <g
          id="Group_42557"
          data-name="Group 42557"
          transform="translate(1718 900)"
        >
          <g
            id="Group_42464"
            data-name="Group 42464"
            transform="translate(0 0)"
            clipPath="url(#clipPath)"
          >
            <path
              id="Path_37798"
              data-name="Path 37798"
              d="M1.64,7.271H4.209V0H9.027V7.274h2.6l-4.989,6.6L1.64,7.271"
              transform="translate(-0.488 0)"
            />
            <rect
              id="Rectangle_17988"
              data-name="Rectangle 17988"
              width="12.994"
              height="1.839"
              transform="translate(0 14.236)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Download;
