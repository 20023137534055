import { Card } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { updateUserSettings } from "auth/store/userSlice";
import TeamMembers from "components/TeamMembers";
import BellIcon from "components/svgs/icons/general/BellIcon";
import Close from "components/svgs/icons/general/Close";
import DarkMode from "components/svgs/icons/general/DarkMode";
import Chat from "components/svgs/icons/navbars/Chat";
import Scrollbars from "core/Scrollbars";
import format from "date-fns/format";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  BasicSwitchStyleOverrides,
  StyledSwipeableDrawer,
} from "settings/styleOverrides";
import withReducer from "store/withReducer";
import Utilities from "utils/utilities";
import reducer from "./store";
import { toggleQuickPanel } from "./store/stateSlice";

const QuickPanel = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [settings, setUserSettings] = useState({});
  const [tabNumber, setTabNumber] = useState(0);
  const data = useSelector(({ quickPanel }) => quickPanel.data);
  const state = useSelector(({ quickPanel }) => quickPanel.state);
  const user = useSelector(({ auth }) => auth.user);

  useEffect(() => {
    if (user?.data?.settings) {
      setUserSettings(user?.data?.settings);
    }
  }, [user?.data?.settings]);

  const handleToggle = (value, checked) => (ev) => {
    let updatedUserSettings;
    switch (value) {
      case "notifications":
        updatedUserSettings = {
          ...settings,
          notifications: checked,
        };
        dispatch(updateUserSettings(updatedUserSettings));
        break;
      case "chatSidebar":
        updatedUserSettings = {
          ...settings,
          chatSidebar: checked,
        };
        dispatch(updateUserSettings(updatedUserSettings));
        break;
      case "darkMode":
        const darkMode = checked;
        const theme = Utilities.getDarkTheme(darkMode);
        updatedUserSettings = {
          ...settings,
          darkMode: darkMode,
          theme: theme,
        };
        dispatch(updateUserSettings(updatedUserSettings));
        break;
      default:
        return;
    }
  };

  const redirect = (to) => () => {
    navigate({
      pathname: `/${user.data.currentWorkspace}/${to}`,
    });
    dispatch(toggleQuickPanel());
  };

  return (
    <StyledSwipeableDrawer
      open={state}
      anchor="right"
      onOpen={(ev) => {}}
      onClose={(ev) => dispatch(toggleQuickPanel())}
      disableSwipeToOpen
    >
      <div className="bg-primaryBlue flex justify-between p-4 ">
        {" "}
        <Typography className="text-2xl font-medium text-white">
          Quick Panel
        </Typography>
        <Box
          onClick={(ev) => dispatch(toggleQuickPanel())}
          className="cursor-pointer"
        >
          <Close />
        </Box>
      </div>
      <div className="fixed-container px-2 py-4 ">
        <div className="date-name-container border-b py-2 mx-2">
          <Typography className="text-xl">
            {format(new Date(), "eeee")}, {format(new Date(), "MMMM")}{" "}
            {format(new Date(), "dd")}
          </Typography>
          <Typography className="mt-4 text-lg">
            Welcome Back, <b>{user?.data?.displayName}</b>
          </Typography>
        </div>
        <div className="tab-container py-6 border-b">
          <div className="outer-div flex rounded-full w-full h-[4em] bg-white border-2 border-primaryBlue">
            <div
              onClick={() => setTabNumber(0)}
              className={`rounded-full flex justify-center items-center ${
                tabNumber === 0 ? "bg-primaryBlue" : "bg-white"
              } w-full cursor-pointer`}
            >
              <Typography
                className={`${
                  tabNumber === 0 ? "text-white" : "text-cancelBlack"
                } font-medium`}
              >
                Events
              </Typography>
            </div>
            <div
              onClick={() => setTabNumber(1)}
              className={`rounded-full flex justify-center items-center ${
                tabNumber === 1 ? "bg-primaryBlue" : "bg-white"
              }  w-full cursor-pointer`}
            >
              <Typography
                className={`${
                  tabNumber === 1 ? "text-white" : "text-cancelBlack"
                } font-medium`}
              >
                Notes
              </Typography>
            </div>
            <div
              onClick={() => setTabNumber(2)}
              className={`rounded-full flex justify-center items-center ${
                tabNumber === 2 ? "bg-primaryBlue" : "bg-white"
              } w-full cursor-pointer`}
            >
              <Typography
                className={`${
                  tabNumber === 2 ? "text-white" : "text-cancelBlack"
                } font-medium`}
              >
                Settings
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <Scrollbars>
        {tabNumber === 0 && (
          <List>
            {data &&
              data.events.map((event) => (
                <Card
                  key={event.id}
                  className="rounded-none p-4 mb-4 cursor-pointer flex justify-between"
                  onClick={redirect("calendar")}
                >
                  <div>
                    <Typography className="font-semibold text-lg truncate">
                      {event.title}
                    </Typography>
                    <Typography className="text-sm truncate">
                      {event.agenda}
                    </Typography>
                    <Typography className="text-sm mt-2 text-grayText">
                      {format(
                        event?.start?.seconds
                          ? new Date(event.start.seconds * 1000)
                          : new Date(event?.start),
                        `MMM dd ${event.allDay ? "" : "- h:mm a"}`
                      )}
                    </Typography>
                  </div>
                  {event.attendees.length > 0 && (
                    <div className="justify-center items-center ml-4">
                      <TeamMembers users={event.attendees} />
                    </div>
                  )}
                </Card>
              ))}

            {data && data.events.length === 0 && (
              <ListItem>
                <ListItemText
                  primary="There are no upcoming events."
                  align="center"
                  className="mt-6"
                />
              </ListItem>
            )}
          </List>
        )}
        {tabNumber === 1 && (
          <List>
            {data &&
              data.notes.map((note) => (
                <Card
                  key={note.id}
                  className="rounded-none p-4 mb-5 cursor-pointer"
                  onClick={redirect("notes")}
                >
                  <div className="flex gap-4">
                    <div className="bg-darkRed w-[3em] h-1"></div>
                    <div className="bg-primaryBlue w-[3em] h-1"></div>
                  </div>
                  <div className="note-content">
                    <Typography className="font-semibold text-lg mt-2">
                      {note.title}
                    </Typography>
                    <Typography className="text-lg mt-2">
                      {note.description}
                    </Typography>
                    <Typography className="text-base mt-4 text-grayText">
                      {`Last edit: ${format(
                        note.modifiedDate?.seconds
                          ? new Date(note.modifiedDate.seconds * 1000)
                          : new Date(note.modifiedDate),
                        "MMM dd yy, h:mm a"
                      )}`}
                    </Typography>
                  </div>
                </Card>
              ))}

            {data && data.notes.length === 0 && (
              <ListItem>
                <ListItemText
                  primary="There are no personal notes yet."
                  align="center"
                  className="mt-6"
                />
              </ListItem>
            )}
          </List>
        )}
        {tabNumber === 2 && (
          <List>
            <ListItem>
              <ListItemIcon className="min-w-40">
                <Box>
                  <BellIcon color="#6e6e6e" />
                </Box>
              </ListItemIcon>
              <ListItemText
                primary="Subscribe"
                primaryTypographyProps={{ className: "text-lg" }}
              />
              <ListItemSecondaryAction>
                <Switch
                  sx={BasicSwitchStyleOverrides(
                    user?.data?.settings?.themeColor
                  )}
                  //onClick={handleToggle("notifications", !settings?.notifications)}
                  checked={settings?.notifications}
                  disabled
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemIcon className="min-w-40">
                <Chat />
              </ListItemIcon>
              <ListItemText
                primary="Chat Sidebar"
                primaryTypographyProps={{ className: "text-lg" }}
              />
              <ListItemSecondaryAction>
                <Switch
                  sx={BasicSwitchStyleOverrides(
                    user?.data?.settings?.themeColor
                  )}
                  onClick={handleToggle("chatSidebar", !settings?.chatSidebar)}
                  checked={settings?.chatSidebar}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemIcon className="min-w-40">
                <DarkMode />
              </ListItemIcon>
              <ListItemText
                primary="Dark Theme"
                primaryTypographyProps={{ className: "text-lg" }}
              />
              <ListItemSecondaryAction>
                <Switch
                  sx={BasicSwitchStyleOverrides(
                    user?.data?.settings?.themeColor
                  )}
                  onClick={handleToggle("darkMode", !settings?.darkMode)}
                  checked={settings?.darkMode}
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        )}
      </Scrollbars>
    </StyledSwipeableDrawer>
  );
};

export default withReducer("quickPanel", reducer)(memo(QuickPanel));
