import {
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SearchBar from "components/header/SearchBar";
import { format } from "date-fns";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Root,
  StyledSwipeableDrawer,
  StyledTableCell,
  StyledTableRow,
} from "settings/styleOverrides";
import withReducer from "store/withReducer";
import _ from "utils/lodash";
import Utilities from "utils/utilities";
import AddBoardDialog from "../AddBoardDialog";
import reducer from "../store";
import {
  getBoards,
  selectBoards,
  toggleBoardFavourite,
} from "../store/boardsSlice";
import FilledHeart from "components/svgs/icons/general/FilledHeart";
import FeatherHeart from "components/svgs/icons/general/FeatherHeart";
import EmptyPanel from "components/display/EmptyPanel";
import GeneralSettingsSidebar from "components/header/GeneralSettingsSidebar";

const Boards = (props) => {
  const { projectData } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeParams = useParams();
  const [showCardView, setCardView] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(null);
  const [open, setOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showCompletedBoards, setShowCompletedBoards] = useState(false);
  // const [pageNumber, setPageNumber] = useState(0);
  // const [totalPages, setTotalPages] = useState(0);
  const containerRef = useRef();
  const projectId = routeParams.projectId;

  const user = useSelector(({ auth }) => auth.user);
  const boards = useSelector(selectBoards);
  const { loading } = useSelector((state) => state.scrumboardApp.boards);
  const disabled = Utilities.checkUserPermissionsForProject(
    projectData?.resources,
    user?.data?.email
  );

  useEffect(() => {
    if (!user.data.currentWorkspace) return;
    dispatch(getBoards({ user, projectId, showCompletedBoards }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.data.currentWorkspace, projectId, showCompletedBoards]);

  // TODO: For now do client side filtering
  useEffect(() => {
    setFilteredData(Utilities.getFilteredArray(boards, searchQuery));
  }, [boards, searchQuery]);

  const toggleFavourite = (e, boardData, favourites) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(
      toggleBoardFavourite({
        user,
        projectId,
        boardId: boardData?.id,
        boardData,
        favourites,
      })
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const rowClickFunc = (id) => {
    navigate(
      `/${user.data.currentWorkspace}/project/${projectId}/scrumboard/boards/${id}`
    );
  };

  const toggleSettingsDrawer = (state) => {
    setOpenDrawer(state === undefined ? !openDrawer : state);
  };

  const item = Utilities.itemOpacityConfig(20);

  if (!filteredData) {
    return null;
  }

  return (
    <Root
      header={
        <SearchBar
          setSearchQuery={setSearchQuery}
          searchQuery={searchQuery}
          showCardViewBtn={true}
          addBtnTxt={`${disabled ? "" : "Add board"}`}
          handleClickOpen={handleClickOpen}
          showCardView={showCardView}
          setCardView={setCardView}
          showSettingsbtn={true}
          toggleSettingsDrawer={toggleSettingsDrawer}
        />
      }
      content={
        <>
          {/* No Board Section */}
          {loading ? (
            <Box className="w-full flex items-center justify-center">
              <CircularProgress color="info" className="m-auto" />
            </Box>
          ) : !loading && boards?.length === 0 ? (
            <EmptyPanel
              mainClass={"mt-20"}
              mainText={"No scrumboards, yet?"}
              imgClass={""}
              subText={
                "Create a scrumboard, add team members, assign tasks and get started to organize your project."
              }
              imageSrc={"emptyBoard"}
              buttonText={disabled ? "" : "Create a board"}
              onBtnClick={handleClickOpen}
              imgAlt={"Empty Boards"}
              searchQuery={searchQuery}
            />
          ) : (
            <div>
              <motion.div
                variants={item}
                className="widget flex w-full px-2 md:px-10 flex-col pb-2"
              >
                {/* Table View Section */}
                {!showCardView && (
                  <TableContainer>
                    <Table
                      sx={{
                        minWidth: 900,
                        borderCollapse: "separate",
                        borderSpacing: "0px 1.5rem",
                      }}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell className="font-medium">
                            Board Name
                          </StyledTableCell>
                          <StyledTableCell className="font-medium">
                            Description
                          </StyledTableCell>
                          <StyledTableCell className="font-medium">
                            Last Activity
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            className="font-medium"
                          >
                            Favourites
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredData?.map((row) => (
                          <StyledTableRow
                            key={row.name}
                            onClick={() => rowClickFunc(row.id)}
                            className="cursor-pointer"
                          >
                            <StyledTableCell className="w-[25%]">
                              {row?.name}
                            </StyledTableCell>
                            <StyledTableCell className="border-l-[1px] w-[35%]">
                              {row?.description
                                ? _.truncate(row?.description, {
                                    length: 40,
                                  })
                                : "-"}
                            </StyledTableCell>
                            <StyledTableCell className="border-l-[1px] w-[25%]">
                              {row?.modifiedDate
                                ? Utilities.formatDate(
                                    row.modifiedDate,
                                    "yyyy-MM-dd hh:mm a",
                                    format
                                  )
                                : "-"}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className="border-l-[1px] w-[15%]"
                            >
                              <IconButton
                                className="mx-auto"
                                onClick={(e) =>
                                  toggleFavourite(
                                    e,
                                    row,
                                    !row?.settings?.favourites
                                  )
                                }
                              >
                                {row?.settings?.favourites ? (
                                  <FilledHeart />
                                ) : (
                                  <FeatherHeart />
                                )}
                              </IconButton>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}

                {/* Grid View Section */}
                {showCardView && (
                  <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:pb-8 py-8 py-7">
                    {filteredData?.map((row) => (
                      <Card
                        key={row?.name}
                        inlinestyle={{
                          background: "#FFFFFF 0% 0% no-repeat padding-box",
                          boxShadow: "0px 3px 6px #00B9D940",
                        }}
                        onClick={() => rowClickFunc(row?.id)}
                        className="flex flex-col p-7 gap-y-8 shadow-[0px_3px_5px_#00B9D91F] cursor-pointer"
                      >
                        <div className="flex justify-between items-center">
                          <div className="text-primaryBlue lg:text-lg md:text-base text-sm tracking-wide font-medium truncate">
                            {row?.name}
                          </div>
                          <IconButton
                            onClick={(e) =>
                              toggleFavourite(e, row, !row.settings?.favourites)
                            }
                          >
                            <Box className="mx-auto w-6 md:w-7">
                              {row?.settings?.favourites ? (
                                <FilledHeart />
                              ) : (
                                <FeatherHeart />
                              )}
                            </Box>
                          </IconButton>
                        </div>
                        <div className="sm:h-32 h-[4.8125rem] text-ellipsis overflow-hidden text-base flex md:text-[15px] sm:text-[10px] leading-loose text-cancelBlack">
                          {row?.description ? (
                            _.truncate(row?.description, {
                              length: 120,
                            })
                          ) : (
                            <div className="flex justify-center items-center mx-auto">
                              No Description Yet
                            </div>
                          )}
                        </div>
                        <div className="flex justify-between items-center">
                          <span className="lg:text-base md:text-sm truncate">
                            Last Activity:
                          </span>
                          <div
                            style={{
                              border: "0.75px solid #00B9D959",
                              borderRadius: "7px",
                            }}
                            className="lg:text-base md:text-sm truncate py-1 px-3 bg-primaryBlueLight"
                          >
                            {row?.modifiedDate
                              ? Utilities.formatDate(
                                  row.modifiedDate,
                                  "yyyy-MM-dd hh:mm a",
                                  format
                                )
                              : "-"}
                          </div>
                        </div>
                      </Card>
                    ))}
                  </section>
                )}

                {/* TODO: Pagination Section - Do not need at this point */}
                {/* {boards?.length > 10 && (
                  <Pagination
                    page={pageNumber}
                    totalPages={totalPages}
                    onNextPage={() => {
                      setPageNumber((curr) => curr + 1);
                    }}
                    onPreviousPage={() => {
                      setPageNumber((curr) => curr - 1);
                    }}
                  />
                )} */}
              </motion.div>
            </div>
          )}
          <StyledSwipeableDrawer
            anchor="right"
            className="overflow-hidden"
            classes={{
              paper: " w-[300px] sm:w-[420px]",
            }}
            BackdropProps={{
              classes: {
                root: "",
              },
            }}
            container={containerRef?.current}
            ModalProps={{
              keepMounted: true,
              // style: { position: "absolute" },
            }}
            open={openDrawer}
            onOpen={(ev) => {}}
            onClose={() => setOpenDrawer(false)}
            disableSwipeToOpen
          >
            <GeneralSettingsSidebar
              title="Use these toggles to update your boards settings"
              setShowCompletedBoards={setShowCompletedBoards}
              showCompletedBoards={showCompletedBoards}
              onClose={() => setOpenDrawer(false)}
              user={user}
            />
          </StyledSwipeableDrawer>
          <AddBoardDialog open={open} onClose={handleClose} />
        </>
      }
    ></Root>
  );
};

export default withReducer("scrumboardApp", reducer)(Boards);
