import SplashScreen from "core/SplashScreen";
import firebase from "services/firebase";
import { useState, createContext, useEffect, useContext } from "react";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { hideMessage, showMessage } from "store/slice/messageSlice";

import {
  setUserDataFirebase,
  setUserData,
  logoutUser,
} from "./store/userSlice";
import Utilities from "utils/utilities";
import { setIsNativeApp } from "store/slice/settingsSlice";

const AuthContext = createContext();

const Auth = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(undefined);
  const [waitAuthCheck, setWaitAuthCheck] = useState(true);
  const dispatch = useDispatch();
  const context = useContext(AuthContext);

  useEffect(() => {
    firebaseCheck();
    isNativeCheck();
  }, []);

  const isNativeCheck = async () => {
    const isNativeApp = await Utilities.isNativeApp();
    dispatch(setIsNativeApp(isNativeApp));
  };

  const firebaseCheck = () => {
    firebase.init((success) => {
      if (!success) {
        setWaitAuthCheck(false);
        return;
      }
      firebase.onAuthStateChanged((authUser) => {
        console.log("auth changes", authUser);

        if (authUser) {
          // TODO: show spinner
          firebase
            .getUserData(authUser.uid)
            .then((user) => {
              console.log("user info", authUser);
              dispatch(
                setUserDataFirebase({ user, authUser, setWaitAuthCheck })
              );

              // TODO: hide spinner
              //setWaitAuthCheck(false);
              setIsAuthenticated(true);
            })
            .catch((error) => {
              setWaitAuthCheck(false);
              setIsAuthenticated(false);
            });
        } else {
          setWaitAuthCheck(false);
          setIsAuthenticated(false);
        }
      });
    });
  };

  return waitAuthCheck ? (
    <SplashScreen />
  ) : (
    <AuthContext.Provider value={{ isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logout: logoutUser,
      setUserData,
      setUserDataFirebase,
      showMessage,
      hideMessage,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(Auth);
