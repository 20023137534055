const Members = () => {
  return (
    <svg
      id="Group_40740"
      data-name="Group 40740"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 29.285 27.23"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_17600"
            data-name="Rectangle 17600"
            width="29.285"
            height="27.23"
            fill="#6e6e6e"
          />
        </clipPath>
      </defs>
      <g
        id="Group_40602"
        data-name="Group 40602"
        transform="translate(0 0)"
        clipPath="url(clipPath)"
      >
        <path
          id="Path_34357"
          data-name="Path 34357"
          d="M24.018,51.183a.827.827,0,0,0-.4.159,3.215,3.215,0,0,1-3.708.009,1.134,1.134,0,0,0-.545-.173,6.751,6.751,0,0,0-3.825,1A4.9,4.9,0,0,0,13,55.879a11.7,11.7,0,0,0-.041,1.5c0,.209.007.417.005.625a.772.772,0,0,0,.132.566.774.774,0,0,0,.566.134H29.91c.512,0,.654-.141.658-.655,0-.272,0-.545,0-.817,0-.241,0-.483,0-.724A4.493,4.493,0,0,0,29.5,53.494a6.588,6.588,0,0,0-5.478-2.31m5.639,5.189V57.78H13.8c.017-.23.027-.46.037-.687a7.544,7.544,0,0,1,.2-1.708A4.364,4.364,0,0,1,17.1,52.447a7.931,7.931,0,0,1,1.581-.3c.154-.019.307-.038.459-.059a.75.75,0,0,1,.41.119,4.147,4.147,0,0,0,4.465-.035.554.554,0,0,1,.225-.089h0a5.584,5.584,0,0,1,4.668,2.141,3.413,3.413,0,0,1,.745,2.152"
          transform="translate(-7.965 -31.478)"
          fill="#6e6e6e"
        />
        <path
          id="Path_34358"
          data-name="Path 34358"
          d="M24.567,28.718a5.781,5.781,0,0,0,1.615,5.345,3.737,3.737,0,0,0,2.656,1.2H28.9a3.832,3.832,0,0,0,2.74-1.334,6.015,6.015,0,0,0,1.581-4.037v0a4.079,4.079,0,0,0-1.973-3.807,4.824,4.824,0,0,0-3.124-.563,3.908,3.908,0,0,0-3.552,3.2m4.1-2.337.174-.011a3.244,3.244,0,0,1,3.079,1.672,3.936,3.936,0,0,1,.329,2.528,4.964,4.964,0,0,1-1.454,2.912,2.626,2.626,0,0,1-3.895.012,4.869,4.869,0,0,1-1.4-4.764,3.011,3.011,0,0,1,2.8-2.324c.121-.012.243-.018.369-.026"
          transform="translate(-15.041 -15.665)"
          fill="#6e6e6e"
        />
        <path
          id="Path_34359"
          data-name="Path 34359"
          d="M9.55,23.71l0-.148H.916v-.184c0-.228,0-.445,0-.664a2.43,2.43,0,0,1,.563-1.61,3.96,3.96,0,0,1,3.21-1.453h0a.532.532,0,0,1,.2.082,3.14,3.14,0,0,0,3.273.022.61.61,0,0,1,.261-.1,3.9,3.9,0,0,1,3.155,1.426.659.659,0,0,0,.912.258l.263-.134-.082-.133c-.029-.048-.056-.095-.082-.139a2.5,2.5,0,0,0-.175-.274,4.868,4.868,0,0,0-4.182-1.906.943.943,0,0,0-.453.172,2.249,2.249,0,0,1-2.456.015,1.453,1.453,0,0,0-.689-.192,4.753,4.753,0,0,0-2.2.485,3.841,3.841,0,0,0-2.428,3.4C0,22.891,0,23.149,0,23.407l0,.321c0,.639.111.751.742.751H8.815c.595,0,.718-.129.736-.77"
          transform="translate(0 -11.531)"
          fill="#6e6e6e"
        />
        <path
          id="Path_34360"
          data-name="Path 34360"
          d="M55.863,32.771a5.25,5.25,0,0,0-4.169-1.839,1.29,1.29,0,0,0-.632.185,2.445,2.445,0,0,1-2.73,0l-.063-.042a.619.619,0,0,0-.292-.129,5.337,5.337,0,0,0-2.352.355l-.12.046.2.777.13-.021c.165-.026.326-.054.486-.08.415-.07.807-.136,1.2-.181a.746.746,0,0,1,.416.11A3.308,3.308,0,0,0,51.331,32a1.213,1.213,0,0,1,.747-.155,4.709,4.709,0,0,1,2.469.911,3.368,3.368,0,0,1,1.271,3.417H45.6c-.6,0-.736.1-.932.691l-.022.121.1.059a.209.209,0,0,0,.107.033l11.384,0a.5.5,0,0,0,.374-.133.522.522,0,0,0,.132-.389c0-.451-.008-.9,0-1.354a3.551,3.551,0,0,0-.884-2.425"
          transform="translate(-27.463 -19.023)"
          fill="#6e6e6e"
        />
        <path
          id="Path_34361"
          data-name="Path 34361"
          d="M50.328,17.9a2.739,2.739,0,0,0,3.3.158,4.486,4.486,0,0,0,2.02-3.594v-.007a3.943,3.943,0,0,0-.572-2.416,3.728,3.728,0,0,0-4.006-1.33,3.176,3.176,0,0,0-2.5,2.956A4.631,4.631,0,0,0,50.328,17.9M49.442,13.8A2.175,2.175,0,0,1,50.8,11.776a2.89,2.89,0,0,1,3.17.384,2.19,2.19,0,0,1,.751,1.572,3.938,3.938,0,0,1-.938,3,2.236,2.236,0,0,1-1.693.881h0a2.3,2.3,0,0,1-1.717-.9,4.4,4.4,0,0,1-.934-2.921"
          transform="translate(-29.86 -6.512)"
          fill="#6e6e6e"
        />
        <path
          id="Path_34362"
          data-name="Path 34362"
          d="M11.707,7.381a2.841,2.841,0,0,0,1.98-.851,4.4,4.4,0,0,0,1.3-2.728,3.477,3.477,0,0,0-.507-2.439A3.485,3.485,0,0,0,10.567.172,3.142,3.142,0,0,0,8.4,3.51v0A4.412,4.412,0,0,0,9.709,6.507a2.83,2.83,0,0,0,2,.874m0-.9H11.7a1.968,1.968,0,0,1-1.461-.736,3.483,3.483,0,0,1-.858-3.129,1.949,1.949,0,0,1,1.351-1.535A2.892,2.892,0,0,1,11.7.908a3.031,3.031,0,0,1,.9.139,2.04,2.04,0,0,1,1.531,1.819A4.064,4.064,0,0,1,13.2,5.732a2.018,2.018,0,0,1-1.493.746"
          transform="translate(-5.165 0)"
          fill="#6e6e6e"
        />
      </g>
    </svg>
  );
};

export default Members;
