import {
  Avatar,
  Button,
  Card,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import MemberDetails from "./MemberDetails";
import { toggleFollowMember } from "./store/membersSlice";
import Utilities from "utils/utilities";
import CardImage from "components/svgs/icons/general/CardImage";
import Profile from "components/svgs/icons/chat/Profile";
import { Box } from "@mui/system";

const MemberLists = (props) => {
  let prevAlphabet = "";
  const dispatch = useDispatch();
  const { showCardView, members, user, searchQuery } = props;
  const [aboutDrawer, setAboutDrawer] = useState(false);
  const [filteredData, setFilteredData] = useState(null);
  const [member, setMember] = useState({});

  useEffect(() => {
    if (members) {
      setFilteredData(Utilities.getFilteredArray(members, searchQuery));
    }
  }, [members, searchQuery]);

  if (!filteredData) {
    return null;
  }

  return (
    <>
      {showCardView ? (
        <div className="flex flex-row flex-wrap xs:justify-center md:justify-start align-center xs:px-2 md:px-6">
          {filteredData.map((member, idx) => {
            return (
              <Card
                className={`xs:w-[18em] md:w-[20em] xs:min-h-[24em] md:min-h-[27em] m-3 overflow-hidden rounded-lg`}
                key={idx}
              >
                <div className="relative ">
                  {member.coverPhotoURL && member.coverPhotoURL !== "" ? (
                    <img
                      className="h-[10em] w-full object-cover"
                      src={member.coverPhotoURL}
                      alt="avatar"
                    />
                  ) : (
                    <CardImage />
                  )}

                  <Box className="absolute xs:bottom-[-55px] md:bottom-[-70px] left-[26%] xs:w-[8em] xs:h-[8em] md:h-[10em] md:w-[10em] p-4 border-2 border-primaryBlue rounded-full">
                    {member.photoURL && member.photoURL !== "" ? (
                      <img
                        className="rounded-[40px]"
                        src={member.photoURL}
                        alt="avatar"
                      />
                    ) : (
                      <Profile />
                    )}
                  </Box>
                </div>
                <div className="flex flex-col justify-center align-center xs:mt-[75px] md:mt-[90px]">
                  <Typography className="flex justify-center align-center xs:text-base md:text-lg font-medium leading-relaxed">
                    {member.displayName}
                  </Typography>
                  <Typography className="flex justify-center align-center xs:text-sm md:text-base text-mute">
                    {member.email}
                  </Typography>
                </div>
                <div className="flex flex-row justify-center align-center mt-4 gap-x-2">
                  <Button
                    className="xs:w-[76px] md:w-[90px] xs:text-sm md:text-base bg-white border border-primaryBlue text-primaryBlue rounded-full"
                    variant="outlined"
                    onClick={() => {
                      setAboutDrawer(true);
                      setMember(member);
                    }}
                  >
                    About
                  </Button>
                  {member.id !== user.uid && (
                    <Button
                      onClick={() =>
                        dispatch(toggleFollowMember({ user, member }))
                      }
                      className="xs:w-[76px] md:w-[90px] xs:text-sm md:text-base bg-primaryBlue text-white rounded-full"
                      variant="contained"
                    >
                      {user?.data?.more?.friends?.includes(member.id)
                        ? "Following"
                        : "Follow"}
                    </Button>
                  )}
                </div>
              </Card>
            );
          })}
        </div>
      ) : (
        <>
          {filteredData
            .sort((a, b) => a.displayName.localeCompare(b.displayName))
            .map((member, idx) => {
              const curAlphabet = member.displayName[0].toLocaleUpperCase();
              let renderLetter = false;
              if (prevAlphabet != curAlphabet) {
                renderLetter = true;
                prevAlphabet = curAlphabet;
              }
              return (
                <List className="xs:m-2 md:m-4" key={idx}>
                  {renderLetter && (
                    <h3 className="text-primaryBlue text-4xl m-2">
                      {curAlphabet}
                    </h3>
                  )}
                  <ListItem className="m-0 p-0">
                    <Card
                      className={`xs:h-[75px] xs:px-[12px] md:px-[35px] flex flex-row justify-between align-center w-full overflow-hidden border-b-1 rounded-none`}
                    >
                      <div className="flex flex-row justify-center align-center">
                        <div className=" flex flex-col justify-center items-center">
                          <Avatar
                            src={member.photoURL}
                            alt="avatar"
                            className="xs:w-[36px] xs:h-[36px] md:w-[48px] md:h-[48px] rounded-full"
                            style={{ backgroundColor: member.more?.color }}
                          >
                            {member.displayName &&
                              member.displayName[0]?.toLocaleUpperCase()}
                          </Avatar>
                        </div>
                        <div className="flex flex-col xs:ml-[8px] md:ml-[32px] justify-center">
                          <Typography className="xs:text-md md:text-xl font-medium leading-relaxed xs:max-w-[10em] md:max-w-[12em] xs:truncate md:whitespace-normal">
                            {member.displayName}
                          </Typography>
                          <Typography className="xs:text-xs md:text-md leading-relaxed text-mute">
                            {member.email}
                          </Typography>
                        </div>
                      </div>
                      <div className="flex flex-row justify-center items-center">
                        <Button
                          className="m-1 xs:text-xs md:text-xl xs:min-w-[42px] md:min-w-[108px] bg-white border border-primaryBlue text-primaryBlue rounded-full"
                          variant="outlined"
                          onClick={() => {
                            setAboutDrawer(true);
                            setMember(member);
                          }}
                        >
                          About
                        </Button>
                        {member.id !== user.uid && (
                          <Button
                            onClick={() =>
                              dispatch(toggleFollowMember({ user, member }))
                            }
                            className="m-1 xs:text-xs md:text-xl xs:min-w-[44px] md:min-w-[108px] bg-primaryBlue text-white rounded-full"
                            variant="contained"
                          >
                            {user?.data?.more?.friends?.includes(member.id)
                              ? "Following"
                              : "Follow"}
                          </Button>
                        )}
                      </div>
                    </Card>
                  </ListItem>
                </List>
              );
            })}
        </>
      )}
      {aboutDrawer && (
        <MemberDetails
          member={member}
          aboutDrawer={aboutDrawer}
          setAboutDrawer={setAboutDrawer}
        />
      )}
    </>
  );
};

export default MemberLists;
