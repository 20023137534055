import LockIcon from "@mui/icons-material/Lock";
import ConfirmDeleteDialog from "components/modals/ConfirmDeleteDialog";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTrackerRow,
  saveTrackerRows,
  setHours,
} from "./store/trackerSlice";
import Utilities from "utils/utilities";
import { Box } from "@mui/system";
import Close from "components/svgs/icons/general/Close";
import Save from "components/svgs/icons/general/Save";

const EachRow = (props) => {
  const {
    eachRow,
    rowNo,
    status,
    user,
    rows,
    currentYear,
    currentWeekNo,
    userId,
    hoverHandler,
    showWeekends,
  } = props;
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [forceRender, setForceRender] = useState(false);

  const dispatch = useDispatch();
  const settings = useSelector(({ entities }) => entities.settings.current);
  const cellsSum = useMemo(
    () => eachRow.cells.reduce((acc, c) => acc + Number(c), 0),
    [eachRow]
  );
  const STATUS = Utilities.getTrackerStatus();

  const deleteRowHandler = () => {
    if (status === STATUS.APPROVED) return;
    dispatch(
      deleteTrackerRow({
        userId,
        user,
        rows,
        currentYear,
        currentWeekNo,
        status,
        rowNo,
      })
    );
  };

  // Trick to re-render the component
  useEffect(() => {
    setForceRender(showWeekends);
  }, [showWeekends]);

  return (
    <div className="boxRow flex items-center py-4 h-88 md:w-full w-870">
      <div
        className="pl-8"
        style={{
          minWidth: "220px",
          maxWidth: "220px",
        }}
      >
        <p className={`${settings.darkMode && "text-slate-300"}`}>
          <span className="font-bold mr-8 text-lg leading-7 font-medium">
            {eachRow?.projectName}
          </span>
        </p>
        <p
          className={`${
            settings.darkMode && "text-slate-300"
          } text-lg font-medium leading-7`}
        >
          {eachRow?.taskName}
        </p>
      </div>
      <div className="flex h-full ml-[5%] justify-start">
        <div className="flex h-full flex items-center h-full">
          {eachRow.cells
            .filter((x, idx) => {
              if (showWeekends) return true;
              else return idx < 5;
            })
            .map((eachCell, index) => {
              return (
                <div
                  key={index}
                  className={`px-4 ${
                    settings.darkMode ? "hover:bg-slate-700" : ""
                  } h-full flex items-center`}
                  onMouseEnter={() => hoverHandler(index)}
                  onMouseLeave={() => hoverHandler(null)}
                >
                  <input
                    disabled={
                      ![STATUS.INITIAL, STATUS.REJECTED].includes(status) ||
                      user.data.id !== userId
                    }
                    type="number"
                    className={`trackerInput ${
                      settings.darkMode
                        ? "bg-zinc-800 border-slate-600"
                        : "border-slate-300"
                    } w-24 leading-3 p-3 text-lg border-2 text-center rounded-md ${
                      ![STATUS.INITIAL, STATUS.REJECTED].includes(status) ||
                      user.data.id !== userId
                        ? "bg-lightGrayTransparent"
                        : ""
                    }`}
                    value={eachCell}
                    onChange={(e) => {
                      if (e.target.value < 0) return;
                      dispatch(
                        setHours({
                          rowNo,
                          cellNo: index,
                          value: e.target.value,
                        })
                      );
                    }}
                  />
                </div>
              );
            })}
        </div>
      </div>
      <div className="flex justify-center items-center ml-12 w-84">
        <p
          className={`text-2xl font-medium mr-8 w-[25px] ${
            settings.darkMode && "text-slate-300"
          }`}
        >
          {cellsSum}
        </p>
        {status === STATUS.APPROVED && <LockIcon className="text-mute/50" />}
        {(status === STATUS.INITIAL || status === STATUS.REJECTED) &&
          user.data.id === userId && (
            <>
              <Box
                className="py-8 w-12 h-[6.7em] cursor-pointer"
                onClick={() =>
                  dispatch(
                    saveTrackerRows({
                      userId,
                      user,
                      rows,
                      currentYear,
                      currentWeekNo,
                      status,
                      onlySave: true,
                    })
                  )
                }
              >
                <Save />
              </Box>
              <Box
                className={`py-8 px-2 mr-4 cursor-pointer object-none`}
                onClick={() => setConfirmDialog(true)}
              >
                <Close width={32} height={22} color="#FF0000" />
              </Box>
              {confirmDialog && (
                <ConfirmDeleteDialog
                  open={confirmDialog}
                  onClose={() => setConfirmDialog(false)}
                  deleteFunc={deleteRowHandler}
                />
              )}
            </>
          )}
      </div>
    </div>
  );
};

export default EachRow;
