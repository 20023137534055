import { createSlice } from "@reduxjs/toolkit";
import { signUp } from "api/signUp";
import firebase from "services/firebase";
import { setIsPopupLoading, submitLoginWithFireBase } from "./loginSlice";
import { createUserSettingsFirebase } from "./userSlice";

export const registerWithFirebase = (model) => async (dispatch) => {
  if (!firebase.auth) {
    console.warn(
      "Firebase Service didn't initialize, check your configuration"
    );

    return () => false;
  }
  const { email, password, displayName, uid } = model;
  const params = new URLSearchParams(window.location.search);
  const body = {
    email: email,
    password: password,
    displayName: displayName,
    id: params.get("id"),
    code: params.get("code"),
    referralKey: params.get("referralKey"),
    uid,
  };

  return signUp(body)
    .then((response) => {
      if (response.body.error) {
        dispatch(registerError(response.body.error));
        return;
      }
      dispatch(
        createUserSettingsFirebase({
          ...response.body.user,
          displayName,
          uid: response.body.user.uid,
          email,
          workspaces: response.body.workspaces,
        })
      );

      if (password) dispatch(submitLoginWithFireBase({ email, password }));
      dispatch(setIsPopupLoading(false));
      return dispatch(registerSuccess(email));
    })
    .catch((error) => {
      dispatch(setIsPopupLoading(false));
      return dispatch(registerError(error));
    });
};

const initialState = {
  success: false,
  errors: [],
};

const registerSlice = createSlice({
  name: "auth/register",
  initialState,
  reducers: {
    registerSuccess: (state, action) => {
      state.success = true;
      state.email = action.payload;
      state.errors = [];
    },
    registerError: (state, action) => {
      state.success = false;
      state.errors = action.payload;
    },
  },
  extraReducers: {},
});

export const { registerSuccess, registerError } = registerSlice.actions;

export default registerSlice.reducer;
