import PropTypes from "prop-types";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  prependNavigationItem,
  removeNavigationItem,
  resetNavigation,
} from "store/slice/navigationSlice";
import { selectProjects } from "views/projects/store/projectsSlice";
import NavItem from "../../NavItem";

const NavVerticalGroup = (props) => {
  const { item, nestedLevel, onItemClick } = props;

  const user = useSelector(({ auth }) => auth.user);
  const projects = useSelector(selectProjects);
  const dispatch = useDispatch();

  const setProjectNav = () => {
    projects.forEach((item) => {
      // IMPORTANT: Confirmed, we still need this dispatch to remove nav item, and add it again
      // as sometimes we do not see projects in navbar for some reason
      dispatch(removeNavigationItem(item.id));
      if (item?.showInNavbar) {
        dispatch(
          prependNavigationItem(
            {
              id: `${item.id}`,
              title: `${item.name}`,
              type: "item",
              icon: `${item.color}`,
              url: `/:workspaceId/project/${item.id}`,
              target: "_blank",
            },
            "projects"
          )
        );
      }
    });
  };

  useEffect(() => {
    dispatch(resetNavigation());
    setProjectNav();

    if (user?.role?.includes("owner")) return;
    dispatch(removeNavigationItem("addProject"));
  }, [projects]);

  const itempadding = nestedLevel > 0 ? 28 + nestedLevel * 16 : 12;

  return useMemo(
    () => (
      <>
        {item.children && (
          <>
            {item.children.map((_item, index) => {
              if (_item.url && user.data.currentWorkspace) {
                _item.url = _item.url.replace(
                  ":workspaceId",
                  user.data.currentWorkspace
                );
              }
              return (
                <NavItem
                  key={index}
                  type={`vertical-${_item.type}`}
                  item={_item}
                  nestedLevel={nestedLevel}
                  onItemClick={onItemClick}
                />
              );
            })}
          </>
        )}
      </>
    ),
    [item, itempadding, nestedLevel, onItemClick]
  );
};

NavVerticalGroup.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    children: PropTypes.array,
  }),
};

NavVerticalGroup.defaultProps = {};

export default NavVerticalGroup;
