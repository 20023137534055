import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import firebase from "services/firebase";
import Utilities from "utils/utilities";

export const getTaskLabels = createAsyncThunk(
  "taskApp/labels/getTaskLabels",
  async (user) => {
    return await firebase.getTaskLabels(user);
  }
);

export const updateTaskLabels = createAsyncThunk(
  "taskApp/labels/updateTaskLabels",
  async ({ user, _labels }, { dispatch }) => {
    const labels = [];
    Object.values(_labels).forEach((label) => {
      labels.push(label);
    });
    await firebase.setTaskLabels(user, labels);

    const message = " has updated the label ";
    Utilities.successNotification(user, message, "for tasks", dispatch);
    return _labels;
  }
);

const labelsAdapter = createEntityAdapter({});

export const {
  selectAll: selectLabels,
  selectEntities: selectTaskLabelsEntities,
  selectById: selectLabelById,
} = labelsAdapter.getSelectors((state) => state.taskApp?.labels);

const labelsSlice = createSlice({
  name: "taskApp/labels",
  initialState: labelsAdapter.getInitialState({}),
  reducers: {},
  extraReducers: {
    [getTaskLabels.fulfilled]: labelsAdapter.setAll,
    [updateTaskLabels.fulfilled]: labelsAdapter.setAll,
  },
});

export default labelsSlice.reducer;
