import { Close } from "@mui/icons-material";
import CloseSvg from "components/svgs/icons/general/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DoneIcon from "@mui/icons-material/Done";
import {
  Alert,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Modal,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { Box } from "@mui/system";
import { searchHandler } from "api/algoliaSearch";
import { approveReject } from "api/approveReject";
import WidgetWrapper from "components/WidgetWrapper";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Root,
  StyledScrollbar,
  StyledSwipeableDrawer,
  modalstyle,
  useStyledFormInputStyles,
} from "settings/styleOverrides";
import { navbarClose } from "store/slice/navbarSlice";
import withReducer from "store/withReducer";
import _ from "utils/lodash";
import Utilities from "utils/utilities";
import { selectMembersEntities } from "views/members/store/membersSlice";
import { selectProjects } from "views/projects/store/projectsSlice";
import EachRow from "./EachRow";
import TrackerBar from "./TrackerBar";
import NewRowModal from "./dialogs/NewRowModal";
import TrackerSettingsSidebar from "./dialogs/TrackerSettingsSidebar";
import trackerSlice, {
  getFirstDayOfWeek,
  getTrackerData,
  goBack,
  resetState,
  resetTrackerFilter,
  resubmitTrackerWeek,
  setDec,
  setLoading,
  setOpenTrackerAsDialog,
  setReason,
  setStatus,
  setTrackerFilter,
  submitTrackerWeek,
} from "./store/trackerSlice";

const TrackerApp = (props) => {
  const theme = useTheme();
  const classes = useStyledFormInputStyles();
  const projects = useSelector(selectProjects);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const MEMBER_ID = params.get("userId");
  const {
    dec,
    currentWeek,
    rows,
    currentYear,
    currentWeekNo,
    status,
    loading,
    filter,
    reason,
    openTrackerAsDialog,
  } = useSelector((state) => state.trackerApp);
  const darkMode = useSelector(
    ({ entities }) => entities.settings.current.darkMode
  );
  const navbarState = useSelector(({ entities }) => entities.navbar.open);
  const STATUS = Utilities.getTrackerStatus();

  const containerRef = useRef(null);
  const decRef = useRef(dec);
  const user = useSelector(({ auth }) => auth.user);
  const members = useSelector(selectMembersEntities);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [userId, setUserId] = useState(MEMBER_ID || user.uid);
  const [hovering, setHovering] = useState(null);
  const [error, setError] = useState(false);
  const [comments, setComments] = useState("");
  const [showCal, setShowCal] = useState(false);
  const [showWeekends, setShowWeekends] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const [settingsDrawerOpen, setSettingsDrawerOpen] = useState(false);

  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(() => {
    if (
      user.data?.currentWorkspace &&
      userId &&
      currentYear &&
      currentWeekNo &&
      filter.length === 0
    ) {
      dispatch(getTrackerData({ userId, user, currentWeekNo, currentYear }));
    }
  }, [user.data?.currentWorkspace, userId, currentYear, currentWeekNo]);

  useEffect(() => {
    if (filter?.length === 0) {
      setFilteredRows(rows);
      return;
    }

    filter.forEach((x) => {
      if (x.label === "member") {
        changeMemberHandler(x.id);
        dispatch(
          getTrackerData({ userId: x.id, user, currentWeekNo, currentYear })
        );
      }
    });
  }, [filter, currentYear, currentWeekNo]);

  useEffect(() => {
    setFilteredRows(rows);
  }, [rows]);

  useEffect(() => {
    if (!MEMBER_ID || Object.keys(members).length === 0) return;
    const member = Utilities.findMemberById(members, MEMBER_ID);
    dispatch(
      setTrackerFilter([
        {
          label: "member",
          operator: "==",
          value: member.name,
          id: MEMBER_ID,
          color: member.color,
        },
      ])
    );
  }, [members]);

  useEffect(() => {
    if (showWeekends) dispatch(navbarClose());
    return () => {
      dispatch(resetTrackerFilter());
    };
  }, []);

  const submitBtn =
    user.role.includes("owner") && status !== STATUS.APPROVED
      ? "Approve"
      : "Submit week";

  const hoverHandler = (index) => {
    setHovering(index);
  };

  const successValidation = (status) => {
    dispatch(setStatus(status));
    dispatch(setLoading(false));

    const member = Utilities.findMemberById(members, userId);
    const approved = status === STATUS.APPROVED;
    const preHighlightedMessage = approved ? " has Approved" : " has Rejected";
    const preHighlightedClass = `ml-1 text-white ${
      approved ? "bg-green-500" : "bg-red-500"
    } rounded-full px-2 py-1`;
    const message = " the tracker entries for ";
    const postHighlightedMessage = member.name;

    Utilities.successNotification(
      user,
      message,
      postHighlightedMessage,
      dispatch,
      preHighlightedMessage,
      preHighlightedClass
    );
  };

  const changeMemberHandler = (id) => {
    setUserId(id);
  };

  const handleDateChange = (e) => {
    let decrementor = Math.trunc(
      (getFirstDayOfWeek(0) - getFirstDayOfWeek(0, e)) / 86400000
    );
    if (decrementor < 0 || decrementor === decRef.current) return;
    setSelectedDate(e);
    decRef.current = decrementor;
    dispatch(setDec(decRef.current));
    dispatch(goBack(decRef.current));
  };

  const submitWeek = async () => {
    if (![STATUS.INITIAL, STATUS.REJECTED].includes(status)) return;
    const requiresTimeApproval = await checkIfAnyProjectRequiresTimeApproval();
    dispatch(
      submitTrackerWeek({
        requiresTimeApproval,
        userId,
        user,
        rows,
        currentYear,
        currentWeekNo,
        dec: decRef.current,
        currentWeek,
      })
    );
  };

  const checkIfAnyProjectRequiresTimeApproval = async () => {
    const projectIds = rows.map((x) => x.projectId);
    const requiresTimeInterval = projects.filter(
      (x) => projectIds.includes(x.id) && x.timeApproval
    );
    if (requiresTimeInterval.length > 0) return true;

    // Else double check from algolia
    const facetFilters = [];
    projectIds.forEach((id) => facetFilters.push(`id:${id}`));
    const body = {
      filters: `objectType:project`,
      facetFilters: facetFilters,
      hits_per_page: projectIds.length,
      query: "",
      workspaceId: user?.data?.currentWorkspace,
    };
    const response = await searchHandler(body);
    return response?.body?.hits.filter((x) => x.timeApproval).length > 0;
  };

  const reject = async () => {
    if (comments.trim().length === 0) {
      setError(true);
      return;
    }
    dispatch(setLoading(true));
    await approveReject({
      userId,
      year: currentYear,
      weekNo: currentWeekNo,
      status: STATUS.REJECTED,
      workspaceId: user.data.currentWorkspace,
      reason: comments,
    });
    successValidation(STATUS.REJECTED);
    dispatch(setReason(comments));
    clearParams();
  };

  const approve = async () => {
    dispatch(setLoading(true));
    await approveReject({
      userId,
      year: currentYear,
      weekNo: currentWeekNo,
      status: STATUS.APPROVED,
      workspaceId: user.data.currentWorkspace,
      reason: comments,
      selfApproval: user.role.includes("owner") && user.uid === userId,
    });
    dispatch(setReason(comments));
    successValidation(STATUS.APPROVED);
    clearParams();
  };

  const clearParams = () => {
    if (!MEMBER_ID) return;
    navigate(`/${user?.data?.currentWorkspace}/tracker`);
    dispatch(resetState());
  };

  const handleClose = () => {
    dispatch(setOpenTrackerAsDialog(false));
    dispatch(resetState());
  };

  const resubmitWeek = async () => {
    // Change status back to initial so hours can be re-submitted once again
    dispatch(
      resubmitTrackerWeek({ user, userId, rows, currentYear, currentWeekNo })
    );
  };

  const revokeApproval = async () => {
    // We can call resubmit week function as it will set the status back to initial
    resubmitWeek();
  };

  const dayWiseSum = useMemo(
    () =>
      new Array(showWeekends ? 7 : 5)
        .fill(0)
        .map((_, index) =>
          rows.reduce((acc, row) => acc + Number(row.cells[index]), 0)
        ),
    [rows, showWeekends]
  );

  const sumOfSum = useMemo(
    () =>
      rows.reduce(
        (acc, row) => acc + row.cells.reduce((a, cell) => a + Number(cell), 0),
        0
      ),
    [rows]
  );

  const toggleSettingsDrawer = (state) => {
    setSettingsDrawerOpen(state === undefined ? !settingsDrawerOpen : state);
  };

  const renderTimeTracking = () => (
    <div className="m-6">
      {filteredRows.length > 0 &&
        user?.role?.includes("owner") &&
        user.uid !== userId &&
        status === STATUS.INITIAL && (
          <Alert severity={"warning"}>
            These hours have not yet being submitted. Are you sure you want to
            approve them?
          </Alert>
        )}

      {filteredRows.length > 0 && reason && (
        <Alert severity={`${status === STATUS.APPROVED ? "success" : "error"}`}>
          {reason}
        </Alert>
      )}

      <StyledScrollbar className="overflow-scroll draggableCancel">
        <Box
          className="card-content"
          sx={{ width: !navbarState || showWeekends ? "94.5rem" : "80rem" }}
        >
          <div className="flex w-full justify-between xs:px-2 md:px-6 mt-8">
            <div className="flex items-center gap-x-4">
              <div
                className={`flex ${
                  !!MEMBER_ID ? "bg-blueTransparent" : "bg-primaryBlue"
                }`}
              >
                <IconButton
                  className={`border-1 cursor-pointer !text-white ${
                    !darkMode ? "border-slate-300 " : "border-slate-500"
                  } rounded-l-lg w-8 h-8`}
                  disabled={!!MEMBER_ID}
                  onClick={() => {
                    decRef.current += 7;
                    setComments("");
                    dispatch(setDec(decRef.current));
                    dispatch(goBack(decRef.current));
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
              </div>
              <Typography className="xs:text-sm md:text-base leading-8 font-medium">
                <span className="font-bold">
                  {decRef.current === 0 ? "Current Week: " : ""}
                  {currentWeek[0].Date.date} {currentWeek[0].Date.month} -{" "}
                  {currentWeek[6].Date.date} {currentWeek[6].Date.month}{" "}
                  {currentYear}
                </span>{" "}
              </Typography>
              <div
                className={`flex ${
                  decRef.current === 0 || !!MEMBER_ID
                    ? "bg-blueTransparent"
                    : "bg-primaryBlue"
                } `}
              >
                <IconButton
                  disabled={!!MEMBER_ID}
                  onClick={() => {
                    if (decRef.current === 0) return;
                    decRef.current -= 7;
                    setComments("");
                    dispatch(setDec(decRef.current));
                    dispatch(goBack(decRef.current));
                  }}
                  className={`border-1 cursor-pointer !text-white ${
                    !darkMode ? "border-slate-300 " : "border-slate-500 "
                  } rounded-r-lg w-8 h-8 `}
                >
                  <ArrowForwardIcon />
                </IconButton>
              </div>
            </div>
            {status != STATUS.INITIAL && (
              <div
                className={`${
                  status === STATUS.APPROVED
                    ? "bg-green-500"
                    : status === STATUS.REJECTED
                    ? "bg-red-500"
                    : "bg-yellowBg"
                } rounded-full py-2 px-5`}
              >
                <Typography className="text-white">
                  {_.capitalize(status)}
                </Typography>
              </div>
            )}
          </div>
          <div
            className={`rounded-t-xl ${
              darkMode ? "bg-primaryBlue" : "bg-primaryBlue"
            } flex justify-center h-68 ${
              darkMode ? "border-slate-900" : "border-slate-300"
            } mt-8 border-2 md:w-full w-900 py-5`}
          >
            <div className="datesRow flex gap-x-4  justify-start md:justify-center text-base h-full md:w-full w-870">
              {currentWeek
                .filter((x) => {
                  if (showWeekends) return x;
                  else return x.Day != "Sat" && x.Day != "Sun";
                })
                .map((item, index) => (
                  <div
                    key={index}
                    className={`flex items-center font-medium ${
                      darkMode ? "text-slate-600" : ""
                    } w-28 justify-center`}
                  >
                    <p
                      className={`w-full
                ${!darkMode && index !== hovering && "text-slate-100"}
                ${!darkMode && index === hovering && "text-red-100"}
                ${darkMode && index !== hovering && "text-slate-200"}
                ${darkMode && index === hovering && "text-rose-300"}`}
                    >
                      {item.Day}
                      <br />
                      {item.Date.month} {item.Date.date}
                    </p>
                  </div>
                ))}
            </div>
            {/* <div className="md:w-84"></div> */}
          </div>
          <div
            className={`border-b-2 border-x-2 rounded-b-xl pb-8 w-full ${
              darkMode ? "border-slate-900" : "border-slate-300 bg-white"
            } `}
          >
            {filteredRows.length > 0 ? (
              filteredRows?.map((eachRow, index) => (
                <EachRow
                  user={user}
                  userId={userId}
                  rows={filteredRows}
                  currentYear={currentYear}
                  currentWeekNo={currentWeekNo}
                  status={status}
                  key={index}
                  rowNo={index}
                  eachRow={eachRow}
                  hoverHandler={hoverHandler}
                  showWeekends={showWeekends}
                />
              ))
            ) : (
              <div className="h-64 flex items-center justify-center">
                <Typography variant="h5" className="text-bold text-slate-500">
                  Add new row to log time
                </Typography>
              </div>
            )}
            <Divider />
            <div className="zeroRow flex items-end h-88 md:w-full w-870">
              <div
                className="flex items-center"
                style={{
                  minWidth: "220px",
                  maxWidth: "220px",
                }}
              >
                {user.data.id === userId &&
                  (status === STATUS.INITIAL || status === STATUS.REJECTED) && (
                    <NewRowModal darkMode={darkMode} classes={classes} />
                  )}
              </div>

              <div className="zeroRow w-full md:justify-center justify-start">
                <div className="ml-[5%] flex gap-x-4 text-base h-full">
                  {dayWiseSum.map((each, index) => (
                    <div
                      key={index}
                      className="flex items-center font-medium text-slate-600 h-full w-28 md:w-28 justify-center"
                    >
                      <p
                        className={`mt-7 ${
                          darkMode && "text-slate-300"
                        } text-center font-bold`}
                      >
                        {each}
                      </p>
                    </div>
                  ))}
                  <div className="flex gap-x-2 items-center font-medium text-slate-600 h-full w-32 md:w-32 px-4 justify-center md:justify-center">
                    <p
                      className={`mt-7 ${
                        darkMode && "text-slate-300"
                      } text-center font-bold`}
                    >
                      <span>Sum: {sumOfSum}</span>
                    </p>
                  </div>
                  {filteredRows.length !== 0 &&
                    (status === STATUS.INITIAL ||
                      status === STATUS.REJECTED) && (
                      <div className="flex items-center">
                        <Button
                          className="bg-primaryBlue hover:bg-primaryBlueLight rounded-none text-white min-w-[8em] -mb-[22%] text-lg"
                          onClick={() => submitWeek()}
                        >
                          {submitBtn}
                        </Button>
                      </div>
                    )}
                  {filteredRows.length !== 0 &&
                    status === STATUS.PENDING &&
                    user.uid === userId && (
                      <div className="flex items-center">
                        <Button
                          variant="outlined"
                          className={`rounded-md border-none min-w-[8em] -mb-[22%] text-lg ${
                            darkMode && "text-white border-slate-50"
                          }`}
                          onClick={() => resubmitWeek()}
                        >
                          Re-submit Week
                        </Button>
                      </div>
                    )}
                  {filteredRows.length !== 0 &&
                    status === STATUS.APPROVED &&
                    user?.role?.includes("owner") && (
                      <div className="flex items-center">
                        <Button
                          variant="outlined"
                          className={`rounded-md border-none min-w-[8em] -mb-[22%] text-lg ${
                            darkMode
                              ? "text-white border-slate-50"
                              : "text-primaryBlue"
                          }`}
                          onClick={() => revokeApproval()}
                        >
                          Revoke Approval
                        </Button>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
          {filteredRows.length !== 0 && (
            <div className="flex my-8 justify-end md:w-full w-870">
              <div className="mt-4 w-full">
                {user?.role?.includes("owner") &&
                  userId !== user.uid &&
                  status === STATUS.CHECKING && (
                    <>
                      <div className="field-container">
                        <Typography className="mb-4 text-grayText font-medium text-lg">
                          Comments
                        </Typography>
                        <TextField
                          sx={{ backgroundColor: "white" }}
                          placeholder="Write your comment here..."
                          id="outlined-basic"
                          size="small"
                          variant="outlined"
                          name="description"
                          autoComplete="off"
                          fullWidth
                          multiline
                          rows={5}
                          value={comments}
                          onChange={(e) => {
                            if (e.target.value.trim().length > 0 && error)
                              setError(false);
                            setComments(e.target.value);
                          }}
                          error={error}
                          helperText={
                            error && "Kindly enter comments before rejecting."
                          }
                          InputProps={{
                            className: classes.inputTextArea,
                          }}
                          InputLabelProps={{
                            shrink: true,
                            className: classes.label,
                          }}
                        />
                      </div>
                      <div className="flex w-full gap-x-4 justify-center mt-8">
                        <Button
                          className={`bg-dndText hover:bg-dndText/70 min-w-[7.5em] rounded-none text-white px-2 py-1`}
                          variant="contained"
                          onClick={() => reject()}
                        >
                          <Close className="text-2xl mr-2" />
                          Reject
                        </Button>
                        <Button
                          className={`bg-lightGreen hover:bg-lightGreen/70 min-w-[7.5em] rounded-none text-white px-2 py-1`}
                          variant="contained"
                          onClick={() => approve()}
                        >
                          <DoneIcon className="text-2xl mr-2" />
                          Approve
                        </Button>
                      </div>
                    </>
                  )}
              </div>
            </div>
          )}
        </Box>
      </StyledScrollbar>

      <StyledSwipeableDrawer
        anchor="right"
        className="overflow-hidden"
        classes={{
          paper: " w-[300px] sm:w-[420px]",
        }}
        BackdropProps={{
          classes: {
            root: "",
          },
        }}
        container={containerRef.current}
        ModalProps={{
          keepMounted: true,
          // style: { position: "absolute" },
        }}
        open={settingsDrawerOpen}
        onOpen={(ev) => {}}
        onClose={() => toggleSettingsDrawer(false)}
        disableSwipeToOpen
      >
        <TrackerSettingsSidebar
          toggleSettingsDrawer={toggleSettingsDrawer}
          setShowWeekends={setShowWeekends}
          showWeekends={showWeekends}
        />
      </StyledSwipeableDrawer>
    </div>
  );

  if (props?.widget) {
    return <WidgetWrapper {...props}>{renderTimeTracking()}</WidgetWrapper>;
  }

  if (openTrackerAsDialog) {
    const style = modalstyle();
    style.width = isSmall ? "90%" : mdDown ? "80%" : lgDown ? "70%" : "70%";

    return (
      <Modal
        BackdropProps={{
          style: {
            backgroundColor: "grey",
            opacity: "0.4",
            maxHeight: style.width,
          },
        }}
        open={openTrackerAsDialog}
        onClose={handleClose}
        aria-labelledby="modal-link-task"
        aria-describedby="modal-link-task"
      >
        <Box sx={style} className="modal-container flex flex-col my-5">
          <div className="modal-header bg-primaryBlue h-[75px] flex flex-col justify-center">
            <div className="flex justify-between px-8">
              <Typography className="text-white text-[20px]">
                Log Hours
              </Typography>
              <Box onClick={handleClose} className="cursor-pointer">
                <CloseSvg />
              </Box>
            </div>
          </div>
          <div
            className={`content-container ${
              darkMode ? "bg-darkSmoke" : "bg-lightBg"
            } h-full`}
          >
            {renderTimeTracking()}
          </div>
        </Box>
      </Modal>
    );
  }

  return (
    <>
      {loading && <CircularProgress color="info" className="m-auto" />}
      {!loading && (
        <Root
          header={
            <TrackerBar
              handleDateChange={handleDateChange}
              showCal={showCal}
              setShowCal={setShowCal}
              selectedDate={selectedDate}
              openFilterBarByDefault={!!MEMBER_ID}
              toggleSettingsDrawer={toggleSettingsDrawer}
              user={user}
              showJumpto={true}
              searchByOnlyOneUser={true}
              redirectBtnTitle={"report"}
            />
          }
          content={<>{renderTimeTracking()}</>}
        />
      )}
    </>
  );
};

export default withReducer("trackerApp", trackerSlice)(TrackerApp);
