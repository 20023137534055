import { ThemeProvider } from "@mui/material/styles";
import { memo, useEffect, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { selectMainTheme } from "store/slice/settingsSlice";

const useEnhancedEffect =
  typeof window === "undefined" ? useEffect : useLayoutEffect;

const Theme = (props) => {
  const direction = useSelector(
    ({ entities }) => entities.settings.defaults.direction
  );
  const mainTheme = useSelector(selectMainTheme);

  useEnhancedEffect(() => {
    document.body.dir = direction;
  }, [direction]);

  // console.warn('Theme:: rendered',mainTheme);
  return <ThemeProvider theme={mainTheme}>{props.children}</ThemeProvider>;
};

export default memo(Theme);
