const Sprint = () => {
  return (
    <svg
      id="Group_40692"
      data-name="Group 40692"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 17.114 23.796"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_17654"
            data-name="Rectangle 17654"
            width="17.114"
            height="23.796"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g
        id="Group_40691"
        data-name="Group 40691"
        transform="translate(0 0)"
        clipPath="url(clipPath)"
      >
        <path
          id="Path_34420"
          data-name="Path 34420"
          d="M14.574.046l-3.2,4.57,1.718.121-.129,2.555-.349,2.424a3.97,3.97,0,0,1-.571,1.635,4.132,4.132,0,0,1-3.479,2.025A4.146,4.146,0,0,1,5.113,11.35a4,4,0,0,1-.567-1.635l-.339-2.41L4.082,5.173c-.009-.144-.023-.283-.032-.413l1.774-.125L2.573,0,0,5.043,2.007,4.9v.009l.079,1.045c.027.352.056.7.088,1.054v.005l.033.292v.163l.018-.005.237,2.174.056.367a6.2,6.2,0,0,0,4.56,5.206c.069.009.167.023.288.042V23.8H9.688V15.288c.093-.015.19-.033.283-.056A6.2,6.2,0,0,0,14.643,10a1.782,1.782,0,0,1,.028-.2l.255-2.373.051-.525c.051-.663.1-1.328.153-1.992V4.876l1.983.14Z"
          transform="translate(0 0)"
          fill="#6e6e6e"
        />
      </g>
    </svg>
  );
};

export default Sprint;
