import Add from "components/svgs/icons/navbars/Add";
import Backlog from "components/svgs/icons/navbars/Backlog";
import Business from "components/svgs/icons/navbars/Business";
import Calendar from "components/svgs/icons/navbars/Calendar";
import Chat from "components/svgs/icons/navbars/Chat";
import Dashboard from "components/svgs/icons/navbars/Dashboard";
import Help from "components/svgs/icons/navbars/Help";
import Invite from "components/svgs/icons/navbars/Invite";
import Members from "components/svgs/icons/navbars/Members";
import Notes from "components/svgs/icons/navbars/Notes";
import Projects from "components/svgs/icons/navbars/Projects";
import Reporting from "components/svgs/icons/navbars/Reporting";
import Settings from "components/svgs/icons/navbars/Settings";
import Sprint from "components/svgs/icons/navbars/Sprint";
import Tasks from "components/svgs/icons/navbars/Tasks";
import Tracker from "components/svgs/icons/navbars/Tracker";
import Version from "components/svgs/icons/navbars/Version";

const icons = {
  add: Add,
  backlog: Backlog,
  business: Business,
  calendar: Calendar,
  chat: Chat,
  dashboard: Dashboard,
  help: Help,
  invite: Invite,
  members: Members,
  notes: Notes,
  projects: Projects,
  reporting: Reporting,
  settings: Settings,
  sprint: Sprint,
  tasks: Tasks,
  tracker: Tracker,
  Version: Version,
};

export default icons;
