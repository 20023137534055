import NavLinkAdapter from "core/NavLinkAdapter";
import { styled } from "@mui/material/styles";
import Icon from "@mui/material/Icon";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import clsx from "clsx";
import PropTypes from "prop-types";
import { memo, useMemo } from "react";
import withRouter from "core/withRouter";
import NavBadge from "../../NavBadge";

const StyledListItem = styled(ListItem)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: "none!important",
  minHeight: 48,
  "&.active": {
    backgroundColor: `${theme.palette.secondary.main}!important`,
    color: `${theme.palette.secondary.contrastText}!important`,
    pointerEvents: "none",
    "& .default-list-item-text-primary": {
      color: "inherit",
    },
    "& .default-list-item-icon": {
      color: "inherit",
    },
  },
  "& .default-list-item-icon": {},
  "& .default-list-item-text": {
    padding: "0 0 0 16px",
  },
}));

const NavigationHorizontalItem = (props) => {
  const { item } = props;

  return useMemo(
    () => (
      <StyledListItem
        button
        component={NavLinkAdapter}
        to={item.url}
        activeClassName="active"
        className={clsx("default-list-item")}
        end={item.end}
        role="button"
      >
        {item.icon && (
          <Icon
            className={clsx(
              "default-list-item-icon text-16 shrink-0",
              item.iconClass
            )}
            color="action"
          >
            {item.icon}
          </Icon>
        )}

        <ListItemText
          className="default-list-item-text"
          primary={item.title}
          classes={{ primary: "text-13 default-list-item-text-primary" }}
        />

        {item.badge && (
          <NavBadge className="ltr:ml-8 rtl:mr-8" badge={item.badge} />
        )}
      </StyledListItem>
    ),
    [item.badge, item.exact, item.icon, item.iconClass, item.title, item.url]
  );
};

NavigationHorizontalItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.string,
    url: PropTypes.string,
  }),
};

NavigationHorizontalItem.defaultProps = {};

const NavHorizontalItem = withRouter(memo(NavigationHorizontalItem));

export default NavHorizontalItem;
