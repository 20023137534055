import React from "react";

const ArrowLeft = (props) => {
  const { height = 24, width = 14, color = "fill-primaryBlue" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20.014 35.004"
      className={color}
    >
      <path
        id="Icon_ionic-ios-arrow-down"
        data-name="Icon ionic-ios-arrow-down"
        d="M23.7,25.227,36.932,11.981a2.491,2.491,0,0,1,3.533,0,2.522,2.522,0,0,1,0,3.543l-15,15.007a2.5,2.5,0,0,1-3.45.073l-15.1-15.07a2.5,2.5,0,0,1,3.533-3.543Z"
        transform="translate(31.26 -6.188) rotate(90)"
      />
    </svg>
  );
};

export default ArrowLeft;
