import {
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import EmptyPanel from "components/display/EmptyPanel";
import SearchBar from "components/header/SearchBar";
import ConfirmDeleteDialog from "components/modals/ConfirmDeleteDialog";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Root, StyledTableCell, StyledTableRow } from "settings/styleOverrides";
import withReducer from "store/withReducer";
import _ from "utils/lodash";
import Utilities from "utils/utilities";
import AddTopicDialog from "./AddTopicDialog";
import discussionsSlice, {
  deleteTopic,
  getDiscussionsTopic,
  selectDiscussions,
  updateTopic,
} from "./store/discussionsSlice";
import FilledHeart from "components/svgs/icons/general/FilledHeart";
import FeatherHeart from "components/svgs/icons/general/FeatherHeart";
import DeleteIcon from "components/svgs/icons/general/DeleteIcon";
import { Box } from "@mui/system";
import Edit from "components/svgs/icons/general/Edit";

const Discussions = (props) => {
  const { projectData } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeParams = useParams();
  const projectId = routeParams.projectId;
  const user = useSelector(({ auth }) => auth.user);
  const topics = useSelector(selectDiscussions);
  const disabled = Utilities.checkUserPermissionsForProject(
    projectData?.resources,
    user?.data?.email
  );
  const workspaceInfo = user?.workspaces?.find(
    (x) => x.id === user?.data.currentWorkspace
  );
  const currentPlan = workspaceInfo?.plan;

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [loadingSpinner, setLoadingSpinner] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(null);
  const [open, setOpen] = useState(false);
  const [row, setRow] = useState({});

  const rowClickFunc = (data) => {
    navigate(
      `/${user.data.currentWorkspace}/project/${projectId}/topic/${data.id}`
    );
  };

  const handleClickOpen = (add) => {
    if (add) setRow({});
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const toggleFavourite = (e, discussionData, favourites) => {
    e.preventDefault();
    e.stopPropagation();

    const data = {
      ...discussionData,
      favourites: favourites,
      time: new Date(),
    };

    dispatch(
      updateTopic({
        user,
        projectId,
        data,
      })
    );
  };

  const editTopic = (e, data) => {
    e.preventDefault();
    e.stopPropagation();

    setRow(data);
    handleClickOpen(false);
  };

  const openDeleteDialog = (e, data) => {
    e.preventDefault();
    e.stopPropagation();

    setRow(data);
    setConfirmDialog(true);
  };

  const confirmDelete = (data) => {
    dispatch(deleteTopic({ user, projectId, data }));
    setConfirmDialog(false);
  };

  useEffect(() => {
    if (!projectId) return;
    dispatch(getDiscussionsTopic({ user, projectId }))
      .then(() => setLoadingSpinner(false))
      .catch(() => setLoadingSpinner(false));
  }, []);

  // TODO: For now do client side filtering
  useEffect(() => {
    setFilteredData(Utilities.getFilteredArray(topics, searchQuery));
  }, [topics, searchQuery]);

  return (
    <>
      {currentPlan === "Starter" ? (
        <EmptyPanel
          mainClass={"mt-10"}
          imgClass={"xs:w-[140px] xs:h-[140px] sm:w-[200px] sm:h-[200px]"}
          mainText={"No Access, yet?"}
          subText={
            "Upgrade your plan and start having group discussions on a project!"
          }
          buttonText={"Upgrade Now"}
          onBtnClick={() =>
            navigate(`/${user.data.currentWorkspace}/settings/plan`)
          }
          imageSrc={"noAccess"}
          imgAlt={"No Access"}
        />
      ) : (
        <Root
          header={
            <SearchBar
              setSearchQuery={setSearchQuery}
              searchQuery={searchQuery}
              addBtnTxt={`${disabled ? "" : "Create a topic"}`}
              handleClickOpen={() => handleClickOpen(true)}
            />
          }
          content={
            <>
              {loadingSpinner ? (
                <CircularProgress color="info" className="m-auto" />
              ) : (
                <>
                  {!loadingSpinner && topics?.length > 0 ? (
                    <div className="flex flex-col h-full w-full px-4 mb-10">
                      <>
                        <TableContainer>
                          <Table
                            sx={{
                              minWidth: 900,
                              borderCollapse: "separate",
                              borderSpacing: "0px 1.5rem",
                            }}
                            aria-label="customized table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell className="font-medium">
                                  Topic
                                </StyledTableCell>
                                <StyledTableCell className="font-medium">
                                  Description
                                </StyledTableCell>
                                <StyledTableCell className="font-medium">
                                  Last Activity
                                </StyledTableCell>
                                <StyledTableCell
                                  align="center"
                                  className="font-medium"
                                >
                                  Favourites
                                </StyledTableCell>
                                {!disabled && (
                                  <StyledTableCell
                                    align="center"
                                    className="font-medium"
                                  >
                                    Actions
                                  </StyledTableCell>
                                )}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {filteredData.map((row, key) => (
                                <StyledTableRow
                                  key={key}
                                  onClick={() => rowClickFunc(row)}
                                  className="cursor-pointer"
                                >
                                  <StyledTableCell
                                    className="w-[25%]"
                                    style={{ width: "20%" }}
                                  >
                                    {row.name}
                                  </StyledTableCell>
                                  <StyledTableCell className="border-l-[1px] w-[35%]">
                                    {_.truncate(row.description, {
                                      length: 40,
                                    })}
                                  </StyledTableCell>
                                  <StyledTableCell className="border-l-[1px] w-[25%]">
                                    {row?.time
                                      ? Utilities.formatDate(
                                          row.time,
                                          "yyyy-MM-dd hh:mm a",
                                          format
                                        )
                                      : "-"}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    className="border-l-[1px] w-[15%]"
                                  >
                                    <IconButton
                                      onClick={(e) =>
                                        toggleFavourite(
                                          e,
                                          row,
                                          !row?.favourites
                                        )
                                      }
                                    >
                                      <Box className="mx-auto">
                                        {row?.favourites ? (
                                          <FilledHeart />
                                        ) : (
                                          <FeatherHeart />
                                        )}
                                      </Box>
                                    </IconButton>
                                  </StyledTableCell>
                                  {!disabled && (
                                    <StyledTableCell
                                      align="center"
                                      style={{ width: "15%" }}
                                      className="border-l-[1px]"
                                    >
                                      <div className="flex justify-center">
                                        <Box
                                          className="mx-2"
                                          onClick={(e) => editTopic(e, row)}
                                        >
                                          <Edit
                                            width={18}
                                            height={23}
                                            className="fill-primaryBlue"
                                          />
                                        </Box>

                                        <Box
                                          className="mx-2"
                                          onClick={(e) =>
                                            openDeleteDialog(e, row)
                                          }
                                        >
                                          <DeleteIcon />
                                        </Box>
                                      </div>
                                    </StyledTableCell>
                                  )}
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>

                        {/* TODO: <Pagination /> */}
                      </>
                    </div>
                  ) : (
                    <EmptyPanel
                      mainClass={"mt-10"}
                      imgClass={
                        "xs:w-[140px] xs:h-[140px] sm:w-[200px] sm:h-[200px]"
                      }
                      mainText={"No topics, yet?"}
                      subText={
                        "Create something you like and start a new topic!"
                      }
                      imageSrc={"emptyDiscussions"}
                      buttonText={"Create a topic"}
                      onBtnClick={() => handleClickOpen(true)}
                      imgAlt={"Empty Discussions"}
                      searchQuery={searchQuery}
                    />
                  )}
                </>
              )}

              <AddTopicDialog open={open} onClose={handleClose} row={row} />
              {confirmDialog && (
                <ConfirmDeleteDialog
                  open={confirmDialog}
                  onClose={() => setConfirmDialog(false)}
                  deleteFunc={confirmDelete}
                  deleteData={row}
                />
              )}
            </>
          }
        />
      )}
    </>
  );
};

export default withReducer("discussionApp", discussionsSlice)(Discussions);
