import React from "react";
import { Typography, Avatar } from "@mui/material";
import Utilities from "utils/utilities";
import DisplayFileOrImage from "components/display/DisplayImageOrFile";

const Message = (props) => {
  const {
    avatarSrc,
    isSender,
    item,
    imgClickHandler,
    formatDistance,
    isSideChat,
    isDiscussionChat,
    color,
    name,
  } = props;

  return (
    <div
      className={`flex w-full h-fit mt-4 mb-2 ${isSender ? "justify-end" : ""}`}
    >
      <div className={`flex h-fit ${isSender ? "" : "flex-row-reverse"}`}>
        <div className="msgTime-container h-full ">
          <div className="time flex justify-end mb-1 text-msgDate ">
            <Typography className="text-xs" component="span">
              {item.time.seconds
                ? formatDistance(
                    new Date(item.time.seconds * 1000),
                    new Date(),
                    {
                      addSuffix: true,
                    }
                  )
                : formatDistance(new Date(item.time), new Date(), {
                    addSuffix: true,
                  })}
            </Typography>
          </div>
          <div
            className={`msg h-fit py-6 px-4 rounded-t-lg ${
              isSideChat
                ? "xs:min-w-[120px] xs:max-w-[180px] md:min-w-[200px] md:max-w-[320px]"
                : isDiscussionChat
                ? "xs:min-w-[120px] xs:max-w-[160px] xmd:min-w-[200px] xmd:max-w-[260px] md:min-w-[300px] md:max-w-[500px]"
                : "sm:max-w-[300px] md:max-w-[250px] lg:max-w-[150px] lg:min-w-[150px] xl:min-w-[200px] xxl:min-w-[300px] 2xl:min-w-[350px]"
            } ${
              isSender
                ? "bg-primaryBlue rounded-bl-lg text-white "
                : "rounded-br-lg bg-lightBrown text-cancelBlack"
            }`}
          >
            <Typography
              className="break-words"
              variant="body2"
              component="span"
            >
              {/* TODO: Prevent XSS attack */}
              <div
                dangerouslySetInnerHTML={{
                  __html: Utilities.getMessageContent(item.message),
                }}
              ></div>
              {item.attachment && item.attachment.length > 0 && (
                <div className="flex justify-center bg-white p-4 m-4">
                  <DisplayFileOrImage
                    url={item.attachment[0]}
                    name={item.attachment[1]}
                    width={100}
                    onClickFunc={() =>
                      imgClickHandler(item?.attachment && item.attachment[0])
                    }
                  />
                </div>
              )}
            </Typography>
          </div>
        </div>
        <div className="picture flex items-end mx-2 ">
          <Avatar
            src={avatarSrc}
            className="mb-1"
            style={{ backgroundColor: color }}
            alt="avatar"
          >
            {name && name[0]?.toLocaleUpperCase()}
          </Avatar>
        </div>
      </div>
    </div>
  );
};

export default Message;
