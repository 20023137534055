import Utilities from "utils/utilities";
import firebase from "services/firebase";

// This can act as generic post api call
export const postApi = async (body, params) => {
  const { api } = params;
  const { currentUser } = firebase.getAuth();
  const token = await currentUser.getIdToken(true);
  const endpoint = `${Utilities.getBaseUrl()}/${api}`;
  const request = await fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(body),
  });
  const response = await request.json();
  if (response.error) {
    return {
      statusCode: response.statusCode || 404,
      body: response,
    };
  }
  return {
    statusCode: 200,
    body: response,
  };
};
