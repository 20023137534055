const Settings = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24.902 24.507"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_17632"
            data-name="Rectangle 17632"
            width="24.902"
            height="24.507"
            fill="#6e6e6e"
          />
        </clipPath>
      </defs>
      <g id="Group_40623" data-name="Group 40623" transform="translate(0 0)">
        <g
          id="Group_40622"
          data-name="Group 40622"
          transform="translate(0 0)"
          clipPath="url(clipPath)"
        >
          <path
            id="Path_34394"
            data-name="Path 34394"
            d="M9.41,10.16l3.151,3.151a.869.869,0,0,0-.218.126c-.912.9-1.816,1.818-2.732,2.718a.4.4,0,0,0-.12.443,4.74,4.74,0,0,1-2.644,5.539,4.689,4.689,0,0,1-3.908-.014c-.317-.137-.353-.383-.1-.644.563-.569,1.13-1.132,1.7-1.7.348-.349.685-.712,1.049-1.044a1.173,1.173,0,0,0,.122-1.758,1.222,1.222,0,0,0-1.768.138c-.867.912-1.775,1.786-2.666,2.675-.35.349-.558.309-.753-.145A4.758,4.758,0,0,1,4.138,13.1a4.664,4.664,0,0,1,2.021.112.308.308,0,0,0,.353-.1c.91-.919,1.826-1.831,2.738-2.747a1.946,1.946,0,0,0,.16-.206"
            transform="translate(0.027 1.952)"
            fill="#6e6e6e"
          />
          <path
            id="Path_34395"
            data-name="Path 34395"
            d="M13.655,12.074,10.571,8.987c.443-.44.9-.9,1.358-1.351.416-.415.824-.837,1.248-1.242a.372.372,0,0,0,.122-.42,4.735,4.735,0,0,1,5.339-5.9,7.772,7.772,0,0,1,1.169.345c.362.13.4.385.128.664-.931.935-1.867,1.864-2.8,2.8a1.146,1.146,0,1,0,1.5,1.731c.325-.286.621-.606.929-.912.657-.654,1.311-1.313,1.968-1.967.316-.312.53-.269.708.139a4.759,4.759,0,0,1-3.44,6.56,4.573,4.573,0,0,1-2.172-.088.308.308,0,0,0-.355.092c-.871.886-1.75,1.763-2.623,2.638"
            transform="translate(2.031 0)"
            fill="#6e6e6e"
          />
          <path
            id="Path_34396"
            data-name="Path 34396"
            d="M1.678,8.289c.2-.319.378-.614.554-.912a.332.332,0,0,0,.057-.193A2.489,2.489,0,0,1,2.822,5.6,10.774,10.774,0,0,1,5.363,2.8,12.421,12.421,0,0,1,9.279.458,5.279,5.279,0,0,1,12.487.291a5.149,5.149,0,0,1,.546.224c0,.033-.006.066-.008.1-.141.043-.279.1-.422.128a8.859,8.859,0,0,0-2.512.809,3.529,3.529,0,0,0-1.763,1.8,1.716,1.716,0,0,0,.111,1.637.66.66,0,0,1-.092.8,2.388,2.388,0,0,1-.172.2q3.6,3.6,7.168,7.172l-1.682,1.676L6.512,7.677c-.209.162-.379.31-.566.434a.92.92,0,0,1-.682.107.915.915,0,0,0-.6.118c-.576.387-1.125.815-1.7,1.239.113.122.2.205.273.294a.781.781,0,0,1-.027,1.085.76.76,0,0,1-1.063.036Q1.154,10.037.2,9.042A.76.76,0,0,1,.237,7.979a.78.78,0,0,1,1.085-.026c.114.1.219.207.356.336"
            transform="translate(0 0.021)"
            fill="#6e6e6e"
          />
          <path
            id="Path_34397"
            data-name="Path 34397"
            d="M22.619,19.333a2.655,2.655,0,0,1-.291.483c-.695.712-1.4,1.414-2.109,2.114-.348.342-.7.312-1.055-.083q-2.736-3.049-5.469-6.1l-1.55-1.73a.774.774,0,0,1,.036-1.19c.353-.356.707-.713,1.066-1.065a.765.765,0,0,1,1.135-.033q3.955,3.542,7.9,7.093a2.484,2.484,0,0,1,.336.511"
            transform="translate(2.283 2.208)"
            fill="#6e6e6e"
          />
        </g>
      </g>
    </svg>
  );
};

export default Settings;
