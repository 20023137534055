import React from "react";

const Contacts = (props) => {
  const { className = "fill-black" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 34.59 27"
      className={className}
    >
      <g
        id="Group_42338"
        data-name="Group 42338"
        transform="translate(-397 -373.001)"
      >
        <path
          id="Path_37775"
          data-name="Path 37775"
          d="M9.534,30.04c-.047-2.33-.437-4.7.732-6.9A6.777,6.777,0,0,1,14.4,19.761a.751.751,0,0,1,.522.058,7.641,7.641,0,0,0,7.629,0,.954.954,0,0,1,.656-.014,6.71,6.71,0,0,1,4.749,6.028c.076,1.387.013,2.781.013,4.21H9.534"
          transform="translate(395.547 369.96)"
        />
        <path
          id="Path_37776"
          data-name="Path 37776"
          d="M19.388,16.434a5.852,5.852,0,1,1,5.827-5.874,5.845,5.845,0,0,1-5.827,5.874"
          transform="translate(394.918 372.272)"
        />
        <path
          id="Path_37777"
          data-name="Path 37777"
          d="M10.165,11.7a5.837,5.837,0,1,1,4.511-9.047C11.068,4.608,9.511,7.586,10.165,11.7"
          transform="translate(396.39 372.995)"
        />
        <path
          id="Path_37778"
          data-name="Path 37778"
          d="M28.694,11.686c.438-4.517-.741-6.9-4.465-9.037C24.837.819,28.107-.475,30.5.167a5.935,5.935,0,0,1,4.237,6.906c-.58,2.82-3.428,4.993-6.045,4.613"
          transform="translate(393.268 373)"
        />
        <path
          id="Path_37779"
          data-name="Path 37779"
          d="M38.787,25.3h-5.98a9.11,9.11,0,0,0-5.385-7.882c.307-.445.584-.876.9-1.279a.518.518,0,0,1,.4-.135,7.862,7.862,0,0,0,4.655-.986.875.875,0,0,1,.611,0,6.785,6.785,0,0,1,4.79,6.106c.085,1.37.014,2.75.014,4.18"
          transform="translate(392.776 370.696)"
        />
        <path
          id="Path_37780"
          data-name="Path 37780"
          d="M5.985,25.262H.067c-.03-.042-.063-.067-.062-.091.019-1.532-.059-3.073.082-4.594a6.709,6.709,0,0,1,4.832-5.6.832.832,0,0,1,.561.069A7.585,7.585,0,0,0,10,15.993a.539.539,0,0,1,.6.307c.223.374.5.714.785,1.107a8.994,8.994,0,0,0-5.4,7.855"
          transform="translate(397 370.697)"
        />
      </g>
    </svg>
  );
};

export default Contacts;
