import React from "react";

const SemiCircle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="125"
      height="63"
      viewBox="0 0 125 63"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_17963"
            data-name="Rectangle 17963"
            width="125"
            height="63"
            transform="translate(3147 -15114)"
            fill="none"
            stroke="#707070"
            strokeWidth="1"
          />
        </clipPath>
      </defs>
      <g
        id="Group_42382"
        data-name="Group 42382"
        transform="translate(-3147 15114)"
      >
        <g
          id="Mask_Group_14"
          data-name="Mask Group 14"
          clipPath="url(#clipPath)"
        >
          <g
            id="Group_33"
            data-name="Group 33"
            transform="translate(3149 -15111.5)"
            fill="none"
            stroke="#fff"
            strokeWidth="2"
          >
            <circle cx="60.5" cy="60.5" r="60.5" stroke="none" />
            <circle cx="60.5" cy="60.5" r="59.5" fill="none" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SemiCircle;
