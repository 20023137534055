const Tasks = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="24"
      height="26"
      viewBox="0 0 24.007 28.667"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_17630"
            data-name="Rectangle 17630"
            width="24.007"
            height="28.667"
            fill="#6e6e6e"
          />
        </clipPath>
      </defs>
      <g id="Group_40618" data-name="Group 40618" transform="translate(0 0)">
        <g
          id="Group_40617"
          data-name="Group 40617"
          transform="translate(0 0)"
          clipPath="url(clipPath)"
        >
          <path
            id="Path_34386"
            data-name="Path 34386"
            d="M13.085,27.349,1.344,27.336V1.319H22.679V17.911c0,.151,0,.3.006.454a.706.706,0,0,0,.248.5.58.58,0,0,0,.441.145.644.644,0,0,0,.608-.639c0-.061,0-.122,0-.183V.96c0-.732-.2-.934-.92-.935L.868,0A.943.943,0,0,0,.2.2.928.928,0,0,0,0,.879V27.757a.934.934,0,0,0,.185.7.909.909,0,0,0,.689.189h0l12.217-.007a.978.978,0,0,0,.721-.223.579.579,0,0,0,.159-.423c0-.292-.163-.639-.889-.64"
            transform="translate(0 0)"
            fill="#6e6e6e"
          />
          <path
            id="Path_34387"
            data-name="Path 34387"
            d="M33.278,35.918a.652.652,0,0,0-.375-.611.543.543,0,0,0-.585.054.927.927,0,0,0-.3.636c-.018,1.212-.019,2.442,0,3.655,0,.1-.008.131,0,.134a.374.374,0,0,1-.14.014q-3.164-.023-6.328-.009h0c-.064,0-.1-.006-.1,0a.479.479,0,0,1-.009-.11c.009-1.486.008-2.973.007-4.46v-1.6h.229c.315,0,.619,0,.923,0l.5,0c.942,0,1.885-.01,2.828-.022a.64.64,0,1,0-.01-1.279q-2.53-.009-5.063-.006a.657.657,0,0,0-.721.718q0,3.663,0,7.327a.669.669,0,0,0,.735.739h0q3.869-.009,7.739-.026c.457,0,.681-.233.684-.7,0-.441,0-.881,0-1.321l0-1.291c0-.61,0-1.22.006-1.83"
            transform="translate(-9.271 -12.422)"
            fill="#6e6e6e"
          />
          <path
            id="Path_34388"
            data-name="Path 34388"
            d="M7.254,23.509a1.4,1.4,0,0,0,.216.013h5.351l8.772-.012h.062A1.055,1.055,0,0,0,21.9,23.5a.63.63,0,0,0,.542-.624.6.6,0,0,0-.52-.616,1.679,1.679,0,0,0-.331-.022h-.069c-4.65-.007-9.3-.014-14.009-.02a.668.668,0,0,0-.46.105.935.935,0,0,0-.339.56.581.581,0,0,0,.536.628"
            transform="translate(-2.582 -8.543)"
            fill="#6e6e6e"
          />
          <path
            id="Path_34389"
            data-name="Path 34389"
            d="M7.666,15.376h-.1a1.927,1.927,0,0,0-.286.008.574.574,0,0,0-.563.6,1.025,1.025,0,0,0,.316.579.564.564,0,0,0,.433.127h.042c4.168,0,8.337,0,12.531-.009a.706.706,0,0,0,.5-.131.834.834,0,0,0,.286-.63c-.039-.231-.278-.38-.451-.488a.527.527,0,0,0-.343-.054c-.023,0-.047,0-.07,0H14.046v-.009Z"
            transform="translate(-2.584 -5.912)"
            fill="#6e6e6e"
          />
          <path
            id="Path_34390"
            data-name="Path 34390"
            d="M18.283,8.523c-.13,0-.261,0-.391,0-.1,0-.2.008-.289,0L7.479,8.514h0a.832.832,0,0,0-.606.214.615.615,0,0,0-.177.449c0,.153.059.651.783.651h3.571l7.23-.009c.537,0,.738-.319.748-.618a.617.617,0,0,0-.169-.454.8.8,0,0,0-.574-.223"
            transform="translate(-2.574 -3.274)"
            fill="#6e6e6e"
          />
          <path
            id="Path_34391"
            data-name="Path 34391"
            d="M33.39,33.625a.664.664,0,0,0-.79.274L30.249,36.61c-.25.288-.5.574-.78.888l-.206.233a2.03,2.03,0,0,0-.193-.239c-.382-.4-.773-.785-1.166-1.172a.7.7,0,0,0-.551-.25.628.628,0,0,0-.449.206.655.655,0,0,0,.071.969q.906.908,1.816,1.807a.769.769,0,0,0,.534.264h.022a.746.746,0,0,0,.527-.3q.877-1.011,1.752-2.025l.333-.387q.823-.955,1.645-1.912a.717.717,0,0,0,.178-.629.631.631,0,0,0-.392-.435"
            transform="translate(-10.275 -12.911)"
            fill="#6e6e6e"
          />
        </g>
      </g>
    </svg>
  );
};

export default Tasks;
