import CircleIcon from "@mui/icons-material/Circle";
import { Box } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import clsx from "clsx";
import SearchIcon from "components/svgs/icons/general/SearchIcon";
import icons from "configs/icons";
import withRouter from "core/withRouter";
import { memo, useEffect, useReducer, useRef } from "react";
import Autosuggest from "react-autosuggest";
import { useSelector } from "react-redux";
import { selectFlatNavigation } from "store/slice/navigationSlice";
import _ from "utils/lodash";

const Root = styled("div")(({ theme }) => ({
  "& .Search-container": {
    position: "relative",
  },

  "& .Search-suggestionsContainerOpen": {
    position: "absolute",
    zIndex: 1,
    // marginTop: theme.spacing(),
    left: 0,
    right: 0,
  },

  "& .Search-suggestion": {
    display: "block",
  },

  "& .Search-suggestionsList": {
    margin: 0,
    padding: 0,
    listStyleType: "none",
  },

  "& .Search-input": {
    transition: theme.transitions.create(["background-color"], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.short,
    }),
    "&:focus": {
      backgroundColor: theme.palette.background.paper,
    },
  },
}));

const renderInputComponent = (inputProps) => {
  const { variant, inputRef = () => {}, ref, ...other } = inputProps;
  return (
    <div className="relative">
      {variant === "basic" ? (
        // Outlined
        <>
          <TextField
            autoComplete="off"
            fullWidth
            InputProps={{
              inputRef: (node) => {
                ref(node);
                inputRef(node);
              },
              classes: {
                input:
                  "Search-input py-0 pr-16 h-40 md:h-48 ltr:pr-48 rtl:pl-48 focus:ring-0",
                notchedOutline: "rounded-8",
              },
            }}
            variant="outlined"
            {...other}
          />
          <Icon
            className="absolute top-0 ltr:right-0 rtl:left-0 pointer-events-none"
            color="action"
          >
            search
          </Icon>
        </>
      ) : (
        // Standard
        <TextField
          autoComplete="off"
          fullWidth
          InputProps={{
            disableUnderline: true,
            inputRef: (node) => {
              ref(node);
              inputRef(node);
            },
            classes: {
              input: "Search-input py-0 pr-16 focus:ring-0",
            },
          }}
          variant="standard"
          {...other}
        />
      )}
    </div>
  );
};

const renderSuggestion = (suggestion, { query, isHighlighted }) => {
  const matches = match(suggestion.title, query);
  const parts = parse(suggestion.title, matches);
  const NavIcon = icons[suggestion.icon];

  return (
    <MenuItem selected={isHighlighted} component="div">
      <ListItemIcon>
        {suggestion.icon ? (
          <>
            {!suggestion.icon.startsWith("#") && (
              <Box className="w-6 mr-4">
                <NavIcon />
              </Box>
            )}
            {suggestion.icon.startsWith("#") && (
              <CircleIcon
                className="mr-3"
                htmlColor={suggestion.icon}
                fontSize="small"
              />
            )}
          </>
        ) : (
          <span className="text-20 font-semibold uppercase text-center">
            {suggestion.title[0]}
          </span>
        )}
      </ListItemIcon>
      <ListItemText
        primary={parts.map((part, index) =>
          part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 600 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </strong>
          )
        )}
      />
    </MenuItem>
  );
};

const getSuggestions = (value, data) => {
  const inputValue = _.deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0
    ? []
    : data.filter((suggestion) => {
        const keep =
          count < 10 && match(suggestion.title, inputValue).length > 0;

        if (keep) {
          count += 1;
        }

        return keep;
      });
};

const getSuggestionValue = (suggestion) => {
  return suggestion.title;
};

const initialState = {
  searchText: "",
  search: false,
  navigation: null,
  suggestions: [],
  noSuggestions: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "open": {
      return {
        ...state,
        opened: true,
      };
    }
    case "close": {
      return {
        ...state,
        opened: false,
        searchText: "",
      };
    }
    case "setSearchText": {
      return {
        ...state,
        searchText: action.value,
      };
    }
    case "setNavigation": {
      return {
        ...state,
        navigation: action.value,
      };
    }
    case "updateSuggestions": {
      const suggestions = getSuggestions(action.value, state.navigation);
      const isInputBlank = action.value.trim() === "";
      const noSuggestions = !isInputBlank && suggestions.length === 0;

      return {
        ...state,
        suggestions,
        noSuggestions,
      };
    }
    case "clearSuggestions": {
      return {
        ...state,
        suggestions: [],
        noSuggestions: false,
      };
    }
    case "decrement": {
      return { count: state.count - 1 };
    }
    default: {
      throw new Error();
    }
  }
};

const Search = (props) => {
  const navigation = useSelector(selectFlatNavigation);

  const [state, dispatch] = useReducer(reducer, initialState);
  const suggestionsNode = useRef(null);
  const popperNode = useRef(null);
  const buttonNode = useRef(null);

  useEffect(() => {
    dispatch({
      type: "setNavigation",
      value: navigation,
    });
  }, [navigation]);

  const showSearch = (ev) => {
    ev.stopPropagation();
    dispatch({ type: "open" });
    document.addEventListener("keydown", escFunction, false);
  };

  const hideSearch = () => {
    dispatch({ type: "close" });
    document.removeEventListener("keydown", escFunction, false);
  };

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      hideSearch();
    }
  };

  const handleSuggestionsFetchRequested = ({ value }) => {
    dispatch({
      type: "updateSuggestions",
      value,
    });
  };

  const handleSuggestionSelected = (event, { suggestion }) => {
    event.preventDefault();
    event.stopPropagation();
    if (!suggestion.url) {
      return;
    }
    props.navigate(suggestion.url);
    hideSearch();
  };

  const handleSuggestionsClearRequested = () => {
    dispatch({
      type: "clearSuggestions",
    });
  };

  const handleChange = (event) => {
    dispatch({
      type: "setSearchText",
      value: event.target.value,
    });
  };

  const handleClickAway = (event) => {
    return (
      state.opened &&
      (!suggestionsNode.current ||
        !suggestionsNode.current.contains(event.target)) &&
      hideSearch()
    );
  };

  const autosuggestProps = {
    renderInputComponent,
    highlightFirstSuggestion: true,
    suggestions: state.suggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    onSuggestionSelected: handleSuggestionSelected,
    getSuggestionValue,
    renderSuggestion,
  };

  switch (props.variant) {
    case "basic": {
      return (
        <div
          className={clsx("flex items-center ", props.className)}
          ref={popperNode}
        >
          <Autosuggest
            {...autosuggestProps}
            inputProps={{
              variant: props.variant,
              placeholder: props.placeholder,
              value: state.searchText,
              onChange: handleChange,
              onFocus: showSearch,
              InputLabelProps: {
                shrink: true,
              },
              autoFocus: false,
            }}
            theme={{
              container: "flex flex-1 ",
              suggestionsList: "Search-suggestionsList",
              suggestion: "Search-suggestion",
            }}
            renderSuggestionsContainer={(options) => (
              <Popper
                anchorEl={popperNode.current}
                open={Boolean(options.children) || state.noSuggestions}
                popperOptions={{ positionFixed: true }}
                className="z-9999"
              >
                <div ref={suggestionsNode}>
                  <Paper
                    className="shadow-lg rounded-8 overflow-hidden"
                    {...options.containerProps}
                    style={{
                      width: popperNode.current
                        ? popperNode.current.clientWidth
                        : null,
                    }}
                  >
                    {options.children}
                    {state.noSuggestions && (
                      <Typography className="px-16 py-12">
                        {props.noResults}
                      </Typography>
                    )}
                  </Paper>
                </div>
              </Popper>
            )}
          />
        </div>
      );
    }
    case "full": {
      return (
        <Root className={clsx("flex", props.className)}>
          <Tooltip title="Click to search" placement="bottom">
            <div
              onClick={showSearch}
              onKeyDown={showSearch}
              role="button"
              tabIndex={0}
              ref={buttonNode}
            >
              {props.trigger}
            </div>
          </Tooltip>

          {state.opened && (
            <ClickAwayListener onClickAway={handleClickAway}>
              <Paper
                // className="absolute left-0 right-0 top-0 z-9999 shadow-0"
                square
              >
                <div className="flex items-center" ref={popperNode}>
                  <Autosuggest
                    {...autosuggestProps}
                    inputProps={{
                      placeholder: props.placeholder,
                      value: state.searchText,
                      onChange: handleChange,
                      InputLabelProps: {
                        shrink: true,
                      },
                      autoFocus: true,
                    }}
                    theme={{
                      container: "flex flex-1",
                      suggestionsList: "Search-suggestionsList",
                      suggestion: "Search-suggestion",
                    }}
                    renderSuggestionsContainer={(options) => (
                      <Popper
                        anchorEl={popperNode.current}
                        open={Boolean(options.children) || state.noSuggestions}
                        popperOptions={{ positionFixed: true }}
                        className="z-9999"
                      >
                        <div ref={suggestionsNode}>
                          <Paper
                            className="shadow-lg"
                            square
                            {...options.containerProps}
                            style={{
                              width: popperNode.current
                                ? popperNode.current.clientWidth
                                : null,
                            }}
                          >
                            {options.children}
                            {state.noSuggestions && (
                              <Typography className="px-16 py-12">
                                {props.noResults}
                              </Typography>
                            )}
                          </Paper>
                        </div>
                      </Popper>
                    )}
                  />
                  <IconButton onClick={hideSearch} size="large">
                    <Icon>close</Icon>
                  </IconButton>
                </div>
              </Paper>
            </ClickAwayListener>
          )}
        </Root>
      );
    }
    default: {
      return null;
    }
  }
};

Search.propTypes = {};
Search.defaultProps = {
  trigger: (
    <IconButton size="large">
      <SearchIcon />
    </IconButton>
  ),
  variant: "full",
  placeholder: "Search",
  noResults: "No results..",
};

export default withRouter(memo(Search));
