const Help = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 27.329 27.333"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_17634"
            data-name="Rectangle 17634"
            width="27.329"
            height="27.333"
            fill="#6e6e6e"
          />
        </clipPath>
      </defs>
      <g id="Group_40625" data-name="Group 40625" transform="translate(0 0)">
        <g
          id="Group_40624"
          data-name="Group 40624"
          transform="translate(0 0)"
          clipPath="url(clipPath)"
        >
          <path
            id="Path_34398"
            data-name="Path 34398"
            d="M13.664,27.334A13.666,13.666,0,1,1,27.329,13.664,13.669,13.669,0,0,1,13.664,27.334m.006-.626A13.05,13.05,0,1,0,.626,13.615,13.041,13.041,0,0,0,13.67,26.707"
            transform="translate(0 0)"
            fill="#6e6e6e"
          />
          <path
            id="Path_34399"
            data-name="Path 34399"
            d="M16.267,20.4H14.04c0-.051-.008-.1-.008-.156,0-.487,0-.974,0-1.462a2.239,2.239,0,0,1,.6-1.575Q16.3,15.356,17.959,13.5a2.642,2.642,0,1,0-4.623-1.766c0,.218,0,.437,0,.669H11.12a10.8,10.8,0,0,1,.006-1.144,4.878,4.878,0,0,1,9.659-.347,4.8,4.8,0,0,1-1.212,4.142q-1.587,1.782-3.19,3.552a.428.428,0,0,0-.119.319c.006.438,0,.875,0,1.313V20.4"
            transform="translate(-2.315 -1.431)"
            fill="#6e6e6e"
          />
          <rect
            id="Rectangle_17633"
            data-name="Rectangle 17633"
            width="2.216"
            height="2.154"
            transform="translate(11.725 20.727)"
            fill="#6e6e6e"
          />
        </g>
      </g>
    </svg>
  );
};

export default Help;
