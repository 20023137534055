import { ClickAwayListener } from "@mui/base";
import { Close } from "@mui/icons-material";
import { Button } from "@mui/material";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import clsx from "clsx";
import DisplayFileOrImage from "components/display/DisplayImageOrFile";
import EmptyPanel from "components/display/EmptyPanel";
import ImageModal from "components/modals/ImageModal";
import Mood from "components/svgs/icons/chat/Mood";
import AttachmentClip from "components/svgs/icons/general/AttachmentClip";
import UploadImage from "components/upload/UploadImage";
import { formatDistance } from "date-fns";
import Picker from "emoji-picker-react";
import { useCallback, useEffect, useRef, useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import firebase from "services/firebase";
import { useStyles } from "settings/styleOverrides";
import { selectMembers } from "views/members/store/membersSlice";
import Message from "../Message";
import { sendMessage } from "./store/chatSlice";

// TODO: This can be converted to use reuasable component between Chat and DiscussionChat
const Chat = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const members = useSelector(selectMembers);
  const chat = useSelector(({ chatPanel }) => chatPanel?.chat?.Chat);
  const media = useSelector(({ chatPanel }) => chatPanel?.chat?.media);
  const user = useSelector(({ auth }) => auth.user);
  const selectedContactId = useSelector(
    ({ chatPanel }) => chatPanel.chat?.selectedContactId
  );
  const darkMode = useSelector(
    ({ entities }) => entities.settings.current.darkMode
  );

  const [showPicker, setShowPicker] = useState(false);
  const [file, setFile] = useState("");
  const [imgModalOpen, setImgModalOpen] = useState(false);
  const [showLoad, setShowLoad] = useState(false);
  const firstDialogRef = useRef(null);
  const chatRef = useRef(null);
  const [messageText, setMessageText] = useState("");
  const [dialog, setDialog] = useState([]);
  const [scrollBottom, setScrollBottom] = useState(0);

  const [popupImgUrl, setPopupImgUrl] = useState("");
  const [fullScreenImg, setFullScreenImg] = useState(false);
  const [imgSrc, setImgSrc] = useState("");

  const firstTimeRef = useRef(true);
  const scrollToBottom = useCallback(
    () => (chatRef.current.scrollTop = chatRef.current.scrollHeight),
    [scrollBottom]
  );

  const onEmojiClick = (event, emojiObject) => {
    setMessageText((prevInput) => prevInput + emojiObject.emoji);
  };

  const onUploadAttachment = (file, url) => {
    setImgSrc(url);
    setFile(file);
  };

  const imgClickHandler = (src) => {
    setPopupImgUrl(src);
    // setFullScreenImg(true);
    setImgModalOpen(true);
  };

  useEffect(() => {
    if (chat) {
      scrollToBottom();
    }
  }, [chat, scrollToBottom]);

  useEffect(() => {
    if (!selectedContactId) return;
    const dRef = firebase.getDialogListenerAdded(user, selectedContactId);
    dRef.on("child_added", (snapshot) => {
      if (firstTimeRef.current) {
        firstTimeRef.current = false;
        return;
      }
      const data = snapshot.val();
      if (!data.time) data.time = new Date();
      setDialog((state) => [...state, data]);
      setScrollBottom((state) => state + 1);
    });
    return () => {
      firebase.detachDialogListenerAdded(user, selectedContactId);
    };
  }, [selectedContactId]);

  useEffect(() => {
    if (!selectedContactId) return;
    const unsubRef = firebase.getDialogListener(user, selectedContactId);
    unsubRef.once("value", (snapshot) => {
      const data = Object.values(snapshot.val() || {});
      if (data.length === 0) firstTimeRef.current = false;
      setDialog(data);
      setScrollBottom((state) => state + 1);
    });
    firebase
      .getFirstDialog(user, selectedContactId)
      .once("value", (snapshot) => {
        const data = snapshot.val() || {};
        firstDialogRef.current = Object.values(data)[0] || {};
      });
  }, [selectedContactId]);

  const onInputChange = (ev) => {
    setMessageText(ev.target.value);
  };

  const onMessageSubmit = (ev) => {
    ev.preventDefault();
    if (messageText === "" && imgSrc === "") {
      return;
    }

    dispatch(
      sendMessage({
        messageText,
        chatId: chat.id,
        contactId: selectedContactId,
        attachment: file,
      })
    ).then((res) => {
      setMessageText("");
      setImgSrc("");
      setFile("");
    });
  };

  const loadMore = useCallback(() => {
    const dRef = firebase.get20DialoguesListener(
      user,
      selectedContactId,
      dialog[0].id
    );
    dRef.once("value", (snapshot) => {
      const data = snapshot.val();
      if (!data) return;
      setDialog([...Object.values(data), ...dialog]);
      chatRef.current.scrollTop = chatRef.current.scrollHeight / 6;
    });
  }, [user, selectedContactId, dialog]);

  const handleScroll = useCallback(() => {
    if (
      chatRef.current.scrollTop === 0 &&
      firstDialogRef.current.id &&
      dialog[0].id !== firstDialogRef.current.id
    ) {
      // request for 50 more discussions
      setShowLoad(true);
    } else {
      if (showLoad) setShowLoad(false);
    }
  }, [showLoad, dialog]);

  useEffect(() => {
    const node = chatRef.current;
    if (node) {
      node.addEventListener("scroll", handleScroll);
      return () => {
        node.removeEventListener("scroll", handleScroll);
      };
    }
  }, [chatRef.current, handleScroll]);

  return (
    <div className={clsx("flex flex-col relative", props.className)}>
      <ImageModal
        open={imgModalOpen}
        handleClose={() => setImgModalOpen(false)}
        imgSrc={popupImgUrl}
        media={media}
      />
      <div
        ref={chatRef}
        className={`flex flex-1 flex-col overflow-y-auto overscroll-contain ${
          imgSrc ? "pb-52" : "pb-24"
        } `}
      >
        {chat && dialog.length > 0 ? (
          <div className="flex flex-col">
            {showLoad && (
              <Button onClick={loadMore} color="info">
                Load more
              </Button>
            )}
            {dialog.map((item, idx) => {
              const contact =
                item.who === user.uid
                  ? user.data
                  : members.find((_member) => _member.id === item.who);

              return (
                <Message
                  key={idx}
                  item={item}
                  avatarSrc={contact.photoURL}
                  color={contact?.more?.color}
                  name={contact?.displayName}
                  imgClickHandler={imgClickHandler}
                  formatDistance={formatDistance}
                  isSender={item.who === user.uid}
                  isSideChat
                />
              );
            })}
          </div>
        ) : (
          <div className="flex flex-col flex-1">
            <div className="flex flex-col flex-1 items-center justify-center">
              <EmptyPanel
                mainClass={"mt-10"}
                imgClass={"xs:w-[140px] xs:h-[140px] sm:w-[200px] sm:h-[200px]"}
                mainText={"No conversations, yet?"}
                subText={"Start a conversation by typing your message below."}
                imageSrc={"emptyChat"}
                imgAlt={"Empty Chat"}
              />
            </div>
          </div>
        )}
      </div>
      {chat && (
        <form
          onSubmit={onMessageSubmit}
          className="absolute bottom-0 right-0 left-0"
        >
          {showPicker && (
            <ClickAwayListener onClickAway={() => setShowPicker(false)}>
              <div style={{ width: "280px" }}>
                <Picker
                  onEmojiClick={onEmojiClick}
                  disableSearchBar={true}
                  pickerStyle={{ position: "relative" }}
                  groupVisibility={{
                    flags: false,
                    objects: false,
                    activities: false,
                    travel_places: false,
                    food_drink: false,
                    animals_nature: false,
                    symbols: false,
                  }}
                />
              </div>
            </ClickAwayListener>
          )}

          <div
            className={`flex flex-col w-full relative shadow ${
              darkMode ? "" : "bg-whiteSmoke"
            }`}
          >
            {imgSrc ? (
              <div className="flex w-full bg-whiteSmoke py-4">
                <div className="relative">
                  <div
                    onClick={() => setImgSrc("")}
                    className="flex justify-center items-center absolute -right-2 -top-1 bg-danger rounded-full cursor-pointer"
                  >
                    <Close className="w-[16px] h-[16px]" htmlColor="white" />
                  </div>
                  <DisplayFileOrImage
                    url={imgSrc}
                    name={file.name}
                    onClickFunc={() => imgClickHandler(imgSrc)}
                    className={`ml-3 object-cover`}
                    width={55}
                    height={55}
                  />
                </div>
              </div>
            ) : null}
            <div
              className={`flex w-full items-center border-t ${
                darkMode ? "bg-darkSmoke" : ""
              }`}
            >
              <div>
                <UploadImage
                  icon={<AttachmentClip />}
                  title="Upload Attachment"
                  acceptFiles={true}
                  className="ml-4"
                  size="large"
                  onChange={onUploadAttachment}
                />
              </div>
              <div className="">
                <IconButton
                  className="emoji-icon"
                  type="button"
                  size="large"
                  onClick={() => setShowPicker((val) => !val)}
                >
                  <Mood />
                </IconButton>
              </div>
              <div className="flex flex-1 inputDiv">
                <InputBase
                  autoComplete="off"
                  autoFocus={false}
                  id="message-input"
                  className="flex-1 flex relative grow shrink-0 my-6 border border-primaryBlue border-solid"
                  placeholder="Type something..."
                  onChange={onInputChange}
                  value={messageText}
                  inputProps={{
                    className: classes.input + " !p-2",
                  }}
                />
              </div>
              <div className="mr-2">
                <IconButton type="submit" size="large">
                  <Icon className="text-24" color="action">
                    send
                  </Icon>
                </IconButton>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default memo(Chat);
