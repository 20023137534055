import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import Switch from "@mui/material/Switch";
import Toolbar from "@mui/material/Toolbar";
import _ from "lodash";
import { BasicSwitchStyleOverrides } from "settings/styleOverrides";

const GeneralSettingsSidebar = (props) => {
  const {
    setShowSubTasks,
    showSubTasks,
    onClose,
    setShowDensityToggle,
    showDensityToggle,
    setEnableColumnFilterModes,
    enableColumnFilterModes,
    setShowArchived,
    showArchived,
    setShowCompletedBoards,
    showCompletedBoards,
    title,
    archiveTitle,
    user,
  } = props;
  const switchStyles = BasicSwitchStyleOverrides(
    user?.data?.settings?.themeColor
  );
  return (
    <>
      <AppBar position="static" className="bg-primaryBlue">
        <Toolbar className="flex w-full justify-between">
          <Typography className="text-white xs:text-lg md:text-xl">
            Settings
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              color: "white",
            }}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <div className="pt-4 px-4">
        <div className="pt-1 text-base font-medium">Quick toggles</div>
        <div className="pt-1 text-base text-gray-600">
          {title ? title : "Use these toggles to update your sprint board"}
        </div>
      </div>

      <List className="py-8" dense>
        {_.isFunction(setShowSubTasks) && (
          <ListItem button>
            <ListItemIcon className="min-w-40">
              <Icon>check_box</Icon>
            </ListItemIcon>
            <ListItemText primary="Show Subtasks" />
            <ListItemSecondaryAction>
              <Switch
                sx={switchStyles}
                onClick={() => setShowSubTasks(!showSubTasks)}
                checked={showSubTasks}
              />
            </ListItemSecondaryAction>
          </ListItem>
        )}

        {_.isFunction(setShowDensityToggle) && (
          <ListItem button>
            <ListItemIcon className="min-w-40">
              <Icon>check_box</Icon>
            </ListItemIcon>
            <ListItemText primary="Show Density Toggle" />
            <ListItemSecondaryAction>
              <Switch
                sx={switchStyles}
                onClick={() => setShowDensityToggle(!showDensityToggle)}
                checked={showDensityToggle}
              />
            </ListItemSecondaryAction>
          </ListItem>
        )}

        {_.isFunction(setEnableColumnFilterModes) && (
          <ListItem button>
            <ListItemIcon className="min-w-40">
              <Icon>check_box</Icon>
            </ListItemIcon>
            <ListItemText primary="Show Column Filter Mode" />
            <ListItemSecondaryAction>
              <Switch
                sx={switchStyles}
                onClick={() =>
                  setEnableColumnFilterModes(!enableColumnFilterModes)
                }
                checked={enableColumnFilterModes}
              />
            </ListItemSecondaryAction>
          </ListItem>
        )}

        {_.isFunction(setShowArchived) && (
          <ListItem button>
            <ListItemIcon className="min-w-40">
              <Icon>check_box</Icon>
            </ListItemIcon>
            <ListItemText
              primary={archiveTitle ? archiveTitle : "Show Archived Projects"}
            />
            <ListItemSecondaryAction>
              <Switch
                sx={switchStyles}
                onClick={() => setShowArchived(!showArchived)}
                checked={showArchived}
              />
            </ListItemSecondaryAction>
          </ListItem>
        )}

        {_.isFunction(setShowCompletedBoards) && (
          <ListItem button>
            <ListItemIcon className="min-w-40">
              <Icon>check_box</Icon>
            </ListItemIcon>
            <ListItemText primary={"Show Completed Boards"} />
            <ListItemSecondaryAction>
              <Switch
                sx={switchStyles}
                onClick={() => setShowCompletedBoards(!showCompletedBoards)}
                checked={showCompletedBoards}
              />
            </ListItemSecondaryAction>
          </ListItem>
        )}
      </List>
    </>
  );
};

export default GeneralSettingsSidebar;
