import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import NavVerticalTab from "./types/NavVerticalTab";

const StyledList = styled(List)(({ theme }) => ({
  "& .default-list-item": {
    "&:hover": {
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255, 255, 255, 0.05)"
          : "rgba(0,0,0,.04)",
    },
    "&:focus:not(.active)": {
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255, 255, 255, 0.06)"
          : "rgba(0,0,0,.05)",
    },
  },
  "&.active-square-list": {
    "& .default-list-item, & .active.default-list-item": {
      width: "100%",
      borderRadius: "0",
    },
  },
  "&.dense": {},
}));

const NavVerticalLayout2 = (props) => {
  const {
    navigation,
    active,
    dense,
    className,
    onItemClick,
    firstLevel,
    selectedId,
  } = props;

  const handleItemClick = (item) => {
    onItemClick?.(item);
  };

  return (
    <StyledList
      className={clsx(
        "navigation whitespace-nowrap items-center flex flex-col",
        `active-${active}-list`,
        dense && "dense",
        className
      )}
    >
      {navigation.map((_item) => (
        <NavVerticalTab
          key={_item.id}
          type={`vertical-${_item.type}`}
          item={_item}
          nestedLevel={0}
          onItemClick={handleItemClick}
          firstLevel={firstLevel}
          dense={dense}
          selectedId={selectedId}
        />
      ))}
    </StyledList>
  );
};

export default NavVerticalLayout2;
