import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
const chatsAdapter = createEntityAdapter({});

export const { selectAll: selectChats, selectById: selectChatById } =
  chatsAdapter.getSelectors((state) => state.chatApp.chats);

const chatsSlice = createSlice({
  name: "chatApp/chats",
  initialState: chatsAdapter.getInitialState({}),
  reducers: {
    setChats: chatsAdapter.setAll,
  },
});
export const { setChats } = chatsSlice.actions;
export default chatsSlice.reducer;
