import AppBar from "@mui/material/AppBar";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import keycode from "keycode";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSwipeable } from "react-swipeable";
import withReducer from "store/withReducer";
import { selectMembers } from "views/members/store/membersSlice";
import Chat from "./Chat";
import ContactList from "./ContactList";
import reducer from "./store";
import { closeChatPanel } from "./store/stateSlice";
import { Box } from "@mui/system";
import ArrowLeft from "components/svgs/icons/general/ArrowLeft";
import Close from "components/svgs/icons/general/Close";

const Root = styled("div")(({ theme, opened }) => ({
  position: "sticky",
  display: "flex",
  top: 0,
  width: 0,
  maxWidth: 70,
  minWidth: 0,
  height: "100vh",
  zIndex: 1000,

  [theme.breakpoints.down("lg")]: {
    width: 0,
    minWidth: 0,
  },

  ...(opened && {
    overflow: "visible",
  }),

  ...(!opened && {
    overflow: "hidden",
    animation: `hide-panel 1ms linear ${theme.transitions.duration.standard}`,
    animationFillMode: "forwards",
  }),

  "& > .panel": {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: 500,
    minWidth: 500,
    height: "100vh",
    margin: 0,
    overflow: "hidden",
    zIndex: 1000,
    backgroundColor: theme.palette.background.paper,
    boxShadow:
      "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    transform:
      theme.direction === "rtl"
        ? "translate3d(-360px,0,0)"
        : "translate3d(360px,0,0)",
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),

    ...(opened && {
      transform:
        theme.direction === "rtl"
          ? "translate3d(500px,0,0)"
          : "translate3d(-500px,0,0)",
    }),

    [theme.breakpoints.down("lg")]: {
      left: "auto",
      transform:
        theme.direction === "rtl"
          ? "translate3d(-360px,0,0)"
          : "translate3d(360px,0,0)",
      boxShadow: "none",
      width: 500,
      minWidth: 500,
      maxWidth: "100%",

      ...(opened && {
        transform: "translate3d(0,0,0)",
        boxShadow:
          "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
      }),
    },

    [theme.breakpoints.down("sm")]: {
      left: "auto",
      transform:
        theme.direction === "rtl"
          ? "translate3d(-260px,0,0)"
          : "translate3d(260px,0,0)",
      boxShadow: "none",
      width: 300,
      minWidth: 300,
      maxWidth: "100%",

      ...(opened && {
        transform: "translate3d(0,0,0)",
        boxShadow:
          "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
      }),
    },
  },

  "@keyframes hide-panel": {
    "0%": {
      overflow: "visible",
    },
    "99%": {
      overflow: "visible",
    },
    "100%": {
      overflow: "hidden",
    },
  },
}));

const ChatPanel = () => {
  const dispatch = useDispatch();
  const members = useSelector(selectMembers);
  const selectedContactId = useSelector(
    ({ chatPanel }) => chatPanel.chat?.selectedContactId
  );
  const state = useSelector(({ chatPanel }) => chatPanel.state);
  const user = useSelector(({ auth }) => auth.user);
  const theme = useTheme();
  const [contactListOpen, setContactListOpen] = useState(true);

  const ref = useRef();
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      return state && theme.direction === "rtl" && dispatch(closeChatPanel());
    },
    onSwipedRight: () => {
      return state && theme.direction === "ltr" && dispatch(closeChatPanel());
    },
  });

  const selectedContact = members.find(
    (_member) => _member.id === selectedContactId
  );

  const handleDocumentKeyDown = useCallback(
    (event) => {
      if (keycode(event) === "esc") {
        dispatch(closeChatPanel());
      }
    },
    [dispatch]
  );

  useEffect(() => {
    return () => {
      document.removeEventListener("keydown", handleDocumentKeyDown);
    };
  }, [dispatch, handleDocumentKeyDown]);

  useEffect(() => {
    if (state) {
      document.addEventListener("keydown", handleDocumentKeyDown);
    } else {
      document.removeEventListener("keydown", handleDocumentKeyDown);
    }
  }, [handleDocumentKeyDown, state]);

  /**
   * Click Away Listener
   */
  useEffect(() => {
    const handleDocumentClick = (ev) => {
      if (ref.current && !ref.current.contains(ev.target)) {
        dispatch(closeChatPanel());
      }
    };

    if (state) {
      document.addEventListener("click", handleDocumentClick, true);
    } else {
      document.removeEventListener("click", handleDocumentClick, true);
    }

    return () => {
      document.removeEventListener("click", handleDocumentClick, true);
    };
  }, [state, dispatch]);

  return (
    <Root opened={state ? 1 : 0} {...handlers}>
      <div className="sideChat panel flex flex-col max-w-full" ref={ref}>
        <AppBar position="static" className="shadow-md bg-primaryBlue">
          <Toolbar className="px-4">
            <div className="flex flex-1 px-2 items-center">
              {!contactListOpen && (
                <Box
                  onClick={() => setContactListOpen(true)}
                  className="cursor-pointer"
                >
                  <ArrowLeft color="fill-white" />
                </Box>
              )}
              <Typography className="text-16 ml-6" color="white">
                {state && selectedContact && !contactListOpen
                  ? selectedContact.displayName
                  : "Team Chat"}
              </Typography>
            </div>
            <div
              onClick={(ev) => {
                setContactListOpen(true);
                dispatch(closeChatPanel());
              }}
              className="flex px-4 cursor-pointer"
            >
              <Close />
            </div>
          </Toolbar>
        </AppBar>
        <Paper square className="flex flex-1 flex-row overflow-y-auto shadow-0">
          {contactListOpen ? (
            <ContactList
              className="flex shrink-0"
              user={user}
              contactListOpen={contactListOpen}
              setContactListOpen={setContactListOpen}
            />
          ) : (
            <Chat className="flex flex-1 z-10 h-full" />
          )}
        </Paper>
      </div>
    </Root>
  );
};

export default withReducer("chatPanel", reducer)(memo(ChatPanel));
