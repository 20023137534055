const Tracker = (props) => {
  const { height = 24, width = 28, className = "fill-[#6e6e6e]" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 27.449 32.433"
      className={className}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_17626"
            data-name="Rectangle 17626"
            width="27.449"
            height="32.433"
          />
        </clipPath>
      </defs>
      <g id="Group_40610" data-name="Group 40610" transform="translate(0 0)">
        <g
          id="Group_40609"
          data-name="Group 40609"
          transform="translate(0 0)"
          clipPath="url(clipPath)"
        >
          <path
            id="Path_34380"
            data-name="Path 34380"
            d="M24.634,10.358l2.715-2.572L24.618,5.1,22.047,7.828a14.176,14.176,0,0,0-7.041-2.779V2.555l1.137-.091V0H11.286V2.478l1.152.076V4.991A13.74,13.74,0,1,0,18.73,31.469,13.152,13.152,0,0,0,26.4,23.885c1.893-4.763,1.187-9.27-1.763-13.528M13.687,29.933A11.221,11.221,0,1,1,14,7.5a11.221,11.221,0,0,1-.31,22.438"
            transform="translate(0 0)"
          />
          <path
            id="Path_34381"
            data-name="Path 34381"
            d="M34.164,35.8a1.343,1.343,0,0,1-.494-1.15c.018-1.476.007-2.951.007-4.478H31.2c0,1.585.022,3.109-.019,4.633a1.422,1.422,0,0,1-.4.883,2.445,2.445,0,0,0-.157,3.534,1.85,1.85,0,0,1,.6,1.539.369.369,0,0,0,.019.105v.759h2.4v-.5a.776.776,0,0,0,.034-.241,1.891,1.891,0,0,1,.638-1.73,2.346,2.346,0,0,0-.149-3.356"
            transform="translate(-18.719 -18.867)"
          />
        </g>
      </g>
    </svg>
  );
};

export default Tracker;
