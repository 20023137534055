import { lazy } from "react";
import { authRoles } from "auth";

const TaskApp = lazy(() => import("./TaskApp"));

const TaskAppConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.user,
  routes: [
    {
      path: "/:workspaceId/task/label/:labelHandle",
      element: <TaskApp />,
      children: [{ path: ":taskId", element: <TaskApp /> }],
      title: "Tasks",
    },
    {
      path: "/:workspaceId/task/filter/:filterHandle",
      element: <TaskApp />,
      children: [{ path: ":taskId", element: <TaskApp /> }],
      title: "Tasks",
    },
    {
      path: "/:workspaceId/task/:taskId",
      element: <TaskApp />,
      children: [{ path: ":taskId", element: <TaskApp /> }],
      title: "Tasks",
    },
    {
      path: "/:workspaceId/task",
      element: <TaskApp />,
      title: "Tasks",
    },
  ],
};

export default TaskAppConfig;
