import React from "react";

const Attachment = (props) => {
  const { height = 80, width = 80 } = props;
  const className = "fill-primaryBlue";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 160 160"
      className={className}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_19588"
            data-name="Rectangle 19588"
            width="72.674"
            height="47.153"
          />
        </clipPath>
      </defs>
      <g
        id="Group_45945"
        data-name="Group 45945"
        transform="translate(-745 -883)"
      >
        <rect
          id="Rectangle_18739"
          data-name="Rectangle 18739"
          width="160"
          height="160"
          rx="5"
          transform="translate(745 883)"
          fillOpacity={0.15}
        />
        <g
          id="Group_45944"
          data-name="Group 45944"
          transform="translate(792 939)"
        >
          <g
            id="Group_45943"
            data-name="Group 45943"
            transform="translate(0 0)"
            clipPath="url(#clipPath)"
          >
            <path
              id="Path_39885"
              data-name="Path 39885"
              d="M56.869,0c.227.057.455.116.683.172a5.81,5.81,0,0,1,4.386,5.485c.029,3.177.008,6.354.007,9.53a2.833,2.833,0,0,1-.03.286,18.288,18.288,0,0,0-5.757-.044v-9.6H5.834v35.46h.594q17.83,0,35.659-.008a.653.653,0,0,1,.69.419,17.581,17.581,0,0,0,3.808,4.991c.122.113.242.229.433.409H6.009A5.814,5.814,0,0,1,.072,42.193.932.932,0,0,0,0,42.016V5.083c.03-.1.067-.194.088-.293A5.792,5.792,0,0,1,4.066.277C4.4.161,4.744.091,5.083,0Z"
              transform="translate(0 0)"
            />
            <path
              id="Path_39886"
              data-name="Path 39886"
              d="M119.964,55.009c-.152.8-.264,1.613-.462,2.4a13.774,13.774,0,1,1,.111-6.608c.153.652.235,1.32.351,1.981Zm-12.324-2.553v-.481q0-2.58,0-5.16a.8.8,0,0,0-.895-.911q-.556-.009-1.112,0c-.648.009-.908.274-.909.927q0,2.58,0,5.16v.466h-.472q-2.58,0-5.16,0c-.644,0-.893.248-.9.881,0,.37,0,.741,0,1.111,0,.664.241.9.914.9q2.561,0,5.121,0h.5v.485q0,2.6,0,5.2a.761.761,0,0,0,.846.868c.37.007.741,0,1.111,0,.689,0,.958-.272.959-.965q0-2.561,0-5.121V55.35h.473q2.58,0,5.16,0c.636,0,.882-.256.884-.9,0-.41.01-.82,0-1.23a.723.723,0,0,0-.778-.764c-.582-.009-1.164,0-1.747,0h-3.991"
              transform="translate(-47.29 -20.538)"
            />
            <path
              id="Path_39887"
              data-name="Path 39887"
              d="M54.422,53.963H26.944a.667.667,0,0,1-.2,0c-.167-.054-.411-.08-.472-.2a.619.619,0,0,1,.116-.494q4.8-7.075,9.62-14.139c.333-.489.633-.481.976.02Q38.744,41.72,40.5,44.3c.4.584.67.584,1.067,0l6.791-9.97c.052-.077.1-.155.157-.229.307-.414.611-.414.91.016.409.586.805,1.18,1.207,1.771q2.445,3.591,4.894,7.18a.522.522,0,0,1,.084.572,17.863,17.863,0,0,0-1.2,9.909c.014.091.023.184.031.276a.953.953,0,0,1-.02.143"
              transform="translate(-13.441 -17.298)"
            />
            <path
              id="Path_39888"
              data-name="Path 39888"
              d="M30.166,30.666a4.635,4.635,0,1,1,4.661-4.623,4.625,4.625,0,0,1-4.661,4.623"
              transform="translate(-13.081 -10.954)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Attachment;
