import React from "react";

const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.749"
      height="28.749"
      viewBox="0 0 28.749 28.749"
    >
      <g
        id="Icon_feather-search"
        data-name="Icon feather-search"
        transform="translate(-3.125 -3.125)"
      >
        <path
          id="Path_34402"
          data-name="Path 34402"
          d="M27.1,15.8A11.3,11.3,0,1,1,15.8,4.5,11.3,11.3,0,0,1,27.1,15.8Z"
          fill="none"
          stroke="#cfcfcf"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.75"
        />
        <path
          id="Path_34403"
          data-name="Path 34403"
          d="M31.12,31.12l-6.145-6.145"
          transform="translate(-1.191 -1.191)"
          fill="none"
          stroke="#cfcfcf"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.75"
        />
      </g>
    </svg>
  );
};

export default SearchIcon;
