import { combineReducers } from "@reduxjs/toolkit";
import dialog from "./dialogSlice";
import message from "./messageSlice";
import navbar from "./navbarSlice";
import navigation from "./navigationSlice";
import settings from "./settingsSlice";
import subscription from "./subscriptionSlice";
import labelDialog from "./labelDialogSlice";

const allStoreReducers = combineReducers({
  navigation,
  settings,
  navbar,
  message,
  dialog,
  labelDialog,
  subscription,
});

export default allStoreReducers;
