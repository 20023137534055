import React from "react";

const List = (props) => {
  const { height = 42, width = 30, className = "stroke-primaryBlue" } = props;
  return (
    <svg
      id="Group_43715"
      data-name="Group 43715"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 23.777 33.76"
      className={className}
    >
      <g
        id="Rectangle_17887"
        data-name="Rectangle 17887"
        transform="translate(0 0)"
        fill="none"
        strokeWidth="1.5"
      >
        <rect width="8.957" height="8.957" rx="2" stroke="none" />
        <rect
          x="0.75"
          y="0.75"
          width="7.457"
          height="7.457"
          rx="1.25"
          fill="none"
        />
      </g>
      <g
        id="Rectangle_17892"
        data-name="Rectangle 17892"
        transform="translate(0 12.401)"
        fill="none"
        strokeWidth="1.5"
      >
        <rect width="8.957" height="8.957" rx="2" stroke="none" />
        <rect
          x="0.75"
          y="0.75"
          width="7.457"
          height="7.457"
          rx="1.25"
          fill="none"
        />
      </g>
      <g
        id="Rectangle_17893"
        data-name="Rectangle 17893"
        transform="translate(0 24.803)"
        fill="none"
        strokeWidth="1.5"
      >
        <rect width="8.957" height="8.957" rx="2" stroke="none" />
        <rect
          x="0.75"
          y="0.75"
          width="7.457"
          height="7.457"
          rx="1.25"
          fill="none"
        />
      </g>
      <line
        id="Line_228"
        data-name="Line 228"
        x2="10.72"
        transform="translate(13.057 4.479)"
        fill="none"
        strokeWidth="1.7"
      />
      <line
        id="Line_229"
        data-name="Line 229"
        x2="10.72"
        transform="translate(13.057 16.88)"
        fill="none"
        strokeWidth="1.7"
      />
      <line
        id="Line_230"
        data-name="Line 230"
        x2="10.72"
        transform="translate(13.057 29.281)"
        fill="none"
        strokeWidth="1.7"
      />
    </svg>
  );
};

export default List;
