import React from "react";

const Online = (props) => {
  const { height, width } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
    >
      <g
        id="Group_42388"
        data-name="Group 42388"
        transform="translate(-3061.226 14621.274)"
      >
        <g
          id="Ellipse_1330"
          data-name="Ellipse 1330"
          transform="translate(3061.226 -14621.274)"
          fill="none"
          stroke="#48bf53"
          strokeWidth="1"
        >
          <circle cx="8" cy="8" r="8" stroke="none" />
          <circle cx="8" cy="8" r="7.5" fill="none" />
        </g>
        <path
          id="Path_37786"
          data-name="Path 37786"
          d="M9.04.844A.925.925,0,0,0,8.371.051a.936.936,0,0,0-1,.264Q5.811,1.871,4.254,3.428L2.966,4.709c-.48-.488-.89-.917-1.314-1.329a.966.966,0,0,0-1.373.01A.954.954,0,0,0,.285,4.743q.994,1.016,2.01,2.011a.941.941,0,0,0,1.371,0q2.557-2.544,5.1-5.1A.907.907,0,0,0,9.04.844"
          transform="translate(3064.525 -14617.013)"
          fill="#48bf53"
        />
      </g>
    </svg>
  );
};

export default Online;
