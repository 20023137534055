import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import Error500 from "components/svgs/errors/Error500";
import { motion } from "framer-motion";
import MobileDetect from "mobile-detect";

const ErrorApp = (props) => {
  const { error, resetErrorBoundary } = props;
  const md = new MobileDetect(window.navigator.userAgent);
  const isSmall = md.mobile();
  console.error(error.message);

  return (
    <div className="flex flex-col flex-1 items-center justify-center p-[16px] bg-whiteSmoke">
      <div className="text-center">
        <motion.div
          initial={{ opacity: 0, scale: 0.6 }}
          animate={{ opacity: 1, scale: 1, transition: { delay: 0.1 } }}
          className="mb-[41px] inline-flex"
        >
          <Error500 width={isSmall ? 250 : 550} height={isSmall ? 250 : 350} />
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}
        >
          <Typography
            color="textSecondary"
            className="text-center text-[28px] mb-[15px] tracking-[0.28px]"
          >
            Well, you broke the internet!
          </Typography>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0, transition: { delay: 0.3 } }}
        >
          <Typography
            variant="subtitle1"
            color="textSecondary"
            className="text-center text-[20px] mb-[35px] tracking-[0.2px]"
          >
            Just kidding, looks like we have an internal issue, please try again
            in couple minutes
          </Typography>
        </motion.div>

        <Button className="bg-red-500 hover:bg-red-500/70 rounded text-white xs:text-sm xmd:text-base p-4">
          Report
        </Button>
        <Button
          onClick={() => window.location.reload()}
          className="ml-4 bg-primaryBlue hover:bg-primaryBlueLight rounded text-white xs:text-sm xmd:text-base p-4"
        >
          Refresh
        </Button>
      </div>
    </div>
  );
};

export default ErrorApp;
