/**
 * Authorization Roles
 */
const authRoles = {
  admin: ["owner"],
  staff: ["owner", "staff"],
  user: ["owner", "staff", "member"],
  onlyGuest: [],
};

export default authRoles;
