import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import firebase from "services/firebase";
import Utilities from "utils/utilities";
import BoardModel from "../model/BoardModel";

export const getBoards = createAsyncThunk(
  "scrumboardApp/boards/getBoards",
  async ({ user, projectId, showCompletedBoards }) => {
    const data = await firebase.getBoards(user, projectId, showCompletedBoards);
    return data;
  }
);

export const toggleBoardFavourite = createAsyncThunk(
  "scrumboardApp/boards/toggleBoardFavourite",
  async ({ user, projectId, boardId, boardData, favourites }, { dispatch }) => {
    const data = {
      ...boardData,
      settings: { ...boardData.settings, favourites },
      modifiedDate: new Date(),
    };
    await firebase.toggleBoardFavourite(user, projectId, boardId, data);

    const message = ` has ${
      favourites ? "marked" : "unmarked"
    } favourites for the board `;
    Utilities.successNotification(user, message, data.name, dispatch);

    return data;
  }
);

export const newBoard = createAsyncThunk(
  "scrumboardApp/boards/newBoard",
  async ({ user, projectId, data }, { dispatch, getState }) => {
    const newBoard = BoardModel(data);
    const response = await firebase.setBoards(user, projectId, newBoard);
    newBoard.id = response;

    const message = " has created the board ";
    Utilities.successNotification(user, message, newBoard.name, dispatch);

    return Promise.resolve(newBoard);
  }
);

const boardsAdapter = createEntityAdapter({});

export const { selectAll: selectBoards, selectById: selectBoardById } =
  boardsAdapter.getSelectors((state) => state.scrumboardApp.boards);

const boardsSlice = createSlice({
  name: "scrumboardApp/boards",
  initialState: boardsAdapter.getInitialState({
    loading: false,
  }),
  reducers: {
    resetBoards: (state, action) => {},
  },
  extraReducers: {
    [getBoards.pending]: (state) => {
      state.loading = true;
    },
    [getBoards.fulfilled]: (state, action) => {
      boardsAdapter.setAll(state, action.payload);
      state.loading = false;
    },
    [newBoard.fulfilled]: boardsAdapter.addOne,
    [toggleBoardFavourite.fulfilled]: (state, action) => {
      boardsAdapter.upsertOne(state, action.payload);
    },
  },
});

export const { resetBoards } = boardsSlice.actions;

export default boardsSlice.reducer;
