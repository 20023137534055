import NavLinkAdapter from "core/NavLinkAdapter";
import { styled, alpha } from "@mui/material/styles";
import Collapse from "@mui/material/Collapse";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useEffect, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import NavBadge from "../../NavBadge";
import NavItem from "../../NavItem";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { navbarCloseMobile } from "store/slice/navbarSlice";
import { Box } from "@mui/system";
import icons from "configs/icons";

const Root = styled("ul")(({ theme, ...props }) => ({
  padding: 0,
  "&.open": {},
  "& > .default-list-item": {
    height: 40,
    width: "100%",
    borderRadius: "6px",
    margin: "0 0 4px 0",
    paddingRight: 12,
    paddingLeft: props.itempadding > 80 ? 80 : props.itempadding,
    color: alpha(theme.palette.text.primary, 0.7),
    "&:hover": {
      color: theme.palette.text.primary,
    },
    "& > .default-list-item-icon": {
      marginRight: 12,
      color: "inherit",
    },
  },
}));

const needsToBeOpened = (location, item) => {
  return location && isUrlInChildren(item, location?.pathname);
};

const isUrlInChildren = (parent, url) => {
  if (!parent.children) {
    return false;
  }

  for (let i = 0; i < parent.children.length; i += 1) {
    if (parent.children[i].children) {
      if (isUrlInChildren(parent.children[i], url)) {
        return true;
      }
    }

    if (
      parent.children[i].url === url ||
      url.includes(parent.children[i].url)
    ) {
      return true;
    }
  }

  return false;
};

const NavVerticalCollapse = (props) => {
  const [open, setOpen] = useState(true);
  const { item, nestedLevel, onItemClick } = props;
  const itempadding = nestedLevel > 0 ? 28 + nestedLevel * 16 : 12;
  const location = useLocation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("lg"));
  const darkMode = useSelector(
    ({ entities }) => entities.settings.current.darkMode
  );
  const NavIcon = icons[item.icon];

  useEffect(() => {
    if (needsToBeOpened(location, props.item)) {
      if (!open) {
        setOpen(true);
      }
    }
    // eslint-disable-next-line
  }, [location, props.item]);

  return useMemo(
    () => (
      <Root
        className={clsx(
          open &&
            `open rounded-xl ${
              darkMode ? "bg-darkSmoke" : "bg-blueTransparent"
            }`
        )}
        itempadding={itempadding}
      >
        <ListItem
          component={item.url ? NavLinkAdapter : "li"}
          button
          className="default-list-item"
          onClick={() => mdDown && dispatch(navbarCloseMobile())}
          to={item.url}
          end={item.end}
          role="button"
        >
          {item.icon && (
            <Box className="w-6 mr-4">
              <NavIcon />
            </Box>
          )}

          <ListItemText
            className="default-list-item-text"
            primary={item.title}
            classes={{ primary: "text-13 font-medium" }}
          />

          {item.badge && <NavBadge className="mx-4" badge={item.badge} />}

          <IconButton
            disableRipple
            className="p-0 focus:bg-transparent hover:bg-transparent"
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
              setOpen(!open);
            }}
            size="large"
          >
            <Icon className="text-16 arrow-icon" color="inherit">
              {open ? "expand_less" : "expand_more"}
            </Icon>
          </IconButton>
        </ListItem>

        {item.children && (
          <Collapse in={open} className="collapse-children">
            {item.children.map((_item) => (
              <NavItem
                key={_item.id}
                type={`vertical-${_item.type}`}
                item={_item}
                nestedLevel={nestedLevel + 1}
                onItemClick={onItemClick}
              />
            ))}
          </Collapse>
        )}
      </Root>
    ),
    [
      item.badge,
      item.children,
      item.icon,
      item.iconClass,
      item.title,
      item.url,
      itempadding,
      nestedLevel,
      onItemClick,
      open,
    ]
  );
};

NavVerticalCollapse.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.string,
    children: PropTypes.array,
  }),
};
NavVerticalCollapse.defaultProps = {};

export default NavVerticalCollapse;
