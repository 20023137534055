import { authRoles } from "auth";
import Loading from "core/Loading";
import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Utilities from "utils/utilities";
import GuestRoutesConfig from "views/GuestRoutesConfig";
import RoutesConfig from "views/RoutesConfig";
import ChatAppConfig from "views/chat/ChatAppConfig";
import DiscussionsAppConfig from "views/discussions/DiscussionAppConfig";
import MembersAppConfig from "views/members/MembersAppConfig";
import NotesAppConfig from "views/notes/NotesAppConfig";
import ProfileAppConfig from "views/profile/ProfileAppConfig";
import ProjectsAppConfig from "views/projects/ProjectsAppConfig";
import ReportingAppConfig from "views/reporting/ReportingAppConfig";
import ScrumBoardAppConfigs from "views/scrumboard/ScrumboardAppConfig";
import SettingsAppConfig from "views/settings/SettingsAppConfig";
import TaskAppConfig from "views/tasks/TaskAppConfig";
const Error404Page = lazy(() => import("views/errors/404/Error404Page"));
const Error500Page = lazy(() => import("views/errors/500/Error500Page"));

const routeConfigs = [
  RoutesConfig,
  ScrumBoardAppConfigs,
  GuestRoutesConfig,
  NotesAppConfig,
  TaskAppConfig,
  SettingsAppConfig,
  ProfileAppConfig,
  MembersAppConfig,
  ChatAppConfig,
  ProjectsAppConfig,
  DiscussionsAppConfig,
  ReportingAppConfig,
];

const routes = [
  // if you want to make whole app auth protected by default change defaultAuth for example:
  // ...Utilities.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
  // The individual route configs which has auth option won't be overridden.
  ...Utilities.generateRoutesFromConfigs(routeConfigs, null),
  // Root should always start with login and it takes care of redirecting to dashboard
  // {
  //   path: "/",
  //   element: <Navigate to="/:workspaceId/dashboard" />,
  //   title: "Dashboard",
  // },
  {
    path: "loading",
    element: <Loading />,
  },
  {
    path: "404",
    element: <Error404Page />,
    auth: authRoles.user,
    title: "Error",
  },
  {
    path: "500",
    element: <Error500Page />,
    auth: authRoles.user,
    title: "Error",
  },
  {
    path: "*",
    element: <Navigate to="404" />,
    title: "Error",
  },
];

export default routes;
