import { createSlice } from "@reduxjs/toolkit";
import { confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";
import firebase from "services/firebase";
import { showMessage } from "store/slice/messageSlice";
import { registerWithFirebase } from "./registerSlice";

export const submitLoginWithFireBase =
  ({ email, password, remember }) =>
  async (dispatch) => {
    if (!firebase.auth) {
      console.warn(
        "Firebase Service didn't initialize, check your configuration"
      );

      return () => false;
    }
    const persistence = firebase.getPersistence(remember);
    firebase.auth.setPersistence(persistence);
    // TODO: Cover scenario if user has login credentials but no valid workspace.
    // We can make an API call to figure that out.
    return firebase.auth
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        return dispatch(loginSuccess());
      })
      .catch((error) => {
        const emailErrorCodes = [
          "auth/email-already-in-use",
          "auth/invalid-email",
          "auth/operation-not-allowed",
          "auth/user-not-found",
          "auth/user-disabled",
        ];
        const passwordErrorCodes = [
          "auth/weak-password",
          "auth/wrong-password",
        ];
        const response = [];

        if (emailErrorCodes.includes(error.code)) {
          response.push({
            type: "email",
            message: error.message,
          });
        }

        if (passwordErrorCodes.includes(error.code)) {
          response.push({
            type: "password",
            message: error.message,
          });
        }

        if (error.code === "auth/invalid-api-key") {
          dispatch(showMessage({ message: error.message }));
        }

        return dispatch(loginError(response));
      });
  };

// TODO: Allow user to create password from Security Page
// TODO: Allow linking of multiple Auth Providers
// TODO: Do the same from marketing site
// TODO: Fix CORS from all requests
// TODO: Test MS signin

export const submitLoginWithProvider = (provider) => async (dispatch) => {
  if (!firebase.auth) {
    console.warn(
      "Firebase Service didn't initialize, check your configuration"
    );

    return () => false;
  }
  const popupConfigurationProvider = firebase.getOAuthProvider(provider);
  popupConfigurationProvider.setCustomParameters({
    // Force user to be asked which account to choose.
    prompt: "login",
  });

  return firebase.auth
    .signInWithPopup(popupConfigurationProvider)
    .then((result) => {
      dispatch(setIsPopupLoading(true));
      const {
        additionalUserInfo: { isNewUser },
        user,
      } = result;
      if (isNewUser) {
        const model = {
          email: user.email,
          displayName: user.displayName,
          uid: user.uid,
        };
        return dispatch(registerWithFirebase(model));
      }
      return dispatch(loginSuccess());
    })
    .catch(async (error) => {
      const emailErrorCodes = [
        "auth/email-already-in-use",
        "auth/invalid-email",
        "auth/operation-not-allowed",
        "auth/user-not-found",
        "auth/user-disabled",
        "auth/popup-blocked",
        "auth/account-exists-with-different-credential",
      ];
      const response = [];

      // An error happened - Edge case to link multiple provider
      if (error.code === "auth/account-exists-with-different-credential") {
        dispatch(setIsPopupLoading(true));
        // Step 2.
        // User's email already exists.
        // The pending Microsoft credential.
        const pendingCred = error.credential;
        // The provider account's email address.
        const email = error.email;

        // Get sign-in methods for this email.
        const methods = await firebase.auth.fetchSignInMethodsForEmail(email);

        // Step 3. TODO
        // If the user has several sign-in methods,
        // the first method in the list will be the "recommended" method to use.
        // if (methods[0] === 'password') {
        //   // Asks the user their password.
        //   // In real scenario, you should handle this asynchronously.
        //   const password = promptUserForPassword(); // TODO: implement promptUserForPassword.
        //   const result = await firebase.auth.signInWithEmailAndPassword(email, password)
        //   // Step 4a.
        //   result.user.linkWithCredential(pendingCred);
        //   // Microsoft account successfully linked to the existing Firebase user.
        //   return dispatch(loginSuccess());
        // }

        // This is custom line - test a scenario where if user only has password login - can he login with microsoft.
        // If not, then we have to remove the line, and built a custom popup to allow user to enter the email and password.
        // TODO: Remove this after implementing step 3
        const filteredMethods = methods.filter((x) => x !== "password");

        // At this point, you should let the user know that they already have an account
        // but with a different provider, and let them validate the fact they want to
        // sign in with this provider.
        // Sign in to provider. Note: browsers usually block popup triggered asynchronously,
        // so in real scenario you should ask the user to click on a "continue" button
        // that will trigger the signInWithPopup.
        const provider = firebase.getOAuthProvider(filteredMethods[0]);
        const result = await firebase.auth.signInWithPopup(provider);
        // Remember that the user may have signed in with an account that has a different email
        // address than the first one. This can happen as Firebase doesn't control the provider's
        // sign in flow and the user is free to login using whichever account they own.
        // Step 4b.
        // Link to Microsoft credential.
        // As we have access to the pending credential, we can directly call the link method.
        const usercred = await result.user.linkWithCredential(pendingCred);

        // Microsoft account successfully linked to the existing Firebase user.
        dispatch(setIsPopupLoading(false));
        return dispatch(loginSuccess());
      }

      if (emailErrorCodes.includes(error.code)) {
        response.push({
          type: "email",
          message: error.message,
        });
      }

      if (error.code === "auth/invalid-api-key") {
        dispatch(showMessage({ message: error.message }));
      }

      return dispatch(loginError(response));
    });
};

export const verifyPasswordResetActionCode = (code) => async (dispatch) => {
  verifyPasswordResetCode(firebase.auth, code).catch((error) => {
    const resetPwdErrorCodes = [
      "auth/expired-action-code",
      "auth/invalid-action-code",
      "auth/user-disabled",
      "auth/user-not-found",
    ];
    const response = [];

    if (resetPwdErrorCodes.includes(error.code)) {
      response.push({
        type: "password",
        message: error.message,
      });
    } else {
      dispatch(showMessage({ message: error.message }));
    }

    dispatch(resetPwdError(response));
  });
};

export const confirmPasswordResetActionCodePwd =
  ({ code, password }) =>
  async (dispatch) => {
    confirmPasswordReset(firebase.auth, code, password)
      .then(() => {
        dispatch(resetPwdSuccess());
      })
      .catch((error) => {
        const resetPwdErrorCodes = [
          "auth/expired-action-code",
          "auth/invalid-action-code",
          "auth/invalid-value-(oob-code)",
          "auth/user-disabled",
          "auth/user-not-found",
          "auth/weak-password",
        ];
        const response = [];

        if (resetPwdErrorCodes.includes(error.code)) {
          response.push({
            type: "password",
            message: error.message,
          });
        } else {
          dispatch(showMessage({ message: error.message }));
        }

        dispatch(resetPwdError(response));
      });
  };

const initialState = {
  isPopupLoading: false,
  success: false,
  errors: [],
};

const loginSlice = createSlice({
  name: "auth/login",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.isPopupLoading = false;
      state.success = true;
      state.errors = [];
    },
    loginError: (state, action) => {
      state.isPopupLoading = false;
      state.success = false;
      state.errors = action.payload;
    },
    resetPwdSuccess: (state, action) => {
      state.success = true;
      state.errors = [];
    },
    resetPwdError: (state, action) => {
      state.isPopupLoading = false;
      state.success = false;
      state.errors = action.payload;
    },
    setIsPopupLoading: (state, action) => {
      state.isPopupLoading = action.payload;
    },
  },
  extraReducers: {},
});

export const {
  loginSuccess,
  loginError,
  resetPwdSuccess,
  resetPwdError,
  setIsPopupLoading,
} = loginSlice.actions;

export default loginSlice.reducer;
