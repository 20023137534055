import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useParams, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProjectTab from "./tabs/ProjectTab";
import withReducer from "store/withReducer";
import Boards from "views/scrumboard/boards/Boards";
import projectsSlice, {
  getProjectById,
  selectProjectById,
} from "./store/projectsSlice";
import Discussions from "views/discussions/Discussions";

// Not using this variavble
// const steps = [
//   {
//     selector: '[data-tour="tab-1"]',
//     content: "Click on reporting to build new and exciting reports",
//   },
//   {
//     selector: '[data-tour="tab-2"]',
//     content: "Click on reporting to build new and exciting reports",
//   },
//   {
//     selector: '[data-tour="tab-3"]',
//     content: "Click on reporting to build new and exciting reports",
//   },
//   {
//     selector: '[data-tour="tab-4"]',
//     content: "Click on reporting to build new and exciting reports",
//   },
// ];

const ProjectDetailApp = ({ add }) => {
  const dispatch = useDispatch();
  const routeParams = useParams();
  const [searchParams] = useSearchParams();
  const [tabValue, setTabValue] = useState(1);

  const user = useSelector(({ auth }) => auth.user);
  const themeColor = user?.data?.settings?.themeColor;
  const projectId = routeParams.projectId;
  const projectData = useSelector((state) =>
    selectProjectById(state, projectId)
  );

  useEffect(() => {
    if (add) setTabValue(0);
    else if (searchParams.has("tab_key")) {
      const key = searchParams.get("tab_key");
      if (key) setTabValue(parseInt(key));
    }
  }, [add, searchParams]);

  // Only time we need to fetch from db if we reload on project page
  useEffect(() => {
    if (!projectData && !add) {
      dispatch(getProjectById({ user, projectId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeTab = (event, value) => {
    setTabValue(value);
  };

  if (add) {
    return <ProjectTab add={add} project={projectData} />;
  }

  return (
    <>
      <div className="mt-5">
        <Tabs
          value={tabValue}
          variant="scrollable"
          scrollButtons={false}
          onChange={handleChangeTab}
          classes={{
            indicator: "bg-transparent",
          }}
          sx={{
            "& button": {
              borderRadius: "9999px",
              border: `1px solid ${themeColor}`,
              color: `${themeColor}`,
              maxHeight: "45px",
              minHeight: "45px",
            },
            "& button.Mui-selected": {
              backgroundColor: `${themeColor}`,
              color: "white",
            },
          }}
        >
          <Tab
            disableRipple
            label="Project"
            data-tour="tab-1"
            className="mx-1 sm:mx-4 px-10 text-base"
          />
          <Tab
            disableRipple
            data-tour="tab-2"
            label="Scrumboard"
            className="mx-1 sm:mx-4 px-10 text-base"
          />
          {/* <Tab
            disableRipple
            data-tour="tab-3"
            label="Team Members"
            className="mx-1 sm:mx-4 px-10 text-base"
          /> */}
          <Tab
            className="mx-1 sm:mx-4 px-10 text-base"
            disableRipple
            label="Discussions"
            data-tour="tab-4"
          />
        </Tabs>
      </div>
      <div className="tabDetailsContainer w-full h-full">
        {tabValue === 0 && <ProjectTab add={add} project={projectData} />}
        {tabValue === 1 && <Boards projectData={projectData} />}
        {tabValue === 2 && <Discussions projectData={projectData} />}
      </div>
    </>
  );
};

export default withReducer("projectApp", projectsSlice)(ProjectDetailApp);
