import { authRoles } from "auth";
import i18next from "i18next";
import ar from "translations/ar";
import en from "translations/en";
import tr from "translations/tr";

i18next.addResourceBundle("en", "navigation", en);
i18next.addResourceBundle("tr", "navigation", tr);
i18next.addResourceBundle("ar", "navigation", ar);

const navigationConfig = [
  {
    id: "applications",
    title: "Menu",
    translate: "MENU",
    type: "group",
    icon: "apps",
    children: [
      {
        id: "dashboard",
        title: "Dashboard",
        translate: "DASHBOARD",
        type: "item",
        icon: "dashboard",
        url: "/:workspaceId/dashboard",
      },
      {
        id: "insights",
        title: "Insights",
        translate: "INSIGHTS",
        type: "collapse",
        icon: "business",
        url: "/:workspaceId/insights",
        children: [
          {
            id: `backlogs`,
            title: `Backlogs`,
            type: "item",
            icon: "backlog",
            url: `/:workspaceId/backlog`,
            target: "_blank",
          },
          {
            id: `sprints`,
            title: `Sprints`,
            type: "item",
            icon: "sprint",
            url: `/:workspaceId/sprint`,
            target: "_blank",
          },
          {
            id: `reporting`,
            title: `Reporting`,
            type: "item",
            icon: "reporting",
            url: `/:workspaceId/reports`,
            target: "_blank",
          },
        ],
      },
      {
        id: "members",
        title: "Members",
        translate: "MEMBERS",
        type: "item",
        icon: "members",
        url: "/:workspaceId/members",
      },
      {
        id: "calendar",
        title: "Calendar",
        translate: "CALENDAR",
        type: "item",
        icon: "calendar",
        url: "/:workspaceId/calendar",
      },
      {
        id: "projects",
        title: "Projects",
        translate: "PROJECTS",
        type: "collapse",
        icon: "projects",
        url: `/:workspaceId/projects`,
        children: [
          //todo. bring the correct add icon from xd, this one might not work on dark mode
          {
            id: `addProject`,
            title: `Add New Project`,
            type: "item",
            icon: "add",
            url: `/:workspaceId/project/add`,
            target: "_blank",
            auth: authRoles.admin,
          },
        ],
      },
      {
        id: "tracker",
        title: "Time tracker",
        translate: "TRACKER",
        type: "item",
        icon: "tracker",
        url: "/:workspaceId/tracker",
      },
      // {
      //   id: "scrumboard",
      //   title: "Scrumboard",
      //   translate: "SCRUMBOARD",
      //   type: "item",
      //   icon: "assessment",
      //   url: "/:workspaceId/scrumboard",
      // },
      {
        id: "notes",
        title: "Notes",
        translate: "NOTES",
        type: "item",
        icon: "notes",
        url: "/:workspaceId/notes",
      },

      {
        id: "task",
        title: "Tasks",
        translate: "TASKS",
        type: "item",
        icon: "tasks",
        url: "/:workspaceId/task",
        // badge: {
        //   title: 0,
        //   bg: "rgb(255, 111, 0)",
        //   fg: "#FFFFFF",
        // },
      },
      {
        id: "chat",
        title: "Chat",
        translate: "CHAT",
        type: "item",
        icon: "chat",
        url: "/:workspaceId/chat",
        badge: {
          title: 0,
          bg: "rgb(0, 185, 217)",
          fg: "#FFFFFF",
        },
      },
      {
        type: "divider",
        id: "menu-divider-1",
      },
      {
        id: "invite",
        title: "INVITE MEMBERS",
        type: "item",
        icon: "invite",
        url: "/:workspaceId/invite",
        auth: authRoles.admin,
      },
      {
        id: "settings",
        title: "SETTINGS",
        type: "item",
        icon: "settings",
        url: "/:workspaceId/settings",
      },
      {
        id: "help",
        title: "HELP",
        type: "link",
        icon: "help",
        url: "https://proman365.com/help",
      },
      {
        id: "version",
        title: "v1.0.0",
        type: "item",
        icon: "Version",
        url: "",
        native: true,
      },
    ],
  },
];

export default navigationConfig;
