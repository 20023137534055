import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import FileDisplay from "./FileDisplay";

const DisplayFileOrImage = (props) => {
  const { url, onClickFunc, className, name, height, width } = props;
  const [imgExists, setImgExists] = useState(null);

  const checkIfImageExists = (url, callback) => {
    const img = new Image();
    img.src = url;

    if (img.complete) {
      callback(true);
    } else {
      img.onload = () => {
        callback(true);
      };

      img.onerror = () => {
        callback(false);
      };
    }
  };
  useEffect(() => {
    checkIfImageExists(url, (exists) => setImgExists(exists));
  }, []);

  if (imgExists === null) return <CircularProgress color="info" />;

  return (
    <>
      {imgExists ? (
        <img
          className={`cursor-pointer ${className ?? "max-h-full rounded-md"}`}
          src={url}
          onClick={() => onClickFunc()}
          width={width}
          height={height}
          alt="uploaded image"
        />
      ) : (
        <FileDisplay src={url} name={name} className={className} />
      )}
    </>
  );
};
export default DisplayFileOrImage;
