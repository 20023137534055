import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  AppBar,
  Button,
  Card,
  CardContent,
  Icon,
  IconButton,
  InputAdornment,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
} from "firebase/auth";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import _ from "utils/lodash";
import Utilities from "utils/utilities";
import * as yup from "yup";
import {
  confirmAccountDeletion,
  getAccountDeletionDate,
} from "../store/settingsSlice";
import { format } from "date-fns";

const schema = yup.object().shape({
  currentPassword: yup
    .string()
    .required("You must enter your current password."),
});

const AccountDeletion = (props) => {
  const { mdDown, userState, classes, loadingSpinner, setLoadingSpinner } =
    props;
  const dispatch = useDispatch();
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const { account_deletion } = useSelector(
    ({ settingsApp }) => settingsApp?.settings
  );
  const defaultValues = {
    currentPassword: "",
  };

  const { control, formState, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;

  const onSubmit = (model) => {
    if (!isValid) return;
    setLoadingSpinner(true);
    const auth = getAuth();
    const { currentUser } = auth;
    const { email } = currentUser;

    const credential = EmailAuthProvider.credential(
      email,
      model.currentPassword
    );
    reauthenticateWithCredential(currentUser, credential)
      .then(() => handleAccountDeletion())
      .catch((error) => {
        Utilities.errorNotification(userState, dispatch, error);
        setLoadingSpinner(false);
      });
  };

  const handleAccountDeletion = () => {
    dispatch(
      confirmAccountDeletion({
        user: userState,
        deleteAccount: !account_deletion,
      })
    );
    setLoadingSpinner(false);
  };

  useEffect(() => {
    dispatch(getAccountDeletionDate(userState));
  }, []);

  return (
    <form
      name="accountDeletionForm"
      noValidate
      className={`flex flex-col flex-1 ${mdDown ? "w-full" : "ml-6"}  my-6`}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Alert severity="warning" className="mb-2">
        Please take a moment to review what will happen when your account is
        deleted as this action is permanent and not reversible.
      </Alert>

      <Card className={`rounded-16 shadow mt-8`}>
        <AppBar position="static" elevation={0} className="bg-primaryBlue">
          <Toolbar className="px-6">
            <Typography
              variant="subtitle1"
              color="inherit"
              className="flex-1 text-lg font-medium text-white"
            >
              Guidelines
            </Typography>
          </Toolbar>
        </AppBar>

        <CardContent>
          <Typography className="font-semibold mt-3 mb-5 text-15">
            Workspaces
          </Typography>
          <Typography>
            Any single user workspaces that you own will be scheduled to be{" "}
            <strong>permanently deleted after 30 days</strong>. You will
            immidiately be removed and lose access to all other workspaces you
            may own.To limit disruptions to other users, consider transfer of
            ownership by contacting our{" "}
            <a
              href="https://proman365.com/contact/"
              target="_blank"
              className="!border-none !bg-transparent"
            >
              <span
                className={`text-primaryBlue xs:text-[0.9em] miniTab:text-[1em] underline mt-1`}
              >
                support team
              </span>
            </a>
            .
          </Typography>
        </CardContent>

        <CardContent>
          <Typography className="font-semibold mt-3 mb-5 text-15">
            Billing
          </Typography>
          <Typography>
            Future billing for all workspaces that are deleted as part of
            account deletion will be stopped. Otherwise, billing for Workspaces
            will continue as usual.
          </Typography>
        </CardContent>

        <CardContent>
          <Typography className="font-semibold mt-3 mb-5 text-15">
            Your User Data
          </Typography>
          <Typography>
            All user data will be scheduled to be{" "}
            <strong>permanently deleted after 30 days</strong>
          </Typography>
        </CardContent>

        <CardContent>
          <Typography className="font-semibold mt-3 mb-5 text-15 text-red-500">
            This action is permanent, and not reversible.
          </Typography>
          <Typography className="mb-2">
            {account_deletion ? (
              <span>
                You account is scheduled to be deleted. If you change your mind,
                then you can undo the deletion process before{" "}
                <strong>
                  {Utilities.formatDate(account_deletion, "yyyy-MM-dd", format)}
                </strong>
              </span>
            ) : (
              <span>
                If you <strong>confirm</strong> the above, then please type in
                your password to continue.
              </span>
            )}
          </Typography>
          <Controller
            name="currentPassword"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                autoComplete="off"
                id="outlined-basic"
                size="small"
                variant="outlined"
                name="currentPassword"
                type="password"
                fullWidth
                required
                error={!!errors.currentPassword}
                helperText={errors?.currentPassword?.message}
                InputLabelProps={{
                  shrink: true,
                  className: classes.label,
                }}
                InputProps={{
                  className: classes.input,
                  type: showCurrentPassword ? "text" : "password",
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowCurrentPassword(!showCurrentPassword)
                        }
                        size="large"
                      >
                        <Icon className="text-20" color="action">
                          {showCurrentPassword
                            ? "visibility"
                            : "visibility_off"}
                        </Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </CardContent>
      </Card>

      <div className="flex w-full justify-between my-[24px]">
        <div className="w-full"></div>

        <div className="flex">
          <Button
            onClick={() => reset(defaultValues)}
            className="bg-lightGray rounded hover:bg-lightGray/70 text-white xs:text-sm xmd:text-base xs:min-w-[80px] xmd:min-w-[110px] max-h-[40px] mr-4"
          >
            Cancel
          </Button>
          <Button
            className={`${
              _.isEmpty(dirtyFields) || !isValid || loadingSpinner
                ? "bg-blueTransparent"
                : "bg-primaryBlue"
            } max-h-[40px] xs:text-sm xmd:text-base text-white rounded hover:bg-primaryBlueLight xs:min-w-[80px] xmd:min-w-[110px]`}
            type="submit"
            disabled={_.isEmpty(dirtyFields) || !isValid || loadingSpinner}
          >
            Save
          </Button>
        </div>
      </div>
    </form>
  );
};

export default AccountDeletion;
