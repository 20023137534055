const Error500 = (props) => {
  const className = "fill-primaryBlue";
  const { height = 300, width = 500 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 469.063 283.285"
      className={className}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_19637"
            data-name="Rectangle 19637"
            width="241.237"
            height="35.029"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-2">
          <rect
            id="Rectangle_19639"
            data-name="Rectangle 19639"
            width="126.715"
            height="14.038"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-3">
          <rect
            id="Rectangle_19640"
            data-name="Rectangle 19640"
            width="98.785"
            height="14.038"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-4">
          <rect
            id="Rectangle_19641"
            data-name="Rectangle 19641"
            width="241.237"
            height="70.043"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-5">
          <rect
            id="Rectangle_19642"
            data-name="Rectangle 19642"
            width="69.926"
            height="70.043"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-6">
          <rect
            id="Rectangle_19645"
            data-name="Rectangle 19645"
            width="82.267"
            height="11.937"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-7">
          <rect
            id="Rectangle_19646"
            data-name="Rectangle 19646"
            width="34.007"
            height="90.843"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-8">
          <rect
            id="Rectangle_19647"
            data-name="Rectangle 19647"
            width="14.37"
            height="53.503"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-9">
          <rect
            id="Rectangle_19649"
            data-name="Rectangle 19649"
            width="82.268"
            height="11.937"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-12">
          <rect
            id="Rectangle_19652"
            data-name="Rectangle 19652"
            width="88.358"
            height="28.424"
            fill="#5b5b5b"
          />
        </clipPath>
      </defs>
      <g
        id="Group_46159"
        data-name="Group 46159"
        transform="translate(-798 -230)"
      >
        <g
          id="Group_46091"
          data-name="Group 46091"
          transform="translate(798 277.963)"
        >
          <path
            id="Path_40063"
            data-name="Path 40063"
            d="M469.063,297.349c0,7.636-105,13.827-234.532,13.827S0,304.985,0,297.349s105-13.827,234.532-13.827,234.532,6.19,234.532,13.827"
            transform="translate(0 -75.854)"
            opacity="0.15"
          />
          <path
            id="Path_40064"
            data-name="Path 40064"
            d="M155.5,119.692v5.794H131.909v-5.794a1.5,1.5,0,0,1,.014-.251,6.408,6.408,0,0,1,6.409-6.17H149.1a6.417,6.417,0,0,1,6.409,6.422"
            transform="translate(1.28 -77.506)"
            fill="#697172"
          />
          <rect
            id="Rectangle_19630"
            data-name="Rectangle 19630"
            width="14.436"
            height="158.544"
            transform="translate(137.769 47.171)"
            fill="#697172"
          />
          <rect
            id="Rectangle_19631"
            data-name="Rectangle 19631"
            width="44.369"
            height="11.937"
            transform="translate(122.801 205.646)"
            fill="#697172"
          />
          <path
            id="Path_40065"
            data-name="Path 40065"
            d="M165.99,281.519v11.937H143.68a8.548,8.548,0,0,0-8.446-7.176H121.621v-4.761Z"
            transform="translate(1.18 -75.874)"
            fill="#606060"
          />
          <path
            id="Path_40066"
            data-name="Path 40066"
            d="M155.5,119.692v5.794h-4.579V283.22h-7.233a12.94,12.94,0,0,0-2.233-6.953,13.366,13.366,0,0,0-4.649-4.216c-.112-.056-.21-.111-.321-.168V258.494h7.217V213.8a13.363,13.363,0,0,0-2.289-7.511,13.193,13.193,0,0,0-4.928-4.384V188.66h7.217V132.3a12.774,12.774,0,0,0-1.955-6.813,5.86,5.86,0,0,0-.544-.81,10.451,10.451,0,0,0-1.313-1.536,13.03,13.03,0,0,0-4.09-2.75,12.8,12.8,0,0,0-3.881-.949,6.408,6.408,0,0,1,6.409-6.17h10.763a6.417,6.417,0,0,1,6.409,6.422"
            transform="translate(1.28 -77.506)"
            fill="#606060"
          />
          <path
            id="Path_40067"
            data-name="Path 40067"
            d="M162.212,96.133A18.282,18.282,0,1,1,143.93,77.85a18.282,18.282,0,0,1,18.283,18.283"
            transform="translate(1.219 -77.85)"
            fill="#68b7d6"
          />
          <path
            id="Path_40068"
            data-name="Path 40068"
            d="M157.2,103.471a15.605,15.605,0,0,1-4.871,5.283"
            transform="translate(1.478 -77.601)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <path
            id="Path_40069"
            data-name="Path 40069"
            d="M147.739,110.807a15.5,15.5,0,0,1-3.4.507"
            transform="translate(1.4 -77.53)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <path
            id="Path_40070"
            data-name="Path 40070"
            d="M131.188,87.134a15.641,15.641,0,0,1,1.437-1.753"
            transform="translate(1.273 -77.776)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <path
            id="Path_40071"
            data-name="Path 40071"
            d="M135.5,83.02a15.53,15.53,0,0,1,16-.4"
            transform="translate(1.315 -77.823)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <path
            id="Path_40072"
            data-name="Path 40072"
            d="M156.282,96.076a12.408,12.408,0,1,1-12.408-12.408,12.408,12.408,0,0,1,12.408,12.408"
            transform="translate(1.275 -77.794)"
            fill="#fff"
          />
          <rect
            id="Rectangle_19632"
            data-name="Rectangle 19632"
            width="24.817"
            height="24.816"
            transform="translate(132.74 5.874)"
            fill="none"
          />
          <path
            id="Path_40073"
            data-name="Path 40073"
            d="M151.983,96.035a8.15,8.15,0,1,1-8.15-8.15,8.15,8.15,0,0,1,8.15,8.15"
            transform="translate(1.316 -77.753)"
            fill="#5594ae"
          />
          <line
            id="Line_418"
            data-name="Line 418"
            y2="11.223"
            transform="translate(140.476 135.392)"
            fill="none"
            stroke="#919a9b"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            id="Line_419"
            data-name="Line 419"
            y2="4.33"
            transform="translate(140.476 199.473)"
            fill="none"
            stroke="#919a9b"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_40074"
            data-name="Path 40074"
            d="M332.872,119.692v5.794h-23.6v-5.794a1.5,1.5,0,0,1,.014-.251,6.408,6.408,0,0,1,6.409-6.17h10.763a6.419,6.419,0,0,1,6.41,6.422"
            transform="translate(3.001 -77.506)"
            fill="#697172"
          />
          <rect
            id="Rectangle_19633"
            data-name="Rectangle 19633"
            width="14.436"
            height="158.544"
            transform="translate(316.856 47.171)"
            fill="#697172"
          />
          <rect
            id="Rectangle_19634"
            data-name="Rectangle 19634"
            width="44.369"
            height="11.937"
            transform="translate(301.89 205.646)"
            fill="#697172"
          />
          <path
            id="Path_40075"
            data-name="Path 40075"
            d="M343.358,281.519v11.937h-22.31a8.548,8.548,0,0,0-8.446-7.176H298.989v-4.761Z"
            transform="translate(2.901 -75.874)"
            fill="#606060"
          />
          <path
            id="Path_40076"
            data-name="Path 40076"
            d="M332.872,119.692v5.794h-4.58V283.22h-7.233a12.931,12.931,0,0,0-2.233-6.953,13.366,13.366,0,0,0-4.649-4.216c-.111-.056-.21-.111-.32-.168V258.494h7.216V213.8a13.348,13.348,0,0,0-2.29-7.511,13.188,13.188,0,0,0-4.926-4.384V188.66h7.216V132.3a12.774,12.774,0,0,0-1.955-6.813,5.944,5.944,0,0,0-.544-.81,10.45,10.45,0,0,0-1.313-1.536,13.03,13.03,0,0,0-4.09-2.75,12.8,12.8,0,0,0-3.881-.949,6.408,6.408,0,0,1,6.409-6.17h10.763a6.419,6.419,0,0,1,6.41,6.422"
            transform="translate(3.001 -77.506)"
            fill="#606060"
          />
          <path
            id="Path_40077"
            data-name="Path 40077"
            d="M339.58,96.133A18.282,18.282,0,1,1,321.3,77.85,18.282,18.282,0,0,1,339.58,96.133"
            transform="translate(2.94 -77.85)"
            fill="#68b7d6"
          />
          <path
            id="Path_40078"
            data-name="Path 40078"
            d="M334.572,103.471a15.6,15.6,0,0,1-4.87,5.283"
            transform="translate(3.199 -77.601)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <path
            id="Path_40079"
            data-name="Path 40079"
            d="M325.106,110.807a15.492,15.492,0,0,1-3.4.507"
            transform="translate(3.121 -77.53)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <path
            id="Path_40080"
            data-name="Path 40080"
            d="M308.556,87.134a15.645,15.645,0,0,1,1.437-1.753"
            transform="translate(2.993 -77.776)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <path
            id="Path_40081"
            data-name="Path 40081"
            d="M312.87,83.02a15.53,15.53,0,0,1,16-.4"
            transform="translate(3.035 -77.823)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <path
            id="Path_40082"
            data-name="Path 40082"
            d="M333.649,96.076a12.408,12.408,0,1,1-12.408-12.408,12.408,12.408,0,0,1,12.408,12.408"
            transform="translate(2.996 -77.794)"
            fill="#fff"
          />
          <rect
            id="Rectangle_19635"
            data-name="Rectangle 19635"
            width="24.816"
            height="24.816"
            transform="translate(311.829 5.874)"
            fill="none"
          />
          <path
            id="Path_40083"
            data-name="Path 40083"
            d="M329.351,96.035a8.15,8.15,0,1,1-8.15-8.15,8.15,8.15,0,0,1,8.15,8.15"
            transform="translate(3.037 -77.753)"
            fill="#5594ae"
          />
          <line
            id="Line_420"
            data-name="Line 420"
            y2="11.223"
            transform="translate(319.564 135.392)"
            fill="none"
            stroke="#919a9b"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            id="Line_421"
            data-name="Line 421"
            y2="4.33"
            transform="translate(319.564 199.473)"
            fill="none"
            stroke="#919a9b"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <rect
            id="Rectangle_19636"
            data-name="Rectangle 19636"
            width="241.237"
            height="35.029"
            transform="translate(113.912 152.23)"
            fill="#fefcf3"
          />
          <path
            id="Path_40084"
            data-name="Path 40084"
            d="M139.428,228.616l-26.611,26.666V240.957l12.314-12.342Z"
            transform="translate(1.094 -76.387)"
            fill="#68b7d6"
          />
          <path
            id="Path_40085"
            data-name="Path 40085"
            d="M167.382,228.616l-34.959,35.029H118.113l34.959-35.029Z"
            transform="translate(1.146 -76.387)"
            fill="#68b7d6"
          />
          <path
            id="Path_40086"
            data-name="Path 40086"
            d="M195.106,228.616l-34.959,35.029h-14.3l34.959-35.029Z"
            transform="translate(1.415 -76.387)"
            fill="#68b7d6"
          />
          <path
            id="Path_40087"
            data-name="Path 40087"
            d="M222.843,228.616l-34.959,35.029H173.574l34.959-35.029Z"
            transform="translate(1.684 -76.387)"
            fill="#68b7d6"
          />
          <path
            id="Path_40088"
            data-name="Path 40088"
            d="M250.58,228.616l-34.959,35.029h-14.31l34.959-35.029Z"
            transform="translate(1.953 -76.387)"
            fill="#68b7d6"
          />
          <path
            id="Path_40089"
            data-name="Path 40089"
            d="M278.3,228.616l-34.959,35.029H229.034l34.959-35.029Z"
            transform="translate(2.222 -76.387)"
            fill="#68b7d6"
          />
          <path
            id="Path_40090"
            data-name="Path 40090"
            d="M306.041,228.616l-34.959,35.029h-14.31l34.959-35.029Z"
            transform="translate(2.491 -76.387)"
            fill="#68b7d6"
          />
          <path
            id="Path_40091"
            data-name="Path 40091"
            d="M333.764,228.616l-34.959,35.029h-14.3l34.959-35.029Z"
            transform="translate(2.76 -76.387)"
            fill="#68b7d6"
          />
          <path
            id="Path_40092"
            data-name="Path 40092"
            d="M352.12,228.616v9.41l-25.577,25.618H312.232l34.959-35.029Z"
            transform="translate(3.029 -76.387)"
            fill="#68b7d6"
          />
          <path
            id="Path_40093"
            data-name="Path 40093"
            d="M351.85,251.514v11.908H339.969Z"
            transform="translate(3.298 -76.165)"
            fill="#68b7d6"
          />
          <g
            id="Group_46053"
            data-name="Group 46053"
            transform="translate(113.912 152.228)"
            opacity="0.3"
            style={{ mixBlendMode: "multiply", isolation: "isolate" }}
          >
            <g id="Group_46052" data-name="Group 46052">
              <g
                id="Group_46051"
                data-name="Group 46051"
                clipPath="url(#clipPath)"
              >
                <path
                  id="Path_40094"
                  data-name="Path 40094"
                  d="M354.054,228.616v35.029h-70.3a78,78,0,0,0-57.2-24.851H112.817V228.616Z"
                  transform="translate(-112.817 -228.616)"
                  fill="#606060"
                />
              </g>
            </g>
          </g>
          <rect
            id="Rectangle_19638"
            data-name="Rectangle 19638"
            width="241.238"
            height="70.043"
            transform="translate(113.915 47.168)"
            fill="#fefcf3"
          />
          <path
            id="Path_40095"
            data-name="Path 40095"
            d="M155.036,124.567l-42.219,42.3V144.336l19.741-19.769Z"
            transform="translate(1.095 -77.396)"
            fill="#68b7d6"
          />
          <path
            id="Path_40096"
            data-name="Path 40096"
            d="M200.006,124.567l-69.9,70.044H112.818v-5.222l64.7-64.822Z"
            transform="translate(1.095 -77.396)"
            fill="#68b7d6"
          />
          <path
            id="Path_40097"
            data-name="Path 40097"
            d="M244.579,124.567l-69.9,70.044H152.2l69.9-70.044Z"
            transform="translate(1.476 -77.396)"
            fill="#68b7d6"
          />
          <path
            id="Path_40098"
            data-name="Path 40098"
            d="M289.116,124.567l-69.9,70.044H196.734l69.9-70.044Z"
            transform="translate(1.909 -77.396)"
            fill="#68b7d6"
          />
          <path
            id="Path_40099"
            data-name="Path 40099"
            d="M333.653,124.567l-69.9,70.044H241.257l69.9-70.044Z"
            transform="translate(2.341 -77.396)"
            fill="#68b7d6"
          />
          <path
            id="Path_40100"
            data-name="Path 40100"
            d="M352.376,127.857v22.519l-44.1,44.2H285.8Z"
            transform="translate(2.773 -77.365)"
            fill="#68b7d6"
          />
          <path
            id="Path_40101"
            data-name="Path 40101"
            d="M351.922,174.8v19.323h-19.28Z"
            transform="translate(3.227 -76.909)"
            fill="#68b7d6"
          />
          <g
            id="Group_46056"
            data-name="Group 46056"
            transform="translate(122.802 91.826)"
            opacity="0.3"
            style={{ mixBlendMode: "screen", isolation: "isolate" }}
          >
            <g id="Group_46055" data-name="Group 46055">
              <g
                id="Group_46054"
                data-name="Group 46054"
                clipPath="url(#clipPath-2)"
              >
                <path
                  id="Path_40102"
                  data-name="Path 40102"
                  d="M241.318,182.832H128.641a7.019,7.019,0,1,1,0-14.038H241.318a7.019,7.019,0,1,1,0,14.038"
                  transform="translate(-121.622 -168.794)"
                  fill="#fefcf3"
                />
              </g>
            </g>
          </g>
          <g
            id="Group_46059"
            data-name="Group 46059"
            transform="translate(122.802 168.33)"
            opacity="0.3"
            style={{ mixBlendMode: "screen", isolation: "isolate" }}
          >
            <g id="Group_46058" data-name="Group 46058">
              <g
                id="Group_46057"
                data-name="Group 46057"
                clipPath="url(#clipPath-3)"
              >
                <path
                  id="Path_40103"
                  data-name="Path 40103"
                  d="M213.388,258.6H128.641a7.019,7.019,0,1,1,0-14.038h84.746a7.019,7.019,0,1,1,0,14.038"
                  transform="translate(-121.622 -244.562)"
                  fill="#fefcf3"
                />
              </g>
            </g>
          </g>
          <g
            id="Group_46062"
            data-name="Group 46062"
            transform="translate(113.912 47.171)"
            opacity="0.3"
            style={{ mixBlendMode: "multiply", isolation: "isolate" }}
          >
            <g id="Group_46061" data-name="Group 46061">
              <g
                id="Group_46060"
                data-name="Group 46060"
                clipPath="url(#clipPath-4)"
              >
                <path
                  id="Path_40104"
                  data-name="Path 40104"
                  d="M112.817,124.567V145.7H226.56a77.948,77.948,0,0,1,34.5,8l-40.9,40.9H282.2l15.853-15.853a78.106,78.106,0,0,1,8.7,15.857h47.3V124.567Z"
                  transform="translate(-112.817 -124.567)"
                  fill="#606060"
                />
              </g>
            </g>
          </g>
          <g
            id="Group_46065"
            data-name="Group 46065"
            transform="translate(234.534 47.168)"
            opacity="0.3"
            style={{ mixBlendMode: "multiply", isolation: "isolate" }}
          >
            <g id="Group_46064" data-name="Group 46064">
              <g
                id="Group_46063"
                data-name="Group 46063"
                clipPath="url(#clipPath-5)"
              >
                <path
                  id="Path_40105"
                  data-name="Path 40105"
                  d="M302.091,162.862a6.236,6.236,0,0,1-1.747,3.271l-28.463,28.474h-39.6V124.564h35.491l32.573,32.583a6.363,6.363,0,0,1,1.747,5.715"
                  transform="translate(-232.28 -124.564)"
                  fill="#606060"
                />
              </g>
            </g>
          </g>
          <path
            id="Path_40106"
            data-name="Path 40106"
            d="M295.793,159.954a4.735,4.735,0,0,1-1.39,3.359L232.319,225.4a4.74,4.74,0,0,1-6.707,0l-62.084-62.084a4.74,4.74,0,0,1,0-6.707L211.143,109l14.469-14.481a4.74,4.74,0,0,1,6.707,0L294.4,156.606a4.73,4.73,0,0,1,1.39,3.348"
            transform="translate(1.573 -77.702)"
          />
          <path
            id="Path_40107"
            data-name="Path 40107"
            d="M295.322,159.954a4.735,4.735,0,0,1-1.39,3.359L231.848,225.4a4.74,4.74,0,0,1-6.707,0l-11.84-11.84,25.811-25.822a35.555,35.555,0,0,0,0-50.29L210.672,109l14.469-14.481a4.74,4.74,0,0,1,6.707,0l62.084,62.084a4.731,4.731,0,0,1,1.39,3.348"
            transform="translate(2.044 -77.702)"
            fill="#5594ae"
          />
          <text
            id="_500"
            data-name="500"
            transform="translate(192.638 93.059)"
            fill="#fff"
            fontSize="35"
            fontFamily="Montserrat-SemiBold, Montserrat"
            fontWeight="600"
            letterSpacing="0.1em"
          >
            <tspan x="0" y="0">
              500
            </tspan>
          </text>
          <path
            id="Path_40108"
            data-name="Path 40108"
            d="M175.767,161.337a1.8,1.8,0,1,1,1.8-1.8,1.8,1.8,0,0,1-1.8,1.8"
            transform="translate(1.688 -77.074)"
            fill="#494947"
          />
          <path
            id="Path_40109"
            data-name="Path 40109"
            d="M176.126,161.337a1.8,1.8,0,1,1,1.8-1.8,1.8,1.8,0,0,1-1.8,1.8"
            transform="translate(1.691 -77.074)"
            fill="#fff"
          />
          <path
            id="Path_40110"
            data-name="Path 40110"
            d="M280.951,157.736a1.8,1.8,0,1,1-1.8,1.8,1.8,1.8,0,0,1,1.8-1.8"
            transform="translate(2.708 -77.074)"
            fill="#494947"
          />
          <path
            id="Path_40111"
            data-name="Path 40111"
            d="M280.592,157.736a1.8,1.8,0,1,1-1.8,1.8,1.8,1.8,0,0,1,1.8-1.8"
            transform="translate(2.705 -77.074)"
            fill="#fff"
          />
          <path
            id="Path_40112"
            data-name="Path 40112"
            d="M228.907,220.626a5.017,5.017,0,0,1-3.571-1.479l-55.677-55.677a5.057,5.057,0,0,1,0-7.142l55.677-55.677a5.169,5.169,0,0,1,7.141,0l55.679,55.677a5.052,5.052,0,0,1,0,7.142l-55.679,55.677a5.011,5.011,0,0,1-3.569,1.479m0-119.852a3.423,3.423,0,0,0-2.438,1.01l-55.677,55.677a3.452,3.452,0,0,0,0,4.876l55.677,55.677a3.528,3.528,0,0,0,4.876,0l55.678-55.677a3.448,3.448,0,0,0,0-4.876l-55.678-55.677a3.424,3.424,0,0,0-2.437-1.01"
            transform="translate(1.632 -77.643)"
            fill="#fff"
          />
          <path
            id="Path_40113"
            data-name="Path 40113"
            d="M425.271,282.457H367.8l4.311-16.65,3.635-14.126,3.039-11.723,3.635-14.116,8.845-34.229h10.541l8.835,34.229,3.645,14.116,3.029,11.723,3.645,14.126Z"
            transform="translate(3.568 -76.746)"
            fill="#68b7d6"
          />
          <path
            id="Path_40114"
            data-name="Path 40114"
            d="M414.178,239.632h-35.5l3.638-14.121H410.54Z"
            transform="translate(3.674 -76.417)"
            fill="#fefcf3"
          />
          <path
            id="Path_40115"
            data-name="Path 40115"
            d="M420.914,265.228H372.069l3.638-14.121h41.569Z"
            transform="translate(3.61 -76.168)"
            fill="#fefcf3"
          />
          <rect
            id="Rectangle_19644"
            data-name="Rectangle 19644"
            width="82.267"
            height="11.937"
            transform="translate(358.968 205.646)"
            fill="#68b7d6"
          />
          <g
            id="Group_46070"
            data-name="Group 46070"
            transform="translate(358.968 205.646)"
            opacity="0.3"
            style={{ mixBlendMode: "multiply", isolation: "isolate" }}
          >
            <g id="Group_46069" data-name="Group 46069">
              <g
                id="Group_46068"
                data-name="Group 46068"
                clipPath="url(#clipPath-6)"
              >
                <path
                  id="Path_40116"
                  data-name="Path 40116"
                  d="M437.786,281.519v11.937H400.435a8.548,8.548,0,0,0-8.446-7.176H355.519v-4.761Z"
                  transform="translate(-355.519 -281.519)"
                  fill="#606060"
                />
              </g>
            </g>
          </g>
          <g
            id="Group_46073"
            data-name="Group 46073"
            transform="translate(394.833 114.869)"
            opacity="0.3"
            style={{ mixBlendMode: "multiply", isolation: "isolate" }}
          >
            <g id="Group_46072" data-name="Group 46072">
              <g
                id="Group_46071"
                data-name="Group 46071"
                clipPath="url(#clipPath-7)"
              >
                <path
                  id="Path_40117"
                  data-name="Path 40117"
                  d="M397.986,282.457c12.3-55.7-6.947-90.843-6.947-90.843H401.58l8.835,34.229,3.645,14.116,3.029,11.723,3.645,14.126,4.311,16.65Z"
                  transform="translate(-391.039 -191.614)"
                  fill="#606060"
                />
              </g>
            </g>
          </g>
          <g
            id="Group_46076"
            data-name="Group 46076"
            transform="translate(384.052 140.968)"
            opacity="0.3"
            style={{ mixBlendMode: "screen", isolation: "isolate" }}
          >
            <g id="Group_46075" data-name="Group 46075">
              <g
                id="Group_46074"
                data-name="Group 46074"
                clipPath="url(#clipPath-8)"
              >
                <path
                  id="Path_40118"
                  data-name="Path 40118"
                  d="M392.522,245.179c-2.864,14.765-7.411,26.3-10.159,25.769s-2.655-12.933.209-27.7,7.411-26.3,10.159-25.769,2.655,12.933-.209,27.7"
                  transform="translate(-380.362 -217.464)"
                  fill="#fefcf3"
                />
              </g>
            </g>
          </g>
          <path
            id="Path_40119"
            data-name="Path 40119"
            d="M97.313,282.457H39.84l4.311-16.65,3.635-14.126,3.039-11.723,3.635-14.116,8.845-34.229H73.847l8.835,34.229,3.645,14.116,3.029,11.723L93,265.807Z"
            transform="translate(0.386 -76.746)"
            fill="#68b7d6"
          />
          <path
            id="Path_40120"
            data-name="Path 40120"
            d="M86.22,239.632h-35.5l3.638-14.121H82.582Z"
            transform="translate(0.492 -76.417)"
            fill="#fefcf3"
          />
          <path
            id="Path_40121"
            data-name="Path 40121"
            d="M92.956,265.228H44.111l3.638-14.121H89.318Z"
            transform="translate(0.428 -76.168)"
            fill="#fefcf3"
          />
          <rect
            id="Rectangle_19648"
            data-name="Rectangle 19648"
            width="82.267"
            height="11.937"
            transform="translate(27.829 205.646)"
            fill="#68b7d6"
          />
          <g
            id="Group_46079"
            data-name="Group 46079"
            transform="translate(27.828 205.646)"
            opacity="0.3"
            style={{ mixBlendMode: "multiply", isolation: "isolate" }}
          >
            <g id="Group_46078" data-name="Group 46078">
              <g
                id="Group_46077"
                data-name="Group 46077"
                clipPath="url(#clipPath-9)"
              >
                <path
                  id="Path_40122"
                  data-name="Path 40122"
                  d="M109.827,281.519v11.937H72.476a8.548,8.548,0,0,0-8.446-7.176H27.561v-4.761Z"
                  transform="translate(-27.56 -281.519)"
                  fill="#606060"
                />
              </g>
            </g>
          </g>
          <g
            id="Group_46082"
            data-name="Group 46082"
            transform="translate(63.692 114.869)"
            opacity="0.3"
            style={{ mixBlendMode: "multiply", isolation: "isolate" }}
          >
            <g id="Group_46081" data-name="Group 46081">
              <g
                id="Group_46080"
                data-name="Group 46080"
                clipPath="url(#clipPath-7)"
              >
                <path
                  id="Path_40123"
                  data-name="Path 40123"
                  d="M70.028,282.457c12.3-55.7-6.947-90.843-6.947-90.843H73.622l8.835,34.229L86.1,239.958l3.029,11.723,3.645,14.126,4.311,16.65Z"
                  transform="translate(-63.081 -191.614)"
                  fill="#606060"
                />
              </g>
            </g>
          </g>
          <g
            id="Group_46085"
            data-name="Group 46085"
            transform="translate(52.913 140.968)"
            opacity="0.3"
            style={{ mixBlendMode: "screen", isolation: "isolate" }}
          >
            <g id="Group_46084" data-name="Group 46084">
              <g
                id="Group_46083"
                data-name="Group 46083"
                clipPath="url(#clipPath-8)"
              >
                <path
                  id="Path_40124"
                  data-name="Path 40124"
                  d="M64.564,245.179c-2.864,14.765-7.411,26.3-10.159,25.769s-2.655-12.933.209-27.7,7.411-26.3,10.16-25.769,2.653,12.933-.21,27.7"
                  transform="translate(-52.404 -217.464)"
                  fill="#fefcf3"
                />
              </g>
            </g>
          </g>
        </g>
        <g
          id="Group_46093"
          data-name="Group 46093"
          transform="translate(988.259 230)"
        >
          <g
            id="Group_46092"
            data-name="Group 46092"
            transform="translate(0 0)"
            clipPath="url(#clipPath-12)"
          >
            <text
              id="Oops_"
              data-name="Oops!"
              transform="translate(0 20.655)"
              fill="#5b5b5b"
              fontSize="25"
              fontFamily="Montserrat-SemiBold, Montserrat"
              fontWeight="600"
            >
              <tspan x="0" y="0" letterSpacing="0.1em">
                Oop
              </tspan>
              <tspan y="0" letterSpacing="0.099em">
                s
              </tspan>
              <tspan y="0">!</tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Error500;
