import React from "react";

const CalendarWhite = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18.804 18.775"
    >
      <g
        id="Group_45772"
        data-name="Group 45772"
        transform="translate(-1643.792 -234.001)"
      >
        <path
          id="Path_39856"
          data-name="Path 39856"
          d="M3.532,3.468c0,.479,0,.948,0,1.416a1.737,1.737,0,0,0,.035.336,1.523,1.523,0,0,0,3-.123,2.421,2.421,0,0,0,.015-.287c0-.444,0-.889,0-1.341h5.631v.12c0,.435,0,.869,0,1.3A1.526,1.526,0,0,0,15.24,5.2a1.633,1.633,0,0,0,.031-.316c0-.428,0-.856,0-1.283V3.476a.153.153,0,0,1,.042-.014c.609,0,1.219-.018,1.827.012A1.776,1.776,0,0,1,18.8,5.188c0,.045,0,.089,0,.133q0,6.806,0,13.612a1.789,1.789,0,0,1-1.275,1.773,1.826,1.826,0,0,1-.5.076q-7.623.006-15.244,0A1.793,1.793,0,0,1,0,19.047c0-.024,0-.048,0-.072Q0,12.128,0,5.281A1.785,1.785,0,0,1,1.321,3.53,1.944,1.944,0,0,1,1.8,3.464c.551-.008,1.1,0,1.653,0,.023,0,.047,0,.084.006m-2.1,5.1v9.855a.9.9,0,0,0,.947.946H16.424a.9.9,0,0,0,.946-.948V8.563Z"
          transform="translate(1643.792 231.989)"
          fill="#fff"
        />
        <path
          id="Path_39857"
          data-name="Path 39857"
          d="M11.816,1.791c0,.366,0,.732,0,1.1a.7.7,0,0,1-1.377.2.714.714,0,0,1-.031-.2c0-.725,0-1.45,0-2.175A.7.7,0,0,1,11.783.5a.85.85,0,0,1,.032.232c0,.352,0,.7,0,1.057"
          transform="translate(1637.741 234)"
          fill="#fff"
        />
        <path
          id="Path_39858"
          data-name="Path 39858"
          d="M32.578,1.8c0,.352,0,.7,0,1.056a.7.7,0,1,1-1.409,0q0-1.066,0-2.133a.705.705,0,1,1,1.409.006V1.8"
          transform="translate(1625.664 233.997)"
          fill="#fff"
        />
        <rect
          id="Rectangle_19524"
          data-name="Rectangle 19524"
          width="2.159"
          height="1.599"
          transform="translate(1657.197 247.914)"
          fill="#fff"
        />
        <rect
          id="Rectangle_19525"
          data-name="Rectangle 19525"
          width="2.152"
          height="1.601"
          transform="translate(1647.036 247.913)"
          fill="#fff"
        />
        <rect
          id="Rectangle_19526"
          data-name="Rectangle 19526"
          width="2.151"
          height="1.601"
          transform="translate(1650.425 247.913)"
          fill="#fff"
        />
        <rect
          id="Rectangle_19527"
          data-name="Rectangle 19527"
          width="2.153"
          height="1.601"
          transform="translate(1653.813 247.913)"
          fill="#fff"
        />
        <rect
          id="Rectangle_19528"
          data-name="Rectangle 19528"
          width="2.153"
          height="1.599"
          transform="translate(1653.81 245.151)"
          fill="#fff"
        />
        <rect
          id="Rectangle_19529"
          data-name="Rectangle 19529"
          width="2.153"
          height="1.598"
          transform="translate(1650.425 245.151)"
          fill="#fff"
        />
        <rect
          id="Rectangle_19530"
          data-name="Rectangle 19530"
          width="2.151"
          height="1.602"
          transform="translate(1657.201 245.148)"
          fill="#fff"
        />
        <rect
          id="Rectangle_19531"
          data-name="Rectangle 19531"
          width="2.151"
          height="1.601"
          transform="translate(1647.037 245.151)"
          fill="#fff"
        />
        <rect
          id="Rectangle_19532"
          data-name="Rectangle 19532"
          width="2.154"
          height="1.596"
          transform="translate(1647.036 242.386)"
          fill="#fff"
        />
        <rect
          id="Rectangle_19533"
          data-name="Rectangle 19533"
          width="2.153"
          height="1.596"
          transform="translate(1653.814 242.387)"
          fill="#fff"
        />
        <rect
          id="Rectangle_19534"
          data-name="Rectangle 19534"
          width="2.154"
          height="1.596"
          transform="translate(1657.201 242.387)"
          fill="#fff"
        />
        <rect
          id="Rectangle_19535"
          data-name="Rectangle 19535"
          width="2.154"
          height="1.596"
          transform="translate(1650.423 242.386)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default CalendarWhite;
