const settingsConfig = {
  layout: {
    style: "layout1", // layout1 layout2 layout3
    config: {}, // checkout default layout configs at app/layouts for example  ../../layout1/Layout1Config.js
  },
  notifications: true,
  chatSidebar: false,
  darkMode: false,
  themeColor: "#00B9D9",
  customScrollbars: true,
  direction: "ltr", // rtl, ltr
  theme: {
    main: "default",
    navbar: "default",
    toolbar: "default",
    footer: "mainThemeDark",
  },
  loginRedirectUrl: "/", // Default redirect url for the logged-in user
};

export default settingsConfig;
