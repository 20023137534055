import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import firebase from "services/firebase";

export const getFilters = createAsyncThunk(
  "taskApp/filters/getFilters",
  async (user) => {
    return await firebase.getFilters(user);
  }
);

const filtersAdapter = createEntityAdapter({});

export const { selectAll: selectFilters, selectById: selectFilterById } =
  filtersAdapter.getSelectors((state) => state.taskApp.filters);

const filtersSlice = createSlice({
  name: "taskApp/filters",
  initialState: filtersAdapter.getInitialState({}),
  reducers: {},
  extraReducers: {
    [getFilters.fulfilled]: filtersAdapter.setAll,
  },
});

export default filtersSlice.reducer;
