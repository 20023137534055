import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { getSubscriptionDetails } from "api/getSubscriptionDetails";
import { formatDistanceToNow, fromUnixTime } from "date-fns";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setSubscriptionDetails } from "store/slice/subscriptionSlice";
import _ from "utils/lodash";

const AlertPanel = () => {
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const subscriptionDetails = useSelector(
    ({ entities }) => entities.subscription
  );
  const [isScreenLocked, setScreenLock] = useState(true);
  const [workspaceInfo, setWorkspaceInfo] = useState();
  const [subscription, setSubscription] = useState();
  const [remainingDays, setDays] = useState();
  const [notification, setNotification] = useState();
  const [buttonText, setButtonText] = useState("Upgrade now");

  useEffect(() => {
    if (
      user &&
      user.role.includes("owner") &&
      user.data.currentWorkspace &&
      user.workspaces.length > 0 &&
      user.data.emailVerified
    ) {
      const idx = user.workspaces.findIndex(
        (x) => x.id === user.data.currentWorkspace
      );
      setWorkspaceInfo(user.workspaces[idx]);
    }
  }, [user?.data?.currentWorkspace]);

  useEffect(() => {
    setScreenLock(user.data.isLocked);
  }, [user.data.isLocked]);

  useEffect(() => {
    if (
      workspaceInfo &&
      workspaceInfo.stripe_subscription_id &&
      workspaceInfo.plan !== "Starter" && // No need of warning banner if on free plan
      !subscription &&
      !isScreenLocked
    )
      getSubscription();
  }, [workspaceInfo]);

  const getSubscription = async () => {
    if (!_.isEmpty(subscriptionDetails)) {
      const data = { data: subscriptionDetails.subscription };
      setSubscription(data);
      checkSubscriptionStatus(data);
      return;
    }
    const result = await getSubscriptionDetails(
      workspaceInfo.stripe_subscription_id
    );
    if (result && result.body) {
      const data = result.body;
      setSubscription(data);
      dispatch(setSubscriptionDetails(data));
      checkSubscriptionStatus(data);
    }
  };

  const checkSubscriptionStatus = (data) => {
    let unixTimestamp = data?.subscription?.current_period_end;
    if (!unixTimestamp) return;
    const subscription_status = workspaceInfo.stripe_subscription_status;
    const isDue =
      subscription_status === "incomplete" ||
      subscription_status === "incomplete_expired" ||
      data.subscription.status === "past_due";
    if (subscription_status === "trialing" || isDue) {
      unixTimestamp = data.subscription.trial_end;
    }
    const days = formatDistanceToNow(fromUnixTime(unixTimestamp), {
      addSuffix: true,
    });
    //const days = diff.replace(/[^0-9]/g, "");

    /*
    1. Trialing
    2. Trialing after 7 days and less than 14
    3. Trialing expired 3 days before invoice
    4. Unpaid (Card is null) - Transfer ownership scenario
    5. Unpaid (Card is not null) - Somehow payment failed
    */

    if (subscription_status === "trialing" && days) {
      setDays(days);
      setNotification(`You have ${days} remaining in your free trial.`);
    } else if (subscription_status == "unpaid" && parseInt(days) <= 3) {
      setDays(days);
      setNotification(`Your free trial expired ${days} ago.`);
    } else if (subscription_status === "unpaid") {
      setDays(days);
      setButtonText("Update now");
      if (data.card === null) {
        setNotification(
          "Your account is currently missing a payment method. To avoid an interruption in service please enter your payment details."
        );
      } else if (data.card !== null) {
        setNotification(
          "Our most recent payment attempt has failed. Please update your payment information or contact your bank for more information."
        );
      }
    } else if (isDue) {
      setDays(days);
      setNotification(`Your free trial will expire ${days}.`);
    }
  };

  if (!workspaceInfo) {
    return null;
  }

  // Combine top with menu toolbar to stick both of them.
  return (
    <Stack sx={{ width: "100%", borderRadius: 0 }}>
      {!isScreenLocked && remainingDays && (
        <Alert
          sx={{ borderRadius: 0 }}
          className="flex items-center justify-between px-10 py-2.5 text-sm text-363636"
          severity="warning"
          action={
            <Link
              to={`/${user.data.currentWorkspace}/settings/2`}
              className="!no-underline"
            >
              {buttonText}
            </Link>
          }
        >
          {notification}
        </Alert>
      )}
    </Stack>
  );
};

export default AlertPanel;
