import {
  Avatar,
  Hidden,
  Icon,
  Input,
  Paper,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import { updateStarredChats } from "auth/store/userSlice";
import Mute from "components/svgs/icons/chat/Mute";
import ToggleRightReverse from "components/svgs/icons/chat/ToggleRightReverse";
import Unmute from "components/svgs/icons/chat/Unmute";
import FeatherHeart from "components/svgs/icons/general/FeatherHeart";
import FilledHeart from "components/svgs/icons/general/FilledHeart";
import { useState } from "react";
import { useDispatch } from "react-redux";
import _ from "utils/lodash";
import Utilities from "utils/utilities";
import StatusIcon from "../../components/StatusIcon";
import SharedFiles from "./SharedFiles";

const ChatDetails = (props) => {
  const { user, isVisible, closeDetails, contact, classes } = props;
  const dispatch = useDispatch();
  const theme = useTheme();
  const [mute, setMute] = useState(false);

  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  const toggleFavouriteChat = (e, contactId) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(updateStarredChats({ user, contactId }));
  };

  if (!contact) return null;

  return isMd && !isVisible ? null : (
    <Paper
      square
      elevation={3}
      className={`${
        !isVisible
          ? "animate-close-chat-details animate-expand-chat-box flex flex-col items-center py-10 my-[32px] mx-[12px] overflow-y-auto overflow-x-hidden"
          : isSm && isVisible
          ? "flex flex-col items-center overflow-y-auto overflow-x-hidden w-full xs:my-6 h-full pb-10"
          : "animate-open-chat-details animate-shrink-chat-box flex flex-col items-center py-10 my-[32px] mx-[12px] overflow-y-auto overflow-x-hidden"
      } `}
    >
      <Hidden smUp>
        <div
          onClick={closeDetails}
          className={`flex self-start relative top-10 cursor-pointer`}
        >
          <ToggleRightReverse width={70} height={70} />
        </div>
      </Hidden>
      <Avatar
        className={`${isSm ? "xs:h-16 xs:w-16 sm:h-20 sm:w-20" : "h-14 w-14"} `}
        src={`${contact.photoURL}`}
        style={{ backgroundColor: contact.more?.color }}
        alt="avatar"
      >
        {contact?.displayName && contact?.displayName[0]?.toLocaleUpperCase()}
      </Avatar>
      <Typography
        className={`${
          isSm ? "xs:text-[18px] sm:text-[22px]" : "text-[18px]"
        }  font-semibold mt-3`}
      >
        {contact?.displayName}
      </Typography>
      <Typography
        className={`text-mute ${
          isSm ? "xs:text-sm sm:text-md" : "text-sm "
        } mt-1`}
      >
        {contact?.email}
      </Typography>
      <div
        className={`flex justify-center items-center ${Utilities.getUserStatusBgClassName(
          contact.status
        )} mt-3 py-2 px-8 rounded`}
      >
        <StatusIcon width={"23px"} height={"23px"} status={contact?.status} />
        <Typography
          className={`${Utilities.getUserStatusClassName(
            contact.status
          )} text-sm ml-2`}
        >
          {_.startCase(contact.status)}
        </Typography>
      </div>
      <div className={`${isSm ? "w-full xs:px-3 sm:px-6" : ""}`}>
        <Typography className="mx-2 px-2 mt-10">
          Search in conversation
        </Typography>
        <Toolbar className="px-2 mx-4 border-2 rounded mt-2">
          <Icon className="text-primaryBlue">search</Icon>
          <Input
            placeholder="Search"
            className="flex flex-1 px-4"
            disableUnderline
            fullWidth
            inputProps={{
              "aria-label": "Search",
              className: classes.input + " !p-2",
            }}
          />
        </Toolbar>
      </div>
      <div
        className={`${isSm ? "xs:px-3 sm:px-6" : ""} flex flex-col mt-6 w-full`}
      >
        <div className={`flex mt-4 ml-4`}>
          <Box
            className={`cursor-pointer ${
              isSm
                ? "xs:h-[20px] xs:w-[24px] sm:h-[28px] sm:w-[30px] "
                : "h-[20px] w-[24] "
            }`}
            onClick={(e) => toggleFavouriteChat(e, contact.id)}
          >
            {user?.data?.starred.includes(contact.id) ? (
              <FilledHeart />
            ) : (
              <FeatherHeart />
            )}
          </Box>
          <Typography className={`${isSm ? "text-[18px]" : ""} ml-3`}>
            Add to favourites
          </Typography>
        </div>
        <div
          className="flex mt-4 ml-5 cursor-pointer"
          onClick={() => setMute(!mute)}
        >
          {mute ? (
            <Mute height={24} width={26} />
          ) : (
            <Unmute height={24} width={26} />
          )}
          <Typography className={`${isSm ? "text-[18px]" : ""} ml-3`}>
            {mute ? "Unmute" : "Mute"} conversation
          </Typography>
        </div>
      </div>
      <SharedFiles />
    </Paper>
  );
};

export default ChatDetails;
