import { authRoles } from "auth";
import { lazy } from "react";
import TrackerApp from "./tracker/TrackerApp";
import TaskApp from "./tasks/TaskApp";
import SettingsApp from "./settings/SettingsApp";
import MembersApp from "./members/MembersApp";
import ChatApp from "./chat/ChatApp";
const InviteUsers = lazy(() => import("views/onboarding/InviteUsers"));
const CalendarApp = lazy(() => import("./calendar/CalendarApp"));
const DashboardApp = lazy(() => import("./dashboard/DashboardApp"));
const Backlog = lazy(() => import("./insights/BacklogItems"));
const InsightsApp = lazy(() => import("./insights/InsightsApp"));
const Sprint = lazy(() => import("./insights/Sprint"));
const ProfileApp = lazy(() => import("./profile/ProfileApp"));
const ProjectsApp = lazy(() => import("./projects/ProjectsApp"));
const Reporting = lazy(() => import("./reporting/ReportingApp"));
const Boards = lazy(() => import("./scrumboard/boards/Boards"));
const TrackerReport = lazy(() => import("./tracker/report/TrackerReport"));

const RoutesConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: "/:workspaceId/profile",
      element: <ProfileApp />,
      title: "Profile",
    },
    {
      path: "/:workspaceId/dashboard",
      element: <DashboardApp />,
      title: "Dashboard",
    },
    {
      path: "/:workspaceId/calendar",
      element: <CalendarApp />,
      title: "Calendar",
    },
    {
      path: "/:workspaceId/members",
      element: <MembersApp />,
      title: "Members",
    },
    {
      path: "/:workspaceId/chat",
      element: <ChatApp />,
      title: "Chats",
    },
    {
      path: "/:workspaceId/chat/:selectedId",
      element: <ChatApp />,
      title: "Chats",
    },
    {
      path: "/:workspaceId/projects",
      element: <ProjectsApp />,
      title: "Projects",
    },
    {
      path: "/:workspaceId/project/:projectId/scrumboards/boards",
      element: <Boards />,
      title: "Scrumboard",
      showMembers: true,
    },
    {
      path: "/:workspaceId/invite",
      element: <InviteUsers />,
      title: "Invite Members",
    },
    {
      path: "/:workspaceId/tracker",
      element: <TrackerApp />,
      title: "Time Tracker",
    },
    {
      path: "/:workspaceId/tracker/report",
      element: <TrackerReport />,
      title: "Tracker Report",
    },
    {
      path: "/:workspaceId/settings",
      element: <SettingsApp />,
      title: "Settings",
    },
    {
      path: "/:workspaceId/settings/:tabId",
      element: <SettingsApp />,
      title: "Settings",
    },
    {
      path: "/:workspaceId/backlog",
      element: <Backlog />,
      title: "Backlogs",
    },
    {
      path: "/:workspaceId/insights",
      element: <InsightsApp />,
      title: "Insights",
    },
    {
      path: "/:workspaceId/sprint",
      element: <Sprint />,
      title: "Sprints",
    },
    {
      path: "/:workspaceId/reports",
      element: <Reporting />,
      title: "Reporting",
    },
    {
      path: "/:workspaceId/task",
      element: <TaskApp />,
      title: "Tasks",
    },
  ],
};

export default RoutesConfig;

/**
 * Lazy load Example
 */
/*
import React, { lazy } from 'react';
const Example = React.lazy(() => import('./Example'));

const ExampleConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : 'example',
            element: <Example/>
        }
    ]
};

export default ExampleConfig;

*/
