import {
  Card,
  Menu,
  SwipeableDrawer,
  Switch,
  TextField,
  useMediaQuery,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import { alpha, styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import PageSimple from "core/PageSimple";
import Utilities from "utils/utilities";
const primaryColor =
  document.documentElement.style.getPropertyValue("--color-primary") ||
  "#00B9D9";

export const Root = styled(PageSimple)(({ theme }) => ({
  "& .PageSimple-header": {
    minHeight: 136,
    //height: 136,
  },
  "& .PageSimple-rightSidebar": {
    width: 288,
    border: 0,
    padding: "12px 0",
  },
  "& .PageSimple-content": {
    maxHeight: "100%",
    "& canvas": {
      maxHeight: "100%",
    },
  },

  "& .PageSimple-toolbar": {
    width: "100%",
    maxWidth: 1120,
    display: "flex",
    minHeight: "auto",
    height: "auto",
    aliginItesm: "flex-end",
  },
}));

export const StyledInput = styled(TextField)(({ theme }) => ({
  "& fieldset": {
    borderRadius: "33px",
    background: "transparent",
  },
  "& input": {
    paddingLeft: "26px",
    borderRadius: "33px !important",
    // backgroundColor: "transparent",
    fontSize: "14px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "18px",
      padding: "16.5px 14px 16px 35px",
    },
  },
  "& .MuiIcon-root": {
    color: theme.palette.primary.main,
  },
}));

export const StyledNumberInput = styled(TextField)(({ theme }) => ({
  "& input": {
    display: "flex",
    justifyContent: "center",
    paddingLeft: "26px",
    borderRadius: "33px !important",
    fontSize: "14px",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      fontSize: "30px",
      paddingLeft: "0px",
    },
  },
  "& .MuiIcon-root": {
    color: theme.palette.primary.main,
  },
}));

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: "17px",
    fontWeight: 600,
    fontFamily: "poppins, sans-serif",
    border: 0,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "15px",
    fontWeight: 450,
    fontFamily: "poppins, sans-serif",
    borderBottom: 0,
  },
  [`&.${tableCellClasses.root}`]: {
    lineHeight: "2.5rem",
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`&.${tableRowClasses.root}`]: {
    background: "0% 0% no-repeat padding-box",
    boxShadow: "0px 2px 8px #0000000F",
    backgroundColor: theme.palette.background.paper,
  },
}));

export const useStyles = makeStyles((theme) => ({
  input: {
    padding: "0px 5px",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    border: "none",
    outline: "none",
    background: theme.palette.background.paper,
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
      lineHeight: "1.5rem",
    },
    "&:focus": {
      boxShadow:
        "var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
    },
  },
  inputTextArea: {
    padding: "10px 20px",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    background: theme.palette.background.paper,
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
      lineHeight: "1.5rem",
    },
  },
  thaiTextFieldInputProps: {
    padding: "12px 0",
    paddingLeft: "18px",
    outline: "none",
    border: "none",
    border: `1px solid ${primaryColor}`,
    borderRadius: "5px",
    background: theme.palette.background.paper,
  },
  hideIcon: {
    "& .MuiSelect-outlined img": {
      display: "none",
    },
  },
  grayIcon: {
    "& .MuiSvgIcon-root": {
      color: "gray",
    },
  },
  whiteIcon: {
    "& .MuiSvgIcon-root": {
      color: "white",
    },
  },
}));

export const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(-1),
    minWidth: 130,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export const StyledSwipeableDrawer = styled(SwipeableDrawer)((props) => {
  const { theme, size } = props;
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));
  const width =
    size == "medium"
      ? isSmall
        ? "95%"
        : mdDown
        ? "90%"
        : lgDown
        ? "85%"
        : "75%"
      : isSmall
      ? "90%"
      : mdDown
      ? "60%"
      : lgDown
      ? "45%"
      : "35%";

  return {
    "& .MuiDrawer-paper": {
      backgroundColor: theme.palette.background.default,
      width: width,
    },
  };
});

export const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  backgroundColor: "#ffffff00",
  transitionProperty: "box-shadow",
  transitionDuration: theme.transitions.duration.short,
  transitionTimingFunction: theme.transitions.easing.easeInOut,
}));

export const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 50,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(24px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: `${primaryColor}`,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: `${primaryColor}`,
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: `${primaryColor}`,
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
    // color: `${primaryColor}`,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export const useStyledFormInputStyles = makeStyles((theme) => ({
  input: {
    borderRadius: "5px",
    fontSize: "0.875rem",
    "& .MuiInputBase-input": {
      padding: "10px 20px",
      background: theme.palette.background.paper,
    },
  },
  label: {
    fontSize: "0.875rem",
  },
  inputTextArea: {
    background: theme.palette.background.paper,
    fontSize: "0.875rem",
    "& .MuiInputBase-root": {
      padding: "10px 20px",
    },
  },
}));

export const modalstyle = () => {
  return {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    backgroundColor: (theme) => theme.palette.background.default,
  };
};

export const StyledScrollbar = styled((props) => <div {...props} />)(
  ({ theme }) => ({
    "&": { minHeight: "100%", minWidth: "100%" },
    "&::WebkitScrollbar": {
      width: "0.4em",
      height: "5px",
      borderRadius: "15px",
    },
    "&::WebkitScrollbarTrack": {
      WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::WebkitScrollbarThumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      //outline: "1px solid slategrey",
    },
  })
);

export const AutocompleteStyleOverrides = () => {
  return {
    "& .MuiOutlinedInput-root": {
      padding: "4px 10px",
    },
    "& .MuiChip-root": {
      height: "fit-content",
      padding: "5px",
      margin: "0px 5px",
    },
    "& .MuiAutocomplete-endAdornment": {
      top: "calc(50% - 20px)",
    },
    "& .MuiAutocomplete-clearIndicator": {
      color: "red",
    },
    "& .MuiAutocomplete-popupIndicator": {
      color: `${primaryColor}`,
    },
  };
};

export const AutocompleteTextFieldStyleOverrides = () => {
  return {
    "& .MuiOutlinedInput-root": {
      fontSize: "0.875rem",
      backgroundColor: (theme) => theme.palette.background.paper,
    },
    "& .MuiInputBase-input": {
      backgroundColor: (theme) => theme.palette.background.paper,
    },
  };
};

export const DatePickerStyleOverrides = () => {
  return {
    "& .MuiInputBase-input": {
      backgroundColor: (theme) => theme.palette.background.paper,
    },
  };
};

export const BasicSwitchStyleOverrides = (color) => {
  return {
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: color,
    },
    "& .MuiSwitch-switchBase.Mui-checked.Mui-disabled": {
      color: Utilities.shadeColor(color, 20),
    },
    "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
      backgroundColor: color,
    },
  };
};
