import { Icon, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import ArrowLeft from "components/svgs/icons/general/ArrowLeft";
import _ from "utils/lodash";

const TabHeader = (props) => {
  const { title, subtitle, onClick, tabNumber, toggleSettingsDrawer } = props;
  return (
    <div className="flex w-full bg-blueTransparent px-5 py-8 justify-between">
      <div className="flex">
        <div className="arrow-container mt-2">
          <Box onClick={onClick} className="cursor-pointer">
            <ArrowLeft />
          </Box>
        </div>
        <div className="text-container ml-5">
          <Typography className="text-2xl font-medium text-primaryBlue">
            {title}
          </Typography>
          <Typography className="text-base">{subtitle}</Typography>
        </div>
      </div>
      <div>
        {tabNumber === 2 && _.isFunction(toggleSettingsDrawer) && (
          <IconButton
            className="text-primaryBlue ml-2 xs:px-0 xmd:px-2"
            onClick={() => toggleSettingsDrawer()}
            size="small"
          >
            <Icon className="xs:text-[0.85em] xmd:text-[1.2em]">settings</Icon>
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default TabHeader;
