import CloseIcon from "@mui/icons-material/Close";
import { AppBar, Button, DialogActions, DialogContent } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import DeleteIcon from "components/svgs/icons/general/DeleteIcon";

const ConfirmDeleteDialog = (props) => {
  const { onClose, open, deleteFunc, deleteData, title, message } = props;
  const deleteHandler = () => {
    deleteFunc(deleteData);
    onClose();
  };
  return (
    <Dialog maxWidth="xs" fullWidth onClose={onClose} open={open}>
      <AppBar className="bg-dndText px-4 py-2" position="static" elevation={0}>
        <div className="flex justify-between items-center w-full h-[48px] pl-[12px]">
          <Typography
            className="text-lg text-white"
            variant="subtitle1"
            color="inherit"
          >
            {title ? title : "Confirm Deletion"}
          </Typography>
          <CloseIcon className="cursor-pointer text-white" onClick={onClose} />
        </div>
      </AppBar>
      <DialogContent classes={{ root: "p-0" }}>
        <div className="flex ml-4 px-2 items-center">
          <DeleteIcon />
          <Typography className="w-8/12 ml-[16px] my-[10px] text-base">
            {message
              ? message
              : "Are you sure you want to permanently remove this item?"}
          </Typography>
        </div>
      </DialogContent>
      <DialogActions className="mb-[4px] mt-4">
        <Button
          variant="outlined"
          className="text-base px-4 text-gray min-w-[5em] border-5 border-gray bg-white hover:bg-white uppercase rounded"
          onClick={onClose}
        >
          No
        </Button>
        <Button
          className=" rounded text-base px-4 text-white bg-red-500 hover:bg-red-600/50 min-w-[6.5em] uppercase"
          onClick={deleteHandler}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDeleteDialog;
