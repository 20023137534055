import { ThemeProvider } from "@mui/material/styles";
import NavbarToggleFab from "./NavbarToggleFab";
import { memo } from "react";
import { useSelector } from "react-redux";
import { selectNavbarTheme } from "store/slice/settingsSlice";
import NavbarStyle1 from "./NavbarStyle";

const NavbarWrapperLayout1 = (props) => {
  const config = useSelector(
    ({ entities }) => entities.settings.current.layout.config
  );
  const navbar = useSelector(({ entities }) => entities.navbar);

  const navbarTheme = useSelector(selectNavbarTheme);

  return (
    <>
      <ThemeProvider theme={navbarTheme}>
        <>{config.navbar.style === "style-1" && <NavbarStyle1 />}</>
      </ThemeProvider>

      {config.navbar.display && !config.toolbar.display && !navbar.open && (
        <NavbarToggleFab />
      )}
    </>
  );
};

export default memo(NavbarWrapperLayout1);
