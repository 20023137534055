import { combineReducers } from "@reduxjs/toolkit";
import chat from "./chatSlice";
import state from "./stateSlice";

const reducer = combineReducers({
  chat,
  state,
});

export default reducer;
