import React from "react";

const Profile = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100%"
      viewBox="0 0 96 96"
      enableBackground="new 0 0 96 96"
      space="preserve"
      className="rounded-[40px]"
    >
      <path
        fill="#EAEBEF"
        opacity="1.000000"
        stroke="none"
        d="
M1.315139,89.228745 
	C1.000000,61.045094 1.054662,33.089931 0.923408,5.135643 
	C0.908767,2.017234 1.139296,0.857074 4.852661,0.883466 
	C34.303661,1.092787 63.757008,1.047727 93.209007,0.927540 
	C96.215454,0.915271 97.109398,1.459997 97.090942,4.697741 
	C96.932510,32.485325 97.000000,60.274197 97.000000,88.531342 
	C95.170616,90.394737 93.503281,89.299133 91.863670,88.597733 
	C84.520203,85.456314 77.210815,82.234764 69.716698,79.458031 
	C64.729294,77.610085 61.367966,73.007332 60.787148,68.860779 
	C60.148563,64.301895 61.394009,58.433544 65.529678,54.833096 
	C69.397751,51.465618 70.028175,47.638355 68.695549,43.166565 
	C67.351555,38.656624 66.942581,34.009720 66.574455,29.384619 
	C65.832863,20.067474 60.009125,14.069355 50.510948,13.666991 
	C43.937206,13.388514 38.047920,15.615033 33.044308,19.872950 
	C28.981024,23.330679 27.909348,27.979942 28.837788,33.022705 
	C29.569521,36.997063 29.881895,40.814934 28.857513,44.836529 
	C28.009619,48.165253 28.795862,51.525368 31.238470,54.156189 
	C34.587402,57.763172 35.735939,62.406364 36.585159,66.884048 
	C37.551647,71.980011 34.515682,76.371864 30.293552,78.403450 
	C21.472918,82.647728 12.390600,86.426659 3.099110,89.611954 
	C2.633376,89.771614 2.111804,89.598457 1.315139,89.228745 
z"
      />
      <path
        fill="#787F87"
        opacity="1.000000"
        stroke="none"
        d="
M0.999999,89.494728 
	C10.408232,85.254395 19.813026,81.500114 29.226818,77.768524 
	C32.008022,76.666061 32.461037,73.698982 34.175560,71.795418 
	C36.372269,69.356506 35.773773,67.606796 34.533905,64.899460 
	C32.794228,61.100750 32.186489,57.088810 28.838221,53.761288 
	C26.271830,51.210789 25.966938,46.448677 27.411882,42.854748 
	C28.442484,40.291393 28.466688,38.193176 27.818521,35.768227 
	C25.896284,28.576666 26.984972,22.092392 33.120396,17.358166 
	C40.744476,11.475266 52.027996,11.267793 59.105247,14.333954 
	C67.059677,17.780142 68.466545,23.327250 68.320511,30.866632 
	C68.242699,34.884106 68.689537,39.025848 70.346794,43.022686 
	C72.433029,48.054096 70.561806,52.854206 66.177704,55.820370 
	C64.821960,56.737629 64.062599,57.847515 63.998299,59.484787 
	C63.952709,60.645630 64.156563,62.007919 63.629593,62.923893 
	C61.700779,66.276550 60.896111,68.706047 64.203629,72.383408 
	C66.833488,75.307335 69.054741,77.624687 72.852661,79.023338 
	C80.796478,81.948822 88.489700,85.554779 96.642921,88.940628 
	C97.000000,97.000000 97.000000,97.000000 89.070236,97.000000 
	C62.022820,97.000000 34.975407,97.000000 7.927990,97.000000 
	C1.000000,97.000000 1.000000,97.000000 0.999999,89.494728 
z"
      />
    </svg>
  );
};

export default Profile;
