import { lazy } from "react";
import { authRoles } from "auth";
const PlanApp = lazy(() => import("./tabs/PlanTab"));

const SettingsAppConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.user,
  routes: [
    {
      path: "/:workspaceId/settings/plan",
      element: <PlanApp />,
      title: "Plan",
    },
  ],
};

export default SettingsAppConfig;
