import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import firebase from "services/firebase";

export const getTimelines = createAsyncThunk(
  "profileApp/timeline/getTimelines",
  async ({ user, tabValue, startDate, endDate }) => {
    const data = await firebase.getTimelines(
      user,
      tabValue,
      startDate,
      endDate
    );
    return data;
  }
);

export const addNewTimeline = createAsyncThunk(
  "profileApp/timeline/addNewTimeline",
  async ({ user, timeline }, { dispatch }) => {
    await firebase.addNewTimeline(user, timeline);
    return timeline;
  }
);

const timelineAdapter = createEntityAdapter({
  selectId: (item) => item.id,
  sortComparer: (a, b) => {
    // Keep the "all IDs" array sorted based on time
    // const firstDate = new Date(a.createdDate.seconds * 1000).toDateString();
    // const lastDate = new Date(b.createdDate.seconds * 1000).toDateString();
    return b.createdDate.seconds - a.createdDate.seconds;
  },
});

export const { selectAll: selectTimelines } = timelineAdapter.getSelectors(
  (state) => state.profileApp.timeline
);

const timelineSlice = createSlice({
  name: "profileApp/timeline",
  initialState: timelineAdapter.getInitialState({}),
  reducers: {},
  extraReducers: {
    [getTimelines.fulfilled]: timelineAdapter.setAll,
    [addNewTimeline.fulfilled]: timelineAdapter.addOne,
  },
});

export default timelineSlice.reducer;
