const BellIcon = (props) => {
  const { color = "#cfcfcf" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 27.425 32.269"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_17884"
            data-name="Rectangle 17884"
            width="27.425"
            height="32.269"
            fill={color}
          />
        </clipPath>
      </defs>
      <g id="Group_41109" data-name="Group 41109" transform="translate(0 0)">
        <g
          id="Group_41108"
          data-name="Group 41108"
          transform="translate(0 0)"
          clipPath="url(clipPath)"
        >
          <path
            id="Path_37756"
            data-name="Path 37756"
            d="M14.026,0a2.9,2.9,0,0,1,1.391.744,2.493,2.493,0,0,1,.71,1.825c0,.314-.095.7.054.92.133.2.54.207.823.309a10.246,10.246,0,0,1,7.011,8.028,11.114,11.114,0,0,1,.165,2.13c.018,2.76.011,5.52,0,8.282a.73.73,0,0,0,.243.575q1.4,1.382,2.78,2.786a.819.819,0,0,1,.2.487c.029.427.009.855.009,1.3H0c0-.508-.011-1,.009-1.489,0-.1.115-.217.2-.3Q1.6,24.2,3,22.813a.741.741,0,0,0,.243-.575c-.008-2.771,0-5.543-.008-8.314A10.511,10.511,0,0,1,4.3,9.087a10.283,10.283,0,0,1,6.573-5.413c.33-.1.45-.222.431-.569a3.131,3.131,0,0,1,.275-1.778A2.544,2.544,0,0,1,13.223.058C13.281.044,13.337.02,13.4,0ZM6.474,24.164H20.948c0-.081.012-.142.012-.2,0-3.421.02-6.841-.015-10.261a7.6,7.6,0,0,0-.343-2.225,7.17,7.17,0,0,0-7.973-4.934,7.142,7.142,0,0,0-6.151,6.91c-.047,3.513-.015,7.029-.017,10.544,0,.05.008.1.012.17"
            transform="translate(0 0.001)"
            fill={color}
          />
          <path
            id="Path_37757"
            data-name="Path 37757"
            d="M6.855,18.983h6.438a3.1,3.1,0,0,1-1.44,2.645,3.014,3.014,0,0,1-3.49.041,3.134,3.134,0,0,1-1.509-2.687"
            transform="translate(3.634 10.063)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

export default BellIcon;
