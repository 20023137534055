import { DatePicker } from "@mui/lab";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import {
  AppBar,
  Button,
  Card,
  CardContent,
  Checkbox,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Utilities from "utils/utilities";
import {
  addProject,
  deleteProject,
  updateProject,
} from "../store/projectsSlice";
import { useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import ColorPicker from "components/pickers/ColorPicker";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { selectMembers } from "views/members/store/membersSlice";
import format from "date-fns/format";
import _ from "utils/lodash";
import { selectBoards } from "views/scrumboard/store/boardsSlice";
import ConfirmDeleteDialog from "components/modals/ConfirmDeleteDialog";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UploadImage from "components/upload/UploadImage";
import UploadMedia from "components/upload/UploadMedia";
import {
  DatePickerStyleOverrides,
  useStyledFormInputStyles,
} from "settings/styleOverrides";
import { getCustomFields } from "views/settings/store/settingsSlice";
import Photo from "components/svgs/icons/general/Photo";

// TODO: Need more testing for all possible scenarios

yup.addMethod(yup.array, "unique", function (field, message) {
  return this.test("unique", message, (array) => {
    const uniqueData = Array.from(
      new Set(array.map((row) => row[field]?.toLowerCase()))
    );
    const isUnique = array.length === uniqueData.length;
    if (isUnique) {
      return true;
    }
    const index = array.findIndex(
      (row, i) => row[field]?.toLowerCase() !== uniqueData[i]
    );
    if (array[index][field] === "") {
      return true;
    }
    return this.createError({
      path: `${this.path}.${index}.${field}`,
      message,
    });
  });
});

const schema = yup.object().shape({
  name: yup.string().required("You must enter a project name."),
  budget: yup.number().typeError("Must be a number type").positive().nullable(),
  currency: yup.string().required("You must enter a currency."),
  startDate: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .required("You must enter start date"),
  dueDate: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .min(yup.ref("startDate"), "Due date can't be before start date"),
  resources: yup
    .array()
    .unique("email", "This email has already been added.")
    .of(
      yup.object().shape({
        role: yup.string().required("Please choose the role."),
        email: yup.string().required("Please choose a resource."),
      })
    ),
});

const ProjectTab = (props) => {
  const { add, project } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyledFormInputStyles();
  const datepickerStyles = DatePickerStyleOverrides();
  const routeParams = useParams();
  const user = useSelector(({ auth }) => auth.user);
  const boards = useSelector(selectBoards);
  const [logo, setProjectLogo] = useState({ url: "", file: "" });
  const [confirmDialog, setConfirmDialog] = useState(false);

  const members = useSelector(selectMembers);
  const customFieldsType = useSelector(
    ({ settingsApp }) => settingsApp?.settings?.projectCustomFields
  );
  const workspaceId = routeParams.workspaceId;
  const TYPES = Utilities.getCustomFieldTypes();

  const startDate = project?.startDate?.seconds
    ? format(new Date(project.startDate.seconds * 1000), "yyyy-MM-dd hh:mm")
    : project?.startDate
    ? format(new Date(project.startDate), "yyyy-MM-dd hh:mm")
    : "";

  const dueDate = project?.dueDate?.seconds
    ? format(new Date(project.dueDate.seconds * 1000), "yyyy-MM-dd hh:mm")
    : project?.dueDate
    ? format(new Date(project.dueDate), "yyyy-MM-dd hh:mm")
    : "";

  const defaultValues = {
    id: add ? "" : project?.id,
    name: add ? "" : project?.name,
    archive: add ? false : project?.archive,
    description: add ? "" : project?.description,
    startDate: add ? "" : startDate,
    dueDate: add ? "" : dueDate,
    budget: add ? "" : project?.budget,
    currency: add ? "" : project?.currency,
    billable: add ? false : project?.billable,
    billType: add ? "hourly" : project?.billType,
    color: add ? "" : project?.color,
    timeApproval: add ? false : project?.timeApproval,
    client: add ? "" : project?.client,
    customFields: add ? [] : _.cloneDeep(project?.customFields),
    resources: add
      ? [{ email: user?.data?.email, role: "admin" }]
      : _.cloneDeep(project?.resources),
    showInNavbar: add ? false : project?.showInNavbar,
    status: add ? "not-started" : project?.status,
    type: add ? "internal" : project?.type,
    logo: add ? "" : project?.logo,
    media: add ? [] : project?.media || [],
    users: add ? [user?.uid] : project?.users,
  };

  const {
    control,
    formState,
    handleSubmit,
    reset,
    watch,
    setValue,
    clearErrors,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const projectForm = watch();

  const { isValid, errors, dirtyFields } = formState;
  const disabled = add
    ? false
    : Utilities.checkUserPermissionsForProject(
        project?.resources,
        user?.data?.email
      );

  // For some reason - useForm is not updating its values on navigating away
  // so need to force it to reset
  useEffect(() => {
    reset(defaultValues);
  }, [routeParams]);

  useEffect(() => {
    if (!user?.data?.currentWorkspace) return;
    dispatch(getCustomFields({ user, type: "project" }));
  }, [user?.data?.currentWorkspace]);

  useEffect(() => {
    Utilities.setCustomFields(customFieldsType, defaultValues, TYPES, reset);
  }, [customFieldsType, routeParams]);

  const onUploadAttachment = (file, url) => {
    setValue("logo", url, { shouldDirty: true });
    setProjectLogo({ ...logo, url: url, file: file });
  };

  const updateMedia = (media) => {
    setValue("media", media, { shouldDirty: true });
  };

  const onSubmit = (model) => {
    if (!isValid) return;
    const data = {
      ...model,
      id: defaultValues.id,
      workspaceId: workspaceId,
      createdBy: add ? user?.data?.email : project?.createdBy,
      logo: logo.file ? logo : projectForm.logo,
      media: projectForm.media,
      users: projectForm.users,
      customFields: _.cloneDeep(projectForm.customFields),
      resources: _.cloneDeep(projectForm.resources),
    };
    if (data.dueDate === null) data.dueDate = "";
    if (add) {
      dispatch(
        addProject({
          user,
          data,
          isUpdateMediaDirty: dirtyFields.media,
          isUpdateLogoDirty: dirtyFields.logo,
        })
      ).then((res) => {
        navigate(`/${workspaceId}/project/${res.payload.id}`);
      });
    } else {
      dispatch(
        updateProject({
          user,
          data,
          isUpdateMediaDirty: dirtyFields.media,
          isUpdateLogoDirty: dirtyFields.logo,
        })
      );
      reset(data);
    }
  };

  const handleAddFieldsResources = () => {
    const resources = [
      ...projectForm.resources,
      { email: "", role: "contributor" },
    ];
    setValue("resources", resources, { shouldDirty: true });
  };

  const handleRemoveFieldsResources = (index) => {
    // Sync react state
    const resources = [...projectForm.resources];
    resources.splice(index, 1);
    setValue("resources", resources, { shouldDirty: true });

    // IMPORTANT: This is required
    dirtyFields.resources = true;
    clearErrors("resources");
  };

  const setUsers = (id) => {
    const users = [...projectForm.users, id];
    setValue("users", users, { shouldDirty: true });
  };

  const deleteProjectHandler = () => {
    dispatch(
      deleteProject({
        projectId: project.id,
        workspaceId: workspaceId,
        data: project,
        user,
      })
      // TODO: figure out how to rediret to projects page and pick latest data
    ).then(() => navigate(`/${workspaceId}/dashboard`));
  };

  const item = Utilities.itemOpacityConfig();

  if (!add && project == undefined) return null;

  return (
    <div className={`px-4 pb-6 ${add ? "pt-12" : "pt-16 sm:pt-12"}`}>
      <motion.div
        variants={Utilities.containerConfig(0.05)}
        initial="hidden"
        animate="show"
      >
        <form
          name="projectForm"
          className=""
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="lg:flex">
            <div className="left-side flex flex-col flex-1 md:ltr:pr-[20px] md:rtl:pl-[20px] mb-4">
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="bg-primaryBlue rounded-t-xl m-0"
                >
                  <Typography className="font-medium text-xl text-white">
                    General Information
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="mb-4 mt-2 flex justify-center">
                    <UploadImage
                      imgSrc={projectForm.logo ? projectForm.logo : <Photo />}
                      title="Upload Logo"
                      size="large"
                      className={`w-20 h-20 ${
                        projectForm.logo
                          ? "object-cover"
                          : "flex justify-center items-center"
                      } rounded-lg border-[2.5px] border-primaryBlue ${
                        disabled ? "cursor-auto" : "cursor-pointer"
                      }`}
                      onChange={onUploadAttachment}
                      alt="project logo"
                      disabled={disabled}
                    />
                  </div>
                  <div className="mb-8">
                    <Typography className="font-semibold mb-4 text-15">
                      Project Name
                    </Typography>

                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="outlined-basic"
                          size="small"
                          variant="outlined"
                          name="name"
                          autoComplete="off"
                          placeholder="Tell us the name of the project?"
                          fullWidth
                          disabled={disabled}
                          error={!!errors.name}
                          helperText={errors?.name?.message}
                          InputProps={{
                            className: classes.input,
                          }}
                          InputLabelProps={{
                            shrink: true,
                            className: classes.label,
                          }}
                        />
                      )}
                    />
                    {defaultValues.archive && (
                      <small className="italic text-mute">
                        This project is in archive mode.
                      </small>
                    )}
                  </div>

                  <div className="mb-8">
                    <Typography className="font-semibold mb-4 text-15">
                      Description
                    </Typography>
                    <Controller
                      name="description"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="outlined-basic"
                          size="small"
                          variant="outlined"
                          name="description"
                          placeholder="Tell us more about this project?"
                          autoComplete="off"
                          fullWidth
                          disabled={disabled}
                          multiline
                          rows={4}
                          InputProps={{
                            className: classes.inputTextArea,
                          }}
                          InputLabelProps={{
                            shrink: true,
                            className: classes.label,
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="mb-8 flex w-full xs:flex-col xmd:flex-row ">
                    <div className="mr-6 w-full">
                      <Typography className="font-semibold mb-4 text-15">
                        Start Date
                      </Typography>

                      <Controller
                        name="startDate"
                        control={control}
                        defaultValue={undefined}
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            value={value}
                            onChange={onChange}
                            disabled={disabled}
                            renderInput={(_props) => (
                              <TextField
                                {..._props}
                                autoComplete="off"
                                className="w-full"
                                size="small"
                                disabled={disabled}
                                error={!!errors.startDate}
                                helperText={errors?.startDate?.message}
                                InputLabelProps={{
                                  shrink: true,
                                  className: classes.label,
                                }}
                                sx={datepickerStyles}
                              />
                            )}
                          />
                        )}
                      />
                    </div>

                    <div className="w-full">
                      <Typography className="font-semibold mb-4 text-15">
                        Due Date
                      </Typography>
                      <Controller
                        name="dueDate"
                        control={control}
                        defaultValue={undefined}
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            value={value}
                            onChange={onChange}
                            disabled={disabled}
                            renderInput={(_props) => (
                              <TextField
                                {..._props}
                                autoComplete="off"
                                className="w-full"
                                size="small"
                                disabled={disabled}
                                error={!!errors.dueDate}
                                helperText={errors?.dueDate?.message}
                                InputLabelProps={{
                                  shrink: true,
                                  className: classes.label,
                                }}
                                sx={datepickerStyles}
                              />
                            )}
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="mb-8 flex xs:flex-col xmd:flex-row gap-y-5 gap-x-8 w-full">
                    <div className="w-full">
                      <Typography className="font-semibold mb-4 text-15">
                        Project Status
                      </Typography>
                      <Controller
                        name="status"
                        control={control}
                        defaultValue="not-started"
                        render={({ field: { onChange, value } }) => (
                          <Select
                            value={value}
                            onChange={onChange}
                            id="status"
                            name="status"
                            size="small"
                            disabled={disabled}
                            fullWidth
                          >
                            <MenuItem value={"not-started"}>
                              Not Started
                            </MenuItem>
                            <MenuItem value={"active"}>Active</MenuItem>
                            <MenuItem value={"hold"}>On Hold</MenuItem>
                            <MenuItem value={"closed"}>Closed</MenuItem>
                          </Select>
                        )}
                      />
                    </div>
                    <div className="w-full">
                      <Typography className="font-semibold mb-4 text-15">
                        Project Type
                      </Typography>
                      <Controller
                        name="type"
                        control={control}
                        defaultValue="internal"
                        render={({ field: { onChange, value } }) => (
                          <Select
                            value={value}
                            onChange={onChange}
                            id="type"
                            name="type"
                            size="small"
                            disabled={disabled}
                            fullWidth
                          >
                            <MenuItem value={"internal"}>Internal</MenuItem>
                            <MenuItem value={"external"}>External</MenuItem>
                          </Select>
                        )}
                      />
                    </div>
                    <div className="w-full">
                      <Typography className="font-semibold mb-4 text-15">
                        Project Color
                      </Typography>
                      <Controller
                        name="color"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <ColorPicker
                            color={value}
                            handleColorChange={onChange}
                            modalParent={true}
                            className="border-1"
                            disabled={disabled}
                          />
                        )}
                      />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="bg-primaryBlue rounded-t-xl"
                >
                  <Typography className="font-medium text-xl text-white">
                    Team
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className=" xmd:px-4 mb-6">
                    <Typography className="font-semibold mb-4 text-15">
                      Client
                    </Typography>

                    <Controller
                      name="client"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="client"
                          size="small"
                          name="client"
                          variant="outlined"
                          placeholder="Name of the client"
                          autoComplete="off"
                          fullWidth
                          disabled={disabled}
                          InputProps={{
                            className: classes.input,
                          }}
                          InputLabelProps={{
                            shrink: true,
                            className: classes.label,
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="xmd:px-4 mb-12">
                    <Typography className="font-semibold mb-6 text-15">
                      Member
                    </Typography>

                    {projectForm.resources &&
                      projectForm.resources.map((resource, index) => (
                        <div
                          key={index}
                          className=" w-full flex justify-left items-center mb-8"
                        >
                          <Controller
                            name={`resources[${index}].email`}
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                value={value}
                                onChange={onChange}
                                select
                                fullWidth
                                autoComplete="off"
                                label="Email"
                                name="email"
                                size="small"
                                className={
                                  "xs:min-w-[6rem] xs:max-w-[9rem] xmd:max-w-[75%]"
                                }
                                disabled={
                                  index === 0 || value === user?.data?.email
                                    ? true
                                    : disabled
                                }
                                error={
                                  !!errors?.resources &&
                                  !!errors?.resources[index]?.email
                                }
                                helperText={
                                  errors?.resources &&
                                  errors?.resources[index]?.email?.message
                                }
                                InputProps={{
                                  className: classes.input,
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                  className: classes.label,
                                }}
                              >
                                {members.map((member) => (
                                  <MenuItem
                                    key={member.id}
                                    value={member.email}
                                    onClick={() => setUsers(member.id)}
                                  >
                                    {member.email}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          />

                          <Controller
                            name={`resources[${index}].role`}
                            control={control}
                            defaultValue="contributor"
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                value={value}
                                onChange={onChange}
                                select
                                autoComplete="off"
                                label="Role"
                                name="role"
                                size="small"
                                className="xs:ml-2 xs:min-w-[6rem] xmd:ml-8"
                                sx={{ width: 250 }}
                                disabled={
                                  index === 0 ||
                                  projectForm.resources[index].email ===
                                    user?.data?.email
                                    ? true
                                    : disabled
                                }
                                error={
                                  !!errors?.resources &&
                                  !!errors?.resources[index]?.role
                                }
                                helperText={
                                  errors?.resources &&
                                  errors?.resources[index]?.role?.message
                                }
                                InputProps={{
                                  className: classes.input,
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                  className: classes.label,
                                }}
                              >
                                <MenuItem key={0} value="admin">
                                  admin
                                </MenuItem>
                                <MenuItem key={1} value="manager">
                                  manager
                                </MenuItem>
                                <MenuItem key={2} value="contributor">
                                  contributor
                                </MenuItem>
                              </TextField>
                            )}
                          />
                          <IconButton
                            className="xs:ml-3 xmd:ml-4 xs:max-w-[10px]"
                            disabled={
                              projectForm.resources.length === 1 ||
                              disabled ||
                              index === 0 ||
                              projectForm.resources[index].email ===
                                user?.data?.email
                            }
                            onClick={() => handleRemoveFieldsResources(index)}
                          >
                            <RemoveCircleOutline className="xs:text-3xl xmd:text-md" />
                          </IconButton>
                          <IconButton
                            className="xs:ml-3 xmd:ml-4 xs:max-w-[10px]"
                            disabled={
                              disabled
                            }
                            onClick={handleAddFieldsResources}
                          >
                            <AddCircleOutline className="xs:text-3xl xmd:text-md" />
                          </IconButton>
                        </div>
                      ))}
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="bg-primaryBlue rounded-t-xl"
                >
                  {" "}
                  <Typography className="font-medium text-xl text-white">
                    Custom Fields
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className=" xmd:px-4 mb-8">
                    {projectForm.customFields &&
                      projectForm.customFields.map((inputField, index) => {
                        if (!inputField.active) return;
                        return (
                          <div
                            key={inputField.id}
                            className="flex items-center mb-8"
                          >
                            <div className="mx-[8px] w-full">
                              <div className="flex items-center">
                                {index === 0 && (
                                  <Typography className="font-semibold mb-4 text-15">
                                    Name
                                  </Typography>
                                )}
                              </div>
                              <Controller
                                name={`customFields[${index}].fieldName`}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    autoComplete="off"
                                    name="fieldName"
                                    variant="outlined"
                                    size="small"
                                    disabled={disabled}
                                    InputProps={{
                                      className: classes.input,
                                    }}
                                    fullWidth
                                  />
                                )}
                              />
                            </div>
                            <div className="mx-[8px] w-full">
                              <div className="flex items-center">
                                {index === 0 && (
                                  <Typography className="font-semibold mb-4 text-15">
                                    Value
                                  </Typography>
                                )}
                              </div>
                              {(inputField.fieldType === TYPES.STRING ||
                                inputField.fieldType === TYPES.NUMBER) && (
                                <Controller
                                  name={`customFields[${index}].fieldValue`}
                                  control={control}
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      autoComplete="off"
                                      name="fieldValue"
                                      type={
                                        inputField.fieldType === "string"
                                          ? "text"
                                          : "number"
                                      }
                                      variant="outlined"
                                      InputProps={{
                                        className: classes.input,
                                      }}
                                      fullWidth
                                      disabled={disabled}
                                    />
                                  )}
                                />
                              )}
                              {inputField.fieldType === TYPES.BOOLEAN && (
                                <Controller
                                  name={`customFields[${index}].fieldValue`}
                                  control={control}
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      autoComplete="off"
                                      name="fieldValue"
                                      select
                                      variant="outlined"
                                      InputProps={{
                                        className: classes.input,
                                      }}
                                      fullWidth
                                      disabled={disabled}
                                    >
                                      <MenuItem value="true">True</MenuItem>
                                      <MenuItem value="false">False</MenuItem>
                                    </TextField>
                                  )}
                                />
                              )}
                              {inputField.fieldType === TYPES.DATE && (
                                <Controller
                                  name={`customFields[${index}].fieldValue`}
                                  control={control}
                                  render={({ field: { onChange, value } }) => (
                                    <DatePicker
                                      value={value}
                                      onChange={onChange}
                                      disabled={disabled}
                                      renderInput={(_props) => (
                                        <TextField
                                          {..._props}
                                          autoComplete="off"
                                          name="fieldValue"
                                          variant="outlined"
                                          className="w-full border border-solid border-primaryBlue rounded-[5px]"
                                          inputProps={{
                                            ..._props.inputProps,
                                            className: "p-3",
                                          }}
                                          sx={datepickerStyles}
                                          disabled={disabled}
                                        />
                                      )}
                                    />
                                  )}
                                />
                              )}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="right-side flex flex-col lg:w-320 mb-4">
              <Card
                component={motion.div}
                variants={item}
                className="w-full mb-3 rounded-16 shadow"
              >
                <AppBar
                  position="static"
                  elevation={0}
                  className="bg-primaryBlue"
                >
                  <Toolbar className="xs:px-2 xmd:px-8 bg-primaryBlue">
                    <Typography className="font-medium text-xl text-white">
                      Financial Information
                    </Typography>
                  </Toolbar>
                </AppBar>

                <CardContent>
                  <div className="xmd:px-4 mb-6 flex justify-between">
                    <div className="flex flex-col mb-2 mr-2">
                      <Typography className="font-semibold mb-4 text-15">
                        Budget
                      </Typography>

                      <Controller
                        name="budget"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            autoComplete="off"
                            id="outlined-basic"
                            size="small"
                            variant="outlined"
                            name="budget"
                            placeholder="100"
                            disabled={disabled}
                            fullWidth
                            error={!!errors.budget}
                            helperText={errors?.budget?.message}
                            InputProps={{
                              className: classes.input,
                            }}
                            InputLabelProps={{
                              shrink: true,
                              className: classes.label,
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="flex flex-col mb-2">
                      <Typography className="font-semibold mb-4 text-15">
                        Currency
                      </Typography>

                      <Controller
                        name="currency"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            autoComplete="off"
                            id="outlined-basic"
                            size="small"
                            variant="outlined"
                            name="currency"
                            placeholder="USD"
                            disabled={disabled}
                            fullWidth
                            error={!!errors.currency}
                            helperText={errors?.currency?.message}
                            InputProps={{
                              className: classes.input,
                            }}
                            InputLabelProps={{
                              shrink: true,
                              className: classes.label,
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                  {projectForm.billable && (
                    <div className="xmd:px-4 mb-6">
                      <Typography className="font-semibold mb-4 text-15">
                        Bill Type
                      </Typography>
                      <Controller
                        name="billType"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            value={value}
                            onChange={onChange}
                            id="billType"
                            name="billType"
                            size="small"
                            disabled={disabled}
                            fullWidth
                          >
                            <MenuItem value={"fixed"}>Fixed</MenuItem>
                            <MenuItem value={"hourly"}>Hourly</MenuItem>
                          </Select>
                        )}
                      />
                    </div>
                  )}
                  <div className="xmd:px-4 flex items-center">
                    <Typography className="font-semibold mr-2 text-15">
                      Is this project billable?
                    </Typography>
                    <Controller
                      name="billable"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Checkbox
                          checked={value}
                          name="billable"
                          onClick={() => onChange(!projectForm.billable)}
                          disabled={disabled}
                        />
                      )}
                    />
                  </div>
                </CardContent>
              </Card>

              <Card
                component={motion.div}
                variants={item}
                className="w-full mb-3 rounded-16 shadow"
              >
                <AppBar
                  position="static"
                  elevation={0}
                  className="bg-primaryBlue"
                >
                  <Toolbar className="xs:px-2 xmd:px-8 bg-primaryBlue">
                    <Typography className="font-medium text-xl text-white">
                      Privacy
                    </Typography>
                  </Toolbar>
                </AppBar>
                <CardContent>
                  <div className="flex items-center xmd:px-4 w-full justify-between">
                    <Typography className="font-semibold mr-4 text-15 max-w-[80%]">
                      Does this project require time approval?
                    </Typography>
                    <Controller
                      name="timeApproval"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Checkbox
                          checked={value}
                          name="timeApproval"
                          onClick={() => onChange(!value)}
                          disabled={disabled}
                          className="float-right"
                        />
                      )}
                    />
                  </div>
                </CardContent>
                <CardContent>
                  <div className="flex items-center xmd:px-4 w-full justify-between">
                    <Typography className="font-semibold mr-4 text-15">
                      Show this project in navbar?
                    </Typography>
                    <Controller
                      name="showInNavbar"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Checkbox
                          checked={value}
                          name="showInNavbar"
                          onClick={() => onChange(!value)}
                          disabled={disabled}
                          className="float-right"
                        />
                      )}
                    />
                  </div>
                </CardContent>
                <CardContent>
                  <div className="flex items-center xmd:px-4 w-full justify-between">
                    <Typography className="font-semibold mr-4 text-15">
                      Do you want to archive it?
                    </Typography>
                    <Controller
                      name="archive"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Checkbox
                          checked={value}
                          name="archive"
                          onClick={() => onChange(!value)}
                          disabled={disabled}
                          className="float-right"
                        />
                      )}
                    />
                  </div>
                </CardContent>
              </Card>

              <Card
                component={motion.div}
                variants={item}
                className="w-full mb-8 rounded-16 shadow"
              >
                <AppBar
                  position="static"
                  elevation={0}
                  className="bg-primaryBlue"
                >
                  <Toolbar className="xs:px-2 xmd:px-8 bg-primaryBlue">
                    <Typography className="font-medium text-xl text-white">
                      Media
                    </Typography>
                  </Toolbar>
                </AppBar>
                <CardContent>
                  <UploadMedia
                    mediaData={projectForm.media}
                    updateMedia={updateMedia}
                    user={user}
                    disabled={disabled}
                  />
                </CardContent>
              </Card>
            </div>
          </div>

          <div className="lg:flex">
            <div className="left-side flex flex-col flex-1"></div>
            <div className="right-side flex flex-col lg:w-320"></div>
            <div className={`mt-6 flex justify-center gap-x-4 w-full`}>
              <div className="text-left w-fit h-fit">
                {!add && boards.length == 0 && (
                  <Button
                    className="bg-red-500 hover:bg-red-500/70 rounded text-white xs:text-sm xmd:text-base xs:min-w-[80px] xmd:min-w-[110px] max-h-[40px]"
                    onClick={() => setConfirmDialog(true)}
                    disabled={disabled || boards.length > 0}
                  >
                    Delete
                  </Button>
                )}
              </div>

              {!disabled && (
                <>
                  <div className="pb-4 text-right w-fit h-fit">
                    <Button
                      onClick={() => reset(defaultValues)}
                      className="bg-lightGray rounded hover:bg-lightGray/70 text-white xs:text-sm xmd:text-base xs:min-w-[80px] xmd:min-w-[110px] max-h-[40px] mr-4"
                    >
                      Clear
                    </Button>
                  </div>
                  <div>
                    <Button
                      className={`${
                        _.isEmpty(dirtyFields) || !isValid
                          ? "bg-blueTransparent"
                          : "bg-primaryBlue"
                      } max-h-[40px] xs:text-sm xmd:text-base text-white rounded hover:bg-primaryBlueLight xs:min-w-[80px] xmd:min-w-[110px]`}
                      type="submit"
                      disabled={_.isEmpty(dirtyFields) || !isValid}
                    >
                      {add ? "Add" : "Update"}
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </form>
        <ConfirmDeleteDialog
          open={confirmDialog}
          onClose={() => setConfirmDialog(false)}
          deleteFunc={deleteProjectHandler}
        />
      </motion.div>
    </div>
  );
};

export default ProjectTab;
