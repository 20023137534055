import React from "react";

const ArrowRight = (props) => {
  const { height, width } = props;
  const className = "fill-primaryBlue";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24.754 39.285"
      className={className}
    >
      <path
        id="Icon_ionic-ios-arrow-down"
        data-name="Icon ionic-ios-arrow-down"
        d="M25.837,18.709,40.692,35.092a2.617,2.617,0,0,0,3.965,0,3.351,3.351,0,0,0,0-4.383L27.826,12.148a2.619,2.619,0,0,0-3.871-.09L7.006,30.7a3.342,3.342,0,0,0,0,4.383,2.617,2.617,0,0,0,3.965,0Z"
        transform="translate(36 -6.188) rotate(90)"
      />
    </svg>
  );
};

export default ArrowRight;
