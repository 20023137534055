import { combineReducers } from "@reduxjs/toolkit";
import board from "./boardSlice";
import boards from "./boardsSlice";
import card from "./cardSlice";
import labels from "./labelsSlice";
import filter from "./filtersSlice";

const scrumboardAppReducers = combineReducers({
  boards,
  board,
  card,
  labels,
  filter,
});

export default scrumboardAppReducers;
