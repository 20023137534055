import { yupResolver } from "@hookform/resolvers/yup";
import {
  AddCircleOutline,
  RemoveCircleOutline,
  UndoOutlined,
} from "@mui/icons-material";
import {
  Alert,
  AppBar,
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { searchHandler } from "api/algoliaSearch";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { StyledSwipeableDrawer } from "settings/styleOverrides";
import _ from "utils/lodash";
import Utilities from "utils/utilities";
import * as yup from "yup";
import CustomFieldsSettingsSidebar from "../dialogs/CustomFieldsSettingsSidebar";
import { addCustomFields, getCustomFields } from "../store/settingsSlice";

const schema = yup.object().shape({
  memberCustomFields: yup.array().of(
    yup.object().shape({
      id: yup.string().required().default(Utilities.generateGUID()),
      fieldName: yup.string().required("Please choose field name."),
      fieldType: yup.string().required("Please choose a field type."),
      active: yup.boolean().required().default(true),
      editable: yup.boolean().required().default(false),
    })
  ),
  projectCustomFields: yup.array().of(
    yup.object().shape({
      id: yup.string().required().default(Utilities.generateGUID()),
      fieldName: yup.string().required("Please choose field name."),
      fieldType: yup.string().required("Please choose a field type."),
      active: yup.boolean().required().default(true),
      editable: yup.boolean().required().default(false),
    })
  ),
  boardCustomFields: yup.array().of(
    yup.object().shape({
      id: yup.string().required().default(Utilities.generateGUID()),
      fieldName: yup.string().required("Please choose field name."),
      fieldType: yup.string().required("Please choose a field type."),
      active: yup.boolean().required().default(true),
      editable: yup.boolean().required().default(false),
    })
  ),
  projectName: yup.string(),
});

const initialStatus = {
  type: "",
  message: "",
};

const limit = {
  Starter: 5,
  Pro: 10,
};

const CustomFields = (props) => {
  const {
    item,
    user,
    classes,
    mdDown,
    settingsDrawerOpen,
    toggleSettingsDrawer,
  } = props;
  const dispatch = useDispatch();
  const [displayedProjectOptions, setDisplayedProjectOptions] = useState([]);
  const [showInactiveFields, setInactiveFields] = useState(false);
  const [status, setStatus] = useState(initialStatus);
  const containerRef = useRef();

  const {
    memberCustomFields,
    projectCustomFields,
    boardCustomFields,
    projectName,
  } = useSelector(({ settingsApp }) => settingsApp?.settings);

  const defaultValues = {
    memberCustomFields: _.cloneDeep(memberCustomFields),
    projectCustomFields: _.cloneDeep(projectCustomFields),
    boardCustomFields: _.cloneDeep(boardCustomFields),
    projectName: projectName || "",
  };
  const {
    control,
    formState,
    handleSubmit,
    reset,
    watch,
    setValue,
    clearErrors,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  const customFieldForm = watch();
  const disabled = !user?.data?.owner;
  const workspaceInfo = user?.workspaces?.find(
    (x) => x.id === user?.data.currentWorkspace
  );
  const currentPlan = workspaceInfo?.plan;

  useEffect(() => {
    if (!user?.data?.currentWorkspace) return;
    dispatch(getCustomFields({ user, type: "member" }));
    dispatch(getCustomFields({ user, type: "project" }));
    resetStatus();
  }, [user?.data?.currentWorkspace]);

  useEffect(() => {
    if (memberCustomFields?.length > 0) reset(defaultValues);
  }, [memberCustomFields]);

  useEffect(() => {
    if (projectCustomFields?.length > 0) reset(defaultValues);
  }, [projectCustomFields]);

  useEffect(() => {
    if (boardCustomFields?.length > 0) reset(defaultValues);
  }, [boardCustomFields]);

  const resetStatus = () => {
    setStatus({
      ...status,
      type: "warning",
      message: `On your ${currentPlan} plan, you have limit of ${limit[currentPlan]} custom fields per section.`,
    });
  };

  const onSubmit = (model) => {
    if (!isValid) return;

    // TODO: For now validate on client side only
    const result = validateActiveCustomFieldsLimitation(currentPlan);
    if (!result) {
      setStatus({
        ...status,
        type: "error",
        message:
          "You have reached your limit. Please upgrade your plan to continue or deactivate some other field.",
      });
      setTimeout(() => resetStatus(), 5000);
      return;
    }

    // Update member
    if (dirtyFields.memberCustomFields) {
      const data = {
        customFields: Utilities.updateCustomFields(
          customFieldForm.memberCustomFields
        ),
        modifiedDate: new Date(),
      };
      dispatch(addCustomFields({ user, type: "member", data }));
    }

    // Update project
    if (dirtyFields.projectCustomFields) {
      const data = {
        customFields: Utilities.updateCustomFields(
          customFieldForm.projectCustomFields
        ),
        modifiedDate: new Date(),
      };
      dispatch(addCustomFields({ user, type: "project", data }));
    }

    // Update project
    if (dirtyFields.boardCustomFields) {
      const project = displayedProjectOptions.find(
        (x) => x.name === model.projectName
      );
      const data = {
        customFields: Utilities.updateCustomFields(
          customFieldForm.boardCustomFields
        ),
        projectName: project.name,
        modifiedDate: new Date(),
      };
      dispatch(
        addCustomFields({
          user,
          type: "board",
          data,
          projectId: project.objectID,
        })
      );
    }

    reset({
      memberCustomFields: customFieldForm.memberCustomFields,
      projectCustomFields: customFieldForm.projectCustomFields,
      boardCustomFields: customFieldForm.boardCustomFields,
      projectName: customFieldForm.projectName,
    });
  };

  const handleAddFields = (type, force) => {
    const field = {
      id: Utilities.generateGUID(),
      fieldName: "",
      fieldType: "",
      active: true,
      editable: true,
    };
    const customFields = force ? [field] : [...customFieldForm[type], field];
    setValue(`${type}`, customFields, { shouldDirty: true });
  };

  const handleRemoveFields = (index, type, active) => {
    // Sync react state
    const customFields = [...customFieldForm[type]];
    if (customFields[index].editable) {
      customFields.splice(index, 1);
    } else {
      customFields[index].active = active;
      // if (active === false) {
      //   setStatus({
      //     ...status,
      //     type: "warning",
      //     message:
      //       "Removing will deactivate this custom field. You can make it active again at any time.",
      //   });
      // }
    }

    setValue(`${type}`, customFields, { shouldDirty: true });

    // IMPORTANT: This is required
    dirtyFields[type] = true;
    clearErrors(`${type}`);
  };

  const fetchData = async () => {
    const projectBody = {
      filters: "objectType:project",
      query: "",
      workspaceId: user?.data?.currentWorkspace,
      attributesToRetrieve: ["name"],
    };

    const projects = await searchHandler(projectBody);
    const { hits } = projects?.body;

    setDisplayedProjectOptions(hits);
  };

  const handleProjectChange = (onChange, value) => {
    const type = "boardCustomFields";
    const project = displayedProjectOptions.find((x) => x.name === value);
    dispatch(
      getCustomFields({
        user,
        type: "board",
        project: { objectID: project.objectID, projectName: project.name },
      })
    ).then((response) => {
      if (response.payload.data.length === 0) {
        handleAddFields(type, true);
      }
    });
    onChange(value);
  };

  const validateActiveCustomFieldsLimitation = (currentPlan) => {
    const activeMemberCustomFields = customFieldForm.memberCustomFields.filter(
      (x) => x.active
    ).length;
    const activeProjectCustomFields =
      customFieldForm.projectCustomFields.filter((x) => x.active).length;
    const activeBoardCustomFields = customFieldForm.boardCustomFields.filter(
      (x) => x.active
    ).length;
    if (
      limit[currentPlan] &&
      (activeMemberCustomFields > limit[currentPlan] ||
        activeProjectCustomFields > limit[currentPlan] ||
        activeBoardCustomFields > limit[currentPlan])
    ) {
      return false;
    }
    return true;
  };

  return (
    <>
      <form
        name="customFieldForm"
        noValidate
        className={`flex flex-col flex-1 ${mdDown ? "w-full" : "ml-6"}  my-6`}
        onSubmit={handleSubmit(onSubmit)}
      >
        {status && status.type && (
          <Alert severity={status.type} className="mb-2">
            {status.message}
          </Alert>
        )}
        {["memberCustomFields", "projectCustomFields", "boardCustomFields"].map(
          (type, idx) => (
            <Card
              key={type}
              component={motion.div}
              variants={item}
              className={`rounded-16 shadow ${idx != 0 ? "mt-8" : ""}`}
            >
              <AppBar
                position="static"
                elevation={0}
                className="bg-primaryBlue"
              >
                <Toolbar className="px-6">
                  <Typography
                    variant="subtitle1"
                    color="inherit"
                    className="flex-1 text-lg font-medium text-white"
                  >
                    {type === "memberCustomFields"
                      ? "User"
                      : type === "projectCustomFields"
                      ? "Project"
                      : "Scrumboard"}{" "}
                    Custom Fields
                  </Typography>
                </Toolbar>
              </AppBar>

              <CardContent>
                <Typography className="font-semibold mt-3 mb-5 text-15">
                  Add custom fields
                  {!disabled &&
                    customFieldForm[type] &&
                    customFieldForm[type].length === 0 && (
                      <IconButton onClick={() => handleAddFields(type)}>
                        <AddCircleOutline />
                      </IconButton>
                    )}
                </Typography>

                {!disabled && type == "boardCustomFields" && (
                  //customFieldForm.boardCustomFields.length != 0 &&
                  <Controller
                    name="projectName"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControl fullWidth>
                        <InputLabel id="projectName" className="text-mute">
                          Select a project
                        </InputLabel>
                        <Select
                          value={value}
                          onChange={(e) =>
                            handleProjectChange(onChange, e.target.value)
                          }
                          name="projectName"
                          className="mb-4"
                          labelId="projectName"
                          label="Select a project"
                          id="projectName"
                          onOpen={() => fetchData()}
                          disabled={disabled}
                          fullWidth
                          size="small"
                          displayEmpty
                          sx={{
                            "& .MuiSelect-select": {
                              padding: "15px",
                            },
                          }}
                        >
                          {displayedProjectOptions?.map((item, idx) => (
                            <MenuItem key={idx} value={item.name}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText error={!!errors.projectName} required>
                          {errors?.projectName?.message}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                )}

                {((type === "boardCustomFields" &&
                  customFieldForm.projectName) ||
                  type !== "boardCustomFields") &&
                  customFieldForm[type]?.map((inputField, index) => {
                    if (!showInactiveFields && !inputField.active) return;
                    return (
                      <div
                        key={inputField.id}
                        className="flex items-center mb-8"
                      >
                        {!inputField.active && (
                          <span className="text-red-500 text-xl">&#42;</span>
                        )}
                        <Controller
                          name={`${type}[${index}].fieldName`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              autoComplete="off"
                              name="fieldName"
                              label="Name"
                              variant="outlined"
                              className="mr-2"
                              size="small"
                              sx={{ width: disabled ? "50%" : "40%" }}
                              error={
                                !!errors[type] &&
                                !!errors[type][index]?.fieldName
                              }
                              helperText={
                                errors[type] &&
                                errors[type][index]?.fieldName?.message
                              }
                              InputProps={{
                                className: classes.input,
                              }}
                              InputLabelProps={{
                                shrink: true,
                                className: classes.label,
                              }}
                              disabled={disabled}
                            />
                          )}
                        />
                        <Controller
                          name={`${type}[${index}].fieldType`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              autoComplete="off"
                              name="fieldType"
                              label="Value"
                              select
                              variant="outlined"
                              className="ml-2"
                              size="small"
                              sx={{ width: disabled ? "50%" : "40%" }}
                              error={
                                !!errors[type] &&
                                !!errors[type][index]?.fieldType
                              }
                              helperText={
                                errors[type] &&
                                errors[type][index]?.fieldType?.message
                              }
                              InputProps={{
                                className: classes.input,
                              }}
                              InputLabelProps={{
                                shrink: true,
                                className: classes.label,
                              }}
                              disabled={
                                disabled || inputField.editable === false
                              }
                            >
                              <MenuItem value="string">String</MenuItem>
                              <MenuItem value="boolean">Boolean</MenuItem>
                              <MenuItem value="number">Number</MenuItem>
                              <MenuItem value="date">Date</MenuItem>
                            </TextField>
                          )}
                        />
                        {!disabled && inputField.active && (
                          <Tooltip title="This will deactivate the custom field and will not show up wherever it's being used.">
                            <IconButton
                              className="ml-10"
                              onClick={() =>
                                handleRemoveFields(index, type, false)
                              }
                              disabled={disabled}
                            >
                              <RemoveCircleOutline />
                            </IconButton>
                          </Tooltip>
                        )}
                        {!disabled && !inputField.active && (
                          <Tooltip title="This will activate the custom field and will show up wherever it's being used.">
                            <IconButton
                              className="ml-10"
                              onClick={() =>
                                handleRemoveFields(index, type, true)
                              }
                              disabled={disabled}
                            >
                              <UndoOutlined />
                            </IconButton>
                          </Tooltip>
                        )}
                        {!disabled && (
                          <IconButton
                            onClick={() => handleAddFields(type)}
                            disabled={disabled}
                          >
                            <AddCircleOutline />
                          </IconButton>
                        )}
                      </div>
                    );
                  })}
              </CardContent>
            </Card>
          )
        )}

        {!disabled && (
          <div className="flex w-full justify-between my-[24px]">
            <div className="w-full"></div>

            <div className="flex">
              <Button
                onClick={() => reset(defaultValues)}
                className="bg-lightGray rounded hover:bg-lightGray/70 text-white xs:text-sm xmd:text-base xs:min-w-[80px] xmd:min-w-[110px] max-h-[40px] mr-4"
              >
                Cancel
              </Button>
              <Button
                className={`${
                  _.isEmpty(dirtyFields) ||
                  !isValid ||
                  customFieldForm.memberCustomFields.length === 0
                    ? "bg-blueTransparent"
                    : "bg-primaryBlue"
                } max-h-[40px] xs:text-sm xmd:text-base text-white rounded hover:bg-primaryBlueLight xs:min-w-[80px] xmd:min-w-[110px]`}
                type="submit"
                disabled={
                  _.isEmpty(dirtyFields) ||
                  !isValid ||
                  customFieldForm.memberCustomFields.length === 0
                }
              >
                Save
              </Button>
            </div>
          </div>
        )}
      </form>

      <StyledSwipeableDrawer
        anchor="right"
        className="overflow-hidden"
        classes={{
          paper: " w-[300px] sm:w-[420px]",
        }}
        BackdropProps={{
          classes: {
            root: "",
          },
        }}
        container={containerRef.current}
        ModalProps={{
          keepMounted: true,
          // style: { position: "absolute" },
        }}
        open={settingsDrawerOpen}
        onOpen={(ev) => {}}
        onClose={() => toggleSettingsDrawer(false)}
        disableSwipeToOpen
      >
        <CustomFieldsSettingsSidebar
          toggleSettingsDrawer={toggleSettingsDrawer}
          setInactiveFields={setInactiveFields}
          showInactiveFields={showInactiveFields}
        />
      </StyledSwipeableDrawer>
    </>
  );
};

export default CustomFields;
