import { createSlice } from "@reduxjs/toolkit";

const filtersSlice = createSlice({
  name: "scrumboardApp",
  initialState: { data: [] },
  reducers: {
    resetFilter: (state, action) => {
      state.data = [];
    },
    setBoardFilter: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: {},
});

export const { setBoardFilter, resetFilter } = filtersSlice.actions;

export default filtersSlice.reducer;
