import { lazy } from "react";
import { Navigate } from "react-router-dom";
import { authRoles } from "auth";

const Board = lazy(() => import("./board/Board"));
const Boards = lazy(() => import("./boards/Boards"));

const ScrumboardAppConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.user,
  routes: [
    {
      path: "/:workspaceId/project/:projectId/scrumboard/boards/:boardId",
      element: <Board />,
      showMembers: true,
      children: [
        {
          path: ":boardUri",
          element: <Board />,
        },
      ],
    },
    {
      path: "/:workspaceId/project/:projectId/scrumboard/boards/:boardId/card/:cardId",
      element: <Board />,
      showMembers: true,
      children: [
        {
          path: ":boardUri",
          element: <Board />,
        },
      ],
    },
    {
      path: "/:workspaceId/backlog/:backlogId",
      element: <Board />,
      showMembers: true,
    },
    {
      path: "/:workspaceId/project/:projectId/scrumboard/boards",
      element: <Boards />,
      showMembers: true,
    },
    {
      path: "/:workspaceId/project/:projectId/scrumboard",
      element: <Boards />,
      showMembers: true,
    },
    {
      path: "/:workspaceId/project/:projectId/scrumboard",
      element: (
        <Navigate to="/:workspaceId/project/:projectId/scrumboard/boards" />
      ),
      showMembers: true,
    },
  ],
};

export default ScrumboardAppConfig;
