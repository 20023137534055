import AddIcon from "@mui/icons-material/Add";
import {
  Avatar,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import { applyCoupon } from "api/applyCoupon";
import PlanIcon from "components/svgs/icons/settings/PlanIcon";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  BasicSwitchStyleOverrides,
  useStyledFormInputStyles,
} from "settings/styleOverrides";
import _ from "utils/lodash";
import Utilities from "utils/utilities";
import { selectMembers } from "views/members/store/membersSlice";
import PlanPayment from "./PlanPayment";

const PlanDetails = (props) => {
  const { setTabNumber, selectedPlan, subscriptionDetails, darkMode, user } =
    props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const classes = useStyledFormInputStyles();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  const [warnMeBeforeBillingPeriod, showWarnMeBeforeBillingPeriod] =
    useState(false);
  const [emailMeInvoice, showEmailMeInboice] = useState(true);
  const [disableApplyBtn, setDisableApplyBtn] = useState(false);
  const [error, setError] = useState();
  const [coupon, setCoupon] = useState("");
  const [response, setResponse] = useState();
  const [billingCycle, setBillingCycle] = useState("month");
  const [planObj, setPlanObj] = useState({});

  const members = useSelector(selectMembers);
  const acceptedMembers = useSelector(
    ({ inviteApp }) => inviteApp?.accepted || []
  );

  const discount = subscriptionDetails?.subscription?.discount;
  // console.log("subscriptionDetails", subscriptionDetails);
  const currentPlan = subscriptionDetails?.subscription?.plan?.nickname;
  const plans = subscriptionDetails?.plans?.data || [];

  const className = `${
    darkMode ? "bg-darkSmoke" : "bg-white"
  } rounded team-members shadow-md shadow-blueTransparent p-6 ${
    mdDown ? "my-6 mx-0" : "m-6"
  }`;

  const discountDisplay = () => {
    if (!discount) return null;
    if (discount?.coupon?.duration === "forever") {
      return (
        <div className="flex items-center text-right">
          <span className="w-full pr-4 text-sm">
            {discount.coupon.name}: -
            {discount.coupon.amount_off || discount.coupon.percent_off}
            {discount.coupon.amount_off ? "$" : "%"} off forever
          </span>
        </div>
      );
    }
    return (
      <div className="flex items-center text-right">
        <span className="w-full pr-4 text-sm">
          {discount.coupon.name}: -
          {discount.coupon.amount_off || discount.coupon.percent_off}
          {discount.coupon.amount_off ? "$" : "%"} off for&nbsp;
          {discount.coupon.duration_in_months} Months
        </span>
      </div>
    );
  };

  const handleChange = (value) => {
    setCoupon(value);
    setResponse();
  };

  const applyCode = () => {
    if (!coupon) {
      setError("Please enter coupon code to apply discount.");
      return;
    }
    setDisableApplyBtn(true);
    setError();

    const body = {
      code: coupon,
      amount: planObj.unit_amount,
    };
    applyCoupon(body)
      .then((res) => {
        setDisableApplyBtn(false);
        if (res?.body?.error) {
          setError(res.body.error.raw?.message);
          return;
        }
        setResponse(res.body);
        Utilities.successNotification(
          user,
          " Coupon applied successfully. ",
          null,
          dispatch
        );
      })
      .catch((error) => {
        setDisableApplyBtn(false);
        Utilities.errorNotification(user, dispatch, error);
      });
  };

  const handleBillingCycle = (e) => {
    const planObj = getPlanObj(e.target.value);
    if (planObj === -1) return;

    setPlanObj(planObj);
    setBillingCycle(e.target.value);
  };

  const getPlanObj = (interval) => {
    return plans.find(
      (x) =>
        x.nickname === selectedPlan.title && x.recurring.interval === interval
    );
  };

  useEffect(() => {
    const interval =
      currentPlan !== selectedPlan.title
        ? "month"
        : subscriptionDetails?.subscription?.plan?.interval;
    const planObj = getPlanObj(interval);
    if (planObj === -1) return;

    setPlanObj(planObj);
    setBillingCycle(interval);
  }, [selectedPlan]);

  return (
    <>
      {/* Summary & Coupon Code Section */}
      <div className={className}>
        <Typography className="font-medium">
          {currentPlan === selectedPlan.title
            ? "Current Plan Summary"
            : "New Plan Summary"}
        </Typography>
        {subscriptionDetails?.subscription && (
          <div className="relative flex flex-col mt-4 md:grid md:grid-cols-3 rounded-t">
            <Typography className="flex items-center text-sm cursor-pointer">
              <Box className="w-[2.1em] h-[2.1em]">
                <PlanIcon color={selectedPlan.color} />
              </Box>
              <span className="ml-3 text-gray-900">{selectedPlan.title}</span>
            </Typography>
            <p
              id="plan-option-limit-1"
              className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center rounded-full text-white font-500 bg-awayText p-1"
            >
              {currentPlan === selectedPlan.title
                ? _.capitalize(subscriptionDetails.subscription.status)
                : selectedPlan.title === "Enterprise"
                ? "Contact Support"
                : "New Effective Plan"}
            </p>
            {!_.isEmpty(planObj) && (
              <p
                id="plan-option-pricing-1"
                className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right"
              >
                <span className="font-medium">
                  {_.upperCase(planObj.currency) + " "}
                  {parseFloat(Math.round(planObj.unit_amount / 100)).toFixed(2)}
                  {planObj.recurring.interval
                    ? ` / member / ${planObj.recurring.interval}`
                    : ""}
                </span>
              </p>
            )}
          </div>
        )}
        {subscriptionDetails?.subscription?.discount ? (
          discountDisplay()
        ) : (
          <div className="flex items-center mt-4 justify-between">
            <Typography className="">Have a coupon code?</Typography>
            <div className="mt-1 relative rounded-md shadow-sm text-end">
              <TextField
                type="text"
                name="coupon_code"
                id="coupon_code"
                className="focus:bg-primaryBlue-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
                placeholder="YVRLU76"
                value={coupon}
                onChange={(ev) => handleChange(ev.target.value)}
                InputProps={{
                  className: classes.input,
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.label,
                }}
              />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                {/* <!-- Heroicon name: question-mark-circle --> */}
                <svg
                  className="h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
            {response ? (
              <p
                id="plan-option-pricing-1"
                className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right"
              >
                <span className="font-medium text-red-500">
                  <b>-</b> {_.upperCase(planObj.currency)}{" "}
                  {response.discount.toFixed(2)}
                </span>
              </p>
            ) : (
              <Button
                variant="outlined"
                size="medium"
                className="rounded-md border-none text-sm text-primaryBlue"
                onClick={() => applyCode()}
                disabled={disableApplyBtn}
              >
                Apply
              </Button>
            )}
          </div>
        )}
        {(discount || response) && (
          <div className="flex items-center mt-4 justify-between">
            <Typography className="">Total Amount Charged</Typography>
            <p
              id="plan-option-pricing-1"
              className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right"
            >
              <span className="font-medium">
                {_.upperCase(planObj.currency)} {response.total.toFixed(2)}{" "}
                {planObj.recurring.interval
                  ? ` / ${planObj.recurring.interval}`
                  : ""}
              </span>
            </p>
          </div>
        )}
        {error && (
          <div className="rounded-md bg-red-50 p-4 mt-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg
                  className="h-5 w-5 text-red-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">
                  There were errors with your submission
                </h3>
                <div className="mt-2 text-sm text-red-700">
                  <ul className="list-disc pl-5 space-y-1">
                    <li>{error?.raw?.message || error}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Plan Details Section */}
      <div className={`flex w-full ${mdDown ? "flex-col" : "flex-row"} `}>
        <div className={`left-container ${mdDown ? "w-full" : "w-[50%]"} `}>
          <div className={className}>
            <div className="flex justify-between">
              <Typography className="font-medium">Team Members</Typography>
              {/* Plus 1 is for owner of the workspace */}
              {selectedPlan.id == 0 && (
                <Typography className="text-mute">
                  {5 - (acceptedMembers?.length + 1) + " remaining"}
                </Typography>
              )}
              {selectedPlan.id == 1 && (
                <Typography className="text-mute">
                  {25 - (acceptedMembers?.length + 1) + " remaining"}
                </Typography>
              )}
              {selectedPlan.id == 2 && (
                <Typography className="text-mute">
                  {100 - (acceptedMembers?.length + 1) + " remaining"}
                </Typography>
              )}
              {selectedPlan.id == 3 && (
                <Typography className="text-mute">&infin; remaining</Typography>
              )}
            </div>
            <div className="avatar-container items-center flex gap-3 mt-4">
              {members.map((member, idx) => {
                if (!member.owner && !acceptedMembers.includes(member.email))
                  return;
                return (
                  <Tooltip title={member.displayName} key={idx}>
                    <Avatar
                      src={member.photoURL}
                      alt={member.displayName}
                      style={{ backgroundColor: member.more.color }}
                    >
                      {member.displayName &&
                        member.displayName[0].toLocaleUpperCase()}
                    </Avatar>
                  </Tooltip>
                );
              })}
              <div className="cursor-pointer flex justify-center items-center rounded-full border-2 p-5 border-dotted w-[1.8em] h-[1.8em]">
                <AddIcon
                  className="text-mute text-2xl"
                  onClick={() =>
                    navigate(`/${user.data.currentWorkspace}/invite`)
                  }
                />
              </div>
            </div>
          </div>

          <div className={className}>
            <Typography className="font-medium mb-4">Options</Typography>
            <div className="flex gap-4 items-center">
              <Switch
                sx={BasicSwitchStyleOverrides(user?.data?.settings?.themeColor)}
                checked={emailMeInvoice}
                onClick={() => showEmailMeInboice(!emailMeInvoice)}
              />
              <Typography className="text-grayText text-base">
                Send new invoice to my inbox
              </Typography>
            </div>
            <div className="flex gap-4 items-center mt-2">
              <Switch
                sx={BasicSwitchStyleOverrides(user?.data?.settings?.themeColor)}
                checked={warnMeBeforeBillingPeriod}
                onClick={() =>
                  showWarnMeBeforeBillingPeriod(!warnMeBeforeBillingPeriod)
                }
              />
              <Typography className="text-grayText text-base">
                Warn me before the end of the billing period
              </Typography>
            </div>
          </div>

          <div className={className}>
            <div className="flex justify-between items-center">
              <Typography className="font-medium">Billing Cycle</Typography>
              <Button
                variant="outlined"
                size="small"
                className={`rounded-md border-none text-sm ${
                  darkMode ? "text-white border-slate-50" : "text-primaryBlue"
                }`}
                onClick={() => setTabNumber(1)}
              >
                My Invoices
              </Button>
            </div>
            <div className="flex gap-6 mt-3">
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={billingCycle}
                  onChange={handleBillingCycle}
                >
                  <FormControlLabel
                    value="month"
                    control={<Radio />}
                    label="Monthly"
                    disabled={selectedPlan.title === "Starter"}
                  />
                  <FormControlLabel
                    value="year"
                    control={<Radio />}
                    label="Yearly"
                    disabled={selectedPlan.title === "Starter"}
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </div>

        <PlanPayment
          mdDown={mdDown}
          subscriptionDetails={subscriptionDetails}
          user={user}
          selectedPlan={selectedPlan}
          billingCycle={billingCycle}
          planObj={planObj}
          darkMode={darkMode}
          emailMeInvoice={emailMeInvoice}
        />
      </div>
    </>
  );
};

export default PlanDetails;
