import React from "react";

const Important = (props) => {
  const {
    height = 24,
    width = 24,
    color = "#5b5b5b",
    fill = "none",
    stroke = "#707070",
  } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 28.681 28.681"
    >
      <g
        id="Group_45954"
        data-name="Group 45954"
        transform="translate(-1736.742 -401.742)"
      >
        <g
          id="Ellipse_1405"
          data-name="Ellipse 1405"
          transform="translate(1736.742 401.742)"
          fill={fill}
          stroke={stroke}
          strokeWidth="1.75"
        >
          <circle cx="14.341" cy="14.341" r="14.341" stroke="none" />
          <circle cx="14.341" cy="14.341" r="13.466" fill={fill} />
        </g>
        <path
          id="Path_39866"
          data-name="Path 39866"
          d="M2.161-5.061H3.892L4.129-13.9H1.924ZM4.4-2.436A1.311,1.311,0,0,0,3.09-3.767a1.315,1.315,0,0,0-1.33,1.33,1.315,1.315,0,0,0,1.33,1.33A1.311,1.311,0,0,0,4.4-2.436Z"
          transform="translate(1748.397 423.603)"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default Important;
