import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import firebase from "services/firebase";
import { closeChatPanel } from "./stateSlice";

export const getChat = createAsyncThunk(
  "chatPanel/chat/getChat",
  async ({ user, contactId }) => {
    const data = await firebase.getChatById(user, contactId);
    return data;
  }
);

export const resetUnreadRealtime = createAsyncThunk(
  "chatPanel/chat/resetUnreadRealtime",
  async ({ user, contactId }) => {
    return await firebase.resetUnreadRealtime(user, contactId);
  }
);

export const sendMessage = createAsyncThunk(
  "chatPanel/chat/sendMessage",
  async ({ messageText, contactId, attachment }, { dispatch, getState }) => {
    const user = getState().auth.user;
    const message = {
      who: user.uid,
      message: messageText,
      time: new Date(),
      attachment: [],
    };
    const data = await firebase.updateChatRealtime(
      user,
      contactId,
      message,
      attachment
    );
    await firebase.updateMemberChatRealtime(
      user,
      contactId,
      message,
      data.message.id,
      data.uploadData
    );
    return data;
  }
);

const chatSlice = createSlice({
  name: "chatPanel/chat",
  initialState: {},
  reducers: {
    removeChat: (state, action) => {},
    setSelectedContactId: (state, action) => {
      state.selectedContactId = action.payload;
    },
  },
  extraReducers: {
    [getChat.fulfilled]: (state, action) => {
      state.Chat = action.payload;
    },
    [closeChatPanel]: (state, action) => {},
  },
});

export const { setSelectedContactId } = chatSlice.actions;

export default chatSlice.reducer;
