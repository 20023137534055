import Dialog from "@mui/material/Dialog";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "store/slice/dialogSlice";

const DialogWrapper = (props) => {
  const dispatch = useDispatch();
  const state = useSelector(({ entities }) => entities.dialog.state);
  const options = useSelector(({ entities }) => entities.dialog.options);

  return (
    <Dialog
      open={state}
      onClose={(ev) => dispatch(closeDialog())}
      aria-labelledby="dialog-title"
      classes={{
        paper: "rounded-8",
      }}
      {...options}
    />
  );
};

export default DialogWrapper;
