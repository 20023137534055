import React from "react";

const DarkMode = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="29"
      height="29.69"
      viewBox="0 0 29 29.69"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_19671"
            data-name="Rectangle 19671"
            width="29"
            height="29.69"
            fill="#5b5b5b"
          />
        </clipPath>
      </defs>
      <g id="Group_46112" data-name="Group 46112" transform="translate(0 0)">
        <g
          id="Group_46111"
          data-name="Group 46111"
          transform="translate(0 0)"
          clipPath="url(#clipPath)"
        >
          <path
            id="Path_40126"
            data-name="Path 40126"
            d="M28.168,7.825l1.888,1.936L7.94,31.871,6.092,29.89,28.168,7.825"
            transform="translate(-3.741 -4.805)"
            fill="#5b5b5b"
          />
          <path
            id="Path_40127"
            data-name="Path 40127"
            d="M42.051,32.94a7.821,7.821,0,0,1-10.88,10.885L42.051,32.94"
            transform="translate(-19.14 -20.227)"
            fill="#5b5b5b"
          />
          <path
            id="Path_40128"
            data-name="Path 40128"
            d="M7.592,2.029a9.525,9.525,0,0,0,.083,15.547c-1.875.317-4.743-1.12-6.153-3.17A7.55,7.55,0,0,1,.977,6.148,7.468,7.468,0,0,1,7.592,2.029"
            transform="translate(0 -1.246)"
            fill="#5b5b5b"
          />
          <path
            id="Path_40129"
            data-name="Path 40129"
            d="M29.328,0l.967,2.314h2.212l.069.173c-.472.4-.967.77-1.4,1.2a.871.871,0,0,0-.2.683c.122.6.327,1.189.548,1.953-.677-.421-1.17-.776-1.708-1.039a.963.963,0,0,0-.773.028c-.523.29-1,.666-1.655,1.119A13.816,13.816,0,0,1,27.78,4.81.955.955,0,0,0,27.3,3.5c-.4-.246-.754-.563-1.314-.99.768-.051,1.308-.1,1.85-.119a.781.781,0,0,0,.861-.656A18.354,18.354,0,0,1,29.328,0"
            transform="translate(-15.955 0)"
            fill="#5b5b5b"
          />
          <path
            id="Path_40130"
            data-name="Path 40130"
            d="M17.195,19A1.972,1.972,0,0,0,19.7,17.064c.283,1.446,1.015,2.139,2.477,1.908-.415.387-1.036.708-1.186,1.179-.142.445.212,1.047.388,1.738-.595-.337-1.086-.839-1.56-.823-.5.017-.99.526-1.642.861A1.85,1.85,0,0,0,17.195,19"
            transform="translate(-10.558 -10.478)"
            fill="#5b5b5b"
          />
          <path
            id="Path_40131"
            data-name="Path 40131"
            d="M62.736,30.147l4.786.335-3.148,3.642-1.639-3.977"
            transform="translate(-38.522 -18.512)"
            fill="#5b5b5b"
          />
          <path
            id="Path_40132"
            data-name="Path 40132"
            d="M67.5,52.128l-4.8.338,1.644-3.977L67.5,52.128"
            transform="translate(-38.503 -29.774)"
            fill="#5b5b5b"
          />
          <path
            id="Path_40133"
            data-name="Path 40133"
            d="M50.319,69.291l-3.628-3.154,3.953-1.645c-.109,1.6-.21,3.094-.325,4.8"
            transform="translate(-28.67 -39.601)"
            fill="#5b5b5b"
          />
          <path
            id="Path_40134"
            data-name="Path 40134"
            d="M28.309,64.472l3.97,1.648-3.6,3.133c-.126-1.645-.24-3.133-.365-4.781"
            transform="translate(-17.383 -39.588)"
            fill="#5b5b5b"
          />
        </g>
      </g>
    </svg>
  );
};

export default DarkMode;
