import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import firebase from "services/firebase";
import Utilities from "utils/utilities";

export const dateFormat = "yyyy-MM-dd hh:mm";

const uploadMediaAttachment = async (user, event, isUpdateMediaDirty) => {
  if (event.media?.length == 0 || !isUpdateMediaDirty) return event;

  const path = `${user.data.currentWorkspace}/users/${user.uid}/events/${event.id}`;
  for (let i = 0; i < event.media.length; i++) {
    const media = { ...event.media[i] };
    if (!media.file?.name) continue;
    const mediaUrl = await firebase.uploadAttachment(path, media.file);
    media.url = mediaUrl;
    media.file = media.file.name;
    event.media[i] = media;
  }
  return event;
};

export const getEvents = createAsyncThunk(
  "calendarApp/events/getEvents",
  async ({ user, start, end, limit, ids }, { dispatch }) => {
    const data = await firebase.getEvents(user, start, end, limit, ids);
    const eventsArray = data.map((event) => ({ ...event, type: "event" }));
    return eventsArray;
  }
);

export const addEvent = createAsyncThunk(
  "calendarApp/events/addEvent",
  async ({ user, eventData, isUpdateMediaDirty }, { dispatch }) => {
    eventData.id = firebase.generateFirebaseDocId(user, "events");
    eventData = await uploadMediaAttachment(
      user,
      eventData,
      isUpdateMediaDirty
    );

    await firebase.setEvent(user, eventData);

    const message = " has created new event ";
    Utilities.successNotification(user, message, eventData.title, dispatch);

    return eventData;
  }
);

export const updateEvent = createAsyncThunk(
  "calendarApp/events/updateEvent",
  async ({ user, updatedEventData, isUpdateMediaDirty }, { dispatch }) => {
    updatedEventData = await uploadMediaAttachment(
      user,
      updatedEventData,
      isUpdateMediaDirty
    );

    await firebase.updateEvent(user, updatedEventData);

    const message = " has updated event ";
    Utilities.successNotification(
      user,
      message,
      updatedEventData.title,
      dispatch
    );

    return updatedEventData;
  }
);

export const removeEvent = createAsyncThunk(
  "calendarApp/events/remove-event",
  async ({ user, eventData }, { dispatch }) => {
    await firebase.removeEvent(user, eventData.id);

    const message = " has removed event ";
    Utilities.successNotification(user, message, eventData.title, dispatch);

    return eventData.id;
  }
);

const eventsAdapter = createEntityAdapter({});

export const {
  selectAll: selectEvents,
  selectIds: selectEventIds,
  selectById: selectEventById,
} = eventsAdapter.getSelectors((state) => state.calendarApp.events);

const eventsSlice = createSlice({
  name: "calendarApp/events",
  initialState: eventsAdapter.getInitialState({
    eventDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
    filter: [],
  }),
  reducers: {
    setEventFilter: (state, action) => {
      state.filter = action.payload;
    },
    openNewEventDialog: {
      prepare: (event) => {
        const payload = {
          type: "new",
          props: {
            open: true,
          },
          data: {
            start: event.start,
            end: event.end,
            userId: event.userId,
          },
        };
        return { payload };
      },
      reducer: (state, action) => {
        state.eventDialog = action.payload;
      },
    },
    openEditEventDialog: {
      prepare: (event) => {
        const payload = {
          type: "edit",
          props: {
            open: true,
          },
          data: {
            ...event,
            start: event.start,
            end: event.end,
            userId: event.userId,
          },
        };
        return { payload };
      },
      reducer: (state, action) => {
        state.eventDialog = action.payload;
      },
    },
    closeNewEventDialog: (state, action) => {
      state.eventDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: null,
      };
    },
    closeEditEventDialog: (state, action) => {
      state.eventDialog = {
        type: "edit",
        props: {
          open: false,
        },
        data: null,
      };
    },
  },
  extraReducers: {
    [getEvents.fulfilled]: eventsAdapter.setAll,
    [addEvent.fulfilled]: eventsAdapter.addOne,
    [updateEvent.fulfilled]: eventsAdapter.upsertOne,
    [removeEvent.fulfilled]: eventsAdapter.removeOne,
  },
});

export const {
  setEventFilter,
  openNewEventDialog,
  closeNewEventDialog,
  openEditEventDialog,
  closeEditEventDialog,
} = eventsSlice.actions;

export default eventsSlice.reducer;
