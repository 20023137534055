import Divider from "@mui/material/Divider";
import PropTypes from "prop-types";
import { memo } from "react";
import _ from "utils/lodash";
import GlobalStyles from "@mui/material/GlobalStyles";
import NavHorizontalLayout from "./horizontal/NavHorizontalLayout";
import NavVerticalLayout1 from "./vertical/NavVerticalLayout1";
import NavVerticalLayout2 from "./vertical/NavVerticalLayout2";
import NavigationHorizontalCollapse from "./horizontal/types/NavHorizontalCollapse";
import NavigationHorizontalGroup from "./horizontal/types/NavHorizontalGroup";
import NavigationHorizontalItem from "./horizontal/types/NavHorizontalItem";
import NavigationHorizontalLink from "./horizontal/types/NavHorizontalLink";
import NavVerticalCollapse from "./vertical/types/NavVerticalCollapse";
import NavVerticalGroup from "./vertical/types/NavVerticalGroup";
import NavVerticalItem from "./vertical/types/NavVerticaltem";
import NavVerticalLink from "./vertical/types/NavVerticalLink";
import { registerComponent } from "./NavItem";

const inputGlobalStyles = (
  <GlobalStyles
    styles={(theme) => ({
      ".popper-navigation-list": {
        "& .default-list-item": {
          padding: "8px 12px 8px 12px",
          height: 40,
          minHeight: 40,
          "& .default-list-item-text": {
            padding: "0 0 0 8px",
          },
        },
        "&.dense": {
          "& .default-list-item": {
            minHeight: 32,
            height: 32,
            "& .default-list-item-text": {
              padding: "0 0 0 8px",
            },
          },
        },
      },
    })}
  />
);

/*
Register Navigation Components
 */
registerComponent("vertical-group", NavVerticalGroup);
registerComponent("vertical-collapse", NavVerticalCollapse);
registerComponent("vertical-item", NavVerticalItem);
registerComponent("vertical-link", NavVerticalLink);
registerComponent("horizontal-group", NavigationHorizontalGroup);
registerComponent("horizontal-collapse", NavigationHorizontalCollapse);
registerComponent("horizontal-item", NavigationHorizontalItem);
registerComponent("horizontal-link", NavigationHorizontalLink);
registerComponent("vertical-divider", () => <Divider className="my-4" />);
registerComponent("horizontal-divider", () => <Divider className="my-4" />);

const Navigation = (props) => {
  const options = _.pick(props, [
    "navigation",
    "layout",
    "active",
    "dense",
    "className",
    "onItemClick",
    "firstLevel",
    "selectedId",
  ]);
  if (props.navigation.length > 0) {
    return (
      <>
        {inputGlobalStyles}
        {/* {props.layout === "horizontal" && <NavHorizontalLayout {...options} />} */}
        {props.layout === "vertical" && <NavVerticalLayout1 {...options} />}
        {/* {props.layout === "vertical-2" && <NavVerticalLayout2 {...options} />} */}
      </>
    );
  }
  return null;
};

Navigation.propTypes = {
  navigation: PropTypes.array.isRequired,
};

Navigation.defaultProps = {
  layout: "vertical",
};

export default memo(Navigation);
