import { ListItemIcon } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Icon from "@mui/material/Icon";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import { IOSSwitch } from "settings/styleOverrides";

const TrackerSettingsSidebar = (props) => {
  const { setShowWeekends, showWeekends, toggleSettingsDrawer } = props;

  return (
    <div>
      <AppBar position="static" className="bg-primaryBlue">
        <div className="flex justify-center items-center p-4">
          <div className="grow font-medium text-base text-white">Settings</div>
          <div
            className="flex-none text-2xl text-white flex justify-center items-center cursor-pointer"
            onClick={() => toggleSettingsDrawer(false)}
          >
            <Icon fontSize="inherit">close</Icon>
          </div>
        </div>
      </AppBar>

      <List className="py-6" dense>
        <ListItem>
          <ListItemIcon className="min-w-40">
            <Icon>check_box</Icon>
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              fontSize: 15,
              letterSpacing: 0.5,
              fontWeight: 400,
            }}
            primary="Show weekends"
          />
          <ListItemSecondaryAction>
            <IOSSwitch
              onChange={() => setShowWeekends(!showWeekends)}
              checked={showWeekends}
            />
          </ListItemSecondaryAction>
        </ListItem>

        {/* TODO
          <ListItem>
            <ListItemIcon className="min-w-40">
              <Icon>check_box</Icon>
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                fontSize: 15,
                letterSpacing: 0.5,
                fontWeight: 400,
              }}
              primary="Show pending approvals"
            />
            <ListItemSecondaryAction>
              <IOSSwitch
                onChange={() => setShowWeekends(!showWeekends)}
                checked={showWeekends}
              />
            </ListItemSecondaryAction>
          </ListItem> 
        */}
      </List>
    </div>
  );
};

export default TrackerSettingsSidebar;
