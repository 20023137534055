import IconButton from "@mui/material/IconButton";
import FilterIcon from "components/svgs/icons/general/FilterIcon";
import { useDispatch } from "react-redux";
import { toggleQuickPanel } from "./store/stateSlice";

const QuickPanelToggleButton = (props) => {
  const dispatch = useDispatch();

  return (
    <IconButton
      className="hidden sm:block xs:p-2"
      onClick={(ev) => dispatch(toggleQuickPanel())}
      size="large"
    >
      {props.children}
    </IconButton>
  );
};

QuickPanelToggleButton.defaultProps = {
  children: <FilterIcon className="w-6 h-6" />,
};

export default QuickPanelToggleButton;
