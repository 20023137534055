const Chat = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="24"
      height="22"
      viewBox="0 0 27.449 25.418"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_17627"
            data-name="Rectangle 17627"
            width="27.449"
            height="25.418"
            fill="#6e6e6e"
          />
        </clipPath>
      </defs>
      <g id="Group_40613" data-name="Group 40613" transform="translate(0 0)">
        <g
          id="Group_40612"
          data-name="Group 40612"
          transform="translate(0 0)"
          clipPath="url(clipPath)"
        >
          <path
            id="Path_34382"
            data-name="Path 34382"
            d="M23.249,18.739c.369,2.223.735,4.43,1.109,6.679l-1.187-.832q-4.1-2.88-8.211-5.757a.619.619,0,0,0-.337-.1c-3.448-.005-6.9-.03-10.344.005A4.214,4.214,0,0,1,.026,15,4.561,4.561,0,0,1,0,14.489Q0,9.363,0,4.236A4.161,4.161,0,0,1,3.211.114a4.38,4.38,0,0,1,1-.108Q13.7,0,23.193,0a4.088,4.088,0,0,1,3.088,1.288,4.046,4.046,0,0,1,1.162,2.868q.011,5.209,0,10.418a4.169,4.169,0,0,1-4.014,4.151c-.053,0-.107.008-.182.014m-2.065-1.778h.224c.611,0,1.223,0,1.834,0a2.415,2.415,0,0,0,2.457-2.45q0-5.154,0-10.308a2.411,2.411,0,0,0-2.436-2.432q-9.547,0-19.095,0a2.481,2.481,0,0,0-.527.055,2.435,2.435,0,0,0-1.9,2.48q0,5.063,0,10.125a2.816,2.816,0,0,0,.04.511,2.412,2.412,0,0,0,2.436,2.017q5.466,0,10.932,0a.69.69,0,0,1,.421.135q3.088,2.175,6.182,4.342c.053.037.108.071.2.128l-.764-4.6"
            transform="translate(0 0)"
            fill="#6e6e6e"
          />
          <path
            id="Path_34383"
            data-name="Path 34383"
            d="M19.406,25.294H11.871a1.111,1.111,0,0,1-1.106-.819,1.132,1.132,0,0,1,1.046-1.444c.1,0,.208,0,.312,0H26.938a1.136,1.136,0,0,1,.751,2.015,1.147,1.147,0,0,1-.765.252q-3.758,0-7.517,0"
            transform="translate(-5.69 -12.221)"
            fill="#6e6e6e"
          />
          <path
            id="Path_34384"
            data-name="Path 34384"
            d="M19.418,11.418q3.76,0,7.521,0a1.13,1.13,0,0,1,.164,2.253,2.456,2.456,0,0,1-.256.01H11.916a1.134,1.134,0,0,1-.363-2.224,1.622,1.622,0,0,1,.4-.038q3.734,0,7.466,0"
            transform="translate(-5.691 -6.059)"
            fill="#6e6e6e"
          />
        </g>
      </g>
    </svg>
  );
};

export default Chat;
