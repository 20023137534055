import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { setUserData, updateWorkspaceUserData } from "auth/store/userSlice";
import _ from "lodash";
import firebase from "services/firebase";
import Utilities from "utils/utilities";

export const getMembers = createAsyncThunk(
  "membersApp/members/getMembers",
  async (user) => {
    return await firebase.getMembers(user);
  }
);

export const updatemember = createAsyncThunk(
  "membersApp/members/updatemember",
  async ({ user, member }, { dispatch, getState }) => {
    const data = await firebase.updateMember(user, member);

    const message = " has updated the member ";
    Utilities.successNotification(user, message, null, dispatch);

    return data;
  }
);

export const toggleFollowMember = createAsyncThunk(
  "membersApp/members/toggleFollowMember",
  async ({ user, member }, { dispatch, getState }) => {
    const friends = [...user?.data?.more?.friends] || [];
    let message = " has started following ";

    // Find index and replace
    if (friends.length > 0 && friends.includes(member.id)) {
      message = " has unfollowed ";
      _.pull(friends, member.id);
    } else {
      friends.push(member.id);
    }
    const newUserData = {
      ...user,
      data: { ...user.data, more: { ...user.data.more, friends: friends } },
    };

    dispatch(updateWorkspaceUserData(newUserData));
    dispatch(setUserData(newUserData));

    Utilities.successNotification(user, message, member.displayName, dispatch);

    return member;
  }
);

const membersAdapter = createEntityAdapter({});

export const {
  selectAll: selectMembers,
  selectById: selectMemberById,
  selectEntities: selectMembersEntities,
} = membersAdapter.getSelectors((state) => state.membersApp.members);

const membersSlice = createSlice({
  name: "membersApp/members",
  initialState: membersAdapter.getInitialState({
    memberDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {},
  extraReducers: {
    [updatemember.fulfilled]: membersAdapter.upsertOne,
    [toggleFollowMember.fulfilled]: membersAdapter.upsertOne,
    [getMembers.fulfilled]: membersAdapter.setAll,
  },
});

export const {
  openNewmemberDialog,
  closeNewmemberDialog,
  openEditmemberDialog,
  closeEditmemberDialog,
} = membersSlice.actions;

export default membersSlice.reducer;
