import { lazy } from "react";
import { authRoles } from "auth";
const DiscussionChatApp = lazy(() => import("./DiscussionChatApp"));

const DiscussionsAppConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.user,
  routes: [
    {
      path: "/:workspaceId/project/:projectId/topic/:topicId",
      element: <DiscussionChatApp />,
      showMembers: true,
    },
  ],
};

export default DiscussionsAppConfig;
