import React from "react";

const FilledHeart = (props) => {
  const { height = 30, width = 30 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 21.097 18.401"
    >
      <path
        id="Icon_feather-heart"
        data-name="Icon feather-heart"
        d="M21.794,6.124a5.551,5.551,0,0,0-7.852,0l-1.07,1.07L11.8,6.124a5.552,5.552,0,1,0-7.852,7.852l1.07,1.07L12.871,22.9l7.852-7.852,1.07-1.07a5.551,5.551,0,0,0,0-7.852Z"
        transform="translate(-2.323 -4.497)"
        fill="#fc3"
      />
    </svg>
  );
};

export default FilledHeart;
