import { combineReducers } from "@reduxjs/toolkit";
import auth from "auth/store";
import entities from "./slice";
import i18n from "./i18nSlice";
import { userLoggedOut } from "auth/store/userSlice";

const createReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    auth,
    entities,
    i18n,
    ...asyncReducers,
  });

  /*
	Reset the redux store when user logged out
	 */
  if (action.type === "auth/user/userLoggedOut") {
    state = undefined;
  }
  // if (
  //   action.type === "auth/user/setCurrentWorkspace" &&
  //   state.auth.user.data.currentWorkspace !== action.payload
  // ) {
  //   state.calendarApp = undefined;
  //   state.chatApp = undefined;
  //   state.chatPanel = undefined;
  //   state.entities = undefined;
  //   state.i18n = undefined;
  //   state.membersApp = undefined;
  //   state.notificationPanel = undefined;
  //   state.profile = undefined;
  //   state.projectApp = undefined;
  //   state.quickPanel = undefined;
  //   state.reportApp = undefined;
  //   state.scrumboardApp = undefined;
  //   state.taskApp = undefined;
  //   state.trackerApp = undefined;
  // }

  return combinedReducer(state, action);
};

export default createReducer;
