import { yupResolver } from "@hookform/resolvers/yup";
import {
  AppBar,
  Autocomplete,
  Button,
  Chip,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { searchHandler } from "api/algoliaSearch";
import ConfirmDeleteDialog from "components/modals/ConfirmDeleteDialog";
import DateRangePicker from "components/pickers/DateRangePicker";
import Completed from "components/svgs/icons/general/Completed";
import EmptyCircle from "components/svgs/icons/general/EmptyCircle";
import FeatherHeart from "components/svgs/icons/general/FeatherHeart";
import FilledHeart from "components/svgs/icons/general/FilledHeart";
import Important from "components/svgs/icons/general/Important";
import UploadMedia from "components/upload/UploadMedia";
import { differenceInDays, isFuture } from "date-fns";
import { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  AutocompleteStyleOverrides,
  AutocompleteTextFieldStyleOverrides,
  useStyledFormInputStyles,
} from "settings/styleOverrides";
import { updateNavigationItem } from "store/slice/navigationSlice";
import _ from "utils/lodash";
import Utilities from "utils/utilities";
import {
  selectLabels,
  selectTaskLabelsEntities,
} from "views/tasks/store/labelsSlice";
import {
  addTask,
  closeEditTaskFormDialog,
  closeNewTaskFormDialog,
  removeTask,
  updateTask,
} from "views/tasks/store/tasksSlice";
import TrackerApp from "views/tracker/TrackerApp";
import {
  addNewRow,
  goBack,
  resetState,
  setOpenTrackerAsDialog,
} from "views/tracker/store/trackerSlice";
import * as yup from "yup";

const schema = yup.object().shape({
  title: yup
    .string()
    .required("You must enter a task title.")
    .max(50, "You cannot have more than 50 characters"),
  hoursLogged: yup
    .number()
    .typeError("Must be a number type")
    .positive()
    .nullable()
    .min(0, "Min value can be 0"),
});

const TaskForm = (props) => {
  const { dialogType, setBoardTasks, editedTask, linkedParent } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { workspaceId, projectId, boardId, taskId } = useParams();
  const classes = useStyledFormInputStyles();
  const autocompleteTextFieldStyleOverrides =
    AutocompleteTextFieldStyleOverrides();

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [completedDate, setCompletedDate] = useState("");
  //const firstTimeRef = useRef(true);

  const labels = useSelector(selectLabels);
  const labelsEntities = useSelector(selectTaskLabelsEntities);
  const user = useSelector(({ auth }) => auth.user);
  //const tasks = useSelector(({ taskApp }) => taskApp.tasks.ids);
  const task = useSelector(({ taskApp }) => taskApp.tasks.taskFormDialog.data);
  const { openTrackerAsDialog } = useSelector((state) => state.trackerApp);

  const dueDate = task?.dueDate?.seconds
    ? new Date(task.dueDate.seconds * 1000)
    : task?.dueDate
    ? new Date(task?.dueDate)
    : new Date();

  const defaultValues = {
    id: task ? task.id : "",
    title: task ? task.title : "",
    subtitle: task ? task.subtitle : "",
    description: task ? task.description : "",
    dueDate: dueDate,
    hoursLogged: task ? task.hoursLogged : 0,
    completed: task ? task.completed : false,
    starred: task ? task.starred : false,
    important: task ? task.important : false,
    deleted: task ? task.deleted : false,
    idLabels: task ? task.idLabels : [],
    taskId: task ? task.taskId : Utilities.generateRandomNumber(),
    linkedParent: task ? task.linkedParent : linkedParent ? [linkedParent] : [],
    media: task ? task.media : [],
    projectId: task ? task.projectId : projectId || "",
    boardId: task ? task.boardId : boardId || "",
  };

  const { handleSubmit, watch, setValue, control, formState } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  const taskForm = watch();
  const autocompleteStyleOverrides = AutocompleteStyleOverrides();

  const updateBadges = (badge) => {
    dispatch(
      updateNavigationItem("task", {
        badge: {
          title: badge,
        },
      })
    );
  };

  const closeTaskDialog = () => {
    dispatch(setOpenTrackerAsDialog(false));
    dispatch(resetState());
    if (taskId) navigate(`/${user.data.currentWorkspace}/task`);

    return dialogType === "edit"
      ? dispatch(closeEditTaskFormDialog())
      : dispatch(closeNewTaskFormDialog());
  };

  const onSubmit = (model) => {
    // TODO: Comment OR condition for now - || model.taskId !== task.taskId
    if (!isValid) return;
    const data = { ...model, media: taskForm.media };

    if (data.dueDate === null) data.dueDate = "";
    data.completedDate = completedDate;

    if (dialogType === "new") {
      dispatch(
        addTask({ user, task: data, isUpdateMediaDirty: dirtyFields.media })
      ).then((response) => {
        if (_.isFunction(setBoardTasks) && response?.payload?.id)
          setBoardTasks(response?.payload);
      });
    } else {
      dispatch(
        updateTask({ user, task: data, isUpdateMediaDirty: dirtyFields.media })
      );
      if (_.isFunction(editedTask)) editedTask(data);
    }

    closeTaskDialog();
  };

  const deleteTaskHandler = () => {
    dispatch(removeTask({ user, task })).then(() => {
      closeTaskDialog();
    });
  };

  const updateMedia = (media) => {
    setValue("media", media, { shouldDirty: true });
  };

  const handleLogHours = async () => {
    if (task?.linkedParent?.length === 0) return;

    // Fetch data
    const projectBody = {
      filters: `objectType:project AND objectID:${task.projectId}`,
      query: "",
      workspaceId,
      attributesToRetrieve: ["name"],
    };
    const pRes = await searchHandler(projectBody);
    const { name } = pRes?.body?.hits.length > 0 ? pRes?.body?.hits[0] : [];

    if (name) {
      const projectInfo = { name: name, id: task.projectId };
      const taskInfo = { name: task.title, id: task.id };
      const cardInfo = { name: "", id: task?.linkedParent[0] };

      if (!isFuture(dueDate)) {
        const today = new Date();
        const dec = differenceInDays(today, dueDate);
        dispatch(goBack(dec));
      }
      dispatch(addNewRow({ cardInfo, projectInfo, taskInfo }));
    }
    dispatch(setOpenTrackerAsDialog(true));
  };

  // TODO: Do not need this for now
  // useEffect(() => {
  //   if (firstTimeRef.current) {
  //     firstTimeRef.current = false;
  //     return;
  //   }
  //   const badges = tasks.filter((x) => x.important && !x.completed);
  //   updateBadges(badges.length);
  // }, [tasks]);

  return (
    <>
      <DialogTitle component="div" className="p-0">
        <AppBar position="static" elevation={0} className="bg-primaryBlue">
          <Toolbar className="flex w-full overflow-x-auto px-[8px] sm:px-[16px]">
            <div className="flex flex-1">
              <div className="mb-[16px] sm:mb-0 flex items-center">
                <Typography className="text-white font-medium" component="span">
                  {dialogType === "edit" ? "Edit Task" : "New Task"}
                </Typography>
              </div>
            </div>
            <IconButton
              onClick={() => closeTaskDialog()}
              color="inherit"
              size="large"
              className={"text-white font-medium"}
            >
              <Icon>close</Icon>
            </IconButton>
          </Toolbar>
        </AppBar>
      </DialogTitle>

      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent
          className="p-[16px] sm:p-[24px]"
          sx={{ backgroundColor: (theme) => theme.palette.background.default }}
        >
          <div className="flex flex-col xmd:flex-row -mx-[8px]">
            {dialogType === "edit" && (
              <div className="mx-[8px]">
                <div className="flex items-center mb-2">
                  <Typography className="font-semibold text-[16px] mx-[8px]">
                    Id
                  </Typography>
                </div>
                <Controller
                  name="taskId"
                  control={control}
                  defaultValue={task?.taskId}
                  render={({ field }) => (
                    <Tooltip title="Click to copy">
                      <TextField
                        {...field}
                        autoComplete="off"
                        variant="outlined"
                        disabled
                        onClick={() =>
                          navigator.clipboard.writeText(
                            `${window.location.href}/${task?.taskId}`
                          )
                        }
                        InputProps={{
                          className: classes.input,
                        }}
                        inputProps={{
                          className: "text-primaryBlue cursor-copy",
                        }}
                        InputLabelProps={{
                          shrink: true,
                          className: classes.label,
                        }}
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "unset",
                          },
                        }}
                      />
                    </Tooltip>
                  )}
                />
              </div>
            )}

            <div className="flex-1 mx-[8px] mt-6 xmd:mt-0">
              <div className="flex items-center mb-2">
                <Typography className="font-semibold text-[16px] mx-[8px]">
                  Title
                </Typography>
              </div>
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <TextField
                    autoComplete="off"
                    {...field}
                    type="text"
                    variant="outlined"
                    fullWidth
                    required
                    className=""
                    error={!!errors.title}
                    helperText={errors?.title?.message}
                    InputProps={{
                      className: classes.input,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      className: classes.label,
                    }}
                  />
                )}
              />
            </div>
          </div>

          <div className="w-full mt-6">
            <Typography className="font-semibold text-[16px] mx-[8px] mb-2">
              Subtitle
            </Typography>
            <Controller
              name="subtitle"
              control={control}
              render={({ field }) => (
                <TextField
                  autoComplete="off"
                  {...field}
                  type="text"
                  variant="outlined"
                  fullWidth
                  className="mb-6"
                  InputProps={{
                    className: classes.input,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.label,
                  }}
                />
              )}
            />

            <Typography className="font-semibold text-[16px] mx-[8px] mb-2">
              Description
            </Typography>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  autoComplete="off"
                  {...field}
                  multiline
                  placeholder="Tell us more about this task"
                  rows="4"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    className: classes.inputTextArea,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.label,
                  }}
                />
              )}
            />
          </div>

          <div className="flex flex-col">
            <div className="">
              <div className="flex items-center mt-[16px] mb-[12px]">
                <Icon className="text-[14px]" color="inherit">
                  label
                </Icon>
                <Typography className="font-semibold text-[16px] mx-[8px]">
                  Labels
                </Typography>
              </div>
              <Autocomplete
                className="!m-0"
                multiple
                options={labels}
                noOptionsText={<div className="text-sm">No options</div>}
                sx={autocompleteStyleOverrides}
                getOptionLabel={(label) => label.title}
                value={
                  taskForm?.idLabels
                    ? taskForm.idLabels.map((id) => labelsEntities[id])
                    : []
                }
                onChange={(event, newValue) => {
                  setValue(
                    "idLabels",
                    newValue.map((item) => item.id),
                    { shouldDirty: true }
                  );
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    return (
                      <Chip
                        label={option?.title}
                        {...getTagProps({ index })}
                        style={{
                          backgroundColor: option?.color,
                          color: "#fff",
                        }}
                      />
                    );
                  })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoComplete="off"
                    placeholder="Select multiple Labels"
                    variant="outlined"
                    sx={autocompleteTextFieldStyleOverrides}
                    InputLabelProps={{
                      shrink: true,
                      className: classes.label,
                    }}
                  />
                )}
              />
            </div>
          </div>

          <div className="flex flex-col sm:flex-row -mx-[8px]">
            <div className="flex-1 mb-[2px] mx-[8px]">
              <div className="flex items-center mt-[16px] mb-[12px]">
                <Icon className="text-[14px]" color="inherit">
                  access_time
                </Icon>
                <Typography className="font-semibold text-[16px] mx-[8px]">
                  Hours Logged
                </Typography>
              </div>
              {/* <Controller
                name="hoursLogged"
                control={control}
                render={({ field }) => (
                  <TextField
                    autoComplete="off"
                    {...field}
                    type="text"
                    variant="outlined"
                    fullWidth
                    required
                    className="mb-6"
                    error={!!errors.hoursLogged}
                    helperText={errors?.hoursLogged?.message}
                    InputProps={{
                      className: classes.input,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      className: classes.label,
                    }}
                  />
                )}
              /> */}
              <div className="flex justify-center w-full">
                <Tooltip
                  title={
                    task?.linkedParent?.length === 0
                      ? "This task is not linked to any scrumboard"
                      : ""
                  }
                >
                  <span className="w-full">
                    <Button
                      variant="outlined"
                      size="small"
                      className="rounded-md w-full text-md font-medium text-primaryBlue"
                      onClick={handleLogHours}
                      disabled={task?.linkedParent?.length === 0}
                    >
                      Log hours
                    </Button>
                  </span>
                </Tooltip>
              </div>
            </div>

            <div className="flex-1 mb-[24px] mx-[8px]">
              <div className="flex items-center mt-[16px] mb-[12px]">
                <Icon className="text-[14px]" color="inherit">
                  event_available
                </Icon>
                <Typography className="font-semibold text-[16px] mx-[8px]">
                  Due date
                </Typography>
              </div>
              <Controller
                name="dueDate"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <DateRangePicker onChange={onChange} value={value} />
                )}
              />
            </div>
          </div>

          <div className="mb-[24px]">
            <div className="flex items-center mt-[8px] mb-[12px]">
              <Icon className="text-[14px]" color="inherit">
                attachment
              </Icon>
              <Typography className="font-semibold text-[16px] mx-[8px]">
                Attachments
              </Typography>
            </div>

            <UploadMedia
              mediaData={taskForm.media}
              updateMedia={updateMedia}
              user={user}
            />
          </div>

          <div className="flex flex-col mb-[24px]">
            <div className="flex justify-between items-center mt-[16px] mb-[12px]">
              <Typography className="font-semibold text-[16px] mx-[8px]">
                Priority
              </Typography>
              <Controller
                name="important"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <Box
                    className="w-6 h-6 cursor-pointer"
                    onClick={() => onChange(!value)}
                  >
                    {value ? (
                      <Important color="#fff" fill="#f44336" stroke="none" />
                    ) : (
                      <Important />
                    )}
                  </Box>
                )}
              />
            </div>
            <div className="flex justify-between items-center mt-[16px] mb-[12px]">
              <Typography className="font-semibold text-[16px] mx-[8px]">
                Favourites
              </Typography>
              <Controller
                name="starred"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <Box
                    className="w-6 h-6 cursor-pointer"
                    onClick={() => onChange(!value)}
                  >
                    {value ? (
                      <FilledHeart width={24} height={24} />
                    ) : (
                      <FeatherHeart width={24} height={24} />
                    )}
                  </Box>
                )}
              />
            </div>
            <div className="flex justify-between items-center mt-[16px] mb-[12px]">
              <Typography className="font-semibold text-[16px] mx-[8px]">
                Mark as Completed
              </Typography>
              <Controller
                name="completed"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Box
                    className="w-6 h-6 cursor-pointer"
                    onClick={(ev) => {
                      const newValue = !value;
                      newValue
                        ? setCompletedDate(new Date())
                        : setCompletedDate("");
                      onChange(newValue);
                    }}
                  >
                    {value ? <Completed /> : <EmptyCircle />}
                  </Box>
                )}
              />
            </div>
            <div></div>
          </div>

          <div className="flex w-full justify-between my-[24px]">
            <div className="w-full">
              {dialogType === "edit" && (
                <Button
                  onClick={() => setConfirmDialog(true)}
                  className="bg-red-500 hover:bg-red-500/70 rounded text-white xs:text-sm xmd:text-base xs:min-w-[80px] xmd:min-w-[110px] max-h-[40px]"
                >
                  Delete
                </Button>
              )}
            </div>

            <div className="flex">
              <Button
                onClick={() => dispatch(closeNewTaskFormDialog())}
                className="bg-lightGray rounded hover:bg-lightGray/70 text-white xs:text-sm xmd:text-base xs:min-w-[80px] xmd:min-w-[110px] max-h-[40px] mr-4"
              >
                Cancel
              </Button>
              <Button
                className={`${
                  _.isEmpty(dirtyFields) || !isValid
                    ? "bg-blueTransparent"
                    : "bg-primaryBlue"
                } max-h-[40px] xs:text-sm xmd:text-base text-white rounded hover:bg-primaryBlueLight xs:min-w-[80px] xmd:min-w-[110px]`}
                type="submit"
                disabled={_.isEmpty(dirtyFields) || !isValid}
              >
                Save
              </Button>
            </div>

            {confirmDialog && (
              <ConfirmDeleteDialog
                open={confirmDialog}
                onClose={() => setConfirmDialog(false)}
                deleteFunc={deleteTaskHandler}
              />
            )}
          </div>
        </DialogContent>
        {openTrackerAsDialog && <TrackerApp />}
      </form>
    </>
  );
};

export default TaskForm;
