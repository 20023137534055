import { useSelector } from "react-redux";
import { useTheme, ThemeProvider } from "@mui/material/styles";
import { selectContrastMainTheme } from "store/slice/settingsSlice";
import clsx from "clsx";

const PageSimpleHeader = (props) => {
  const theme = useTheme();
  const contrastTheme = useSelector(
    selectContrastMainTheme(theme.palette.primary.main)
  );

  return (
    <div className={clsx("PageSimple-header")}>
      {props.header && (
        <ThemeProvider theme={contrastTheme}>{props.header}</ThemeProvider>
      )}
    </div>
  );
};

export default PageSimpleHeader;
