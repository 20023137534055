import { combineReducers } from "@reduxjs/toolkit";
import filters from "./filtersSlice";
import folders from "./foldersSlice";
import labels from "./labelsSlice";
import tasks from "./tasksSlice";

const reducer = combineReducers({
  tasks,
  folders,
  labels,
  filters,
});

export default reducer;
