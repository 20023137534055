import { Close } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { format } from "date-fns";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { StyledSwipeableDrawer, useStyles } from "settings/styleOverrides";
import _ from "utils/lodash";
import Utilities from "utils/utilities";
import { getCustomFields } from "views/settings/store/settingsSlice";
import { toggleFollowMember } from "./store/membersSlice";

const MemberDetails = (props) => {
  const { member, aboutDrawer, setAboutDrawer } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [spinner, setSpinner] = useState(false);
  const user = useSelector(({ auth }) => auth.user);
  const customFieldsType = useSelector(
    ({ settingsApp }) => settingsApp?.settings?.memberCustomFields
  );

  const date = member?.more?.birthday;
  const birthday = date?.seconds
    ? Utilities.formatDate(new Date(date.seconds * 1000), "MM/dd/yyyy", format)
    : date
    ? Utilities.formatDate(new Date(date), "MM/dd/yyyy", format)
    : "";

  const defaultValues = {
    displayName: member?.displayName,
    email: member?.email,
    gender: member?.more?.gender || "male",
    birthday: birthday,
    about: member?.more?.about || "",
    location: member?.more?.location || "",
    role: member?.more?.role || "",
    skills: member?.more?.skills || "",
    address: member?.more?.address || "",
    phone: member?.more?.phone || "",
    website: member?.more?.website || "",
    customFields: _.cloneDeep(member?.customFields) || [],
    friends: member?.more?.friends || [],
    groups: member?.more?.groups || [],
  };

  const { control, watch, reset } = useForm({
    mode: "onChange",
    defaultValues,
  });
  const memberDetailsForm = watch();

  useEffect(() => {
    if (!user?.data?.currentWorkspace) return;
    dispatch(getCustomFields({ user, type: "member" }));
  }, [user?.data?.currentWorkspace]);

  useEffect(() => {
    Utilities.setCustomFields(customFieldsType, defaultValues, "", reset);
  }, [customFieldsType]);

  return (
    <StyledSwipeableDrawer
      open={aboutDrawer}
      anchor="right"
      disableSwipeToOpen
      onOpen={() => {}}
      onClose={() => {}}
      size="medium"
    >
      <motion.div
        variants={Utilities.containerConfig(0.05)}
        initial="hidden"
        animate="show"
      >
        <div className="flex justify-between items-center xs:px-6 md:px-10 bg-primaryBlue py-4">
          <Typography className="text-white text-xl">About</Typography>
          <Close
            className="text-white cursor-pointer"
            onClick={() => setAboutDrawer(false)}
          />
        </div>
        <div className="h-[300px] flex flex-row lg:m-[40px] md:m-[40px] sm:m-[20px]">
          <div
            className={`w-full relative h-full text-right bg-cover bg-center bg-slate-800`}
          >
            {!spinner ? (
              <>
                {member?.coverPhotoURL && (
                  <img
                    src={member?.coverPhotoURL}
                    className="w-full h-full object-cover"
                    alt="avatar"
                  />
                )}

                <div className="top-0 absolute w-full h-full flex flex-row items-center justify-between lg:px-[54px] md:px-[16px] sm:px-[12px]  py-[30px]">
                  <div className="lg:w-[313px] md:w-[250px] sm:w-[200px]">
                    <Card className="rounded-[6px] h-[275px] shadow p-4 flex flex-col justify-center items-center">
                      <div className="mb-4">
                        <div className="flex flex-column relative rounded-full overflow-hidden w-[86px] h-[86px] ">
                          <Avatar
                            className="absolute w-full h-full"
                            src={member?.photoURL}
                            alt={member?.name}
                          />
                        </div>
                      </div>
                      <Typography fontWeight={600} fontSize={18}>
                        {member.displayName}
                      </Typography>
                      <Typography fontSize={11}>{member.email}</Typography>
                      <div className="flex flex-row justify-between mt-4">
                        <Typography
                          fontSize={13}
                          className="border-r-2 border-primaryBlue px-4 text-center"
                        >
                          <span className="f-[17px] font-[700]">
                            {member?.more?.friends?.length || 0}
                          </span>
                          <br />
                          Following
                        </Typography>
                        <Typography fontSize={13} className="px-4 text-center">
                          <span className="f-[17px] font-[700]">
                            {member?.more?.posts || 0}
                          </span>
                          <br />
                          Posts
                        </Typography>
                      </div>
                      {member.id !== user.uid && (
                        <Button
                          className="flex justify-center items-center mt-5 text-white text-sm bg-primaryBlue hover:bg-primaryBlueLight px-4 py-2 rounded-full"
                          onClick={() =>
                            dispatch(toggleFollowMember({ user, member }))
                          }
                        >
                          {user?.data?.more?.friends?.includes(member.id)
                            ? "Following"
                            : "Follow"}
                        </Button>
                      )}
                    </Card>
                  </div>
                </div>
              </>
            ) : (
              <div className="w-full h-full flex items-center justify-center">
                <CircularProgress
                  color="info"
                  className="absolute m-auto  z-50"
                />
              </div>
            )}
          </div>
        </div>
        <div className="md:flex flex-wrap gap-10 justify-center mb-10">
          <form
            name="loginForm"
            noValidate
            className="flex flex-col flex-1 xs:mx-6 md:mx-10"
          >
            <Accordion className="mb-1" defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="bg-primaryBlue rounded-t-xl"
              >
                <Typography className="font-medium text-xl text-white">
                  General Information
                </Typography>
              </AccordionSummary>

              <AccordionDetails className="pt-10">
                <div className="mb-7">
                  <Typography className="font-semibold mb-4 text-15">
                    Name
                  </Typography>

                  <Controller
                    name="displayName"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        autoComplete="off"
                        id="outlined-basic"
                        size="small"
                        variant="outlined"
                        name="displayName"
                        placeholder="Display Name"
                        InputProps={{
                          classes: {
                            input: classes.thaiTextFieldInputProps,
                          },
                        }}
                        fullWidth
                        disabled={true}
                        required
                        aria-readonly={true}
                      />
                    )}
                  />
                </div>
                <div className="mb-7">
                  <Typography className="font-semibold mb-4 text-15">
                    Email
                  </Typography>

                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        autoComplete="off"
                        id="outlined-basic"
                        size="small"
                        variant="outlined"
                        placeholder="Email"
                        name="email"
                        InputProps={{
                          classes: {
                            input: classes.thaiTextFieldInputProps,
                          },
                        }}
                        fullWidth
                        disabled={true}
                        required
                        aria-readonly={true}
                      />
                    )}
                  />
                </div>
                <div className="mb-7">
                  <Typography className="font-semibold mb-4 text-15">
                    Gender
                  </Typography>
                  <Controller
                    name="gender"
                    control={control}
                    defaultValue="male"
                    render={({ field: { onChange, value } }) => (
                      <>
                        <RadioGroup
                          row
                          aria-labelledby="radio-buttons-group-label"
                          name="gender"
                          value={value}
                          onChange={onChange}
                        >
                          <FormControlLabel
                            value="female"
                            className="mr-10"
                            control={<Radio />}
                            label="Female"
                            disabled={true}
                            aria-readonly={true}
                          />
                          <FormControlLabel
                            value="male"
                            className="mr-10"
                            control={<Radio />}
                            label="Male"
                            disabled={true}
                            aria-readonly={true}
                          />
                          <FormControlLabel
                            value="other"
                            className="mr-10"
                            control={<Radio />}
                            label="Other"
                            disabled={true}
                            aria-readonly={true}
                          />
                        </RadioGroup>
                      </>
                    )}
                  />
                </div>

                <div className="mb-7">
                  <Typography className="font-semibold mb-4 text-15">
                    Birthday
                  </Typography>
                  <Controller
                    name="birthday"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        autoComplete="off"
                        id="outlined-basic"
                        size="small"
                        variant="outlined"
                        placeholder="Date of birth"
                        name="birthday"
                        InputProps={{
                          classes: {
                            input: classes.thaiTextFieldInputProps,
                          },
                        }}
                        fullWidth
                        disabled={true}
                        required
                        aria-readonly={true}
                      />
                    )}
                  />
                </div>

                <div className="mb-7">
                  <Typography className="font-semibold mb-4 text-15">
                    Location
                  </Typography>
                  <Controller
                    name="location"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        autoComplete="off"
                        id="outlined-basic"
                        size="small"
                        variant="outlined"
                        name="location"
                        placeholder="Your workspace location"
                        InputProps={{
                          classes: {
                            input: classes.thaiTextFieldInputProps,
                          },
                        }}
                        fullWidth
                        disabled={true}
                        required
                        aria-readonly={true}
                      />
                    )}
                  />
                </div>

                <div className="mb-7">
                  <Typography className="font-semibold mb-4 text-15">
                    About Me
                  </Typography>
                  <Controller
                    name="about"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        autoComplete="off"
                        id="outlined-basic"
                        size="small"
                        variant="outlined"
                        placeholder="Describe yourself in few words"
                        name="about"
                        fullWidth
                        disabled={true}
                        required
                        aria-readonly={true}
                        multiline
                        rows={4}
                      />
                    )}
                  />
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion className="mb-1" defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="bg-primaryBlue rounded-t-xl"
              >
                <Typography className="font-medium text-xl text-white">
                  Work
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <div className="mb-7">
                  <Typography className="font-semibold mb-4 text-15">
                    Role
                  </Typography>
                  <Controller
                    name="role"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        autoComplete="off"
                        id="outlined-basic"
                        size="small"
                        variant="outlined"
                        placeholder="Your current position"
                        InputProps={{
                          classes: {
                            input: classes.thaiTextFieldInputProps,
                          },
                        }}
                        name="role"
                        fullWidth
                        disabled={true}
                        required
                        aria-readonly={true}
                      />
                    )}
                  />
                </div>

                <div className="mb-7">
                  <Typography className="font-semibold mb-4 text-15">
                    Skills
                  </Typography>
                  <Controller
                    name="skills"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        autoComplete="off"
                        id="outlined-basic"
                        size="small"
                        variant="outlined"
                        placeholder="Your primary skills"
                        InputProps={{
                          classes: {
                            input: classes.thaiTextFieldInputProps,
                          },
                        }}
                        name="skills"
                        fullWidth
                        disabled={true}
                        required
                        aria-readonly={true}
                      />
                    )}
                  />
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion className="mb-1" defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="bg-primaryBlue rounded-t-xl"
              >
                <Typography className="font-medium text-xl text-white">
                  Contact
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <div className="mb-7">
                  <Typography className="font-semibold mb-4 text-15">
                    Address
                  </Typography>
                  <Controller
                    name="address"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        autoComplete="off"
                        id="outlined-basic"
                        size="small"
                        variant="outlined"
                        name="address"
                        placeholder="Your complete address"
                        fullWidth
                        disabled={true}
                        required
                        aria-readonly={true}
                        multiline
                        rows={2}
                      />
                    )}
                  />
                </div>

                <div className="mb-7">
                  <Typography className="font-semibold mb-4 text-15">
                    Phone
                  </Typography>

                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        autoComplete="off"
                        id="outlined-basic"
                        size="small"
                        variant="outlined"
                        placeholder="Your mobile number"
                        InputProps={{
                          classes: {
                            input: classes.thaiTextFieldInputProps,
                          },
                        }}
                        name="phone"
                        fullWidth
                        disabled={true}
                        required
                        aria-readonly={true}
                      />
                    )}
                  />
                </div>

                <div className="mb-7">
                  <Typography className="font-semibold mb-4 text-15">
                    Website
                  </Typography>

                  <Controller
                    name="website"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        autoComplete="off"
                        id="outlined-basic"
                        size="small"
                        variant="outlined"
                        name="website"
                        placeholder="Your portfolio or website link"
                        InputProps={{
                          classes: {
                            input: classes.thaiTextFieldInputProps,
                          },
                        }}
                        fullWidth
                        disabled={true}
                        required
                        aria-readonly={true}
                      />
                    )}
                  />
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion className="mb-1" defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="bg-primaryBlue rounded-t-xl"
              >
                <Typography className=" font-medium text-xl text-white">
                  Custom Fields
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <div className="mb-7">
                  {memberDetailsForm.customFields &&
                    memberDetailsForm.customFields.map((inputField, index) => {
                      if (!inputField.active) return;
                      return (
                        <div
                          key={inputField.id}
                          className="flex flex-col xmd:flex-row my-4"
                        >
                          <div className="mx-[8px] w-full">
                            <div className="xmd:flex items-center hidden">
                              {index === 0 && (
                                <Typography className="font-semibold mb-4 text-15">
                                  Name
                                </Typography>
                              )}
                            </div>
                            <Controller
                              name={`customFields[${index}].fieldName`}
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  autoComplete="off"
                                  name="fieldName"
                                  variant="outlined"
                                  InputProps={{
                                    classes: {
                                      input: classes.thaiTextFieldInputProps,
                                    },
                                  }}
                                  fullWidth
                                  disabled={true}
                                />
                              )}
                            />
                          </div>

                          <div className="mx-[8px] w-full">
                            <div className="xmd:flex items-center hidden">
                              {index === 0 && (
                                <Typography className="font-semibold mb-4 text-15">
                                  Value
                                </Typography>
                              )}
                            </div>
                            <Controller
                              name={`customFields[${index}].fieldValue`}
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  autoComplete="off"
                                  name="fieldValue"
                                  variant="outlined"
                                  InputProps={{
                                    classes: {
                                      input: classes.thaiTextFieldInputProps,
                                    },
                                  }}
                                  fullWidth
                                  disabled={true}
                                />
                              )}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              </AccordionDetails>
            </Accordion>
          </form>
        </div>
      </motion.div>
    </StyledSwipeableDrawer>
  );
};

export default MemberDetails;
