import WidgetWrapper from "components/WidgetWrapper";
import SearchBar from "components/header/SearchBar";
import LabelsDialog from "components/labels/LabelsDialog";
import TaskFormDialog from "components/tasks/TaskFormDialog";
import { motion } from "framer-motion";
import { useDeepCompareEffect } from "hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Root } from "settings/styleOverrides";
import { openLabelDialog } from "store/slice/labelDialogSlice";
import withReducer from "store/withReducer";
import Utilities from "utils/utilities";
import TaskList from "./TaskList";
import reducer from "./store";
import { getFilters, selectFilters } from "./store/filtersSlice";
import { getFolders, selectFolders } from "./store/foldersSlice";
import { getTaskLabels } from "./store/labelsSlice";
import {
  getTasks,
  getTasksOfUsers,
  openNewTaskFormDialog,
  setTaskFilter,
} from "./store/tasksSlice";

const TaskApp = (props) => {
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  //const filters = useSelector(selectFilters);
  //const folders = useSelector(selectFolders);
  const { startDate, endDate, userId, filter, taskFormDialog } = useSelector(
    (state) => state?.taskApp?.tasks
  );
  const routeParams = useParams();
  const [showFilterBar, setShowFilterBar] = useState(false);
  const [loadingSpinner, setLoadingSpinner] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    // TODO: Delete dead code for filters & folders and route params filters
    if (!user.data.currentWorkspace) return;
    //if (filters.length === 0) dispatch(getFilters(user));
    //if (folders.length === 0) dispatch(getFolders());
    dispatch(getTaskLabels(user));
    dispatch(
      setTaskFilter([
        {
          label: "member",
          operator: "==",
          value: user?.data?.displayName,
          id: user?.uid,
          color: user?.data?.more?.color,
        },
      ])
    );
  }, [user?.data?.currentWorkspace]);

  useDeepCompareEffect(() => {
    if (!user.data.currentWorkspace) return;
    dispatch(
      getTasks({
        userId: userId ? userId : user.uid,
        workspaceId: user.data.currentWorkspace,
        routeParams,
        startDate,
        endDate,
        initialPoint: null,
      })
    )
      .then(() => setLoadingSpinner(false))
      .catch(() => setLoadingSpinner(false));
  }, [user?.data?.currentWorkspace, startDate, endDate]);

  useEffect(() => {
    if (!showFilterBar) return;

    const completed = filter?.find((x) => x.label === "completed");
    const favourite = filter?.find((x) => x.label === "favourite");
    const priority = filter?.find((x) => x.label === "priority");

    dispatch(
      getTasksOfUsers({
        user,
        start: startDate,
        end: endDate,
        ids: filter.filter((x) => x.label === "member").map((each) => each.id),
        labelIds: filter
          .filter((x) => x.label === "label")
          .map((each) => each.id),
        completed: completed?.id,
        favourite: favourite?.id,
        priority: priority?.id,
      })
    );
  }, [filter]);

  const Main = () => (
    <div>
      <Root
        header={
          <SearchBar
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            showCardViewBtn={false}
            addBtnTxt="Create a task"
            handleClickOpen={() => dispatch(openNewTaskFormDialog())}
            showSortBtn={false}
            showFilterBtn={true}
            onFilterBtnClick={() => setShowFilterBar(!showFilterBar)}
            showPopupBtn={true}
            popupBtnTxt="Labels"
            handleOpenPopup={() => dispatch(openLabelDialog({ app: "task" }))}
            showFilterBar={showFilterBar}
            filterBarStyle="task"
          />
        }
        content={
          <motion.div
            className="mt-6"
            variants={Utilities.containerConfig(0.1)}
            initial="hidden"
            animate="show"
          >
            <TaskList
              setLoadingSpinner={setLoadingSpinner}
              loadingSpinner={loadingSpinner}
              searchQuery={searchQuery}
            />
          </motion.div>
        }
      />
      <LabelsDialog />
      {taskFormDialog?.props?.open && <TaskFormDialog />}
    </div>
  );

  if (props?.widget)
    return (
      <WidgetWrapper {...props}>
        <Main />
      </WidgetWrapper>
    );
  return <Main />;
};

export default withReducer("taskApp", reducer)(TaskApp);
