const quotes = [
    {
        _id: "RKl9iZrjfP",
        author: "Charles Dickens",
        content: "We need never be ashamed of our tears.",
        tags: ["Sadness"],
        authorSlug: "charles-dickens",
        length: 38,
        dateAdded: "2023-04-14",
        dateModified: "2023-04-14",
    },
    {
        _id: "smbfaOldHD",
        author: "The Buddha",
        content: "A disciplined mind brings happiness.",
        tags: ["Wisdom", "Happiness"],
        authorSlug: "the-buddha",
        length: 36,
        dateAdded: "2023-03-30",
        dateModified: "2023-04-14",
    },
    {
        _id: "WnJz7QKVKzI",
        author: "The Buddha",
        content: "The root of suffering is attachment.",
        tags: ["Wisdom", "Happiness"],
        authorSlug: "the-buddha",
        length: 36,
        dateAdded: "2023-03-30",
        dateModified: "2023-04-14",
    },
    {
        _id: "1YkUWV_0wT",
        author: "The Buddha",
        content: "Give, even if you only have a little.",
        tags: ["Wisdom"],
        authorSlug: "the-buddha",
        length: 37,
        dateAdded: "2023-03-30",
        dateModified: "2023-04-14",
    },
    {
        _id: "6_rYXTe8c4S",
        author: "The Buddha",
        content: "May all beings have happy minds.",
        tags: ["Wisdom", "Happiness"],
        authorSlug: "the-buddha",
        length: 32,
        dateAdded: "2023-03-30",
        dateModified: "2023-04-14",
    },
    {
        _id: "0p8bgUAkaWj",
        author: "Sandy Koufax",
        content: "Pitching is the art of instilling fear.",
        tags: ["Sports", "Competition"],
        authorSlug: "sandy-koufax",
        length: 39,
        dateAdded: "2022-07-06",
        dateModified: "2023-04-14",
    },
    {
        _id: "1gJ03696_R",
        author: "Benjamin Disraeli",
        content: "Never complain and never explain.",
        tags: ["Motivational"],
        authorSlug: "benjamin-disraeli",
        length: 33,
        dateAdded: "2022-07-06",
        dateModified: "2023-04-14",
    },
    {
        _id: "Hpo_qh-q76A",
        author: "Reggie Jackson (basketball, born 1990)",
        content: "Fans don't boo nobodies.",
        tags: ["Sports", "Competition"],
        authorSlug: "reggie-jackson-basketball-born-1990",
        length: 24,
        dateAdded: "2022-07-06",
        dateModified: "2023-04-14",
    },
    {
        _id: "I4MksjS5zey",
        author: "Carl Lewis",
        content: "Life is about timing.",
        tags: ["Sports", "Competition"],
        authorSlug: "carl-lewis",
        length: 21,
        dateAdded: "2022-07-06",
        dateModified: "2023-04-14",
    },
    {
        _id: "U1gie7MXOB",
        author: "Abraham Lincoln",
        content: "The ballot is stronger than the bullet.",
        tags: ["Politics", "Famous Quotes"],
        authorSlug: "abraham-lincoln",
        length: 39,
        dateAdded: "2022-03-12",
        dateModified: "2023-04-14",
    },
    {
        _id: "s2aFAaJuV3",
        author: "Alan Watts",
        content: "I owe my solitude to other people.",
        tags: ["Humorous"],
        authorSlug: "alan-watts",
        length: 34,
        dateAdded: "2022-03-12",
        dateModified: "2023-04-14",
    },
    {
        _id: "DgPQoKY7nq",
        author: "Winston Churchill",
        content: "History is written by the victors.",
        tags: ["History"],
        authorSlug: "winston-churchill",
        length: 34,
        dateAdded: "2022-03-12",
        dateModified: "2023-04-14",
    },
    {
        _id: "Log-YUXR2i-",
        content: "Truth is powerful and it prevails.",
        author: "Sojourner Truth",
        tags: ["Truth"],
        authorSlug: "sojourner-truth",
        length: 34,
        dateAdded: "2021-05-12",
        dateModified: "2023-04-14",
    },
    {
        _id: "0KzOsnrddItt",
        content: "Nothing will work unless you do.",
        author: "Maya Angelou",
        tags: ["Success"],
        authorSlug: "maya-angelou",
        length: 32,
        dateAdded: "2021-05-07",
        dateModified: "2023-04-14",
    },
    {
        _id: "Fk_kOv_a_9r",
        content: "What worries you masters you.",
        author: "Haddon Robinson",
        tags: ["Famous Quotes"],
        authorSlug: "haddon-robinson",
        length: 29,
        dateAdded: "2021-05-05",
        dateModified: "2023-04-14",
    },
    {
        _id: "_eNo_ZJ4ggNS",
        content: "The beginning is always today.",
        author: "Mary Wollstonecraft",
        tags: ["Famous Quotes"],
        authorSlug: "mary-wollstonecraft",
        length: 30,
        dateAdded: "2021-05-05",
        dateModified: "2023-04-14",
    },
    {
        _id: "64iqW6Q_Bg",
        content: "A friend is, as it were, a second self.",
        author: "Cicero",
        tags: ["Friendship"],
        authorSlug: "cicero",
        length: 39,
        dateAdded: "2021-05-05",
        dateModified: "2023-04-14",
    },
    {
        _id: "IxrwsheO2w6",
        content: "Wherever you go, go with all your heart.",
        author: "Confucius",
        tags: ["Wisdom"],
        authorSlug: "confucius",
        length: 40,
        dateAdded: "2021-04-23",
        dateModified: "2023-04-14",
    },
    {
        _id: "OY_FmDtw10XY",
        content: "Love is a serious mental disease.",
        author: "Plato",
        tags: ["Famous Quotes"],
        authorSlug: "plato",
        length: 33,
        dateAdded: "2021-04-23",
        dateModified: "2023-04-14",
    },
    {
        _id: "ITSWNEMYeJ2",
        content: "Our distrust is very expensive.",
        author: "Ralph Waldo Emerson",
        tags: ["Famous Quotes"],
        authorSlug: "ralph-waldo-emerson",
        length: 31,
        dateAdded: "2021-04-23",
        dateModified: "2023-04-14",
    },
];

export const getQuote = async () => {
    //const endpoint = "https://api.quotable.io/random?maxLength=40";
    //const request = await fetch(endpoint);
    //const response = await request.json();
    const response = quotes[Math.floor(Math.random() * quotes.length)];
    if (response.error) {
        return {
            statusCode: 404,
            body: response,
        };
    }
    return {
        statusCode: 200,
        body: response,
    };
};
