import React from "react";

const Security = () => {
  const className = "fill-primaryBlue";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="71.853"
      height="71.001"
      viewBox="0 0 71.853 71.001"
      className={className}
    >
      <g
        id="Group_45981"
        data-name="Group 45981"
        transform="translate(-595.168 -312.001)"
      >
        <path
          id="Path_39908"
          data-name="Path 39908"
          d="M164.016,21.184c-.74,11.2-3.429,22.479-9.57,32.739A47.331,47.331,0,0,1,129.458,74.75a3.777,3.777,0,0,1-2.724,0c-11.605-4.243-20.172-11.971-26.087-22.733A68.194,68.194,0,0,1,93.232,29.9c-.489-3-.735-6.049-1.04-9.08a2.931,2.931,0,0,1,2.282-3.367,20.16,20.16,0,0,0,6.345-3.752,61.492,61.492,0,0,0,8.669-8.683,2.785,2.785,0,0,1,2.544-.984c2.685.213,5.369.515,8.058.559q7.977.133,15.959,0c2.776-.049,5.548-.387,8.322-.589a2.629,2.629,0,0,1,2.294.974,63.83,63.83,0,0,0,8.678,8.677,20.82,20.82,0,0,0,6.769,3.937c1.281.4,1.977,1.577,1.9,3.586m-5.524,1.445c.155-.41-.228-.7-.8-.983a25.887,25.887,0,0,1-2.667-1.516,55.022,55.022,0,0,1-10.888-9.8,1.936,1.936,0,0,0-1.786-.694c-3.153.207-6.309.428-9.466.5a169.992,169.992,0,0,1-19.418-.514,1.294,1.294,0,0,0-1.211.5q-1.271,1.459-2.628,2.844a42.562,42.562,0,0,1-11.5,8.917.9.9,0,0,0-.341.739c.241,2.188.448,4.385.816,6.553a63.551,63.551,0,0,0,7.053,20.686,42.381,42.381,0,0,0,21.573,19.281,2.1,2.1,0,0,0,1.708.013A41.4,41.4,0,0,0,146.61,55.76c7.437-9.682,10.591-20.923,11.882-33.132"
          transform="translate(503 308)"
        />
        <path
          id="Path_39909"
          data-name="Path 39909"
          d="M133.568,52.713h-10.55c-1.406,0-1.854-.439-1.854-1.82q0-4.942,0-9.883c0-1.264.4-1.709,1.645-1.751.509-.017.622-.215.637-.683a26.547,26.547,0,0,1,.239-4.12,10.1,10.1,0,0,1,19.957.956c.105,1.06.086,2.133.087,3.2,0,.446.132.624.6.644,1.294.056,1.674.483,1.675,1.792q0,4.942,0,9.882c0,1.309-.465,1.781-1.759,1.781q-5.342,0-10.683,0m.021-13.467c2.047,0,4.093-.01,6.14.007.485,0,.678-.148.655-.653-.036-.777,0-1.557-.012-2.335a6.808,6.808,0,0,0-5.791-6.522,6.656,6.656,0,0,0-7.506,4.957,26.218,26.218,0,0,0-.282,3.838c-.045.567.182.724.724.718,2.024-.024,4.048-.01,6.072-.01m-.006,10.773c.311,0,.622,0,.932,0,.813-.006.928-.146.749-.954-.209-.954-.456-1.9-.628-2.86a1.047,1.047,0,0,1,.225-.784,1.965,1.965,0,0,0,.075-2.756,1.917,1.917,0,0,0-2.7-.006,1.955,1.955,0,0,0,.062,2.755,1.048,1.048,0,0,1,.23.782c-.17.96-.415,1.907-.624,2.861-.179.814-.068.955.74.962.311,0,.622,0,.932,0"
          transform="translate(497.515 303.773)"
        />
      </g>
    </svg>
  );
};

export default Security;
