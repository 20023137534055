import React from "react";

const Pdf = (props) => {
  const { height = 50, width = 50 } = props;
  const className = "fill-primaryBlue";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 111 111"
      className={className}
    >
      <g
        id="Group_42561"
        data-name="Group 42561"
        transform="translate(-7012 -2484)"
      >
        <rect
          id="Rectangle_17764"
          data-name="Rectangle 17764"
          width="111"
          height="111"
          rx="10"
          transform="translate(7012 2484)"
        />
        <g
          id="Group_42560"
          data-name="Group 42560"
          transform="translate(-181.856 73)"
        >
          <path
            id="Path_37841"
            data-name="Path 37841"
            d="M95.906,47.962c-.378-.027-.7-.025-1.008-.075a2.354,2.354,0,0,1-2-2.315q-.015-7.429,0-14.857a2.384,2.384,0,0,1,2.386-2.373c.193,0,.384,0,.624,0v-.5q0-10.832,0-21.665A6.009,6.009,0,0,1,102.082.006Q113.19,0,124.3,0a.9.9,0,0,1,.7.29q6.873,6.866,13.764,13.716a.814.814,0,0,1,.259.638q-.008,17.931-.005,35.863a6,6,0,0,1-6.15,6.155q-15.425,0-30.85,0a6.008,6.008,0,0,1-6.107-6.13c0-.851,0-1.7,0-2.57m39.351-32.228H134.8l-7.1.015a4.158,4.158,0,0,1-4.378-4.355q0-3.64,0-7.281V3.665h-.495l-20.71.016a2.366,2.366,0,0,0-2.534,2.542q0,10.848,0,21.7v.42h28.768a2.416,2.416,0,0,1,2.618,2.624q0,7.171,0,14.343a3.381,3.381,0,0,1-.056.727,2.417,2.417,0,0,1-2.486,1.9H99.586c0,.914-.01,1.766,0,2.618a2.355,2.355,0,0,0,2.491,2.438q15.386,0,30.771,0a2.363,2.363,0,0,0,2.488-2.515q-.04-16.393-.081-32.784c0-.633,0-1.265,0-1.957m-27.193,27.7a12.534,12.534,0,0,0,6.162-.414,4.4,4.4,0,0,0,2.54-2.715,7.5,7.5,0,0,0,.324-1.493,4.645,4.645,0,0,0-4.362-5.512,19.55,19.55,0,0,0-4.23.054c-.336.035-.443.134-.442.483.015,3.07.008,6.142.008,9.212Zm-6.515.059V39.9c.166,0,.287,0,.407,0a6.433,6.433,0,0,0,2.448-.318A3.2,3.2,0,0,0,106.59,36.1a2.968,2.968,0,0,0-2.58-2.729,14.752,14.752,0,0,0-4.367-.012c-.313.031-.4.132-.4.445.012,3.107.008,6.214.008,9.321,0,.119.011.237.018.371Zm23.32-10.184h-6.207V43.488h2.315v-4.1h3.64V37.51h-3.626V35.186h3.878Z"
            transform="translate(7132.969 2438)"
            fill="#fff"
          />
          <path
            id="Path_37842"
            data-name="Path 37842"
            d="M115.741,52.418V45.745c1.892-.421,3.434.254,3.928,1.51a4.323,4.323,0,0,1-.165,3.682,2.836,2.836,0,0,1-2.46,1.476c-.42.039-.846.006-1.3.006"
            transform="translate(7127.645 2427.372)"
            fill="#fff"
          />
          <path
            id="Path_37843"
            data-name="Path 37843"
            d="M104.183,48.69c0-.963,0-1.911.009-2.86,0-.064.134-.179.209-.181a7.3,7.3,0,0,1,1.346.017,1.33,1.33,0,0,1,1.182,1.253,1.525,1.525,0,0,1-.919,1.631,3.184,3.184,0,0,1-1.827.14"
            transform="translate(7130.338 2427.371)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};

export default Pdf;
