import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  Box,
  Button,
  Icon,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { getQuote } from "api/getQuote";
import clsx from "clsx";
import FilterBar from "components/header/FilterBar";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DatePickerStyleOverrides } from "settings/styleOverrides";
import { resetState } from "./store/trackerSlice";
import { useNavigate } from "react-router-dom";
import _ from "utils/lodash";
import Calendar from "components/svgs/icons/navbars/Calendar";
import CalendarWhite from "components/svgs/icons/general/CalendarWhite";
import Filter from "components/svgs/icons/general/Filter";
import Reporting from "components/svgs/icons/navbars/Reporting";
import Tracker from "components/svgs/icons/navbars/Tracker";

const TrackerBar = (props) => {
  const {
    setShowCal,
    showCal,
    selectedDate,
    handleDateChange,
    openFilterBarByDefault,
    toggleSettingsDrawer,
    showJumpto,
    redirectBtnTitle,
    searchByOnlyOneUser,
    user,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [quote, setQuote] = useState("");
  const [showFilterBar, setShowFilterBar] = useState(openFilterBarByDefault);
  const datepickerStyles = DatePickerStyleOverrides();

  useEffect(() => {
    const existingQuote = JSON.parse(localStorage.getItem("quote"));
    if (
      existingQuote &&
      new Date(existingQuote.date).setHours(0, 0, 0, 0) ===
        new Date().setHours(0, 0, 0, 0)
    ) {
      setQuote(existingQuote.content);
    } else {
      getQuote().then(({ body: { content } }) => {
        localStorage.setItem(
          "quote",
          JSON.stringify({ date: new Date().toISOString(), content })
        );
        setQuote(content);
      });
    }
    return () => {
      dispatch(resetState());
    };
  }, []);

  const redirectUrl = () => {
    const base = `/${user.data.currentWorkspace}/tracker`;
    redirectBtnTitle === "report"
      ? navigate(`${base}/report`)
      : navigate(`${base}`);
  };

  return (
    <div className={`px-2 py-4 mt-4 xs:mx-2 xmd:mx-4`}>
      <div className="flex flex-wrap gap-2 gap-y-6 pl-4 pr-2 sm:pl-4 sm:pr-4 py-4 md:py-7 bg-blueTransparent">
        <div className="flex w-2/3 sm:w-full items-center justify-start min-w-[140px] max-w-[700px]">
          <div className="flex justify-center items-center w-full p-[4px] rounded-[5px] bg-white border border-primaryBlue">
            <Typography className="text-primaryBlue md:text-lg">
              {quote}
            </Typography>
          </div>
        </div>

        {showJumpto && (
          <Button
            type="button"
            className={clsx(
              "rounded-full hover:bg-primaryBlueLight text-sm md:text-base px-1 !text-white min-w-[160px]",
              showCal && "bg-primaryBlue/50 hover:bg-primaryBlue",
              !showCal && "bg-primaryBlue hover:bg-primaryBlueLight"
            )}
            onClick={() => setShowCal(true)}
          >
            <Box className={`mr-2`}>
              <CalendarWhite />
            </Box>
            <span className="text-sm md:text-base">Jump to</span>
          </Button>
        )}

        {showJumpto && (
          <div className="invisible w-0">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Custom input"
                open={showCal}
                onClose={() => setShowCal(false)}
                value={selectedDate}
                onChange={handleDateChange}
                renderInput={({ inputRef, inputProps, InputProps }) => (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      ref={inputRef}
                      {...inputProps}
                      sx={datepickerStyles}
                    />
                    {InputProps?.endAdornment}
                  </Box>
                )}
              />
            </LocalizationProvider>
          </div>
        )}

        <Button
          onClick={() => setShowFilterBar(!showFilterBar)}
          type="button"
          className={clsx(
            "rounded-full text-sm md:text-base px-1 md:px-2 text-white min-w-[120px]",
            showFilterBar && "bg-primaryBlue/50 hover:bg-primaryBlue",
            !showFilterBar && "bg-primaryBlue hover:bg-primaryBlueLight"
          )}
        >
          <Box className="w-[16px] h-[16px] mr-2">
            <Filter />
          </Box>
          <span className="text-sm md:text-base">Filter</span>
        </Button>

        <Button
          onClick={() => redirectUrl()}
          type="button"
          className={
            "rounded-full text-sm md:text-base px-1 md:px-2 text-white min-w-[120px] bg-primaryBlue hover:bg-primaryBlueLight"
          }
        >
          <Box className="w-[16px] h-[16px] mr-2">
            {redirectBtnTitle === "report" ? (
              <Reporting width={16} height={16} className="fill-white" />
            ) : (
              <Tracker width={16} height={16} className="fill-white" />
            )}
          </Box>
          <span className="text-sm md:text-base">
            {_.capitalize(redirectBtnTitle)}
          </span>
        </Button>

        <IconButton
          className="text-primaryBlue ml-2 xs:px-0 xmd:px-2"
          onClick={() => toggleSettingsDrawer()}
          size="small"
        >
          <Icon className="xs:text-[0.85em] xmd:text-[1.2em]">settings</Icon>
        </IconButton>
      </div>

      {showFilterBar && (
        <FilterBar style="tracker" searchByOnlyOneUser={searchByOnlyOneUser} />
      )}
    </div>
  );
};

export default TrackerBar;
