import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { searchHandler } from "api/algoliaSearch";
import { subMonths } from "date-fns";
import firebase from "services/firebase";
import Utilities from "utils/utilities";
import { onApproval } from "../../../api/onApproval";

const STATUS = Utilities.getTrackerStatus();

const WEEKS = {
  Mon: 0,
  Tue: 1,
  Wed: 2,
  Thu: 3,
  Fri: 4,
  Sat: 5,
  Sun: 6,
};

export const getFirstDayOfWeek = (dec, d) => {
  let date;
  if (d) date = new Date(d);
  else date = new Date();
  const day = WEEKS[date.toLocaleDateString("default", { weekday: "short" })];
  const diff = date.getDate() - day - dec;

  return new Date(date.setDate(diff));
};

const getWeekArray = (dec) => {
  const i = getFirstDayOfWeek(dec);
  let weekArray = new Array(7)
    .fill("")
    .map((_, index) => new Date(i.getTime() + 86400000 * index));
  return weekArray.map((each) => ({
    Day: each.toLocaleDateString("default", { weekday: "short" }),
    Date: {
      month: each.toLocaleString("default", {
        month: "short",
      }),
      date: each.getDate(),
    },
  }));
};

const getWeekNumber = (d) => {
  let date = new Date(d);
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  // January 4 is always in week 1.
  const week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return (
    1 +
    Math.round(
      ((date.getTime() - week1.getTime()) / 86400000 -
        3 +
        ((week1.getDay() + 6) % 7)) /
        7
    )
  );
};

export const saveTrackerRows = createAsyncThunk(
  "trackerApp/saveTrackerRows",
  async (
    { user, rows, currentYear, currentWeekNo, status, userId, onlySave },
    { dispatch }
  ) => {
    const data = rows.map(({ projectName, cardName, taskName, ...row }) => row);
    await firebase.setTracker(
      userId,
      user,
      { rows: data, status },
      currentYear,
      currentWeekNo
    );
    if (onlySave) {
      const message = " has saved tracker entries ";
      Utilities.successNotification(user, message, null, dispatch);
    }
  }
);

export const updateTrackerPendingStatus = createAsyncThunk(
  "trackerApp/updateTrackerPendingStatus",
  async ({ user, userId, currentYear, currentWeekNo }) => {
    await firebase.removeFromPending(user, userId, {
      year: currentYear,
      weekNo: currentWeekNo,
    });
  }
);

export const resubmitTrackerWeek = createAsyncThunk(
  "trackerApp/resubmitTrackerWeek",
  async ({ user, userId, rows, currentYear, currentWeekNo }, { dispatch }) => {
    const data = rows.map(({ projectName, cardName, taskName, ...row }) => row);
    await firebase.setTracker(
      userId,
      user,
      { rows: data, status: STATUS.INITIAL },
      currentYear,
      currentWeekNo
    );
    dispatch(setStatus(STATUS.INITIAL));
    dispatch(
      updateTrackerPendingStatus({ user, userId, currentYear, currentWeekNo })
    );
  }
);

export const deleteTrackerRow = createAsyncThunk(
  "trackerApp/deleteTrackerRow",
  async (
    { user, rows, currentYear, currentWeekNo, status, userId, rowNo },
    { dispatch }
  ) => {
    const data = [...rows];
    data.splice(rowNo, 1);
    await firebase.setTracker(
      userId,
      user,
      { rows: data, status },
      currentYear,
      currentWeekNo
    );
    const message = " has deleted a tracker entry ";
    Utilities.successNotification(user, message, null, dispatch);
    dispatch(deleteRow(rowNo));
  }
);

export const submitTrackerWeek = createAsyncThunk(
  "trackerApp/submitTrackerWeek",
  async (
    {
      requiresTimeApproval,
      user,
      rows,
      currentYear,
      currentWeekNo,
      userId,
      dec,
      currentWeek,
    },
    { dispatch }
  ) => {
    try {
      const data = rows.map(
        ({ projectName, cardName, taskName, ...row }) => row
      );
      if (user.role.includes("owner") || !requiresTimeApproval) {
        await firebase.setTracker(
          userId,
          user,
          { rows: data, status: "approved" },
          currentYear,
          currentWeekNo
        );
        dispatch(setStatus("approved"));
      } else {
        await firebase.setTracker(
          userId,
          user,
          { rows: data, status: STATUS.PENDING },
          currentYear,
          currentWeekNo
        );
        await firebase.addInPending(user, userId, {
          year: currentYear,
          weekNo: currentWeekNo,
        });
        const ownerEmail = await firebase.getOwnerEmail(user);
        await onApproval({
          workspaceId: user.data.currentWorkspace,
          userId: user.data.id,
          displayName: user.data.displayName,
          currentWeek: currentWeek,
          weekNo: currentWeekNo,
          year: currentYear,
          email: ownerEmail,
          dec,
        });
        dispatch(setStatus(STATUS.PENDING));
      }
      dispatch(setReason(""));
    } catch (error) {
      Utilities.errorNotification(user, dispatch);
    }
  }
);

export const getUserTrackerDataForReport = createAsyncThunk(
  "trackerApp/getUserTrackerDataForReport",
  async ({ userId, user, currentYear, status }) => {
    return await firebase.getUserTrackerDataForReport(
      userId,
      user,
      currentYear,
      status
    );
  }
);

export const fetchRawData = createAsyncThunk(
  "trackerApp/fetchRawData",
  async ({ workspaceId }, { dispatch }) => {
    // For now - we do not have much data or clients in PROD so its okay to fetch all data at once.
    const scrumboardBody = {
      filters: "objectType:scrumboard",
      query: "",
      workspaceId,
      attributesToRetrieve: ["name", "cards"],
    };

    const projectBody = {
      filters: "objectType:project",
      query: "",
      workspaceId,
      attributesToRetrieve: ["name"],
    };

    const taskBody = {
      filters: "objectType:task",
      query: "",
      workspaceId,
      attributesToRetrieve: ["title"],
    };

    const pRes = await searchHandler(projectBody);
    const sRes = await searchHandler(scrumboardBody);
    const tRes = await searchHandler(taskBody);

    dispatch(setData({ type: "projects", data: pRes?.body?.hits || [] }));
    dispatch(setData({ type: "tasks", data: tRes?.body?.hits || [] }));
    dispatch(setData({ type: "scrumboards", data: sRes?.body?.hits || [] }));
  }
);

export const getTrackerData = createAsyncThunk(
  "trackerApp/getTrackerData",
  async ({ userId, user, currentYear, currentWeekNo }) => {
    const trackerState = await firebase.getTrackerData(
      userId,
      user,
      currentYear,
      currentWeekNo
    );
    if (!trackerState) return;
    const { rows, status, reason } = trackerState;
    if (rows?.length === 0) return { ...trackerState, user };

    // TODO: Will it be better to maintain a mapping of it in a collection as an array?
    const projectIds = rows?.map((x) => x.projectId);
    const taskIds = rows?.map((x) => x.taskId);

    const facetFilters = [];
    projectIds.forEach((id) => facetFilters.push(`id:${id}`));

    const body = {
      filters: `objectType:project`,
      facetFilters: facetFilters,
      hits_per_page: projectIds.length,
      query: "",
      workspaceId: user?.data?.currentWorkspace,
      attributesToRetrieve: ["name"],
    };
    const response = await searchHandler(body);
    const mergingProjectNames = rows.map((row) => ({
      ...row,
      projectName: response?.body?.hits?.find(
        (x) => x.objectID === row.projectId
      )?.name,
    }));

    const fetchTaskData = await firebase.getTasksByIds(user, userId, taskIds);
    const finalMerge = mergingProjectNames.map((row) => ({
      ...row,
      taskName: fetchTaskData?.find((x) => x.id === row.taskId)?.title,
    }));

    return { rows: finalMerge, status, user, reason };
  }
);

const params = new URLSearchParams(window.location.search);
const trackerSlice = createSlice({
  name: "trackerApp",
  initialState: {
    currentYear: params.get("year") || getFirstDayOfWeek(0).getFullYear(),
    currentWeekNo: params.get("weekNo") || getWeekNumber(getFirstDayOfWeek(0)),
    currentWeek: params.get("dec")
      ? getWeekArray(params.get("dec"))
      : getWeekArray(0),
    rows: [],
    status: STATUS.INITIAL,
    reason: "",
    dec: Number(params.get("dec")) || 0,
    loading: false,
    filter: [],
    userReport: [],
    rawData: {},
    openTrackerAsDialog: false,
    firstLoad: false,
    startDate: subMonths(new Date(), 1),
    endDate: new Date(),
  },
  reducers: {
    addNewRow(state, { payload: { cardInfo, projectInfo, taskInfo } }) {
      state.rows.push({
        cardId: cardInfo.id,
        projectId: projectInfo.id,
        taskId: taskInfo.id,
        cardName: cardInfo.name,
        projectName: projectInfo.name,
        taskName: taskInfo.name,
        cells: new Array(7).fill(""),
        modifiedDate: new Date(),
      });
    },
    deleteRow(state, { payload }) {
      state.rows = state.rows.filter((_, index) => index !== payload);
    },
    setHours(state, { payload: { value, rowNo, cellNo } }) {
      state.rows[rowNo].cells[cellNo] = value;
    },
    setStatus(state, { payload }) {
      state.status = payload;
    },
    setReason(state, { payload }) {
      state.reason = payload; // This is the reason field in db
    },
    goBack(state, { payload }) {
      state.currentWeek = getWeekArray(payload);
      state.currentYear = getFirstDayOfWeek(payload).getFullYear();
      state.currentWeekNo = getWeekNumber(getFirstDayOfWeek(payload));
    },
    setDec(state, { payload }) {
      state.dec = payload;
    },
    setLoading(state, { payload }) {
      state.loading = payload;
    },
    resetState(state) {
      state.currentYear = getFirstDayOfWeek(0).getFullYear();
      state.currentWeekNo = getWeekNumber(getFirstDayOfWeek(0));
      state.currentWeek = getWeekArray(0);
      state.rows = [];
      state.status = STATUS.INITIAL;
      state.reason = "";
      state.filter = [];
      state.dec = 0;
      state.startDate = subMonths(new Date(), 1);
      state.endDate = new Date();
      state.userReport = [];
      state.rawData = {};
    },
    setTrackerFilter: (state, action) => {
      state.filter = action.payload;
    },
    resetTrackerFilter: (state, action) => {
      state.filter = [];
    },
    setOpenTrackerAsDialog: (state, action) => {
      state.openTrackerAsDialog = action.payload;
      state.firstLoad = action.payload;
    },
    setData(state, { payload }) {
      state.rawData[payload.type] = payload.data;
    },
    setRange: (state, { payload }) => {
      state.startDate = payload.startDate;
      state.endDate = payload.endDate;
    },
  },
  extraReducers: {
    [getTrackerData.fulfilled]: (state, { payload }) => {
      const rows = payload?.rows || [];
      if (state.firstLoad) {
        const findIfExists = rows.filter(
          (x) =>
            x.projectId === state.rows[0].projectId &&
            x.taskId === state.rows[0].taskId &&
            x.cardId === state.rows[0].cardId
        );
        if (findIfExists.length > 0) state.rows = [];
        const concatRows = state.rows.concat(rows);
        state.rows = [...new Set(concatRows)];
        state.firstLoad = false;
      } else {
        state.rows = rows;
      }
      state.reason = payload?.reason || "";
      if (
        payload?.status === STATUS.PENDING &&
        payload?.user.role.includes("owner")
      )
        payload.status = STATUS.CHECKING;
      state.status = payload?.status || STATUS.INITIAL;
    },
    [getUserTrackerDataForReport.fulfilled]: (state, { payload }) => {
      state.userReport = payload;
    },
  },
});

export const {
  setTrackerFilter,
  resetTrackerFilter,
  addNewRow,
  setHours,
  setStatus,
  setReason,
  goBack,
  setDec,
  resetState,
  deleteRow,
  setLoading,
  setOpenTrackerAsDialog,
  setData,
  setRange,
} = trackerSlice.actions;

export default trackerSlice.reducer;
