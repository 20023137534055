import { yupResolver } from "@hookform/resolvers/yup";
import { FormControlLabel, Switch, TextField } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ColorPicker from "components/pickers/ColorPicker";
import { motion } from "framer-motion";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { BasicSwitchStyleOverrides } from "settings/styleOverrides";
import _ from "utils/lodash";
import Utilities from "utils/utilities";
import * as yup from "yup";
import { updateGeneralSettings } from "../store/settingsSlice";

const schema = yup.object().shape({
  displayName: yup
    .string()
    .required("You must enter your display name.")
    .max(25, "You cannot have more than 25 characters"),
  themeColor: yup.string(),
  darkMode: yup.boolean(),
  chatSidebar: yup.boolean(),
});

const UserSettings = (props) => {
  const { user, classes, item, mdDown, setConfirmDialog } = props;
  const dispatch = useDispatch();

  const defaultValues = {
    displayName: user.data?.displayName,
    themeColor: user.data?.settings?.themeColor || "#00B9D9",
    darkMode: user.data?.settings?.darkMode,
    chatSidebar: user.data?.settings?.chatSidebar,
  };

  const { control, formState, handleSubmit, reset, watch } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;
  const userForm = watch();

  const onSubmit = (model) => {
    if (!isValid) return;

    dispatch(
      updateGeneralSettings({
        user: user,
        newDisplayName: model.displayName,
        themeColor: model.themeColor,
        darkMode: model.darkMode,
        chatSidebar: model.chatSidebar,
      })
    ).then(() => {
      reset({
        displayName: model.displayName,
        themeColor: model.themeColor,
        darkMode: model.darkMode,
        chatSidebar: model.chatSidebar,
      });
    });
  };

  const handleColorChange = (onChange, value) => {
    onChange(value);
    Utilities.setThemeColor(value);
  };

  return (
    <form
      name="userForm"
      noValidate
      className={`flex flex-col flex-1 ${mdDown ? "w-full" : "ml-6"}  my-6`}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Card
        component={motion.div}
        variants={item}
        className="w-full rounded-16 shadow"
      >
        <AppBar position="static" elevation={0} className="bg-primaryBlue">
          <Toolbar className="px-6">
            <Typography
              variant="subtitle1"
              color="inherit"
              className="flex-1 text-lg font-medium text-white"
            >
              User Settings
            </Typography>
          </Toolbar>
        </AppBar>

        <CardContent>
          <div className="mb-6">
            <Typography className="font-semibold mb-4 text-15">
              Display Name
            </Typography>

            <Controller
              name="displayName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  autoComplete="off"
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                  name="displayName"
                  fullWidth
                  required
                  error={!!errors.displayName}
                  helperText={errors?.displayName?.message}
                  InputProps={{
                    className: classes.input,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.label,
                  }}
                />
              )}
            />
          </div>
          <div className="flex justify-between mb-6">
            <div className="flex flex-col items-center mb-2">
              <Typography className="font-semibold mb-4 text-15">
                Theme
              </Typography>
              <Controller
                name="themeColor"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <ColorPicker
                    color={value}
                    handleColorChange={(e, code) =>
                      handleColorChange(onChange, code)
                    }
                    modalParent={false}
                    className="border-1"
                  />
                )}
              />
            </div>
            <div className="flex flex-col items-center mb-2">
              <Typography className="font-semibold mb-4 text-15">
                Dark Mode
              </Typography>
              <Controller
                name="darkMode"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    label=""
                    className="xs:ml-4 md:ml-8"
                    sx={BasicSwitchStyleOverrides(userForm.themeColor)}
                    control={<Switch onChange={onChange} checked={value} />}
                  />
                )}
              />
            </div>
            <div className="flex flex-col items-center mb-2">
              <Typography className="font-semibold mb-4 text-15">
                Chat Sidebar
              </Typography>
              <Controller
                name="chatSidebar"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    label=""
                    className="xs:ml-4 md:ml-8"
                    sx={BasicSwitchStyleOverrides(userForm.themeColor)}
                    control={<Switch onChange={onChange} checked={value} />}
                  />
                )}
              />
            </div>
          </div>
        </CardContent>
      </Card>
      <div className="flex w-full justify-between my-[24px]">
        <div className="w-full">
          {user.role.includes("member") && (
            <Button
              onClick={() => setConfirmDialog(true)}
              className="bg-red-500 hover:bg-red-500/70 rounded text-white xs:text-sm xmd:text-base xs:min-w-[80px] xmd:min-w-[110px] max-h-[40px]"
            >
              Leave Workspace
            </Button>
          )}
        </div>

        <div className="flex">
          <Button
            onClick={() => reset(defaultValues)}
            className="bg-lightGray rounded hover:bg-lightGray/70 text-white xs:text-sm xmd:text-base xs:min-w-[80px] xmd:min-w-[110px] max-h-[40px] mr-4"
          >
            Cancel
          </Button>
          <Button
            className={`${
              _.isEmpty(dirtyFields) || !isValid
                ? "bg-blueTransparent"
                : "bg-primaryBlue"
            } max-h-[40px] xs:text-sm xmd:text-base text-white rounded hover:bg-primaryBlueLight xs:min-w-[80px] xmd:min-w-[110px]`}
            type="submit"
            disabled={_.isEmpty(dirtyFields) || !isValid}
          >
            Save
          </Button>
        </div>
      </div>
    </form>
  );
};

export default UserSettings;
