import { Paper, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Billing from "components/svgs/icons/general/Billing";
import AppCenter from "components/svgs/icons/settings/AppCenter";
import General from "components/svgs/icons/settings/General";
import Security from "components/svgs/icons/settings/Security";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Root, useStyledFormInputStyles } from "settings/styleOverrides";
import withReducer from "store/withReducer";
import reducer from "./store";
import { getWorkspaceById } from "./store/settingsSlice";
import AppCenterTab from "./tabs/AppCenterTab";
import GeneralTab from "./tabs/GeneralTab";
import PlanTab from "./tabs/PlanTab";
import SecurityTab from "./tabs/SecurityTab";

const SettingsApp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyledFormInputStyles();
  const [tabNumber, setTabNumber] = useState(0);
  const user = useSelector(({ auth }) => auth.user);
  const isNativeApp = useSelector(
    ({ entities }) => entities.settings.isNativeApp
  );

  const theme = useTheme();
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  useEffect(() => {
    if (!user?.data?.currentWorkspace) return;
    dispatch(getWorkspaceById(user));
  }, [user?.data?.currentWorkspace]);

  return (
    <Root
      content={
        <div
          className={`flex w-full  justify-center ${
            lgDown
              ? "mt-8"
              : `items-center ${[3, 4].includes(tabNumber) ? "" : "h-full"}` // TODO: Hack to get around ui issue
          }`}
        >
          {tabNumber === 0 && (
            <div className="md:flex xs:grid xs:grid-cols-2 xmd:grid-cols-3 xs:gap-6 md:flex-nowrap xs:mx-4 md:mx-0">
              <Paper
                onClick={() => setTabNumber(1)}
                className="flex flex-col justify-center items-center xs:px-4 xmd:px-6 py-10 xs:min-w-[10em] miniTab:min-w-[12em] xs:max-w-[12em] xmd:max-w-[15em] cursor-pointer shadow-md shadow-blueTransparent"
                elevation={2}
              >
                <General />
                <Typography className="mt-4 xs:text-base miniTab:text-lg md:text-xl font-medium text-grayText">
                  General
                </Typography>
              </Paper>
              <Paper
                onClick={() => setTabNumber(2)}
                className="flex flex-col justify-center items-center xs:px-4 xmd:px-6 py-10 xs:min-w-[10em] miniTab:min-w-[12em] xs:max-w-[12em] xmd:max-w-[15em] cursor-pointer shadow-md shadow-blueTransparent"
                elevation={2}
              >
                <Security />

                <Typography className="mt-4 xs:text-base miniTab:text-lg md:text-xl font-medium text-grayText">
                  Security
                </Typography>
              </Paper>
              {user?.data?.owner && !isNativeApp && (
                <Paper
                  onClick={() => {
                    setTabNumber(3);
                    navigate(`/${user.data.currentWorkspace}/settings/plan`);
                  }}
                  className="flex flex-col justify-center items-center xs:px-4 xmd:px-2 miniTab:px-6 py-10 xs:min-w-[10em] miniTab:min-w-[12em] xs:max-w-[12em] xmd:max-w-[15em] cursor-pointer shadow-md shadow-blueTransparent"
                  elevation={2}
                >
                  <Billing />

                  <Typography className="mt-4 xs:text-base miniTab:text-lg md:text-xl font-medium text-grayText">
                    Plan & Billing
                  </Typography>
                </Paper>
              )}
              {/* {!isMobile && (
                <Paper
                  onClick={() => setTabNumber(4)}
                  className="flex flex-col justify-center items-center xs:px-4 xmd:px-6 py-10 xs:min-w-[10em] miniTab:min-w-[12em] xs:max-w-[12em] xmd:max-w-[15em] cursor-pointer shadow-md shadow-blueTransparent"
                  elevation={2}
                >
                  <AppCenter />

                  <Typography className="mt-4 xs:text-base miniTab:text-lg md:text-xl font-medium text-grayText">
                    App Center
                  </Typography>
                </Paper>
              )} */}
            </div>
          )}
          {tabNumber === 1 && (
            <GeneralTab
              user={user}
              classes={classes}
              goBack={() => setTabNumber(0)}
            />
          )}
          {tabNumber === 2 && (
            <SecurityTab
              user={user}
              classes={classes}
              goBack={() => setTabNumber(0)}
            />
          )}
          {tabNumber === 3 && <PlanTab />}
          {tabNumber === 4 && (
            <AppCenterTab
              user={user}
              dispatch={dispatch}
              goBack={() => setTabNumber(0)}
            />
          )}
        </div>
      }
    />
  );
};

export default withReducer("settingsApp", reducer)(SettingsApp);
