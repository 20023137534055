import React from "react";

const Edit = (props) => {
  const { height = 23, width = 23, className = "#5b5b5b" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 34 34.02"
      className={className}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_17951"
            data-name="Rectangle 17951"
            width="34"
            height="34.02"
            fill={className}
          />
        </clipPath>
      </defs>
      <g id="Group_43862" data-name="Group 43862" transform="translate(0 0)">
        <g
          id="Group_42345"
          data-name="Group 42345"
          transform="translate(0 0)"
          clipPath="url(#clipPath)"
        >
          <path
            id="Path_37781"
            data-name="Path 37781"
            d="M24.993,16.987q-1.012,1.032-2.035,2.054Q17.316,24.7,11.68,30.36A3.349,3.349,0,0,1,9.7,31.546a.292.292,0,0,0-.048.017c-1.495.435-2.989.867-4.481,1.308a.348.348,0,0,1-.4-.112,11.331,11.331,0,0,0-.8-.8.515.515,0,0,1-.167-.625c.557-1.852,1.094-3.71,1.645-5.564a.8.8,0,0,1,.2-.32q6.65-6.637,13.307-13.269c.3-.3.618-.592.908-.869L18.072,9.517a2.662,2.662,0,0,1-.2.252q-2.82,2.8-5.643,5.6-3.575,3.544-7.151,7.086c-.452.447-.9.9-1.38,1.317a1.673,1.673,0,0,0-.584.823c-.117.422-.244.842-.368,1.263Q1.411,30.372.075,34.885a1.29,1.29,0,0,0,.314,1.382,1.284,1.284,0,0,0,1.363.294c.617-.188,1.237-.364,1.855-.546q2.19-.646,4.378-1.292,2.1-.619,4.208-1.229a1.346,1.346,0,0,0,.843-.67,1.625,1.625,0,0,1,.273-.368q2.756-2.74,5.517-5.472,3.723-3.688,7.446-7.375c.322-.319.651-.631.958-.928l-2-2.019a2.571,2.571,0,0,1-.236.327"
            transform="translate(0 -2.619)"
            fill={className}
          />
          <path
            id="Path_37782"
            data-name="Path 37782"
            d="M11.118,28.95a.346.346,0,0,1,.339.357c.06.536.156,1.068.246,1.661L26.16,16.38,23.9,14.11,9.321,28.568c.621.134,1.206.273,1.8.382"
            transform="translate(-2.565 -3.883)"
            fill={className}
          />
          <path
            id="Path_37783"
            data-name="Path 37783"
            d="M26.892,6.742l9.118,9.12,2.027-1.869L28.881,4.832l-1.989,1.91"
            transform="translate(-7.401 -1.33)"
            fill={className}
          />
          <path
            id="Path_37784"
            data-name="Path 37784"
            d="M41.776,5.273Q39.992,3.482,38.2,1.7c-.022-.022-.041-.043-.059-.064-.151-.147-.541-.506-.71-.661L37.223.79A4.5,4.5,0,0,0,35.848.083a2.715,2.715,0,0,0-2.314.448,20.729,20.729,0,0,0-1.819,1.711L40.477,10.9c.423-.386.9-.778,1.321-1.213a3.058,3.058,0,0,0-.022-4.413"
            transform="translate(-8.728 0)"
            fill={className}
          />
        </g>
      </g>
    </svg>
  );
};

export default Edit;
