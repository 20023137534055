import { createSlice } from "@reduxjs/toolkit";
const dataSlice = createSlice({
  name: "quickPanel/data",
  initialState: {
    notes: [],
    events: [],
  },
  reducers: {
    setNotes(state, action) {
      state.notes = action.payload;
    },
    setEvents(state, action) {
      state.events = action.payload;
    },
  },
});
export const { setNotes, setEvents } = dataSlice.actions;
export default dataSlice.reducer;
