import QuickPanel from "../../components/quickPanel/QuickPanel";
import { memo, useEffect, useState } from "react";
import ChatPanel from "../chatPanel/ChatPanel";
import NotificationPanel from "../notificationPanel/NotificationPanel";
import { useSelector } from "react-redux";

const RightSideLayout1 = () => {
  const [showChatPanel, setShowHideChatPanel] = useState(false);
  const user = useSelector(({ auth }) => auth.user);

  useEffect(() => {
    if (user.data.settings)
      setShowHideChatPanel(user.data.settings.chatSidebar);
  }, [user && user.data && user.data.settings]);

  return (
    <>
      {showChatPanel && <ChatPanel />}
      <QuickPanel />
      <NotificationPanel />
    </>
  );
};

export default memo(RightSideLayout1);
