const Calendar = (props) => {
  const { height = 24, width = 24, color = "#6e6e6e" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 27 27"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_17624"
            data-name="Rectangle 17624"
            width="27"
            height="27"
            transform="translate(-0.445 -0.444)"
            fill={color}
          />
        </clipPath>
      </defs>
      <g
        id="Group_40605"
        data-name="Group 40605"
        transform="translate(1.009 0.829)"
      >
        <g
          id="Group_40604"
          data-name="Group 40604"
          transform="translate(-0.564 -0.385)"
          clipPath="url(clipPath)"
        >
          <path
            id="Path_34363"
            data-name="Path 34363"
            d="M25.342,4.092v19.6a2.894,2.894,0,0,1-2.027,2.027H2.027A2.888,2.888,0,0,1,0,23.69V4.092C.591,2.079,1.743,1.88,3.349,2.151v1.6c-.325,0-.624.025-.916,0a.592.592,0,0,0-.727.644C1.677,5.286,1.7,6.182,1.7,7.1H23.657c0-.17,0-.315,0-.459,0-.75.013-1.5-.018-2.251-.017-.423-.224-.7-.721-.647A8.828,8.828,0,0,1,22,3.754V2.065c.382,0,.734,0,1.086,0a2.072,2.072,0,0,1,2.035,1.4c.083.207.148.421.222.632M1.691,8.845V22.9c0,.9.229,1.131,1.12,1.131h19.7c.924,0,1.144-.223,1.144-1.163q0-6.726,0-13.453c0-.182-.016-.365-.025-.567Z"
            transform="translate(0.601 0.098)"
            fill={color}
          />
          <path
            id="Path_34364"
            data-name="Path 34364"
            d="M7.324,0A1.111,1.111,0,0,1,7.9,1.115c-.028.974,0,1.948-.009,2.923,0,.665-.307,1.029-.843,1.03S6.2,4.7,6.2,4.04c0-.974.019-1.95-.01-2.923A1.121,1.121,0,0,1,6.761,0Z"
            transform="translate(-0.528 0.473)"
            fill={color}
          />
          <path
            id="Path_34365"
            data-name="Path 34365"
            d="M23.857,0a1.115,1.115,0,0,1,.571,1.118c-.026.974,0,1.948-.009,2.923,0,.666-.307,1.029-.844,1.028s-.841-.368-.844-1.029c0-.974.02-1.95-.01-2.923A1.115,1.115,0,0,1,23.294,0Z"
            transform="translate(-3.545 0.473)"
            fill={color}
          />
          <rect
            id="Rectangle_17601"
            data-name="Rectangle 17601"
            width="8.378"
            height="1.624"
            transform="translate(9.075 2.19)"
            fill={color}
          />
          <rect
            id="Rectangle_17602"
            data-name="Rectangle 17602"
            width="1.62"
            height="1.62"
            transform="translate(7.384 10.64)"
            fill={color}
          />
          <rect
            id="Rectangle_17603"
            data-name="Rectangle 17603"
            width="1.62"
            height="1.626"
            transform="translate(10.766 10.637)"
            fill={color}
          />
          <rect
            id="Rectangle_17604"
            data-name="Rectangle 17604"
            width="1.62"
            height="1.624"
            transform="translate(14.16 10.647)"
            fill={color}
          />
          <rect
            id="Rectangle_17605"
            data-name="Rectangle 17605"
            width="1.64"
            height="1.61"
            transform="translate(17.536 10.645)"
            fill={color}
          />
          <rect
            id="Rectangle_17606"
            data-name="Rectangle 17606"
            width="1.616"
            height="1.627"
            transform="translate(20.907 10.637)"
            fill={color}
          />
          <rect
            id="Rectangle_17607"
            data-name="Rectangle 17607"
            width="1.627"
            height="1.617"
            transform="translate(4.007 14.03)"
            fill={color}
          />
          <rect
            id="Rectangle_17608"
            data-name="Rectangle 17608"
            width="1.613"
            height="1.64"
            transform="translate(7.392 14)"
            fill={color}
          />
          <rect
            id="Rectangle_17609"
            data-name="Rectangle 17609"
            width="1.619"
            height="1.629"
            transform="translate(10.778 14.024)"
            fill={color}
          />
          <rect
            id="Rectangle_17610"
            data-name="Rectangle 17610"
            width="1.637"
            height="1.607"
            transform="translate(14.149 14.035)"
            fill={color}
          />
          <rect
            id="Rectangle_17611"
            data-name="Rectangle 17611"
            width="1.62"
            height="1.62"
            transform="translate(17.526 14.033)"
            fill={color}
          />
          <rect
            id="Rectangle_17612"
            data-name="Rectangle 17612"
            width="1.62"
            height="1.617"
            transform="translate(20.899 14.02)"
            fill={color}
          />
          <rect
            id="Rectangle_17613"
            data-name="Rectangle 17613"
            width="1.626"
            height="1.62"
            transform="translate(4.007 17.409)"
            fill={color}
          />
          <rect
            id="Rectangle_17614"
            data-name="Rectangle 17614"
            width="1.637"
            height="1.607"
            transform="translate(7.381 17.404)"
            fill={color}
          />
          <rect
            id="Rectangle_17615"
            data-name="Rectangle 17615"
            width="1.62"
            height="1.63"
            transform="translate(10.777 17.402)"
            fill={color}
          />
          <rect
            id="Rectangle_17616"
            data-name="Rectangle 17616"
            width="1.626"
            height="1.62"
            transform="translate(14.144 17.409)"
            fill={color}
          />
          <rect
            id="Rectangle_17617"
            data-name="Rectangle 17617"
            width="1.627"
            height="1.616"
            transform="translate(17.522 17.409)"
            fill={color}
          />
          <rect
            id="Rectangle_17618"
            data-name="Rectangle 17618"
            width="1.616"
            height="1.626"
            transform="translate(20.907 17.394)"
            fill={color}
          />
          <rect
            id="Rectangle_17619"
            data-name="Rectangle 17619"
            width="1.607"
            height="1.637"
            transform="translate(4.026 20.768)"
            fill={color}
          />
          <rect
            id="Rectangle_17620"
            data-name="Rectangle 17620"
            width="1.614"
            height="1.64"
            transform="translate(7.402 20.769)"
            fill={color}
          />
          <rect
            id="Rectangle_17621"
            data-name="Rectangle 17621"
            width="1.6"
            height="1.64"
            transform="translate(10.785 20.759)"
            fill={color}
          />
          <rect
            id="Rectangle_17622"
            data-name="Rectangle 17622"
            width="1.633"
            height="1.624"
            transform="translate(14.141 20.785)"
            fill={color}
          />
          <rect
            id="Rectangle_17623"
            data-name="Rectangle 17623"
            width="1.64"
            height="1.61"
            transform="translate(17.526 20.791)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

export default Calendar;
