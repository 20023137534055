import Avatar from "@mui/material/Avatar";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import format from "date-fns/format";
import { useState } from "react";
import StatusIcon from "../StatusIcon";
import { useSelector } from "react-redux";
import FilledHeart from "components/svgs/icons/general/FilledHeart";
import FeatherHeart from "components/svgs/icons/general/FeatherHeart";
import { Box } from "@mui/system";

const StyledListItem = styled(ListItem)(({ theme, active }) => ({
  ...(active && {
    backgroundColor: theme.palette.background.paper,
  }),
}));

const ContactListItem = (props) => {
  const {
    user,
    contact,
    selectedContactId,
    listType,
    lastMessage,
    onContactClick,
    toggleFavouriteChat,
  } = props;
  const [loading, setImgLoading] = useState(contact?.photoURL !== "");
  const darkMode = useSelector(
    ({ entities }) => entities.settings.current.darkMode
  );

  return (
    <StyledListItem
      style={{ borderBottom: "1px solid #DCDCDC" }}
      button
      className="min-h-92 py-4 border-b-10"
      active={selectedContactId === contact.id ? 1 : 0}
      onClick={() => onContactClick(contact)}
    >
      <div className="relative">
        <div className="absolute right-3 bottom-2 -m-4 z-10">
          <StatusIcon status={contact.status} needBg />
        </div>

        {loading && (
          <Skeleton variant="circular">
            <Avatar
              className="h-[42px] w-[42px]"
              src={contact.photoURL}
              imgProps={{ onLoad: () => setImgLoading(false) }}
              alt="avatar"
            ></Avatar>
          </Skeleton>
        )}
        {!loading && (
          <Avatar
            className="h-[42px] w-[42px]"
            src={contact.photoURL}
            alt={contact.displayName}
            style={{ backgroundColor: contact?.more?.color }}
          >
            {contact?.displayName &&
              contact?.displayName[0]?.toLocaleUpperCase()}
          </Avatar>
        )}
      </div>

      <ListItemText
        classes={{
          root: "min-w-px px-4",
          primary: `font-medium text-[16px] ${
            contact.unread && contact.unread !== 0
              ? "text-primaryBlue"
              : darkMode
              ? "text-white"
              : ""
          }`,
          secondary: `truncate max-w-[260px] ${
            contact.unread && contact.unread !== 0
              ? "text-primaryBlue"
              : darkMode
              ? "text-mute"
              : ""
          }`,
        }}
        primary={contact.displayName}
        secondary={contact.mood}
      />

      {
        <div className="flex flex-col justify-center items-end">
          {listType === "recents" && lastMessage && (
            <Typography
              className={`whitespace-nowrap mb-6 font-medium text-[12px] ${
                contact.unread !== 0 ? "text-primaryBlue" : ""
              }`}
              color="textSecondary"
            >
              {format(new Date(lastMessage), "PP")}
            </Typography>
          )}
          {listType === "groups" && (
            <Box
              onClick={(e) => toggleFavouriteChat(e, contact.id)}
              className="mr-2 cursor-pointer"
            >
              {user?.data?.starred.includes(contact.id) ? (
                <FilledHeart width={22} height={22} />
              ) : (
                <FeatherHeart width={22} height={22} />
              )}
            </Box>
          )}
          {listType === "favourites" && (
            <Box
              onClick={(e) => toggleFavouriteChat(e, contact.id)}
              className="mr-2 cursor-pointer"
            >
              <FeatherHeart width={22} height={22} />
            </Box>
          )}
          {/* {contact.unread !== 0 && (
            <Box
              sx={{
                backgroundColor: "secondary.main",
                color: "secondary.contrastText",
              }}
              className="flex items-center justify-center w-24 h-24 rounded-full font-medium text-12 text-center"
            >
              {contact.unread}
            </Box>
          )} */}
        </div>
      }
    </StyledListItem>
  );
};

export default ContactListItem;
