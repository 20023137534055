import {
  AppBar,
  Avatar,
  Icon,
  Input,
  Paper,
  Skeleton,
  Toolbar,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import StatusIcon from "components/StatusIcon";
import ChatContacts from "components/chatPanel/ChatContacts";
import StatusModal from "components/modals/StatusModal";
import Edit from "components/svgs/icons/general/Edit";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";

const ChatsSidebar = (props) => {
  const { closeLeftPanel, classes } = props;
  const user = useSelector(({ auth }) => auth.user);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const [searchText, setSearchText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setImgLoading] = useState(user?.data?.photoURL !== "");

  const handleSearchText = (event) => {
    setSearchText(event.target.value);
  };

  return (
    <Paper
      elevation={3}
      square
      className={`chatSideBarClass flex-col flex-auto h-full mx-4 sm:mx-1 sm:my-[32px] 2xl:my-[32px]`}
    >
      <AppBar
        position="static"
        style={{ background: "#0000", color: "inherit" }}
        elevation={0}
      >
        <Toolbar className="flex justify-between items-center px-4 ">
          {user && user.data && (
            <div
              className="flex justify-between my-4 relative p-0 w-full"
              tabIndex={0}
            >
              {loading && (
                <Skeleton variant="circular">
                  <Avatar
                    src={user.data.photoURL}
                    alt="avatar"
                    className="w-[42px] h-[42px]"
                    imgProps={{ onLoad: () => setImgLoading(false) }}
                  ></Avatar>
                </Skeleton>
              )}
              {!loading && (
                <>
                  <div className="flex">
                    <div className="relative">
                      <div className="absolute right-3 bottom-3 -m-4 z-10">
                        <StatusIcon status={user?.data?.status} needBg />
                      </div>
                      <Avatar
                        src={user?.data?.photoURL}
                        alt={user?.data?.displayName}
                        className="w-[42px] h-[42px]"
                        style={{ backgroundColor: user?.data?.more?.color }}
                      >
                        {user?.data?.displayName &&
                          user?.data?.displayName[0]?.toLocaleUpperCase()}
                      </Avatar>
                    </div>
                    <div className="flex justiy-between ml-4">
                      <div>
                        <Typography
                          className="font-semibold text-[14px] mt-1 xs:max-w-[130px] sm:min-w-[165px] text-primaryBlue"
                          variant="body1"
                        >
                          {user?.data?.displayName}
                        </Typography>
                        <Typography
                          className="font-base text-[12px] mt-1"
                          variant="body1"
                        >
                          {user?.data?.mood}
                        </Typography>
                      </div>
                    </div>
                  </div>

                  <div
                    onClick={() => setIsModalOpen(true)}
                    className="ml-2 mt-2 mr-2 cursor-pointer justify-self-end"
                  >
                    <Edit width={20} height={20} className="fill-primaryBlue" />
                  </div>
                </>
              )}
            </div>
          )}
        </Toolbar>
        {useMemo(
          () => (
            <Toolbar className="px-2 mx-4 min-h-[48px] border-b-2">
              <Icon className="text-primaryBlue">search</Icon>
              <Input
                placeholder="Search"
                className="flex flex-1 px-4"
                disableUnderline
                fullWidth
                value={searchText}
                inputProps={{
                  "aria-label": "Search",
                  className: classes.input + " !p-2",
                }}
                onChange={handleSearchText}
              />
            </Toolbar>
          ),
          [searchText]
        )}
      </AppBar>

      <ChatContacts
        isMobile={isMobile}
        searchText={searchText}
        closeLeftPanel={closeLeftPanel}
        sideChat={false}
      />

      <StatusModal
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        onClose={() => setIsModalOpen(false)}
        user={user}
      />
    </Paper>
  );
};

export default ChatsSidebar;
