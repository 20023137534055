import { authRoles } from "auth";
import { lazy } from "react";
import ProjectDetailApp from "./ProjectDetailApp";

const ProjectsApp = lazy(() => import("./ProjectsApp"));

const ProjectsAppConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.user,
  routes: [
    {
      path: "/:workspaceId/projects",
      element: <ProjectsApp />,
      title: "Projects",
    },
    {
      path: "/:workspaceId/project/add",
      element: <ProjectDetailApp add={true} />,
      title: "Add New Project",
    },
    {
      path: "/:workspaceId/project/:projectId",
      element: <ProjectDetailApp add={false} />,
      showMembers: true,
    },
  ],
};

export default ProjectsAppConfig;
