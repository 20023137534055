/* eslint import/no-extraneous-dependencies: off */
import { format } from "date-fns";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/firestore";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  list,
  ref as stoRef,
  uploadBytes,
} from "firebase/storage";
import Utilities from "utils/utilities";
import config from "../configs/firebase";

class FirebaseService {
  init(success) {
    if (Object.entries(config).length === 0 && config.constructor === Object) {
      if (process.env.NODE_ENV === "development") {
        console.warn(
          "Missing Firebase Configuration at src/services/firebase.js"
        );
      }
      success(false);
      return;
    }

    if (firebase.apps.length) {
      return;
    }
    try {
      const fireApp = firebase.initializeApp(config);
      this.db = firebase.firestore();
      this.rdb = firebase.database();
      this.auth = firebase.auth();
      this.storage = getStorage(fireApp);
    } catch (err) {
      if (!/already exists/.test(err.message)) {
        console.error("Firebase initialization error", err.stack);
      }
    }
    success(true);
  }

  getAuth = () => {
    return this.auth;
  };

  getOAuthProvider = (provider) => {
    let result;
    switch (provider) {
      case "google":
      case "google.com":
        result = new firebase.auth.GoogleAuthProvider();
        break;

      case "microsoft":
      case "microsoft.com":
        result = new firebase.auth.OAuthProvider("microsoft.com");
        break;
    }
    return result;
  };

  getPersistence = (rememberMe) => {
    return rememberMe
      ? firebase.auth.Auth.Persistence.LOCAL
      : firebase.auth.Auth.Persistence.SESSION;
  };

  onAuthStateChanged = (callback) => {
    if (!this.auth) {
      return;
    }
    this.auth.onAuthStateChanged(callback);
  };

  verifyPasswordResetCode = async (code) => {
    return this.auth.verifyPasswordResetCode(code);
  };

  confirmPasswordReset = async (code, password) => {
    return this.auth.confirmPasswordReset(code, password);
  };

  signOut = () => {
    if (!this.auth) {
      return;
    }
    this.auth.signOut();
  };

  getUserData = async (userId) => {
    if (!firebase.apps.length) {
      return false;
    }
    return await this.db
      .collection("users")
      .doc(userId)
      .get()
      .then((snapshot) => {
        return snapshot.data();
      });
  };

  getAccountDeletionDate = async (userId) => {
    return await this.db
      .collection("users")
      .doc(userId)
      .get()
      .then((snapshot) => {
        return snapshot.data()?.delete_account;
      });
  };

  updateUserData = (user, workspaces, displayName) => {
    return this.db
      .collection("users")
      .doc(user.uid)
      .update({ workspaces, displayName });
  };

  updateWorkspaceUserData = (user) => {
    if (!firebase.apps.length || !user.data.currentWorkspace) {
      return false;
    }
    const workspaceId = user.data.currentWorkspace;
    return this.db
      .collection("workspaces")
      .doc(workspaceId)
      .collection("users")
      .doc(user.uid)
      .set(user.data, { merge: true });
  };

  getCustomFields = async (workspaceId, type, project) => {
    let data;
    const doc = type === "board" ? project.objectID : type;
    return await this.db
      .collection("workspaces")
      .doc(workspaceId)
      .collection("customFields")
      .doc(doc)
      .get()
      .then((snapshot) => {
        data = snapshot.data();
        return data;
      });
  };

  addCustomFields = (workspaceId, type, data, projectId) => {
    const doc = type === "board" ? projectId : type;
    return this.db
      .collection("workspaces")
      .doc(workspaceId)
      .collection("customFields")
      .doc(doc)
      .set(data);
  };

  generateFirebaseDocId = (user, path) => {
    const workspaceId = user.data.currentWorkspace;
    const workspaceCollection = this.db
      .collection("workspaces")
      .doc(workspaceId);
    if (path == "projects" || path == "tasks" || path == "events") {
      return workspaceCollection.collection(path).doc().id;
    }
  };

  toggleScreenLock = async (user, locked) => {
    const workspaceId = user.data.currentWorkspace;
    return this.db
      .collection("workspaces")
      .doc(workspaceId)
      .collection("users")
      .doc(user.uid)
      .update({ isLocked: locked, modifiedDate: new Date() });
  };

  updateWorkspaceName = async (user, data) => {
    const workspaceId = user.data.currentWorkspace;
    await this.db.collection("workspaces").doc(workspaceId).update(data);
  };

  setBoards = async (user, projectId, data) => {
    const boardRef = this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("projects")
      .doc(projectId)
      .collection("scrumboards");
    const boardDoc = boardRef.doc();
    data.id = boardDoc.id; // Update the id to match firebae id
    data.createdDate = new Date();
    await boardRef.doc(boardDoc.id).set(data);
    return data.id;
  };

  getBoardLabels = async (user) => {
    let data = [];
    return await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("labels")
      .doc("scrumboard")
      .get()
      .then((snapshot) => {
        data = snapshot.data();
        return data?.labels || [];
      });
  };

  setBoardLabels = async (user, data) => {
    const labelsRef = this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("labels");
    return await labelsRef
      .doc("scrumboard")
      .set({ labels: data, modifiedDate: new Date() });
  };

  getBoards = async (user, projectId, showCompletedBoards) => {
    let ref = this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("projects")
      .doc(projectId)
      .collection("scrumboards");

    if (!showCompletedBoards) {
      ref = ref.where("settings.completed", "==", false);
    }

    const query = await ref.get();
    return Utilities.toArray(query);
  };

  toggleBoardFavourite = async (user, projectId, boardId, boardData) => {
    return await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("projects")
      .doc(projectId)
      .collection("scrumboards")
      .doc(boardId)
      .update(boardData);
  };

  getBacklogItemsById = async (workspaceId, backlogId) => {
    const doc = await this.db
      .collection("workspaces")
      .doc(workspaceId)
      .collection("backlog")
      .doc(backlogId)
      .get();
    return doc.data();
  };

  getBacklogItems = async (user, start, quantity, next) => {
    const query = next
      ? await this.db
          .collection("workspaces")
          .doc(user.data.currentWorkspace)
          .collection("backlog")
          .orderBy("createdDate", "desc")
          .startAfter(start)
          .limit(quantity)
          .get()
      : await this.db
          .collection("workspaces")
          .doc(user.data.currentWorkspace)
          .collection("backlog")
          .orderBy("createdDate", "desc")
          .endBefore(start)
          .limit(quantity)
          .get();

    return Utilities.toArrayOfObjects(query);
  };

  getBoardById = async (user, params) => {
    let data;
    return await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("projects")
      .doc(params.projectId)
      .collection("scrumboards")
      .doc(params.boardId)
      .get()
      .then((snapshot) => {
        data = snapshot.data();
        return data;
      });
  };

  deleteBoard = async (user, projectId, boardId) => {
    return await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("projects")
      .doc(projectId)
      .collection("scrumboards")
      .doc(boardId)
      .delete();
  };

  updateBoardName = async (
    user,
    projectId,
    boardId,
    boardName,
    uri,
    boardDescription
  ) => {
    await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("projects")
      .doc(projectId)
      .collection("scrumboards")
      .doc(boardId)
      .update({
        name: boardName,
        uri: uri,
        modifiedDate: new Date(),
        description: boardDescription,
      });
    return boardName;
  };

  editSections = async (user, projectId, boardId, sections) => {
    await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("projects")
      .doc(projectId)
      .collection("scrumboards")
      .doc(boardId)
      .update({ sections: sections, modifiedDate: new Date() });
    return sections;
  };

  updateBoardSettings = async (user, projectId, boardId, settings) => {
    await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("projects")
      .doc(projectId)
      .collection("scrumboards")
      .doc(boardId)
      .update({ settings: settings, modifiedDate: new Date() });
    return settings;
  };

  customizeBoardSettings = async (user, projectId, boardId, customize) => {
    await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("projects")
      .doc(projectId)
      .collection("scrumboards")
      .doc(boardId)
      .update({ customize: customize, modifiedDate: new Date() });
    return customize;
  };

  addCard = async (user, projectId, boardId, sections, cards) => {
    await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("projects")
      .doc(projectId)
      .collection("scrumboards")
      .doc(boardId)
      .update({ sections: sections, cards: cards, modifiedDate: new Date() });
  };

  moveToBacklog = async (user, board, projectId) => {
    try {
      const projectDetail = await this.db
        .collection("workspaces")
        .doc(user.data.currentWorkspace)
        .collection("projects")
        .doc(projectId)
        .get();
      const projectData = projectDetail.data();
      const backlogBoard = {
        projectName: projectData.name,
        projectId,
        ...board,
      };

      await this.db
        .collection("workspaces")
        .doc(user.data.currentWorkspace)
        .collection("backlog")
        .doc(board.id)
        .set(backlogBoard);

      await this.db
        .collection("workspaces")
        .doc(user.data.currentWorkspace)
        .collection("projects")
        .doc(projectId)
        .collection("scrumboards")
        .doc(board.id)
        .delete();
      return 1;
    } catch {
      return 0;
    }
  };

  moveToSprint = async (user, backlogBoard) => {
    let res;
    try {
      const { projectId, projectName, boardId, boardObj } = backlogBoard;
      await this.db
        .collection("workspaces")
        .doc(user.data.currentWorkspace)
        .collection("projects")
        .doc(projectId)
        .collection("scrumboards")
        .doc(boardId)
        .set(boardObj);

      await this.db
        .collection("workspaces")
        .doc(user.data.currentWorkspace)
        .collection("backlog")
        .doc(boardId)
        .delete();

      res = 1;
    } catch {
      res = 0;
    }
    return res;
  };

  uploadAttachment = async (path, image) => {
    const fileName = image?.name || Utilities.generateGUID();
    const uploadTask = stoRef(this.storage, `${path}/${fileName}`);

    await uploadBytes(uploadTask, image);
    const res = await getDownloadURL(uploadTask);
    return res;
  };

  createTopicForDiscussion = async (user, projectId, data) => {
    const newTopicId = this.rdb
      .ref(`${user.data.currentWorkspace}/projects/${projectId}/topics`)
      .push().key;
    const newData = { ...data, id: newTopicId, time: new Date() };

    await this.rdb
      .ref(
        `${user.data.currentWorkspace}/projects/${projectId}/topics/${newTopicId}`
      )
      .update(newData);

    return newData;
  };

  deleteTopicForDiscussion = async (user, projectId, topicId) => {
    return await this.rdb
      .ref(
        `${user.data.currentWorkspace}/projects/${projectId}/topics/${topicId}`
      )
      .remove();
  };

  getDiscussionsTopic = async (user, projectId) => {
    const query = await this.rdb
      .ref(`${user.data.currentWorkspace}/projects/${projectId}/topics`)
      .get();
    return query.exists() ? query.val() : [];
  };

  getDiscussionsTopicById = async (user, projectId, topicId) => {
    const query = await this.rdb
      .ref(
        `${user.data.currentWorkspace}/projects/${projectId}/topics/${topicId}`
      )
      .get();
    return query.exists() ? query.val() : [];
  };

  getDiscussionsMediaData = async (user, projectId, topicId) => {
    const query = await this.rdb
      .ref(
        `${user.data.currentWorkspace}/projects/${projectId}/uploads/${topicId}`
      )
      .get();
    return query.exists() ? query.val() : [];
  };

  updateTopicForDiscussion = async (user, projectId, data) => {
    return await this.rdb
      .ref(
        `${user.data.currentWorkspace}/projects/${projectId}/topics/${data.id}`
      )
      .update(data);
  };

  getDiscussionListenerAdded = (user, projectId, topicId) => {
    return this.rdb
      .ref(
        `${user.data.currentWorkspace}/projects/${projectId}/discussions/${topicId}`
      )
      .limitToLast(1);
  };

  detachDiscussionListenerAdded = (user, projectId, topicId) => {
    this.rdb
      .ref(
        `${user.data.currentWorkspace}/projects/${projectId}/discussions/${topicId}`
      )
      .off("child_added");
  };

  getFirstDiscussion = (user, projectId, topicId) => {
    return this.rdb
      .ref(
        `${user.data.currentWorkspace}/projects/${projectId}/discussions/${topicId}`
      )
      .orderByKey()
      .limitToFirst(1);
  };

  getDiscussionListenerRealtime = (user, projectId, topicId) => {
    return this.rdb
      .ref(
        `${user.data.currentWorkspace}/projects/${projectId}/discussions/${topicId}`
      )
      .orderByKey()
      .limitToLast(50);
  };

  detachDiscussionListenerRealtime = (user, projectId, topicId) => {
    this.rdb
      .ref(
        `${user.data.currentWorkspace}/projects/${projectId}/discussions/${topicId}`
      )
      .off("value");
  };

  get20DiscussionsListener = (user, projectId, topicId, discussionId) => {
    const path = `${user.data.currentWorkspace}/projects/${projectId}/discussions/${topicId}`;
    return this.rdb
      .ref(path)
      .orderByKey()
      .endBefore(discussionId)
      .limitToLast(20);
  };

  updateDiscussionRealtime = async (
    user,
    projectId,
    topicId,
    message,
    attachment
  ) => {
    const dialogPath = `${user.data.currentWorkspace}/projects/${projectId}`;
    const path = `${dialogPath}/discussions/${topicId}`;
    const newDisscussionId = this.rdb.ref(path).push().key;
    message.id = newDisscussionId;
    let uploadData = [];
    if (attachment) {
      const url = await this.uploadAttachment(path, attachment);
      message.attachment.push(url);
      message.attachment.push(attachment.name);

      const uploads = await this.rdb
        .ref(`${dialogPath}/uploads/${topicId}`)
        .get();
      const value = uploads.val() || [];
      uploadData = [
        ...value,
        {
          src: url,
          name: attachment.name,
          type: attachment.type,
          size: attachment.size,
        },
      ];
      this.rdb.ref(`${dialogPath}/uploads/${topicId}`).set(uploadData);
    } else {
      const result = Utilities.detectURLs(message.message);
      if (result?.length > 0) {
        const uploads = await this.rdb
          .ref(`${dialogPath}/uploads/${topicId}`)
          .get();
        const value = uploads.val() || [];
        uploadData = [...value, { src: result[0], type: "link" }];
        this.rdb.ref(`${dialogPath}/uploads/${topicId}`).set(uploadData);
      }
    }
    this.rdb.ref(path + `/${newDisscussionId}`).update(message);
    return message;
  };

  // #region Notes

  setNote = async (user, data) => {
    const noteRef = this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("users")
      .doc(user.uid)
      .collection("notes");
    const noteDoc = noteRef.doc();
    data.id = noteDoc.id; // Update the id to match firebae id
    data.createdDate = new Date();
    data.modifiedDate = new Date();
    return await noteRef.doc(noteDoc.id).set(data);
  };

  /*   
    This is fine for now as we only fetch it once component is mounted
    If people use it a lot, then we can certainly refactor the query
  */
  getNotes = async (user) => {
    const query = await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("users")
      .doc(user.uid)
      .collection("notes")
      .get();

    return Utilities.toArray(query);
  };

  updateNote = async (user, data) => {
    const newObj = Object.assign({}, data);
    newObj.modifiedDate = new Date();
    return await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("users")
      .doc(user.uid)
      .collection("notes")
      .doc(data.id)
      .update(newObj);
  };

  removeNote = async (user, noteId) => {
    return await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("users")
      .doc(user.uid)
      .collection("notes")
      .doc(noteId)
      .delete();
  };

  getNoteLabels = async (user) => {
    let data;
    return await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("users")
      .doc(user.uid)
      .collection("labels")
      .doc("notes")
      .get()
      .then((snapshot) => {
        data = snapshot.data() || {};
        return data;
      });
  };

  setNoteLabels = async (user, data) => {
    const labelsRef = this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("users")
      .doc(user.uid)
      .collection("labels");
    return await labelsRef.doc("notes").set(data);
  };

  // #endregion

  setTasks = async (user, data) => {
    const tasksRef = this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("users")
      .doc(user.uid)
      .collection("tasks");

    data.createdDate = new Date();
    data.modifiedDate = new Date();
    await tasksRef.doc(data.id).set(data);
    return data;
  };

  updateTask = async (user, data) => {
    const newObj = Object.assign({}, data);
    newObj.modifiedDate = new Date();
    return await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("users")
      .doc(user.uid)
      .collection("tasks")
      .doc(data.id)
      .update(newObj);
  };

  removeTask = async (user, task) => {
    const data = { ...task };
    data.modifiedDate = new Date();
    data.deleted = true;

    return await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("users")
      .doc(user.uid)
      .collection("tasks")
      .doc(data.id)
      .update(data);
  };

  getTaskLabels = async (user) => {
    let data = [];
    return await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("labels")
      .doc("tasks")
      .get()
      .then((snapshot) => {
        data = snapshot.data();
        return data?.labels || [];
      });
  };

  setTaskLabels = async (user, data) => {
    const labelsRef = this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("labels");
    return await labelsRef
      .doc("tasks")
      .set({ labels: data, modifiedDate: new Date() });
  };

  getTasksByRange = async (user, start, end, quantity) => {
    const query = await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("users")
      .doc(user.uid)
      .collection("tasks")
      .orderBy("dueDate", "desc")
      .where("dueDate", ">=", start)
      .where("dueDate", "<=", end)
      .limit(quantity)
      .get();

    return Utilities.toArray(query);
  };

  getTasksByProjectId = async (user, projectId) => {
    const query = await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("users")
      .doc(user.uid)
      .collection("tasks")
      .where("projectId", "==", projectId)
      .get();

    return Utilities.toArray(query);
  };

  getTasksByParentId = async (user, cardId) => {
    const query = await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("users")
      .doc(user.uid)
      .collection("tasks")
      .where("linkedParent", "array-contains", cardId)
      .get();

    return Utilities.toArray(query);
  };

  getNonCompletedAndOrphanTasks = async (user, cardId) => {
    const query = await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("users")
      .doc(user.uid)
      .collection("tasks")
      .where("linkedParent", "==", [])
      .where("completed", "==", false)
      .get();

    return Utilities.toArray(query);
  };

  getTasksByRangeWithoutLimit = async (
    userId,
    workspaceId,
    start,
    end,
    initialPoint,
    quantity,
    routeParams
  ) => {
    let query;
    if (routeParams.filterHandle) {
      query = await this.db
        .collection("workspaces")
        .doc(workspaceId)
        .collection("users")
        .doc(userId)
        .collection("tasks")
        .orderBy("dueDate")
        .where("dueDate", ">=", start)
        .where("dueDate", "<=", end)
        .startAfter(initialPoint)
        .where(`${routeParams.filterHandle}`, "==", true)
        .limit(quantity)
        .get();
    } else if (routeParams.labelHandle) {
      query = await this.db
        .collection("workspaces")
        .doc(workspaceId)
        .collection("users")
        .doc(userId)
        .collection("tasks")
        .orderBy("dueDate")
        .where("dueDate", ">=", start)
        .where("dueDate", "<=", end)
        .startAfter(initialPoint)
        .where("labels", "array-contains", `${routeParams.labelHandle}`)
        .limit(quantity)
        .get();
    } else {
      // all
      query = await this.db
        .collection("workspaces")
        .doc(workspaceId)
        .collection("users")
        .doc(userId)
        .collection("tasks")
        .orderBy("dueDate")
        // TODO
        //.where("dueDate", ">=", start)
        //.where("dueDate", "<=", end)
        .where("completed", "==", false)
        .startAfter(initialPoint)
        //.limit(quantity)
        .get();
    }
    const lastVisible = query.docs[query.docs.length - 1];

    return { data: Utilities.toArray(query), lastVisible };
  };

  getFilters = async (user) => {
    let data;
    return await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("filters")
      .doc("tasks")
      .get()
      .then((snapshot) => {
        data = snapshot.data();
        return data?.filters;
      });
  };

  /* dashboard api */
  setDashboardLayout = async (user, title, layouts) => {
    const docRef = this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("users")
      .doc(user.uid)
      .collection("widgets");

    const modifiedDate = new Date();
    const widgetDoc = docRef.doc();
    const data = { title, layouts, id: widgetDoc.id, modifiedDate };
    await widgetDoc.set(data, { merge: true });

    return { layouts, title, modifiedDate };
  };

  getDashboardLayout = async (user) => {
    let data;
    return await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("users")
      .doc(user.uid)
      .collection("widgets")
      .limit(1)
      .get()
      .then((snapshot) => {
        if (snapshot.docs.length > 0) {
          const doc = snapshot.docs[0];
          data = doc.data();
        }
        return data;
      });
  };

  updateDashboardLayout = async (user, title, layouts, id) => {
    const modifiedDate = new Date();
    await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("users")
      .doc(user.uid)
      .collection("widgets")
      .doc(id)
      .update({
        title,
        layouts,
        modifiedDate,
      });

    return { layouts, title, modifiedDate };
  };

  /* events api */
  setEvent = async (user, data) => {
    const eventRef = this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("events");
    const eventDoc = eventRef.doc();
    data.id = eventDoc.id;
    data.createdDate = new Date();
    return await eventRef.doc(eventDoc.id).set(data);
  };

  getEvents = async (user, start, end, limit, ids) => {
    const collectionPath = this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("events")
      .orderBy("start", "desc")
      .where("start", ">=", start)
      .where("start", "<=", end);
    let batches = [];
    let batch = [];
    while (ids.length) {
      batch = ids.splice(0, 10);
      batches.push(
        collectionPath
          .where("attendees", "array-contains-any", [...batch])
          .limit(limit)
          .get()
          .then((results) => Utilities.toArray(results))
      );
    }
    // after all of the data is fetched, return it
    return Promise.all(batches).then((content) => content.flat());
  };

  getTasksByIds = async (user, userId, ids) => {
    const collectionPath = this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("users")
      .doc(userId)
      .collection("tasks");
    let batches = [];
    let batch = [];
    while (ids.length) {
      batch = ids.splice(0, 10);
      batches.push(
        collectionPath
          .where("id", "in", [...batch])
          .get()
          .then((results) => Utilities.toArray(results))
      );
    }
    // after all of the data is fetched, return it
    return Promise.all(batches).then((content) => content.flat());
  };

  getTasksOfUsers = async (
    user,
    start,
    end,
    ids,
    labelIds,
    completed,
    favourite,
    priority
  ) => {
    const collectionPath = this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("users");
    const batches = [];
    ids.forEach((eachId) => {
      let initialQuery = collectionPath
        .doc(eachId)
        .collection("tasks")
        .orderBy("dueDate", "desc")
        .where("dueDate", ">=", start)
        .where("dueDate", "<=", end);
      if (completed !== undefined)
        initialQuery = initialQuery.where("completed", "==", completed);
      if (favourite !== undefined)
        initialQuery = initialQuery.where("starred", "==", favourite);
      if (priority !== undefined)
        initialQuery = initialQuery.where("important", "==", priority);
      const filteredQuery =
        labelIds?.length > 0
          ? initialQuery.where("idLabels", "array-contains-any", labelIds)
          : initialQuery;

      batches.push(
        filteredQuery.get().then((results) =>
          Utilities.toArray(results).map((each) => ({
            ...each,
            userId: eachId,
          }))
        )
      );
    }); // after all of the data is fetched, return it
    return Promise.all(batches).then((content) => content.flat());
  };

  updateEvent = async (user, data) => {
    const newObj = Object.assign({}, data);
    newObj.modifiedDate = new Date();
    return await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("events")
      .doc(data.id)
      .update(newObj);
  };

  removeEvent = async (user, id) => {
    return await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("events")
      .doc(id)
      .delete();
  };

  getMembers = async (user) => {
    const query = await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("users")
      .get();

    return Utilities.toArray(query);
  };

  /* chat api */
  getChatById = async (user, id) => {
    const snapshot = await this.rdb
      .ref(`${user.data.currentWorkspace}/${user.uid}/chatContacts/${id}`)
      .get();
    return snapshot.val() || {};
  };

  get20DialoguesListener = (user, id, dialogId) => {
    return this.rdb
      .ref(
        `${user.data.currentWorkspace}/${user.uid}/chatDialogues/${id}/dialogues`
      )
      .orderByKey()
      .endBefore(dialogId)
      .limitToLast(20);
  };

  getFirstDialog = (user, id) => {
    return this.rdb
      .ref(
        `${user.data.currentWorkspace}/${user.uid}/chatDialogues/${id}/dialogues`
      )
      .orderByKey()
      .limitToFirst(1);
  };

  getDialogListener = (user, id) => {
    return this.rdb
      .ref(
        `${user.data.currentWorkspace}/${user.uid}/chatDialogues/${id}/dialogues`
      )
      .orderByKey()
      .limitToLast(50);
  };

  getMediaData = async (user, id) => {
    const snapshot = await this.rdb
      .ref(
        `${user.data.currentWorkspace}/${user.uid}/chatDialogues/${id}/uploads`
      )
      .limitToFirst(10)
      .get();
    return snapshot.val() || {};
  };

  detachDialogListener = (user, id) => {
    this.rdb
      .ref(
        `${user.data.currentWorkspace}/${user.uid}/chatDialogues/${id}/dialogues`
      )
      .off("value");
  };

  getDialogListenerAdded = (user, id) => {
    return this.rdb
      .ref(
        `${user.data.currentWorkspace}/${user.uid}/chatDialogues/${id}/dialogues`
      )
      .limitToLast(1);
  };

  detachDialogListenerAdded = (user, id) => {
    this.rdb
      .ref(
        `${user.data.currentWorkspace}/${user.uid}/chatDialogues/${id}/dialogues`
      )
      .off("child_added");
  };

  updateChatRealtime = async (user, id, message, attachment) => {
    this.rdb
      .ref(`${user.data.currentWorkspace}/${user.uid}/chatContacts/${id}`)
      .update({
        lastMessageTime: message.time,
        contactId: id,
        id: user.uid,
        unread: 0,
      });
    const dialogPath = `${user.data.currentWorkspace}/${user.uid}/chatDialogues/${id}`;
    const newDialogueId = this.rdb.ref(`${dialogPath}/dialogues`).push().key;
    message.id = newDialogueId;
    let uploadData = [];
    if (attachment) {
      const path = `${user.data.currentWorkspace}/users/chats/${message.id}`;
      const url = await this.uploadAttachment(path, attachment);
      message.attachment.push(url);
      message.attachment.push(attachment.name);

      const uploads = await this.rdb.ref(`${dialogPath}/uploads`).get();
      const value = uploads.val() || [];
      uploadData = [
        ...value,
        {
          src: url,
          name: attachment.name,
          type: attachment.type,
          size: attachment.size,
        },
      ];
      this.rdb.ref(`${dialogPath}/uploads`).set(uploadData);
    } else {
      const result = Utilities.detectURLs(message.message);
      if (result?.length > 0) {
        const uploads = await this.rdb.ref(`${dialogPath}/uploads`).get();
        const value = uploads.val() || [];
        uploadData = [...value, { src: result[0], type: "link" }];
        this.rdb.ref(`${dialogPath}/uploads`).set(uploadData);
      }
    }

    this.rdb.ref(`${dialogPath}/dialogues/${newDialogueId}`).update(message);

    return { message, uploadData };
  };

  updateMemberChatRealtime = async (user, uid, data, dialogId, uploadData) => {
    this.rdb
      .ref(`${user.data.currentWorkspace}/${uid}/chatContacts/${user.uid}`)
      .update({
        contactId: user.uid,
        id: uid,
        unread: firebase.database.ServerValue.increment(1),
      });
    const dialogPath = `${user.data.currentWorkspace}/${uid}/chatDialogues/${user.uid}`;

    this.rdb.ref(`${dialogPath}/dialogues/${dialogId}`).update(data);
    this.rdb.ref(`${dialogPath}/uploads`).set(uploadData);
  };

  resetUnreadRealtime = async (user, contactId) => {
    this.rdb
      .ref(
        `${user.data.currentWorkspace}/${user.uid}/chatContacts/${contactId}`
      )
      .update({ unread: 0 });
  };

  // TODO: This is expensive query. We cannot fetch entire tracker here.
  getTrackerRef = (user) => {
    return this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("tracker")
      .doc(user.data.id);
  };

  getTrackerWeekRef = (user, each) => {
    return this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("tracker")
      .doc(user.data.id)
      .collection(`${each.year}`)
      .doc(`sprint ${each.weekNo}`);
  };

  // #region quick panel api
  getNotesRef = (user) => {
    return this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("users")
      .doc(user.uid)
      .collection("notes")
      .where("archive", "==", false)
      .orderBy("modifiedDate", "desc")
      .limit(3);
  };

  getEventsRef = (user) => {
    return this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("events")
      .orderBy("start", "desc")
      .limit(3);
  };

  getPostsRef = (user, start, quantity, postId) => {
    if (postId) {
      return this.db
        .collection("workspaces")
        .doc(user.data.currentWorkspace)
        .collection("posts")
        .doc(postId);
    }
    return this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("posts")
      .orderBy("createdDate", "desc")
      .endAt(start)
      .limit(quantity);
  };

  getChatsRef = (user) => {
    return this.rdb.ref(
      `${user.data.currentWorkspace}/${user.uid}/chatContacts`
    );
  };

  //#endregion

  getUserWorkspacesInfo = async (workspaces) => {
    return Promise.all(
      workspaces.map(async (workspace) => {
        if (!workspace.name) {
          const value = await this.getWorkspaceById(workspace.id);
          workspace.name = value.data.name;
        }
        return workspace;
      })
    ).then((content) => content);
  };

  getWorkspaceById = async (workspaceId) => {
    const result = {};
    return await this.db
      .collection("workspaces")
      .doc(workspaceId)
      .get()
      .then((snapshot) => {
        result.data = snapshot.data();
        result.id = snapshot.id;
        return result;
      });
  };

  getWorkspaceUserById = async (workspaceId, userId) => {
    return await this.db
      .collection("workspaces")
      .doc(workspaceId)
      .collection("users")
      .doc(userId)
      .get()
      .then((snapshot) => {
        return snapshot.data();
      });
  };

  getFilteredProjects = async (user) => {
    const query = await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("projects")
      .where("users", "array-contains", user.uid)
      //.where("status", "==", "active")
      .get();

    return Utilities.toArray(query);
  };

  getNavItemProjects = async (user) => {
    const query = await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("projects")
      .where("users", "array-contains", user.uid)
      .where("showInNavbar", "==", true)
      //.where("status", "==", "active")
      .get();

    return Utilities.toArray(query);
  };

  getProjects = async (user, start, quantity, next) => {
    const query = next
      ? await this.db
          .collection("workspaces")
          .doc(user.data.currentWorkspace)
          .collection("projects")
          .orderBy("createdDate", "desc")
          .startAfter(start)
          .limit(quantity)
          .get()
      : await this.db
          .collection("workspaces")
          .doc(user.data.currentWorkspace)
          .collection("projects")
          .orderBy("createdDate", "desc")
          .endBefore(start)
          .limit(quantity)
          .get();

    return Utilities.toArray(query);
  };

  getProjectById = async (user, id) => {
    let data;
    return await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("projects")
      .doc(id)
      .get()
      .then((snapshot) => {
        data = snapshot.data();
        return data;
      });
  };

  setProject = async (argument) => {
    const { workspaceId, ...data } = argument;
    const projectRef = this.db
      .collection("workspaces")
      .doc(workspaceId)
      .collection("projects");

    data.createdDate = new Date();
    data.customFields = Utilities.mapCustomFields(data.customFields);
    await projectRef.doc(data.id).set(data);
    return data.id;
  };

  updateProject = async (argument) => {
    const { workspaceId, ...data } = argument;
    data.customFields = Utilities.mapCustomFields(data.customFields);
    data.modifiedDate = new Date();
    await this.db
      .collection("workspaces")
      .doc(workspaceId)
      .collection("projects")
      .doc(data.id)
      .update(data);
    return data.id;
  };

  deleteProject = async (projectId, workspaceId) => {
    return await this.db
      .collection("workspaces")
      .doc(workspaceId)
      .collection("projects")
      .doc(projectId)
      .delete();
  };

  // Tracker API
  setTracker = async (
    userId,
    user,
    trackerInfo,
    currentYear,
    currentWeekNo
  ) => {
    const docRef = this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("tracker")
      .doc(userId);
    docRef.set({ id: userId, modifiedDate: new Date() });
    await docRef
      .collection(`${currentYear}`)
      .doc(`sprint ${currentWeekNo}`)
      .set(trackerInfo);
  };

  deleteTrackerNotification = async (user, currentYear, currentWeekNo) => {
    let userId = user.data.id;
    await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("tracker")
      .doc(userId)
      .collection(`${currentYear}`)
      .doc(`sprint ${currentWeekNo}`)
      .update({
        notified: firebase.firestore.FieldValue.delete(),
      });
  };

  getUserTrackerDataForReport = async (userId, user, currentYear, status) => {
    let ref = this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("tracker")
      .doc(userId)
      .collection(`${currentYear}`);

    if (Array.isArray(status)) {
      status.forEach((x) => {
        ref = ref.where("status", "==", x);
      });
    }
    const query = await ref.get();

    return Utilities.toArrayWithId(query, userId);
  };

  getTrackerData = async (userId, user, currentYear, currentWeekNo) => {
    return await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("tracker")
      .doc(userId)
      .collection(`${currentYear}`)
      .doc(`sprint ${currentWeekNo}`)
      .get()
      .then((snapshot) => {
        return snapshot.data();
      });
  };

  getOwnerEmail = async (user) => {
    return await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .get()
      .then((snapshot) => {
        return snapshot.data().owner;
      });
  };

  addInPending = async (user, userId, obj) => {
    await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("tracker")
      .doc(userId)
      .set(
        {
          pending: firebase.firestore.FieldValue.arrayUnion(obj),
          modifiedDate: new Date(),
        },
        { merge: true }
      );
  };

  removeFromPending = async (user, userId, obj) => {
    await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("tracker")
      .doc(userId)
      .update({
        pending: firebase.firestore.FieldValue.arrayRemove(obj),
      });
  };

  updateUserStatus = async (user, status, mood) => {
    return await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("users")
      .doc(user.uid)
      .update({ status, mood });
  };

  updateUserStarredChats = async (user, starred) => {
    return await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("users")
      .doc(user.uid)
      .update({ starred });
  };

  getTimelines = async (user, tabValue, startDate, endDate, quantity = 10) => {
    const timelineRef = this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("users")
      .doc(user.uid)
      .collection("timeline");

    let query = null;
    if (tabValue === 0) {
      query = await timelineRef
        .where("createdDate", "==", startDate)
        .limit(quantity)
        .get();
    } else {
      query = await timelineRef
        .where("createdDate", ">=", startDate)
        .where("createdDate", "<=", endDate)
        .orderBy("createdDate", "desc")
        .limit(quantity)
        .get();
    }

    return Utilities.toArray(query);
  };

  addNewTimeline = async (user, timeline) => {
    return await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("users")
      .doc(user.uid)
      .collection("timeline")
      .doc()
      .set(timeline);
  };

  getHashtags = async (user) => {
    let data;
    return await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("filters")
      .doc("hashtags")
      .get()
      .then((snapshot) => {
        data = snapshot.data();
        return data?.hashtags;
      });
  };

  setHashtags = async (user, hashtags, hashtag) => {
    const tags = hashtags.map((x) => x.display);
    const data = tags.concat(hashtag);
    await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("filters")
      .doc("hashtags")
      .set({ hashtags: [...new Set(data)] });
  };

  getPosts = async (user, start, quantity, postId, userId, hashTag) => {
    const postRef = this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("posts")
      .orderBy("createdDate", "desc");

    if (postId) {
      return await postRef
        .doc(postId)
        .get()
        .then((snapshot) => new Array(1).fill(snapshot.data()));
    } else {
      const ref = userId
        ? postRef.where("user", "==", userId)
        : hashTag
        ? postRef.where("hashtag", "array-contains", hashTag)
        : postRef;
      const query = await ref.startAfter(start).limit(quantity).get();
      return Utilities.toArray(query);
    }
  };

  setPost = async (user, data, hashtags) => {
    const conRef = this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("posts");
    const contDoc = conRef.doc();
    data.id = contDoc.id;
    if (data.media) {
      const uploadTask = stoRef(
        this.storage,
        `${user.data.currentWorkspace}/posts/${format(
          new Date(),
          "MMM yyyy"
        )}/${data.media.image.name}`
      );
      await uploadBytes(uploadTask, data.media.image);
      const url = await getDownloadURL(uploadTask);
      data.media = {
        type: "image",
        preview: url,
      };
    }

    if (data.hashtag?.length > 0) {
      await this.setHashtags(user, hashtags, data.hashtag);
    }
    await conRef.doc(contDoc.id).set(data);
    const myData = await conRef.doc(contDoc.id).get();
    return myData.data();
  };

  editPost = async (user, data, hashtags) => {
    data.modifiedDate = new Date();
    if (data.media) {
      const uploadTask = stoRef(
        this.storage,
        `${user.data.currentWorkspace}/posts/${format(
          new Date(),
          "MMM yyyy"
        )}/${data.media.image.name}`
      );
      await uploadBytes(uploadTask, data.media.image);
      const url = await getDownloadURL(uploadTask);
      data.media = {
        type: "image",
        preview: url,
      };
    }

    if (data.hashtag?.length > 0) {
      await this.setHashtags(user, hashtags, data.hashtag);
    }
    await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("posts")
      .doc(data.id)
      .update(data);
    return data;
  };

  deletePost = async (user, data) => {
    if (data.media) {
      const imgRef = stoRef(
        this.storage,
        `${user.data.currentWorkspace}/posts/${user.uid}/${data.id}`
      );

      deleteObject(imgRef);
    }

    await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("posts")
      .doc(data.id)
      .delete();
  };

  addComment = async (user, data, message) => {
    return await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("posts")
      .doc(data.id)
      .update({ comments: message });
  };

  addLike = async (user, data, likes) => {
    return await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("posts")
      .doc(data.id)
      .update({ like: likes });
  };

  addShare = async (user, data) => {
    return await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("posts")
      .doc(data.id)
      .update(data);
  };

  getPhotos = async (user, selection, currentState) => {
    const listOptions = {
      maxResults: 10,
      pageToken: currentState?.nextPageToken
        ? currentState?.nextPageToken
        : null,
    };
    const photoStorageRef = stoRef(
      this.storage,
      `${user.data.currentWorkspace}/posts/${selection}`
    );
    return list(photoStorageRef, listOptions).then(async (res) => {
      const data = {
        count: res.items.length,
        info: res.items.length + " Photos",
        nextPageToken: res.nextPageToken,
        items: [],
        selection,
      };
      for (var i = 0; i < res.items.length; i++) {
        const specStorageRef = stoRef(
          this.storage,
          `${user.data.currentWorkspace}/posts/${selection}/${res.items[i].name}`
        );
        const url = await getDownloadURL(specStorageRef);
        data.items.push({
          title: res.items[i].name,
          preview: url,
          type: "photo",
        });
      }
      return data;
    });
  };

  updateUserPhoto = async (user, image) => {
    // const fileName = image?.name || Utilities.generateGUID();
    //can I name user profile picture as its uid?
    const fileName = `profile-${user.uid}`;
    const uploadTask = stoRef(
      this.storage,
      `${user.data.currentWorkspace}/users/${user.uid}/${fileName}`
    );
    try {
      await uploadBytes(uploadTask, image);
      const url = await getDownloadURL(uploadTask);
      await this.db
        .collection("workspaces")
        .doc(user.data.currentWorkspace)
        .collection("users")
        .doc(user.uid)
        .update({ photoURL: url });
      return url;
    } catch {
      return 0;
    }
  };

  deleteUserPhoto = async (user) => {
    const imgRef = stoRef(
      this.storage,
      `${user.data.currentWorkspace}/users/${user.uid}/profile-${user.uid}`
    );

    deleteObject(imgRef)
      .then(() => {
        this.db
          .collection("workspaces")
          .doc(user.data.currentWorkspace)
          .collection("users")
          .doc(user.uid)
          .update({ photoURL: "" });
      })
      .catch((error) => {
        return 0;
      });
    return 1;
  };

  updateCoverPhoto = async (user, image) => {
    const fileName = `cover-${user.uid}`;
    const uploadTask = stoRef(
      this.storage,
      `${user.data.currentWorkspace}/users/${user.uid}/${fileName}`
    );
    try {
      await uploadBytes(uploadTask, image);
      const url = await getDownloadURL(uploadTask);
      await this.db
        .collection("workspaces")
        .doc(user.data.currentWorkspace)
        .collection("users")
        .doc(user.uid)
        .update({ coverPhotoURL: url });
      return url;
    } catch (e) {
      console.error(e);
      return 0;
    }
  };

  deleteCoverPhoto = async (user) => {
    const imgRef = stoRef(
      this.storage,
      `${user.data.currentWorkspace}/users/${user.uid}/cover-${user.uid}`
    );

    deleteObject(imgRef)
      .then(() => {
        this.db
          .collection("workspaces")
          .doc(user.data.currentWorkspace)
          .collection("users")
          .doc(user.uid)
          .update({ coverPhotoURL: "" });
      })
      .catch((error) => {
        return 0;
      });
    return 1;
  };

  addInvitedMembers = async (argument) => {
    const { workspaceId, data } = argument;
    await this.db
      .collection("workspaces")
      .doc(workspaceId)
      .collection("invitedMembers")
      .doc("list")
      .set({ invited: data }, { merge: true });

    return 1;
  };

  getInvitedMembers = async (workspaceId) => {
    let data;
    return await this.db
      .collection("workspaces")
      .doc(workspaceId)
      .collection("invitedMembers")
      .doc("list")
      .get()
      .then((snapshot) => {
        data = snapshot.data() || { invited: [] };
        return data.invited;
      });
  };

  createReport = async (user, reportData) => {
    const reportRef = this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("reports");
    const reportDoc = reportRef.doc();
    reportData.id = reportDoc.id;
    reportData.createdDate = new Date();
    reportData.modifiedDate = new Date();
    await reportRef.doc(reportDoc.id).set(reportData);
    return reportData.id;
  };

  updateReport = async (user, reportData, reportId) => {
    await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("reports")
      .doc(reportId)
      .update({ ...reportData });
  };

  fetchReport = async (user, reportId) => {
    return await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("reports")
      .doc(reportId)
      .get()
      .then((snapshot) => snapshot.data());
  };

  deleteReport = async (user, reportId) => {
    return await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("reports")
      .doc(reportId)
      .delete();
  };

  // TODO: Cannot fetch all reports at once, use pagination similar to backlog page
  fetchReports = async (user) => {
    const snapshot = await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("reports")
      .where("permissionConfig", "array-contains-any", [user.uid, false])
      .get();
    return Utilities.toArray(snapshot);
  };

  // TODO: Hook up this
  updateReportPermission = async (user, reportId, hidden) => {
    await this.db
      .collection("workspaces")
      .doc(user.data.currentWorkspace)
      .collection("reports")
      .doc(reportId)
      .update({ permissionConfig: [user.uid, hidden] });
  };
}

const instance = new FirebaseService();

export default instance;
