import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { matchRoutes, useLocation, useParams } from "react-router-dom";
import { setDefaultSettings } from "store/slice/settingsSlice";
import _ from "utils/lodash";
import { selectProjectById } from "views/projects/store/projectsSlice";
import {
  navbarToggleMobile,
  navbarToggle,
} from "../../store/slice/navbarSlice";
import TeamMembers from "../TeamMembers";
import { Box } from "@mui/system";
import Hamburger from "components/svgs/icons/general/Hamburger";
import Arrow from "components/svgs/icons/general/Arrow";

const NavbarToggleButton = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const routeParams = useParams();
  const [title, setTitle] = useState("");
  const [projectId, setProjectId] = useState("");
  const [showMembers, setShowHideMembers] = useState(false);
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("lg"));
  const xsDown = useMediaQuery(theme.breakpoints.down("sm"));
  const settings = useSelector(({ entities }) => entities.settings.current);
  const { config } = settings.layout;
  const projectData = useSelector((state) =>
    selectProjectById(state, projectId)
  );

  useEffect(() => {
    updateNavTitle();
  }, []);

  useEffect(() => {
    updateNavTitle();
  }, [routeParams]);

  useEffect(() => {
    if (projectData) setTitle(projectData.name);
  }, [projectData]);

  const updateNavTitle = () => {
    const matchedRoutes = matchRoutes(props.routes, location.pathname);
    if (matchedRoutes) {
      if (matchedRoutes[0].route.showMembers) {
        setShowHideMembers(true);
        setProjectId(matchedRoutes[0].params.projectId);
      } else {
        setShowHideMembers(false);
        setTitle(matchedRoutes[0].route.title);
      }
    }
  };

  return (
    <>
      <IconButton
        className={props.className}
        disableRipple
        color="inherit"
        size="small"
        onClick={(ev) => {
          if (mdDown) {
            dispatch(navbarToggleMobile());
          } else if (config.navbar.style === "style-2") {
            dispatch(
              setDefaultSettings(
                _.set(
                  {},
                  "layout.config.navbar.folded",
                  !settings.layout.config.navbar.folded
                )
              )
            );
          } else {
            dispatch(navbarToggle());
          }
        }}
      >
        <Box className="logo-icon xs:w-5 xs:h-5 md:w-8 md:h-8">
          {props.navbar?.open ? <Hamburger /> : <Arrow />}
        </Box>

        <span className="xs:ml-2 md:ml-5 font-medium text-primaryBlue md:text-2xl xs:text-xl text-lg">
          {xsDown
            ? _.truncate(title, { length: 15 })
            : _.truncate(title, { length: 30 })}
        </span>
      </IconButton>
      {showMembers && (
        <span className={`${mdDown ? "" : "ml-4"} mt-1 cursor-pointer`}>
          <TeamMembers users={projectData?.users} />
        </span>
      )}
    </>
  );
};

NavbarToggleButton.defaultProps = {};

export default NavbarToggleButton;
