import { Dialog, useMediaQuery, useTheme } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import TaskForm from "./TaskForm";
import { useSelector } from "react-redux";

const TaskFormDialog = (props) => {
  const { editedTask, setBoardTasks } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const taskDialog = useSelector(({ taskApp }) => taskApp.tasks.taskFormDialog);

  if (isMobile) {
    return (
      <Dialog
        fullScreen
        anchor="right"
        open={taskDialog?.props?.open}
        classes={{ paper: "rounded-none" }}
      >
        <TaskForm
          dialogType={taskDialog.type}
          setBoardTasks={setBoardTasks}
          editedTask={editedTask}
          linkedParent={taskDialog?.props?.linkedParent}
        />
      </Dialog>
    );
  }

  return (
    <Drawer
      PaperProps={{
        sx: { width: { xs: "80%", sm: "70%", md: "60%", lg: "60%" } },
      }}
      anchor="right"
      open={taskDialog?.props?.open}
    >
      <TaskForm
        dialogType={taskDialog.type}
        setBoardTasks={setBoardTasks}
        editedTask={editedTask}
        linkedParent={taskDialog?.props?.linkedParent}
      />
    </Drawer>
  );
};

export default TaskFormDialog;
