import React from "react";

const ToggleRightReverse = (props) => {
  const { height = 60, width = 32 } = props;
  return (
    <svg
      id="Group_43753"
      data-name="Group 43753"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 36.537 66.87"
      className="stroke-primaryBlue"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_17961"
            data-name="Rectangle 17961"
            width="36.537"
            height="66.87"
            fill="#fff"
            stroke="#707070"
            strokeWidth="1"
          />
        </clipPath>
        <filter
          id="Path_37793"
          x="-41.269"
          y="-3.419"
          width="78.748"
          height="73.774"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx="-2" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodColor="#00b9d9" floodOpacity="0.2" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Mask_Group_12"
        data-name="Mask Group 12"
        transform="translate(0)"
        clipPath="url(#clipPath)"
      >
        <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_37793)">
          <path
            id="Path_37793-2"
            data-name="Path 37793"
            d="M30.374,0C13.6,0,0,12.485,0,27.887S13.6,55.774,30.374,55.774,60.748,43.288,60.748,27.887,47.149,0,30.374,0Z"
            transform="translate(-30.27 5.58)"
            fill="#fff"
          />
        </g>
      </g>
      <path
        id="Icon_feather-chevron-down"
        data-name="Icon feather-chevron-down"
        d="M0,9.919,7,0l7,9.919"
        transform="translate(24.857 27.198) rotate(90)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default ToggleRightReverse;
