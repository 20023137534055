const Invite = (props) => {
  const { height = 24, width = 24, color = "#6e6e6e" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width={height}
      height={width}
      viewBox="0 0 27.328 27.421"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_17631"
            data-name="Rectangle 17631"
            width="27.328"
            height="27.421"
            fill={color}
          />
        </clipPath>
      </defs>
      <g id="Group_40620" data-name="Group 40620" transform="translate(0 0)">
        <g
          id="Group_40619"
          data-name="Group 40619"
          transform="translate(0 0)"
          clipPath="url(clipPath)"
        >
          <path
            id="Path_34392"
            data-name="Path 34392"
            d="M20.558,18.649a.787.787,0,0,0,.452.213c.458.017.736-.065.864-.265a1.156,1.156,0,0,0,.016-.886.7.7,0,0,0-.345-.385l-.064-.042a13.3,13.3,0,0,0-3.779-1.87,8.373,8.373,0,0,0,3.81-9.561A8.2,8.2,0,0,0,13.745,0h-.037A8.2,8.2,0,0,0,5.8,5.879a8.358,8.358,0,0,0,3.77,9.5c-.218.075-.43.151-.641.232A13.236,13.236,0,0,0,.71,23.84c-.232.667-.4,1.361-.565,2.031l-.108.439a.968.968,0,0,0,.091.762.754.754,0,0,0,.5.332.834.834,0,0,0,.171.017.883.883,0,0,0,.834-.783c.031-.133.057-.266.082-.4A12.081,12.081,0,0,1,20.481,18.59Zm-6.635-3.84a6.585,6.585,0,1,1-.4-13.163h.014A6.489,6.489,0,0,1,20.2,8.237a6.592,6.592,0,0,1-6.277,6.572"
            transform="translate(0 0)"
            fill={color}
          />
          <path
            id="Path_34393"
            data-name="Path 34393"
            d="M29.91,25.007a1.009,1.009,0,0,0-.76-.311c-.47,0-.935,0-1.4,0l-1.521,0,0-1.735c0-.437.005-.875-.005-1.312a.765.765,0,0,0-.784-.8h-.011a.762.762,0,0,0-.758.769,4.035,4.035,0,0,0-.013.422V24.7H21.627a.76.76,0,0,0-.855.779.785.785,0,0,0,.83.808c.107,0,.219,0,.33,0l2.72,0,0,1.014q0,1.017.005,2.036a.866.866,0,0,0,.233.639.751.751,0,0,0,.545.2h.016a.77.77,0,0,0,.764-.814c.013-.191.012-.384.011-.576v-2.5h.925c.67,0,1.338,0,2.009,0h.008a.819.819,0,0,0,.907-.743.672.672,0,0,0-.168-.531"
            transform="translate(-2.755 -2.765)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

export default Invite;
