const Projects = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 26.449 29.289"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_17625"
            data-name="Rectangle 17625"
            width="26.449"
            height="29.289"
            fill="#6e6e6e"
          />
        </clipPath>
      </defs>
      <g id="Group_40607" data-name="Group 40607" transform="translate(0 0)">
        <g
          id="Group_40606"
          data-name="Group 40606"
          transform="translate(0 0)"
          clipPath="url(clipPath)"
        >
          <path
            id="Path_34366"
            data-name="Path 34366"
            d="M28.914,15.855q0,5.763,0,11.525a1.839,1.839,0,0,1-1.535,1.882,2.085,2.085,0,0,1-.37.025q-8.848,0-17.695,0A1.89,1.89,0,0,1,7.357,27.34a2.737,2.737,0,0,1,.524-1.89c.118-.152.2-.334.3-.5s.237-.158.322,0a.771.771,0,0,1,.1.347c.009.675,0,1.35,0,2.025a.651.651,0,0,0,.73.732q8.813,0,17.625,0a.635.635,0,0,0,.719-.713q0-11.479,0-22.956a.641.641,0,0,0-.717-.718c-.954,0-1.909.006-2.863-.005-.213,0-.278.07-.257.267a2.7,2.7,0,0,1,0,.3.685.685,0,0,1-.744.736c-1.1,0-2.2,0-3.306,0l-6.543,0a.712.712,0,0,1-.807-.819c0-.482,0-.482-.474-.482q-1.3,0-2.608,0a.664.664,0,0,0-.756.756q0,4.843,0,9.685a.919.919,0,0,1-.107.413.158.158,0,0,1-.3,0c-.273-.443-.534-.891-.792-1.342a.473.473,0,0,1-.035-.225q0-4.319,0-8.638A1.858,1.858,0,0,1,9.239,2.437c.985-.011,1.971-.007,2.957,0,.192,0,.251-.054.251-.249,0-.889.167-1.047,1.065-1.047.83,0,1.661,0,2.491,0A.389.389,0,0,0,16.38.951a2.113,2.113,0,0,1,3.547.027.331.331,0,0,0,.32.167c.924-.005,1.847,0,2.77,0,.572,0,.823.253.824.822,0,.469,0,.469.469.469.877,0,1.754,0,2.631,0A1.854,1.854,0,0,1,28.913,4.4q0,5.727,0,11.455M18.142,4.155c1.544,0,3.089,0,4.633,0,.208,0,.267-.065.264-.265q-.016-.838,0-1.676c0-.2-.06-.266-.265-.264-1.024.009-2.049,0-3.073.007a.265.265,0,0,1-.3-.2,1.3,1.3,0,0,0-2.5,0,.266.266,0,0,1-.3.207c-1.016-.007-2.033,0-3.05-.007-.217,0-.3.051-.292.282q.023.826,0,1.652c-.006.222.072.271.279.27,1.537-.007,3.073,0,4.61,0"
            transform="translate(-2.466 0)"
            fill="#6e6e6e"
          />
          <path
            id="Path_34367"
            data-name="Path 34367"
            d="M10.952,23.477c.261.626.514,1.225.752,1.83.019.049-.043.168-.1.2q-.531.335-1.079.642a.273.273,0,0,0-.174.332.622.622,0,0,1,.009.277c-.09.338.078.5.359.642.351.178.681.4,1.038.61-.209.506-.411,1-.615,1.49-.189.457-.19.457-.665.337-.316-.08-.633-.153-.945-.245a.236.236,0,0,0-.3.094.6.6,0,0,1-.158.169c-.358.219-.362.511-.235.871.118.334.184.687.281,1.062L7.175,32.6c-.242-.409-.486-.8-.711-1.208-.088-.158-.2-.181-.368-.207a.87.87,0,0,0-1.045.618,6.689,6.689,0,0,1-.466.8c-.616-.255-1.224-.5-1.826-.763-.049-.021-.088-.155-.072-.223.1-.415.2-.829.316-1.239a.241.241,0,0,0-.094-.3.721.721,0,0,1-.2-.191c-.2-.337-.469-.32-.79-.213a9.041,9.041,0,0,1-.945.243.243.243,0,0,1-.212-.084c-.26-.6-.505-1.2-.761-1.814.416-.248.818-.5,1.229-.727.141-.08.161-.18.187-.33a.893.893,0,0,0-.638-1.079A6.87,6.87,0,0,1,0,25.423l.806-1.944c.477.121.935.23,1.389.356a.271.271,0,0,0,.341-.1,1.018,1.018,0,0,1,.242-.25.452.452,0,0,0,.179-.61,8.645,8.645,0,0,1-.272-1.057.248.248,0,0,1,.079-.219c.6-.26,1.2-.5,1.82-.76.242.407.484.8.712,1.206.086.152.175.234.365.2a1.085,1.085,0,0,1,.417,0c.209.043.3-.051.4-.22.217-.393.455-.774.7-1.182l1.946.8c-.119.469-.228.927-.354,1.381-.043.156-.034.271.11.358a.156.156,0,0,1,.05.048.794.794,0,0,0,1.107.289,7.976,7.976,0,0,1,.919-.244m-7.9,3.25a2.831,2.831,0,1,0,2.813-2.842,2.821,2.821,0,0,0-2.813,2.842"
            transform="translate(0 -6.988)"
            fill="#6e6e6e"
          />
          <path
            id="Path_34368"
            data-name="Path 34368"
            d="M19.9,13.889q3.269,0,6.539,0a1.25,1.25,0,0,1,.431.062.482.482,0,0,1,.327.488.487.487,0,0,1-.318.472.962.962,0,0,1-.36.068q-6.609,0-13.218,0a.942.942,0,0,1-.252-.032.527.527,0,0,1-.425-.558.559.559,0,0,1,.528-.5c.092-.009.186-.006.279-.006H19.9"
            transform="translate(-4.233 -4.657)"
            fill="#6e6e6e"
          />
          <path
            id="Path_34369"
            data-name="Path 34369"
            d="M19.9,11.007q-3.27,0-6.54,0a1.193,1.193,0,0,1-.428-.072.51.51,0,0,1,.01-.943,1.13,1.13,0,0,1,.406-.074q6.563-.006,13.126,0a1.255,1.255,0,0,1,.3.033.53.53,0,0,1,.426.558.551.551,0,0,1-.5.5,2.428,2.428,0,0,1-.279.008H19.9"
            transform="translate(-4.233 -3.324)"
            fill="#6e6e6e"
          />
          <path
            id="Path_34370"
            data-name="Path 34370"
            d="M31.5,30.908c.578,0,1.135,0,1.692.007.051,0,.112.088.144.148.023.043.006.106.006.161,0,1.464,0,2.929,0,4.393,0,.2-.05.287-.271.281-.518-.015-1.036-.005-1.575-.005Z"
            transform="translate(-10.561 -10.364)"
            fill="#6e6e6e"
          />
          <path
            id="Path_34371"
            data-name="Path 34371"
            d="M16.716,18.952q-1.64,0-3.28,0a2.249,2.249,0,0,1-.348-.02.529.529,0,0,1-.019-1.048,1.515,1.515,0,0,1,.3-.019h6.675a2.159,2.159,0,0,1,.279.011.536.536,0,0,1,.485.508.523.523,0,0,1-.465.546,2.65,2.65,0,0,1-.417.021q-1.605,0-3.21,0"
            transform="translate(-4.233 -5.991)"
            fill="#6e6e6e"
          />
          <path
            id="Path_34372"
            data-name="Path 34372"
            d="M24.057,31.773h1.823c.005.088.016.177.016.267,0,1.288,0,2.575.006,3.863,0,.227-.066.292-.288.285-.457-.016-.915-.006-1.373,0-.12,0-.208-.01-.207-.171.005-1.389,0-2.777.007-4.166a.506.506,0,0,1,.017-.074"
            transform="translate(-8.059 -10.654)"
            fill="#6e6e6e"
          />
          <path
            id="Path_34373"
            data-name="Path 34373"
            d="M29.088,26.222l.459.409-3.5,3.359-2.47-2.033L20.4,31l-.441-.427,3.583-3.439,2.471,2.033,3.075-2.945"
            transform="translate(-6.693 -8.793)"
            fill="#6e6e6e"
          />
          <path
            id="Path_34374"
            data-name="Path 34374"
            d="M27.749,33.211h1.723c.158,0,.136.116.136.213q0,.826,0,1.651c0,.449-.006.9,0,1.348,0,.175-.041.25-.231.245-.48-.011-.961,0-1.441,0-.112,0-.194-.011-.193-.159,0-1.084,0-2.168,0-3.3"
            transform="translate(-9.304 -11.136)"
            fill="#6e6e6e"
          />
          <path
            id="Path_34375"
            data-name="Path 34375"
            d="M22.166,37.2c-.586,0-1.143,0-1.7-.007a.211.211,0,0,1-.14-.15c-.009-.735-.007-1.47-.007-2.227h1.846Z"
            transform="translate(-6.813 -11.675)"
            fill="#6e6e6e"
          />
          <path
            id="Path_34376"
            data-name="Path 34376"
            d="M24.94,26.048l-.814-1.408h1.625l-.812,1.408"
            transform="translate(-8.09 -8.262)"
            fill="#6e6e6e"
          />
          <path
            id="Path_34377"
            data-name="Path 34377"
            d="M32.509,23.192h1.629L33.323,24.6l-.814-1.412"
            transform="translate(-10.901 -7.777)"
            fill="#6e6e6e"
          />
          <path
            id="Path_34378"
            data-name="Path 34378"
            d="M22.053,3.994c.869,0,1.737,0,2.606,0a.4.4,0,0,1,.124.792,1.557,1.557,0,0,1-.255.014H19.572a1.676,1.676,0,0,1-.209-.007A.388.388,0,0,1,19,4.387.376.376,0,0,1,19.377,4c.31-.011.62-.005.931-.005h1.745Z"
            transform="translate(-6.371 -1.339)"
            fill="#6e6e6e"
          />
          <path
            id="Path_34379"
            data-name="Path 34379"
            d="M6.211,29.01l.663-.7.781.739,1.464-1.384.662.7-2.124,2.02L6.211,29.01"
            transform="translate(-2.083 -9.278)"
            fill="#6e6e6e"
          />
        </g>
      </g>
    </svg>
  );
};

export default Projects;
