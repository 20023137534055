import { Add } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import {
  CardContent,
  FormControl,
  InputAdornment,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/styles";
import { searchHandler } from "api/algoliaSearch";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import firebase from "services/firebase";
import { modalstyle } from "settings/styleOverrides";
import { addNewRow } from "../store/trackerSlice";
import Close from "components/svgs/icons/general/Close";

// TODO: should use useForm concept here instead of maintaining too many states
const NewRowModal = (props) => {
  const { darkMode, classes } = props;
  const dispatch = useDispatch();
  const theme = useTheme();
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
  const [projects, setProjects] = useState([]);
  const [boardCardOptions, setBoardCardOptions] = useState([]);
  const [taskOptions, setTaskOptions] = useState([]);
  const [projectInfo, setProjectInfo] = useState(null);
  const [cardInfo, setCardInfo] = useState(null);
  const [taskInfo, setTaskInfo] = useState(null);
  const { rows } = useSelector((state) => state.trackerApp);
  const user = useSelector(({ auth }) => auth.user);
  const settings = useSelector(({ entities }) => entities.settings.current);

  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));

  const style = modalstyle();
  style.width = isSmall ? "90%" : mdDown ? "80%" : lgDown ? "65%" : "50%";

  useEffect(() => {
    if (open) fetchProjects();
  }, [open]);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setProjectInfo(null);
    setCardInfo(null);
    setBoardCardOptions([]);
    setTaskInfo(null);
    setTaskOptions([]);
    setOpen(false);
  };

  const handleProjectName = (e) => {
    const selected = { name: e.target.value.name, id: e.target.value.id };
    setProjectInfo(selected);
    setCardInfo(null);

    fetchBoardsByProjectId(selected);
  };

  const handleCardName = (e, props) => {
    const selected = { name: props?.value, id: props?.id };
    setCardInfo(selected);
    setTaskInfo(null);

    fetchTasks(selected);
  };

  const handleTaskName = (e, props) => {
    const selected = { name: props?.props?.value, id: props?.props?.id };
    setTaskInfo(selected);
  };

  const fetchBoardsByProjectId = async (project) => {
    const body = {
      filters: `objectType:scrumboard AND projectId:${project.id} AND cards.idMembers:${user.uid}`,
      hits_per_page: 100,
      query: "",
      workspaceId: user?.data?.currentWorkspace,
    };
    const response = await searchHandler(body);
    if (response?.body?.hits.length === 0) {
      setBoardCardOptions([]);
      return;
    }

    // const concernedRows = rows.filter(
    //   (eachRow) => eachRow.projectName === project.name
    // );
    // // TODO: Track against card or task scenario
    // const data = response?.body?.hits.map((eachBoard) => ({
    //   ...eachBoard,
    //   cards: eachBoard.cards.filter((eachCard) =>
    //     concernedRows.every((eachRow) => eachRow.cardName !== eachCard.name)
    //   ),
    //}));

    setBoardCardOptions(response?.body?.hits);
  };

  const fetchProjects = async () => {
    const body = {
      filters: `objectType:project AND users:${user.uid}`,
      hits_per_page: 100,
      query: "",
      workspaceId: user?.data?.currentWorkspace,
    };
    const response = await searchHandler(body);
    if (response?.body?.hits.length > 0) setProjects(response?.body?.hits);
  };

  const fetchTasks = async (card) => {
    const response = await firebase.getTasksByParentId(user, card.id);
    const filteredTasks = response?.filter(
      (x) => rows.findIndex((y) => y.taskId === x.id) === -1
    );
    setTaskOptions(filteredTasks);
  };

  const submitForm = () => {
    dispatch(addNewRow({ cardInfo, projectInfo, taskInfo }));
    handleClose();
  };

  const containsText = (text, searchText) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  const displayedProjectOptions = useMemo(() => {
    if (searchText === "") return projects;
    return projects.filter((option) => containsText(option.name, searchText));
  }, [projects, searchText]);

  return (
    <div>
      <Button
        className={`ml-3 bg-primaryBlue hover:bg-primaryBlueLight min-w-[5.5em] text-white w-36 rounded-none text-lg -mb-[5%] `}
        startIcon={<Add />}
        onClick={handleOpen}
      >
        New Row
      </Button>
      <Modal
        BackdropProps={{ style: { backgroundColor: "grey", opacity: "0.4" } }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modal-container flex flex-col my-5">
          <div className="modal-header bg-primaryBlue h-[75px] flex flex-col justify-center">
            <div className="flex justify-between px-8">
              <Typography className="text-white text-[20px]">
                Add New Row
              </Typography>
              <Box onClick={handleClose} className="cursor-pointer">
                <Close />
              </Box>
            </div>
          </div>
          <div
            className={`content-container ${
              darkMode ? "bg-darkSmoke" : "bg-lightBg"
            } h-full`}
          >
            <CardContent>
              <div className="my-8">
                <div className="text-lg tracking-wide pb-2">Project</div>
                <FormControl className="w-full">
                  <Select
                    MenuProps={{ autoFocus: false }}
                    fullWidth
                    labelId="simple-select-label"
                    id="simple-select"
                    defaultValue=""
                    onChange={handleProjectName}
                    onClose={() => setSearchText("")}
                    size="small"
                  >
                    {projects.length === 0 && (
                      <ListSubheader
                        className={`font-semibold ${
                          settings.darkMode
                            ? "bg-slate-700 text-slate-200"
                            : "bg-slate-100 text-mute"
                        } text-md`}
                      >
                        Add Project first in order to log time
                      </ListSubheader>
                    )}
                    {projects.length > 0 && (
                      <ListSubheader>
                        <TextField
                          size="small"
                          autoFocus
                          placeholder="Type to search..."
                          fullWidth
                          InputProps={{
                            className: classes.input,
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => setSearchText(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key !== "Escape") {
                              // Prevents autoselecting item while typing (default Select behaviour)
                              e.stopPropagation();
                            }
                          }}
                        />
                      </ListSubheader>
                    )}
                    {displayedProjectOptions.map((item) => (
                      <MenuItem key={item.id} value={item}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="my-8">
                <div className="text-lg tracking-wide pb-2">Board/Card</div>
                <FormControl className="w-full">
                  <Select
                    fullWidth
                    labelId="simple-select-label"
                    id="simple-select"
                    value={cardInfo?.name}
                    onChange={(e, child) => handleCardName(e, child.props)}
                    size="small"
                  >
                    {boardCardOptions?.map((list) => {
                      const elementsArr = [];
                      if (list.cards.length > 0) {
                        elementsArr.push(
                          <ListSubheader
                            className={`font-semibold ${
                              settings.darkMode
                                ? "bg-slate-700 text-slate-200"
                                : "bg-slate-100 text-black"
                            } text-md`}
                          >
                            {list.name}
                          </ListSubheader>
                        );
                        elementsArr.push(
                          list.cards.map((c) => {
                            return (
                              <MenuItem
                                className="ml-12"
                                key={c.id}
                                id={c.id}
                                value={c.name}
                              >
                                {c.id + ": " + c.name}
                              </MenuItem>
                            );
                          })
                        );
                      }
                      return elementsArr;
                    })}

                    {boardCardOptions.length === 0 && (
                      <ListSubheader
                        className={`font-semibold ${
                          settings.darkMode
                            ? "bg-slate-700 text-slate-200"
                            : "bg-slate-100 text-black"
                        } text-md`}
                      >
                        There are no boards available
                      </ListSubheader>
                    )}
                  </Select>
                </FormControl>
              </div>

              <div className="my-8">
                <div className="text-lg tracking-wide pb-2">Tasks</div>
                <FormControl className="w-full">
                  <Select
                    fullWidth
                    labelId="simple-select-label"
                    id="simple-select"
                    value={taskInfo?.name}
                    onChange={(e, child) => handleTaskName(e, child)}
                    size="small"
                  >
                    {taskOptions.map((item) => (
                      <MenuItem key={item.id} id={item.id} value={item.title}>
                        {item.taskId + ": " + item.title}
                      </MenuItem>
                    ))}

                    {taskOptions.length === 0 && (
                      <ListSubheader
                        className={`font-semibold ${
                          settings.darkMode
                            ? "bg-slate-700 text-slate-200"
                            : "bg-slate-100 text-black"
                        } text-md`}
                      >
                        There are no tasks available
                      </ListSubheader>
                    )}
                  </Select>
                </FormControl>
              </div>

              <div className="flex gap-x-6 justify-end">
                <Button
                  className="bg-lightGray hover:bg-lightGray/70 min-w-[5.5em] text-white w-36 rounded"
                  aria-label="cancel"
                  onClick={handleClose}
                >
                  Cancel
                </Button>

                <Button
                  className={`${
                    !projectInfo?.name || !cardInfo?.name || !taskInfo?.name
                      ? "bg-blueTransparent"
                      : "bg-primaryBlue"
                  } ml-3 hover:bg-primaryBlueLight min-w-[5.5em] text-white w-36 rounded`}
                  aria-label="Add"
                  onClick={submitForm}
                  disabled={
                    !projectInfo?.name || !cardInfo?.name || !taskInfo?.name
                  }
                >
                  Add
                </Button>
              </div>
            </CardContent>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default NewRowModal;
