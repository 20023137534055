import React from "react";

const Offline = (props) => {
  const { height, width } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
    >
      <g
        id="Group_42394"
        data-name="Group 42394"
        transform="translate(-3259 14675.5)"
      >
        <g
          id="Ellipse_1336"
          data-name="Ellipse 1336"
          transform="translate(3259 -14675.5)"
          fill="none"
          stroke="#999"
          strokeWidth="1.2"
        >
          <circle cx="8" cy="8" r="8" stroke="none" />
          <circle cx="8" cy="8" r="7.4" fill="none" />
        </g>
        <line
          id="Line_277"
          data-name="Line 277"
          x2="11"
          y2="10"
          transform="translate(3261.5 -14672.5)"
          fill="none"
          stroke="#999"
          strokeWidth="1.2"
        />
      </g>
    </svg>
  );
};

export default Offline;
