import React from "react";

const Document = (props) => {
  const { height = 50, width = 50 } = props;
  const className = "fill-primaryBlue";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 111 111"
      className={className}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_18664"
            data-name="Rectangle 18664"
            width="38.328"
            height="52.777"
            fill="none"
          />
        </clipPath>
      </defs>
      <g
        id="Group_43775"
        data-name="Group 43775"
        transform="translate(-741.655 -1158)"
      >
        <g
          id="Group_42562"
          data-name="Group 42562"
          transform="translate(-8176.345 -1338)"
        >
          <rect
            id="Rectangle_17764"
            data-name="Rectangle 17764"
            width="111"
            height="111"
            rx="10"
            transform="translate(8918 2496)"
          />
        </g>
        <g
          id="Group_43660"
          data-name="Group 43660"
          transform="translate(779.284 1187.402)"
        >
          <g id="Group_43659" data-name="Group 43659" clipPath="url(#clipPath)">
            <path
              id="Path_38714"
              data-name="Path 38714"
              d="M38.314,41.128h0V40.8c0-2.728-.012-25.96.007-27.636a2.213,2.213,0,0,0-.6-1.578Q32.91,6.141,28.143.659A1.731,1.731,0,0,0,26.722,0Q14.587.022,2.452.01A2.148,2.148,0,0,0,.016,2.447c0,4.981,0,32.662,0,38.355h0c0,.006,0,.012,0,.017v.238h0c0,3.006.028,6.013-.014,9.018a2.463,2.463,0,0,0,1.643,2.7H36.685a2.462,2.462,0,0,0,1.643-2.7c-.042-2.982-.016-5.965-.014-8.947M36.2,50.143h-.565q-16.481,0-32.962.009c-.464,0-.609-.112-.605-.6.022-2.833.012-5.666.013-8.5h.026V2.107H26.066v.574q0,4.818,0,9.636c0,1.038.352,1.389,1.4,1.39q4.071,0,8.141,0h.6V40.8h0Z"
              transform="translate(0 0)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Document;
