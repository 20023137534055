import React from "react";

const CreditCardBg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="335"
      height="210"
      viewBox="0 0 335 210"
      className="fill-primaryBlue rounded-[20px]"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_19627"
            data-name="Rectangle 19627"
            width="335"
            height="210"
            rx="20"
            transform="translate(1431 619)"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g
        id="Mask_Group_59"
        data-name="Mask Group 59"
        transform="translate(-1431 -619)"
        clipPath="url(#clipPath)"
      >
        <g id="Group_46034" data-name="Group 46034">
          <rect
            id="Rectangle_19626"
            data-name="Rectangle 19626"
            width="345"
            height="110"
            transform="translate(1426 616)"
            fill="#4ee5ff"
          />
          <path
            id="Path_39924"
            data-name="Path 39924"
            d="M3580.282,16390.191s301.763-104,483.3,11.42-441.223,86.563-441.223,86.563Z"
            transform="translate(-2203 -15653)"
          />
        </g>
      </g>
    </svg>
  );
};

export default CreditCardBg;
