import React from "react";

const Away = (props) => {
  const { height, width } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
    >
      <g
        id="Group_42389"
        data-name="Group 42389"
        transform="translate(-3060.859 14581.641)"
      >
        <g
          id="Ellipse_1331"
          data-name="Ellipse 1331"
          transform="translate(3060.859 -14581.641)"
          fill="none"
          stroke="#ffb805"
          strokeWidth="1"
        >
          <circle cx="8" cy="8" r="8" stroke="none" />
          <circle cx="8" cy="8" r="7.5" fill="none" />
        </g>
        <path
          id="Path_37787"
          data-name="Path 37787"
          d="M240.082,8.636a5.614,5.614,0,1,1-5.609-5.619,5.607,5.607,0,0,1,5.609,5.619m-6.172-1.4h0c0,.443,0,.886,0,1.329a.612.612,0,0,0,.342.588c.344.192.684.393,1.025.59.448.258.893.522,1.344.774a.559.559,0,1,0,.542-.978q-1.01-.589-2.026-1.169a.182.182,0,0,1-.109-.183c0-.767,0-1.535,0-2.3a.618.618,0,0,0-.128-.417.557.557,0,0,0-.605-.177.542.542,0,0,0-.384.512c-.006.478,0,.956,0,1.434"
          transform="translate(2834.392 -14582.271)"
          fill="#ffb805"
        />
      </g>
    </svg>
  );
};

export default Away;
