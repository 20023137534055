import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import firebase from "services/firebase";
import Utilities from "utils/utilities";

export const getNoteLabels = createAsyncThunk(
  "notesApp/labels/getNoteLabels",
  async (user) => {
    return await firebase.getNoteLabels(user);
  }
);

export const updateNoteLabels = createAsyncThunk(
  "notesApp/labels/updateNoteLabels",
  async ({ user, _labels }, { dispatch }) => {
    await firebase.setNoteLabels(user, _labels);

    const message = " has updated the label ";
    Utilities.successNotification(user, message, "for notes", dispatch);

    return _labels;
  }
);

const labelsAdapter = createEntityAdapter({});

export const {
  selectAll: selectLabels,
  selectEntities: selectLabelsEntities,
  selectById: selectLabelById,
} = labelsAdapter.getSelectors((state) => state.notesApp.labels);

const labelsSlice = createSlice({
  name: "notesApp/labels",
  initialState: labelsAdapter.getInitialState({}),
  reducers: {},
  extraReducers: {
    [getNoteLabels.fulfilled]: labelsAdapter.setAll,
    [updateNoteLabels.fulfilled]: labelsAdapter.setAll,
  },
});

export default labelsSlice.reducer;
