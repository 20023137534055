import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import firebase from "services/firebase";
import Utilities from "utils/utilities";

export const getBoardLabels = createAsyncThunk(
  "scrumboardApp/labels/getBoardLabels",
  async (user) => {
    const data = await firebase.getBoardLabels(user);
    return data;
  }
);

export const updateBoardLabels = createAsyncThunk(
  "scrumboardApp/labels/updateBoardLabels",
  async ({ user, _labels }, { dispatch }) => {
    const labels = [];
    Object.values(_labels).forEach((label) => {
      labels.push(label);
    });
    await firebase.setBoardLabels(user, labels);

    const message = " has updated the label ";
    Utilities.successNotification(user, message, "for scrumboards", dispatch);

    return _labels;
  }
);

const labelsAdapter = createEntityAdapter({});

export const {
  selectAll: selectBoardLabels,
  selectEntities: selectBoardLabelsEntities,
  selectById: selectLabelById,
} = labelsAdapter.getSelectors((state) => state.scrumboardApp?.labels);

const labelsSlice = createSlice({
  name: "scrumboardApp/labels",
  initialState: labelsAdapter.getInitialState([]),
  reducers: {},
  extraReducers: {
    [getBoardLabels.fulfilled]: labelsAdapter.setAll,
    [updateBoardLabels.fulfilled]: labelsAdapter.setAll,
  },
});

export default labelsSlice.reducer;
