import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import GlobalStyles from "@mui/material/GlobalStyles";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { alpha, styled } from "@mui/material/styles";
import clsx from "clsx";
import EmptyPanel from "components/display/EmptyPanel";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "store/withReducer";
import Chat from "./Chat";
import ChatsSidebar from "./ChatsSidebar";
import reducer from "./store";

import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Toggle from "components/svgs/icons/chat/Toggle";
import ToggleRightReverse from "components/svgs/icons/chat/ToggleRightReverse";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStyles } from "settings/styleOverrides";
import { selectMemberById } from "views/members/store/membersSlice";
import ChatDetails from "./ChatDetails";
import { getChat, getMediaData } from "./store/chatSlice";

const drawerWidth = 320;
const headerHeight = 200;

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  minHeight: "100%",
  position: "relative",
  flex: "1 1 auto",
  height: "auto",
  backgroundColor: theme.palette.background.default,

  "& .ChatApp-contentCardWrapper": {
    position: "relative",
    maxWidth: 1400,
    display: "flex",
    flexDirection: "column",
    flex: "1 0 auto",
    width: "100%",
    minWidth: "0",
    maxHeight: "100%",
    margin: "0 auto",
  },

  "& .ChatApp-contentCard": {
    display: "flex",
    position: "relative",
    flex: "1 1 100%",
    flexDirection: "row",
    //backgroundColor: theme.palette.background.paper,
    minHeight: 0,
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },

  "& .ChatApp-contentWrapper": {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 100%",
    zIndex: 10,
    background: `linear-gradient(to bottom, ${alpha(
      theme.palette.background.paper,
      0.8
    )} 0,${alpha(theme.palette.background.paper, 0.6)} 20%,${alpha(
      theme.palette.background.paper,
      0.8
    )})`,
  },

  "& .ChatApp-content": {
    display: "flex",
    flex: "1 1 100%",
    minHeight: 0,
  },
}));

const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => {
  return {
    "& .MuiDrawer-paper": {
      backgroundColor: theme.palette.background.default,
      maxWidth: "100%",
      overflow: "hidden",
      border: "none",
      position: "static",
      [theme.breakpoints.up("md")]: {
        position: "relative",
      },
    },
  };
});

const ChatApp = () => {
  const [rightPanelVisible, setRightPanelVisible] = useState(false);
  const [leftPanelVisible, setLeftPanelVisible] = useState(true);

  const dispatch = useDispatch();
  const classes = useStyles();
  const routeParams = useParams();
  const chat = useSelector(({ chatApp }) => chatApp.chat.chat);
  const user = useSelector(({ auth }) => auth.user);
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  const selectedContact = useSelector((state) =>
    selectMemberById(state, routeParams.selectedId)
  );

  const handleRightToggleClick = () => {
    setLeftPanelVisible(false);
    setRightPanelVisible((prevRightPanel) => !prevRightPanel);
  };

  const closeRightPanel = () => {
    setRightPanelVisible(false);
  };

  const closeLeftPanel = () => {
    setLeftPanelVisible(false);
  };

  const openLeftPanel = () => {
    setLeftPanelVisible(true);
    setRightPanelVisible(false);
  };

  const handleLeftToggleClick = () => {
    setRightPanelVisible(false);
    setLeftPanelVisible((prevLeftPanel) => !prevLeftPanel);
  };

  useEffect(() => {
    if (routeParams.selectedId) {
      dispatch(getChat({ user, contactId: routeParams.selectedId }));
      dispatch(getMediaData({ user, contactId: routeParams.selectedId }));
    }
  }, [routeParams.selectedId]);

  return (
    <>
      <GlobalStyles
        styles={(theme) => ({
          "#main": {
            height: "100vh",
          },
        })}
      />
      <Root>
        <div className={clsx("ChatApp-contentCardWrapper", "container")}>
          <div className={clsx("ChatApp-contentCard", "shadow rounded-0")}>
            <StyledSwipeableDrawer
              className={`${
                isMd && !leftPanelVisible ? "hidden" : "h-full z-20"
              } `}
              variant="permanent"
              open
              onOpen={(ev) => {}}
              onClose={(ev) => {}}
            >
              <ChatsSidebar closeLeftPanel={closeLeftPanel} classes={classes} />
            </StyledSwipeableDrawer>

            <Paper
              square
              elevation={3}
              className={`${
                isSm && (rightPanelVisible || leftPanelVisible)
                  ? " hidden w-0"
                  : "flex ChatApp-contentWrapper h-[90%] lg:w-[300px] xl:w-[400px] 2xl:w-[700px] 3xl:w-[900px] flex justify-center sm:mx-1 sm:my-[32px] 2xl:my-[32px] z-10"
              } `}
            >
              {!chat || !selectedContact ? (
                <EmptyPanel
                  mainText={"No chat, yet?"}
                  imgClass={""}
                  subText={"Choose a contact to start communicating with them."}
                  imageSrc={"emptyChat"}
                  buttonText={isSm ? "Create a chat" : ""}
                  onBtnClick={() => openLeftPanel()}
                  imgAlt={"Empty Chat"}
                />
              ) : (
                <>
                  <AppBar
                    style={{ background: "#0000", color: "inherit" }}
                    className={`w-full ${
                      isSm && (rightPanelVisible || leftPanelVisible)
                        ? "hidden"
                        : ""
                    }`}
                    elevation={0}
                    position="static"
                  >
                    <Toolbar className="flex justify-between px-0 py-4 border-b">
                      <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        onClick={() => handleLeftToggleClick()}
                        className={`flex px-0  ${isMd ? "" : "hidden"}`}
                        size="large"
                      >
                        <div className={`ml-1 ${isMd ? "" : "hidden"}`}>
                          <ToggleRightReverse />
                        </div>
                      </IconButton>
                      <div className="flex items-center lg:ml-4 " tabIndex={0}>
                        <div className="relative mr-4">
                          <Avatar
                            className="w-[42px] h-[42px] ml-2"
                            src={selectedContact?.photoURL}
                            alt={selectedContact?.displayName}
                            style={{
                              backgroundColor: selectedContact?.more?.color,
                            }}
                          >
                            {selectedContact?.displayName &&
                              selectedContact?.displayName[0]?.toLocaleUpperCase()}
                          </Avatar>
                        </div>
                        <Typography className="text-[20px] font-semibold text-primaryBlue">
                          {selectedContact.displayName}
                        </Typography>
                      </div>
                      <div
                        onClick={handleRightToggleClick}
                        className="cursor-pointer"
                      >
                        <Toggle rightPanelVisible={rightPanelVisible} />
                      </div>
                    </Toolbar>
                  </AppBar>

                  <div
                    className={` ${
                      isSm && (rightPanelVisible || leftPanelVisible)
                        ? "hidden w-0"
                        : "w-inherit ChatApp-content"
                    } `}
                  >
                    <Chat
                      classes={classes}
                      className={` ${
                        isSm && (rightPanelVisible || leftPanelVisible)
                          ? "hidden"
                          : "flex flex-1 z-10"
                      }`}
                    />
                  </div>
                </>
              )}
            </Paper>
            {rightPanelVisible && (
              <ChatDetails
                isVisible={rightPanelVisible}
                closeDetails={closeRightPanel}
                contact={selectedContact}
                user={user}
                classes={classes}
              />
            )}
          </div>
        </div>
      </Root>
    </>
  );
};

export default withReducer("chatApp", reducer)(ChatApp);
