import { Backdrop, CircularProgress, Paper, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { lazy, useState } from "react";
import Utilities from "utils/utilities";
import TabHeader from "../components/TabHeader";
import { postApi } from "api/postApi";
const Apple = lazy(() => import("components/svgs/apps/Apple"));
const GooglePlay = lazy(() => import("components/svgs/apps/GooglePlay"));
const Desktop = lazy(() => import("components/svgs/apps/Desktop"));
const androidApp =
  "https://play.google.com/store/apps/details?id=com.proman365.app.twa";
const iosApp = "https://apps.apple.com/us/app/proman365/id6451008303";

const AppCenterTab = (props) => {
  const { user, goBack, dispatch } = props;
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const workspaceInfo = user?.workspaces?.find(
    (x) => x.id === user?.data.currentWorkspace
  );
  const currentPlan = workspaceInfo?.plan;

  const downloadElectronApp = async () => {
    setLoadingSpinner(true);
    const response = await postApi(
      { platform: window.navigator.platform },
      { api: "downloadElectron" }
    );
    setLoadingSpinner(false);

    if (response.statusCode !== 200 && !response?.body?.url) {
      Utilities.errorNotification(user, dispatch);
      return;
    }
    Utilities.successNotification(
      user,
      "Your download for Desktop App was ",
      "successfull.",
      dispatch
    );
    redirect_url(response.body.url);
  };

  const redirect_url = (url) => {
    window.open(url, "_blank");
  };

  return (
    <motion.div
      className="w-full m-6"
      variants={Utilities.containerConfig(0.05)}
      initial="hidden"
      animate="show"
    >
      {loadingSpinner ? (
        <Backdrop sx={{ color: "#fff" }} open={loadingSpinner}>
          <CircularProgress color="info" className="m-auto" />
        </Backdrop>
      ) : (
        <>
          <TabHeader
            onClick={goBack}
            title={"App Center"}
            subtitle="You can download your apps from here."
          />
          <div className="flex flex-1 mt-4 xs:justify-center md:justify-start">
            <div className="md:flex xs:grid xs:grid-cols-1 xmd:grid-cols-2 miniTab:grid-cols-3 xs:gap-6">
              {currentPlan !== "Starter" && currentPlan !== "Pro" && (
                <Paper
                  onClick={() => downloadElectronApp()}
                  className="flex flex-col justify-center items-center w-[200px] h-[200px] py-10 cursor-pointer shadow-md shadow-blueTransparent"
                  elevation={2}
                >
                  <Desktop />
                  <Typography className="mt-4 xs:text-base miniTab:text-lg md:text-xl font-medium text-grayText">
                    Desktop App
                  </Typography>
                </Paper>
              )}
              <Paper
                onClick={() => redirect_url(androidApp)}
                className="flex flex-col justify-center items-center w-[200px] h-[200px] py-10 cursor-pointer shadow-md shadow-blueTransparent"
                elevation={2}
              >
                <GooglePlay />
                <Typography className="mt-4 xs:text-base miniTab:text-lg md:text-xl font-medium text-grayText">
                  Google Play
                </Typography>
              </Paper>
              <Paper
                onClick={() => redirect_url(iosApp)}
                className="flex flex-col justify-center items-center w-[200px] h-[200px] py-10 cursor-pointer shadow-md shadow-blueTransparent"
                elevation={2}
              >
                <Apple />
                <Typography className="mt-4 xs:text-base miniTab:text-lg md:text-xl font-medium text-grayText">
                  Apple Store
                </Typography>
              </Paper>
            </div>
          </div>
        </>
      )}
    </motion.div>
  );
};

export default AppCenterTab;
