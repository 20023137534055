import { AppBar, Icon, Input, Paper, Toolbar } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useMemo, useState } from "react";
import ChatContacts from "./ChatContacts";
import { useStyles } from "settings/styleOverrides";

const ContactList = (props) => {
  const { setContactListOpen } = props;
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [searchText, setSearchText] = useState("");

  const handleSearchText = (event) => {
    setSearchText(event.target.value);
  };

  return (
    <Paper
      square
      className={`${
        isSm ? "max-w-[300px]" : " w-[650px]"
      } chatSideBarClass flex flex-col flex-auto h-full`}
    >
      <AppBar
        position="static"
        style={{ background: "#0000", color: "inherit" }}
        elevation={0}
      >
        {useMemo(
          () => (
            <Toolbar className="px-2 mx-4 border-b-2">
              <Icon className="text-primaryBlue">search</Icon>
              <Input
                placeholder="Search"
                className="flex flex-1 px-4"
                disableUnderline
                fullWidth
                value={searchText}
                inputProps={{
                  "aria-label": "Search",
                  className: classes.input + " !p-2",
                }}
                onChange={handleSearchText}
              />
            </Toolbar>
          ),
          [searchText]
        )}
      </AppBar>

      <ChatContacts
        isMobile={isMobile}
        searchText={searchText}
        sideChat={true}
        setContactListOpen={setContactListOpen}
      />
    </Paper>
  );
};

export default ContactList;
