import React from "react";

const Word = () => {
  const className = "fill-primaryBlue";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="45"
      viewBox="0 0 45 45"
      className={className}
    >
      <g
        id="Group_42556"
        data-name="Group 42556"
        transform="translate(-1410 -885)"
      >
        <circle
          id="Ellipse_1352"
          data-name="Ellipse 1352"
          cx="22.5"
          cy="22.5"
          r="22.5"
          transform="translate(1410 885)"
        />
        <path
          id="Path_37845"
          data-name="Path 37845"
          d="M0,17.525V3.357a.822.822,0,0,1,.635-.486c1.2-.211,2.4-.444,3.592-.668q3.166-.595,6.333-1.189a.626.626,0,0,1,.772.622c.008.367,0,.734,0,1.1v.778h8.043c.546,0,.773.229.773.779q0,6.148,0,12.3c0,.552-.225.779-.772.779H11.334c0,.626,0,1.223,0,1.819a.638.638,0,0,1-.812.674q-4.9-.916-9.791-1.828A.868.868,0,0,1,0,17.525M18.877,16.1V4.783h-7.53v1.25h5.5a1.972,1.972,0,0,1,.216.005.627.627,0,0,1,.062,1.24,1.319,1.319,0,0,1-.255.014h-5.52V8.552h5.484a2,2,0,0,1,.236.006A.627.627,0,0,1,17.116,9.8a1.443,1.443,0,0,1-.255.012H11.345v1.259h5.5a2.116,2.116,0,0,1,.216,0,.627.627,0,0,1,.07,1.239,1.389,1.389,0,0,1-.274.015H11.348v1.259h5.5a1.9,1.9,0,0,1,.216.005.627.627,0,0,1,.054,1.24,1.365,1.365,0,0,1-.255.013H11.344V16.1ZM4.084,10.976l-.037-.01c-.01-.05-.023-.1-.03-.151-.08-.564-.158-1.129-.241-1.693a.63.63,0,1,0-1.248.171c.2,1.447.408,2.893.629,4.337a.786.786,0,0,0,.249.445.619.619,0,0,0,.995-.35c.4-1.047.787-2.1,1.181-3.143.023-.061.05-.12.09-.218.04.1.062.159.082.214q.59,1.572,1.18,3.144a.637.637,0,0,0,1.258-.166q.17-1.5.34-3.008.14-1.25.277-2.5a.633.633,0,1,0-1.254-.135Q7.5,8.44,7.437,8.967c-.069.616-.138,1.231-.212,1.9-.042-.088-.067-.132-.084-.177-.286-.76-.568-1.521-.856-2.28a.654.654,0,0,0-1.237,0c-.074.189-.141.381-.213.571l-.752,2"
          transform="translate(1422 897)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default Word;
