import { Avatar, AvatarGroup, Card, Typography } from "@mui/material";
import StatusIcon from "components/StatusIcon";
import { formatDistanceToNow } from "date-fns";
import NotificationIcon from "./NotificationIcon";

const NotificationCard = (props) => {
  const { item, className } = props;
  const {
    message,
    status,
    name,
    color,
    photoUrl,
    preHighlightedClass,
    preHighlightedMessage,
    postHighlightedMessage,
    postHighlightedClass,
    messageClass,
    created,
  } = item;
  const { variant, showAvatar, showAvatarGroup, showNotificationIcon } =
    item?.options;

  const handleClose = () => {
    if (props.onClose) {
      props.onClose(item.id);
    }
  };

  if (variant === "new-default") {
    return (
      <Card className="flex rounded-none px-4 xs:py-4 md:py-6 my-4 gap-4 border-primaryBlue border-2">
        <div className="flex justify-center items-center">
          {showNotificationIcon ? (
            <NotificationIcon value="error" />
          ) : (
            <div className="relative">
              <div className="absolute right-3 bottom-2 -m-4 z-10">
                <StatusIcon status={status} needBg />
              </div>
              <Avatar
                src={photoUrl}
                className="h-[42px] w-[42px]"
                style={{ backgroundColor: color }}
                alt="avatar"
              >
                {name && name[0]?.toLocaleUpperCase()}
              </Avatar>
            </div>
          )}
        </div>
        <div className="text-container flex flex-col justify-center">
          <Typography className="xs:text-sm md:text-base">
            <span className="text-medium font-bold">{name}</span>
            {preHighlightedMessage && (
              <span
                className={`${
                  preHighlightedClass
                    ? preHighlightedClass
                    : "text-primaryBlue font-medium"
                }`}
              >
                {preHighlightedMessage}
              </span>
            )}
            {message && (
              <span
                className={`${className ? className : "text-graytext"} ${
                  messageClass ? messageClass : ""
                }`}
              >
                {message}
              </span>
            )}
            {postHighlightedMessage && (
              <span
                className={`${
                  postHighlightedClass
                    ? postHighlightedClass
                    : "text-primaryBlue font-bold"
                }`}
              >
                {postHighlightedMessage}
              </span>
            )}
          </Typography>
          <Typography className="xs:text-xs md:text-sm text-grayText mt-2">
            {formatDistanceToNow(created, {
              addSuffix: true,
            })}
          </Typography>
        </div>
        {showAvatarGroup && (
          <div className="flex justify-center items-center ml-4">
            <AvatarGroup spacing={"small"}>
              <Avatar
                className="xs:h-[36px] xs:w-[36px] md:h-[42px] md:w-[42px]"
                alt="avatar"
              ></Avatar>
              <Avatar
                className="xs:h-[36px] xs:w-[36px] md:h-[42px] md:w-[42px]"
                alt="avatar"
              ></Avatar>
              <Avatar
                className="xs:h-[36px] xs:w-[36px] md:h-[42px] md:w-[42px]"
                alt="avatar"
              ></Avatar>
            </AvatarGroup>
          </div>
        )}
        {showAvatar && (
          <div className="flex justify-center items-center ml-auto">
            <Avatar
              className="xs:h-[36px] xs:w-[36px] md:h-[42px] md:w-[42px]"
              alt="avatar"
            ></Avatar>
          </div>
        )}
      </Card>
    );
  }
};

export default NotificationCard;
