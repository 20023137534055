import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import firebase from "services/firebase";
import Utilities from "utils/utilities";

export const getDiscussionsTopic = createAsyncThunk(
  "discussionApp/discussions/getDiscussionsTopic",
  async ({ user, projectId }) => {
    const data = await firebase.getDiscussionsTopic(user, projectId);
    return Promise.resolve(data);
  }
);

export const getDiscussionsTopicById = createAsyncThunk(
  "discussionApp/discussions/getDiscussionsTopicById",
  async ({ user, projectId, topicId }) => {
    return await firebase.getDiscussionsTopicById(user, projectId, topicId);
  }
);

export const getDiscussionsMediaData = createAsyncThunk(
  "discussionApp/discussions/getDiscussionsMediaData",
  async ({ user, projectId, topicId }) => {
    return await firebase.getDiscussionsMediaData(user, projectId, topicId);
  }
);

export const createNewTopic = createAsyncThunk(
  "discussionApp/discussions/createNewTopic",
  async ({ user, projectId, data }, { dispatch }) => {
    const response = await firebase.createTopicForDiscussion(
      user,
      projectId,
      data
    );

    const message = " has created new topic ";
    Utilities.successNotification(user, message, data.name, dispatch);

    return response;
  }
);

export const updateTopic = createAsyncThunk(
  "discussionApp/discussions/updateTopic",
  async ({ user, projectId, data }, { dispatch }) => {
    await firebase.updateTopicForDiscussion(user, projectId, data);

    const message = " has updated the topic ";
    Utilities.successNotification(user, message, data.name, dispatch);

    return data;
  }
);

export const deleteTopic = createAsyncThunk(
  "discussionApp/discussions/deleteTopic",
  async ({ user, projectId, data }, { dispatch }) => {
    await firebase.deleteTopicForDiscussion(user, projectId, data.id);

    const message = " has deleted the topic ";
    Utilities.successNotification(user, message, data.name, dispatch);

    return Promise.resolve(data.id);
  }
);

export const sendMessage = createAsyncThunk(
  "discussionApp/discussions/sendMessage",
  async ({ messageText, user, projectId, topicId, attachment }) => {
    const message = {
      who: user.uid,
      message: messageText,
      time: new Date(),
      attachment: [],
    };
    const data2 = await firebase.updateDiscussionRealtime(
      user,
      projectId,
      topicId,
      message,
      attachment
    );
    return data2;
  }
);

const discussionsAdapter = createEntityAdapter({
  media: [],
});

export const {
  selectAll: selectDiscussions,
  selectById: selectDiscussionById,
} = discussionsAdapter.getSelectors((state) => state.discussionApp);

const discussionsSlice = createSlice({
  name: "discussionApp/discussions",
  initialState: discussionsAdapter.getInitialState(),
  reducers: {},
  extraReducers: {
    [getDiscussionsTopic.fulfilled]: discussionsAdapter.setAll,
    [getDiscussionsMediaData.fulfilled]: (state, action) => {
      state.media = action.payload;
    },
    [createNewTopic.fulfilled]: discussionsAdapter.addOne,
    [getDiscussionsTopicById.fulfilled]: discussionsAdapter.upsertOne,
    [updateTopic.fulfilled]: discussionsAdapter.upsertOne,
    [deleteTopic.fulfilled](state, { payload: id }) {
      discussionsAdapter.removeOne(state, id);
    },
  },
});

export default discussionsSlice.reducer;
