import { authRoles } from "auth";
import { lazy } from "react";
const Onboarding = lazy(() => import("views/onboarding/Onboarding"));
const CreateWorkspace = lazy(() => import("./auth/CreateWorkspace"));
const ForgotPassword = lazy(() => import("./auth/ForgotPassword"));
const Login = lazy(() => import("./auth/Login"));
const Maintenance = lazy(() => import("./auth/Maintenance"));
const Register = lazy(() => import("./auth/Register"));
const ResetPassword = lazy(() => import("./auth/ResetPassword"));
const ScreenLock = lazy(() => import("./auth/ScreenLock"));
const Verification = lazy(() => import("./auth/Verification"));

const GuestRoutesConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "register",
      element: <Register />,
    },
    {
      path: "lock",
      element: <ScreenLock />,
      auth: authRoles.user,
    },
    {
      path: "/:workspaceId/onBoarding",
      element: <Onboarding />,
      auth: authRoles.user,
    },
    {
      path: "forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "verification",
      element: <Verification />,
      auth: authRoles.user,
    },
    {
      path: "createWorkspace",
      element: <CreateWorkspace />,
      auth: authRoles.user,
    },
    {
      path: "__/auth/action",
      element: <ResetPassword />,
    },
    {
      path: "maintenance",
      element: <Maintenance />,
      auth: authRoles.user,
    },
  ],
};

export default GuestRoutesConfig;

/**
 * Lazy load Example
 */
/*
import React, { lazy } from 'react';
const Example = React.lazy(() => import('./Example'));

const ExampleConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : 'example',
            element: <Example/>
        }
    ]
};

export default ExampleConfig;

*/
