import React from "react";
import { Button } from "@mui/material";
import Reload from "./svgs/icons/general/Reload";

const LoadMoreButton = (props) => {
  const { onBtnClick } = props;
  return (
    <div className="flex justify-center m-4">
      <Button
        onClick={onBtnClick}
        type="button"
        className=" w-fit rounded-[5px] mt-3 bg-primaryBlue hover:bg-primaryBlueLight text-sm px-8 py-3 text-white w-full"
      >
        <Reload />
        Load More
      </Button>
    </div>
  );
};

export default LoadMoreButton;
