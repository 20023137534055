import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { StyledEngineProvider } from "@mui/material/styles";
import { ErrorBoundary } from "react-error-boundary";
import Provider from "react-redux/es/components/Provider";
import ErrorApp from "views/errors/ErrorApp";
import AppContext from "./AppContext";
import routes from "./configs/routes";
import store from "./store";

const withAppProviders = (Component) => (props) => {
  const WrapperComponent = () => (
    <AppContext.Provider
      value={{
        routes,
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Provider store={store}>
          <StyledEngineProvider injectFirst>
            <ErrorBoundary FallbackComponent={ErrorApp}>
              <Component {...props} />
            </ErrorBoundary>
          </StyledEngineProvider>
        </Provider>
      </LocalizationProvider>
    </AppContext.Provider>
  );

  return WrapperComponent;
};

export default withAppProviders;
