import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { motion } from "framer-motion";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Utilities from "utils/utilities";
import "views/onboarding/InviteUsers";
import { getInvitedMembers } from "views/onboarding/store/invitationSlice";
import PlanCard from "../components/PlanCard";
import PlanDetails from "../components/PlanDetails";
import TabHeader from "../components/TabHeader";
import PlanHistoryTab from "./PlanHistoryTab";

const plans = [
  {
    id: 0,
    title: "Starter",
    subtitle: "Plan for starters",
    color: "#28a067",
    billMonthly: "$0 per month",
    billYearly: "$0 yearly",
    smallDetail:
      "This plan is for individuals or teams just getting started with project management.",
    description: "This plan includes features such as:",
    features: [
      "1 Workspace limit",
      "Invite up to 5 Members",
      "Unlimited Projects",
      "Unlimited Boards",
      "Up to 5 Custom Fields",
      "Limited Dashboard Widgets",
      "Calendar View",
      "Project Overview",
      "Basic Reporting",
      "Chat & Collaborate",
      "Time Tracker",
      "Private Notes",
      "iOS and Android Apps",
    ],
  },
  {
    id: 1,
    title: "Pro",
    subtitle: "For professional",
    color: "#ef3f8f",
    billMonthly: "USD $8 per member per month",
    billYearly: "USD $5 per member yearly",
    smallDetail:
      "This plan is for small teams that need to manage work and scale collaboration.",
    description:
      "This plan includes everything in Basic plus features such as:",
    features: [
      "2 Workspace limit",
      "Invite up to 25 Members",
      "Up to 10 Custom Fields",
      "All Dashboard Widgets",
      "Advanced Reporting",
      "Group Discussions",
      "Generate Invoice",
      "Define User Roles",
      "Project Archiving",
      "Support 9-5 EST",
    ],
  },
  {
    id: 2,
    title: "Business",
    subtitle: "For serious people",
    color: "#6970cc",
    billMonthly: "USD $15 per member per month",
    billYearly: "USD $12 per member yearly",
    smallDetail:
      "This plan is for mid to large size teams that need to manage work and scale collaboration.",
    description: "This plan includes everything in Pro plus features such as:",
    features: [
      "Unlimited Workspaces",
      "Unlimited Members",
      "Unlimited Custom Fields",
      "Custom Domain",
      "Share notes among teams",
      "iOS, Android & Desktop Apps",
      "24/7 Support",
    ],
  },
  {
    id: 3,
    title: "Enterprise",
    subtitle: "For big companies",
    color: "#e58e6c",
    billMonthly: "Contact Suport",
    billYearly: "Contact Suport",
    smallDetail:
      "This plan is for teams that need extra support, security & custom features built.",
    description:
      "This plan includes everything in Business plus features such as:",
    features: [
      "Custom Branding",
      "Custom Features",
      "Documentation & Whiteboard",
      "Access to APIs",
      "Enhanced Security",
      "Advanced Permissions",
    ],
  },
];

const PlanTab = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tabNumber, setTabNumber] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [cardSelected, setCardSelected] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState(plans[0]);
  //const [subscriptionDetails, setSubscriptionDetails] = useState({});

  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const user = useSelector(({ auth }) => auth.user);
  const subscriptionDetails = useSelector(
    ({ entities }) => entities.subscription
  );
  const darkMode = useSelector(
    ({ entities }) => entities.settings.current.darkMode
  );

  const selectPlan = (plan) => {
    setCardSelected(plan.id);
    setSelectedPlan(plan);
  };

  useEffect(() => {
    dispatch(getInvitedMembers(user));
  }, []);

  useEffect(() => {
    if (_.isEmpty(subscriptionDetails)) return;
    const planNicname = subscriptionDetails?.subscription?.plan?.nickname;
    if (planNicname) {
      const index =
        planNicname == "Starter"
          ? 0
          : planNicname == "Pro"
          ? 1
          : planNicname == "Business"
          ? 2
          : 3;
      setCardSelected(index);
      setSelectedPlan(plans[index]);
    }
  }, [subscriptionDetails]);

  return (
    <motion.div
      className="m-6"
      variants={Utilities.containerConfig(0.05)}
      initial="hidden"
      animate="show"
    >
      <TabHeader
        onClick={() => navigate(`/${user.data.currentWorkspace}/settings`)}
        title={"Plan & Billing"}
        subtitle="Update your plan & billing related settings"
      />
      <div
        className={`flex ${
          mdDown ? "flex-col" : "flex-row"
        } w-full content-container`}
      >
        <div
          className={`left-side ${
            mdDown ? "w-full mt-4 border-b-2" : "pb-6 border-r-2 w-[25%]"
          }  `}
        >
          <div
            onClick={() => setTabNumber(0)}
            className={`cursor-pointer flex items-center px-3 py-6  ${
              tabNumber === 0 ? "bg-white shadow-md shadow-blueTransparent" : ""
            }`}
          >
            {tabNumber === 0 ? (
              <ArrowDropUpIcon className="text-primaryBlue" />
            ) : (
              <ArrowDropDownIcon className="text-primaryBlue" />
            )}

            <Typography className="ml-2 text-base font-semibold text-grayText">
              Choose Plan
            </Typography>
          </div>
          <div
            onClick={() => setTabNumber(1)}
            className={`cursor-pointer flex items-center px-3 py-6 ${
              tabNumber === 1 ? "bg-white shadow-md shadow-blueTransparent" : ""
            }`}
          >
            {tabNumber === 1 ? (
              <ArrowDropUpIcon className="text-primaryBlue" />
            ) : (
              <ArrowDropDownIcon className="text-primaryBlue" />
            )}
            <Typography className="ml-2 text-base font-semibold text-grayText">
              Billing
            </Typography>
          </div>
        </div>
        <div className={`right-side  ${mdDown ? "w-full" : "w-[75%]"}`}>
          {tabNumber === 0 && (
            <>
              <div
                className={` grid ${
                  mdDown ? "grid-cols-2 mx-0 my-5" : "grid-cols-4 m-5"
                }  gap-4`}
              >
                {plans.map((plan, idx) => (
                  <PlanCard
                    key={idx}
                    selected={cardSelected === plan.id}
                    onClick={() => selectPlan(plan)}
                    title={plan.title}
                    subtitle={plan.subtitle}
                    color={plan.color}
                    learnMore={() => setModalOpen(true)}
                    darkMode={darkMode}
                  />
                ))}
              </div>
              {cardSelected !== -1 && (
                <PlanDetails
                  setTabNumber={setTabNumber}
                  selectedPlan={selectedPlan}
                  subscriptionDetails={subscriptionDetails}
                  darkMode={darkMode}
                  user={user}
                />
              )}
            </>
          )}
          {tabNumber === 1 && <PlanHistoryTab user={user} />}
        </div>
      </div>
      {/* <PlanModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        icon={selectedPlan.icon}
        planName={selectedPlan.title}
        planSubtitle={selectedPlan.subtitle}
        billMonthly={selectedPlan.billMonthly}
        billYearly={selectedPlan.billYearly}
        smallDetail={selectedPlan.smallDetail}
        description={selectedPlan.description}
      /> */}
    </motion.div>
  );
};

export default PlanTab;
