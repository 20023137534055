import {
  Avatar,
  Box,
  Modal,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import { maxWidth } from "@mui/system";
import StatusIcon from "components/StatusIcon";
import {
  StyledTableCell,
  StyledTableRow,
  modalstyle,
} from "settings/styleOverrides";
import Utilities from "utils/utilities";
import _ from "utils/lodash";
import { useSelector } from "react-redux";
import Close from "components/svgs/icons/general/Close";

const TeamMembersModal = (props) => {
  const { open, handleClose, members } = props;
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));
  const darkMode = useSelector(
    ({ entities }) => entities.settings.current.darkMode
  );

  const style = modalstyle();
  style.width = isSmall ? "90%" : mdDown ? "80%" : lgDown ? "65%" : "50%";
  style.height = isSmall ? 650 : lgDown ? 650 : 730;

  return (
    <Modal
      BackdropProps={{ style: { backgroundColor: "grey", opacity: "0.4" } }}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-team-member"
      aria-describedby="modal-project-team-members"
    >
      <Box sx={style} className="modal-container flex flex-col my-5">
        <div className="modal-header bg-primaryBlue h-[75px] flex flex-col justify-center">
          <div className="flex justify-between px-8">
            <Typography className="text-white text-[20px]">
              Team Member
            </Typography>
            <Box onClick={handleClose} className="cursor-pointer">
              <Close />
            </Box>
          </div>
        </div>
        <div
          className={`content-container ${
            darkMode ? "" : "bg-lightBg"
          } h-full ${isSmall ? "px-1" : "px-8"} `}
        >
          <div className="members-container overflow-x-auto mt-6">
            <TableContainer>
              <Table
                sx={{
                  width: isSmall ? 385 : maxWidth,
                  maxWidth: 900,
                  borderCollapse: "separate",
                  borderSpacing: "0px 1rem",
                }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      style={{ paddingTop: "0", paddingBottom: "0" }}
                    >
                      <Typography
                        className={`font-medium ${
                          isSmall ? "text-[16px]" : "text-[18px]"
                        } `}
                      >
                        Name
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ paddingTop: "0", paddingBottom: "0" }}
                    >
                      <Typography
                        className={`font-medium ${
                          isSmall ? "text-[16px]" : "text-[18px]"
                        } `}
                      >
                        Role
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ paddingTop: "0", paddingBottom: "0" }}
                    >
                      <Typography
                        className={`font-medium ${
                          isSmall ? "text-[16px]" : "text-[18px]"
                        } `}
                      >
                        Status
                      </Typography>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {members &&
                    members.map((row) => (
                      <StyledTableRow className="cursor-pointer" key={row?.id}>
                        <StyledTableCell
                          style={{ width: isSmall ? "10%" : "40%" }}
                        >
                          <div className="flex items-center">
                            <Avatar
                              className={`${
                                isSmall ? " h-[30px] w-[30px]" : ""
                              }`}
                              src={row?.photoURL}
                              style={{ backgroundColor: row?.more?.color }}
                              alt="avatar"
                            >
                              {row?.displayName &&
                                row.displayName[0].toLocaleUpperCase()}
                            </Avatar>
                            <Typography
                              className={`font-medium ml-3 ${
                                isSmall
                                  ? "text-[14px] truncate max-w-[70px]"
                                  : "text-[18px]"
                              }`}
                            >
                              {row.displayName}
                            </Typography>
                          </div>
                        </StyledTableCell>
                        <StyledTableCell style={{ width: "30%" }}>
                          <Typography
                            className={`font-medium ${
                              isSmall ? "text-[14px ]" : "text-[18px]"
                            } `}
                          >
                            Contributor
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ width: "60%" }}
                          className={`flex justify-center items-center ${Utilities.getUserStatusBgClassName(
                            row.status
                          )} mt-3 py-2 px-8 rounded`}
                        >
                          <StatusIcon
                            width={"23px"}
                            height={"23px"}
                            status={row.status}
                          />
                          <Typography
                            className={`${Utilities.getUserStatusClassName(
                              row.status
                            )} text-sm ml-2`}
                          >
                            {_.startCase(row.status)}
                          </Typography>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default TeamMembersModal;
