import React from "react";

const CreditCard = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="56"
      viewBox="0 0 60.322 37.901"
    >
      <path
        id="Path_39926"
        data-name="Path 39926"
        d="M18.358,37.9A17.885,17.885,0,0,1,4.8,31.611,18.968,18.968,0,0,1,4.984,6.154,19.166,19.166,0,0,1,19,.037,19.605,19.605,0,0,1,30.044,3.527,20.3,20.3,0,0,1,41.374,0,18.871,18.871,0,0,1,56.2,7.161a19.153,19.153,0,0,1-.849,24.711,17.664,17.664,0,0,1-13.252,6,23.5,23.5,0,0,1-11.929-3.622A22.707,22.707,0,0,1,18.358,37.9ZM19,6.385a12.6,12.6,0,0,0-9.474,20.99,11.554,11.554,0,0,0,8.835,4.177,17.446,17.446,0,0,0,9.932-3.67l1.816-1.3,1.838,1.271A18.342,18.342,0,0,0,42.1,31.527a11.325,11.325,0,0,0,8.576-3.948,12.758,12.758,0,0,0,.56-16.461,12.556,12.556,0,0,0-9.86-4.771,14.486,14.486,0,0,0-9.309,3.586L30.011,11.65,27.973,9.916A13.66,13.66,0,0,0,19,6.385Z"
        transform="translate(0.016 0)"
        fill="#fff"
      />
    </svg>
  );
};

export default CreditCard;
