import { combineReducers } from "@reduxjs/toolkit";
import chat from "./chatSlice";
import chats from "./chatsSlice";

const reducer = combineReducers({
  chats,
  chat,
});

export default reducer;
