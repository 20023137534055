const Dashboard = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 25.362 25.362"
    >
      <g id="metrics" transform="translate(0.5 0.5)">
        <circle
          id="Ellipse_1"
          data-name="Ellipse 1"
          cx="4.378"
          cy="4.378"
          r="4.378"
          transform="translate(0.5 15.106)"
          fill="none"
          stroke="#6e6e6e"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_13"
          data-name="Path 13"
          d="M2.5,6.871V.5A19.114,19.114,0,0,1,21.614,19.614H15.243"
          transform="translate(2.248)"
          fill="none"
          stroke="#6e6e6e"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          id="Line_6"
          data-name="Line 6"
          y1="5.492"
          x2="5.492"
          transform="translate(8.298 10.572)"
          fill="none"
          stroke="#6e6e6e"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export default Dashboard;
