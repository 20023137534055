import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { leaveWorkspace } from "api/leaveWorkspace";
import { postApi } from "api/postApi";
import {
  logoutUser,
  setUserData,
  setUserWorkspaces,
  updateWorkspaceUserData,
} from "auth/store/userSlice";
import firebase from "services/firebase";
import Utilities from "utils/utilities";

const uploadLogoAttachment = async (user, data, logo, isUpdateLogoDirty) => {
  if (logo == "" || !isUpdateLogoDirty) return data;

  const path = `${user.data.currentWorkspace}`;
  if (logo?.file) {
    const url = await firebase.uploadAttachment(path, logo.file);
    data.logo = url;
  }
  return data;
};

export const updateRootUserData =
  ({ user, workspaces, displayName }) =>
  async (dispatch) => {
    return firebase
      .updateUserData(user, workspaces, displayName)
      .catch((error) => Utilities.errorNotification(user, dispatch, error));
  };

export const getWorkspaceById = createAsyncThunk(
  "settingsApp/settings/getWorkspaceById",
  async (user) => {
    return await firebase.getWorkspaceById(user.data.currentWorkspace);
  }
);

export const updateGeneralSettings = createAsyncThunk(
  "settingsApp/settings/updateGeneralSettings",
  async (
    {
      user,
      isWorkspaceNameUpdated,
      newWorkspaceName,
      newDefaultWorkspace,
      logo,
      isUpdateLogoDirty,
      newDisplayName,
      themeColor,
      darkMode,
      chatSidebar,
    },
    { dispatch }
  ) => {
    let updatedWorkspaces = user.workspaces,
      data = user.data;
    if (isWorkspaceNameUpdated || isUpdateLogoDirty) {
      const workspaces = user.workspaces.map((workspace) => {
        return { ...workspace };
      });

      updatedWorkspaces = workspaces.map((workspace) => {
        if (newDefaultWorkspace)
          workspace.is_default = workspace.id === newDefaultWorkspace;
        if (
          workspace.id == user.data.currentWorkspace &&
          isWorkspaceNameUpdated
        )
          workspace.name = newWorkspaceName;
        return workspace;
      });

      let result = { name: newWorkspaceName, modifiedDate: new Date() };
      result = await uploadLogoAttachment(
        user,
        result,
        logo,
        isUpdateLogoDirty
      );
      await firebase.updateWorkspaceName(user, result);
    } else {
      const theme = Utilities.getDarkTheme(darkMode);
      data = {
        ...user.data,
        displayName: newDisplayName,
        settings: {
          ...user.data.settings,
          themeColor,
          darkMode,
          chatSidebar,
          theme,
        },
      };
    }

    const newUser = {
      ...user,
      workspaces: updatedWorkspaces,
      data,
    };

    try {
      dispatch(
        updateRootUserData({
          user,
          workspaces: updatedWorkspaces,
          displayName: isWorkspaceNameUpdated
            ? user.data.displayName
            : newDisplayName,
        })
      );

      isWorkspaceNameUpdated
        ? dispatch(setUserWorkspaces(updatedWorkspaces))
        : dispatch(updateWorkspaceUserData(newUser));

      dispatch(setUserData(newUser));

      const message = " has updated the user settings successfully ";
      Utilities.successNotification(user, message, null, dispatch);

      return Promise.resolve({ id: user.data.currentWorkspace });
    } catch (error) {
      Utilities.errorNotification(user, error, dispatch);
    }
  }
);

export const getCustomFields = createAsyncThunk(
  "settingsApp/settings/getCustomFields",
  async ({ user, type, project }) => {
    const data = await firebase.getCustomFields(
      user.data.currentWorkspace,
      type,
      project
    );
    return Promise.resolve({
      data: data?.customFields || [],
      projectName: project?.projectName || "",
      type,
    });
  }
);

export const addCustomFields = createAsyncThunk(
  "settingsApp/settings/addCustomFields",
  async ({ user, type, data, projectId }, { dispatch }) => {
    await firebase.addCustomFields(
      user.data.currentWorkspace,
      type,
      data,
      projectId
    );

    const message = " has updated the custom fields settings successfully ";
    Utilities.successNotification(user, message, null, dispatch);

    return {
      data: data?.customFields || [],
      projectName: data.projectName,
      type,
    };
  }
);

export const leaveWorkspaceThunk = createAsyncThunk(
  "settingsApp/settings/leaveWorkspaceThunk",
  async (user, { dispatch }) => {
    // TODO: Figure out error handling
    try {
      await leaveWorkspace({ user })
        .then(() => {
          const message = " has left the workspace ";
          Utilities.successNotification(
            user,
            message,
            user.data.currentWorkspace,
            dispatch
          );
          dispatch(logoutUser());
        })
        .catch((error) => {
          Utilities.errorNotification(user, dispatch, error);
          Promise.reject(false);
        });
    } catch {
      Utilities.errorNotification(user, dispatch);
      Promise.reject(false);
    }
  }
);

export const getAccountDeletionDate = createAsyncThunk(
  "settingsApp/settings/getAccountDeletionDate",
  async (user) => {
    return await firebase.getAccountDeletionDate(user.uid);
  }
);

export const confirmAccountDeletion = createAsyncThunk(
  "settingsApp/settings/confirmAccountDeletion",
  async ({ user, deleteAccount }, { dispatch }) => {
    // TODO: Figure out error handling
    try {
      await postApi({ user, deleteAccount }, { api: "accountDeletion" })
        .then(() => {
          const message = " has confirmed the account deletion ";
          Utilities.successNotification(user, message, null, dispatch);
        })
        .catch((error) => {
          Utilities.errorNotification(user, dispatch, error);
        });
    } catch {
      Utilities.errorNotification(user, dispatch);
    }
    return deleteAccount;
  }
);

const settingsAdapter = createEntityAdapter({});

export const { selectAll: selectWorkspaces, selectById: selectWorkspaceById } =
  settingsAdapter.getSelectors((state) => state.settingsApp.settings);

const settingsSlice = createSlice({
  name: "settingsApp/settings",
  initialState: settingsAdapter.getInitialState({
    memberCustomFields: [],
    projectCustomFields: [],
    boardCustomFields: [],
    projectName: "",
    account_deletion: null,
  }),
  reducers: {},
  extraReducers: {
    [getWorkspaceById.fulfilled]: settingsAdapter.upsertOne,
    [updateGeneralSettings.fulfilled]: settingsAdapter.upsertOne,
    [getCustomFields.fulfilled]: (state, { payload }) => {
      if (payload.type === "member") {
        state.memberCustomFields = payload.data;
      } else if (payload.type === "project") {
        state.projectCustomFields = payload.data;
      } else if (payload.type === "board") {
        state.boardCustomFields = payload.data;
        state.projectName = payload.projectName;
      }
    },
    [addCustomFields.fulfilled]: (state, { payload }) => {
      if (payload.type === "member") {
        state.memberCustomFields = payload.data;
      } else if (payload.type === "project") {
        state.projectCustomFields = payload.data;
      } else if (payload.type === "board") {
        state.boardCustomFields = payload.data;
        state.projectName = payload.projectName;
      }
    },
    [getAccountDeletionDate.fulfilled]: (state, { payload }) => {
      state.account_deletion = payload;
    },
    [confirmAccountDeletion.fulfilled]: (state, { payload }) => {
      const date = new Date();
      date.setDate(date.getDate() + 30);
      state.account_deletion = payload ? date : null;
    },
  },
});

export default settingsSlice.reducer;
