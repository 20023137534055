import { Menu } from "@headlessui/react"; // TODO: Get rid of this library and use material ui
import {
  Icon,
  Button,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import LoadMoreButton from "components/LoadMoreButton";
import Download from "components/svgs/icons/general/Download";
import Pdf from "components/svgs/icons/general/Pdf";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Utilities from "utils/utilities";

const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

const DropDown = ({ sharedFilesType, setSharedFilesType }) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex mr-6 w-full justify-center rounded-md text-sm font-medium text-gray-700">
          {sharedFilesType}
          <Icon className="-mr-1 ml-1 h-5 w-5" color="inherit">
            expand_more
          </Icon>
        </Menu.Button>
      </div>

      <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div className="py-1">
          <Menu.Item>
            {({ active }) => (
              <a
                onClick={() => setSharedFilesType("Images")}
                className={classNames(
                  active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                  "block px-4 py-2 text-sm cursor-pointer"
                )}
              >
                Images
              </a>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <a
                onClick={() => setSharedFilesType("Links")}
                className={classNames(
                  active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                  "block px-4 py-2 text-sm cursor-pointer"
                )}
              >
                Links
              </a>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <a
                onClick={() => setSharedFilesType("Documents")}
                className={classNames(
                  active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                  "block px-4 py-2 text-sm cursor-pointer"
                )}
              >
                Documents
              </a>
            )}
          </Menu.Item>
        </div>
      </Menu.Items>
    </Menu>
  );
};

const SharedFiles = () => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const mediaData = useSelector(({ chatApp }) => chatApp?.chat?.media);

  const [media, setMedia] = useState([]);
  const [sharedFilesType, setSharedFilesType] = useState("Images");

  const displayImages = () => {
    return media
      .filter((x) => x.type?.includes("image"))
      .map((item, idx) => (
        <Tooltip key={idx} title={item?.name}>
          <img
            className={`w-20 h-20 object-contain`}
            src={item?.src}
            alt="images"
          />
        </Tooltip>
      ));
  };

  const displayLinks = () => {
    return media
      .filter((x) => x.type === "link")
      .map((item, idx) => {
        return (
          <div className="w-full break-words py-3 border-b" key={idx}>
            <a
              href={item?.src}
              target="_blank"
              className="!hover:text-primaryBlue !text-primaryBlue !bg-transparent"
            >
              {item?.src}
            </a>
          </div>
        );
      });
  };

  const displayDocs = () => {
    return media
      .filter((x) => x.type?.includes("application"))
      .map((item, idx) => {
        return (
          <div
            className=" flex justify-between docs-container w-full py-3 border-b"
            key={idx}
          >
            <div className="flex">
              <div className="icon">
                <Pdf />
              </div>
              <a
                className="ml-2 file-container cursor-pointer !bg-transparent !border-none"
                href={item?.src}
                target="_blank"
              >
                <Typography className="text-primaryBlue font-normal text-[1rem]">
                  {item?.name}
                </Typography>
                <Typography className="text-primaryBlue text-[0.7rem]">
                  {item?.size}
                </Typography>
              </a>
            </div>
            <div className="download-icon-container">
              <Box
                className="cursor-pointer"
                onClick={() => Utilities.downloadFile(item?.src)}
              >
                <Download />
              </Box>
            </div>
          </div>
        );
      });
  };

  useEffect(() => {
    if (mediaData) setMedia(mediaData);
  }, [mediaData]);

  return (
    <div className="w-full">
      <div className={`${isSm ? "px-6" : ""} mt-6 flex justify-between`}>
        <Typography className="ml-4 font-semibold">Shared files</Typography>
        <DropDown
          sharedFilesType={sharedFilesType}
          setSharedFilesType={setSharedFilesType}
        />
      </div>
      {sharedFilesType === "Images" && (
        <div className={`${isSm ? "px-6" : ""} m-4 grid grid-cols-3 gap-2`}>
          {displayImages()}
        </div>
      )}
      {sharedFilesType === "Links" && (
        <div className="mx-4 mt-3">{displayLinks()}</div>
      )}
      {sharedFilesType === "Documents" && (
        <div className="mx-3 mt-3">{displayDocs()}</div>
      )}
      {media?.length > 10 && <LoadMoreButton />}
    </div>
  );
};

export default SharedFiles;
