import { IconButton } from "@mui/material";
import { useState } from "react";
import { SketchPicker } from "react-color";
import reactCSS from "reactcss";

const ColorPicker = (props) => {
  const { modalParent, handleColorChange, label, disabled } = props;
  const initialState = props.color ? props.color : "#f17013";
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState(initialState);

  const styles = reactCSS({
    default: {
      color: {
        width: "36px",
        height: "14px",
        borderRadius: "2px",
        background: color,
      },
      swatch: {
        padding: "5px",
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
      },
      popover: {
        position: `${modalParent ? "absolute" : "fixed"}`,
        zIndex: "2",
        //
        left: `${modalParent && "60px"}`,
        bottom: `${modalParent && "0px"}`,
        // top:`${chartModal && "0px"}`
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  const onColorChange = (color) => {
    setColor(color.hex);
    if (handleColorChange) {
      if (modalParent) handleColorChange(color.hex);
      else handleColorChange(label, color.hex);
    }
  };

  return (
    <IconButton
      onClick={() => setDisplayColorPicker(!displayColorPicker)}
      disabled={disabled}
    >
      <div style={styles.swatch}>
        <div style={styles.color} />
      </div>
      {displayColorPicker ? (
        <div style={styles.popover}>
          <div
            style={styles.cover}
            onClick={() => setDisplayColorPicker(false)}
          />
          <SketchPicker
            color={color}
            onChange={(color) => onColorChange(color)}
            presetColors={[
              "#D0021B",
              "#F5A623",
              "#F8E71C",
              "#8B572A",
              "#7ED321",
              "#417505",
              "#BD10E0",
              "#9013FE",
              "#4A90E2",
              "#50E3C2",
              "#B8E986",
              "#000000",
              "#4A4A4A",
              "#9B9B9B",
              "#00B9D9",
            ]}
          />
        </div>
      ) : null}
    </IconButton>
  );
};

export default ColorPicker;
