import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { endOfWeek, startOfWeek } from "date-fns";
import firebase from "services/firebase";

export const getThisWeekTasks = createAsyncThunk(
  "dashboardApp/widgets/getThisWeekTasks",
  async (user) => {
    const today = new Date();
    return await firebase.getTasksByRange(
      user,
      startOfWeek(today),
      endOfWeek(today),
      50
    );
  }
);

const initialState = {
  thisWeekTasks: [],
};

const widgetsAdapter = createEntityAdapter({});

export const { selectEntities: selectWidgets, selectById: selectWidgetById } =
  widgetsAdapter.getSelectors((state) => state.dashboardApp.widgets);

const widgetsSlice = createSlice({
  name: "dashboardApp/widgets",
  initialState,
  reducers: {
    setThisWeekTasks(state, action) {
      state.thisWeekTasks = action.payload;
    },
  },
  extraReducers: {
    [getThisWeekTasks.fulfilled]: widgetsAdapter.setAll,
  },
});

export const { setThisWeekTasks } = widgetsSlice.actions;
export default widgetsSlice.reducer;
