import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import clsx from "clsx";
import EmptyPanel from "components/display/EmptyPanel";
import { format } from "date-fns";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { StyledTableCell, StyledTableRow } from "settings/styleOverrides";
import _ from "utils/lodash";
import Utilities from "utils/utilities";

const PlanHistoryTab = () => {
  const invoices = useSelector(
    ({ entities }) => entities.subscription.invoices
  );

  return (
    <motion.div
      variants={Utilities.itemOpacityConfig(20)}
      className="widget flex w-full px-2 md:px-10 flex-col p-5"
      initial="hidden"
      animate="show"
    >
      {invoices == undefined || invoices?.data?.length == 0 ? (
        <EmptyPanel
          mainClass={"mt-20"}
          mainText={"No invoices, yet?"}
          imgClass={""}
          subText={
            "Choose the right plan for subscription, and then come back to this tab to see your invoices."
          }
          imageSrc={"emptyBilling"}
          imgAlt={"No Invoices"}
        />
      ) : (
        <>
          <TableContainer
            className={`${invoices?.data?.length == 0 && "overflow-hidden"}`}
          >
            <Table
              sx={{
                minWidth: 900,
                borderCollapse: "separate",
                borderSpacing: "0px 1.5rem",
              }}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>Date</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell>Amount</StyledTableCell>
                  <StyledTableCell>Currency</StyledTableCell>
                  <StyledTableCell>Receipt</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoices &&
                  invoices.data.map((row) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell component="th" scope="row">
                        {format(new Date(row.created * 1000), "yyyy-MM-dd")}
                      </StyledTableCell>
                      <StyledTableCell>
                        <span
                          className={`inline text-[11px] font-500 px-8 py-4 text-white rounded-full mr-1 ${
                            row.status === "open"
                              ? "bg-yellowBg"
                              : "bg-primaryBlue"
                          }`}
                        >
                          {_.capitalize(row.status)}
                        </span>
                      </StyledTableCell>
                      <StyledTableCell>{row.total / 100}</StyledTableCell>
                      <StyledTableCell>
                        {_.upperCase(row.currency)}
                      </StyledTableCell>
                      <StyledTableCell>
                        <a className="!no-underline" href={row.invoice_pdf}>
                          Download
                        </a>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </motion.div>
  );
};

export default PlanHistoryTab;
