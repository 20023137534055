import { createSlice } from "@reduxjs/toolkit";

const navbarSlice = createSlice({
  name: "navbar",
  initialState: {
    open: true,
    mobileOpen: false,
    navbarLogo: "",
  },
  reducers: {
    navbarToggleMobile: (state, action) => {
      state.mobileOpen = !state.mobileOpen;
    },

    navbarCloseMobile: (state, action) => {
      state.mobileOpen = false;
    },
    navbarClose: (state, action) => {
      state.open = false;
    },
    navbarOpen: (state, action) => {
      state.open = true;
    },
    navbarToggle: (state, action) => {
      state.open = !state.open;
    },
    navbarSet: (state, action) => {
      state.open = action.payload;
    },
    navbarSetLogo: (state, action) => {
      state.navbarLogo = action.payload || "";
    },
  },
});

export const {
  navbarOpen,
  navbarClose,
  navbarToggle,
  navbarSet,
  navbarCloseMobile,
  navbarToggleMobile,
  navbarSetLogo,
} = navbarSlice.actions;

export default navbarSlice.reducer;
