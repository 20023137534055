import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import WidgetWrapper from "components/WidgetWrapper";
import Scrollbars from "core/Scrollbars";
import { useSnackbar } from "notistack";
import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { StyledSwipeableDrawer } from "settings/styleOverrides";
import withReducer from "store/withReducer";
import NotificationCard from "./NotificationCard";
import NotificationTemplate from "./NotificationTemplate";
import reducer from "./store";
import {
  dismissAll,
  dismissItem,
  selectNotifications,
} from "./store/dataSlice";
import {
  closeNotificationPanel,
  toggleNotificationPanel,
} from "./store/stateSlice";
import Close from "components/svgs/icons/general/Close";
import { Box } from "@mui/system";

let displayed = [];

const storeDisplayed = (id) => {
  displayed = [...displayed, id];
};

const removeDisplayed = (id) => {
  displayed = [...displayed.filter((key) => id !== key)];
};

const NotificationPanel = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const state = useSelector(({ notificationPanel }) => notificationPanel.state);
  const notifications = useSelector(selectNotifications);
  const darkMode = useSelector(
    ({ entities }) => entities.settings.current.darkMode
  );

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    notifications.forEach((item) => {
      const { id: key, message, options = {}, dismissed = false } = item;

      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key);
        return;
      }
      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) {
        return;
      }

      // display snackbar using notistack
      enqueueSnackbar(message, {
        key,
        ...options,
        autoHideDuration: 3000,
        content: () => (
          <NotificationTemplate
            item={item}
            onClose={() => {
              closeSnackbar(key);
              dispatch(dismissItem(key));
            }}
          />
        ),
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey) => {},
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  useEffect(() => {
    if (state) {
      dispatch(closeNotificationPanel());
    }
    // eslint-disable-next-line
  }, [location, dispatch]);

  const handleClose = () => {
    dispatch(closeNotificationPanel());
  };

  const handleDismiss = (id) => {
    dispatch(dismissItem(id));
  };

  const handleDismissAll = () => {
    dispatch(dismissAll());
  };

  const Main = () => (
    <>
      <div className="bg-primaryBlue flex justify-between p-4 sticky top-0 z-[99]">
        {" "}
        <Typography className="text-2xl font-medium text-white">
          Notifications
        </Typography>
        {!props?.widget && (
          <Box onClick={handleClose} className="cursor-pointer">
            <Close />
          </Box>
        )}
      </div>
      {notifications.length > 0 ? (
        <Scrollbars className="draggableCancel">
          <div className="flex flex-col justify-between h-screen">
            <div className="flex flex-col">
              {notifications.map((item) => (
                <NotificationCard
                  key={item.id}
                  className="mb-16"
                  item={item}
                  onClose={handleDismiss}
                />
              ))}
            </div>

            <Button
              variant="outlined"
              size="small"
              className={`rounded-md border-none flex justify-center m-8 ${
                darkMode && "text-white border-slate-50"
              }`}
              onClick={() => handleDismissAll()}
            >
              Clear all
            </Button>
          </div>

          {/* <Card className="flex bg-white rounded-none px-4 py-6 mb-4 gap-4">
              <div className="flex justify-center items-center">
                <div className="relative">
                  <div className="absolute right-3 bottom-2 -m-4 z-10">
                    <StatusIcon status={"away"} needBg />
                  </div>
                  <Avatar className="h-[42px] w-[42px]"></Avatar>
                </div>
              </div>

              <div className="text-container flex flex-col justify-center">
                <Typography className="xs:text-sm md:text-base">
                  <span className="font-medium text-cancelBlack">
                    {"Deepa Smith"}
                  </span>
                  <span className="text-graytext">
                    {" added 3 members to the"}
                  </span>
                  <span className="text-primaryBlue font-medium">
                    {" Black & White Project"}
                  </span>
                </Typography>
                <Typography className="xs:text-xs md:text-sm text-grayText mt-2">
                  {"1:32 AM"}
                </Typography>
              </div>
              <div className="flex justify-center items-center ml-4">
                <AvatarGroup spacing={"small"}>
                  <Avatar className="xs:h-[36px] xs:w-[36px] md:h-[42px] md:w-[42px]"></Avatar>
                  <Avatar className="xs:h-[36px] xs:w-[36px] md:h-[42px] md:w-[42px]"></Avatar>
                  <Avatar className="xs:h-[36px] xs:w-[36px] md:h-[42px] md:w-[42px]"></Avatar>
                </AvatarGroup>
              </div>
            </Card>

            <Card className="flex bg-white rounded-none px-4 py-6 mb-4 gap-4">
              <div className="flex justify-center items-center">
                <div className="relative">
                  <div className="absolute right-3 bottom-2 -m-4 z-10">
                    <StatusIcon status={"do-not-disturb"} needBg />
                  </div>
                  <Avatar className="h-[42px] w-[42px]"></Avatar>
                </div>
              </div>

              <div className="text-container flex flex-col justify-center">
                <Typography className="xs:text-sm md:text-base">
                  <span className="font-medium text-cancelBlack">
                    {"Protap Sala"}
                  </span>
                  <span className="text-graytext">{" followed you."}</span>
                  <span className="text-primaryBlue">{""}</span>
                </Typography>
                <Typography className="xs:text-xs md:text-sm text-grayText mt-2">
                  {"1:32 AM"}
                </Typography>
              </div>
            </Card>

            <Card className="flex bg-white rounded-none px-4 py-6 mb-4 gap-4">
              <div className="flex justify-center items-center ">
                <div className="relative">
                  <div className="absolute right-3 bottom-2 -m-4 z-10">
                    <StatusIcon status={"online"} needBg />
                  </div>
                  <Avatar className="h-[42px] w-[42px]"></Avatar>
                </div>
              </div>
              <div className="text-container flex flex-col justify-center">
                <Typography className="xs:text-sm md:text-base">
                  <span className="font-medium text-cancelBlack">
                    {"Cliola Mam"}
                  </span>
                  <span className="text-graytext">{" donated"}</span>
                  <span className="text-darkGreen">{" $100.00"}</span>
                  <span className="text-gratText">{" for"}</span>
                  <span className="text-primaryBlue font-medium">
                    {" Charity Farm"}
                  </span>
                </Typography>
                <Typography className="xs:text-xs md:text-sm text-grayText mt-2">
                  {"1:32 AM"}
                </Typography>
              </div>
              <div className="flex justify-center items-center ml-auto">
                <Avatar className="xs:h-[36px] xs:w-[36px] md:h-[42px] md:w-[42px]"></Avatar>
              </div>
            </Card>

            <Card className="flex bg-white rounded-none px-4 py-6 mb-4 gap-4">
              <div className="flex justify-center items-center">
                <div className="relative">
                  <div className="absolute right-3 bottom-2 -m-4 z-10">
                    <StatusIcon status={"online"} needBg />
                  </div>
                  <Avatar className="h-[42px] w-[42px]"></Avatar>
                </div>
              </div>
              <div className="text-container flex flex-col justify-center">
                <Typography className="xs:text-sm md:text-base">
                  <span className="font-medium text-cancelBlack">
                    {"Sujola"}
                  </span>
                  <span className="text-graytext">
                    {" uploaded 2 files to"}
                  </span>
                  <span className="text-primaryBlue font-medium">
                    {" Secret Group"}
                  </span>
                </Typography>
                <Typography className="xs:text-xs md:text-sm text-grayText mt-2">
                  {"1:32 AM"}
                </Typography>
              </div>
              <div className="flex justify-center items-center gap-2 ml-auto">
                <Avatar className="xs:h-[36px] xs:w-[36px] md:h-[42px] md:w-[42px]"></Avatar>
                <Avatar className="xs:h-[36px] xs:w-[36px] md:h-[42px] md:w-[42px]"></Avatar>
              </div>
            </Card>

            <Card className="flex bg-white rounded-none px-4 py-6 mb-4 gap-4">
              <div className="flex justify-center items-center">
                <div className="relative">
                  <div className="absolute right-3 bottom-2 -m-4 z-10">
                    <StatusIcon status={"do-not-disturb"} needBg />
                  </div>
                  <Avatar className="h-[42px] w-[42px]"></Avatar>
                </div>
              </div>

              <div className="text-container flex flex-col justify-center">
                <Typography className="xs:text-sm md:text-base">
                  <span className="font-medium text-cancelBlack">
                    {"Montu A."}
                  </span>
                  <span className="text-graytext">
                    {" changed the status of a Task from "}
                  </span>
                  <span className="text-white bg-darkRed rounded-full px-2 py-1">
                    {"Pending"}
                  </span>
                  <span className="text-grayText">{" to "}</span>
                  <span className="text-white bg-darkGreen rounded-full px-2 py-1">
                    {"Completed"}
                  </span>
                </Typography>
                <Typography className="xs:text-xs md:text-sm text-grayText mt-2">
                  {"1:32 AM"}
                </Typography>
              </div>
            </Card> */}
        </Scrollbars>
      ) : (
        <div className="flex flex-1 items-center justify-center m-16 draggableCancel">
          <Typography className="text-24 text-center text-mute">
            There are no notifications for now.
          </Typography>
        </div>
      )}
    </>
  );
  return props?.widget ? (
    <WidgetWrapper {...props}>
      <Main />
    </WidgetWrapper>
  ) : (
    <StyledSwipeableDrawer
      open={state}
      anchor="right"
      onOpen={(ev) => {}}
      onClose={(ev) => dispatch(toggleNotificationPanel())}
      disableSwipeToOpen
    >
      <Main />
    </StyledSwipeableDrawer>
  );
};

export default withReducer(
  "notificationPanel",
  reducer
)(memo(NotificationPanel));
