import _ from "utils/lodash";

// a little function to help us with reordering the result
const reorder = (section, startIndex, endIndex) => {
  const result = Array.from(section);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default reorder;

export const reorderQuoteMap = (sections, source, destination) => {
  const current = _.find(sections, { id: source.droppableId });
  const next = _.find(sections, { id: destination.droppableId });
  const target = current.idCards[source.index];

  // moving to same section
  if (source.droppableId === destination.droppableId) {
    const reordered = reorder(current.idCards, source.index, destination.index);
    return sections.map((section) => {
      if (section.id === source.droppableId) {
        section.idCards = reordered;
      }
      return section;
    });
  }

  // moving to different list

  // remove from original
  current.idCards.splice(source.index, 1);
  // insert into next
  next.idCards.splice(destination.index, 0, target);

  return sections.map((section) => {
    if (section.id === source.droppableId) {
      return current;
    }
    if (section.id === destination.droppableId) {
      return next;
    }
    return section;
  });
};
