import Utilities from "utils/utilities";
import firebase from "services/firebase";

export const getSubscriptionDetails = async (stripe_subscription_id) => {
  const { currentUser } = firebase.getAuth();
  const token = await currentUser.getIdToken(true);
  const endpoint = `${Utilities.getBaseUrl()}/getSubscriptionDetails?id=${stripe_subscription_id}`;
  const request = await fetch(endpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  const response = await request.json();
  if (response.error) {
    return {
      statusCode: response.statusCode || 404,
      body: response,
    };
  }
  return {
    statusCode: 200,
    body: response,
  };
};
