import { lazy } from "react";
// TODO: const EmptyChat = lazy(() => import("components/svgs/empty/EmptyChat"));
import EmptyChat from "components/svgs/empty/EmptyChat";
const ComingSoon = lazy(() => import("components/svgs/empty/ComingSoon"));
const EmptyBilling = lazy(() => import("components/svgs/empty/EmptyBilling"));
const EmptyBoard = lazy(() => import("components/svgs/empty/EmptyBoard"));
const EmptyDiscussions = lazy(() =>
  import("components/svgs/empty/EmptyDiscussions")
);
const EmptyMembers = lazy(() => import("components/svgs/empty/EmptyMembers"));
const EmptyNotes = lazy(() => import("components/svgs/empty/EmptyNotes"));
const EmptyPhotos = lazy(() => import("components/svgs/empty/EmptyPhotos"));
const EmptyPost = lazy(() => import("components/svgs/empty/EmptyPost"));
const EmptyProject = lazy(() => import("components/svgs/empty/EmptyProject"));
const EmptyReports = lazy(() => import("components/svgs/empty/EmptyReports"));
const EmptySearch = lazy(() => import("components/svgs/empty/EmptySearch"));
const EmptySprint = lazy(() => import("components/svgs/empty/EmptySprint"));
const EmptyTask = lazy(() => import("components/svgs/empty/EmptyTask"));
const NoAccess = lazy(() => import("components/svgs/empty/NoAccess"));
const NoBacklogs = lazy(() => import("components/svgs/empty/NoBacklogs"));
const Workspace = lazy(() => import("components/svgs/empty/Workspace"));
const AddWidgets = lazy(() =>
  import("components/svgs/icons/general/AddWidgets")
);
const NoMoreWidget = lazy(() => import("components/svgs/widgets/NoMoreWidget"));

const placeholders = {
  comingSoon: ComingSoon,
  noAccess: NoAccess,
  emptyPost: EmptyPost,
  emptySearch: EmptySearch,
  emptyBilling: EmptyBilling,
  emptyBoard: EmptyBoard,
  emptyChat: EmptyChat,
  emptyDiscussions: EmptyDiscussions,
  emptyMembers: EmptyMembers,
  emptyNotes: EmptyNotes,
  emptyPhotos: EmptyPhotos,
  emptyReports: EmptyReports,
  emptySprint: EmptySprint,
  emptyTask: EmptyTask,
  noBacklogs: NoBacklogs,
  workspace: Workspace,
  emptyProject: EmptyProject,
  addWidgets: AddWidgets,
  noMoreWidgetsToSelect: NoMoreWidget,
};

export default placeholders;
