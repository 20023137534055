let vercelConfig;
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

// Unable to set node env to development for preview builds
if (
  process.env.REACT_APP_VERCEL_ENV &&
  process.env.REACT_APP_VERCEL_ENV === "development"
) {
  vercelConfig = {
    apiKey: process.env.REACT_APP_VERCEL_API_KEY,
    authDomain: process.env.REACT_APP_VERCEL_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_VERCEL_DATABASE_URL,
    projectId: process.env.REACT_APP_VERCEL_PROJECT_ID,
    storageBucket: process.env.REACT_APP_VERCEL_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_VERCEL_SENDER_ID,
    appId: process.env.REACT_APP_VERCEL_APP_ID,
  };
}
console.log("env", process.env.NODE_ENV);
console.log("react vercel env", process.env.REACT_APP_VERCEL_ENV);

const firebase = vercelConfig ?? firebaseConfig;

export default firebase;
