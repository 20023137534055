import { lazy } from "react";

const ChatApp = lazy(() => import("./ChatApp"));

const ChatAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/:workspaceId/chat",
      element: <ChatApp />,
      title: "Chats",
    },
    {
      path: "/:workspaceId/chat/:selectedId",
      element: <ChatApp />,
      title: "Chats",
    },
  ],
};

export default ChatAppConfig;
