/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice, isRejected } from "@reduxjs/toolkit";
import settingsConfig from "configs/settings";
import firebase from "services/firebase";
import { navbarSetLogo } from "store/slice/navbarSlice";
import {
  setDefaultSettings,
  setInitialSettings,
  setIsNativeApp,
} from "store/slice/settingsSlice";
import _ from "utils/lodash";
import Utilities from "utils/utilities";

export const setUserDataFirebase =
  ({ user, authUser, setWaitAuthCheck }) =>
  async (dispatch) => {
    return dispatch(
      createUserSettingsFirebase({
        authUser: user || authUser,
        setWaitAuthCheck,
      })
    );
  };

export const createUserSettingsFirebase =
  ({ authUser, setWaitAuthCheck }) =>
  async (dispatch, getState) => {
    const guestUser = getState().auth.user;
    const defaultSettings = getState().entities.settings.defaults;
    const { currentUser } = firebase.getAuth();
    if (!currentUser) return; // This happens upon registeration

    // TODO: Do this entire logic on server side
    const workspaces =
      authUser && authUser.workspaces ? authUser.workspaces : [];
    const defaultWorkspace =
      workspaces.find((x) => x.is_default) || workspaces[0];
    const params = new URLSearchParams(window.location.search);
    let id, role; // TODO: Need improvements
    if (params.get("id") !== null) {
      id = params.get("id");
      role = "member";
    } else if (
      // TODO: need to find better approach than split
      workspaces.find(
        (each) => each.id === window.location.pathname.split("/")[1]
      )
    ) {
      id = window.location.pathname.split("/")[1];
      workspaces.find((x) => {
        if (x.id === id) {
          role = x.role;
        }
      });
    } else {
      id = defaultWorkspace?.id;
      role = defaultWorkspace?.role;
    }

    if (!id || !role) return; // This happens upon registeration

    const color =
      role === "owner" ? "#00b9d933" : Utilities.randomMatColor(100);
    const workspaceUserInfo =
      id && currentUser.uid
        ? await firebase.getWorkspaceUserById(id, currentUser.uid)
        : {};
    const workspaceInfo = await firebase.getWorkspaceById(id);
    const user = _.merge({}, guestUser, {
      uid: currentUser.uid,
      role: [role],
      workspaces: workspaces,
      data: {
        id: currentUser.uid,
        displayName: authUser.displayName,
        newUser: workspaceUserInfo?.newUser || false,
        owner: workspaceUserInfo?.owner || false,
        email: currentUser.email,
        emailVerified: workspaceUserInfo?.emailVerified || false,
        currentWorkspace: id,
        mood: workspaceUserInfo?.mood || "busy",
        status: workspaceUserInfo?.status || "online",
        shortcuts: workspaceUserInfo?.shortcuts || [],
        photoURL: workspaceUserInfo?.photoURL || "",
        coverPhotoURL: workspaceUserInfo?.coverPhotoURL || "",
        isLocked: workspaceUserInfo?.isLocked || false,
        more: workspaceUserInfo?.more || {
          color: color,
          savedPosts: [],
        },
        starred: workspaceUserInfo?.starred || [],
        customFields: workspaceUserInfo?.customFields || [],
        settings:
          workspaceUserInfo && workspaceUserInfo.settings
            ? {
                ...defaultSettings,
                notifications: workspaceUserInfo.settings.notifications,
                chatSidebar: workspaceUserInfo.settings.chatSidebar,
                darkMode: workspaceUserInfo.settings.darkMode,
                themeColor:
                  workspaceUserInfo.settings.themeColor ||
                  defaultSettings.themeColor,
                theme: workspaceUserInfo.settings.theme,
                layout: workspaceUserInfo.settings.layout,
              }
            : { ...defaultSettings },
      },
    });

    currentUser.updateProfile(user.data);
    Utilities.setThemeColor(user.data.settings.themeColor);
    if (user?.data?.emailVerified)
      sessionStorage.setItem("workspace", defaultWorkspace.id); // TODO: use firebase user session

    dispatch(updateWorkspaceUserData(user));
    dispatch(setUserData(user));

    // This will make sure we never have scenario where user.data.currentWorkspcace is empty
    setWaitAuthCheck(false);

    // Update navbar logo if present
    if (workspaceInfo?.data?.logo) {
      dispatch(navbarSetLogo(workspaceInfo?.data?.logo));
    }
  };

export const setUserData = (user) => async (dispatch, getState) => {
  // You can redirect the logged-in user to a specific route depending on his role
  // for example '/onboarding'
  if (user.loginRedirectUrl) {
    settingsConfig.loginRedirectUrl = user.loginRedirectUrl;
  }

  dispatch(setDefaultSettings(user.data.settings));
  dispatch(setUser(user));
};

export const updateUserSettings = (settings) => async (dispatch, getState) => {
  const oldUser = getState().auth.user;
  const user = _.merge({}, oldUser, { data: { settings } });

  dispatch(updateWorkspaceUserData(user));
  return dispatch(setUserData(user));
};

export const updateUserShortcuts =
  (shortcuts) => async (dispatch, getState) => {
    const { user } = getState().auth;
    const newUser = {
      ...user,
      data: {
        ...user.data,
        shortcuts,
      },
    };

    dispatch(updateWorkspaceUserData(newUser));
    return dispatch(setUserData(newUser));
  };

export const updateUserLockScreen =
  (isLocked) => async (dispatch, getState) => {
    const { user } = getState().auth;
    const newUser = {
      ...user,
      data: {
        ...user.data,
        isLocked,
      },
    };

    dispatch(updateWorkspaceUserData(newUser));
    return dispatch(setUserData(newUser));
  };

export const logoutUser = () => async (dispatch, getState) => {
  // TODO: Test over the course of time, if this is required
  // const { user } = getState().auth;
  // if (!user.role || user.role.length === 0) {
  //   // is guest
  //   return null;
  // }

  sessionStorage.removeItem("workspace");
  firebase.signOut();
  dispatch(setInitialSettings());
  dispatch(userLoggedOut());

  const isNativeApp = await Utilities.isNativeApp();
  return dispatch(setIsNativeApp(isNativeApp));
};

export const updateWorkspaceUserData = (user) => async (dispatch, getState) => {
  if (!user.role || user.role.length === 0) {
    // is guest
    return;
  }
  firebase
    .updateWorkspaceUserData(user)
    .catch((error) => Utilities.errorNotification(user, dispatch, error));
};

export const updateStarredChats = createAsyncThunk(
  "chatApp/updateStarredChats",
  async ({ user, contactId }, { dispatch }) => {
    const starred = [...user?.data?.starred] || [];

    // Find index and replace
    if (starred.length > 0 && starred.includes(contactId)) {
      _.pull(starred, contactId);
    } else {
      starred.push(contactId);
    }

    await firebase.updateUserStarredChats(user, starred);
    const updatedUser = {
      ...user,
      data: { ...user.data, starred },
    };
    dispatch(setUserData(updatedUser));

    const message = " has updated his chat favorites ";
    Utilities.successNotification(user, message, null, dispatch);
  }
);

export const updateUserStatus = createAsyncThunk(
  "chatApp/updateUserStatus",
  async ({ user, status, mood }, { dispatch }) => {
    await firebase.updateUserStatus(user, status, mood);
    const updatedUser = {
      ...user,
      data: { ...user.data, status: status, mood: mood },
    };
    dispatch(setUserData(updatedUser));

    const message = " has updated the user status ";
    Utilities.successNotification(user, message, null, dispatch);
  }
);

export const updateUserPhoto = createAsyncThunk(
  "profileApp/updateUserPhoto",
  async ({ user, image }, { dispatch }) => {
    if (!user.role || user.role.length === 0) {
      // is guest
      return;
    }
    try {
      const url = await firebase.updateUserPhoto(user, image);
      const updatedData = { ...user.data, photoURL: url };

      dispatch(setUserData({ ...user, data: updatedData }));

      const message = " has updated the profile picture ";
      Utilities.successNotification(user, message, null, dispatch);

      return url;
    } catch {
      Utilities.errorNotification(user, dispatch);
      return isRejected();
    }
  }
);

export const deleteUserPhoto = createAsyncThunk(
  "profileApp/deleteUserPhoto",
  async ({ user, image }, { dispatch }) => {
    if (!user.role || user.role.length === 0) {
      // is guest
      return;
    }
    try {
      await firebase.deleteUserPhoto(user);

      const message = " has removed profile picture ";
      Utilities.successNotification(user, message, null, dispatch);
    } catch {
      Utilities.errorNotification(user, dispatch);
      return isRejected();
    }
  }
);

export const updateCoverPhoto = createAsyncThunk(
  "profileApp/updateCoverPhoto",
  async ({ user, image }, { dispatch }) => {
    if (!user.role || user.role.length === 0) {
      // is guest
      return;
    }
    try {
      const url = await firebase.updateCoverPhoto(user, image);
      const updatedData = { ...user.data, coverPhotoURL: url };

      dispatch(setUserData({ ...user, data: updatedData }));

      const message = " has updated cover photo ";
      Utilities.successNotification(user, message, null, dispatch);

      return url;
    } catch {
      Utilities.errorNotification(user, dispatch);
      return isRejected();
    }
  }
);

export const deleteCoverPhoto = createAsyncThunk(
  "profileApp/deleteCoverPhoto",
  async ({ user }, { dispatch }) => {
    if (!user.role || user.role.length === 0) {
      // is guest
      return;
    }
    try {
      await firebase.deleteCoverPhoto(user);

      const message = " has deleted cover photo ";
      Utilities.successNotification(user, message, null, dispatch);
    } catch {
      Utilities.errorNotification(user, dispatch);
    }
  }
);

const initialState = {
  role: [],
  workspaces: [],
  logout: false,
  data: {
    displayName: "",
    photoURL: "",
    coverPhotoURL: "",
    email: "",
    emailVerified: false,
    shortcuts: [],
    currentWorkspace: "",
  },
};

const userSlice = createSlice({
  name: "auth/user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      return {
        ...state.auth,
        data: action.payload.data,
        role: action.payload.role,
        workspaces: action.payload.workspaces,
        uid: action.payload.uid,
      };
    },
    userLoggedOut: (state, action) => {
      return { ...initialState, logout: true };
    },
    setCurrentWorkspace(state, action) {
      state.data.currentWorkspace = action.payload;
    },
    setUserWorkspaces(state, action) {
      state.workspaces = action.payload;
    },
    setEmailVerified(state, action) {
      state.data.emailVerified = action.payload;
    },
  },
  extraReducers: {
    [updateUserPhoto.fulfilled]: (state, { payload }) => {
      state.data = { ...state.data, photoURL: payload };
    },
    [updateUserPhoto.rejected]: (state, { payload }) => {
      //  set loading false
    },
    [deleteUserPhoto.fulfilled]: (state, { payload }) => {
      state.data = { ...state.data, photoURL: "" };
      //  set loading false
    },
    [updateCoverPhoto.fulfilled]: (state, { payload }) => {
      state.data = { ...state.data, coverPhotoURL: payload };
      //  set loading false
    },
    [deleteCoverPhoto.fulfilled]: (state, { payload }) => {
      state.data = { ...state.data, coverPhotoURL: "" };
      //  set loading false
    },
  },
});

export const {
  setUser,
  userLoggedOut,
  setCurrentWorkspace,
  setUserWorkspaces,
  setEmailVerified,
} = userSlice.actions;

export default userSlice.reducer;
