import { authRoles } from "auth";
import { lazy } from "react";

const MembersApp = lazy(() => import("./MembersApp"));

const MembersAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: "/:workspaceId/members/:memberId",
      element: <MembersApp />,
      title: "Members",
    },
    {
      path: "/:workspaceId/members",
      element: <MembersApp />,
      title: "Members",
    },
  ],
};

export default MembersAppConfig;
