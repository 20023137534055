import Utilities from "utils/utilities";
import _ from "utils/lodash";

const CardModel = (data) => {
  data = data || {};

  return _.defaults(data, {
    id: Utilities.generateRandomNumber(),
    name: "",
    description: "",
    idAttachmentCover: "",
    severity: "medium",
    idMembers: [],
    idLabels: [],
    idTasks: [],
    attachments: [],
    customFields: [],
    subscribed: true,
    archive: false,
    checklists: [
      {
        id: Utilities.generateGUID(),
        name: "Acceptance Criteria",
        checkItems: [],
      },
    ],
    comments: [],
    activities: [],
    sprint: new Date(),
  });
};
export default CardModel;
