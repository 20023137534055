import { lazy } from "react";
import { authRoles } from "auth";

const ProfileApp = lazy(() => import("./ProfileApp"));

const ProfileAppConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.user,
  routes: [
    {
      path: "/:workspaceId/profile/:postId",
      element: <ProfileApp />,
      title: "Profile",
    },
    {
      path: "/:workspaceId/profile",
      element: <ProfileApp />,
      title: "Profile",
    },
  ],
};

export default ProfileAppConfig;
