import React from "react";

const Gallery = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 38.269 38.268"
      className="stroke-primaryBlue"
    >
      <g
        id="Group_42616"
        data-name="Group 42616"
        transform="translate(12287.885 -4317.366)"
      >
        <g
          id="Rectangle_17892"
          data-name="Rectangle 17892"
          transform="translate(-12287.885 4317.366)"
          fill="none"
          strokeWidth="2.5"
        >
          <rect width="16.048" height="16.048" rx="2" stroke="none" />
          <rect
            x="1.25"
            y="1.25"
            width="13.548"
            height="13.548"
            rx="0.75"
            fill="none"
          />
        </g>
        <g
          id="Rectangle_18038"
          data-name="Rectangle 18038"
          transform="translate(-12265.664 4317.366)"
          fill="none"
          strokeWidth="2.5"
        >
          <rect width="16.048" height="16.048" rx="2" stroke="none" />
          <rect
            x="1.25"
            y="1.25"
            width="13.548"
            height="13.548"
            rx="0.75"
            fill="none"
          />
        </g>
        <g
          id="Rectangle_17893"
          data-name="Rectangle 17893"
          transform="translate(-12287.885 4339.586)"
          fill="none"
          strokeWidth="2.5"
        >
          <rect width="16.048" height="16.048" rx="2" stroke="none" />
          <rect
            x="1.25"
            y="1.25"
            width="13.548"
            height="13.548"
            rx="0.75"
            fill="none"
          />
        </g>
        <g
          id="Rectangle_18037"
          data-name="Rectangle 18037"
          transform="translate(-12265.664 4339.586)"
          fill="none"
          strokeWidth="2.5"
        >
          <rect width="16.048" height="16.048" rx="2" stroke="none" />
          <rect
            x="1.25"
            y="1.25"
            width="13.548"
            height="13.548"
            rx="0.75"
            fill="none"
          />
        </g>
      </g>
    </svg>
  );
};

export default Gallery;
