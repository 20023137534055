import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Icon,
  IconButton,
  InputAdornment,
  InputBase,
} from "@mui/material";
import { Box } from "@mui/system";
import clsx from "clsx";
import FilterBar from "components/header/FilterBar";
import Edit from "components/svgs/icons/general/Edit";
import Filter from "components/svgs/icons/general/Filter";
import Gallery from "components/svgs/icons/general/Gallery";
import List from "components/svgs/icons/general/List";
import { Controller, useForm } from "react-hook-form";
import { useStyles } from "settings/styleOverrides";

const SearchBar = (props) => {
  const {
    placeholder,
    showCardViewBtn,
    setCardView,
    addBtnTxt,
    handleClickOpen,
    showCornerPlusIcon,
    showCardView,
    showSortBtn,
    handleSortClick,
    showPopupBtn,
    handleOpenPopup,
    popupBtnTxt,
    showFilterBtn,
    showFilterImage,
    onFilterBtnClick,
    showFilterBar,
    filterBarStyle,
    showSettingsbtn,
    toggleSettingsDrawer,
    setSearchQuery,
    searchQuery,
    darkMode,
  } = props;
  const classes = useStyles();

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      search: searchQuery,
    },
    mode: "onChange",
  });

  const onSubmit = ({ search }) => {
    setSearchQuery(search);
  };
  return (
    <div className="px-2 py-4">
      <div className="relative pl-4 pr-2 sm:pl-4 sm:pr-4 py-4 md:py-7 bg-blueTransparent">
        {showCornerPlusIcon && (
          <div className="cursor-pointer absolute flex justify-center items-center bg-primaryBlue w-[36px] h-[36px] rounded-full -top-5 -right-2 text-white">
            <Icon className="text-2xl mb-2">add</Icon>
          </div>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex gap-x-2 md:gap-x-4">
            {addBtnTxt && (
              <div className="w-1/4 hidden md:flex gap-x-2 md:gap-x-4 h-10">
                <Button
                  type="button"
                  onClick={handleClickOpen}
                  className="rounded-full bg-primaryBlue hover:bg-primaryBlueLight text-sm md:text-base px-1 md:px-2 text-white min-w-[160px] w-full"
                >
                  <Icon className="text-2xl mb-2 mr-1">add</Icon>
                  <span className="text-sm md:text-lg">{addBtnTxt}</span>
                </Button>
              </div>
            )}
            <div className="flex items-center justify-center w-2/5">
              {/* Exception: Only pass darkMode from Board page as there is some UI issue */}
              <div
                className={`flex w-full p-[4px] rounded-[5px] ${
                  darkMode ? "" : "bg-white"
                } border`}
              >
                <Icon className="pl-0 md:pl-1 h-min w-min text-primaryBlue">
                  <SearchIcon className="block" fontSize="small" />
                </Icon>
                <Controller
                  name="search"
                  control={control}
                  defaultValue=""
                  render={({
                    field: { name, value, onChange },
                    fieldState: { error },
                  }) => (
                    <InputBase
                      type="text"
                      placeholder={`${placeholder || "Search"}`}
                      name={name}
                      value={value}
                      onChange={onChange}
                      autoComplete="off"
                      inputProps={{
                        className: classes.input,
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            className="p-0"
                            aria-label="Delete"
                            type="button"
                            size="small"
                            hidden={searchQuery === ""}
                            onClick={() => {
                              setSearchQuery("");
                              setValue("search", "");
                            }}
                          >
                            <Icon fontSize="small" color="error">
                              clear
                            </Icon>
                          </IconButton>
                        </InputAdornment>
                      }
                      fullWidth
                    />
                  )}
                />
              </div>
            </div>
            <div className="w-2/5 flex gap-x-2 md:gap-x-4 h-10">
              <Button
                type="submit"
                className="rounded-[5px] bg-primaryBlue hover:bg-primaryBlueLight text-sm md:text-base px-1 text-white w-full min-w-[160px]"
              >
                Search
              </Button>
            </div>
            <div className="w-2/5 hidden lg:flex gap-x-2 md:gap-x-4 h-10">
              {showSortBtn && (
                <Button
                  type="button"
                  className="rounded-full bg-primaryBlue hover:bg-primaryBlueLight text-sm md:text-base px-1 text-white min-w-[100px]"
                  onClick={handleSortClick}
                >
                  <Box className={`h-[18px] w-[18px] mr-2`}>
                    <Edit height={18} width={18} className="fill-white" />
                  </Box>
                  <span className="text-sm md:text-base">Sort</span>
                </Button>
              )}

              {showPopupBtn && (
                <Button
                  type="button"
                  className="rounded-full bg-primaryBlue hover:bg-primaryBlueLight text-sm md:text-base px-1 text-white min-w-[160px]"
                  onClick={handleOpenPopup}
                >
                  <Box className={`h-[18px] w-[18px] mr-2`}>
                    <Edit height={18} width={18} className="fill-white" />
                  </Box>
                  <span className="text-sm md:text-base">{popupBtnTxt}</span>
                </Button>
              )}

              {showFilterBtn && (
                <Button
                  onClick={onFilterBtnClick}
                  type="button"
                  className={clsx(
                    "rounded-full text-sm md:text-base px-1 md:px-2 text-white min-w-[120px]",
                    showFilterBar && "bg-primaryBlue/50 hover:bg-primaryBlue",
                    !showFilterBar && "bg-primaryBlue hover:bg-primaryBlueLight"
                  )}
                >
                  <Box className="w-[16px] h-[16px] mr-2">
                    <Filter />
                  </Box>
                  <span className="text-sm md:text-base">Filter</span>
                </Button>
              )}

              {showCardViewBtn && (
                <IconButton onClick={() => setCardView(!showCardView)}>
                  {showCardView ? <List /> : <Gallery />}
                </IconButton>
              )}

              {showSettingsbtn && (
                <IconButton
                  className="text-primaryBlue ml-2 xs:px-0 xmd:px-2"
                  onClick={() => toggleSettingsDrawer()}
                >
                  <Icon className="xs:text-[0.85em] xmd:text-[1.2em]">
                    settings
                  </Icon>
                </IconButton>
              )}
            </div>
          </div>

          <div className="flex lg:hidden xmd:w-2/4 w-full my-4 justify-center">
            {showPopupBtn && (
              <Button
                type="button"
                className="rounded-full bg-primaryBlue hover:bg-primaryBlueLight text-sm md:text-base my-4 px-1 mr-1 text-white min-w-[160px]"
                onClick={handleOpenPopup}
              >
                <Box className={`h-[18px] w-[18px] mr-2`}>
                  <Edit height={18} width={18} className="fill-white" />
                </Box>
                <span className="text-sm md:text-base">{popupBtnTxt}</span>
              </Button>
            )}

            {showFilterBtn && (
              <Button
                onClick={onFilterBtnClick}
                type="button"
                className={clsx(
                  "rounded-full text-sm md:text-base my-4 px-1 md:px-2 mr-1 text-white min-w-[120px]",
                  showFilterBar && "bg-primaryBlue/50 hover:bg-primaryBlue",
                  !showFilterBar && "bg-primaryBlue hover:bg-primaryBlueLight"
                )}
              >
                <Box className="w-[16px] h-[16px] mr-2">
                  <Filter />
                </Box>
                <span className="text-sm md:text-base">Filter</span>
              </Button>
            )}

            {showCardViewBtn && (
              <IconButton onClick={() => setCardView(!showCardView)}>
                {showCardView ? <List /> : <Gallery />}
              </IconButton>
            )}

            {showSettingsbtn && (
              <IconButton
                className="text-primaryBlue ml-2 xs:px-0 xmd:px-2"
                onClick={() => toggleSettingsDrawer()}
              >
                <Icon className="xs:text-[0.85em] xmd:text-[1.2em]">
                  settings
                </Icon>
              </IconButton>
            )}
          </div>

          {addBtnTxt && (
            <div className="flex md:hidden xmd:w-2/4 w-full my-4">
              <Button
                type="button"
                onClick={handleClickOpen}
                className="rounded-full bg-primaryBlue hover:bg-primaryBlueLight text-sm md:text-base px-1 md:px-4 text-white w-full"
              >
                <Icon className="text-2xl mb-2">add</Icon>
                <span className="text-sm md:text-lg">{addBtnTxt}</span>
              </Button>
            </div>
          )}

          {showFilterImage && (
            <Box
              className="w-[40px] h-[40px] mr-2 cursor-pointer"
              onClick={onFilterBtnClick}
            >
              {showFilterBar ? (
                <Filter />
              ) : (
                <Filter className="fill-[#5b5b5b]" />
              )}
            </Box>
          )}
        </form>
      </div>
      {showFilterBar && (
        <FilterBar style={filterBarStyle} className="bg-blueTransparent" />
      )}
    </div>
  );
};

export default SearchBar;
