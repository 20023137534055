// eslint-disable-next-line max-classes-per-file
import * as colors from "@mui/material/colors";
import NotificationModel from "components/notificationPanel/model/NotificationModel";
import { addNotification } from "components/notificationPanel/store/dataSlice";
import settingsConfig from "configs/settings";
import _ from "utils/lodash";
import { addNewTimeline } from "views/profile/store/timelineSlice";

class EventEmitter {
  constructor() {
    this.events = {};
  }

  _getEventListByName(eventName) {
    if (typeof this.events[eventName] === "undefined") {
      this.events[eventName] = new Set();
    }
    return this.events[eventName];
  }

  on(eventName, fn) {
    this._getEventListByName(eventName).add(fn);
  }

  once(eventName, fn) {
    const self = this;

    const onceFn = (...args) => {
      self.removeListener(eventName, onceFn);
      fn.apply(self, args);
    };
    this.on(eventName, onceFn);
  }

  emit(eventName, ...args) {
    this._getEventListByName(eventName).forEach(
      // eslint-disable-next-line func-names
      function (fn) {
        fn.apply(this, args);
      }.bind(this)
    );
  }

  removeListener(eventName, fn) {
    this._getEventListByName(eventName).delete(fn);
  }
}

class Utilities {
  // TODO: Unable to set node env to development for preview builds
  static getBaseUrl = () => {
    if (
      process.env.REACT_APP_VERCEL_ENV &&
      process.env.REACT_APP_VERCEL_ENV === "development"
    ) {
      return process.env.REACT_APP_VERCEL_BASE_URL;
    } else {
      return process.env.REACT_APP_BASE_URL;
    }
  };

  static setDate = (date) => {
    return date.toISOString();
  };

  static toArray = (query) => {
    const arr = [];
    query.forEach((item) => arr.push(item.data()));
    return arr;
  };

  static toArrayWithId = (query, userId) => {
    const arr = [];
    query.forEach((item) => {
      const data = item.data();
      data.id = item.id;
      if (userId) data.userId = userId;
      arr.push(data);
    });
    return arr;
  };

  static getSuggestedMembers = (members) => {
    return members.map((x) => ({
      id: x.id,
      display: x.displayName,
    }));
  };

  static toArrayOfObjects = (query) => {
    const arr = [];
    query.forEach((item) =>
      arr.push({
        data: item.data(),
        id: item.id,
      })
    );
    return arr;
  };

  static capitalizeArrayOfStrings = (labels) => {
    return labels
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(", ");
  };

  static getFilteredArray = (arr, _searchText, filterOn) => {
    if (_searchText.length === 0) {
      return arr;
    }
    return this.filterArrayByString(arr, _searchText, filterOn);
  };

  static filterArrayByString(mainArr, searchText, filterOn) {
    if (searchText === "") {
      return mainArr;
    }

    searchText = searchText.toLowerCase();

    if (filterOn) {
      return mainArr.filter((itemObj) =>
        this.searchInString(itemObj[filterOn], searchText)
      );
    }
    return mainArr.filter((itemObj) => this.searchInObj(itemObj, searchText));
  }

  static searchInObj(itemObj, searchText) {
    if (!itemObj) {
      return false;
    }

    const propArray = Object.keys(itemObj);

    for (let i = 0; i < propArray.length; i += 1) {
      const prop = propArray[i];
      const value = itemObj[prop];

      if (typeof value === "string") {
        if (this.searchInString(value, searchText)) {
          return true;
        }
      } else if (Array.isArray(value)) {
        if (this.searchInArray(value, searchText)) {
          return true;
        }
      }

      if (typeof value === "object") {
        if (this.searchInObj(value, searchText)) {
          return true;
        }
      }
    }
    return false;
  }

  static searchInArray(arr, searchText) {
    arr.forEach((value) => {
      if (typeof value === "string") {
        if (this.searchInString(value, searchText)) {
          return true;
        }
      }

      if (typeof value === "object") {
        if (this.searchInObj(value, searchText)) {
          return true;
        }
      }
      return false;
    });
    return false;
  }

  static searchInString(value, searchText) {
    return value?.toLowerCase()?.includes(searchText);
  }

  static generateGUID() {
    const S4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    };

    return S4() + S4();
  }

  static generateRandomNumber() {
    return Math.floor(Math.random() * 899999 + 100000).toString();
  }

  // TODO: Use this generic method
  static toggleInArray(item, array) {
    if (array.indexOf(item) === -1) {
      array.push(item);
    } else {
      array.splice(array.indexOf(item), 1);
    }
  }

  static handleize(text) {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/\W+/g, "") // Remove all non-word chars
      .replace(/--+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text
  }

  static setRoutes(config, defaultAuth) {
    let routes = [...config.routes];

    routes = routes.map((route) => {
      let auth =
        config.auth || config.auth === null ? config.auth : defaultAuth || null;
      auth = route.auth || route.auth === null ? route.auth : auth;
      const settings = _.merge({}, config.settings, route.settings);

      return {
        ...route,
        settings,
        auth,
      };
    });

    return [...routes];
  }

  static generateRoutesFromConfigs(configs, defaultAuth) {
    let allRoutes = [];
    configs.forEach((config) => {
      allRoutes = [...allRoutes, ...this.setRoutes(config, defaultAuth)];
    });
    return allRoutes;
  }

  static findById(obj, id) {
    let i;
    let childObj;
    let result;

    if (id === obj.id) {
      return obj;
    }

    for (i = 0; i < Object.keys(obj).length; i += 1) {
      childObj = obj[Object.keys(obj)[i]];

      if (typeof childObj === "object") {
        result = this.findById(childObj, id);
        if (result) {
          return result;
        }
      }
    }
    return false;
  }

  static getFlatNavigation(navigationItems, flatNavigation = []) {
    for (let i = 0; i < navigationItems.length; i += 1) {
      const navItem = navigationItems[i];

      if (navItem.type === "item") {
        flatNavigation.push({
          id: navItem.id,
          title: navItem.title,
          type: navItem.type,
          icon: navItem.icon || false,
          url: navItem.url,
          auth: navItem.auth || null,
        });
      }

      if (navItem.type === "collapse" || navItem.type === "group") {
        if (navItem.children) {
          this.getFlatNavigation(navItem.children, flatNavigation);
        }
      }
    }
    return flatNavigation;
  }

  static randomMatColor(hue) {
    hue = hue || "400";
    const mainColors = [
      "red",
      "pink",
      "purple",
      "deepPurple",
      "indigo",
      "blue",
      "lightBlue",
      "cyan",
      "teal",
      "green",
      "lightGreen",
      "lime",
      "yellow",
      "amber",
      "orange",
      "deepOrange",
    ];
    const randomColor =
      mainColors[Math.floor(Math.random() * mainColors.length)];
    return colors[randomColor][hue];
  }

  static shadeColor(color, amount) {
    if (!color) return;
    return (
      "#" +
      color
        .replace(/^#/, "")
        .replace(/../g, (color) =>
          (
            "0" +
            Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(
              16
            )
          ).substr(-2)
        )
    );
  }

  static RGBAToHexA(rgba, forceRemoveAlpha = false) {
    return (
      "#" +
      rgba
        .replace(/^rgba?\(|\s+|\)$/g, "") // Get's rgba / rgb string values
        .split(",") // splits them at ","
        .filter((string, index) => !forceRemoveAlpha || index !== 3)
        .map((string) => parseFloat(string)) // Converts them to numbers
        .map((number, index) =>
          index === 3 ? Math.round(number * 255) : number
        ) // Converts alpha to 255 number
        .map((number) => number.toString(16)) // Converts numbers to hex
        .map((string) => (string.length === 1 ? "0" + string : string)) // Adds 0 when length of one number is 1
        .join("")
    ); // Puts the array to togehter to a string
  }

  static HexToRGBA(hex, alpha = 1) {
    const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
  }

  static setThemeColor(primary) {
    const lightColor = this.HexToRGBA(primary, 0.2);
    const transparentColor = this.HexToRGBA(primary, 0.1);

    document.documentElement.style.setProperty("--color-primary", primary);
    document.documentElement.style.setProperty(
      "--color-primary-light",
      this.RGBAToHexA(lightColor)
    );
    document.documentElement.style.setProperty(
      "--color-primary-transparent",
      this.RGBAToHexA(transparentColor)
    );
  }

  static getDarkTheme(darkMode) {
    return darkMode
      ? {
          main: "defaultDark",
          navbar: "defaultDark",
          toolbar: "defaultDark",
          footer: "defaultDark",
        }
      : settingsConfig.theme;
  }

  static difference(object, base) {
    const changes = (_object, _base) => {
      return _.transform(_object, (result, value, key) => {
        if (!_.isEqual(value, _base[key])) {
          result[key] =
            _.isObject(value) && _.isObject(_base[key])
              ? changes(value, _base[key])
              : value;
        }
      });
    };

    return changes(object, base);
  }

  static EventEmitter = EventEmitter;

  static updateNavItem(nav, id, item) {
    return nav.map((_item) => {
      if (_item.id === id) {
        return _.merge({}, _item, item);
      }

      if (_item.children) {
        return _.merge({}, _item, {
          children: this.updateNavItem(_item.children, id, item),
        });
      }

      return _.merge({}, _item);
    });
  }

  static removeNavItem(nav, id) {
    return nav
      .map((_item) => {
        if (_item.id === id) {
          return null;
        }

        if (_item.children) {
          return _.merge({}, _.omit(_item, ["children"]), {
            children: this.removeNavItem(_item.children, id),
          });
        }

        return _.merge({}, _item);
      })
      .filter((s) => s);
  }

  static prependNavItem(nav, item, parentId) {
    if (!parentId) {
      return [item, ...nav];
    }

    return nav.map((_item) => {
      if (_item.id === parentId && _item.children) {
        let alreadyExists = false;
        _item.children.map((el) => {
          if (el.id === item.id) alreadyExists = true;
        });
        if (alreadyExists)
          return {
            ..._item,
          };
        return {
          ..._item,
          children: [item, ..._item.children],
        };
      }

      if (_item.children) {
        return _.merge({}, _item, {
          children: this.prependNavItem(_item.children, item, parentId),
        });
      }

      return _.merge({}, _item);
    });
  }

  static appendNavItem(nav, item, parentId) {
    if (!parentId) {
      return [...nav, item];
    }

    return nav.map((_item) => {
      if (_item.id === parentId && _item.children) {
        let alreadyExists = false;
        _item.children.map((el) => {
          if (el.id === item.id) alreadyExists = true;
        });
        if (alreadyExists)
          return {
            ..._item,
          };
        return {
          ..._item,
          children: [..._item.children, item],
        };
      }

      if (_item.children) {
        return _.merge({}, _item, {
          children: this.appendNavItem(_item.children, item, parentId),
        });
      }

      return _.merge({}, _item);
    });
  }

  static hasPermission(authArr, userRole) {
    /**
     * If auth array is not defined
     * Pass and allow
     */
    if (authArr === null || authArr === undefined) {
      // console.info("auth is null || undefined:", authArr);
      return true;
    }
    if (authArr.length === 0) {
      /**
       * if auth array is empty means,
       * allow only user role is guest (null or empty[])
       */
      // console.info("auth is empty[]:", authArr);
      return !userRole || userRole.length === 0;
    }
    /**
     * Check if user has grants
     */
    // console.info("auth arr:", authArr);
    /*
            Check if user role is array,
            */
    if (userRole && Array.isArray(userRole)) {
      return authArr.some((r) => userRole.indexOf(r) >= 0);
    }

    /*
            Check if user role is string,
            */
    return authArr.includes(userRole);
  }

  static filterRecursive(data, predicate) {
    // if no data is sent in, return null, otherwise transform the data
    return !data
      ? null
      : data.reduce((list, entry) => {
          let clone = null;
          if (predicate(entry)) {
            // if the object matches the filter, clone it as it is
            clone = { ...entry };
          }
          if (entry.children != null) {
            // if the object has childrens, filter the list of children
            const children = this.filterRecursive(entry.children, predicate);
            if (children.length > 0) {
              // if any of the children matches, clone the parent object, overwrite
              // the children list with the filtered list
              clone = { ...entry, children };
            }
          }

          // if there's a cloned object, push it to the output list
          if (clone) {
            list.push(clone);
          }
          return list;
        }, []);
  }

  static detectURLs(message) {
    const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return message.match(urlRegex);
  }

  static getMessageContent(message) {
    if (!message) return;

    const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    if (message.search(urlRegex) == -1) return message;

    const style = {
      backgroundColor: "transparent",
      textDecoration: "none",
      //color: "#22d3ee",
    };

    const res = message.replace(urlRegex, (url) => {
      const hyperlink = url;
      if (!hyperlink.match("^https?://")) {
        hyperlink = "http://" + hyperlink;
      }
      return '<a href="' + hyperlink + '" target="_blank">' + url + "</a>";
    });
    return res;
  }

  static containerConfig(value) {
    return {
      show: {
        transition: {
          staggerChildren: value,
        },
      },
    };
  }

  static itemOpacityConfig(value) {
    return {
      hidden: { opacity: 0, y: value || 40 },
      show: { opacity: 1, y: 0 },
    };
  }

  static itemScaleConfig() {
    return {
      hidden: { opacity: 0, scale: 0.6 },
      show: { opacity: 1, scale: 1 },
    };
  }

  static labelColor = (darkMode) => {
    return {
      style: {
        colors: this.getChartFontColor(darkMode),
      },
    };
  };

  static getChartFontColor = (darkMode) => {
    return darkMode ? "#fff" : "#111827";
  };

  static getUserStatusBgClassName = (status) => {
    const USER_STATUS = this.getUserStatusItems();
    return status === USER_STATUS.ONLINE
      ? "bg-green-200"
      : status === USER_STATUS.AWAY
      ? "bg-yellow-100"
      : status === USER_STATUS.DND
      ? "bg-red-100"
      : "bg-offlineBg";
  };

  static getUserStatusClassName = (status) => {
    const USER_STATUS = this.getUserStatusItems();
    return status === USER_STATUS.ONLINE
      ? "text-greenText"
      : status === USER_STATUS.AWAY
      ? "text-awayText"
      : status === USER_STATUS.DND
      ? "text-dndText"
      : "text-gray-400";
  };

  static getSeverityItems() {
    return {
      CRITICAL: "critical",
      HIGH: "high",
      MEDIUM: "medium",
      LOW: "low",
    };
  }

  static getProjectStatus() {
    return {
      NOT_STARTED: "not-started",
      ACTIVE: "active",
      ON_HOLD: "hold",
      CLOSED: "closed",
    };
  }

  static getUserStatusItems() {
    return {
      ONLINE: "online",
      AWAY: "away",
      DND: "do not disturb",
      OFFLINE: "offline",
    };
  }

  static getTrackerStatus() {
    return {
      INITIAL: "initial",
      APPROVED: "approved",
      REJECTED: "rejected",
      PENDING: "pending",
      CHECKING: "checking",
    };
  }

  static getCustomFieldTypes() {
    return {
      STRING: "string",
      BOOLEAN: "boolean",
      NUMBER: "number",
      DATE: "date",
    };
  }

  static setCustomFields(customFieldsType, defaultValues, TYPES, reset) {
    if (customFieldsType?.length > 0) {
      const ids = new Set(defaultValues.customFields.map((x) => x.id));
      customFieldsType.forEach((x, idx) => {
        if (ids.has(x.id)) {
          defaultValues.customFields[idx] = {
            ...defaultValues.customFields[idx],
            fieldName: x.fieldName,
            fieldType: x.fieldType,
            active: x.active,
          };
        } else {
          defaultValues.customFields.push({
            ...x,
            fieldValue: x.fieldType === TYPES?.DATE ? null : "",
          });
        }
      });

      if (reset) reset(defaultValues);
    }
  }

  static updateCustomFields(customFields) {
    return customFields.map((item) => ({ ...item, editable: false }));
  }

  static mapCustomFields(customFields) {
    return customFields.map((x) => ({
      id: x.id,
      fieldValue: x.fieldValue,
    }));
  }

  static formatDate(date, format, callback) {
    const unformattedDate = date._seconds
      ? new Date(date._seconds * 1000)
      : date.seconds
      ? new Date(date.seconds * 1000)
      : new Date(date);

    return format && callback
      ? callback(unformattedDate, format)
      : unformattedDate;
  }

  static addTimelineEvent(user, message, dispatch, icon) {
    const timeline = {
      id: this.generateGUID(),
      user: user.uid,
      message: message,
      createdDate: new Date(),
      icon: icon ? icon : "user",
    };
    dispatch(addNewTimeline({ user, timeline }));
  }

  static errorNotification(user, dispatch, error) {
    dispatch(
      addNotification(
        NotificationModel({
          name: user.data?.displayName,
          photoUrl: user.data?.photoURL,
          color: user?.data?.more?.color,
          message: error?.message
            ? " " + error?.message + " "
            : " - Ooops! Something went wrong. Please try again. ",
          status: user.data?.status,
          options: { variant: "new-default" },
        })
      )
    );
  }

  static successNotification(
    user,
    message,
    postHighlightedMessage,
    dispatch,
    preHighlightedMessage,
    preHighlightedClass,
    postHighlightedClass,
    messageClass
  ) {
    const member = user.data || user;
    dispatch(
      addNotification(
        NotificationModel({
          name: member.displayName,
          photoUrl: member.photoURL,
          color: member.more?.color,
          preHighlightedClass: preHighlightedClass,
          preHighlightedMessage: preHighlightedMessage || "",
          messageClass: messageClass,
          message: message,
          postHighlightedClass: postHighlightedClass,
          postHighlightedMessage: postHighlightedMessage || "",
          status: member.status,
          options: { variant: "new-default" },
        })
      )
    );
  }

  static findMemberByEmail(members, email) {
    const member = members?.find((x) => x?.email === email);
    return { displayName: member?.displayName };
  }

  static findMemberById(members, id) {
    const member = _.isObject(members)
      ? members[id]
      : members?.find((x) => x?.id === id);
    return {
      avatar: member?.photoURL,
      name: member?.displayName,
      color: member?.more?.color,
      status: member?.status,
      mood: member?.mood,
    };
  }

  static findLabelById(labels, id) {
    const label = _.isObject(labels)
      ? labels[id]
      : labels?.find((x) => x?.id === id);
    return {
      id: label?.id,
      title: label?.title,
      color: label?.color,
    };
  }

  static checkUserPermissionsForProject(resources, email) {
    return (
      resources?.findIndex(
        (x) => x.email === email && x.role === "contributor"
      ) != -1
    );
  }

  static downloadFile(src) {
    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = () => {
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(xhr.response);
      // Hardcode the name for now, else we need to fetch from firebase to get image info
      a.download = "download";
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
    };
    xhr.open("GET", src);
    xhr.send();
  }

  static checkUserPermissionsForProject(resources, email) {
    return (
      resources?.findIndex(
        (x) => x.email === email && x.role === "contributor"
      ) != -1
    );
  }

  static async isNativeApp() {
    const isMobileApp = /iPhone|iPad|iPod|android|tablet/i.test(
      navigator.userAgent
    );
    if (isMobileApp) {
      const isStandAlone =
        window.matchMedia("(display-mode: standalone)").matches ||
        window.navigator.standalone;
      return isStandAlone;
    }
  }
}

export default Utilities;
