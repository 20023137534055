import BrowserRouter from "core/BrowserRouter";
import Authorization from "core/Authorization";
import Layout from "core/Layout";
import Theme from "core/Theme";
import { SnackbarProvider } from "notistack";
import { useSelector } from "react-redux";
//import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { selectCurrLangDir } from "store/i18nSlice";
import withAppProviders from "./withAppProviders";
import { Auth } from "./auth";
import { TourProvider } from "@reactour/tour";
import steps from "./steps";
import * as history from "history";

const emotionCacheOptions = {
  // rtl: {
  //   key: "muirtl",
  //   stylisPlugins: [rtlPlugin],
  //   insertionPoint: document.getElementById("emotion-insertion-point"),
  // },
  ltr: {
    key: "muiltr",
    stylisPlugins: [],
    insertionPoint: document.getElementById("emotion-insertion-point"),
  },
};

const App = () => {
  const langDirection = useSelector(selectCurrLangDir);
  const browserHistory = history.createBrowserHistory();

  return (
    <CacheProvider value={createCache(emotionCacheOptions[langDirection])}>
      <Auth>
        <BrowserRouter history={browserHistory}>
          {/* <TourProvider steps={steps}> */}
          <Authorization>
            <Theme>
              <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                classes={{
                  containerRoot: "bottom-0 left-0 z-99",
                }}
              >
                <Layout />
              </SnackbarProvider>
            </Theme>
          </Authorization>
          {/* </TourProvider> */}
        </BrowserRouter>
      </Auth>
    </CacheProvider>
  );
};

export default withAppProviders(App)();
