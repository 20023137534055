import React from "react";

const General = () => {
  const className = "fill-primaryBlue";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70.338"
      height="71.059"
      viewBox="0 0 70.338 71.059"
      className={className}
    >
      <g
        id="Group_45980"
        data-name="Group 45980"
        transform="translate(-503.188 -315)"
      >
        <path
          id="Path_39905"
          data-name="Path 39905"
          d="M7.885,78.059a10.084,10.084,0,0,1-6.3-4.63,8.894,8.894,0,0,1,.975-10.583c5.86-6.033,11.921-11.871,18.249-18.135l3.818,4.8c1.675-1.755,2.915-3.107,4.212-4.405,6.282-6.291,12.6-12.548,18.838-18.88a4.134,4.134,0,0,0,1-2.45c.143-2.041,0-4.1.082-6.15a2.043,2.043,0,0,1,.718-1.525c5.6-2.873,11.251-5.666,17.163-8.616,1.206,1.3,2.462,2.574,3.6,3.943a1.659,1.659,0,0,1,.185,1.463q-3.908,8-7.961,15.919c-.225.44-.915.66-1.417.931-.163.089-.413.014-.624.016-2.415.032-5.1-.583-7.171.278s-3.558,3.177-5.263,4.893c-6.153,6.191-12.29,12.4-18.759,18.929l4.775,3.794c-3.048,3.04-5.653,5.635-8.255,8.234-2.793,2.788-5.631,5.532-8.353,8.386a12.458,12.458,0,0,1-6.043,3.788Z"
          transform="translate(503 308)"
        />
        <path
          id="Path_39906"
          data-name="Path 39906"
          d="M17.361,7a15.6,15.6,0,0,1,10.355,6.564A14.77,14.77,0,0,1,30.209,23.9a6.265,6.265,0,0,0,1.852,5.459c1.143,1.144,2.373,2.2,3.5,3.238l-3.674,3.727c-1.37-1.472-2.756-2.966-4.148-4.454-.9-.964-1.934-1.332-3.039-.405-.938.786-.8,1.839.332,3,1.424,1.467,2.874,2.908,4.254,4.3-1.115,1.141-2.189,2.242-3.467,3.551l-2.738-3A6.907,6.907,0,0,0,17,37.005,14.876,14.876,0,0,1,2.988,30.8,14.483,14.483,0,0,1,.278,20.373,1.652,1.652,0,0,1,1,19.141c.336-.12.97.222,1.309.538,1.42,1.329,2.751,2.752,4.163,4.09a5.4,5.4,0,0,0,7.782.017c.961-.9,1.893-1.828,2.783-2.794a5.382,5.382,0,0,0-.084-7.782c-1.305-1.371-2.641-2.715-4.017-4.015C12.1,8.41,12,7.73,12.939,7Z"
          transform="translate(503 308)"
        />
        <path
          id="Path_39907"
          data-name="Path 39907"
          d="M41.864,46.479l3.818-3.408c.126.11.4.323.648.568,6.51,6.508,13.044,12.992,19.513,19.54a6.649,6.649,0,0,1-2.859,11.29A5.875,5.875,0,0,1,57.072,73.2C50.1,66.27,43.231,59.244,36.249,52.177l3.3-3.131c.352.332.77.709,1.168,1.107q8.874,8.866,17.744,17.738a9.081,9.081,0,0,0,1.034.969c.888.644,1.953.691,2.5-.222a3.884,3.884,0,0,0,.025-2.413c-.073-.387-.611-.691-.945-1.025q-8.814-8.815-17.64-17.619a11.959,11.959,0,0,0-1.565-1.1"
          transform="translate(503 308)"
        />
      </g>
    </svg>
  );
};

export default General;
