import React from "react";

const Arrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 34.327 25.243"
      className="stroke-primaryBlue"
    >
      <g
        id="Icon_feather-arrow-down"
        data-name="Icon feather-arrow-down"
        transform="translate(4.327 30.621) rotate(-90)"
      >
        <path
          id="Path_37764"
          data-name="Path 37764"
          d="M18,7.5V38.827"
          transform="translate(0 -10.327)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <path
          id="Path_37765"
          data-name="Path 37765"
          d="M28.5,18,18,28.5,7.5,18"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
      </g>
    </svg>
  );
};

export default Arrow;
