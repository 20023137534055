import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import firebase from "services/firebase";
import Utilities from "utils/utilities";

export const removeCard = createAsyncThunk(
  "scrumboardApp/card/removeCard",
  async ({ user, projectId, board, cardId }, { dispatch, getState }) => {
    // DO NOT DELETE THE DATA - JUST ARCHIVE IT
    // let sections = [];
    // board.sections.filter((section) => {
    //   let cards = [];
    //   let _section;
    //   section.idCards.filter((card) => {
    //     if (card !== cardId) {
    //       cards = [...cards, card];
    //     }
    //   });
    //   _section = {
    //     id: section.id,
    //     idCards: cards,
    //     name: section.name,
    //   };
    //   sections = [...sections, _section];
    //   return section;
    // });
    // let newCards = [];
    // board.cards.filter((card) => {
    //   if (card.id !== cardId) {
    //     newCards = [...newCards, card];
    //   }
    //   return card;
    // });

    const newCards = board.cards.map((card) => {
      if (card.id === cardId) return { ...card, archive: true };
      else return card;
    });
    await firebase.addCard(user, projectId, board.id, board.sections, newCards);
    dispatch(closeCardDialog());

    const message = " has deleted the card - ";
    Utilities.successNotification(user, message, cardId, dispatch);

    return cardId;
  }
);

export const getTasksByParentId = createAsyncThunk(
  "scrumboardApp/card/getTasksByParentId",
  async ({ user, card }) => {
    const data = await firebase.getTasksByParentId(user, card.id);
    return Promise.resolve(data);
  }
);

export const getNonCompletedAndOrphanTasks = createAsyncThunk(
  "scrumboardApp/card/getNonCompletedAndOrphanTasks",
  async ({ user, card }) => {
    const data = await firebase.getNonCompletedAndOrphanTasks(user, card.id);
    return Promise.resolve(data);
  }
);

const cardSlice = createSlice({
  name: "scrumboardApp/card",
  initialState: {
    dialogOpen: false,
    data: null,
  },
  reducers: {
    openCardDialog: (state, action) => {
      state.dialogOpen = true;
      state.data = action.payload;
    },
    closeCardDialog: (state, action) => {
      state.dialogOpen = false;
      state.data = null;
    },
  },
  extraReducers: {},
});

export const { openCardDialog, closeCardDialog } = cardSlice.actions;

export default cardSlice.reducer;
