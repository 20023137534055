import { styled } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import PropTypes from "prop-types";
import { useMemo } from "react";
import NavBadge from "../../NavBadge";
import { Box } from "@mui/system";
import icons from "configs/icons";

const Root = styled(ListItem)(({ theme, ...props }) => ({
  height: 40,
  width: "100%",
  borderRadius: "6px",
  margin: "0 0 4px 0",
  paddingRight: 12,
  paddingLeft: props.itempadding > 80 ? 80 : props.itempadding,
  "&.active": {
    backgroundColor: `${theme.palette.secondary.main}!important`,
    color: `${theme.palette.secondary.contrastText}!important`,
    pointerEvents: "none",
    transition: "border-radius .15s cubic-bezier(0.4,0.0,0.2,1)",
    "& > .default-list-item-text-primary": {
      color: "inherit",
    },
    "& > .default-list-item-icon": {
      color: "inherit",
    },
  },
  "& > .default-list-item-icon": {
    marginRight: 12,
  },
  "& > .default-list-item-text": {},
  color: theme.palette.text.primary,
  textDecoration: "none!important",
}));

const NavVerticalLink = (props) => {
  const { item, nestedLevel, onItemClick } = props;

  const itempadding = nestedLevel > 0 ? 28 + nestedLevel * 16 : 12;
  const NavIcon = icons[item.icon];

  return useMemo(
    () => (
      <Root
        button
        component="a"
        href={item.url}
        target={item.target ? item.target : "_blank"}
        className="default-list-item"
        onClick={() => onItemClick && onItemClick(item)}
        role="button"
        itempadding={itempadding}
      >
        {item.icon && (
          <Box className="w-6 mr-4">
            <NavIcon />
          </Box>
        )}

        <ListItemText
          className="default-list-item-text"
          primary={item.title}
          classes={{ primary: "text-13 default-list-item-text-primary" }}
        />

        {item.badge && <NavBadge badge={item.badge} />}
      </Root>
    ),
    [item, itempadding, onItemClick]
  );
};

NavVerticalLink.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.string,
    url: PropTypes.string,
    target: PropTypes.string,
  }),
};
NavVerticalLink.defaultProps = {};

export default NavVerticalLink;
