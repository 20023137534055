import React from "react";

const Mood = () => {
  return (
    <svg
      id="mood"
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 31.579 31.579"
    >
      <path
        id="Path_336"
        data-name="Path 336"
        d="M15.789,0A15.789,15.789,0,1,0,31.579,15.789,15.836,15.836,0,0,0,15.789,0Zm5.526,9.474a2.428,2.428,0,0,1,2.368,2.368,2.368,2.368,0,1,1-4.737,0A2.428,2.428,0,0,1,21.316,9.474Zm-11.053,0a2.428,2.428,0,0,1,2.368,2.368,2.428,2.428,0,0,1-2.368,2.368,2.368,2.368,0,0,1,0-4.737Zm5.526,15.789a9.4,9.4,0,0,1-8.842-6.316H24.631A9.4,9.4,0,0,1,15.789,25.263Z"
        fill="#b8bcd5"
      />
    </svg>
  );
};

export default Mood;
