import WidgetWrapper from "components/WidgetWrapper";
import SearchBar from "components/header/SearchBar";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import withReducer from "store/withReducer";
import MemberLists from "./MemberLists";
import reducer from "./store";
import { selectMembers } from "./store/membersSlice";
import { Root } from "settings/styleOverrides";

const MembersApp = (props) => {
  const routeParams = useParams();
  const [showCardView, setCardView] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const user = useSelector(({ auth }) => auth.user);
  const members = useSelector(selectMembers);

  const Main = () => (
    <Root
      header={
        <SearchBar
          setSearchQuery={setSearchQuery}
          searchQuery={searchQuery}
          showCardViewBtn={true}
          showCardView={showCardView}
          setCardView={setCardView}
        />
      }
      content={
        <MemberLists
          id={routeParams.memberId}
          members={members}
          user={user}
          showCardView={showCardView}
          searchQuery={searchQuery}
        />
      }
    />
  );
  return props?.widget ? (
    <WidgetWrapper {...props}>
      <Main />
    </WidgetWrapper>
  ) : (
    <Main />
  );
};

export default withReducer("membersApp", reducer)(MembersApp);
