import React from "react";

const Completed = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 31 31"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_19562"
            data-name="Rectangle 19562"
            width="17.279"
            height="13.457"
            fill="green"
          />
        </clipPath>
      </defs>
      <g
        id="Group_45955"
        data-name="Group 45955"
        transform="translate(-370 -534)"
      >
        <g
          id="Ellipse_1407"
          data-name="Ellipse 1407"
          transform="translate(370 534)"
          fill="#fff"
          stroke="green"
          strokeWidth="2"
        >
          <circle cx="15.5" cy="15.5" r="15.5" stroke="none" />
          <circle cx="15.5" cy="15.5" r="14.5" fill="none" />
        </g>
        <g
          id="Group_45866"
          data-name="Group 45866"
          transform="translate(376.861 542.771)"
          clip-path="url(#clip-path)"
        >
          <path
            id="Path_39862"
            data-name="Path 39862"
            d="M16.735,3.154Q11.872,8.029,7,12.89a1.8,1.8,0,0,1-2.617,0Q2.444,10.99.543,9.055A1.822,1.822,0,0,1,.53,6.472a1.845,1.845,0,0,1,2.622-.02c.814.79,1.6,1.61,2.518,2.545l1.5-1.5h0q3.445-3.455,6.9-6.9A1.787,1.787,0,0,1,15.981.1a1.765,1.765,0,0,1,1.275,1.515,1.731,1.731,0,0,1-.52,1.542"
            transform="translate(0 0)"
            fill="green"
          />
        </g>
      </g>
    </svg>
  );
};

export default Completed;
