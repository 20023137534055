import { Box, Modal, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { closeLabelDialog } from "store/slice/labelDialogSlice";
import LabelsForm from "./LabelsForm";
import { modalstyle } from "settings/styleOverrides";
import Close from "components/svgs/icons/general/Close";

const LabelsDialog = () => {
  const dispatch = useDispatch();
  const labelsDialogState = useSelector(({ entities }) => entities.labelDialog);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));

  const style = modalstyle();
  style.width = isSmall ? "90%" : mdDown ? "80%" : lgDown ? "65%" : "50%";
  style.minHeight = 600;

  return (
    <Modal
      BackdropProps={{ style: { backgroundColor: "grey", opacity: "0.4" } }}
      open={labelsDialogState.state}
      onClose={(ev) => dispatch(closeLabelDialog())}
      aria-labelledby="modal-new-note"
      aria-describedby="modal-create-note"
    >
      <Box sx={style}>
        <div className="flex justify-between bg-primaryBlue py-4 px-6">
          <Typography className="text-lg font-medium text-white">
            Edit Labels
          </Typography>
          <Box
            onClick={(ev) => dispatch(closeLabelDialog())}
            className="cursor-pointer"
          >
            <Close />
          </Box>
        </div>
        <div className="p-4 pt-6 w-full overflow-y-auto">
          <LabelsForm options={labelsDialogState.options} />
          {/* TODO: <Pagination /> */}
        </div>
      </Box>
    </Modal>
  );
};

export default LabelsDialog;
