import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { endOfWeek, startOfWeek } from "date-fns";
import endOfDay from "date-fns/endOfDay";
import startOfDay from "date-fns/startOfDay";
import firebase from "services/firebase";
import Utilities from "utils/utilities";

const uploadMediaAttachment = async (user, task, isUpdateMediaDirty) => {
  if (task.media?.length == 0 || !isUpdateMediaDirty) return task;

  const path = `${user.data.currentWorkspace}/users/${user.uid}/tasks/${task.id}`;
  for (let i = 0; i < task.media.length; i++) {
    const media = { ...task.media[i] };
    if (!media.file?.name) continue;
    const mediaUrl = await firebase.uploadAttachment(path, media.file);
    media.url = mediaUrl;
    media.file = media.file.name;
    task.media[i] = media;
  }
  return task;
};

export const getTasks = createAsyncThunk(
  "taskApp/tasks/getTasks",
  async (
    { userId, workspaceId, routeParams, startDate, endDate, initialPoint },
    { getState, dispatch }
  ) => {
    if (!userId) {
      dispatch(setUserId(getState().auth.user.uid));
      return;
    }
    dispatch(setLoading(true));
    const { maxQuantity } = getState().taskApp.tasks;
    const { data, lastVisible } = await firebase.getTasksByRangeWithoutLimit(
      userId,
      workspaceId,
      startOfDay(startDate),
      endOfDay(endDate),
      initialPoint,
      maxQuantity,
      routeParams
    );

    dispatch(setLast(lastVisible));
    if (data.length === 0 || data.length < maxQuantity)
      dispatch(setMore(false));
    else dispatch(setMore(true));
    dispatch(setLoading(false));
    return Promise.resolve({ data, initialPoint });
  }
);

export const getTasksOfUsers = createAsyncThunk(
  "taskApp/tasks/getTasksOfUsers",
  async ({
    user,
    start,
    end,
    ids,
    labelIds,
    completed,
    favourite,
    priority,
  }) => {
    return await firebase.getTasksOfUsers(
      user,
      start,
      end,
      ids,
      labelIds,
      completed,
      favourite,
      priority
    );
  }
);

export const addTask = createAsyncThunk(
  "taskApp/tasks/addTask",
  async ({ user, task, isUpdateMediaDirty }, { dispatch, getState }) => {
    task.id = firebase.generateFirebaseDocId(user, "tasks");
    task = await uploadMediaAttachment(user, task, isUpdateMediaDirty);
    await firebase.setTasks(user, task);

    const message = " has created new task ";
    Utilities.successNotification(user, message, task.title, dispatch);
    return task;
  }
);

export const updateTask = createAsyncThunk(
  "taskApp/tasks/updateTask",
  async ({ user, task, isUpdateMediaDirty }, { dispatch, getState }) => {
    task = await uploadMediaAttachment(user, task, isUpdateMediaDirty);

    await firebase.updateTask(user, task);
    const message = " has updated the task ";
    Utilities.successNotification(user, message, task.title, dispatch);
    return task;
  }
);

export const removeTask = createAsyncThunk(
  "taskApp/tasks/removeTask",
  async ({ user, task }, { dispatch, getState }) => {
    await firebase.removeTask(user, task);

    const message = " has removed the task ";
    Utilities.successNotification(user, message, task.title, dispatch);
    return task.id;
  }
);

const tasksAdapter = createEntityAdapter({});

export const { selectAll: selectTasks, selectById: selectTasksById } =
  tasksAdapter.getSelectors((state) => state.taskApp?.tasks);

const tasksSlice = createSlice({
  name: "taskApp",
  initialState: tasksAdapter.getInitialState({
    orderBy: "",
    orderDescending: false,
    taskFormDialog: {
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
    startDate: startOfWeek(new Date()),
    endDate: endOfWeek(new Date()),
    lastVisible: null,
    maxQuantity: 3,
    moreExists: true,
    loading: false,
    userId: null,
    filter: [],
  }),
  reducers: {
    setTaskFilter: (state, action) => {
      state.filter = action.payload;
    },
    toggleOrderDescending: (state, action) => {
      state.orderDescending = !state.orderDescending;
    },
    changeOrder: (state, action) => {
      state.orderBy = action.payload;
    },
    openNewTaskFormDialog: (state, action) => {
      state.taskFormDialog = {
        type: "new",
        props: {
          open: true,
          linkedParent: action.payload?.linkedParent,
        },
        data: null,
      };
    },
    closeNewTaskFormDialog: (state, action) => {
      state.taskFormDialog = {
        type: "new",
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditTaskFormDialog: (state, action) => {
      state.taskFormDialog = {
        type: "edit",
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditTaskFormDialog: (state, action) => {
      state.taskFormDialog = {
        type: "edit",
        props: {
          open: false,
        },
        data: null,
      };
    },
    setRange: (state, { payload }) => {
      state.startDate = payload.startDate;
      state.endDate = payload.endDate;
    },
    setLast: (state, { payload }) => {
      state.lastVisible = payload;
    },
    setMore: (state, { payload }) => {
      state.moreExists = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setUserId: (state, { payload }) => {
      state.userId = payload;
    },
  },
  extraReducers: {
    [updateTask.fulfilled]: tasksAdapter.upsertOne,
    [addTask.fulfilled]: tasksAdapter.addOne,
    [removeTask.fulfilled]: tasksAdapter.removeOne,
    [getTasks.fulfilled]: (state, action) => {
      if (!action.payload) return;
      const { data, initialPoint } = action.payload;
      if (initialPoint) {
        tasksAdapter.addMany(state, data);
      } else {
        tasksAdapter.setAll(state, data);
      }
    },
    [getTasks.rejected]: (state, action) => {
      console.log(action?.error?.message);
    },
    [getTasksOfUsers.fulfilled]: tasksAdapter.setAll,
    [getTasksOfUsers.rejected]: (state, action) => {
      console.error(action?.error?.message);
    },
  },
});

export const {
  setTaskFilter,
  toggleOrderDescending,
  changeOrder,
  openNewTaskFormDialog,
  closeNewTaskFormDialog,
  openEditTaskFormDialog,
  closeEditTaskFormDialog,
  setLast,
  setRange,
  setMore,
  setLoading,
  setUserId,
} = tasksSlice.actions;

export default tasksSlice.reducer;
