import Icon from "@mui/material/Icon";
import Utilities from "utils/utilities";
import Away from "./svgs/icons/status/Away";
import Online from "./svgs/icons/status/Online";
import Dnd from "./svgs/icons/status/Dnd";
import Offline from "./svgs/icons/status/Offline";

const StatusIcon = (props) => {
  const USER_STATUS = Utilities.getUserStatusItems();
  switch (props.status) {
    case USER_STATUS.ONLINE:
      return props.needBg ? (
        <Icon
          sx={{ color: "#48BF53", width: props.width, height: props.height }}
          className={`block text-16 text-green bg-white rounded-full scale-75`}
        >
          check_circle
        </Icon>
      ) : (
        <Online height={props.height} width={props.width} />
      );
    case USER_STATUS.AWAY:
      return props.needBg ? (
        <Icon className="block text-16 text-white bg-awayText rounded-full scale-75">
          access_time
        </Icon>
      ) : (
        <Away height={props.height} width={props.width} />
      );
    case USER_STATUS.DND:
      return props.needBg ? (
        <Icon className="block text-16 text-dndText bg-white rounded-full scale-75">
          remove_circle_outline
        </Icon>
      ) : (
        <Dnd height={props.height} width={props.width} />
      );
    case USER_STATUS.OFFLINE:
      return props.needBg ? (
        <Icon className="block text-16 text-grey-700 bg-white rounded-full scale-75">
          not_interested
        </Icon>
      ) : (
        <Offline height={props.height} width={props.width} />
      );
    default:
      return null;
  }
};

export default StatusIcon;
