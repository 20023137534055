import { lazy } from "react";
import { authRoles } from "auth";

const NotesApp = lazy(() => import("./NotesApp"));

const NotesAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: "/:workspaceId/notes",
      element: <NotesApp />,
      title: "Notes",
      children: [
        {
          path: ":id",
          element: <NotesApp />,
          title: "Notes",
          children: [
            {
              path: ":labelHandle",
              element: <NotesApp />,
              title: "Notes",
              children: [{ path: ":labelId", element: <NotesApp /> }],
            },
          ],
        },
      ],
    },
  ],
};

export default NotesAppConfig;
