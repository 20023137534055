const Business = () => {
  return (
    <svg
      id="Group_46007"
      data-name="Group 46007"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 40.965 29"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_19611"
            data-name="Rectangle 19611"
            width="40.965"
            height="29"
            fill="#6e6e6e"
          />
        </clipPath>
      </defs>
      <g
        id="Group_45995"
        data-name="Group 45995"
        transform="translate(0 0)"
        clipPath="url(clipPath)"
      >
        <path
          id="Path_39915"
          data-name="Path 39915"
          d="M25.012,20.054,35.888,9.2c-.654,0-1.3,0-1.951,0-.294,0-.591.015-.883-.01a1.435,1.435,0,0,1,.091-2.866q3.174-.017,6.347,0a1.428,1.428,0,0,1,1.461,1.51q.024,3.231,0,6.462a1.457,1.457,0,1,1-2.906.009c-.021-.82-.006-1.641-.007-2.462v-.519c-.189.161-.308.247-.41.349q-4.746,4.744-9.488,9.491c-.644.644-1.29,1.284-1.934,1.928a1.46,1.46,0,0,1-2.182.02c-1.064-1.056-2.119-2.119-3.179-3.181q-2.5-2.5-4.992-5.01c-.178-.18-.287-.178-.465,0q-2.628,2.647-5.272,5.279Q6.325,23.992,2.53,27.784a1.443,1.443,0,0,1-2.07.046,1.42,1.42,0,0,1,.011-2.066q3.208-3.236,6.44-6.448,3.794-3.792,7.59-7.581a1.441,1.441,0,0,1,2.234-.016q2,1.972,3.968,3.97c1.452,1.463,2.9,2.933,4.309,4.364"
          transform="translate(0.002 0.755)"
          fill="#6e6e6e"
        />
        <path
          id="Path_39916"
          data-name="Path 39916"
          d="M2.053,11.166q0,5.037,0,10.073A1.018,1.018,0,0,1,.8,22.346a.971.971,0,0,1-.781-.841A3.137,3.137,0,0,1,0,21.121Q0,11.2,0,1.283A1.15,1.15,0,0,1,.286.324,1,1,0,0,1,1.345.059a1,1,0,0,1,.7.918c.011.128,0,.256,0,.384v9.8"
          transform="translate(0 0)"
          fill="#6e6e6e"
        />
        <path
          id="Path_39917"
          data-name="Path 39917"
          d="M6.853,8.576q0,3.631,0,7.263c0,.814-.383,1.319-1.015,1.322-.668,0-1.074-.485-1.074-1.31q0-7.282,0-14.564A1.152,1.152,0,0,1,5.419.095.985.985,0,0,1,6.766.67a1.552,1.552,0,0,1,.082.489q.008,3.708,0,7.416"
          transform="translate(0.569 0.001)"
          fill="#6e6e6e"
        />
        <path
          id="Path_39918"
          data-name="Path 39918"
          d="M9.433,6.552q0-2.711,0-5.421A1.041,1.041,0,0,1,10.005.119a.988.988,0,0,1,1.086.1,1.144,1.144,0,0,1,.424,1.022c0,1.038,0,2.077,0,3.115q0,3.654,0,7.308a3.177,3.177,0,0,1-.05.61A1.015,1.015,0,0,1,9.5,12.35a2.242,2.242,0,0,1-.065-.568c0-1.744,0-3.488,0-5.231"
          transform="translate(1.128 0)"
          fill="#6e6e6e"
        />
      </g>
    </svg>
  );
};

export default Business;
