import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import NavItem from "../NavItem";
import { useSelector } from "react-redux";

const StyledList = styled(List)(({ theme }) => ({
  "& .default-list-item": {
    "&:hover": {
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255, 255, 255, 0.05)"
          : "rgba(0,0,0,.04)",
    },
    "&:focus:not(.active)": {
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255, 255, 255, 0.06)"
          : "rgba(0,0,0,.05)",
    },
  },
  "&.active-square-list": {
    "& .default-list-item, & .active.default-list-item": {
      width: "100%",
      borderRadius: "0",
    },
  },
  "&.dense": {
    "& .default-list-item": {
      paddingTop: 0,
      paddingBottom: 0,
      height: 32,
    },
  },
}));

const NavVerticalLayout1 = (props) => {
  const { navigation, active, dense, className, onItemClick } = props;
  const isNativeApp = useSelector(
    ({ entities }) => entities.settings.isNativeApp
  );
  const handleItemClick = (item) => {
    onItemClick?.(item);
  };

  return (
    <StyledList
      className={clsx(
        "navigation whitespace-nowrap px-4",
        `active-${active}-list`,
        dense && "dense",
        className
      )}
    >
      {navigation.map((_item) => {
        if (_item.native && !isNativeApp) return null;
        return (
          <NavItem
            key={_item.id}
            type={`vertical-${_item.type}`}
            item={_item}
            nestedLevel={0}
            onItemClick={handleItemClick}
          />
        );
      })}
    </StyledList>
  );
};

export default NavVerticalLayout1;
