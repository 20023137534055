import React from "react";

const Filter = (props) => {
  const { height = 16, width = 16, className = "#fff" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 15.277 15.253"
      className={className}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_18753"
            data-name="Rectangle 18753"
            width="15.277"
            height="15.253"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g id="Group_43952" data-name="Group 43952" clipPath="url(#clipPath)">
        <path
          id="Path_38770"
          data-name="Path 38770"
          d="M7.64,0H14.4a1.472,1.472,0,0,1,.33.026.7.7,0,0,1,.52.539.655.655,0,0,1-.185.69L13.37,2.945q-1.751,1.749-3.5,3.5a.33.33,0,0,0-.109.257c0,2.6,0,5.191,0,7.786a.741.741,0,0,1-.963.732.775.775,0,0,1-.29-.172q-1.387-1.376-2.768-2.762a.765.765,0,0,1-.226-.567q0-2.515,0-5.03a.3.3,0,0,0-.093-.23q-2.6-2.6-5.2-5.2A.671.671,0,0,1,.053.5.689.689,0,0,1,.688,0c.065,0,.13,0,.195,0H7.64"
          transform="translate(0)"
          fill={className}
        />
      </g>
    </svg>
  );
};

export default Filter;
