import React from "react";
import { Button } from "@mui/material";
import placeholders from "configs/placeholders";

const EmptyPanel = (props) => {
  const {
    mainClass,
    imgClass,
    mainText,
    subText,
    buttonText,
    onBtnClick,
    imageSrc,
    searchQuery,
  } = props;
  const EmpptyPlaceholders = searchQuery
    ? placeholders.emptySearch
    : placeholders[imageSrc];

  return (
    <div
      className={`flex h-fit justify-center items-center flex-col gap-y-3 ${mainClass}`}
    >
      {/* Image */}
      <div className={imgClass}>
        <EmpptyPlaceholders />
      </div>
      {/* Title text */}
      <div className="text-2xl flex justify-center mt-5 items-center mr-0 font-semibold">
        {searchQuery ? "No results found!" : mainText}
      </div>
      {/* text */}
      <div className="w-3/4 md:w-1/3 lg:w-[350px] font-medium text-base text-center mt-3 text-mute">
        {searchQuery
          ? "We could not find any relevant results. Please try to search with other keywords."
          : subText}
      </div>
      {/* Button */}
      {buttonText && (
        <div>
          <Button
            type="button"
            onClick={onBtnClick}
            className="rounded-[5px] mt-5 bg-primaryBlue hover:bg-primaryBlueLight text-lg px-2 py-3 md:px-5 text-white w-full"
          >
            {buttonText}
          </Button>
        </div>
      )}
    </div>
  );
};

export default EmptyPanel;
