import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import firebase from "services/firebase";
import { showMessage } from "store/slice/messageSlice";
import _ from "utils/lodash";
import Utilities from "utils/utilities";
import CardModel from "../model/CardModel";
import ListModel from "../model/ListModel";
import { removeCard } from "./cardSlice";
import reorder, { reorderQuoteMap } from "./reorder";

export const getBoard = createAsyncThunk(
  "scrumboardApp/board/getBoard",
  async ({ user, params, showArchived }, { dispatch, getState }) => {
    try {
      const data = await firebase.getBoardById(user, params);
      if (!showArchived && data && data?.cards?.length > 0) {
        return { ...data, cards: [...data.cards.filter((x) => !x.archive)] };
      }
      return data;
    } catch (error) {
      // TODO: figure out why we need this block of code
      dispatch(
        showMessage({
          message: error.response.data,
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        })
      );
      return null;
    }
  }
);

export const getBacklogBoard = createAsyncThunk(
  "workspace/getBacklogBoard",
  async ({ workspaceId, backlogId }, { dispatch, getState }) => {
    try {
      const data = await firebase.getBacklogItemsById(workspaceId, backlogId);
      return data;
    } catch (error) {
      // TODO: figure out why we need this block of code
      dispatch(
        showMessage({
          message: error.response.data,
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        })
      );
      return null;
    }
  }
);

export const reorderSection = createAsyncThunk(
  "scrumboardApp/board/reorderSection",
  async ({ user, projectId, board, result }, { dispatch, getState }) => {
    const { sections } = board;

    const ordered = reorder(
      _.merge([], sections),
      result.source.index,
      result.destination.index
    );
    firebase.editSections(user, projectId, board.id, ordered);

    const message = " has reordered the sections ";
    Utilities.successNotification(user, message, null, dispatch);

    return ordered;
  }
);

export const reorderCard = createAsyncThunk(
  "scrumboardApp/board/reorderCard",
  async ({ user, projectId, board, result }, { dispatch, getState }) => {
    const { source, destination } = result;
    const { sections } = board;

    const ordered = reorderQuoteMap(_.merge([], sections), source, destination);
    firebase.editSections(user, projectId, board.id, ordered);

    const message = " has reordered new card - ";
    Utilities.successNotification(user, message, null, dispatch);

    return ordered;
  }
);

export const updateCard = createAsyncThunk(
  "scrumboardApp/board/updateCard",
  async ({ user, projectId, board, card }, { dispatch, getState }) => {
    let newCards = [];
    let newCard;
    let attachments = [];
    for (let i = 0; i < card.attachments.length; i++) {
      if (!card.attachments[i].src) {
        const path = `${user.data.currentWorkspace}/projects/${projectId}/boards/${board.id}`;
        const url = await firebase.uploadAttachment(
          path,
          card.attachments[i].image
        );
        attachments = [
          ...attachments,
          {
            id: card.attachments[i].id,
            name: card.attachments[i].name,
            src: url,
            createdDate: card.attachments[i].createdDate,
            type: card.attachments[i].type,
          },
        ];
      } else {
        attachments = [...attachments, card.attachments[i]];
      }
    }
    newCard = { ...card, attachments };
    board.cards.filter((_card) => {
      if (_card.id !== card.id) {
        newCards = [...newCards, _card];
      } else {
        newCards = [...newCards, newCard];
      }
      return card;
    });
    const updatedBoard = { ...board, cards: newCards };
    await firebase.addCard(
      user,
      projectId,
      updatedBoard.id,
      updatedBoard.sections,
      updatedBoard.cards
    );

    const message = " has updated the card ";
    Utilities.successNotification(user, message, null, dispatch);

    return updatedBoard;
  }
);

export const newCard = createAsyncThunk(
  "scrumboardApp/board/newCard",
  async (
    { user, projectId, board, sectionId, cardTitle },
    { dispatch, getState }
  ) => {
    const newCard1 = CardModel({ name: cardTitle, idMembers: [user.uid] });
    let sections = [];

    board.sections.filter((section) => {
      let _section;
      if (section.id === sectionId) {
        _section = {
          id: section.id,
          idCards: [...section.idCards, newCard1.id],
          name: section.name,
        };
      } else {
        _section = section;
      }
      sections = [...sections, _section];
      return section;
    });
    const updatedBoard = {
      ...board,
      sections: sections,
      cards: [...board.cards, newCard1],
    };
    await firebase.addCard(
      user,
      projectId,
      updatedBoard.id,
      updatedBoard.sections,
      updatedBoard.cards
    );

    const message = " has created new card - ";
    Utilities.successNotification(user, message, cardTitle, dispatch);

    return updatedBoard;
  }
);

export const newSection = createAsyncThunk(
  "scrumboardApp/board/newSection",
  async ({ user, projectId, board, sectionTitle }, { dispatch, getState }) => {
    const data = await firebase.editSections(user, projectId, board.id, [
      ...getState().scrumboardApp.board.sections,
      ListModel({ name: sectionTitle }),
    ]);

    const message = " has created new section ";
    Utilities.successNotification(user, message, sectionTitle, dispatch);

    return data;
  }
);

export const renameSection = createAsyncThunk(
  "scrumboardApp/board/renameSection",
  async (
    { user, projectId, board, sectionId, sectionTitle },
    { dispatch, getState }
  ) => {
    let sections = [];
    board.sections.filter((section) => {
      let _section;
      if (section.id === sectionId) {
        _section = {
          id: section.id,
          idCards: section.idCards,
          name: sectionTitle,
        };
      } else {
        _section = section;
      }
      sections = [...sections, _section];
      return section;
    });
    await firebase.editSections(user, projectId, board.id, sections);

    const message = " has renamed the section ";
    Utilities.successNotification(user, message, null, dispatch);

    return { sectionTitle, sectionId };
  }
);

export const removeSection = createAsyncThunk(
  "scrumboardApp/board/removeSection",
  async ({ user, projectId, board, sectionId }, { dispatch, getState }) => {
    let sections = [];
    board.sections.filter((section) => {
      if (section.id !== sectionId) {
        sections = [...sections, section];
        return section;
      }
    });
    await firebase.editSections(user, projectId, board.id, sections);

    const message = " has removed section ";
    Utilities.successNotification(user, message, null, dispatch);

    return sectionId;
  }
);

export const changeBoardSettings = createAsyncThunk(
  "scrumboardApp/board/changeBoardSettings",
  async ({ user, projectId, board, newSettings }, { dispatch, getState }) => {
    const settings = _.merge({}, board.settings, newSettings);
    const data = await firebase.updateBoardSettings(
      user,
      projectId,
      board.id,
      settings
    );

    const message = " has updated ";
    Utilities.successNotification(user, message, "board settings", dispatch);

    return data;
  }
);

export const customizeBoardSettings = createAsyncThunk(
  "scrumboardApp/board/customizeBoardSettings",
  async ({ user, projectId, board, customize }, { dispatch, getState }) => {
    const data = await firebase.customizeBoardSettings(
      user,
      projectId,
      board.id,
      customize
    );

    const message = " has customized ";
    Utilities.successNotification(user, message, "board settings", dispatch);

    return data;
  }
);

export const deleteBoard = createAsyncThunk(
  "scrumboardApp/board/deleteBoard",
  async ({ user, projectId, id }, { dispatch, getState }) => {
    await firebase.deleteBoard(user, projectId, id);

    const message = " has deleted the board ";
    Utilities.successNotification(user, message, null, dispatch);

    return Promise.resolve(true);
  }
);

export const copyBoard = createAsyncThunk(
  "scrumboardApp/board/copyBoard",
  async ({ user, projectId, board }, { dispatch, getState }) => {
    let newBoardData = _.merge({}, board, {
      id: Utilities.generateGUID(),
      name: `${board.name} (Copied)`,
      uri: `${board.uri}-copied`,
    });

    const data = await firebase.setBoards(user, projectId, newBoardData);
    newBoardData.id = data;

    const message = " has copied the board ";
    Utilities.successNotification(user, message, newBoardData.name, dispatch);

    return newBoardData;
  }
);

export const renameBoard = createAsyncThunk(
  "scrumboardApp/board/renameBoard",
  async (
    { user, projectId, boardId, boardTitle, boardDescription },
    { dispatch, getState }
  ) => {
    let uri = boardTitle.toLowerCase();
    uri = Utilities.handleize(uri);
    await firebase.updateBoardName(
      user,
      projectId,
      boardId,
      boardTitle,
      uri,
      boardDescription
    );

    const message = " has updated ";
    Utilities.successNotification(
      user,
      message,
      "board title & description",
      dispatch
    );

    return Promise.resolve({ name: boardTitle, description: boardDescription });
  }
);

export const moveToBacklog = createAsyncThunk(
  "scrumboardApp/board/moveToBacklog",
  async ({ user, workspaceId, board, projectId, navigate }, { dispatch }) => {
    const res = await firebase.moveToBacklog(user, board, projectId);
    if (res) {
      const message = " has moved the board to backlog ";
      Utilities.successNotification(user, message, null, dispatch);
      Utilities.addTimelineEvent(
        user,
        "You have moved the board to backlog",
        dispatch
      );

      navigate(`/${workspaceId}/backlog`);
    } else {
      Utilities.errorNotification(user, dispatch);
    }
  }
);

const boardsSlice = createSlice({
  name: "scrumboardApp/board",
  initialState: null,
  reducers: {
    resetBoard: (state, action) => null,
    openFormTitle: (state, action) => {
      state.setFormOpen = true;
    },
  },
  extraReducers: {
    // TODO: update last modified date in react so it gets reflected
    [getBoard.fulfilled]: (state, action) => action.payload,
    [getBacklogBoard.fulfilled]: (state, action) => action.payload,
    [reorderSection.fulfilled]: (state, action) => {
      state.sections = action.payload;
    },
    [reorderCard.fulfilled]: (state, action) => {
      state.sections = action.payload;
    },
    [newSection.fulfilled]: (state, action) => {
      state.sections = action.payload;
    },
    [newCard.fulfilled]: (state, action) => action.payload,
    [updateCard.fulfilled]: (state, action) => action.payload,
    [renameSection.fulfilled]: (state, action) => {
      const { sectionId, sectionTitle } = action.payload;
      state.sections = state.sections.map((section) => {
        if (section.id === sectionId) {
          section.name = sectionTitle;
        }
        return section;
      });
    },
    [removeSection.fulfilled]: (state, action) => {
      state.sections = _.reject(state.sections, { id: action.payload });
    },
    [changeBoardSettings.fulfilled]: (state, action) => {
      state.settings = action.payload;
    },
    [customizeBoardSettings.fulfilled]: (state, action) => {
      state.customize = action.payload;
    },
    [deleteBoard.fulfilled]: (state, action) => {
      state = {};
    },
    [renameBoard.fulfilled]: (state, action) => {
      state.name = action.payload.name;
      state.description = action.payload.description;
    },
    [removeCard.fulfilled]: (state, action) => {
      const cardId = action.payload;
      state.cards = _.reject(state.cards, { id: cardId });
      state.sections = state.sections.map((section) => {
        _.set(
          section,
          "idCards",
          _.reject(section.idCards, (id) => id === cardId)
        );
        return section;
      });
    },
  },
});

export const { resetBoard, openFormTitle } = boardsSlice.actions;

export default boardsSlice.reducer;
