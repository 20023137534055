import React from "react";
import { Tooltip } from "@mui/material";
import Pdf from "components/svgs/icons/general/Pdf";
import Word from "components/svgs/icons/general/Word";
import Document from "components/svgs/icons/general/Document";
const COMPONENT = "component";
const imageReg = /[\/.](gif|jpg|jpeg|tiff|png)$/i;

const FileDisplay = (props) => {
  const { src, name, className } = props;
  const isPdf = name?.endsWith(".pdf");
  const isWord = name?.endsWith(".doc");
  const isImage = imageReg.test(name);
  const url = isPdf || isWord || !isImage ? COMPONENT : src;

  return (
    <div className="flex items-center hover:opacity-70">
      <Tooltip title={name}>
        {url === COMPONENT ? (
          <a
            href={src}
            target="_blank"
            className="!border-none !bg-transparent"
          >
            {isPdf && <Pdf />}
            {isWord && <Word />}
            {!isImage && !isPdf && !isWord && <Document />}
          </a>
        ) : (
          <a
            href={src}
            target="_blank"
            className="!border-none !bg-transparent"
          >
            <img
              className={`cursor-pointer ${
                className ?? "max-h-full rounded-md"
              }`}
              src={url}
              alt="file"
            />
          </a>
        )}
      </Tooltip>
    </div>
  );
};

export default FileDisplay;
