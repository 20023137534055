import React from "react";

const Dnd = (props) => {
  const { height, width } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
    >
      <g
        id="Group_42390"
        data-name="Group 42390"
        transform="translate(-3061 14541.5)"
      >
        <g
          id="Ellipse_1333"
          data-name="Ellipse 1333"
          transform="translate(3061 -14541.5)"
          fill="none"
          stroke="#f44336"
          strokeWidth="1"
        >
          <circle cx="8" cy="8" r="8" stroke="none" />
          <circle cx="8" cy="8" r="7.5" fill="none" />
        </g>
        <line
          id="Line_276"
          data-name="Line 276"
          x2="7.655"
          transform="translate(3065.172 -14533.5)"
          fill="none"
          stroke="#f44336"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};

export default Dnd;
