import { yupResolver } from "@hookform/resolvers/yup";
import {
  Backdrop,
  CircularProgress,
  FormHelperText,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { getSubscriptionDetails } from "api/getSubscriptionDetails";
import { upgradePlan } from "api/upgradePlan";
// import AmericanExpress from "components/svgs/icons/plans/AmericanExpress";
// import MasterCard from "components/svgs/icons/plans/MasterCardIcon";
// import Visa from "components/svgs/icons/plans/VisaIcon";
import VisaIcon from "components/svgs/icons/settings/Visa.svg";
import MasterCardIcon from "components/svgs/icons/settings/MasterCard.svg";
import AmericanExpressIcon from "components/svgs/icons/settings/AmericanExpress.svg";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { CountryRegionData } from "react-country-region-selector";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useStyledFormInputStyles } from "settings/styleOverrides";
import { setSubscriptionDetails } from "store/slice/subscriptionSlice";
import _ from "utils/lodash";
import Utilities from "utils/utilities";
import * as yup from "yup";
import CreditCard from "components/svgs/icons/settings/CreditCard";
import CreditCardBg from "components/svgs/icons/settings/CreditCardBg";

const schema = yup.object().shape({
  fullName: yup.string().required("You must enter full name."),
  cardNumber: yup
    .string()
    .matches(/^[0-9\*]*$/g)
    .required("You must enter card number."),
  expiryMonth: yup.number().required("").min(1).max(12),
  expiryYear: yup.number().required(""),
  cardCVC: yup.string().required("You must enter security code."),
  country: yup.string().required("You must select country."),
  postalCode: yup.string().required("You must enter postal code."),
});

const userAgent = navigator.userAgent.toLowerCase();
const isElectron = userAgent.indexOf(" electron/") > -1;

const PlanPayment = (props) => {
  const {
    mdDown,
    user,
    subscriptionDetails,
    selectedPlan,
    billingCycle,
    planObj,
    darkMode,
    emailMeInvoice,
  } = props;
  const dispatch = useDispatch();
  const classes = useStyledFormInputStyles();

  const data = CountryRegionData;
  const currentPlan = subscriptionDetails?.subscription?.plan;
  const billingDetails = subscriptionDetails?.card?.billing_details;
  const card = subscriptionDetails?.card;
  const upgrade = currentPlan?.nickname !== selectedPlan?.title;

  const [showCardForm, setShowCardForm] = useState(card == null);
  const [disabled, setSaveDisabled] = useState(false);
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  useEffect(() => {
    reset(defaultValues);
  }, [subscriptionDetails]);

  const defaultValues = {
    plan: currentPlan?.nickname?.toLowerCase() || "",
    fullName: billingDetails?.name || "",
    cardNumber: card?.card?.last4 ? "************" + card?.card?.last4 : "",
    billingEmail: user?.data?.email,
    expiryMonth: card?.card?.exp_month || "",
    expiryYear: card?.card?.exp_year || "",
    cardCVC: "",
    country: billingDetails?.address?.country || "",
    postalCode: billingDetails?.address?.postal_code || "",
    billingCycle: billingCycle,
    planObj,
  };

  const { control, formState, handleSubmit, reset, watch } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;
  const paymentForm = watch();

  const onSubmit = async (model) => {
    if (!isValid) return;
    setSaveDisabled(true);
    setLoadingSpinner(true);

    const body = {
      subscriptionDetails,
      model: model,
      upgradePlan: upgrade,
      workspaceId: user.data.currentWorkspace,
      emailMeInvoice,
    };
    await upgradePlan(body)
      .then((res) => {
        setSaveDisabled(false);
        setLoadingSpinner(false);
        setShowCardForm(false);
        if (res?.body?.error) {
          Utilities.errorNotification(user, dispatch, res.body.error.raw);
          return;
        }
        showSuccess();
      })
      .catch((error) => {
        setSaveDisabled(false);
        setLoadingSpinner(false);
        Utilities.errorNotification(user, dispatch, error);
      });
  };

  const showSuccess = async () => {
    const result = await getSubscriptionDetails(
      subscriptionDetails.subscription.id
    );
    dispatch(setSubscriptionDetails(result.body));
    Utilities.successNotification(
      user,
      "Payment method is updated now.",
      null,
      dispatch
    );
  };

  return (
    <motion.div
      variants={Utilities.containerConfig(0.05)}
      initial="hidden"
      animate="show"
      className={`right-container ${mdDown ? "w-full" : "w-[50%]"}`}
    >
      {loadingSpinner ? (
        <Backdrop sx={{ color: "#fff" }} open={loadingSpinner}>
          <CircularProgress color="info" className="m-auto" />
        </Backdrop>
      ) : (
        <div
          className={`${
            darkMode ? "bg-darkSmoke" : "bg-white"
          } xs:px-2 xmd:px-6 py-6 shadow-md shadow-blueTransparent mt-6 rounded`}
        >
          <Typography className="font-medium xs:ml-4 xmd:ml-0">
            Payment Information
          </Typography>
          <div className="flex justify-center items-center mt-8">
            <div className="w-fit h-fit relative">
              <Box className="w-[100%] h-[70%]">
                <CreditCardBg />
              </Box>
              <Box className="absolute top-[10%] left-8 w-14 h-14">
                <CreditCard />
              </Box>
              {/* {isElectron && (
                <Box className="absolute top-[10%] right-8 w-14 h-14">
                  {card?.card?.brand === "mastercard" ? (
                    <MasterCard />
                  ) : card?.card?.brand === "amex" ? (
                    <AmericanExpress />
                  ) : (
                    <Visa />
                  )}
                </Box>
              )} */}
              {!isElectron && (
                <img
                  src={`${
                    card?.card?.brand === "mastercard"
                      ? MasterCardIcon
                      : card?.card?.brand === "amex"
                      ? AmericanExpressIcon
                      : VisaIcon
                  }`}
                  className="absolute top-[10%] right-8 w-14 h-14"
                  alt="card_icon"
                />
              )}
              <Typography className="absolute top-[45%] left-[9%] text-[0.8em] text-white">
                Card Number
              </Typography>

              <Typography className="absolute top-[52%] text-lg left-[9%] text-white">
                {paymentForm.cardNumber
                  ? paymentForm.cardNumber
                  : "0000 0000 0000 0000"}
              </Typography>
              <Typography className="absolute top-[75%] text-[0.8em] left-[9%] text-white">
                Cardholder Name
              </Typography>
              <Typography className="absolute top-[84%] text-[1.1em] left-[9%] text-white">
                {paymentForm.fullName ? paymentForm.fullName : "-"}
              </Typography>

              <Typography className="absolute top-[75%] text-[0.8em] right-[17%] text-white">
                Expiration
              </Typography>
              <Typography className="absolute top-[84%] text-[1.1em] right-[8%] text-white">
                {paymentForm.expiryMonth ? paymentForm.expiryMonth : "MM"}/
                {paymentForm.expiryYear ? paymentForm.expiryYear : "YYYY"}
              </Typography>
            </div>
          </div>
          {showCardForm ? (
            <form
              name="planForm"
              noValidate
              className="flex flex-col flex-1"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="card-form mt-4 px-4">
                <Typography className="text-sm mb-2 text-mute">Name</Typography>
                <Controller
                  name="fullName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      autoComplete="off"
                      id="outlined-basic"
                      size="small"
                      variant="outlined"
                      name="fullName"
                      fullWidth
                      required
                      placeholder="Full Name"
                      error={!!errors.fullName}
                      helperText={errors?.fullName?.message}
                      InputProps={{
                        className: classes.input,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.label,
                      }}
                    />
                  )}
                />

                <Typography className="text-sm mb-2 mt-4 text-mute">
                  Card Number
                </Typography>
                <Controller
                  name="cardNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      autoComplete="off"
                      id="outlined-basic"
                      size="small"
                      variant="outlined"
                      name="cardNumber"
                      fullWidth
                      required
                      error={!!errors.cardNumber}
                      helperText={errors?.cardNumber?.message}
                      InputProps={{
                        className: classes.input,
                        endAdornment: (
                          <InputAdornment position="end">
                            {/* {isElectron &&
                              (card?.card?.brand === "mastercard" ? (
                                <MasterCard />
                              ) : card?.card?.brand === "amex" ? (
                                <AmericanExpress />
                              ) : (
                                <Visa />
                              ))} */}
                            {!isElectron && (
                              <img
                                src={`${
                                  card?.card?.brand === "mastercard"
                                    ? MasterCardIcon
                                    : card?.card?.brand === "amex"
                                    ? AmericanExpressIcon
                                    : VisaIcon
                                }`}
                                alt="card_brand"
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />

                <div className="flex gap-4">
                  <div>
                    <Typography className="text-sm mb-2 mt-4 text-mute">
                      Month
                    </Typography>
                    <Controller
                      name="expiryMonth"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          autoComplete="off"
                          id="outlined-basic"
                          size="small"
                          variant="outlined"
                          name="expiryMonth"
                          placeholder="MM"
                          type="number"
                          fullWidth
                          required
                          error={!!errors.expiryMonth}
                          helperText={
                            paymentForm.expiryMonth === ""
                              ? ""
                              : errors?.expiryMonth?.message
                          }
                          InputProps={{
                            className: classes.input,
                          }}
                          InputLabelProps={{
                            shrink: true,
                            className: classes.label,
                          }}
                        />
                      )}
                    />
                  </div>
                  <div>
                    <Typography className="text-sm mb-2 mt-4 text-mute">
                      Year
                    </Typography>
                    <Controller
                      name="expiryYear"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          autoComplete="off"
                          id="outlined-basic"
                          size="small"
                          variant="outlined"
                          name="expiryYear"
                          placeholder="YYYY"
                          type="number"
                          fullWidth
                          required
                          error={!!errors.expiryYear}
                          helperText={
                            paymentForm.expiryYear === ""
                              ? ""
                              : errors?.expiryYear?.message
                          }
                          InputProps={{
                            className: classes.input,
                          }}
                          InputLabelProps={{
                            shrink: true,
                            className: classes.label,
                          }}
                        />
                      )}
                    />
                  </div>

                  <div>
                    <Typography className="text-sm mb-2 mt-4 text-mute">
                      CVC
                    </Typography>
                    <Controller
                      name="cardCVC"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          autoComplete="off"
                          id="outlined-basic"
                          size="small"
                          variant="outlined"
                          name="cardCVC"
                          required
                          placeholder="3 digits"
                          error={!!errors.cardCVC}
                          helperText={errors?.cardCVC?.message}
                          InputProps={{
                            className: classes.input,
                          }}
                          InputLabelProps={{
                            shrink: true,
                            className: classes.label,
                          }}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="flex gap-4 w-full">
                  <div>
                    <Typography className="text-sm mb-2 mt-4 text-mute">
                      Country
                    </Typography>
                    <Controller
                      name="country"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <Select
                            autoComplete="off"
                            id="outlined-basic"
                            size="small"
                            variant="outlined"
                            name="country"
                            fullWidth
                            sx={{
                              height: "35px",
                              minWidth: "200px",
                              maxWidth: "200px",
                            }}
                            required
                            onChange={onChange}
                            displayEmpty
                            defaultValue={defaultValues.country}
                            MenuProps={{
                              PaperProps: { sx: { maxHeight: 200 } },
                            }}
                          >
                            <MenuItem value=""></MenuItem>
                            {data.map((value, idx) => (
                              <MenuItem key={idx} value={value[1]}>
                                {value[0]}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText error={!!errors.country} required>
                            {errors?.country?.message}
                          </FormHelperText>
                        </>
                      )}
                    />
                  </div>
                  <div>
                    <Typography className="text-sm mb-2 mt-4 text-mute">
                      Postal
                    </Typography>
                    <Controller
                      name="postalCode"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          autoComplete="off"
                          id="outlined-basic"
                          size="small"
                          variant="outlined"
                          name="postalCode"
                          fullWidth
                          placeholder="XXXXXX"
                          required
                          error={!!errors.postalCode}
                          helperText={errors?.postalCode?.message}
                          InputProps={{
                            className: classes.input,
                          }}
                          InputLabelProps={{
                            shrink: true,
                            className: classes.label,
                          }}
                        />
                      )}
                    />
                  </div>
                </div>

                <Button
                  className={`${
                    _.isEmpty(dirtyFields) || !isValid || disabled
                      ? "bg-blueTransparent"
                      : "bg-primaryBlue"
                  } mt-4 w-full rounded hover:bg-primaryBlueLight text-white text-sm py-4`}
                  type="submit"
                  disabled={_.isEmpty(dirtyFields) || !isValid || disabled}
                >
                  {card === null
                    ? "Save Payment Method"
                    : "Update Payment Method"}
                </Button>
              </div>
            </form>
          ) : (
            <Button
              className={`mt-4 w-full rounded bg-primaryBlue hover:bg-primaryBlueLight text-white text-sm py-4`}
              onClick={() => setShowCardForm(!showCardForm)}
            >
              Show Card Details
            </Button>
          )}
        </div>
      )}
    </motion.div>
  );
};

export default PlanPayment;
