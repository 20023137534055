import { yupResolver } from "@hookform/resolvers/yup";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useStyles } from "settings/styleOverrides";
import _ from "utils/lodash";
import Utilities from "utils/utilities";
import * as yup from "yup";
import { newBoard } from "./store/boardsSlice";

const defaultValues = {
  name: "",
  description: "",
  settings: { public: true },
  sections: [],
};

const schema = yup.object().shape({
  name: yup
    .string()
    .required("You must enter a board name")
    .max(50, "You cannot have more than 50 characters"),
  sections: yup.array().of(
    yup.object().shape({
      id: yup.string().required().default(Utilities.generateGUID()),
      idCards: yup.array(),
      name: yup.string().required("Please choose a name."),
    })
  ),
});

const AddBoardDialog = (props) => {
  const { onClose, open } = props;
  const dispatch = useDispatch();
  const routeParams = useParams();
  const classes = useStyles();
  const user = useSelector(({ auth }) => auth.user);
  const projectId = routeParams.projectId;

  const {
    control,
    formState,
    handleSubmit,
    reset,
    setValue,
    watch,
    clearErrors,
  } = useForm({
    mode: "onBlur",
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;
  const boardDialogForm = watch();

  const onSubmit = (model) => {
    if (!isValid) return;
    const data = {
      ...model,
      sections: _.cloneDeep(boardDialogForm.sections),
      settings: {
        color: "",
        subscribed: true,
        cardCoverImages: true,
        completed: false,
        public: boardDialogForm.settings.public,
        favourites: false,
      },
    };
    dispatch(newBoard({ user, projectId, data })).then(() => {
      handleClose();
    });
  };

  const handleClose = () => {
    onClose();
    reset(defaultValues);
  };

  const handleRemoveFields = (index) => {
    const sections = [...boardDialogForm.sections];
    sections.splice(index, 1);
    setValue("sections", sections, { shouldDirty: true });

    // IMPORTANT: This is required
    clearErrors("sections");
  };

  const handleAddFields = () => {
    const sections = [
      ...boardDialogForm.sections,
      { id: Utilities.generateGUID(), idCards: [], name: "" },
    ];
    setValue("sections", sections, { shouldDirty: true });
  };

  const setPublic = (value) => {
    setValue("settings.public", value, { shouldDirty: true });
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      component="form"
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: { borderRadius: 10 },
      }}
    >
      <DialogTitle className="bg-primaryBlue text-white h-[60px]">
        <Typography
          variant="subtitle1"
          color="inherit"
          className="absolute top-[10px]"
          component="span"
        >
          Add Board
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 40,
            top: 5,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent classes={{ root: "px-6 py-6 pb-0 sm:px-8" }}>
        <div className="my-6">
          <div>
            <div className="text-lg tracking-wide pb-2">Board Name</div>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  autoComplete="off"
                  type="text"
                  id="board"
                  placeholder="Board Name"
                  className=""
                  autoFocus
                  required
                  fullWidth
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  inputProps={{
                    className: classes.input + " !py-4 !pl-6",
                  }}
                />
              )}
            />
          </div>
          <div className="mt-6">
            <div className="text-lg tracking-wide pb-2">Description</div>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  autoComplete="off"
                  placeholder="Type your description here..."
                  multiline
                  rows="5"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    className: classes.inputTextArea,
                  }}
                />
              )}
            />
          </div>
          <div className="mt-6">
            <div className="text-lg tracking-wide pb-2">Privacy</div>
            <Button
              type="button"
              className={clsx(
                "rounded-[5px] text-sm md:text-base px-1 py-3 md:px-4 w-32 border border-solid border-primaryBlue",
                boardDialogForm.settings.public &&
                  "bg-primaryBlue text-white hover:bg-primaryBlueLight",
                !boardDialogForm.settings.public &&
                  "hover:bg-primaryBlue hover:text-white text-primaryBlue bg-primaryBlue/10"
              )}
              onClick={() => setPublic(!boardDialogForm.settings.public)}
            >
              Public
            </Button>
            <Button
              type="button"
              className={clsx(
                "rounded-[5px] hover:bg-primaryBlueLight text-sm md:text-base px-1 py-3 md:px-4 w-32 ml-6  border border-solid border-primaryBlue",
                !boardDialogForm.settings.public &&
                  "bg-primaryBlue text-white hover:bg-primaryBlueLight",
                boardDialogForm.settings.public &&
                  "hover:bg-primaryBlue hover:text-white text-primaryBlue bg-primaryBlue/10"
              )}
              onClick={() => setPublic(!boardDialogForm.settings.public)}
            >
              Private
            </Button>
          </div>
          <div className="mt-6">
            {boardDialogForm.sections &&
            boardDialogForm.sections.length === 0 ? (
              <Typography className="font-medium mt-3 mb-5 text-15">
                Do you want to create sections for your boards? Click
                <IconButton onClick={handleAddFields}>
                  <AddCircleOutline />
                </IconButton>
                to get started.
              </Typography>
            ) : (
              <div className="text-lg tracking-wide pb-2">Add Section</div>
            )}
            {boardDialogForm.sections.map((inputField, index) => (
              <div key={inputField.id} className="mb-12 flex">
                <div className="w-full">
                  <Controller
                    name={`sections[${index}].name`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        autoComplete="off"
                        type="text"
                        id="board"
                        placeholder="Example: Todo, In Progress, Review, Done"
                        required
                        fullWidth
                        inputProps={{
                          className: classes.input + " !py-4 !pl-6",
                        }}
                        error={
                          !!errors?.sections && !!errors?.sections[index]?.name
                        }
                        helperText={
                          errors?.sections &&
                          errors?.sections[index]?.name?.message
                        }
                      />
                    )}
                  />
                </div>
                <div className="min-w-max ml-4">
                  <IconButton
                    onClick={() => handleRemoveFields(index)}
                    color="primary"
                  >
                    <RemoveCircleOutline />
                  </IconButton>
                  <IconButton onClick={handleAddFields} color="primary">
                    <AddCircleOutline />
                  </IconButton>
                </div>
              </div>
            ))}
          </div>
          <div>
            <div className="flex justify-center items-center gap-x-6">
              <Button
                type="button"
                onClick={handleClose}
                className="bg-lightGray hover:bg-lightGray/70 rounded text-base px-1 md:px-4 text-white w-36"
              >
                <span className="text-lg">Cancel</span>
              </Button>
              <Button
                type="button"
                disabled={_.isEmpty(dirtyFields) || !isValid}
                onClick={handleSubmit(onSubmit)}
                className={`hover:bg-primaryBlueLight rounded text-base px-1 md:px-4 text-white w-36 ${
                  _.isEmpty(dirtyFields) || !isValid
                    ? "bg-blueTransparent"
                    : "bg-primaryBlue"
                }`}
              >
                <span className="text-lg">Save</span>
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddBoardDialog;
