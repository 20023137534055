import Utilities from "utils/utilities";
import _ from "utils/lodash";

const BoardModel = (data) => {
  data = data || {};
  data.name = data.name || "Untitled Board";

  return _.defaults(data, {
    uri: data.name.toLowerCase().replace(" ", "-"),
    id: Utilities.generateGUID(),
    createdDate: new Date(),
    modifiedDate: new Date(),
    settings: {
      color: "",
      subscribed: true,
      cardCoverImages: true,
      completed: false,
      public: true,
      favourites: false,
      listView: false,
    },
    sections: [],
    cards: [],
    customize: {
      hiddenFields: [],
      hiddenSections: [],
    },
    description: "",
  });
};

export default BoardModel;
