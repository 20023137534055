import * as React from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import endOfWeek from "date-fns/endOfWeek";
import isSameDay from "date-fns/isSameDay";
import isWithinInterval from "date-fns/isWithinInterval";
import startOfWeek from "date-fns/startOfWeek";
import { DatePicker, LocalizationProvider, PickersDay } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useStyledFormInputStyles } from "settings/styleOverrides";

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: "#00B9D9 !important",
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      backgroundColor: "#00B9D9 !important",
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(isLastDay && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
}));

const Root = styled("div")(({ theme, active }) => ({
  "& > div": {
    width: "100%",
  },
}));

const DateRangePicker = (props) => {
  const classes = useStyledFormInputStyles();
  const date = props?.value?.seconds
    ? new Date(props.value.seconds * 1000)
    : props?.value
    ? new Date(props?.value)
    : new Date();
  const initialValue = endOfWeek(date);
  const [value, setValue] = React.useState(initialValue);

  const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
    if (!value) {
      return <PickersDay {...pickersDayProps} />;
    }

    const start = startOfWeek(value);
    const end = endOfWeek(value);

    const dayIsBetween = isWithinInterval(date, { start, end });
    const isFirstDay = isSameDay(date, start);
    const isLastDay = isSameDay(date, end);

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };

  return (
    <Root>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label={props.label}
          value={value}
          onChange={(newValue) => {
            const customValue = endOfWeek(newValue);
            props.onChange(customValue);
            setValue(customValue);
          }}
          renderDay={renderWeekPickerDay}
          renderInput={(params) => (
            <TextField
              InputLabelProps={{
                shrink: true,
                className: classes.label,
              }}
              {...params}
            />
          )}
          inputFormat="'Week of' MMM d"
          className="w-full text-sm"
          InputProps={{
            className: classes.input,
          }}
        />
      </LocalizationProvider>
    </Root>
  );
};

export default DateRangePicker;
