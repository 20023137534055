import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import PlanIcon from "components/svgs/icons/settings/PlanIcon";

const PlanCard = (props) => {
  const { color, title, subtitle, onClick, selected, learnMore, darkMode } =
    props;

  return (
    <Paper
      onClick={onClick}
      className={`flex md:min-w-[12em] p-4 shadow-md shadow-blueTransparent cursor-pointer ${
        selected ? "bg-primaryBlue" : darkMode ? "bg-darkSmoke" : "bg-white"
      }`}
    >
      <div className="img-container flex items-center">
        <Box className="w-[2.1em] h-[2.1em]">
          <PlanIcon color={selected ? "#fff" : color} />
        </Box>
      </div>
      <div className="text-container ml-3">
        <Typography
          className={`${
            selected || darkMode ? "text-white" : "text-cancelBlack"
          } xs:text-[1.18em] miniTab:text-[1.3em] font-semibold`}
        >
          {title}
        </Typography>
        <Typography
          className={`${
            selected || darkMode ? "text-white" : "text-grayText"
          } xs:text-[0.9em] miniTab:text-[1.04em]`}
        >
          {subtitle}
        </Typography>
        <a
          href="https://proman365.com/pricing/"
          target="_blank"
          className="flex items-center !border-none !bg-transparent"
        >
          <Typography
            className={`${
              selected || darkMode ? "text-white" : "text-primaryBlue"
            } xs:text-[0.9em] miniTab:text-[1em] underline mt-1`}
          >
            Learn More
          </Typography>
          <ArrowForwardIcon
            className={`text-sm mt-[5px] ml-1 ${
              selected || darkMode ? "text-white" : "text-primaryBlue"
            } `}
          />
        </a>
      </div>
    </Paper>
  );
};

export default PlanCard;
