import Navigation from "core/Navigation";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { navbarCloseMobile } from "../../store/slice/navbarSlice";

import clsx from "clsx";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectNavigation } from "store/slice/navigationSlice";

const NavigationWrapper = (props) => {
  const navigation = useSelector(selectNavigation);
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("lg"));
  const dispatch = useDispatch();

  const handleItemClick = (item) => {
    if (mdDown) {
      dispatch(navbarCloseMobile());
    }
  };

  return (
    <Navigation
      className={clsx("navigation", props.className)}
      navigation={navigation}
      layout={props.layout}
      dense={props.dense}
      active={props.active}
      onItemClick={handleItemClick}
    />
  );
};

NavigationWrapper.defaultProps = {
  layout: "vertical",
};

export default memo(NavigationWrapper);
