import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import firebase from "services/firebase";

export const getChat = createAsyncThunk(
  "chatApp/chat/getChat",
  async ({ user, contactId }) => {
    return await firebase.getChatById(user, contactId);
  }
);

export const getMediaData = createAsyncThunk(
  "chatApp/chat/getMediaData",
  async ({ user, contactId }) => {
    return await firebase.getMediaData(user, contactId);
  }
);

export const sendMessage = createAsyncThunk(
  "chatApp/chat/sendMessage",
  async ({ messageText, contactId, attachment }, { dispatch, getState }) => {
    const user = getState().auth.user;
    const message = {
      who: user.uid,
      message: messageText,
      time: new Date(),
      attachment: [],
    };
    const data = await firebase.updateChatRealtime(
      user,
      contactId,
      message,
      attachment
    );
    await firebase.updateMemberChatRealtime(
      user,
      contactId,
      message,
      data.message.id,
      data.uploadData
    );

    return data;
  }
);

const chatSlice = createSlice({
  name: "chatApp/chat",
  initialState: {
    chat: null,
    media: [],
  },
  reducers: {},
  extraReducers: {
    [getChat.fulfilled]: (state, action) => {
      state.chat = action.payload;
    },
    [getMediaData.fulfilled]: (state, action) => {
      state.media = action.payload;
    },
  },
});

export default chatSlice.reducer;
