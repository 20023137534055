import Utilities from "utils/utilities";
import _ from "utils/lodash";

const LabelModel = (data) => {
  data = data || {};

  return _.defaults(data, {
    id: Utilities.generateGUID(),
    title: "",
    color: "",
    handle: Utilities.handleize(data.title || ""),
  });
};

export default LabelModel;
