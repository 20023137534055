import { createSlice } from "@reduxjs/toolkit";

const labelDialogSlice = createSlice({
  name: "labelDialog",
  initialState: {
    state: false,
    options: {},
  },
  reducers: {
    openLabelDialog: (state, action) => {
      state.state = true;
      state.options = action.payload;
    },
    closeLabelDialog: (state, action) => {
      state.state = false;
    },
  },
});

export const { openLabelDialog, closeLabelDialog } = labelDialogSlice.actions;

export default labelDialogSlice.reducer;
