import Utilities from "utils/utilities";
import firebase from "services/firebase";

export const upgradePlan = async (body) => {
  const { currentUser } = firebase.getAuth();
  const token = await currentUser.getIdToken(true);
  const endpoint = `${Utilities.getBaseUrl()}/upgradePlan`;
  const request = await fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(body),
  });
  const response = await request.json();
  if (response.error) {
    return {
      statusCode: response.statusCode || 404,
      body: response,
    };
  }
  return {
    statusCode: 200,
    body: response,
  };
};
