import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { leaveWorkspace } from "api/leaveWorkspace";
import firebase from "services/firebase";
import Utilities from "utils/utilities";

export const getInvitedMembers = createAsyncThunk(
  "inviteApp/invitation/getInvitedMembers",
  async (user) => {
    const data = await firebase.getInvitedMembers(user.data.currentWorkspace);
    return Promise.resolve(data);
  }
);

export const addInvitedMembers = createAsyncThunk(
  "inviteApp/invitation/addInvitedMembers",
  async ({ user, data }, { dispatch }) => {
    await firebase.addInvitedMembers({
      workspaceId: user.data.currentWorkspace,
      data: data,
    });

    const message = " has sent the invitation ";
    Utilities.successNotification(user, message, null, dispatch);

    return data;
  }
);

export const removeInvitedMember = createAsyncThunk(
  "inviteApp/invitation/removeInvitedMember",
  async (user, { dispatch }) => {
    // TODO: Figure out error handling
    try {
      await leaveWorkspace({ user })
        .then(() => {
          const message = " has left the workspace ";
          Utilities.successNotification(
            user,
            message,
            user.currentWorkspace,
            dispatch
          );
        })
        .catch((error) => {
          Utilities.errorNotification(user, dispatch, error);
          Promise.reject(false);
        });
    } catch {
      Utilities.errorNotification(user, dispatch);
      Promise.reject(false);
    }
  }
);

const invitationSlice = createSlice({
  name: "inviteApp/invitation",
  initialState: {
    list: [],
    accepted: [],
  },
  reducers: {},
  extraReducers: {
    [getInvitedMembers.fulfilled]: (state, { payload }) => {
      state.list = payload || [];
      state.accepted = state.list
        .filter((x) => x.status === "accepted")
        .map((x) => x.email);
    },
    // TODO
    //[addInvitedMembers.fulfilled]: invitationAdapter.setAll,
  },
});

export default invitationSlice.reducer;
