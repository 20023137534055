const Backlog = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_17653"
            data-name="Rectangle 17653"
            width="25.935"
            height="18.355"
            fill="#6e6e6e"
          />
        </clipPath>
      </defs>
      <g id="Group_40689" data-name="Group 40689" transform="translate(0 0)">
        <g
          id="Group_40688"
          data-name="Group 40688"
          transform="translate(0 0)"
          clipPath="url(#clipPath)"
        >
          <path
            id="Path_34407"
            data-name="Path 34407"
            d="M24.685,12.974H9.659A1.19,1.19,0,0,0,8.38,14.237q-.007,1.493,0,2.986a1.194,1.194,0,0,0,1.275,1.27q7.537,0,15.074,0a1.207,1.207,0,0,0,1.294-1.3q0-1.445,0-2.891a1.212,1.212,0,0,0-1.339-1.328m.02,4.846q-7.513,0-15.026,0c-.436,0-.627-.194-.627-.627q0-1.457,0-2.914c0-.44.2-.637.644-.637H24.651c.521,0,.7.182.7.7q0,1.41,0,2.819c0,.461-.19.658-.65.658"
            transform="translate(-0.09 -0.139)"
            fill="#6e6e6e"
          />
          <path
            id="Path_34408"
            data-name="Path 34408"
            d="M4.86,12q7.525,0,15.05,0a1.194,1.194,0,0,0,1.282-1.283q0-1.457,0-2.914a1.2,1.2,0,0,0-1.324-1.314h-7.5q-3.774,0-7.548,0A1.182,1.182,0,0,0,3.551,7.761q0,1.469,0,2.938A1.191,1.191,0,0,0,4.86,12M4.22,7.8c0-.459.192-.647.657-.647H19.832c.509,0,.693.183.694.687v2.818c0,.48-.189.669-.663.669H4.908c-.517,0-.689-.169-.689-.684q0-1.422,0-2.843"
            transform="translate(-0.038 -0.07)"
            fill="#6e6e6e"
          />
          <path
            id="Path_34409"
            data-name="Path 34409"
            d="M9.686,5.515H24.712a1.2,1.2,0,0,0,1.311-1.3q0-1.445,0-2.891A1.2,1.2,0,0,0,24.7,0Q17.189,0,9.676,0a1.194,1.194,0,0,0-1.3,1.294q0,1.457,0,2.914A1.193,1.193,0,0,0,9.686,5.515m-.635-4.2c0-.453.185-.639.636-.639h7.524q3.739,0,7.478,0A.674.674,0,0,1,25.131.8a.8.8,0,0,1,.215.495c.02.964.01,1.927.009,2.891,0,.475-.192.668-.665.668H11.312c-.55,0-1.1,0-1.649,0a.542.542,0,0,1-.612-.619q0-1.457,0-2.914"
            transform="translate(-0.09 0)"
            fill="#6e6e6e"
          />
          <path
            id="Path_34410"
            data-name="Path 34410"
            d="M23.469,14.608c-.278.007-.557,0-.835,0-.295,0-.589,0-.883,0-.188,0-.282.078-.282.268q0,.884,0,1.767c0,.191.092.271.28.269q.871,0,1.742,0c.2,0,.287-.093.286-.291q-.006-.859,0-1.718c0-.215-.1-.3-.307-.3"
            transform="translate(-0.23 -0.157)"
            fill="#6e6e6e"
          />
          <path
            id="Path_34411"
            data-name="Path 34411"
            d="M19.885,14.9c-.046-.03-.124-.011-.188-.011H13.51a.634.634,0,0,0-.258.016c-.073.035-.168.13-.163.192a.364.364,0,0,0,.163.221c.048.035.139.013.21.013H19.7c.063,0,.144.023.187-.008.079-.059.177-.142.189-.228.008-.056-.11-.145-.188-.195"
            transform="translate(-0.14 -0.16)"
            fill="#6e6e6e"
          />
          <path
            id="Path_34412"
            data-name="Path 34412"
            d="M19.886,16.363c-.046-.03-.124-.009-.188-.009H13.464c-.071,0-.161-.021-.211.013a.377.377,0,0,0-.164.221c0,.062.09.154.161.193a.525.525,0,0,0,.235.017h6.187c.071,0,.159.022.211-.011.081-.054.181-.14.19-.223.007-.061-.109-.15-.187-.2"
            transform="translate(-0.14 -0.176)"
            fill="#6e6e6e"
          />
          <path
            id="Path_34413"
            data-name="Path 34413"
            d="M16.636,10.15c0,.2.1.283.3.279.278,0,.557,0,.835,0,.295,0,.589,0,.883,0,.2,0,.293-.086.292-.286,0-.581,0-1.161,0-1.742a.24.24,0,0,0-.275-.277c-.581,0-1.161,0-1.742,0-.2,0-.293.085-.292.285q0,.871,0,1.742"
            transform="translate(-0.178 -0.087)"
            fill="#6e6e6e"
          />
          <path
            id="Path_34414"
            data-name="Path 34414"
            d="M8.424,8.827c.053.033.139.014.211.014h6.236a.439.439,0,0,0,.21-.016c.072-.046.17-.136.164-.2a.358.358,0,0,0-.17-.219c-.059-.037-.155-.016-.234-.016H8.629c-.072,0-.162-.02-.21.016a.372.372,0,0,0-.16.225c0,.063.093.15.165.194"
            transform="translate(-0.089 -0.09)"
            fill="#6e6e6e"
          />
          <path
            id="Path_34415"
            data-name="Path 34415"
            d="M8.422,10.3c.053.032.139.012.211.012h6.235c.071,0,.157.021.211-.012.072-.043.171-.132.166-.194a.375.375,0,0,0-.168-.218c-.049-.035-.139-.013-.211-.013H8.632c-.071,0-.161-.022-.211.013a.365.365,0,0,0-.162.22c0,.061.092.149.163.192"
            transform="translate(-0.089 -0.106)"
            fill="#6e6e6e"
          />
          <path
            id="Path_34416"
            data-name="Path 34416"
            d="M21.776,3.948c.565-.006,1.131,0,1.7,0,.21,0,.306-.091.3-.3q-.006-.859,0-1.719c0-.2-.089-.295-.288-.295H21.744a.242.242,0,0,0-.276.278c0,.287,0,.573,0,.86s0,.574,0,.86c0,.219.082.323.31.321"
            transform="translate(-0.23 -0.018)"
            fill="#6e6e6e"
          />
          <path
            id="Path_34417"
            data-name="Path 34417"
            d="M13.251,2.336c.046.031.124.014.187.014H19.72c.063,0,.141.016.187-.014.072-.046.173-.132.168-.192a.379.379,0,0,0-.168-.222c-.048-.034-.139-.012-.21-.012H13.463c-.071,0-.161-.023-.211.012a.368.368,0,0,0-.163.221c0,.061.092.145.162.193"
            transform="translate(-0.14 -0.021)"
            fill="#6e6e6e"
          />
          <path
            id="Path_34418"
            data-name="Path 34418"
            d="M13.252,3.812c.048.035.139.013.211.013h6.21c.071,0,.16.025.211-.009a.414.414,0,0,0,.19-.226c.008-.057-.111-.146-.189-.2-.046-.029-.124-.01-.187-.01H13.439c-.063,0-.141-.016-.187.015-.07.046-.168.131-.163.192a.37.37,0,0,0,.163.222"
            transform="translate(-0.14 -0.037)"
            fill="#6e6e6e"
          />
          <path
            id="Path_34419"
            data-name="Path 34419"
            d="M6.459,15.481q-1.051-.614-2.107-1.219c-.187-.107-.254-.061-.259.161,0,.2-.009.4,0,.6s-.07.284-.279.279c-.5-.01-1,0-1.5,0A1.583,1.583,0,0,1,.678,13.714Q.66,12.983.664,12.25c0-.372.006-7.148.014-7.519A1.584,1.584,0,0,1,2.211,3.148c.257-.018.4-.146.4-.348s-.161-.323-.419-.32A2.223,2.223,0,0,0,.044,4.392c-.007.064-.012.129-.016.193v0c-.013.194-.014.39-.011.587L0,12.25l0,.167c0,.422,0,.844,0,1.265a2.262,2.262,0,0,0,2.3,2.28c.517,0,1.035,0,1.552,0,.173,0,.247.07.243.242-.006.207,0,.414,0,.62,0,.238.07.283.268.17q1.045-.6,2.086-1.2c.187-.109.191-.2.012-.309"
            transform="translate(0 -0.027)"
            fill="#6e6e6e"
          />
        </g>
      </g>
    </svg>
  );
};

export default Backlog;
