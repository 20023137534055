import { createSlice } from "@reduxjs/toolkit";

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: {},
  reducers: {
    setSubscriptionDetails: (state, action) => action.payload,
  },
});

export const { setSubscriptionDetails } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
