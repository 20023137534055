const FilterIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24.443 29.653"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_17883"
            data-name="Rectangle 17883"
            width="24.443"
            height="29.653"
            fill="#cfcfcf"
          />
        </clipPath>
      </defs>
      <g id="Group_41107" data-name="Group 41107" transform="translate(0 0)">
        <g
          id="Group_41106"
          data-name="Group 41106"
          transform="translate(0 0)"
          clipPath="url(clipPath)"
        >
          <path
            id="Path_37747"
            data-name="Path 37747"
            d="M14.821,7.064h3.65c.01.237.027.452.027.665q0,9.191,0,18.382c0,1.2-.29,1.483-1.492,1.484-.363,0-.724.006-1.085,0a1,1,0,0,1-1.085-.973,3.729,3.729,0,0,1-.015-.481V7.064Z"
            transform="translate(4.308 2.054)"
            fill="#cfcfcf"
          />
          <path
            id="Path_37748"
            data-name="Path 37748"
            d="M1.265,11.029H4.948v.661q0,6.652,0,13.306c0,1.137-.318,1.447-1.47,1.447-.342,0-.683.005-1.024,0a1.048,1.048,0,0,1-1.187-1.127c-.009-.119,0-.24,0-.36V11.029"
            transform="translate(0.367 3.206)"
            fill="#cfcfcf"
          />
          <path
            id="Path_37749"
            data-name="Path 37749"
            d="M11.571,15.548H7.94v-.719q0-6.71,0-13.42C7.941.263,8.207,0,9.358,0c.4,0,.8-.006,1.2,0A.958.958,0,0,1,11.59.951a2.914,2.914,0,0,1,.009.3q0,6.92,0,13.84c0,.136-.015.271-.027.456"
            transform="translate(2.308 0)"
            fill="#cfcfcf"
          />
          <path
            id="Path_37750"
            data-name="Path 37750"
            d="M4.93,9.054H1.264v-.6q0-3.555,0-7.109C1.269.328,1.588,0,2.582,0,3,0,3.426,0,3.848,0c.631.009,1.06.3,1.067.9.034,2.705.014,5.411.014,8.151"
            transform="translate(0.367 0)"
            fill="#cfcfcf"
          />
          <path
            id="Path_37751"
            data-name="Path 37751"
            d="M7.939,16.029H11.5c.039.065.075.1.075.13.008,2.589.023,5.178.013,7.766,0,.7-.4,1.045-1.114,1.061-.441.01-.883.006-1.324,0-.851-.009-1.2-.351-1.208-1.215-.01-1.988,0-3.974,0-5.962V16.029"
            transform="translate(2.308 4.66)"
            fill="#cfcfcf"
          />
          <path
            id="Path_37752"
            data-name="Path 37752"
            d="M3.513,7.6c.782,0,1.564-.008,2.347,0A.968.968,0,0,1,6.933,8.632c.025.54.021,1.083-.005,1.624A.95.95,0,0,1,5.9,11.273q-2.436.023-4.872,0A.932.932,0,0,1,.023,10.3a16.269,16.269,0,0,1,0-1.683A.971.971,0,0,1,1.108,7.6c.8-.01,1.6,0,2.406,0"
            transform="translate(0 2.208)"
            fill="#cfcfcf"
          />
          <path
            id="Path_37753"
            data-name="Path 37753"
            d="M9.88,16.27c-.782,0-1.566.008-2.348,0-.672-.008-1.042-.3-1.087-.944a11.646,11.646,0,0,1,.017-1.8.9.9,0,0,1,1-.9q2.438-.014,4.875,0a.936.936,0,0,1,1.024.945,16.037,16.037,0,0,1,0,1.744.926.926,0,0,1-1.012.953c-.822.013-1.646,0-2.468,0"
            transform="translate(1.866 3.669)"
            fill="#cfcfcf"
          />
          <path
            id="Path_37754"
            data-name="Path 37754"
            d="M17.043,7.31c-.822,0-1.644.01-2.467,0a.923.923,0,0,1-1-.959c-.03-.56-.027-1.124,0-1.684a.953.953,0,0,1,1.036-1q2.438-.015,4.872,0a.913.913,0,0,1,.991.911,12.8,12.8,0,0,1,0,1.861.881.881,0,0,1-.958.875c-.822.006-1.644,0-2.467,0Z"
            transform="translate(3.94 1.063)"
            fill="#cfcfcf"
          />
          <path
            id="Path_37755"
            data-name="Path 37755"
            d="M18.473,3.986H14.887c0-1.12-.045-2.23.022-3.333C14.935.235,15.334,0,15.8,0c.541,0,1.082-.006,1.622,0a.933.933,0,0,1,1.043.925c.044,1,.012,1.994.012,3.055"
            transform="translate(4.324 0)"
            fill="#cfcfcf"
          />
        </g>
      </g>
    </svg>
  );
};

export default FilterIcon;
