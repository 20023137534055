import { yupResolver } from "@hookform/resolvers/yup";
import {
  Avatar,
  Box,
  Button,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import { updateUserStatus } from "auth/store/userSlice";
import StatusIcon from "components/StatusIcon";
import SemiCircle from "components/svgs/icons/chat/SemiCircle";
import Close from "components/svgs/icons/general/Close";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { modalstyle } from "settings/styleOverrides";
import _ from "utils/lodash";
import Utilities from "utils/utilities";
import * as yup from "yup";

const schema = yup.object().shape({
  mood: yup.string().max(50, "You cannot have more than 50 characters"),
  userStatus: yup.string(),
});

const StatusModal = (props) => {
  const { open, handleClose, onClose, user } = props;
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const USER_STATUS = Utilities.getUserStatusItems();

  const style = modalstyle();
  style.width = isSmall ? 370 : 422;
  style.height = isSmall ? 650 : 752;

  const defaultValues = {
    mood: user?.data?.mood,
    status: user?.data?.status,
  };

  const { control, formState, handleSubmit, setValue, watch } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  const userStatusForm = watch();

  const onSubmit = (model) => {
    if (!isValid) return;
    const { mood, status } = model;
    if (user.data.mood !== mood || user.data.status !== status) {
      dispatch(updateUserStatus({ user, status, mood }));
    }
    onClose();
  };

  const changeStatus = (value) => {
    setValue("status", value, { shouldDirty: true });
  };

  return (
    <Modal
      BackdropProps={{ style: { backgroundColor: "grey", opacity: "0.4" } }}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-user-info"
      aria-describedby="modal-user-status"
    >
      <Box sx={style} className="modal-container flex flex-col my-5">
        <div className="modal-header bg-primaryBlue h-[149px] flex flex-col">
          <div className="flex justify-between px-5 mt-4">
            <Typography className="text-white">User Info</Typography>
            <Box onClick={onClose} className="cursor-pointer">
              <Close />
            </Box>
          </div>
          <div className="flex flex col justify-center relative items-end h-full">
            <SemiCircle />
            <Avatar
              className={`h-[92px] w-[92px] absolute ${
                isSmall ? "top-10" : "top-[65px]"
              }`}
              src={`${user?.data?.photoURL}`}
              style={{ backgroundColor: user?.data?.more?.color }}
              alt={user?.data?.displayName}
            >
              {user?.data?.displayName &&
                user?.data?.displayName[0]?.toLocaleUpperCase()}
            </Avatar>
          </div>
        </div>
        <div className="content-container items-center w-full relative">
          <div className="name-and-status flex flex-col items-center mt-[4rem]">
            <Typography className="font-semibold text-2xl">
              {user?.data?.displayName}
            </Typography>
            <Typography className="font-medium mt-1 text-mute">
              {user?.data?.email}
            </Typography>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="h-full overflow-scroll scroll"
            style={{ minHeight: "350px" }}
          >
            <Typography className="w-full flex mt-8 px-5">Mood</Typography>
            <div className="w-full flex justify-center mt-1 px-5">
              <Controller
                name="mood"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    autoComplete="off"
                    type="text"
                    id="mood"
                    placeholder="How are you feeling now?"
                    className=""
                    fullWidth
                    multiline
                    rows={3}
                    error={!!errors.mood}
                    helperText={errors?.mood?.message}
                  />
                )}
              />
            </div>
            <div className="mt-6">
              <Typography className="ml-5 font-base">Status</Typography>
              <div className="status-btns-container grid grid-cols-2 gap-5 px-5 mt-4">
                <div
                  className={`flex justify-center items-center bg-lightGreen py-3 rounded cursor-pointer ${
                    isSmall ? "w-[150px]" : "w-[175px]"
                  }`}
                  onClick={() => changeStatus(USER_STATUS.ONLINE)}
                >
                  <StatusIcon
                    width={"20px"}
                    height={"20px"}
                    status={USER_STATUS.ONLINE}
                  />
                  <Typography
                    className={`${
                      userStatusForm.status === USER_STATUS.ONLINE
                        ? "font-bold"
                        : ""
                    } text-greenText text-[1em] ml-2`}
                  >
                    Online
                  </Typography>
                </div>
                <div
                  className={`flex justify-center items-center bg-yellow-100 py-3 rounded cursor-pointer ${
                    isSmall ? "w-[150px]" : "w-[175px]"
                  }`}
                  onClick={() => changeStatus(USER_STATUS.AWAY)}
                >
                  <StatusIcon
                    width={"20px"}
                    height={"20px"}
                    status={USER_STATUS.AWAY}
                  />
                  <Typography
                    className={`${
                      userStatusForm.status === USER_STATUS.AWAY
                        ? "font-bold"
                        : ""
                    } text-awayText text-[1em] ml-2`}
                  >
                    Away
                  </Typography>
                </div>
                <div
                  className={`flex justify-center items-center bg-red-100 py-3 rounded cursor-pointer ${
                    isSmall ? "w-[150px]" : "w-[175px]"
                  }`}
                  onClick={() => changeStatus(USER_STATUS.DND)}
                >
                  <StatusIcon
                    width={"20px"}
                    height={"20px"}
                    status={USER_STATUS.DND}
                  />
                  <Typography
                    className={`${
                      userStatusForm.status === USER_STATUS.DND
                        ? "font-bold"
                        : ""
                    } text-dndText text-[1em] ml-2`}
                  >
                    Do Not Disturb
                  </Typography>
                </div>
                <div
                  className={`flex justify-center items-center bg-offlineBg py-3 rounded cursor-pointer ${
                    isSmall ? "w-[150px]" : "w-[175px]"
                  }`}
                  onClick={() => changeStatus(USER_STATUS.OFFLINE)}
                >
                  <StatusIcon
                    width={"20px"}
                    height={"20px"}
                    status={USER_STATUS.OFFLINE}
                  />
                  <Typography
                    className={`${
                      userStatusForm.status === USER_STATUS.OFFLINE
                        ? "font-bold"
                        : ""
                    } text-gray-400 text-[1em] ml-2`}
                  >
                    Offline
                  </Typography>
                </div>
              </div>
              <div
                className={`action-btns flex justify-center ${
                  isSmall ? "mt-[3rem] mb-8" : "mt-[5rem]"
                }`}
              >
                <Button
                  style={{ width: 90, height: 35 }}
                  className="px-6 bg-lightGray hover:bg-lightGray/70 text-white text-sm rounded"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  style={{ width: 90, height: 35 }}
                  className={`${
                    _.isEmpty(dirtyFields) || !isValid
                      ? "bg-blueTransparent"
                      : "bg-primaryBlue"
                  } px-6 bg-primaryBlue hover:bg-primaryBlueLight text-white text-sm ml-5 rounded`}
                  type="submit"
                  disabled={_.isEmpty(dirtyFields) || !isValid}
                >
                  Save
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Box>
    </Modal>
  );
};

export default StatusModal;
