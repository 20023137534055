import { Box, Button, Modal, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import ArrowLeft from "components/svgs/icons/general/ArrowLeft";
import ArrowRight from "components/svgs/icons/general/ArrowRight";
import Close from "components/svgs/icons/general/Close";
import Download from "components/svgs/icons/general/Download";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { modalstyle } from "settings/styleOverrides";
import Utilities from "utils/utilities";

const ImageModal = (props) => {
  const { open, handleClose, imgSrc, displayName, photoUrl, media } = props;
  const theme = useTheme();
  const [src, setSrc] = useState("");
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const lgDown = useMediaQuery(theme.breakpoints.down("md"));
  const darkMode = useSelector(
    ({ entities }) => entities.settings.current.darkMode
  );

  const style = modalstyle();
  style.width = isSmall ? "90%" : mdDown ? "80%" : lgDown ? "65%" : "50%";
  style.minHeight = 400;
  style.display = "flex";
  style.flexDirection = "column";

  // TODO: use some carousel library to make it easy
  // Hide right/left arrow on extremities
  const findNewSrc = (value) => {
    const filteredMedia = media.filter((x) => x.type?.includes("image"));
    const index = filteredMedia.findIndex((x) => x.src === src);
    if (index === -1) return;
    const newIndex = value === "prev" ? index - 1 : index + 1;
    const newSrc = filteredMedia[newIndex];
    if (!newSrc) return;
    setSrc(newSrc.src);
  };

  useEffect(() => {
    setSrc(imgSrc);
  }, [imgSrc]);

  return (
    <Modal
      BackdropProps={{ style: { backgroundColor: "grey", opacity: "0.4" } }}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-display-image"
      aria-describedby="modal-image"
    >
      <Box sx={style}>
        <div className="modal-header bg-primaryBlue h-[75px] flex flex-col justify-center">
          <div className="flex justify-between px-8">
            <Typography className="text-white text-[20px]">Gallery</Typography>
            <Box onClick={handleClose} className="cursor-pointer">
              <Close />
            </Box>
          </div>
        </div>
        {/* TODO: Call storage API to show next/prev image */}
        <div
          className={`flex ${
            media?.length > 0 ? "justify-between" : "justify-center"
          } items-center h-full mt-28`}
        >
          {media?.length > 0 && (
            <div className="px-6 flex h-full justify-center items-center">
              <Box
                className="cursor-pointer"
                onClick={() => findNewSrc("prev")}
              >
                <ArrowLeft height={38} width={22} />
              </Box>
            </div>
          )}
          <div className={`flex justify-center items-center`}>
            <img
              className={`object-contain mt-6`}
              src={src}
              height={400}
              width={400}
              alt="gallery image"
            />
          </div>
          {media?.length > 0 && (
            <div className="px-6 flex h-full justify-center items-center">
              <Box
                className="cursor-pointer"
                onClick={() => findNewSrc("next")}
              >
                <ArrowRight height={38} width={22} />
              </Box>
            </div>
          )}
        </div>
        <div className="flex mt-10 h-fit w-full justify-center items-center">
          {/* <Avatar
            className="h-[38px] w-[38px]"
            src={`${photoUrl ?? "./assets/images/svg/profile.svg"}`}
          />
          <Typography className="font-semibold ml-3 text-lg">
            {displayName}
          </Typography> */}
          <Button
            onClick={() => Utilities.downloadFile(imgSrc)}
            type="button"
            variant="outlined"
            className={`rounded-md border-none flex justify-center m-8 ${
              darkMode && "text-white border-slate-50"
            }`}
          >
            <Box className="mr-6 cursor-pointer">
              <Download />
            </Box>
            Download
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default ImageModal;
