import Utilities from "utils/utilities";
import _ from "utils/lodash";

const ListModel = (data) => {
  data = data || {};

  return _.defaults(data, {
    id: Utilities.generateGUID(),
    name: "",
    idCards: [],
  });
};

export default ListModel;
