import { Avatar, AvatarGroup, useMediaQuery } from "@mui/material";
import { createTheme } from "@mui/system";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  selectMembers,
  selectMembersEntities,
} from "views/members/store/membersSlice";
import TeamMembersModal from "./modals/TeamMembersModal";
import Utilities from "utils/utilities";
const theme = createTheme();

const TeamMembers = (props) => {
  const members = useSelector(selectMembers);
  const membersLookup = useSelector(selectMembersEntities);

  const [modalVisible, setModalVisible] = useState(false);
  const { users } = props;
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  if (!users) return null;

  return (
    <>
      <AvatarGroup
        max={isSm ? 2 : 4}
        sx={{
          "& .MuiAvatar-root": {
            fontSize: "1.3rem",
            lineHeight: "1.75rem",
            width: "35px",
            height: "35px",
            [theme.breakpoints.between("md", "sm")]: {
              width: "33px",
              height: "33px",
              fontSize: "1.5rem",
            },
            [theme.breakpoints.down("sm")]: {
              width: "27px",
              height: "27px",
              fontSize: "1rem",
            },
          },
        }}
        className="justify-center"
        onClick={() => setModalVisible(true)}
      >
        {members.length > 0 &&
          users?.map((id, idx) => {
            const {
              name = "",
              avatar,
              color,
            } = Utilities.findMemberById(membersLookup, id);
            return (
              <Avatar
                alt={name}
                src={avatar}
                key={idx}
                style={{ backgroundColor: color }}
              >
                {name && name[0]?.toLocaleUpperCase()}
              </Avatar>
            );
          })}
      </AvatarGroup>
      <TeamMembersModal
        members={members.filter((x) => users?.includes(x.id))}
        open={modalVisible}
        handleClose={() => setModalVisible(false)}
      />
    </>
  );
};

export default TeamMembers;
