import { yupResolver } from "@hookform/resolvers/yup";
import { Close } from "@mui/icons-material";
import {
  Button,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import DateRangePicker from "components/pickers/DateRangePicker";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useStyledFormInputStyles } from "settings/styleOverrides";
import Utilities from "utils/utilities";
import { setEventFilter } from "views/calendar/store/eventsSlice";
import {
  selectMembers,
  selectMembersEntities,
} from "views/members/store/membersSlice";
import {
  resetFilter,
  setBoardFilter,
} from "views/scrumboard/store/filtersSlice";
import { selectBoardLabels } from "views/scrumboard/store/labelsSlice";
import {
  selectLabels,
  selectTaskLabelsEntities,
} from "views/tasks/store/labelsSlice";
import { setTaskFilter } from "views/tasks/store/tasksSlice";
import {
  resetTrackerFilter,
  setStatus,
  setTrackerFilter,
} from "views/tracker/store/trackerSlice";
import * as yup from "yup";

const schema = yup.object().shape({
  value: yup.string().required("You must enter a value."),
});

const FilterBar = (props) => {
  const { style, className, searchByOnlyOneUser } = props;
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const members = useSelector(selectMembers);
  const membersEntities = useSelector(selectMembersEntities);
  const taskLabels = useSelector(selectLabels);
  const taskLabelsEntities = useSelector(selectTaskLabelsEntities);
  const scrumboardLabels = useSelector(selectBoardLabels);
  const classes = useStyledFormInputStyles();
  const [filters, setFilters] = useState([]);
  const [error, setError] = useState({ status: false, message: "" });
  const showMemberLabel = style !== "tracker" || user?.role?.includes("owner");
  const params = new URLSearchParams(window.location.search);
  const MEMBER_ID = params.get("userId");
  const STATUS = Utilities.getTrackerStatus();
  const selectStyles = {
    height: "40px",
    marginTop: "2px",
    color: user?.data?.settings?.themeColor,
    backgroundColor: "white",
  };

  // Fetch all selectors as we cannot use the conditionally
  const taskFilters = useSelector(({ taskApp }) => taskApp?.tasks?.filter);
  const trackerFilters = useSelector(({ trackerApp }) => trackerApp?.filter);
  const eventFilters = useSelector(
    ({ calendarApp }) => calendarApp?.events?.filter
  );
  const scrumboardFilters = useSelector(
    ({ scrumboardApp }) => scrumboardApp?.filter?.data
  );
  const trackerRawData = useSelector(({ trackerApp }) => trackerApp?.rawData);

  let defaultFilters = [];
  switch (style) {
    case "calendar":
      defaultFilters = eventFilters;
      break;
    case "task":
      defaultFilters = taskFilters;
      break;
    case "tracker":
      defaultFilters = trackerFilters;
      break;
    case "scrumboard":
      defaultFilters = scrumboardFilters;
      break;
    case "scrumboard":
      defaultFilters = scrumboardFilters;
  }

  const defaultValues = {
    label: showMemberLabel ? "member" : "project",
    operator: "==",
    value: "",
    id: "",
    color: "",
  };
  const { control, formState, handleSubmit, reset, watch } = useForm({
    defaultValues: defaultValues,
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const { isValid, errors, dirtyFields } = formState;
  const filterForm = watch();

  useEffect(() => {
    if (defaultFilters?.length > 0) setFilters(defaultFilters);
  }, [defaultFilters]);

  const onSubmit = (model) => {
    if (!isValid) return;
    switch (filterForm.label) {
      case "member":
        if (searchByOnlyOneUser) {
          const checkIfWeUserMemberFilter = filters.find(
            (x) => x.label === "member"
          );
          if (checkIfWeUserMemberFilter) {
            setError({
              status: true,
              message:
                "You can only filter by one member at a time. You can go to reporting to generate reports for multiple members.",
            });
            return;
          }
        }
        const member = Utilities.findMemberById(membersEntities, model.value);
        setFilters([
          ...filters,
          {
            label: model.label,
            operator: model.operator,
            value: member.name,
            id: model.value,
            color: member.color,
          },
        ]);
        break;
      case "label":
        const label = Utilities.findLabelById(taskLabelsEntities, model.value);
        setFilters([
          ...filters,
          {
            label: model.label,
            operator: model.operator,
            value: label.title,
            id: label.id,
            color: label.color,
          },
        ]);
        break;
      case "project":
        const project = trackerRawData.projects.find(
          (x) => x.objectID === model.value
        );
        setFilters([
          ...filters,
          {
            label: model.label,
            operator: model.operator,
            value: project?.name || "",
            id: model.value,
            color: user?.data?.more?.color,
          },
        ]);
        break;
      case "priority":
      case "favourite":
      case "completed":
      case "status":
        setFilters([
          ...filters,
          {
            label: model.label,
            operator: model.operator,
            value: model.value,
            id: model.value === "true",
            color: user?.data?.more?.color,
          },
        ]);
        break;
    }
    reset(defaultValues);
  };

  const applyFilter = () => {
    switch (style) {
      case "calendar":
        dispatch(setEventFilter(filters));
        break;

      case "task":
        dispatch(setTaskFilter(filters));
        break;

      case "tracker":
        dispatch(setTrackerFilter(filters));
        break;

      case "scrumboard":
        dispatch(setBoardFilter(filters));
        break;
    }
  };

  const handleDelete = (id) => {
    const cloneFilters = [...filters];
    _.remove(cloneFilters, { id });
    setFilters(cloneFilters);

    if (style === "scrumboard" && cloneFilters.length === 0) {
      dispatch(resetFilter());
    } else if (style === "tracker") {
      if (error?.status) setStatus({ error: false, message: "" });
      if (cloneFilters.length === 0) dispatch(resetTrackerFilter());
    }
  };

  return (
    <div
      className={`relative block gap-x-4 gap-y-5 pb-6 px-6 ${className} ${
        style === "calendar" ? "" : "bg-blueTransparent"
      }`}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        {style && (
          <>
            <div
              className={`flex gap-3 ${
                style === "calendar" ? "mt-4" : ""
              } flex-wrap`}
            >
              <div className="xs:w-[190px] sm:w-[140px] md:w-[190px]">
                <span className="text-grayText font-medium">Filter by</span>
                <Controller
                  name="label"
                  control={control}
                  defaultValue={`${showMemberLabel ? "member" : "project"}`}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      className={`${classes.grayIcon}`}
                      value={value}
                      onChange={onChange}
                      id="label"
                      name="label"
                      sx={selectStyles}
                      fullWidth
                      displayEmpty
                      inputProps={{ "aria-label": "Filter By" }}
                      disabled={!!MEMBER_ID}
                    >
                      {(!showMemberLabel || style === "tracker") && (
                        <MenuItem value="project">Project</MenuItem>
                      )}
                      {showMemberLabel && (
                        <MenuItem value="member">Member</MenuItem>
                      )}
                      {style !== "tracker" && (
                        <MenuItem value="label">Label</MenuItem>
                      )}
                      {style === "tracker" &&
                        [{ key: "status", value: "Status" }].map((x, idx) => (
                          <MenuItem key={idx} value={x.key}>
                            {x.value}
                          </MenuItem>
                        ))}
                      {style === "task" &&
                        [
                          { key: "favourite", value: "Favourite" },
                          { key: "completed", value: "Completed" },
                          { key: "priority", value: "Priority" },
                        ].map((x, idx) => (
                          <MenuItem key={idx} value={x.key}>
                            {x.value}
                          </MenuItem>
                        ))}
                      {style === "sprint" && (
                        <MenuItem value="dueDate">Due Date</MenuItem>
                      )}
                    </Select>
                  )}
                />
              </div>
              <div className="xs:w-[190px] sm:w-[140px] md:w-[190px]">
                <span className="text-grayText font-medium">Operator</span>
                <Controller
                  name="operator"
                  control={control}
                  defaultValue="=="
                  render={({ field: { onChange, value } }) => (
                    <Select
                      className={`${classes.hideIcon} ${classes.grayIcon}`}
                      value={value}
                      onChange={onChange}
                      id="operator"
                      name="operator"
                      sx={selectStyles}
                      fullWidth
                      displayEmpty
                      inputProps={{ "aria-label": "Operator" }}
                      disabled={!!MEMBER_ID}
                    >
                      <MenuItem value={"=="}>Equal to</MenuItem>
                      <MenuItem value={"!=="}>Not Equal to</MenuItem>
                      {style === "sprint" &&
                        filterForm.label === "dueDate" &&
                        [
                          { key: "<", value: "Less than" },
                          { key: ">", value: "Greater than" },
                          { key: "<=", value: "Less than Equal to" },
                          { key: ">=", value: "Greater than Equal to" },
                          { key: "<>", value: "Between" },
                        ].map((x, idx) => (
                          <MenuItem key={idx} value={x.key}>
                            {x.value}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
              </div>
              <div className="xs:w-[190px] sm:w-[140px] md:w-[190px] ">
                <span className="text-grayText font-medium">Value</span>
                {/* <div className="rounded-[5px] bg-white border mt-[2px]">
                  <Controller
                    name="value"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        autoComplete="off"
                        type="text"
                        id="value"
                        autoFocus
                        required
                        fullWidth
                        error={!!errors.value}
                        helperText={errors?.value?.message}
                        inputProps={{
                          className: classes.input,
                        }}
                      />
                    )}
                  />
                 </div> */}

                {filterForm.label === "dueDate" && (
                  <Controller
                    name="value"
                    control={control}
                    defaultValue={new Date()}
                    render={({ field: { onChange, value } }) => (
                      <DateRangePicker onChange={onChange} value={value} />
                    )}
                  />
                )}

                {filterForm.label !== "dueDate" && (
                  <Controller
                    name="value"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <Select
                          className={`${classes.grayIcon}`}
                          value={value}
                          onChange={onChange}
                          id="value"
                          name="value"
                          sx={selectStyles}
                          fullWidth
                          displayEmpty
                          inputProps={{ "aria-label": "Value" }}
                          disabled={!!MEMBER_ID}
                        >
                          {filterForm.label === "member" &&
                            members?.map((member) => {
                              if (
                                filters.findIndex((x) => x.id === member.id) !==
                                -1
                              )
                                return;
                              return (
                                <MenuItem key={member.id} value={member.id}>
                                  {member.displayName}
                                </MenuItem>
                              );
                            })}
                          {filterForm.label === "label" &&
                            style === "task" &&
                            taskLabels?.map((label) => {
                              if (
                                filters.findIndex((x) => x.id === label.id) !==
                                -1
                              )
                                return;
                              return (
                                <MenuItem key={label.id} value={label.id}>
                                  {label.title}
                                </MenuItem>
                              );
                            })}
                          {filterForm.label === "label" &&
                            ["scrumboard", "sprint"].includes(style) &&
                            scrumboardLabels?.map((label) => {
                              if (
                                filters.findIndex((x) => x.id === label.id) !==
                                -1
                              )
                                return;
                              return (
                                <MenuItem key={label.id} value={label.id}>
                                  {label.title}
                                </MenuItem>
                              );
                            })}
                          {![
                            "member",
                            "dueDate",
                            "label",
                            "status",
                            "project",
                          ].includes(filterForm.label) &&
                            [
                              { key: "true", value: "True" },
                              { key: "false", value: "False" },
                            ].map((x, idx) => (
                              <MenuItem key={idx} value={x.key}>
                                {x.value}
                              </MenuItem>
                            ))}
                          {style === "tracker" &&
                            filterForm.label === "status" &&
                            Object.keys(STATUS).map((x) => {
                              const value = STATUS[x];
                              if (
                                filters.findIndex((x) => x.value === value) !==
                                -1
                              )
                                return;
                              return (
                                <MenuItem key={x} value={value}>
                                  {_.capitalize(value)}
                                </MenuItem>
                              );
                            })}
                          {style === "tracker" &&
                            filterForm.label === "project" &&
                            trackerRawData?.projects?.map((project) => {
                              if (
                                filters.findIndex(
                                  (x) => x.id === project.objectID
                                ) !== -1
                              )
                                return;
                              return (
                                <MenuItem
                                  key={project.objectID}
                                  value={project.objectID}
                                >
                                  {project.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                        <FormHelperText error={!!errors.value} required>
                          {errors?.value?.message}
                        </FormHelperText>
                      </>
                    )}
                  />
                )}
              </div>
              <div className="xs:w-[190px] sm:min-w-[140px] md:min-w-[170px] flex items-end gap-x-3 h-[60px]">
                <Button
                  type="submit"
                  className={`${
                    _.isEmpty(dirtyFields) || !isValid || !!MEMBER_ID
                      ? "bg-blueTransparent"
                      : "bg-primaryBlue"
                  } " rounded hover:bg-primaryBlueLight text-sm md:text-base px-1 md:px-2 text-white min-w-[80px] h-[2.5rem]"`}
                  disabled={_.isEmpty(dirtyFields) || !isValid || !!MEMBER_ID}
                >
                  Add
                </Button>

                <Button
                  onClick={() => applyFilter()}
                  disabled={filters.length === 0 || !!MEMBER_ID}
                  className={`${
                    filters.length === 0
                      ? "bg-lightGrayTransparent"
                      : "bg-lightGray"
                  } " rounded hover:bg-lightGray/70 text-sm md:text-base px-1 md:px-2 text-white min-w-[80px] h-[2.5rem]"`}
                >
                  Apply
                </Button>
              </div>
            </div>
          </>
        )}
        {searchByOnlyOneUser && error.status && (
          <FormHelperText error={error.status}>{error.message}</FormHelperText>
        )}
        {filters.length > 0 && (
          <div className="flex flex-wrap gap-x-4">
            {filters.map((x, idx) => (
              <div
                className={`flex items-center gap-x-4 px-6 py-3 rounded-full w-fit mt-4`}
                key={idx}
                style={{ backgroundColor: x.color }}
              >
                <Typography className="text-white">
                  {x.label} {x.operator} {x.value}
                </Typography>
                <div className="flex bg-danger w-4 h-4 justify-center items-center rounded-full cursor-pointer">
                  {" "}
                  <IconButton
                    disabled={!!MEMBER_ID}
                    onClick={() => handleDelete(x.id)}
                  >
                    {" "}
                    <Close className="w-[14px] h-[14px]" htmlColor="white" />
                  </IconButton>
                </div>
              </div>
            ))}
          </div>
        )}
      </form>
    </div>
  );
};

export default FilterBar;
