const DeleteIcon = (props) => {
  const { height = 22, width = 19, color = "#f44336" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 19.064 22.505"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_18700"
            data-name="Rectangle 18700"
            width="19.064"
            height="22.505"
            fill={color}
            stroke="#f44336"
            strokeWidth="0.4"
          />
        </clipPath>
      </defs>
      <g id="Group_43790" data-name="Group 43790" clipPath="url(#clipPath)">
        <path
          id="Path_38726"
          data-name="Path 38726"
          d="M6.081,1.654c0-.032,0-.064,0-.1,0-.269,0-.539,0-.808A.781.781,0,0,1,6.858,0C7.5,0,8.141,0,8.783,0q1.688,0,3.375,0a.788.788,0,0,1,.783.55.829.829,0,0,1,.037.241c0,.258,0,.516,0,.774v.091c.026,0,.045.007.065.007q2.307,0,4.615,0a2.543,2.543,0,0,1,.609.047,1.067,1.067,0,0,1,.793,1.029c0,.77,0,1.54,0,2.319H17.512c-.01.1-.02.2-.027.3-.037.487-.074.975-.112,1.462-.044.56-.09,1.121-.134,1.681-.038.484-.074.969-.112,1.454s-.079.975-.117,1.462c-.037.47-.072.94-.109,1.41q-.068.867-.137,1.734-.047.6-.093,1.208-.066.845-.132,1.69l-.132,1.69c-.044.557-.085,1.115-.132,1.672a4.148,4.148,0,0,1-.082.66,1.373,1.373,0,0,1-1.327,1.02l-4.817,0q-2.659,0-5.318,0A6.607,6.607,0,0,1,4,22.485a1.352,1.352,0,0,1-1.164-1.169c-.054-.5-.087-1-.128-1.5s-.08-.986-.118-1.479c-.036-.47-.068-.94-.105-1.41-.052-.657-.108-1.313-.16-1.97-.056-.709-.108-1.419-.164-2.128-.05-.636-.1-1.272-.152-1.909-.044-.56-.084-1.121-.128-1.681-.049-.63-.1-1.261-.152-1.891-.039-.49-.074-.981-.111-1.471-.021-.268-.044-.537-.067-.815H0v-.1Q0,3.88,0,2.79A1.085,1.085,0,0,1,1.1,1.664c1.128-.009,2.256,0,3.384,0H6.039a.247.247,0,0,0,.042-.009M2.5,5.065c.01.133.02.255.029.378q.057.718.113,1.436c.038.487.073.975.111,1.462s.08.992.118,1.488c.033.423.062.846.095,1.269.019.251.043.5.063.753.032.406.061.812.093,1.217.038.484.08.968.117,1.453s.07.964.107,1.445c.032.4.07.8.1,1.207.039.49.074.981.112,1.471q.085,1.13.171,2.259c.006.073.015.145.023.218a.451.451,0,0,0,.506.445l4.745,0,4.219,0c.1,0,.2,0,.3,0,.3,0,.591,0,.886,0,.17,0,.34,0,.509-.013a.422.422,0,0,0,.386-.382c.028-.294.048-.589.071-.884q.094-1.178.188-2.355c.044-.563.085-1.127.129-1.69.056-.715.115-1.43.171-2.145.05-.633.1-1.267.146-1.9.056-.709.114-1.418.17-2.127.062-.788.121-1.576.182-2.364s.124-1.57.186-2.355c.007-.095.013-.189.02-.289Zm15.63-.945V2.826c0-.173-.051-.224-.226-.224l-3.744,0H1.149c-.157,0-.212.055-.212.21q0,.615,0,1.23c0,.025,0,.05.006.078ZM12.041.941H7.025v.714h5.016Z"
          transform="translate(0 0)"
          fill={color}
          stroke="#f44336"
          strokeWidth="0.4"
        />
        <rect
          id="Rectangle_18696"
          data-name="Rectangle 18696"
          width="0.932"
          height="11.979"
          transform="translate(10.35 7.325)"
          fill={color}
          stroke="#f44336"
          strokeWidth="0.4"
        />
        <rect
          id="Rectangle_18697"
          data-name="Rectangle 18697"
          width="0.933"
          height="11.979"
          transform="translate(5.212 7.324)"
          fill={color}
          stroke="#f44336"
          strokeWidth="0.4"
        />
        <rect
          id="Rectangle_18698"
          data-name="Rectangle 18698"
          width="0.929"
          height="11.98"
          transform="translate(12.918 7.324)"
          fill={color}
          stroke="#f44336"
          strokeWidth="0.4"
        />
        <rect
          id="Rectangle_18699"
          data-name="Rectangle 18699"
          width="0.929"
          height="11.979"
          transform="translate(7.782 7.324)"
          fill={color}
          stroke="#f44336"
          strokeWidth="0.4"
        />
      </g>
    </svg>
  );
};

export default DeleteIcon;
