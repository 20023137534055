import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import Draggable from "react-draggable";
import { useDispatch } from "react-redux";
import { openChatPanel } from "./store/stateSlice";

const ChatPanelToggleButton = (props) => {
  const dispatch = useDispatch();
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = ui;
    setPosition({ x, y });
  };
  return (
    <Draggable
      onDrag={handleDrag}
      position={position}
      bounds="parent"
      defaultClassName="fixed bottom-4 right-4 z-20"
    >
      <IconButton size="large" onClick={() => dispatch(openChatPanel())}>
        {props.children}
      </IconButton>
    </Draggable>
  );
};

ChatPanelToggleButton.defaultProps = {
  children: <Icon>chat</Icon>,
};

export default ChatPanelToggleButton;
