import React from "react";

const EmptyChat = () => {
  const className = "fill-primaryBlue";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="233.578"
      height="220.254"
      viewBox="0 0 233.578 220.254"
      className={className}
    >
      <g
        id="Group_45968"
        data-name="Group 45968"
        transform="translate(-1190 -410.18)"
      >
        <path
          id="Path_39899"
          data-name="Path 39899"
          d="M233.578,119.615V129.77a57.891,57.891,0,0,1-23.241,36.323c-6.886,5.065-14.589,9.017-21.868,13.562a12.156,12.156,0,0,0-4,3.449,68.316,68.316,0,0,1-21.278,21.415c-17.858,11.6-37.793,17.078-58.058,19.944,5.223-3.772,11.1-7.424,16.258-11.9,5.388-4.674,8.777-10.719,8.76-18.511-19.1,1.006-37.449-.113-55.527-4.344-15.8-3.7-30.823-9.322-44.453-18.314C14.885,161.305,3.607,148.32,0,129.77V119.615c2.051-8.363,4.766-16.4,10.119-23.373,7.344-9.567,16.615-16.778,27.05-22.58,18.114-10.069,37.74-15.307,58.188-17.6a169.5,169.5,0,0,1,39.852-.084c7.836,37.991,49.475,55.91,82.273,33.952a6.1,6.1,0,0,1,1.116.747c.745.774,1.415,1.619,2.137,2.417,6.873,7.586,10.59,16.7,12.843,26.524m-58.588-7.647c-7.209.053-13.211,5.561-13.106,12.025.106,6.534,6.1,12.06,13.175,12.156,7.138.1,13.465-5.828,13.3-12.458s-6.044-11.777-13.371-11.723m-118.365-.024c-7.325.013-13.163,5.231-13.271,11.86-.105,6.582,6.3,12.466,13.434,12.341,7.054-.124,13.077-5.784,13.052-12.265-.026-6.536-6.019-11.948-13.215-11.936m79.069,0c-7.354-.034-13.13,5.154-13.168,11.828-.039,6.81,6.054,12.486,13.271,12.364,7.075-.119,12.972-5.7,12.931-12.245-.042-6.772-5.651-11.912-13.034-11.948m-39.482-.006c-7.367-.036-13.1,5.078-13.206,11.789-.111,6.736,6.031,12.5,13.231,12.422,7.117-.08,12.947-5.573,12.959-12.209.012-6.75-5.631-11.966-12.983-12"
          transform="translate(1190 405.97)"
        />
        <path
          id="Path_39900"
          data-name="Path 39900"
          d="M204.005,0c1.459.373,2.905.81,4.379,1.111,20.957,4.271,34.951,23.707,33.428,46.457-1.281,19.152-18.889,36.769-38.205,38.226-22.551,1.7-42.354-12.389-46.639-33.187-5.152-25,9.856-47.053,35.256-51.84A7.134,7.134,0,0,0,193.849,0ZM192.228,67.282h13.634V50.227h17.444V35.869H205.669V18.756H192.119V36.049H174.725V50.538h17.5Z"
          transform="translate(1178.027 410.18)"
        />
      </g>
    </svg>
  );
};

export default EmptyChat;
