import { Icon, IconButton, Tooltip } from "@mui/material";
import { Box } from "@mui/system";

const UploadImage = (props) => {
  const {
    icon,
    onChange,
    id,
    acceptFiles,
    disabled,
    className,
    title,
    imgSrc,
    alt,
    textClassName,
    subtitle,
    size,
    color,
  } = props;
  const handleChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    const reader = new FileReader();

    reader.readAsBinaryString(file);

    reader.onload = () => {
      if (onChange) {
        const url = URL.createObjectURL(file);
        onChange(file, url);
      }
    };

    reader.onerror = () => {
      console.error("error on load image");
    };
  };

  return (
    <>
      <label htmlFor={id || "button-file"} className="z-0">
        <input
          accept={
            acceptFiles ? "" : `image/png, image/jpeg, image/jpg, image/gif`
          }
          className="hidden"
          id={id || "button-file"}
          type="file"
          onChange={handleChange}
          onClick={(e) => (e.target.value = null)}
          disabled={disabled}
        />

        <Tooltip title={title || alt} placement="bottom">
          {imgSrc ? (
            typeof imgSrc === "string" ? (
              <img className={className} src={imgSrc} alt={alt} />
            ) : (
              <Box className={className}>{imgSrc}</Box>
            )
          ) : (
            <IconButton className={`${className}`} component="span" size={size}>
              {icon && (
                <Icon
                  className="text-24 "
                  color="action"
                  style={{ color: color }}
                >
                  {icon}
                </Icon>
              )}
              {subtitle && <p className={textClassName}>{subtitle}</p>}
            </IconButton>
          )}
        </Tooltip>
      </label>
    </>
  );
};

export default UploadImage;
