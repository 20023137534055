import { Close } from "@mui/icons-material";
import DisplayFileOrImage from "components/display/DisplayImageOrFile";
import ImageModal from "components/modals/ImageModal";
import { useState } from "react";
import UploadImage from "./UploadImage";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import _ from "utils/lodash";
import Attachment from "components/svgs/icons/general/Attachment";

const UploadMedia = (props) => {
  const { updateMedia, mediaData, user, disabled } = props;

  const [popupImgUrl, setPopupImgUrl] = useState("");
  const [imgModalOpen, setImgModalOpen] = useState(false);
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  const onUploadMedia = (file, url) => {
    const data = [...mediaData, { file: file, url: url }];
    updateMedia(data);
  };

  const onDeleteMedia = (idx) => {
    const clonedData = _.cloneDeep(mediaData);
    _.pullAt(clonedData, idx);
    updateMedia(clonedData);
  };

  const imgClickHandler = (src) => {
    setPopupImgUrl(src);
    setImgModalOpen(true);
  };

  return (
    <>
      <div className="flex w-full gap-2 flex-wrap xs:justify-center xmd:justify-start">
        <div className="max-w-[8em]">
          {!disabled && (
            <UploadImage
              imgSrc={<Attachment />}
              title="Upload Media"
              size="large"
              className={`cursor-pointer object-cover ${
                mdDown
                  ? "xs:w-[5rem] xs:h-[5rem] xmd:w-[6.5rem] xmd:h-[6.5rem]"
                  : ""
              }`}
              onChange={onUploadMedia}
              id="media"
              acceptFiles="jpg, .png, .doc, .pdf"
            />
          )}
        </div>
        {mediaData &&
          mediaData.map((obj, idx) => (
            <div className="max-w-[8em] relative" key={idx}>
              {!disabled && (
                <div
                  onClick={() => onDeleteMedia(idx)}
                  className="flex justify-center items-center absolute -right-2 -top-1 bg-danger rounded-full cursor-pointer"
                >
                  <Close className="w-[16px] h-[16px]" htmlColor="white" />
                </div>
              )}
              <DisplayFileOrImage
                url={obj.url}
                name={obj.file && obj.file.name ? obj.file.name : obj.file}
                onClickFunc={() => imgClickHandler(obj.url)}
                className={"w-[80px] h-[80px]"}
              />
            </div>
          ))}
      </div>

      {popupImgUrl && (
        <ImageModal
          open={imgModalOpen}
          handleClose={() => setImgModalOpen(false)}
          imgSrc={popupImgUrl}
          displayName={user?.data?.displayName}
          photoUrl={user?.data?.photoURL}
        />
      )}
    </>
  );
};

export default UploadMedia;
