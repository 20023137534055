import CircleIcon from "@mui/icons-material/Circle";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { alpha, styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import icons from "configs/icons";
import NavLinkAdapter from "core/NavLinkAdapter";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import NavBadge from "../../NavBadge";

const Root = styled(ListItem)(({ theme, ...props }) => ({
  height: 40,
  width: "100%",
  borderRadius: "6px",
  margin: "0 0 4px 0",
  paddingRight: 12,
  paddingLeft: props.itempadding,
  color: alpha(theme.palette.text.primary, 0.7),
  cursor: "pointer",
  textDecoration: "none!important",
  "&:hover": {
    color: theme.palette.text.primary,
  },
  "&.active": {
    color: theme.palette.text.primary,
    backgroundColor:
      theme.palette.mode === "light"
        ? "rgba(0, 0, 0, .05)!important"
        : "rgba(255, 255, 255, .1)!important",
    pointerEvents: "none",
    transition: "border-radius .15s cubic-bezier(0.4,0.0,0.2,1)",
    "& > .default-list-item-text-primary": {
      color: "inherit",
    },
    "& > .default-list-item-icon": {
      color: "inherit",
    },
  },
  "& >.default-list-item-icon": {
    marginRight: 12,
    color: "inherit",
  },
  "& > .default-list-item-text": {},
}));

const NavVerticalItem = (props) => {
  const user = useSelector(({ auth }) => auth.user);
  const isNativeApp = useSelector(
    ({ entities }) => entities.settings.isNativeApp
  );
  const { item, nestedLevel, onItemClick } = props;

  const itempadding = nestedLevel > 0 ? 28 + nestedLevel * 16 : 12;
  const NavIcon = icons[item.icon];

  const workspaceId = user?.data?.currentWorkspace;
  const newUrl = item.url.replace(":workspaceId", workspaceId);

  return useMemo(() => {
    if (item.native && !isNativeApp) return null;
    return (
      <Root
        button
        component={NavLinkAdapter}
        to={newUrl}
        activeClassName="active"
        className="default-list-item"
        onClick={() => onItemClick && onItemClick(item)}
        end={item.end}
        itempadding={itempadding}
        role="button"
        data-tour={item.title.toLowerCase()}
      >
        {item.icon && !item.icon.startsWith("#") && (
          <Box className="w-6 mr-4">
            <NavIcon />
          </Box>
        )}
        {item.icon && item.icon.startsWith("#") && (
          <CircleIcon className="mr-3" htmlColor={item.icon} fontSize="small" />
        )}

        <ListItemText
          className="default-list-item-text truncate"
          primary={item.title}
          classes={{
            primary:
              "text-13 font-medium default-list-item-text-primary truncate",
          }}
        />
        {item.badge && item.badge > 0 && <NavBadge badge={item.badge} />}
      </Root>
    );
  }, [item, itempadding, onItemClick, user]);
};

NavVerticalItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.string,
    url: PropTypes.string,
  }),
};

NavVerticalItem.defaultProps = {};

export default NavVerticalItem;
