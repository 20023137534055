import React from "react";

const Save = () => {
  const className = "fill-primaryBlue";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26.353"
      height="26.354"
      viewBox="0 0 26.353 26.354"
      className={className}
    >
      <path
        id="Path_37807"
        data-name="Path 37807"
        d="M77.267,7v8.59H89.582V7.006c1.23,0,2.391-.013,3.552.015.171,0,.359.186.5.325.872.857,1.754,1.708,2.584,2.6a1.518,1.518,0,0,1,.376.944q.032,10.5.014,21.005c0,1.073-.382,1.452-1.461,1.453q-11.713,0-23.426,0c-1.079,0-1.461-.379-1.462-1.452q0-11.713,0-23.426c0-1.085.376-1.466,1.444-1.47C73.521,7,75.34,7,77.267,7m6.164,14.5c-2.157,0-4.314,0-6.471,0-.955,0-1.262.306-1.265,1.256q-.009,3.626,0,7.251c0,.918.343,1.279,1.27,1.282q6.471.016,12.942,0c.927,0,1.262-.363,1.265-1.287q.009-3.626,0-7.251c0-.944-.314-1.248-1.271-1.25-2.157-.005-4.314,0-6.471,0"
        transform="translate(-70.257 -6.999)"
      />
    </svg>
  );
};

export default Save;
