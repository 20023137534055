import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useStyles } from "settings/styleOverrides";
import _ from "utils/lodash";
import * as yup from "yup";
import { createNewTopic, updateTopic } from "./store/discussionsSlice";

const schema = yup.object().shape({
  name: yup
    .string()
    .required("You must enter a topic name")
    .max(50, "You cannot have more than 50 characters"),
});

const AddTopicDialog = (props) => {
  const { onClose, open, row } = props;
  const dispatch = useDispatch();
  const routeParams = useParams();
  const user = useSelector(({ auth }) => auth.user);
  const projectId = routeParams.projectId;

  const classes = useStyles();
  const defaultValues = {
    name: row.name ?? "",
    description: row.description ?? "",
    favourites: row.favourites ?? "",
  };

  // IMPORTANT: This is needed as state might not be set at first
  // Ideally this can be fixed by updating the state in reducer
  useEffect(() => {
    reset(row);
  }, [row]);

  const { control, formState, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  const onSubmit = (model) => {
    if (!isValid) return;
    if (_.isEmpty(row)) {
      dispatch(createNewTopic({ user, projectId, data: model })).then(() => {
        handleClose();
      });
    } else {
      const data = {
        ...model,
        id: row.id,
        time: new Date(),
      };

      dispatch(updateTopic({ user, projectId, data })).then(() => {
        handleClose();
      });
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      component="form"
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: { borderRadius: 10 },
      }}
    >
      <DialogTitle className="bg-primaryBlue text-white h-[60px]">
        <Typography
          variant="subtitle1"
          color="inherit"
          className="absolute top-[10px]"
          component="span"
        >
          Add topic
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 40,
            top: 5,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent classes={{ root: "px-6 py-6 pb-0 sm:px-8" }}>
        <div className="my-6">
          <div>
            <div className="text-lg tracking-wide pb-2">Topic Name</div>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  autoComplete="off"
                  type="text"
                  id="board"
                  placeholder="Topic Name"
                  className=""
                  autoFocus
                  required
                  fullWidth
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  inputProps={{
                    className: classes.input + " !py-4 !pl-6",
                  }}
                />
              )}
            />
          </div>
          <div className="mt-6">
            <div className="text-lg tracking-wide pb-2">Description</div>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  autoComplete="off"
                  placeholder="Type your description here..."
                  multiline
                  rows="5"
                  variant="outlined"
                  InputProps={{
                    className: classes.inputTextArea,
                  }}
                  fullWidth
                />
              )}
            />
          </div>
          <div className="mt-6">
            <div className="flex justify-center items-center gap-x-6">
              <Button
                type="button"
                onClick={handleClose}
                className="bg-lightGray hover:bg-lightGray/70 rounded text-base px-1 md:px-4 text-white w-36"
              >
                <span className="text-lg">Cancel</span>
              </Button>
              <Button
                type="button"
                disabled={_.isEmpty(dirtyFields) || !isValid}
                onClick={handleSubmit(onSubmit)}
                className={`hover:bg-primaryBlueLight rounded text-base px-1 md:px-4 text-white w-36 ${
                  _.isEmpty(dirtyFields) || !isValid
                    ? "bg-blueTransparent"
                    : "bg-primaryBlue"
                }`}
              >
                <span className="text-lg">Save</span>
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddTopicDialog;
