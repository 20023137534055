import React from "react";

const Toggle = (props) => {
  const { rightPanelVisible } = props;
  const transform = rightPanelVisible
    ? "translate(1502.155 270.226) rotate(90)"
    : "translate(1490.155 290.226) rotate(-90)";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="32"
      height="60"
      viewBox="0 0 53 97"
      className="stroke-primaryBlue"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_17961"
            data-name="Rectangle 17961"
            width="53"
            height="97"
            transform="translate(1465.37 233)"
            fill="#fff"
            stroke="#707070"
            strokeWidth="1"
          />
        </clipPath>
        <filter
          id="Path_37793"
          x="-2.212"
          y="-0.904"
          width="106.119"
          height="98.904"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx="-2" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodColor="#00b9d9" floodOpacity="0.2" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Group_42399"
        data-name="Group 42399"
        transform="translate(-1461 -233)"
      >
        <g
          id="Mask_Group_12"
          data-name="Mask Group 12"
          transform="translate(-4.37)"
          clipPath="url(#clipPath)"
        >
          <g
            transform="matrix(1, 0, 0, 1, 1465.37, 233)"
            filter="url(#Path_37793)"
          >
            <path
              id="Path_37793-2"
              data-name="Path 37793"
              d="M44.06,0c24.333,0,44.06,18.111,44.06,40.452S68.393,80.9,44.06,80.9,0,62.793,0,40.452,19.726,0,44.06,0Z"
              transform="translate(8.79 8.1)"
              fill="#fff"
            />
          </g>
        </g>
        <path
          id="Icon_feather-chevron-down"
          data-name="Icon feather-chevron-down"
          d="M0,11.274,11.274,0,22.549,11.274"
          transform={transform}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
      </g>
    </svg>
  );
};

export default Toggle;
