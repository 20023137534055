import List from "@mui/material/List";
import Scrollbars from "core/Scrollbars";
import { motion } from "framer-motion";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Utilities from "utils/utilities";
import ContactListItem from "components/chatPanel/ContactListItem";
import { selectChats } from "views/chat/store/chatsSlice";
import {
  selectMembers,
  selectMembersEntities,
} from "views/members/store/membersSlice";
import {
  getChat,
  resetUnreadRealtime,
  setSelectedContactId,
} from "./store/chatSlice";
import { Link } from "react-router-dom";
import { updateStarredChats } from "auth/store/userSlice";
import { getMediaData } from "views/chat/store/chatSlice";
import { Box } from "@mui/system";
import Contacts from "components/svgs/icons/chat/Contacts";
import Recent from "components/svgs/icons/chat/Recent";
import Star from "components/svgs/icons/chat/Star";

const ChatContacts = (props) => {
  const { isMobile, searchText, closeLeftPanel, setContactListOpen, sideChat } =
    props;
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const members = useSelector(selectMembers);
  const membersEntities = useSelector(selectMembersEntities);
  const chats = useSelector(selectChats);
  const Chat = useSelector(({ chatApp }) => chatApp.chat.chat);
  const [chatListType, setChatListType] = useState("recents");
  const [recentChats, setRecentChats] = useState([]);
  const darkMode = useSelector(
    ({ entities }) => entities.settings.current.darkMode
  );

  useEffect(() => {
    const list = [];
    chats.slice(0, 10).forEach((x) => {
      const member = Utilities.findMemberById(membersEntities, x.contactId);
      const obj = {
        ...x,
        displayName: member.name,
        more: { color: member.color },
        photoURL: member.avatar,
        status: member.status,
        mood: member.mood,
      };
      list.push(obj);
    });

    setRecentChats(list);
  }, [chats]);

  const contactsInfo = () => {
    let filteredChatList = [],
      filteredContacts = [];
    switch (chatListType) {
      case "recents":
        filteredChatList = Utilities.getFilteredArray(
          [...recentChats],
          searchText,
          "displayName"
        );
        break;

      case "groups":
        filteredContacts = Utilities.getFilteredArray(
          [...members],
          searchText,
          "displayName"
        );
        break;

      case "favourites":
        const favChats = members.filter((x) =>
          user?.data?.starred.includes(x.id)
        );
        filteredContacts = filteredContacts = Utilities.getFilteredArray(
          [...favChats],
          searchText,
          "displayName"
        );
        break;
    }
    return [filteredChatList, filteredContacts];
  };

  const onContactClick = (contact) => {
    if (sideChat) {
      setContactListOpen(false);
      dispatch(setSelectedContactId(contact.contactId));
      if (contact.id !== Chat?.contactId) {
        dispatch(getChat({ user, contactId: contact.contactId }));
        dispatch(getMediaData({ user, contactId: contact.contactId }));
      }
    } else {
      closeLeftPanel();
    }
    if (+contact.unread > 0)
      dispatch(resetUnreadRealtime({ user, contactId: contact.contactId }));
  };

  const toggleFavouriteChat = (e, contactId) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(updateStarredChats({ user, contactId }));
  };

  return (
    <>
      <div
        className={`flex justify-between mt-6 mb-2 px-6 ${
          darkMode ? "bg-darkSmoke p-2" : ""
        }`}
      >
        <Box
          onClick={() => setChatListType("recents")}
          className="cursor-pointer"
        >
          <Recent
            className={chatListType === "recents" ? "fill-primaryBlue" : ""}
          />
        </Box>
        <Box
          onClick={() => setChatListType("groups")}
          className="cursor-pointer"
        >
          <Contacts
            className={chatListType === "groups" ? "fill-primaryBlue" : ""}
          />
        </Box>
        <Box
          onClick={() => setChatListType("favourites")}
          className="cursor-pointer"
        >
          <Star
            className={chatListType === "favourites" ? "fill-primaryBlue" : ""}
          />
        </Box>
      </div>

      <Scrollbars className="overflow-y-auto flex-1">
        <List className="w-full">
          {useMemo(() => {
            const [filteredChatList, filteredContacts] = contactsInfo();

            return (
              <motion.div
                className="flex flex-col shrink-0"
                variants={Utilities.containerConfig(0.1)}
                initial="hidden"
                animate="show"
              >
                {filteredChatList.map((contact) => (
                  <motion.div
                    variants={Utilities.itemOpacityConfig(20)}
                    key={contact.contactId}
                    initial="hidden"
                    animate="show"
                  >
                    {sideChat ? (
                      <ContactListItem
                        sideChat={sideChat}
                        listType={chatListType}
                        lastMessage={contact.lastMessageTime}
                        contact={contact}
                        onContactClick={onContactClick}
                        toggleFavouriteChat={toggleFavouriteChat}
                        user={user}
                      />
                    ) : (
                      <Link
                        to={`/${user.data.currentWorkspace}/chat/${contact.contactId}`}
                        role="button"
                      >
                        <ContactListItem
                          listType={chatListType}
                          lastMessage={contact.lastMessageTime}
                          contact={contact}
                          onContactClick={onContactClick}
                          toggleFavouriteChat={toggleFavouriteChat}
                          user={user}
                        />
                      </Link>
                    )}
                  </motion.div>
                ))}

                {filteredContacts
                  .filter((x) => x.id != user.uid)
                  .map((contact) => (
                    <motion.div
                      variants={Utilities.itemOpacityConfig()}
                      key={contact.id}
                    >
                      {sideChat ? (
                        <ContactListItem
                          sideChat
                          listType={chatListType}
                          contact={contact}
                          onContactClick={onContactClick}
                          toggleFavouriteChat={toggleFavouriteChat}
                          user={user}
                        />
                      ) : (
                        <Link
                          to={`/${user.data.currentWorkspace}/chat/${contact.id}`}
                          role="button"
                        >
                          <ContactListItem
                            listType={chatListType}
                            contact={contact}
                            onContactClick={onContactClick}
                            toggleFavouriteChat={toggleFavouriteChat}
                            user={user}
                          />
                        </Link>
                      )}
                    </motion.div>
                  ))}
              </motion.div>
            );
          }, [
            members,
            chats,
            recentChats,
            user,
            searchText,
            dispatch,
            isMobile,
            Chat,
            chatListType,
          ])}
        </List>
      </Scrollbars>
    </>
  );
};

export default ChatContacts;
