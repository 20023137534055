import React from "react";

const Star = (props) => {
  const { className = "fill-black" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 28.385 27"
      className={className}
    >
      <path
        id="Path_37774"
        data-name="Path 37774"
        d="M5.405,27c.136-.794.266-1.551.4-2.309q.622-3.635,1.249-7.27a.337.337,0,0,0-.122-.341Q3.579,13.82.234,10.559c-.072-.07-.156-.127-.234-.19L.027,10.3c.372-.055.744-.112,1.115-.166C3.784,9.753,6.423,9.348,9.069,9a1.209,1.209,0,0,0,1.068-.787c1.278-2.657,2.6-5.294,3.9-7.938.04-.08.083-.159.144-.275.1.192.177.349.255.507q2.015,4.081,4.025,8.165a.4.4,0,0,0,.349.249q4.714.677,9.427,1.364c.029,0,.058.016.146.094a1.459,1.459,0,0,0-.222.15q-3.357,3.266-6.712,6.533a.4.4,0,0,0-.13.405q.8,4.588,1.578,9.179c.018.105.032.211.054.355l-1.764-.927q-3.409-1.792-6.816-3.588a.364.364,0,0,0-.386,0q-4.16,2.194-8.325,4.379c-.074.039-.149.076-.257.131"
      />
    </svg>
  );
};

export default Star;
