import React from "react";

const FloatingChat = () => {
  const className = "fill-primaryBlue";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="56"
      height="56"
      viewBox="0 0 91 91"
      className={className}
    >
      <defs>
        <filter
          id="Oval"
          x="0"
          y="0"
          width="91"
          height="91"
          filterUnits="userSpaceOnUse"
        >
          <feOffset input="SourceAlpha" />
          <feGaussianBlur stdDeviation="2.5" result="blur" />
          <feFlood floodOpacity="0.2" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Group_42503"
        data-name="Group 42503"
        transform="translate(-13241.096 8326.5)"
      >
        <g transform="matrix(1, 0, 0, 1, 13241.1, -8326.5)" filter="url(#Oval)">
          <path
            id="Oval-2"
            data-name="Oval"
            d="M38,0A38,38,0,1,1,0,38,38,38,0,0,1,38,0Z"
            transform="translate(7.5 7.5)"
          />
        </g>
        <g
          id="chat_2_"
          data-name="chat (2)"
          transform="translate(13268.136 -8298.836)"
        >
          <path
            id="Combined_Shape"
            data-name="Combined Shape"
            d="M7.717,36.958a.627.627,0,0,1-.39-.589V30.063H3.621A3.686,3.686,0,0,1,0,26.323V12.99A3.686,3.686,0,0,1,3.621,9.25H26.905a3.686,3.686,0,0,1,3.621,3.74V26.323a3.686,3.686,0,0,1-3.621,3.74H14.312L8.388,36.794a.6.6,0,0,1-.672.164Zm-1-13.193a.621.621,0,0,0,.61.63H23.81a.631.631,0,0,0,0-1.261H7.326A.622.622,0,0,0,6.716,23.766Zm0-3.785a.622.622,0,0,0,.61.631H23.81a.631.631,0,0,0,0-1.261H7.326A.621.621,0,0,0,6.716,19.981Zm0-3.784a.621.621,0,0,0,.61.63h8.547a.631.631,0,0,0,0-1.261H7.326A.622.622,0,0,0,6.716,16.2Zm25.4,4.153v-8.06A4.871,4.871,0,0,0,27.275,7.4H6.475V3.657A3.642,3.642,0,0,1,10.095,0H33.38A3.642,3.642,0,0,1,37,3.657V16.693a3.642,3.642,0,0,1-3.62,3.656Z"
            transform="translate(0.46 -0.164)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};

export default FloatingChat;
