import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import firebase from "services/firebase";
import {
  prependNavigationItem,
  removeNavigationItem,
  updateNavigationItem,
} from "store/slice/navigationSlice";
import Utilities from "utils/utilities";

const uploadMediaAttachment = async (user, data, isUpdateMediaDirty) => {
  if (data.media?.length == 0 || !isUpdateMediaDirty) return data;

  const path = `${user.data.currentWorkspace}/projects/${data.id}`;
  for (let i = 0; i < data.media.length; i++) {
    const media = { ...data.media[i] };
    if (!media.file?.name) continue;
    const mediaUrl = await firebase.uploadAttachment(path, media.file);
    media.url = mediaUrl;
    media.file = media.file.name;
    data.media[i] = media;
  }
  return data;
};

const uploadLogoAttachment = async (user, data, isUpdateLogoDirty) => {
  if (data.logo == "" || !isUpdateLogoDirty) return data;

  const path = `${user.data.currentWorkspace}/projects/${data.id}`;
  if (data.logo?.file) {
    const url = await firebase.uploadAttachment(path, data.logo.file);
    data.logo = url;
  }
  return data;
};

export const getProjects = createAsyncThunk(
  "projectApp/projects/getProjects",
  async ({ user, lastItemCreatedDate, rowsPerPage, next, searchQuery }) => {
    return await firebase.getProjects(
      user,
      lastItemCreatedDate,
      rowsPerPage,
      next,
      searchQuery
    );
  }
);

export const getNavItemProjects = createAsyncThunk(
  "projectApp/projects/getNavItemProjects",
  async (user) => {
    return await firebase.getNavItemProjects(user);
  }
);

export const getProjectById = createAsyncThunk(
  "projectApp/projects/getProjectById",
  async ({ user, projectId }) => {
    return await firebase.getProjectById(user, projectId);
  }
);

export const deleteProject = createAsyncThunk(
  "projectApp/projects/deleteProject",
  async ({ projectId, workspaceId, data, user }, { dispatch }) => {
    await firebase.deleteProject(projectId, workspaceId);

    const message = " has deleted the project ";
    Utilities.successNotification(user, message, data.name, dispatch);

    if (data.showInNavbar) {
      dispatch(removeNavigationItem(projectId));
    }
    return Promise.resolve(projectId);
  }
);

export const updateProject = createAsyncThunk(
  "projectApp/projects/updateProject",
  async (
    { user, data, isUpdateMediaDirty, isUpdateLogoDirty },
    { dispatch }
  ) => {
    data = await uploadLogoAttachment(user, data, isUpdateLogoDirty);
    data = await uploadMediaAttachment(user, data, isUpdateMediaDirty);

    await firebase.updateProject(data);

    const message = " has updated the project ";
    Utilities.successNotification(user, message, data.name, dispatch);

    if (data.showInNavbar) {
      dispatch(
        updateNavigationItem(
          data.id,
          {
            id: `${data.id}`,
            title: `${data.name}`,
            type: "item",
            icon: `${data.color}`,
            url: `/:workspaceId/project/${data.id}`,
            target: "_blank",
          },
          "projects"
        )
      );
    }

    return Promise.resolve(data);
  }
);

export const addProject = createAsyncThunk(
  "projectApp/projects/addProject",
  async (
    { user, data, isUpdateMediaDirty, isUpdateLogoDirty },
    { dispatch }
  ) => {
    data.id = firebase.generateFirebaseDocId(user, "projects");
    data = await uploadLogoAttachment(user, data, isUpdateLogoDirty);
    data = await uploadMediaAttachment(user, data, isUpdateMediaDirty);
    await firebase.setProject(data);

    const message = " has created the project ";
    Utilities.successNotification(user, message, data.name, dispatch);

    if (data.showInNavbar) {
      dispatch(
        prependNavigationItem(
          {
            id: `${data.id}`,
            title: `${data.name}`,
            type: "item",
            icon: `${data.color}`,
            url: `/:workspaceId/project/${data.id}`,
            target: "_blank",
          },
          "projects"
        )
      );
    }

    // TODO: permission error when adding timeline of other users
    // data?.users?.forEach(each=>{
    //   const timeline = {
    //     id: Utilities.generateGUID(),
    //     user: each,
    //     message: "added to project",
    //     createdDate: new Date(),
    //   };
    //   dispatch(addNewTimeline({user: {data :
    //     {currentWorkspace : data.workspaceId}
    //    ,uid : each}, timeline }));
    // })
    return Promise.resolve(data);
  }
);

const projectsAdapter = createEntityAdapter({});

export const { selectAll: selectProjects, selectById: selectProjectById } =
  projectsAdapter.getSelectors((state) => state.projectApp);

const projectsSlice = createSlice({
  name: "projectApp/projects",
  initialState: projectsAdapter.getInitialState(),
  reducers: {},
  extraReducers: {
    [getProjects.fulfilled]: projectsAdapter.setAll,
    [getNavItemProjects.fulfilled]: projectsAdapter.upsertMany,
    [getProjectById.fulfilled]: projectsAdapter.upsertOne,
    [addProject.fulfilled]: projectsAdapter.addOne,
    [deleteProject.fulfilled](state, { payload: id }) {
      projectsAdapter.removeOne(state, id);
    },
    [updateProject.fulfilled]: projectsAdapter.upsertOne,
  },
});

export default projectsSlice.reducer;
