import { useDebounce } from "hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import _ from "utils/lodash";
import TextField from "@mui/material/TextField";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import LabelModel from "./LabelModel";
import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import ColorPicker from "components/pickers/ColorPicker";
import { updateTaskLabels } from "views/tasks/store/labelsSlice";
import { updateBoardLabels } from "views/scrumboard/store/labelsSlice";
import { updateNoteLabels } from "views/notes/store/labelsSlice";
import ConfirmDeleteDialog from "components/modals/ConfirmDeleteDialog";
import { Paper } from "@mui/material";
import { useStyles } from "settings/styleOverrides";
import DeleteIcon from "components/svgs/icons/general/DeleteIcon";

const defaultValues = {
  title: "",
};

const schema = yup.object().shape({
  title: yup.string().required("You must enter a label title"),
});

const LabelsForm = (props) => {
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [dialogOnclick, setDialogOnclick] = useState(() => {});
  const [confirmDialogData, setConfirmDialogData] = useState({});
  const dispatch = useDispatch();
  const classes = useStyles();
  const { options } = props;

  // Fetch all selectors as we cannot use the conditionally
  const taskLabels = useSelector(({ taskApp }) => taskApp.labels.entities);
  const notesLabels = useSelector(({ notesApp }) => notesApp?.labels?.entities);
  const scrumboardLabels = useSelector(
    ({ scrumboardApp }) => scrumboardApp.labels.entities
  );
  const user = useSelector(({ auth }) => auth.user);
  let labels = [];
  switch (options?.app) {
    case "scrumboard":
      labels = scrumboardLabels;
      break;
    case "task":
      labels = taskLabels;
      break;
    case "notes":
      labels = notesLabels;
      break;
  }

  const [labelsForm, setLabels] = useState(labels);

  const { control, formState, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    setLabels(labels);
  }, [labels]);

  const handleOnChange = useDebounce((_labels) => {
    switch (options?.app) {
      case "scrumboard":
        dispatch(updateBoardLabels({ user, _labels }));
        break;
      case "task":
        dispatch(updateTaskLabels({ user, _labels }));
        break;
      case "notes":
        dispatch(updateNoteLabels({ user, _labels }));
        break;
    }
  }, 300);

  useEffect(() => {
    if (labelsForm && !_.isEqual(labelsForm, labels)) {
      handleOnChange(labelsForm);
    }
  }, [labelsForm]);

  const onSubmit = (data) => {
    if (!isValid) return;
    const newLabel = LabelModel(data);
    setLabels(_.setIn(labelsForm, newLabel.id, newLabel));
    //reset(defaultValues);
  };

  return (
    <>
      <List dense>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ListItem className="p-0 mb-4" dense>
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className={clsx("flex flex-1")}
                  error={!!errors.title}
                  helperText={errors?.title?.message}
                  placeholder="Create new label"
                  variant="outlined"
                  autoComplete="off"
                  InputProps={{
                    className: classes.input + " text-black",
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon className="list-item-icon text-16" color="action">
                          add
                        </Icon>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          className="w-32 h-32 p-0"
                          aria-label="Delete"
                          disabled={_.isEmpty(dirtyFields) || !isValid}
                          type="submit"
                          size="large"
                        >
                          <Icon fontSize="small">check</Icon>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </ListItem>
        </form>
        <div className="label-rows mt-8">
          {useMemo(() => {
            const handleOnDelete = (label) => {
              setConfirmDialogData(label);
              setConfirmDialog(true);
              setDialogOnclick(() => deleteLabelHandler);
            };
            const deleteLabelHandler = (label) => {
              setLabels(_.omit(labelsForm, [label.id]));
            };

            const handleLabelChange = (event, label) => {
              const updatedLabel = LabelModel(
                _.setIn(label, event.target.name, event.target.value)
              );
              setLabels(_.setIn(labelsForm, updatedLabel.id, updatedLabel));
            };

            const handleColorChange = (label, color) => {
              const updatedLabel = LabelModel(_.setIn(label, "color", color));
              setLabels(_.setIn(labelsForm, updatedLabel.id, updatedLabel));
            };

            return Object.entries(labelsForm).map(([key, label]) => (
              <Paper
                square
                className="p-0 mb-4 mt-2 shadow-md shadow-blueTransparent"
                key={label.id}
              >
                <ListItem dense>
                  <Icon className="list-item-icon text-16" color="action">
                    label
                  </Icon>
                  <Input
                    className={clsx("flex flex-1 mx-8 font-[22px]")}
                    name="title"
                    value={label.title}
                    onChange={(event) => handleLabelChange(event, label)}
                    disableUnderline
                    inputProps={{
                      className: classes.input + " !p-2",
                    }}
                  />
                  <ColorPicker
                    handleColorChange={handleColorChange}
                    label={label}
                    color={label.color}
                  />
                  <IconButton
                    className="w-4 h-4 mx-4 p-0"
                    aria-label="Delete"
                    onClick={(ev) => handleOnDelete(label)}
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              </Paper>
            ));
          }, [labelsForm])}
        </div>
      </List>
      {confirmDialog && (
        <ConfirmDeleteDialog
          open={confirmDialog}
          onClose={() => setConfirmDialog(false)}
          deleteFunc={dialogOnclick}
          deleteData={confirmDialogData}
        />
      )}
    </>
  );
};

export default LabelsForm;
