import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { addNewWorkspace } from "api/addWorkspace";
import { setCurrentWorkspace } from "auth/store/userSlice";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useStyles } from "settings/styleOverrides";
import _ from "utils/lodash";
import Utilities from "utils/utilities";
import * as yup from "yup";

const schema = yup.object().shape({
  name: yup
    .string()
    .required("You must enter a workspace name.")
    .min(2, "You cannot have less than 2 characters")
    .max(25, "You cannot have more than 25 characters"),
});

const CreateWorkspaceDialog = (props) => {
  const {
    closeModal,
    addWorkspaceMenu,
    user,
    showProgress,
    setShowProgress,
    updateWorkspaces,
    closeHandler,
  } = props;

  const dispatch = useDispatch();
  const classes = useStyles();
  const defaultValues = {
    name: "",
  };

  const { control, formState, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  const onSubmit = async (model) => {
    if (!isValid) return;
    const data = {
      email: user.data.email,
      displayName: user.data.displayName,
      workspaceName: model.name,
      user: user,
    };

    setShowProgress(true);
    const response = await addNewWorkspace(data);
    if (response.statusCode !== 200) {
      closeHandler();
      reset(defaultValues);
      const body = response.statusCode === 426 ? response.body : null;
      return Utilities.errorNotification(user, dispatch, body);
    }
    updateWorkspaces({
      id: response.body.wId,
      is_default: false,
      name: model.name,
      role: "owner",
    });
    dispatch(setCurrentWorkspace(response.body.wId));
    closeHandler();
    reset(defaultValues);

    const message = " has created new workspace ";
    Utilities.successNotification(user, message, model.name, dispatch);

    window.location.href = `/${response.body.wId}/onboarding`;
    //navigate(`${response.body.wId}/dashboard`, { replace: true });
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      component="form"
      open={addWorkspaceMenu}
      onClose={closeModal}
      PaperProps={{
        style: { borderRadius: 10 },
      }}
    >
      <DialogTitle className="bg-primaryBlue text-white h-[60px]">
        <Typography
          variant="subtitle1"
          color="inherit"
          className="absolute top-[10px]"
          component="span"
        >
          Create New Workspace
        </Typography>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 40,
            top: 5,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent classes={{ root: "px-6 py-6 pb-0 sm:px-8" }}>
        <DialogContentText className="mt-2">
          <small>
            *You can add entirely new workspace to manage things better and
            avoid clutter
          </small>
        </DialogContentText>
        <div className="my-6">
          <div>
            <div className="text-lg tracking-wide pb-2">Workspace Name</div>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  autoComplete="off"
                  type="text"
                  id="board"
                  placeholder="Enter your new workspace name"
                  className=""
                  autoFocus
                  required
                  fullWidth
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  inputProps={{
                    className: classes.input + " !py-4 !pl-6",
                  }}
                />
              )}
            />
          </div>
          <div className="mt-6">
            <div className="flex justify-center items-center gap-x-6">
              <Button
                type="button"
                onClick={closeModal}
                className="bg-lightGray hover:bg-lightGray/70 rounded text-base px-1 md:px-4 text-white w-36"
              >
                <span className="text-lg">Cancel</span>
              </Button>
              <Button
                type="button"
                disabled={_.isEmpty(dirtyFields) || !isValid || showProgress}
                onClick={handleSubmit(onSubmit)}
                className={`hover:bg-primaryBlueLight rounded text-base px-1 md:px-4 text-white w-36 ${
                  _.isEmpty(dirtyFields) || !isValid
                    ? "bg-blueTransparent"
                    : "bg-primaryBlue"
                }`}
              >
                <span className="text-lg">Submit</span>
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showProgress}
      >
        <CircularProgress color="info" />
      </Backdrop>
    </Dialog>
  );
};

export default CreateWorkspaceDialog;
