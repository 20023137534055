import React from "react";

const FeatherHeart = (props) => {
  const { height = 30, width = 30 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 25.621 22.602"
    >
      <path
        id="Icon_feather-heart"
        data-name="Icon feather-heart"
        d="M24.123,6.319a6.215,6.215,0,0,0-8.792,0l-1.2,1.2-1.2-1.2A6.217,6.217,0,1,0,4.144,15.11l1.2,1.2L14.133,25.1l8.792-8.792,1.2-1.2a6.215,6.215,0,0,0,0-8.792Z"
        transform="translate(-1.323 -3.497)"
        fill="none"
        stroke="#fc3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default FeatherHeart;
