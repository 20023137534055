import { styled } from "@mui/material/styles";
import AlertPanel from "components/AlertPanel";
import ChatPanelToggleButton from "components/chatPanel/ChatPanelToggleButton";
import FooterLayout1 from "components/layout/FooterLayout";
import LeftSideLayout1 from "components/layout/LeftSideLayout";
import NavbarWrapperLayout from "components/layout/NavbarWrapperLayout";
import RightSideLayout1 from "components/layout/RightSideLayout";
import ToolbarLayout1 from "components/layout/ToolbarLayout";
import FloatingChat from "components/svgs/icons/chat/FloatingChat";
import DialogWrapper from "core/DialogWrapper";
import Message from "core/Message";
import SuspenseWrapper from "core/SuspenseWrapper";
import { memo, useContext } from "react";
import { useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";
import AppContext from "../AppContext";

const Root = styled("div")(({ theme, config }) => ({
  ...(config.mode === "boxed" && {
    clipPath: "inset(0)",
    maxWidth: `${config.containerWidth}px`,
    margin: "0 auto",
    boxShadow:
      "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
  }),
  ...(config.mode === "container" && {
    "& .container": {
      maxWidth: `${config.containerWidth}px`,
      width: "100%",
      margin: "0 auto",
    },
  }),
}));

const Layout1 = (props) => {
  const config = useSelector(
    ({ entities }) => entities.settings.current.layout.config
  );
  const { user } = useSelector((state) => state?.auth);
  const isNativeApp = useSelector(
    ({ entities }) => entities.settings.isNativeApp
  );
  const appContext = useContext(AppContext);
  const { routes } = appContext;

  return (
    <Root id="default-layout" config={config} className="w-full flex">
      {user?.uid &&
      user?.data?.settings?.chatSidebar &&
      !user?.data?.isLocked ? (
        <ChatPanelToggleButton className="cursor-pointer">
          <FloatingChat />
        </ChatPanelToggleButton>
      ) : null}
      {config.leftSidePanel.display && <LeftSideLayout1 />}
      <div className="flex flex-auto min-w-0">
        {config.navbar.display && config.navbar.position === "left" && (
          <NavbarWrapperLayout />
        )}

        <main
          id="main"
          className="flex flex-col flex-auto min-h-screen min-w-0 relative"
        >
          {!isNativeApp && <AlertPanel />}
          {config.toolbar.display && (
            <ToolbarLayout1
              className={
                config.toolbar.style === "fixed" &&
                "sticky top-0 sm:py-2 py-0 z-30"
              }
              routes={routes}
            />
          )}

          {/* <SettingsPanel /> */}

          <div className="flex flex-col flex-auto min-h-0 relative">
            <DialogWrapper />

            <SuspenseWrapper>{useRoutes(routes)}</SuspenseWrapper>

            {props.children}
          </div>

          {config.footer.display && (
            <FooterLayout1
              className={config.footer.style === "fixed" && "sticky bottom-0"}
            />
          )}
        </main>
        {/* right side navbar */}
        {/* <NavbarWrapperLayout1 /> */}
        {/* {config.navbar.display && config.navbar.position === "right" && (
          <NavbarWrapperLayout1 />
        )} */}
      </div>

      {config.rightSidePanel.display && <RightSideLayout1 />}
      <Message />
    </Root>
  );
};

export default memo(Layout1);
