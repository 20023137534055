import _ from "utils/lodash";
import Utilities from "utils/utilities";

const NotificationModel = (data) => {
  data = data || {};

  return _.defaults(data, {
    id: Utilities.generateGUID(),
    name: "",
    status: "",
    photoUrl: "",
    preHighlightedClass: "",
    preHighlightedMessage: "",
    message: "",
    messageClass: "",
    postHighlightedMessage: "",
    postHighlightedClass: "",
    created: new Date(),
    options: {
      variant: "new-default",
      showAvatar: false,
      showAvatarGroup: false,
      showNotificationIcon: false,
    },
  });
};

export default NotificationModel;
