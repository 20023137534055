import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import BellIcon from "components/svgs/icons/general/BellIcon";
import { useDispatch, useSelector } from "react-redux";
import withReducer from "store/withReducer";
import reducer from "./store";
import { selectNotifications } from "./store/dataSlice";
import { toggleNotificationPanel } from "./store/stateSlice";

const NotificationPanelToggleButton = (props) => {
  const notifications = useSelector(selectNotifications);

  const dispatch = useDispatch();

  return (
    <IconButton
      className="hidden xmd:block xs:p-2"
      onClick={(ev) => dispatch(toggleNotificationPanel())}
      size="large"
    >
      <Badge
        color="secondary"
        variant="dot"
        invisible={notifications.length === 0}
      >
        {props.children}
      </Badge>
    </IconButton>
  );
};

NotificationPanelToggleButton.defaultProps = {
  children: <BellIcon className="w-6 h-6" />,
};

export default withReducer(
  "notificationPanel",
  reducer
)(NotificationPanelToggleButton);
