import { useSelector } from "react-redux";
import Scrollbars from "core/Scrollbars";
import { useTheme, ThemeProvider } from "@mui/material/styles";
import { selectContrastMainTheme } from "store/slice/settingsSlice";
import clsx from "clsx";

const PageSimpleSidebarContent = (props) => {
  const theme = useTheme();
  const contrastTheme = useSelector(
    selectContrastMainTheme(theme.palette.primary.main)
  );

  return (
    <Scrollbars enable={props.innerScroll}>
      {props.header && (
        <ThemeProvider theme={contrastTheme}>
          <div
            className={clsx(
              "PageSimple-sidebarHeader",
              props.variant,
              props.sidebarInner && "PageSimple-sidebarHeaderInnerSidebar"
            )}
          >
            {props.header}
          </div>
        </ThemeProvider>
      )}

      {props.content && (
        <div className="PageSimple-sidebarContent">{props.content}</div>
      )}
    </Scrollbars>
  );
};

export default PageSimpleSidebarContent;
