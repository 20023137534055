import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, Hidden } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useTour } from "@reactour/tour";
import {
  logoutUser,
  setUserWorkspaces,
  updateUserLockScreen,
} from "auth/store/userSlice";
import CreateWorkspaceDialog from "components/modals/CreateWorkspaceDialog";
import { setEvents, setNotes } from "components/quickPanel/store/dataSlice";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import firebase from "services/firebase";
import {
  resetNavigation,
  updateNavigationItem,
} from "store/slice/navigationSlice";
import Utilities from "utils/utilities";
import { setChats } from "views/chat/store/chatsSlice";
import { getThisWeekTasks } from "views/dashboard/store/widgetsSlice";
import { getMembers } from "views/members/store/membersSlice";
import { getBoardLabels } from "views/scrumboard/store/labelsSlice";
import { getTaskLabels } from "views/tasks/store/labelsSlice";
import { setStatus } from "views/tracker/store/trackerSlice";
import { toggleNotificationPanel } from "./notificationPanel/store/stateSlice";

const menuStyles = {
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
};

const UserMenu = () => {
  const { setIsOpen } = useTour();
  const dispatch = useDispatch();
  const routeParams = useParams();
  const user = useSelector(({ auth }) => auth.user);
  const [displayName, setDisplayName] = useState();
  const [workspaces, setWorkspaces] = useState([]);
  const [userMenu, setUserMenu] = useState(false);
  const [addWorkspaceMenu, setAddWorkspaceMenu] = useState(false);
  const [isCollapseOpen, setIsCollapseOpen] = useState(true);
  const [showProgress, setShowProgress] = useState(false);
  const currentWorkspace = user.data.currentWorkspace;
  const { currentYear, currentWeekNo } = useSelector(
    (state) => state.trackerApp
  );
  // const thisWeekPriorityTasks = useSelector(
  //   (state) => state.dashboardApp?.widgets?.thisWeekTasks
  // );
  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };
  const unSubscribeRefs = useRef([]);
  const unSubscribeRef = useRef(() => {});

  useEffect(() => {
    if (!user.data.currentWorkspace) return;
    const STATUS = Utilities.getTrackerStatus();
    unSubscribeRef.current = firebase.getTrackerRef(user).onSnapshot((doc) => {
      const { pending } = doc?.data() || [];
      // unsubscribing previous snapshots
      unSubscribeRefs?.current?.forEach((each) => each());
      // subscribing all pending weeks
      pending?.forEach((each) => {
        const unSubscribe = firebase
          .getTrackerWeekRef(user, each)
          .onSnapshot((doc) => {
            const data = doc?.data();
            if (data.notified === false) {
              // need to show a notification
              let content;
              const approved = data.status === STATUS.APPROVED;
              const className = `ml-1 text-white ${
                approved ? "bg-green-500" : "bg-red-500"
              } rounded-full px-2 py-1`;
              const status = approved ? "Approved" : "Rejected";

              if (approved) {
                content =
                  " Congratulations. Your submitted tracker entries were ";
                Utilities.addTimelineEvent(
                  user,
                  `Congratulations. Your submitted tracker entries were ${status}`,
                  dispatch
                );

                Utilities.successNotification(
                  user,
                  content,
                  status,
                  dispatch,
                  null,
                  null,
                  className
                );
              } else {
                const preHighlightedMessage = " Your entries were ";
                const postHighlightedMessage = ` Reason: ${data.reason}. You may enter your hours again and then submit.`;
                Utilities.successNotification(
                  user,
                  status,
                  postHighlightedMessage,
                  dispatch,
                  preHighlightedMessage,
                  postHighlightedMessage,
                  null,
                  className
                );
              }
              if (currentYear == each.year && currentWeekNo == each.weekNo) {
                dispatch(setStatus(data.status));
              }
              firebase.deleteTrackerNotification(user, each.year, each.weekNo);
              firebase.removeFromPending(user, user.data.id, {
                weekNo: each.weekNo,
                year: each.year,
              });
            }
          });
        unSubscribeRefs.current.push(unSubscribe);
      });
    });

    return () => {
      unSubscribeRef?.current();
      unSubscribeRefs?.current?.forEach((each) => each());
    };
  }, [user?.data?.currentWorkspace, currentYear, currentWeekNo]);

  useEffect(() => {
    if (!user.data.currentWorkspace) return;
    const unSub1 = firebase.getNotesRef(user).onSnapshot((querySnapShot) => {
      const data = [];
      querySnapShot.forEach((doc) => data.push(doc.data()));
      dispatch(setNotes(data));
    });

    const unSub2 = firebase.getEventsRef(user).onSnapshot((querySnapShot) => {
      const data = [];
      querySnapShot.forEach((doc) => data.push(doc.data()));
      dispatch(setEvents(data));
    });
    return () => {
      unSub1();
      unSub2();
    };
  }, [user?.data?.currentWorkspace]);

  useEffect(() => {
    if (!user.data.currentWorkspace) return;
    firebase.getChatsRef(user).on("value", (snapshot) => {
      const data = Object.values(snapshot.val() || {}).map((each) => {
        // if(each.lastMessageTime) return {...each,lastMessageTime : each.lastMessageTime.toDate()}
        return each;
      });
      dispatch(setChats(data));
      dispatch(
        updateNavigationItem("chat", {
          badge: {
            title:
              data.reduce((acc, each) => acc + Number(each.unread), 0) || 0,
          },
        })
      );
    });

    return () => {
      firebase.getChatsRef(user).off();
    };
  }, [user?.data?.currentWorkspace]);

  const userMenuClose = () => {
    setUserMenu(null);
  };

  const addWorkspace = () => {
    setAddWorkspaceMenu(true);
  };

  const updateWorkspaces = (obj) => {
    let temp = [...workspaces, obj];
    setWorkspaces(temp);
  };

  const closeHandler = () => {
    setAddWorkspaceMenu(false);
    setShowProgress(false);
    userMenuClose();
  };

  useEffect(() => {
    if (user?.data?.displayName) setDisplayName(user.data.displayName);
  }, [user?.data?.displayName]);

  useEffect(() => {
    if (!user?.data?.currentWorkspace) return;
    fetchAvailableWorkspaces();
    dispatch(getMembers(user));
    dispatch(getBoardLabels(user));
    dispatch(getTaskLabels(user));
    dispatch(getThisWeekTasks(user));
    setIsOpen(true);
  }, [user?.data?.currentWorkspace]);

  // useEffect(() => {
  //   const badges = thisWeekPriorityTasks.filter(
  //     (x) => x.important && !x.completed
  //   );
  //   //updateBadges(badges.length);
  // }, [thisWeekPriorityTasks]);

  const fetchAvailableWorkspaces = async () => {
    const workspaces = user.workspaces.map((workspace) => {
      return { ...workspace };
    });
    const availableWorkspaces = await firebase.getUserWorkspacesInfo(
      workspaces
    );
    const filteredWorkspace = availableWorkspaces.filter(
      (x) => x.status === "accepted" || x.role === "owner"
    );
    setWorkspaces(filteredWorkspace);
    dispatch(setUserWorkspaces(availableWorkspaces));
  };

  const listClicked = (id) => {
    // dispatch(setCurrentWorkspace(id));
    if (currentWorkspace === id) return;
    window.location.href = `/${id}/dashboard`;
    setUserMenu(false);
  };

  // TODO: Do not need this for now
  const updateBadges = (badge) => {
    // dispatch(
    //   updateNavigationItem("task", {
    //     badge: {
    //       title: badge,
    //     },
    //   })
    // );
  };

  return (
    <>
      <Hidden mdDown>
        <Button onClick={userMenuClick} color="inherit">
          <div className="hidden md:flex flex-col items-end">
            <Typography
              component="span"
              className="font-semibold max-w-[100px] truncate font-lato text-mute"
            >
              {displayName}
            </Typography>
            <Typography
              className="text-11 font-medium capitalize font-lato"
              color="textSecondary"
            >
              {user.role.toString()}
              {(!user.role ||
                (Array.isArray(user.role) && user.role.length === 0)) &&
                "Guest"}
            </Typography>
          </div>

          <Avatar
            className="md:ml-4"
            style={{ backgroundColor: user?.data?.more?.color }}
            alt="avatar"
          >
            {displayName && displayName[0].toLocaleUpperCase()}
          </Avatar>
        </Button>
      </Hidden>
      <Hidden mdUp>
        <Avatar
          sx={{ width: 34, height: 34 }}
          onClick={userMenuClick}
          style={{ backgroundColor: user?.data?.more?.color }}
          alt="avatar"
        >
          {displayName && displayName[0].toLocaleUpperCase()}
        </Avatar>
      </Hidden>
      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: "py-4",
        }}
        sx={{ minWidth: "250px", maxWidth: "250px" }}
      >
        {!user.role || user.role.length === 0 ? (
          <>
            <MenuItem component={Link} to="/" role="button">
              <ListItemIcon>
                <Icon>lock</Icon>
              </ListItemIcon>
              <ListItemText primary="Login" />
            </MenuItem>
            <MenuItem component={Link} to="/register" role="button">
              <ListItemIcon>
                <Icon>person_add</Icon>
              </ListItemIcon>
              <ListItemText primary="Register" />
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem
              component={"div"}
              role="button"
              onClick={() => setIsCollapseOpen((state) => !state)}
            >
              <ListItemIcon>
                <Icon>work_outline</Icon>
              </ListItemIcon>
              <ListItemText primary="Workspaces" />
              {isCollapseOpen ? <ExpandLess /> : <ExpandMore />}
            </MenuItem>
            <Collapse in={isCollapseOpen} timeout="auto" unmountOnExit>
              <MenuItem onClick={addWorkspace} role="button" className="pl-8">
                <ListItemIcon>
                  <Icon>add</Icon>
                </ListItemIcon>
                <ListItemText primary="New Workspace" />
              </MenuItem>
              {workspaces.map((item) => (
                <MenuItem
                  key={item.id}
                  // component={Link}
                  className="pl-8"
                  to={`/${item.id}/dashboard`}
                  onClick={() => listClicked(item.id)}
                  role="button"
                  selected={currentWorkspace === item.id}
                >
                  <ListItemIcon>
                    <Icon>work_outline</Icon>
                  </ListItemIcon>
                  <ListItemText
                    primary={item?.name}
                    primaryTypographyProps={{ style: menuStyles }}
                  />
                </MenuItem>
              ))}
            </Collapse>
            <hr />
            <MenuItem
              component={Link}
              to={`/${currentWorkspace}/profile`}
              onClick={userMenuClose}
              role="button"
            >
              <ListItemIcon>
                <Icon>account_circle</Icon>
              </ListItemIcon>
              <ListItemText primary="My Profile" />
            </MenuItem>
            <MenuItem
              className="flex xmd:hidden"
              onClick={() => {
                dispatch(toggleNotificationPanel());
                userMenuClose();
              }}
            >
              <ListItemIcon>
                <Icon>notifications</Icon>
              </ListItemIcon>
              <ListItemText primary="Notifications" />
            </MenuItem>
            <MenuItem
              component={Link}
              to={`/lock`}
              onClick={() => {
                dispatch(updateUserLockScreen(true));
                userMenuClose();
              }}
              role="button"
            >
              <ListItemIcon>
                <Icon>lock</Icon>
              </ListItemIcon>
              <ListItemText primary="Lock Screen" />
            </MenuItem>
            <MenuItem
              onClick={() => {
                dispatch(resetNavigation());
                dispatch(logoutUser());
                userMenuClose();
              }}
            >
              <ListItemIcon>
                <Icon>exit_to_app</Icon>
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </MenuItem>
          </>
        )}
      </Popover>

      <div>
        <CreateWorkspaceDialog
          showProgress={showProgress}
          addWorkspaceMenu={addWorkspaceMenu}
          closeModal={() => setAddWorkspaceMenu(false)}
          user={user}
          setShowProgress={setShowProgress}
          updateWorkspaces={updateWorkspaces}
          closeHandler={closeHandler}
        />
      </div>
    </>
  );
};

export default UserMenu;
