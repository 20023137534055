import React from "react";

const Recent = (props) => {
  const { className = "fill-black" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 26.998 27"
      className={className}
    >
      <g
        id="Group_42339"
        data-name="Group 42339"
        transform="translate(-352 -373)"
      >
        <path
          id="Path_37772"
          data-name="Path 37772"
          d="M6.164,6.176l1.992,2H1.922V1.964l1.983,2A13.442,13.442,0,0,1,13.874,0a13.061,13.061,0,0,1,8.274,3.124A13.5,13.5,0,1,1,0,13.5H3.115a10.284,10.284,0,0,0,3.916,8.089,10.022,10.022,0,0,0,6.479,2.264A10.36,10.36,0,1,0,6.164,6.176"
          transform="translate(352 373)"
        />
        <path
          id="Path_37773"
          data-name="Path 37773"
          d="M22.688,8.953h3.148v.559q0,3.35,0,6.7c0,.254,0,.508.008.761a.331.331,0,0,0,.083.2q2.059,2.067,4.125,4.127.773.772,1.547,1.542c.019.019.034.042.051.062L29.43,25.121c-.141-.138-.285-.277-.427-.419q-.987-.986-1.973-1.973-1.036-1.037-2.069-2.075-1.095-1.1-2.188-2.191a.294.294,0,0,1-.081-.188q-.006-4.375,0-8.749V8.953"
          transform="translate(341.232 368.751)"
        />
      </g>
    </svg>
  );
};

export default Recent;
