import React from "react";

const Close = (props) => {
  const { height = 32, width = 18, color = "#fff" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 16.076 16.069"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_17957"
            data-name="Rectangle 17957"
            width="16.076"
            height="16.069"
            transform="translate(0 0)"
            fill={color}
          />
        </clipPath>
      </defs>
      <g id="Group_42359" data-name="Group 42359" clipPath="url(#clipPath)">
        <path
          id="Path_37785"
          data-name="Path 37785"
          d="M11.789,9.151h0L10.676,8.036,13.1,5.615h0L15.512,3.2A1.862,1.862,0,1,0,12.89.553Q11.418,2.02,9.951,3.493h0L8.042,5.4,6.447,3.807Q4.84,2.2,3.233.59A1.83,1.83,0,0,0,1.473.046,1.745,1.745,0,0,0,.08,1.352,1.789,1.789,0,0,0,.571,3.2q1.274,1.28,2.555,2.554l0,0L5.405,8.037,4.061,9.381h0L.581,12.86A1.863,1.863,0,1,0,3.207,15.5q1.554-1.553,3.106-3.107l.121-.121,1.6-1.6,1.843,1.843h0l2.966,2.968a1.866,1.866,0,1,0,2.643-2.631q-1.848-1.851-3.7-3.7"
          transform="translate(0 0)"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default Close;
