import React from "react";

const Billing = () => {
  const className = "fill-primaryBlue";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="76.383"
      height="71"
      viewBox="0 0 76.383 71"
      className={className}
    >
      <g
        id="Group_45982"
        data-name="Group 45982"
        transform="translate(-735.52 -308)"
      >
        <path
          id="Path_39910"
          data-name="Path 39910"
          d="M243.5,4.562c1.084-1.093,2.045-2.078,3.024-3.045a4.538,4.538,0,0,1,6.671-.111c1.038.966,2.007,2.006,3.065,3.072.706-.694,1.388-1.366,2.071-2.036.4-.388.779-.8,1.2-1.155a4.449,4.449,0,0,1,6.3.056c.934.836,1.794,1.754,2.69,2.634.16.157.329.3.495.458.976-.962,1.924-1.9,2.876-2.835A4.7,4.7,0,0,1,276.253.1a4.773,4.773,0,0,1,2.5,1.382L281.8,4.517c.9-.918,1.77-1.813,2.648-2.7a5.09,5.09,0,0,1,4.24-1.742A4.452,4.452,0,0,1,292.774,4.5c.071,3.147.019,6.3.017,9.447a1.108,1.108,0,0,1-.054.219H287.56V6.281l-.2-.111c-.485.522-.962,1.051-1.457,1.562Q284.873,8.8,283.82,9.839a2.769,2.769,0,0,1-4.12.009q-1.883-1.87-3.742-3.767a5.831,5.831,0,0,1-.493-.7c-.485.507-.83.879-1.187,1.239-1.031,1.036-2.054,2.08-3.1,3.1a2.942,2.942,0,0,1-4.263.007c-1.4-1.388-2.757-2.826-4.249-4.363-.7.746-1.289,1.394-1.9,2.021q-1.077,1.106-2.184,2.181a3.021,3.021,0,0,1-4.627-.02c-1.326-1.321-2.64-2.653-4.119-4.14a10.786,10.786,0,0,1-.7.885q-1.748,1.788-3.521,3.551a2.76,2.76,0,0,1-4.114,0c-1.068-1.056-2.124-2.126-3.186-3.189-.142-.141-.294-.275-.525-.491-.017.293-.038.484-.038.675q0,28.928,0,57.855c0,.925.182,1.1,1.129,1.1h47.543c.988,0,1.137-.152,1.138-1.147V56.884H292.8v.623q0,3.736,0,7.471a5.89,5.89,0,0,1-5.962,6.015q-24.172.014-48.344,0A5.847,5.847,0,0,1,232.522,65q-.007-30.038,0-60.077A4.54,4.54,0,0,1,235.964.18a4.778,4.778,0,0,1,4.589,1.348c.839.791,1.647,1.613,2.462,2.428a7.612,7.612,0,0,1,.484.606"
          transform="translate(503 308)"
        />
        <path
          id="Path_39911"
          data-name="Path 39911"
          d="M298.355,20.428a18.535,18.535,0,1,1-18.576,18.367,18.526,18.526,0,0,1,18.576-18.367m14.838,18.533a14.88,14.88,0,1,0-14.835,14.874,14.906,14.906,0,0,0,14.835-14.874"
          transform="translate(495.054 304.565)"
        />
        <path
          id="Path_39912"
          data-name="Path 39912"
          d="M268.581,41.018H266.8c-.033-.066-.065-.1-.067-.137-.064-1.16-.08-1.115-1.189-1.521a11.313,11.313,0,0,1-2.378-1.133,4.114,4.114,0,0,1-1.764-4.168c1.046,0,2.085-.011,3.125.017.1,0,.264.237.277.376.225,2.268,1.962,2.733,3.788,2.359a1.912,1.912,0,0,0,1.511-1.818,2.058,2.058,0,0,0-.973-2.234c-1.036-.583-2.136-1.054-3.2-1.585a18.734,18.734,0,0,1-1.907-1,4.44,4.44,0,0,1-2.1-4.32,4.554,4.554,0,0,1,2.821-4.03,8.948,8.948,0,0,1,1.578-.522c.49-.117.694-.355.618-.858a5.654,5.654,0,0,1-.006-.784h1.9c0,.349.021.71-.006,1.068-.028.383.124.532.5.613a5.444,5.444,0,0,1,2.912,1.513,5,5,0,0,1,1.321,3.8c-1.086,0-2.128.022-3.167-.027-.128-.006-.3-.375-.346-.6a2.247,2.247,0,0,0-2.547-2.12,1.889,1.889,0,0,0-1.928,1.508,2.149,2.149,0,0,0,.876,2.6c.926.529,1.9.967,2.863,1.437.572.281,1.162.526,1.723.828,2.077,1.121,2.845,2.7,2.554,5.189a4.5,4.5,0,0,1-3.562,3.876c-.02.006-.041.009-.061.014-.414.11-.97.1-1.2.365s-.125.808-.174,1.292"
          transform="translate(498.15 304.695)"
        />
        <rect
          id="Rectangle_19603"
          data-name="Rectangle 19603"
          width="22.321"
          height="4.102"
          transform="translate(746.816 361.646)"
        />
        <path
          id="Path_39913"
          data-name="Path 39913"
          d="M261.39,56.166v-4.1c.24-.011.459-.029.677-.029,4.2,0,8.394,0,12.592-.009a.754.754,0,0,1,.816.527c.557,1.21,1.187,2.387,1.805,3.608Z"
          transform="translate(498.146 299.252)"
        />
        <rect
          id="Rectangle_19604"
          data-name="Rectangle 19604"
          width="7.418"
          height="4.099"
          transform="translate(746.801 351.344)"
        />
        <path
          id="Path_39914"
          data-name="Path 39914"
          d="M295.071,44.221c1.135-.9,2.242-1.821,3.385-2.689,2.407-1.83,4.818-3.654,7.258-5.44a26.539,26.539,0,0,1,2.432-1.457,1.522,1.522,0,0,1,.575-.2c.593-.067.856.237.593.763a7.451,7.451,0,0,1-1.089,1.7q-5.659,6.2-11.378,12.341a2.5,2.5,0,0,1-4.113-.386,32.212,32.212,0,0,1-3.554-6.266,6.428,6.428,0,0,1-.369-1.237c-.152-.814.37-1.249,1.133-.905a9.213,9.213,0,0,1,1.8,1.055c1.126.861,2.2,1.789,3.328,2.714"
          transform="translate(493.54 302.211)"
        />
      </g>
    </svg>
  );
};

export default Billing;
