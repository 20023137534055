// For the search only version
import algoliasearch from "algoliasearch/lite";
import _ from "utils/lodash";

const appId = process.env.REACT_APP_VERCEL_ENV
  ? process.env.REACT_APP_VERCEL_ALGOLIA_APP_ID
  : process.env.REACT_APP_ALGOLIA_APP_ID;
const apiKey = process.env.REACT_APP_VERCEL_ENV
  ? process.env.REACT_APP_VERCEL_ALGOLIA_SEARCH_KEY
  : process.env.REACT_APP_ALGOLIA_SEARCH_KEY;

export const searchHandler = async (params) => {
  const client = algoliasearch(appId, apiKey);
  const index = client.initIndex(params.workspaceId);

  try {
    const requestOptions = {
      hitsPerPage: params.hits_per_page || 20,
      page: params.page || 0,
    };
    if (params.filters) requestOptions.filters = params.filters;
    if (params.facetFilters) requestOptions.facetFilters = params.facetFilters;
    if (!_.isUndefined(params.attributesToRetrieve))
      requestOptions.attributesToRetrieve = params.attributesToRetrieve;

    // TODO: Secure this request or do on backend
    const hits = await index.search(params.query, requestOptions);
    return {
      statusCode: 200,
      body: hits,
    };
  } catch (error) {
    return { statusCode: 500, error };
  }
};
