import { lazy } from "react";
import { authRoles } from "auth";

const Reporting = lazy(() => import("./ReportingApp"));
const ReportPageLayout = lazy(() => import("./ReportPageLayout"));

const ReportingAppConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.user,
  routes: [
    {
      path: "/:workspaceId/reports",
      element: <Reporting />,
      title: "Reporting",
    },
    {
      path: "/:workspaceId/reports/:reportId",
      element: <ReportPageLayout />,
      title: "Reporting",
    },
  ],
};

export default ReportingAppConfig;
