const Reporting = (props) => {
  const { height = 24, width = 15, className = "fill-[#6e6e6e]" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 24.385 15.624"
      className={className}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_17655"
            data-name="Rectangle 17655"
            width="24.385"
            height="15.624"
            transform="translate(0 0)"
          />
        </clipPath>
      </defs>
      <g id="Group_40694" data-name="Group 40694" transform="translate(0 0)">
        <g
          id="Group_40693"
          data-name="Group 40693"
          transform="translate(0 0)"
          clipPath="url(clipPath)"
        >
          <path
            id="Path_34421"
            data-name="Path 34421"
            d="M14.181,12.23,9.684,7.7l-7.9,7.929L0,13.727,9.772,3.979,14.04,8.316l5.651-5.669H18.427V0h5.958V5.944h-2.62V4.621L14.181,12.23"
            transform="translate(0 0)"
          />
        </g>
      </g>
    </svg>
  );
};

export default Reporting;
