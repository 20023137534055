import React from "react";

const CardImage = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="260"
      height="130"
      viewBox="0 0 291 146"
    >
      <path
        id="Rectangle_17829"
        data-name="Rectangle 17829"
        d="M15,0H276a15,15,0,0,1,15,15V146a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V15A15,15,0,0,1,15,0Z"
        fill="#e6e6e6"
      />
    </svg>
  );
};

export default CardImage;
