import Loading from "core/Loading";
import PropTypes from "prop-types";
import { Suspense } from "react";

/**
 * React Suspense defaults
 * For to Avoid Repetition
 */
const SuspenseWrapper = (props) => {
  return (
    <Suspense fallback={<Loading {...props.loadingProps} />}>
      {props.children}
    </Suspense>
  );
};

SuspenseWrapper.propTypes = {
  loadingProps: PropTypes.object,
};

SuspenseWrapper.defaultProps = {
  loadingProps: {
    delay: 0,
  },
};

export default SuspenseWrapper;
