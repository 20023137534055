import React from "react";

const Unmute = (props) => {
  const { height, width } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 26.35 24.481"
    >
      <g
        id="Group_46173"
        data-name="Group 46173"
        transform="translate(-1696.834 -189)"
      >
        <path
          id="Path_40533"
          data-name="Path 40533"
          d="M1712.6,202.731V192.266c0-.118.021-.249-.118-.318a.347.347,0,0,0-.372.054l-7.278,4.824a.351.351,0,0,0-.173.329v11.158a.349.349,0,0,0,.175.328q3.641,2.41,7.279,4.824a.346.346,0,0,0,.373.052c.137-.069.114-.2.114-.319Z"
          transform="translate(-3.258 -1.215)"
          fill="#e04f5f"
        />
        <path
          id="Path_40534"
          data-name="Path 40534"
          d="M1700.546,200.6H1697.2c-.3,0-.364.059-.364.359v10.793c0,.277.064.347.341.344h3.385c.283,0,.343-.062.343-.34V200.967C1700.9,200.661,1700.846,200.6,1700.546,200.6Z"
          transform="translate(0 -4.834)"
          fill="#e04f5f"
        />
        <path
          id="Path_40535"
          data-name="Path 40535"
          d="M1735.729,196.366a12.689,12.689,0,0,0-3.563-5.99,8.357,8.357,0,0,0-1.99-1.376l-1.531,2.4c.332.2.663.372.963.587a8.323,8.323,0,0,1,2.77,3.568,14.06,14.06,0,0,1,.461,10.163,9.356,9.356,0,0,1-2.656,4.286c-.439.389-.949.7-1.445,1.052l1.5,2.421c.483-.328.972-.617,1.414-.967a11.724,11.724,0,0,0,3.677-5.232A17.049,17.049,0,0,0,1735.729,196.366Z"
          transform="translate(-13.253)"
          fill="#e04f5f"
        />
        <path
          id="Path_40536"
          data-name="Path 40536"
          d="M1725.05,197.1q-.745,1.177-1.487,2.348a7.493,7.493,0,0,1,.111,10.221l1.474,2.381C1729.781,208.42,1729.463,200.265,1725.05,197.1Z"
          transform="translate(-11.135 -3.376)"
          fill="#e04f5f"
        />
      </g>
    </svg>
  );
};

export default Unmute;
