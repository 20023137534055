import React from "react";
import Search from "core/Search";
import { ThemeProvider } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Hidden from "@mui/material/Hidden";
import Toolbar from "@mui/material/Toolbar";
import NavbarToggleButton from "./NavbarToggleButton";
import QuickPanelToggleButton from "../../components/quickPanel/QuickPanelToggleButton";
import UserMenu from "../../components/UserMenu";
import clsx from "clsx";
import { memo } from "react";
import { useSelector } from "react-redux";
import { selectToolbarTheme } from "store/slice/settingsSlice";
// import LanguageSwitcher from '../../components/LanguageSwitcher';
import NotificationPanelToggleButton from "components/notificationPanel/NotificationPanelToggleButton";
import { Box, Stack } from "@mui/material";

const ToolbarLayout1 = (props) => {
  const config = useSelector(
    ({ entities }) => entities.settings.current.layout.config
  );
  const navbar = useSelector(({ entities }) => entities.navbar);
  const toolbarTheme = useSelector(selectToolbarTheme);

  return (
    <ThemeProvider theme={toolbarTheme}>
      <AppBar
        id="toolbar"
        className={clsx("flex shadow-[0px_3px_5px_#00B9D91F]", props.className)}
        color="default"
        sx={{ backgroundColor: toolbarTheme.palette.navbar }}
        position="absolute"
      >
        <Toolbar>
          <div className="flex flex-1">
            {config.navbar.display && config.navbar.position === "left" && (
              <>
                <Hidden lgDown>
                  <NavbarToggleButton
                    className="mx-0"
                    navbar={navbar}
                    routes={props.routes}
                  />
                </Hidden>

                <Hidden lgUp>
                  <NavbarToggleButton
                    className="mx-0"
                    navbar={navbar}
                    routes={props.routes}
                  />
                </Hidden>
              </>
            )}

            {/* <Hidden lgDown>
              <Shortcuts />
            </Hidden> */}
          </div>

          <Stack
            direction={"row"}
            className="items-center h-full overflow-x-auto"
          >
            {/* <LanguageSwitcher /> */}
            {/* <AdjustFontSize /> */}
            {/* <FullScreenToggle /> */}
            <Hidden smDown>
              <Search variant="full" />
            </Hidden>
            <QuickPanelToggleButton />
            <NotificationPanelToggleButton />
            <Hidden xsDown>
              <Box ml={2} />
            </Hidden>
            <UserMenu />
          </Stack>

          {config.navbar.display && config.navbar.position === "right" && (
            <>
              <Hidden lgDown>
                {!navbar.open && <NavbarToggleButton className="mx-0" />}
              </Hidden>

              <Hidden lgUp>
                <NavbarToggleButton className="mx-0 sm:mx-8" />
              </Hidden>
            </>
          )}
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};

export default memo(ToolbarLayout1);
