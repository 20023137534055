const skyBlue = {
  50: "#00B9D9",
  100: "#00B9D9",
  200: "#00B9D9",
  300: "#00B9D9",
  400: "#00B9D9",
  500: "#00B9D9",
  600: "#00B9D9",
  700: "#00B9D9",
  800: "#00B9D9",
  900: "#00B9D9",
  A100: "#00B9D9",
  A200: "#00B9D9",
  A400: "#00B9D9",
  A700: "#00B9D9",
  contrastDefaultColor: "dark",
};

export default skyBlue;
