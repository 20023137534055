import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import Close from "./svgs/icons/general/Close";

const WidgetWrapper = (props) => {
  const { removeWidget, children, widget, className } = props;
  const { formOpen } = useSelector((state) => state.dashboardApp?.dashboard);
  return (
    <Paper
      component={"span"}
      data-grid={widget}
      className={`relative shadow-[0px_3px_5px_#00B9D91F] h-[inherit] w-[inherit] overflow-auto self-center rounded-20 select-none ${className}`}
    >
      <>
        {formOpen && (
          <IconButton
            onTouchStart={() => removeWidget?.(widget?.i)}
            onClick={() => removeWidget?.(widget?.i)}
            className="fixed z-[100] top-[15px] right-[5px] text-danger text-sm cursor-pointer bg-[#0000000a]"
            disableFocusRipple
            disableRipple
          >
            <Close width={10} height={10} color="#FF0000" />
          </IconButton>
        )}

        {children}
      </>
    </Paper>
  );
};

export default WidgetWrapper;
