import withRouter from "core/withRouter";
import { memo, useContext, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { matchRoutes, useNavigate } from "react-router-dom";
import Utilities from "utils/utilities";
import { getNavItemProjects } from "views/projects/store/projectsSlice";
import AppContext from "../AppContext";
import { logoutUser } from "auth/store/userSlice";

const Authorization = (props) => {
  const { location, userRole, user } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const notesData = useSelector(({ quickPanel }) => quickPanel.data);

  const [accessGranted, setAccessGranted] = useState(true);
  const [queryString, setQueryString] = useState(window.location.search);
  const [forceLogout, setForceLogout] = useState(false);

  const { routes } = appContext;
  const params = new URLSearchParams(queryString);
  const id = params.get("id");
  const code = params.get("code");
  const year = params.get("year");

  useEffect(() => {
    if (
      !user?.data?.isLocked &&
      (!accessGranted || props?.userRole?.length > 0)
    )
      redirectRoute();
  }, [accessGranted]);

  useEffect(() => {
    const { pathname } = location;

    const matchedRoutes = matchRoutes(routes, pathname);
    const matched = matchedRoutes ? matchedRoutes[0] : false;
    if (matched) {
      const hasPermission = Utilities.hasPermission(
        matched.route.auth,
        userRole
      );
      setAccessGranted(hasPermission);
      document.title = matched?.route?.title
        ? matched.route.title + " - Proman365"
        : "Proman365";
    } else {
      setAccessGranted(true);
    }
  }, [location, userRole]);

  useEffect(() => {
    if (user?.data?.isLocked) {
      navigate({
        pathname: `/lock${queryString}`,
      });
    }
  }, [user?.data?.isLocked]);

  // useEffect(() => {
  //   let timeoutFunc;
  //   // TODO: This implementation is not right. The query we have only fetches last 2 notes.
  //   if (notesData.notes) {
  //     notesData.notes.forEach((note) => {
  //       if (note.reminder) {
  //         const remainTime =
  //           new Date(note.reminder.seconds * 1000).getTime() -
  //           new Date().getTime();
  //         if (remainTime > 0) {
  //           timeoutFunc = setTimeout(() => {
  //             dispatch(
  //               addNotification(
  //                 NotificationModel({
  //                   message: `Remainder for ${note.title}`,
  //                   options: { variant: "warning" },
  //                 })
  //               )
  //             );
  //           }, remainTime);
  //         }
  //       }
  //     });
  //   }
  //   return () => clearTimeout(timeoutFunc);
  // }, [notesData.notes]);

  const redirectRoute = () => {
    const workspace_id = sessionStorage.getItem("workspace");

    if (
      workspace_id &&
      id &&
      code &&
      workspace_id !== user?.data?.currentWorkspace &&
      userRole.length > 0
    ) {
      setForceLogout(true);
      dispatch(logoutUser());
      return;
    }
    if (user?.logout && !forceLogout) setQueryString("");
    if (!workspace_id && (!userRole || userRole.length === 0)) {
      // User is guest. Redirect to Login Page
      setTimeout(() => {
        goToLogin();
      }, 1000);
    } else {
      /*
        User is member
        User must be on unAuthorized page or just logged in
        Redirect to dashboard or loginRedirectUrl
        */
      const currentWorkspaceId = user?.data?.currentWorkspace || workspace_id;
      const { noAccess } = workspaceToRedirectTo(user, currentWorkspaceId);
      const isNewUser = user?.data?.newUser;
      if (noAccess) {
        goToCreateWorkspace();
      } else if (!user?.data?.emailVerified) {
        navigate({ pathname: `/verification${queryString}` });
      } else if (!currentWorkspaceId) {
        goToLogin();
      } else {
        if (user?.data?.currentWorkspace) dispatch(getNavItemProjects(user));
        if (year) {
          goToTracker(currentWorkspaceId);
        } else if (location.pathname == "/") {
          goToDashboard(currentWorkspaceId, isNewUser);
        } else if (location.pathname !== "/login") {
          // TODO: I believe this is not required
          navigate({
            pathname: location.pathname.replace(
              ":workspaceId",
              currentWorkspaceId
            ),
          });
        } else {
          goToDashboard(currentWorkspaceId, isNewUser);
        }
      }
    }
  };

  const workspaceToRedirectTo = (user, currentWorkspaceId) => {
    if (!currentWorkspaceId) return { noAccess: false };
    const workspace = Object.freeze(
      user.workspaces.find((x) => x.id === currentWorkspaceId)
    );
    if (workspace && workspace.status === "deactivated") {
      // Check if user has alternate workspace to go to
      if (user.workspaces.length > 1) {
        const alternateWorkspaces = user.workspaces.filter(
          (x) => x.status !== "deactivated"
        );
        if (alternateWorkspaces.length > 0) {
          // TODO: Check if this scenario can exist or not
        }
      } else {
        // Send user to create workspace page
        return { noAccess: true };
      }
    }
    return { noAccess: false };
  };

  const goToLogin = () => {
    navigate({
      pathname: `/${queryString}`,
    });
  };

  const goToCreateWorkspace = () => {
    navigate({
      pathname: `/createWorkspace${queryString}`,
    });
  };

  const goToDashboard = (currentWorkspaceId, isNewUser) => {
    // Clear all query string if u have reached dashboard. Double check it does not break anything.
    setQueryString("");
    navigate({
      pathname: isNewUser
        ? `/${currentWorkspaceId}/onboarding`
        : `/${currentWorkspaceId}/dashboard`,
    });
  };

  const goToTracker = (currentWorkspaceId) => {
    navigate({
      pathname: `/${currentWorkspaceId}/tracker${queryString}`,
    });
  };

  return accessGranted ? <>{props.children}</> : null;
};

const mapStateToProps = ({ auth }) => {
  return {
    userRole: auth.user.role,
    user: auth.user,
  };
};

export default withRouter(memo(connect(mapStateToProps)(Authorization)));
