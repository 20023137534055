import React from "react";

const Reload = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.03"
      height="15.589"
      viewBox="0 0 12.03 15.589"
      className="mr-2"
    >
      <g
        id="Group_42365"
        data-name="Group 42365"
        transform="translate(-1655.513 -1142.924)"
      >
        <path
          id="Path_37788"
          data-name="Path 37788"
          d="M6.355,24.054l-1.235,1.2.679.782h.017l2.869-2.86L5.8,20.3l-.69.674,2,1.981a4.711,4.711,0,0,1-5.182-1.744,4.727,4.727,0,0,1,.445-6.289l-.653-.653a5.605,5.605,0,0,0-1.172,6.5,5.752,5.752,0,0,0,5.8,3.289"
          transform="translate(1655.722 1132.4)"
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.15"
        />
        <path
          id="Path_37789"
          data-name="Path 37789"
          d="M19.5,5.269A5.732,5.732,0,0,0,13.714,1.95l1.207-1.2L14.265,0h-.056V0L11.335,2.864l2.865,2.9.719-.708L12.91,3.063a4.7,4.7,0,0,1,5.181,1.72,4.732,4.732,0,0,1-.437,6.311l.648.656A5.612,5.612,0,0,0,19.5,5.269"
          transform="translate(1647.3 1142.999)"
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.15"
        />
      </g>
    </svg>
  );
};

export default Reload;
