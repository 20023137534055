import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";

export const getFolders = createAsyncThunk(
  "taskApp/folders/getFolders",
  async () => {
    // TODO: If we want to create more folders
    return [
      {
        id: "0",
        handle: "all",
        title: "All",
        icon: "view_headline",
      },
    ];
  }
);

const foldersAdapter = createEntityAdapter({});

export const { selectAll: selectFolders, selectById: selectFolderById } =
  foldersAdapter.getSelectors((state) => state.taskApp.folders);

const foldersSlice = createSlice({
  name: "taskApp/folders",
  initialState: foldersAdapter.getInitialState({}),
  reducers: {},
  extraReducers: {
    [getFolders.fulfilled]: foldersAdapter.setAll,
  },
});

export default foldersSlice.reducer;
