import Scrollbars from "core/Scrollbars";
import { styled } from "@mui/material/styles";
import Navigation from "./NavigationWrapper";
import clsx from "clsx";
import { memo, useState } from "react";
import { Box } from "@mui/system";
import LogoSvg from "components/svgs/logos/LogoSvg";
import { Avatar, Skeleton } from "@mui/material";

const Root = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.navbar,
  color: theme.palette.text.primary,
  "& ::-webkit-scrollbar-thumb": {
    boxShadow: `inset 0 0 0 20px ${
      theme.palette.mode === "light"
        ? "rgba(0, 0, 0, 0.24)"
        : "rgba(255, 255, 255, 0.24)"
    }`,
  },
  "& ::-webkit-scrollbar-thumb:active": {
    boxShadow: `inset 0 0 0 20px ${
      theme.palette.mode === "light"
        ? "rgba(0, 0, 0, 0.37)"
        : "rgba(255, 255, 255, 0.37)"
    }`,
  },
}));

const StyledContent = styled(Scrollbars)(({ theme }) => ({
  overscrollBehavior: "contain",
  overflowX: "hidden",
  overflowY: "auto",
  WebkitOverflowScrolling: "touch",
  background:
    "linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 30%), linear-gradient(rgba(0, 0, 0, 0.25) 0, rgba(0, 0, 0, 0) 40%)",
  backgroundRepeat: "no-repeat",
  backgroundSize: "100% 40px, 100% 10px",
  backgroundAttachment: "local, scroll",
}));

const NavbarStyle1Content = (props) => {
  const { navbar } = props;
  console.log(navbar);
  const [loading, setImgLoading] = useState(navbar?.navbarLogo !== "");

  return (
    <Root
      className={clsx(
        "flex flex-auto flex-col overflow-hidden h-full",
        props.className
      )}
    >
      <Box className="flex justify-center py-4">
        {loading ? (
          <Skeleton variant="circular">
            <Avatar
              className="rounded-full w-[100px] h-[100px]"
              src={navbar?.navbarLogo}
              imgProps={{ onLoad: () => setImgLoading(false) }}
              alt="logo"
            ></Avatar>
          </Skeleton>
        ) : navbar?.navbarLogo ? (
          <img
            className="rounded-full"
            src={navbar?.navbarLogo}
            height={100}
            width={100}
            alt="logo"
          />
        ) : (
          <LogoSvg height={100} width={100} />
        )}
      </Box>

      <StyledContent
        option={{ suppressScrollX: true, wheelPropagation: false }}
      >
        <Navigation layout="vertical" />
      </StyledContent>
    </Root>
  );
};

export default memo(NavbarStyle1Content);
