import { yupResolver } from "@hookform/resolvers/yup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  Icon,
  IconButton,
  InputAdornment,
  TextField,
  useMediaQuery,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  reauthenticateWithPopup,
} from "firebase/auth";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import firebase from "services/firebase";
import _ from "utils/lodash";
import Utilities from "utils/utilities";
import * as yup from "yup";
import TabHeader from "../components/TabHeader";

const PASSWORD = "password";
const schema = yup.object().shape({
  currentPassword: yup
    .string()
    .required("You must enter your current password."),
  newPassword: yup
    .string()
    .required("You must enter a new password.")
    .min(8, "Password is too short - should be 8 chars minimum."),
  confirmPassword: yup
    .string()
    .required("You must enter confirm password.")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});

const SecurityTab = (props) => {
  const { classes, goBack } = props;
  const dispatch = useDispatch();

  const [hasPasswordLogin, setHasPasswordLogin] = useState(true);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [tabNumber, setTabNumber] = useState(0);

  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const userState = useSelector(({ auth }) => auth.user);

  const defaultValues = {
    currentPassword: hasPasswordLogin ? "" : PASSWORD,
    newPassword: "",
    confirmPassword: "",
  };

  const { control, formState, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    const auth = getAuth();
    const providerData = auth.currentUser.providerData;
    const doesUserHavePasswordLogin =
      providerData.findIndex((x) => x.providerId === PASSWORD) !== -1;
    setHasPasswordLogin(doesUserHavePasswordLogin);
  }, []);

  const onSubmit = (model) => {
    if (!isValid) return;
    const auth = getAuth();
    const { currentUser } = auth;
    const { email } = currentUser;

    if (!hasPasswordLogin) {
      const provider = currentUser.providerData[0].providerId;
      const popupConfigurationProvider = firebase.getOAuthProvider(provider);
      reauthenticateWithPopup(currentUser, popupConfigurationProvider)
        .then(() => updatePassword(model.newPassword))
        .catch((error) =>
          Utilities.errorNotification(userState, dispatch, error)
        );
      return;
    }
    const credential = EmailAuthProvider.credential(
      email,
      model.currentPassword
    );
    reauthenticateWithCredential(currentUser, credential)
      .then(() => updatePassword(model.newPassword))
      .catch((error) =>
        Utilities.errorNotification(userState, dispatch, error)
      );
  };

  const updatePassword = (newPassword) => {
    const user = firebase.getAuth().currentUser;
    user
      .updatePassword(newPassword)
      .then(() => {
        const TOKEN = hasPasswordLogin ? "updated" : "created";
        reset(defaultValues);
        Utilities.addTimelineEvent(
          userState,
          `You have successfully ${TOKEN} your password.`,
          dispatch,
          "settings"
        );

        const message = ` has ${TOKEN} the password. `;
        Utilities.successNotification(userState, message, null, dispatch);
      })
      .catch((error) => {
        Utilities.errorNotification(userState, dispatch, error);
      });
  };

  return (
    <motion.div
      className="w-full m-6"
      variants={Utilities.containerConfig(0.05)}
      initial="hidden"
      animate="show"
    >
      <TabHeader
        onClick={goBack}
        title={"Security"}
        subtitle="Update your security related settings"
      />
      <div
        className={`flex ${
          mdDown ? "flex-col" : "flex-row"
        } w-full content-container`}
      >
        <div
          className={`left-side ${
            mdDown
              ? "h-fit w-full mt-4 pb-6 border-b-2"
              : "h-screen border-r-2 w-[30%]"
          }  `}
        >
          <div
            onClick={() => setTabNumber(0)}
            className={`cursor-pointer flex items-center px-3 py-6  ${
              tabNumber === 0 ? "bg-white shadow-md shadow-blueTransparent" : ""
            }`}
          >
            {tabNumber === 0 ? (
              <ArrowDropUpIcon className="text-primaryBlue" />
            ) : (
              <ArrowDropDownIcon className="text-primaryBlue" />
            )}

            <Typography className="ml-2 text-base font-semibold text-grayText">
              Change Password
            </Typography>
          </div>
        </div>
        <div
          className={`right-side  ${
            mdDown ? "w-full h-fit" : "w-[70%] h-screen"
          }`}
        >
          <form
            name="settingsForm"
            noValidate
            className={`flex flex-col flex-1 ${
              mdDown ? "w-full" : "ml-6"
            }  my-6`}
            onSubmit={handleSubmit(onSubmit)}
          >
            {tabNumber === 0 && (
              <Card
                component={motion.div}
                variants={Utilities.itemOpacityConfig()}
                className="w-full rounded-16 shadow"
              >
                <AppBar
                  position="static"
                  elevation={0}
                  className="bg-primaryBlue"
                >
                  <Toolbar className="px-6">
                    <Typography
                      variant="subtitle1"
                      color="inherit"
                      className="flex-1 text-lg font-medium text-white"
                    >
                      Change Password
                    </Typography>
                  </Toolbar>
                </AppBar>

                <CardContent>
                  {hasPasswordLogin && (
                    <div className="mb-8">
                      <Typography className="font-semibold mb-4 text-15">
                        Current Password
                      </Typography>

                      <Controller
                        name="currentPassword"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            autoComplete="off"
                            id="outlined-basic"
                            size="small"
                            variant="outlined"
                            name="currentPassword"
                            type="password"
                            fullWidth
                            required
                            disabled={!hasPasswordLogin}
                            error={!!errors.currentPassword}
                            helperText={errors?.currentPassword?.message}
                            InputLabelProps={{
                              shrink: true,
                              className: classes.label,
                            }}
                            InputProps={{
                              className: classes.input,
                              type: showCurrentPassword ? "text" : "password",
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      setShowCurrentPassword(
                                        !showCurrentPassword
                                      )
                                    }
                                    size="large"
                                  >
                                    <Icon className="text-20" color="action">
                                      {showCurrentPassword
                                        ? "visibility"
                                        : "visibility_off"}
                                    </Icon>
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </div>
                  )}
                  <div className="mb-8">
                    <Typography className="font-semibold mb-4 text-15">
                      New Password
                    </Typography>

                    <Controller
                      name="newPassword"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          autoComplete="off"
                          id="outlined-basic"
                          size="small"
                          variant="outlined"
                          name="newPassword"
                          fullWidth
                          required
                          error={!!errors.newPassword}
                          helperText={errors?.newPassword?.message}
                          InputLabelProps={{
                            shrink: true,
                            className: classes.label,
                          }}
                          InputProps={{
                            className: classes.input,
                            type: showNewPassword ? "text" : "password",
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() =>
                                    setShowNewPassword(!showNewPassword)
                                  }
                                  size="large"
                                >
                                  <Icon className="text-20" color="action">
                                    {showNewPassword
                                      ? "visibility"
                                      : "visibility_off"}
                                  </Icon>
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </div>

                  <div className="mb-8">
                    <Typography className="font-semibold mb-4 text-15">
                      Confirm Password
                    </Typography>
                    <Controller
                      name="confirmPassword"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          autoComplete="off"
                          id="outlined-basic"
                          size="small"
                          variant="outlined"
                          name="confirmPassword"
                          fullWidth
                          required
                          error={!!errors.confirmPassword}
                          helperText={errors?.confirmPassword?.message}
                          InputLabelProps={{
                            shrink: true,
                            className: classes.label,
                          }}
                          InputProps={{
                            className: classes.input,
                            type: showConfirmPassword ? "text" : "password",
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() =>
                                    setShowConfirmPassword(!showConfirmPassword)
                                  }
                                  size="large"
                                >
                                  <Icon className="text-20" color="action">
                                    {showConfirmPassword
                                      ? "visibility"
                                      : "visibility_off"}
                                  </Icon>
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </div>
                </CardContent>
              </Card>
            )}
            <div className="flex w-full justify-between my-[24px]">
              <div className="w-full"></div>
              <div className="flex">
                <Button
                  onClick={() => reset(defaultValues)}
                  className="bg-lightGray rounded hover:bg-lightGray/70 text-white xs:text-sm xmd:text-base xs:min-w-[80px] xmd:min-w-[110px] max-h-[40px] mr-4"
                >
                  Cancel
                </Button>
                <Button
                  className={`${
                    _.isEmpty(dirtyFields) || !isValid
                      ? "bg-blueTransparent"
                      : "bg-primaryBlue"
                  } max-h-[40px] xs:text-sm xmd:text-base text-white rounded hover:bg-primaryBlueLight xs:min-w-[80px] xmd:min-w-[110px]`}
                  type="submit"
                  disabled={_.isEmpty(dirtyFields) || !isValid}
                >
                  Save
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </motion.div>
  );
};

export default SecurityTab;
