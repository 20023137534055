import Icon from "@mui/material/Icon";

const NotificationIcon = ({ value }) => {
  switch (value) {
    case "error": {
      return (
        <Icon
          className="opacity-75 text-[#dc2626]"
          fontSize="medium"
          color="inherit"
        >
          error
        </Icon>
      );
    }
    case "success": {
      return (
        <Icon
          className="opacity-75 text-[#84cc16]"
          fontSize="medium"
          color="inherit"
        >
          thumbup
        </Icon>
      );
    }
    case "warning": {
      return (
        <Icon
          className="opacity-75 text-[#f59e0b]"
          fontSize="medium"
          color="inherit"
        >
          warning
        </Icon>
      );
    }
    case "info": {
      return (
        <Icon className="opacity-75" fontSize="medium" color="inherit">
          info
        </Icon>
      );
    }
    default: {
      return null;
    }
  }
};

export default NotificationIcon;
