import React from "react";

const Mute = (props) => {
  const { height, width } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 24.592 21.633"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_17960"
            data-name="Rectangle 17960"
            width="24.592"
            height="21.633"
            fill="#e04f5f"
          />
        </clipPath>
      </defs>
      <g id="Group_42380" data-name="Group 42380" transform="translate(0 0)">
        <g
          id="Group_42379"
          data-name="Group 42379"
          transform="translate(0 0)"
          clipPath="url(#clipPath)"
        >
          <path
            id="Path_37790"
            data-name="Path 37790"
            d="M18.918,10.815q0,5.232,0,10.464c0,.12.023.25-.114.319a.347.347,0,0,1-.373-.052q-3.637-2.414-7.277-4.823a.348.348,0,0,1-.175-.328q0-5.578,0-11.155a.35.35,0,0,1,.173-.329Q14.791,2.5,18.428.088A.348.348,0,0,1,18.8.034c.139.069.118.2.118.318V10.815"
            transform="translate(-6.417 0)"
            fill="#e04f5f"
          />
          <path
            id="Path_37791"
            data-name="Path 37791"
            d="M40.945,17.364c.181-.191.327-.352.481-.507q1.079-1.083,2.161-2.162c.229-.23.3-.229.536.006l1.467,1.467c.19.19.191.283,0,.47l-2.716,2.714a.7.7,0,0,1,.186.095q1.258,1.251,2.511,2.507c.214.213.213.293,0,.509L44.1,23.93c-.207.207-.294.209-.5.006q-1.242-1.241-2.483-2.484a2.343,2.343,0,0,1-.149-.186c-.076.072-.128.119-.177.168l-2.509,2.509c-.19.19-.285.192-.472.005q-.754-.752-1.505-1.505c-.191-.191-.191-.28,0-.47q1.228-1.23,2.458-2.457c.064-.064.135-.123.214-.194-.053-.057-.091-.1-.132-.142l-2.521-2.522c-.213-.213-.212-.294,0-.51l1.441-1.44c.245-.244.31-.245.55-.005q1.241,1.241,2.482,2.484c.042.043.076.095.141.179"
            transform="translate(-21.141 -8.489)"
            fill="#e04f5f"
          />
          <path
            id="Path_37792"
            data-name="Path 37792"
            d="M0,17.948V12.563c0-.3.06-.358.363-.359H3.711c.3,0,.357.057.357.363v10.79c0,.278-.06.34-.343.34H.341C.064,23.7,0,23.63,0,23.353v-5.4"
            transform="translate(0 -7.134)"
            fill="#e04f5f"
          />
        </g>
      </g>
    </svg>
  );
};

export default Mute;
