import { yupResolver } from "@hookform/resolvers/yup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import ConfirmDeleteDialog from "components/modals/ConfirmDeleteDialog";
import LogoSvg from "components/svgs/logos/LogoSvg";
import UploadImage from "components/upload/UploadImage";
import { motion } from "framer-motion";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import _ from "utils/lodash";
import Utilities from "utils/utilities";
import * as yup from "yup";
import AccountDeletion from "../components/AccountDeletion";
import CustomFields from "../components/CustomFields";
import TabHeader from "../components/TabHeader";
import UserSettings from "../components/UserSettings";
import {
  leaveWorkspaceThunk,
  selectWorkspaceById,
  updateGeneralSettings,
} from "../store/settingsSlice";
import { navbarSetLogo } from "store/slice/navbarSlice";

const schema = yup.object().shape({
  workspaceName: yup
    .string()
    .required("You must enter a workspace name.")
    .max(25, "You cannot have more than 25 characters"),
});

const GeneralTab = (props) => {
  const { user, goBack, classes } = props;
  const dispatch = useDispatch();
  const theme = useTheme();
  const workspaceInfo = useSelector((state) =>
    selectWorkspaceById(state, user.data.currentWorkspace)
  );
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [tabNumber, setTabNumber] = useState(0);
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [settingsDrawerOpen, setSettingsDrawerOpen] = useState(false);
  const [logo, setWorkspaceLogo] = useState({ url: "", file: "" });

  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const disabled = !(user && user.role.includes("owner"));

  const currentDefaultWorkspace = user.workspaces.find((x) => x.is_default);
  const defaultValues = {
    workspaceName: workspaceInfo?.data?.name,
    defaultWorkspace: currentDefaultWorkspace?.id,
    logo: workspaceInfo?.data?.logo,
  };

  const { control, formState, setValue, handleSubmit, reset, watch } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;
  const generalForm = watch();

  const onSubmit = (model) => {
    if (!isValid) return;

    dispatch(
      updateGeneralSettings({
        user: user,
        isWorkspaceNameUpdated:
          workspaceInfo?.data?.name !== model.workspaceName,
        newWorkspaceName: model.workspaceName,
        newDefaultWorkspace: model.defaultWorkspace,
        logo: logo.file ? logo : generalForm.logo,
        isUpdateLogoDirty: dirtyFields.logo,
      })
    ).then(() => {
      reset({
        workspaceName: model.workspaceName,
        defaultWorkspace: model.defaultWorkspace,
      });
    });
  };

  const leaveWorkspaceHandler = () => {
    setLoadingSpinner(true);
    dispatch(leaveWorkspaceThunk(user)).catch(() => setLoadingSpinner(false));
  };

  const toggleSettingsDrawer = (state) => {
    setSettingsDrawerOpen(state === undefined ? !settingsDrawerOpen : state);
  };

  const onUploadAttachment = (file, url) => {
    setValue("logo", url, { shouldDirty: true });
    setWorkspaceLogo({ ...logo, url: url, file: file });
    dispatch(navbarSetLogo(url));
  };

  const handleCancel = () => {
    reset(defaultValues);
    dispatch(navbarSetLogo(workspaceInfo?.data?.logo));
  };

  const item = Utilities.itemOpacityConfig();

  return (
    <motion.div
      className="w-full m-6"
      variants={Utilities.containerConfig(0.05)}
      initial="hidden"
      animate="show"
    >
      <TabHeader
        onClick={goBack}
        title={"General"}
        subtitle="Update your workspace related settings"
        tabNumber={tabNumber}
        toggleSettingsDrawer={toggleSettingsDrawer}
      />
      <div
        className={`flex ${
          mdDown ? "flex-col" : "flex-row"
        } w-full content-container`}
      >
        {loadingSpinner ? (
          <CircularProgress color="info" className="m-auto" />
        ) : (
          <>
            <div
              className={`left-side ${
                mdDown
                  ? "h-fit w-full mt-4 border-b-2"
                  : "h-screen pb-6 border-r-2 w-[30%]"
              }  `}
            >
              {[
                { key: 0, value: "Workspace Settings" },
                { key: 1, value: "User Settings" },
                { key: 2, value: "Custom Fields" },
                { key: 3, value: "Account Deletion" },
              ].map((x) => (
                <div
                  onClick={() => setTabNumber(x.key)}
                  key={x.key}
                  className={`cursor-pointer flex items-center px-3 py-6  ${
                    tabNumber === x.key
                      ? "bg-white shadow-md shadow-blueTransparent"
                      : ""
                  }`}
                >
                  {tabNumber === x.key ? (
                    <ArrowDropUpIcon className="text-primaryBlue" />
                  ) : (
                    <ArrowDropDownIcon className="text-primaryBlue" />
                  )}

                  <Typography className="ml-2 text-base font-semibold text-grayText">
                    {x.value}
                  </Typography>
                </div>
              ))}
            </div>
            <div className={`right-side  ${mdDown ? "w-full" : "w-[70%]"}`}>
              {tabNumber === 0 && (
                <form
                  name="generalForm"
                  noValidate
                  className={`flex flex-col flex-1 ${
                    mdDown ? "w-full" : "ml-6"
                  }  my-6`}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  {tabNumber === 0 && (
                    <Card
                      component={motion.div}
                      variants={item}
                      className="rounded-16 shadow"
                    >
                      <AppBar
                        position="static"
                        elevation={0}
                        className="bg-primaryBlue"
                      >
                        <Toolbar className="px-6">
                          <Typography
                            variant="subtitle1"
                            color="inherit"
                            className="flex-1 text-lg font-medium text-white"
                          >
                            Workspace Settings
                          </Typography>
                        </Toolbar>
                      </AppBar>

                      <CardContent>
                        <div className="">
                          <div className="mb-8">
                            <Typography className="font-semibold mb-4 text-15">
                              Workspace Name
                            </Typography>

                            <Controller
                              name="workspaceName"
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  autoComplete="off"
                                  id="outlined-basic"
                                  size="small"
                                  variant="outlined"
                                  name="workspaceName"
                                  fullWidth
                                  required
                                  error={!!errors.workspaceName}
                                  helperText={errors?.workspaceName?.message}
                                  disabled={disabled}
                                  InputProps={{
                                    className: classes.input,
                                  }}
                                  InputLabelProps={{
                                    shrink: true,
                                    className: classes.label,
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div className="mb-8">
                            <Typography className="font-semibold mb-4 text-15">
                              Default workspace
                            </Typography>
                            <Controller
                              name="defaultWorkspace"
                              control={control}
                              defaultValue={user.data.currentWorkspace}
                              render={({ field: { onChange, value } }) => (
                                <Select
                                  value={value}
                                  sx={{ width: 200 }}
                                  autoComplete="off"
                                  id="outlined-basic"
                                  size="small"
                                  variant="outlined"
                                  name="defaultWorkspace"
                                  onChange={onChange}
                                  className="w-full"
                                >
                                  {user?.workspaces
                                    ?.filter(
                                      (x) =>
                                        x.status === "accepted" ||
                                        x.role === "owner"
                                    )
                                    .map((workspace) => (
                                      <MenuItem
                                        key={workspace.id}
                                        value={workspace.id}
                                      >
                                        {workspace.name}
                                      </MenuItem>
                                    ))}
                                </Select>
                              )}
                            />
                          </div>
                          {user.role.includes("owner") &&
                            (currentDefaultWorkspace.plan != "Starter" ||
                              currentDefaultWorkspace.plan != "Pro") && (
                              <div className="flex flex-col">
                                <Typography className="font-semibold text-15 mb-4">
                                  Custom Logo
                                </Typography>
                                <UploadImage
                                  imgSrc={
                                    generalForm.logo ? (
                                      generalForm.logo
                                    ) : (
                                      <LogoSvg />
                                    )
                                  }
                                  title="Upload Logo"
                                  size="large"
                                  className={`w-20 h-20 ${
                                    generalForm.logo
                                      ? "object-cover"
                                      : "flex justify-center items-center"
                                  } rounded-lg border-[2.5px] border-primaryBlue ${
                                    disabled ? "cursor-auto" : "cursor-pointer"
                                  }`}
                                  onChange={onUploadAttachment}
                                  alt="project logo"
                                  disabled={disabled}
                                />
                              </div>
                            )}
                        </div>
                      </CardContent>
                    </Card>
                  )}
                  <div className="flex w-full justify-between my-[24px]">
                    <div className="w-full">
                      {user.role.includes("member") && (
                        <Button
                          onClick={() => setConfirmDialog(true)}
                          className="bg-red-500 hover:bg-red-500/70 rounded text-white xs:text-sm xmd:text-base xs:min-w-[80px] xmd:min-w-[110px] max-h-[40px]"
                        >
                          Leave Workspace
                        </Button>
                      )}
                    </div>

                    <div className="flex">
                      <Button
                        onClick={() => handleCancel()}
                        className="bg-lightGray rounded hover:bg-lightGray/70 text-white xs:text-sm xmd:text-base xs:min-w-[80px] xmd:min-w-[110px] max-h-[40px] mr-4"
                      >
                        Cancel
                      </Button>
                      <Button
                        className={`${
                          _.isEmpty(dirtyFields) || !isValid
                            ? "bg-blueTransparent"
                            : "bg-primaryBlue"
                        } max-h-[40px] xs:text-sm xmd:text-base text-white rounded hover:bg-primaryBlueLight xs:min-w-[80px] xmd:min-w-[110px]`}
                        type="submit"
                        disabled={_.isEmpty(dirtyFields) || !isValid}
                      >
                        Save
                      </Button>
                    </div>

                    {confirmDialog && (
                      <ConfirmDeleteDialog
                        open={confirmDialog}
                        onClose={() => setConfirmDialog(false)}
                        deleteFunc={leaveWorkspaceHandler}
                      />
                    )}
                  </div>
                </form>
              )}
              {tabNumber === 1 && (
                <UserSettings
                  item={item}
                  user={user}
                  classes={classes}
                  mdDown={mdDown}
                  setConfirmDialog={setConfirmDialog}
                />
              )}
              {tabNumber === 2 && (
                <CustomFields
                  item={item}
                  user={user}
                  classes={classes}
                  mdDown={mdDown}
                  toggleSettingsDrawer={toggleSettingsDrawer}
                  settingsDrawerOpen={settingsDrawerOpen}
                />
              )}
              {tabNumber === 3 && (
                <AccountDeletion
                  mdDown={mdDown}
                  userState={user}
                  classes={classes}
                  loadingSpinner={loadingSpinner}
                  setLoadingSpinner={setLoadingSpinner}
                />
              )}
            </div>
          </>
        )}
      </div>
    </motion.div>
  );
};

export default GeneralTab;
