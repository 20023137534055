const Add = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 15.481 15.481"
    >
      <g
        id="Icon_feather-plus"
        data-name="Icon feather-plus"
        transform="translate(0.875 0.875)"
      >
        <path
          id="Path_37837"
          data-name="Path 37837"
          d="M18,7.5V21.231"
          transform="translate(-11.135 -7.5)"
          fill="none"
          stroke="#6e6e6e"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.75"
        />
        <path
          id="Path_37838"
          data-name="Path 37838"
          d="M7.5,18H21.231"
          transform="translate(-7.5 -11.135)"
          fill="none"
          stroke="#6e6e6e"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.75"
        />
      </g>
    </svg>
  );
};

export default Add;
