import React from "react";

const Hamburger = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 33 29"
      className="xs:w-5 xs:h-5 md:w-8 md:h-8 stroke-primaryBlue"
    >
      <g
        id="Group_41153"
        data-name="Group 41153"
        transform="translate(-331 -104)"
      >
        <line
          id="Line_244"
          data-name="Line 244"
          x2="30"
          transform="translate(332.5 118.5)"
          fill="none"
          strokeLinecap="round"
          strokeWidth="3"
        />
        <line
          id="Line_245"
          data-name="Line 245"
          x2="30"
          transform="translate(332.5 105.5)"
          fill="none"
          strokeLinecap="round"
          strokeWidth="3"
        />
        <line
          id="Line_246"
          data-name="Line 246"
          x2="30"
          transform="translate(332.5 131.5)"
          fill="none"
          strokeLinecap="round"
          strokeWidth="3"
        />
      </g>
    </svg>
  );
};

export default Hamburger;
