import React from "react";

const Photo = (props) => {
  const { height = 34, width = 27, className = "fill-[#5b5b5b]" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 34 27.359"
      className={className}
    >
      <g
        id="Group_43865"
        data-name="Group 43865"
        transform="translate(-2150 2311)"
      >
        <path
          id="Path_38732"
          data-name="Path 38732"
          d="M34,23.27c0-.038,0-.075-.007-.114A.545.545,0,0,1,34,23.009v-2.46l0,0v-.343q0-7.938,0-15.876A4.2,4.2,0,0,0,29.673,0Q18.7,0,7.725,0H5a.526.526,0,0,1-.117.006C4.815,0,4.744,0,4.674,0h-.7L3.757,0A3.382,3.382,0,0,0,1.8.614,4.093,4.093,0,0,0,0,4.175Q0,13.82,0,23.465a3.76,3.76,0,0,0,3.852,3.889H16.809c4.319,0,8.638-.014,12.957.006A4.1,4.1,0,0,0,34,23.628Zm-4.04,1.895c-.106.005-.212,0-.318,0H3.873a1.583,1.583,0,0,1-1.691-1.7c0-1.041.005-2.082,0-3.124a.467.467,0,0,1,.212-.432Q5.948,17.3,9.5,14.687a2.094,2.094,0,0,1,2.712.107q2.547,2.228,5.081,4.47a4.513,4.513,0,0,0,5.415.547q4.392-2.538,8.781-5.08c.089-.051.181-.1.325-.175v.376q0,4.15,0,8.3a1.822,1.822,0,0,1-1.852,1.931m1.629-13.052q-5.011,2.91-10.016,5.83a2.252,2.252,0,0,1-2.755-.272q-2.464-2.168-4.924-4.342a4.376,4.376,0,0,0-5.814-.281l-5.612,4.08c-.077.056-.156.107-.285.2v-.375q0-6.363,0-12.725a2.314,2.314,0,0,1,.313-1.245,1.526,1.526,0,0,1,1.381-.793q12.942,0,25.884,0a2,2,0,0,1,2.051,2.049c.006,2.5,0,4.994.006,7.49a.388.388,0,0,1-.228.385"
          transform="translate(2150 -2311)"
        />
        <path
          id="Path_38733"
          data-name="Path 38733"
          d="M35.568,17.97a4.132,4.132,0,1,1,4.119-4.124,4.121,4.121,0,0,1-4.119,4.124m-1.957-4.136a1.944,1.944,0,1,0,3.888,0,1.944,1.944,0,0,0-3.888,0"
          transform="translate(2134.393 -2315.821)"
        />
      </g>
    </svg>
  );
};

export default Photo;
