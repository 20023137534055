import React from "react";

const EmptyCircle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 31 31"
    >
      <g
        id="Ellipse_1409"
        data-name="Ellipse 1409"
        fill="#fff"
        stroke="#717171"
        strokeWidth="2"
      >
        <circle cx="15.5" cy="15.5" r="15.5" stroke="none" />
        <circle cx="15.5" cy="15.5" r="14.5" fill="none" />
      </g>
    </svg>
  );
};

export default EmptyCircle;
