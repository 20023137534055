import React from "react";

const AttachmentClip = (props) => {
  const { height = 24, width = 24 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 20.429 21.889"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_18694"
            data-name="Rectangle 18694"
            width="20.941"
            height="9.096"
            fill="#afb4d2"
          />
        </clipPath>
      </defs>
      <g
        id="Group_43819"
        data-name="Group 43819"
        transform="translate(0 16.041) rotate(-50)"
      >
        <g
          id="Group_43776"
          data-name="Group 43776"
          transform="translate(0 0)"
          clipPath="url(#clipPath)"
        >
          <path
            id="Path_38725"
            data-name="Path 38725"
            d="M1.506,7.959A4.583,4.583,0,0,1,1.5,1.169,4.43,4.43,0,0,1,4.529.018C8.844.013,13.158.025,17.472,0a3.371,3.371,0,0,1,3.438,3.072,3.685,3.685,0,0,1-.636,2.654A3.155,3.155,0,0,1,17.6,7.1c-1.75.011-3.5.006-5.25.007-2.343,0-4.686.009-7.029,0a2.06,2.06,0,0,1-1.91-1.14,2.042,2.042,0,0,1,.09-2.2,2.033,2.033,0,0,1,1.774-.984c2.776-.012,5.552-.009,8.327-.01a.579.579,0,1,1-.02,1.156l-8.243.009a1.006,1.006,0,1,0,.013,2.011l3.2,0c2.96,0,5.919-.024,8.878,0a2.267,2.267,0,0,0,2.344-2.177,2.523,2.523,0,0,0-.467-1.747,2.1,2.1,0,0,0-1.79-.873q-5.278.007-10.557.009c-.833,0-1.666-.009-2.5,0A3.372,3.372,0,0,0,1.464,6,3.234,3.234,0,0,0,4.535,7.936c4.328.01,8.657,0,12.985-.007a.6.6,0,0,1,.572.292.575.575,0,0,1-.517.865c-.735.005-1.47,0-2.2,0q-5.413,0-10.826.008A4.338,4.338,0,0,1,1.506,7.959"
            transform="translate(0 0)"
            fill="#afb4d2"
          />
        </g>
      </g>
    </svg>
  );
};

export default AttachmentClip;
