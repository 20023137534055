import {
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import EmptyPanel from "components/display/EmptyPanel";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyledTableCell, StyledTableRow } from "settings/styleOverrides";
import _ from "utils/lodash";
import Utilities from "utils/utilities";
import { selectTaskLabelsEntities } from "./store/labelsSlice";
import {
  openEditTaskFormDialog,
  openNewTaskFormDialog,
  selectTasks,
} from "./store/tasksSlice";
import { useParams } from "react-router-dom";
import FilledHeart from "components/svgs/icons/general/FilledHeart";
import FeatherHeart from "components/svgs/icons/general/FeatherHeart";
import { Box } from "@mui/system";
import Completed from "components/svgs/icons/general/Completed";
import EmptyCircle from "components/svgs/icons/general/EmptyCircle";
import DeleteIcon from "components/svgs/icons/general/DeleteIcon";
import Important from "components/svgs/icons/general/Important";

const TaskList = (props) => {
  const { linkTasks, unlinkTask, taskClicked, loadingSpinner, searchQuery } =
    props;
  const dispatch = useDispatch();
  const { taskId } = useParams();
  let tasks = useSelector(selectTasks);
  const labels = useSelector(selectTaskLabelsEntities);
  const orderBy = useSelector(({ taskApp }) => taskApp.tasks.orderBy);
  const taskDialog = useSelector(
    ({ taskApp }) => taskApp.tasks.taskFormDialog.props
  );
  const orderDescending = useSelector(
    ({ taskApp }) => taskApp.tasks.orderDescending
  );

  if (linkTasks) {
    tasks = props.tasks;
  }

  const [filteredData, setFilteredData] = useState(null);

  useEffect(() => {
    if (tasks) {
      setFilteredData(
        _.orderBy(
          Utilities.getFilteredArray(tasks, searchQuery),
          [orderBy],
          [orderDescending ? "desc" : "asc"]
        )
      );

      if (taskId && !taskDialog.open) {
        const task = tasks.find((x) => x.taskId === taskId);
        if (task) dispatch(openEditTaskFormDialog(task));
      }
    }
  }, [tasks, searchQuery, orderBy, orderDescending]);

  if (!filteredData) {
    return null;
  }

  return (
    <motion.div
      variants={Utilities.itemOpacityConfig(20)}
      initial="hidden"
      animate="show"
    >
      {loadingSpinner ? (
        <CircularProgress color="info" className="m-auto" />
      ) : (
        <motion.div
          variants={Utilities.itemOpacityConfig(20)}
          className={`widget flex w-full flex-col ${
            linkTasks ? "" : "px-2 md:px-10"
          }`}
          initial="hidden"
          animate="show"
        >
          {!loadingSpinner && filteredData.length === 0 ? (
            <EmptyPanel
              mainClass={"mt-40 draggableCancel"}
              mainText={"No task, yet?"}
              imgClass={""}
              subText={
                "Start creating tasks and go ahead to link it with scrumboard cards."
              }
              imageSrc={"emptyTask"}
              buttonText={"Create a task"}
              onBtnClick={() => dispatch(openNewTaskFormDialog())}
              imgAlt={"Empty Tasks"}
              searchQuery={searchQuery}
            />
          ) : (
            <TableContainer>
              <Table
                sx={{
                  minWidth: 900,
                  borderCollapse: "separate",
                  borderSpacing: "0px 1.5rem",
                }}
                aria-label="customized table"
              >
                {!linkTasks && (
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Task Name</StyledTableCell>
                      <StyledTableCell>Labels</StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                    </TableRow>
                  </TableHead>
                )}
                <TableBody>
                  {/* TODO: udate the query */}
                  {filteredData
                    .filter((x) => !x.deleted)
                    .map((row) => (
                      <StyledTableRow
                        key={row.id}
                        onClick={() => {
                          linkTasks
                            ? taskClicked(row)
                            : dispatch(openEditTaskFormDialog(row));
                        }}
                        className="cursor-pointer draggableCancel"
                      >
                        <StyledTableCell
                          style={{ width: "40%" }}
                          component="th"
                          scope="row"
                        >
                          <div className="flex">
                            <div className="flex items-center">
                              <Box className="w-6 h-6 cursor-pointer">
                                {row.completed ? (
                                  <Completed />
                                ) : (
                                  <EmptyCircle />
                                )}
                              </Box>
                            </div>
                            <div className="text-container ml-3">
                              <Typography
                                className={`text-lg ${
                                  row.completed ? "line-through" : ""
                                }`}
                              >
                                {row.title}
                              </Typography>
                              <Typography
                                className={`text-mute text-sm ${
                                  row.completed ? "line-through" : ""
                                }`}
                              >
                                {row.subtitle}
                              </Typography>
                            </div>
                          </div>
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ width: "40%" }}
                          component="th"
                          scope="row"
                        >
                          {row.idLabels.map((id, idx) => {
                            const label = labels[id];
                            if (!label) return;
                            return (
                              <span
                                key={idx}
                                className="inline text-[11px] font-500 px-8 py-4 text-white rounded-full mr-1"
                                style={{ backgroundColor: `${label?.color}` }}
                              >
                                {label?.title}
                              </span>
                            );
                          })}
                          {row.idLabels.length == 0 && "-"}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ width: "20%" }}
                          component="th"
                          scope="row"
                        >
                          <div className="flex w-full gap-x-4 justify-center">
                            <Box className="w-8 h-8 cursor-pointer">
                              {row.important ? (
                                <Important
                                  width={32}
                                  height={32}
                                  color="#fff"
                                  fill="#f44336"
                                  stroke="none"
                                />
                              ) : (
                                <Important width={32} height={32} />
                              )}
                            </Box>
                            <Box className="w-8 h-8">
                              {row?.starred ? (
                                <FilledHeart width={32} height={32} />
                              ) : (
                                <FeatherHeart width={32} height={32} />
                              )}
                            </Box>
                            {linkTasks && (
                              <Box
                                className="cursor-pointer text-center"
                                onClick={(e) => unlinkTask(e, row)}
                              >
                                <DeleteIcon width={19} height={32} />
                              </Box>
                            )}
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </motion.div>
      )}
    </motion.div>
  );
};

export default TaskList;
